import { Dispatch, SetStateAction, useState } from "react";
import { surveySettingInterface } from "../../Interfaces/SurveyInterfaces";
import Attendents from "./survey360Share/Attendents";
import Roles from "./survey360Share/Roles";

type Props = {
  setSurveySetting: Dispatch<SetStateAction<surveySettingInterface>>;
  surveySetting: surveySettingInterface;
};

const Survey360Share: React.FC<Props> = ({
  setSurveySetting,
  surveySetting,
}) => {
  const [state360, setState360] = useState<number>(1);

  return (
    <div className={`w-full ${state360 === 1 ? "flex-col" : "flex"}`}>
      {state360 === 1 ? (
        <Roles
          setState360={setState360}
          setSurveySetting={setSurveySetting}
          surveySetting={surveySetting}
        />
      ) : (
        <Attendents setState360={setState360} />
      )}
      <br />
    </div>
  );
};

export default Survey360Share;

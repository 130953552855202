import { ChangeEvent, FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./CESSRange.css";
import Slider from "../../../staticDesign/Slider";
import { v4 as uuidv4 } from "uuid";
import { Switch } from "@headlessui/react";
import {
  classNames,
  generateBaseValues,
  getRange,
} from "../../../../lib/externalJS";
import { useAtom, useAtomValue } from "jotai";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../../atoms/surveyAtoms";
type componentProps = {
  numbers: string;
};

const HighPleasure: FC<componentProps> = ({ numbers }) => {
  return (
    <p className="my-2 text-sm text-stone-500">
      Yüksek Memnuniyet <span className={`text-[#3CCB7F]`}>({numbers})</span>
    </p>
  );
};
const MediumPleasure: FC<componentProps> = ({ numbers }) => {
  return (
    <p className="my-2 text-sm text-stone-500">
      Orta Memnuniyet <span className={`text-[#FAC515]`}>({numbers})</span>
    </p>
  );
};
const LowPleasure: FC<componentProps> = ({ numbers }) => {
  return (
    <p className="my-2 text-sm text-stone-500">
      Düşük Memnuniyet <span className={`text-[#F33D68]`}>({numbers})</span>
    </p>
  );
};

const CESScoring = () => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const [localState, setLocalState] = useState(
    stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]?.NPSType,
  );
  const [localStateVal1, setLocalStateVal1] = useState(
    stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
      ?.CesCalculation1,
  );
  const [localStateVal2, setLocalStateVal2] = useState(
    stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
      ?.CesCalculation2,
  );
  const [localStateVal3, setLocalStateVal3] = useState(
    stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
      ?.CesCalculation3,
  );
  const [localStateSwitch, setLocalStateSwitch] = useState(
    stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]?.CSATType,
  );

  const { t } = useTranslation();

  const handleNPSChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setLocalState((prevState) => {
      let newState = prevState;
      newState = Number(newValue);
      return newState;
    });
  };

  const handleSwitchChange = (e: boolean) => {
    const newValue = e;
    setLocalStateSwitch((prevState) => {
      let newState = prevState;
      newState = newValue;
      return newState;
    });
  };

  useEffect(() => {
    const { start, end } = getRange(localState ? localState : 1);

    let newRateValues =
      stateSurveyJSON.pages?.[pageIndex]?.elements?.[
        elementIndex
      ]?.rateValues?.filter((item) => {
        const contentAsNumber = parseInt(item.content || "", 10);
        return (
          !isNaN(contentAsNumber) &&
          contentAsNumber >= start &&
          contentAsNumber <= end
        );
      }) ?? [];

    const baseValues = generateBaseValues(start, end);
    for (const baseValue of baseValues) {
      if (!newRateValues.some((item) => item.content === baseValue.content)) {
        newRateValues.push(baseValue);
      }
    }

    newRateValues.sort(
      (a, b) => parseInt(a.content || "", 10) - parseInt(b.content || "", 10),
    );

    setStateSurveyJSON((prevState) => {
      let newState = { ...prevState };
      newState.pages![pageIndex]!.elements![elementIndex]!.NPSType = localState;
      newState.pages![pageIndex]!.elements![elementIndex]!.rateValues =
        newRateValues;
      return newState;
    });
  }, [localState]);

  useEffect(() => {
    setStateSurveyJSON((prevState) => {
      let newState = { ...prevState };
      newState.pages![pageIndex]!.elements![elementIndex]!.CSATType =
        localStateSwitch;
      return newState;
    });
  }, [localStateSwitch]);

  useEffect(() => {
    setLocalState(() => {
      return stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
        ?.NPSType;
    });
  }, [stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]?.NPSType]);

  const changeLocalVal1 = (e: number, maxLimit: number) => {
    if (e <= maxLimit) {
      if (localStateVal3! - e <= 1) {
        setLocalStateVal2(e + 1);
        setLocalStateVal3(e + 2);
      }
      setLocalStateVal1(e);
    }
  };

  const changeLocalVal2 = (e: number, maxLimit: number, minLimit: number) => {
    if (e <= maxLimit && e >= minLimit) {
      if (e - localStateVal1! <= 1) {
        setLocalStateVal1(e - 1);
        setLocalStateVal3(e + 1);
      } else if (localStateVal3! - e >= 1) {
        setLocalStateVal3(e + 1);
      } else if (e - localStateVal3! >= 0) {
        setLocalStateVal3(e + 1);
      }
      setLocalStateVal2(e);
    }
  };

  const changeLocalVal3 = (e: number, minLimit: number) => {
    if (e >= minLimit) {
      if (
        localStateVal2! - localStateVal1! === 0 &&
        e - localStateVal2! === 0
      ) {
        setLocalStateVal1(e - 2);
        setLocalStateVal2(e - 1);
      } else if (
        localStateVal2! - localStateVal1! === 1 &&
        e - localStateVal2! === 0
      ) {
        setLocalStateVal1(e - 2);
        setLocalStateVal2(e - 1);
      } else if (localStateVal2! - localStateVal1! > 0) {
        setLocalStateVal2(e - 1);
        if (e - 1 - localStateVal1! <= 0) {
          setLocalStateVal1(e - 2);
        }
      } else if (e - localStateVal2! < 0) {
        if (localStateVal2! - localStateVal1! >= 0) {
          setLocalStateVal1(e - 2);
          setLocalStateVal2(e - 1);
        } else {
          setLocalStateVal2(e - 1);
        }
      } else if (e - localStateVal2! > 0) {
        if (localStateVal2! - localStateVal1! > 0) {
          setLocalStateVal2(e - 1);
        } else if (localStateVal2! - localStateVal1! <= 0) {
          setLocalStateVal1(e - 2);
          setLocalStateVal2(e - 1);
        }
      }
      setLocalStateVal3(e);
    }
  };

  return (
    <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
      <label className="mb-2 block text-sm font-bold text-gray-500">
        CES Puanlama
      </label>
      <div className="mt-3 flex items-center">
        <input
          id="0-10"
          type="radio"
          style={{ transform: "scale(1.2)" }}
          value={1}
          checked={localState === 1 ? true : false}
          onChange={(e) => {
            handleNPSChange(e);
            setLocalStateVal1(3);
            setLocalStateVal2(6);
            setLocalStateVal3(7);
            setStateSurveyJSON((prevState) => {
              let newState = { ...prevState };
              newState.pages![pageIndex]!.elements![elementIndex]!.rateValues =
                [
                  { content: "0", _id: uuidv4() },
                  { content: "1", _id: uuidv4() },
                  {
                    content: "2",
                    _id: uuidv4(),
                  },
                  { _id: uuidv4(), content: "3" },
                  { _id: uuidv4(), content: "4" },
                  { _id: uuidv4(), content: "5" },
                  { _id: uuidv4(), content: "6" },
                  { _id: uuidv4(), content: "7" },
                  { _id: uuidv4(), content: "8" },
                  {
                    _id: uuidv4(),
                    content: "9",
                  },
                  { _id: uuidv4(), content: "10" },
                ];
              return newState;
            });
          }}
          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
        />
        <label
          htmlFor="0-10"
          className="ml-3 block text-sm font-medium leading-6 text-gray-900"
        >
          0-10
        </label>
      </div>
      <div className="mt-3 flex items-center">
        <input
          id="1-10"
          type="radio"
          style={{ transform: "scale(1.2)" }}
          value={2}
          checked={localState === 2 ? true : false}
          onChange={(e) => {
            handleNPSChange(e);
            setLocalStateVal1(3);
            setLocalStateVal2(6);
            setLocalStateVal3(7);
            setStateSurveyJSON((prevState) => {
              let newState = { ...prevState };
              newState.pages![pageIndex]!.elements![elementIndex]!.rateValues =
                [
                  { content: "1", _id: uuidv4() },
                  {
                    content: "2",
                    _id: uuidv4(),
                  },
                  { _id: uuidv4(), content: "3" },
                  { _id: uuidv4(), content: "4" },
                  { _id: uuidv4(), content: "5" },
                  { _id: uuidv4(), content: "6" },
                  { _id: uuidv4(), content: "7" },
                  { _id: uuidv4(), content: "8" },
                  {
                    _id: uuidv4(),
                    content: "9",
                  },
                  { _id: uuidv4(), content: "10" },
                ];
              return newState;
            });
          }}
          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
        />
        <label
          htmlFor="1-10"
          className="ml-3 block text-sm font-medium leading-6 text-gray-900"
        >
          1-10
        </label>
      </div>
      <div className="mt-3 flex items-center">
        <input
          id="1-7"
          type="radio"
          style={{ transform: "scale(1.2)" }}
          value={3}
          checked={localState === 3 ? true : false}
          onChange={(e) => {
            handleNPSChange(e);
            setLocalStateVal1(3);
            setLocalStateVal2(6);
            setLocalStateVal3(7);
            setStateSurveyJSON((prevState) => {
              let newState = { ...prevState };
              newState.pages![pageIndex]!.elements![elementIndex]!.rateValues =
                [
                  { content: "1", _id: uuidv4() },
                  {
                    content: "2",
                    _id: uuidv4(),
                  },
                  { _id: uuidv4(), content: "3" },
                  { _id: uuidv4(), content: "4" },
                  { _id: uuidv4(), content: "5" },
                  { _id: uuidv4(), content: "6" },
                  { _id: uuidv4(), content: "7" },
                ];
              return newState;
            });
          }}
          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
        />
        <label
          htmlFor="1-7"
          className="ml-3 block text-sm font-medium leading-6 text-gray-900"
        >
          1-7
        </label>
      </div>
      <div className="mt-3 flex items-center">
        <input
          id="1-5"
          type="radio"
          style={{ transform: "scale(1.2)" }}
          value={4}
          checked={localState === 4 ? true : false}
          onChange={(e) => {
            handleNPSChange(e);
            setLocalStateVal1(2);
            setLocalStateVal2(4);
            setLocalStateVal3(5);
            setStateSurveyJSON((prevState) => {
              let newState = { ...prevState };
              newState.pages![pageIndex]!.elements![elementIndex]!.rateValues =
                [
                  { content: "1", _id: uuidv4() },
                  {
                    content: "2",
                    _id: uuidv4(),
                  },
                  { _id: uuidv4(), content: "3" },
                  { _id: uuidv4(), content: "4" },
                  { _id: uuidv4(), content: "5" },
                ];
              return newState;
            });
          }}
          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
        />
        <label
          htmlFor="1-5"
          className="ml-3 block text-sm font-medium leading-6 text-gray-900"
        >
          1-5
        </label>
      </div>
      <div className="mt-3 flex items-center">
        <input
          id="1-3"
          type="radio"
          style={{ transform: "scale(1.2)" }}
          value={5}
          checked={localState === 5 ? true : false}
          onChange={(e) => {
            handleNPSChange(e);
            setLocalStateVal1(1);
            setLocalStateVal2(2);
            setLocalStateVal3(3);
            setStateSurveyJSON((prevState) => {
              let newState = { ...prevState };
              newState.pages![pageIndex]!.elements![elementIndex]!.rateValues =
                [
                  { content: "1", _id: uuidv4() },
                  {
                    content: "2",
                    _id: uuidv4(),
                  },
                  { _id: uuidv4(), content: "3" },
                ];
              return newState;
            });
          }}
          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
        />
        <label
          htmlFor="1-3"
          className="ml-3 block text-sm font-medium leading-6 text-gray-900"
        >
          1-3
        </label>
      </div>
      <div className="mt-3 flex">
        <Switch
          id="flexSwitchCheckReq"
          checked={localStateSwitch}
          onChange={handleSwitchChange}
          className={classNames(
            localStateSwitch ? "bg-blue-600" : "bg-gray-200",
            "relative mr-2 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2",
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={classNames(
              localStateSwitch ? "translate-x-5" : "translate-x-0",
              "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
            )}
          />
        </Switch>
        <label className="text-gray-300" htmlFor="flexSwitchCheckReq">
          {localStateSwitch ? t("ters") : t("normal")}
        </label>
      </div>
      {localState === 1 && !localStateSwitch && (
        <div>
          <HighPleasure numbers={"0-" + localStateVal1} />
          <div className="pb-4">
            <Slider
              colorNumber={1}
              min={0}
              max={10}
              value={localStateVal1!}
              onChange={(e: any) => changeLocalVal1(e, 8)}
            />
          </div>
          <MediumPleasure
            numbers={
              localStateVal2! - 1 === localStateVal1!
                ? localStateVal2!.toString()
                : (localStateVal1! + 1).toString() +
                  "-" +
                  localStateVal2!.toString()
            }
          />
          <div className="pb-4">
            <Slider
              colorNumber={2}
              value={localStateVal2!}
              leftValue={localStateVal1!}
              min={0}
              max={10}
              onChange={(e: any) => changeLocalVal2(e, 9, 1)}
            />
          </div>
          <LowPleasure numbers={localStateVal3 + "-10"} />
          <div className="pb-4">
            <Slider
              colorNumber={3}
              value={localStateVal3!}
              min={0}
              max={10}
              onChange={(e: any) => changeLocalVal3(e, 2)}
            />
          </div>
        </div>
      )}
      {localState === 2 && !localStateSwitch && (
        <div className="mb-2">
          <div className="w-full">
            <HighPleasure numbers={"1-" + localStateVal1} />
            <div className="pb-4">
              <Slider
                colorNumber={1}
                value={localStateVal1!}
                min={1}
                max={10}
                onChange={(e: any) => changeLocalVal1(e, 8)}
              />
            </div>
          </div>
          <div className="w-full">
            <MediumPleasure
              numbers={
                localStateVal2! - 1 === localStateVal1!
                  ? localStateVal2!.toString()
                  : (localStateVal1! + 1).toString() +
                    "-" +
                    localStateVal2!.toString()
              }
            />

            <div className="pb-4">
              <Slider
                colorNumber={2}
                value={localStateVal2!}
                leftValue={localStateVal1!}
                min={1}
                max={10}
                onChange={(e: any) => changeLocalVal2(e, 9, 2)}
              />
            </div>
          </div>
          <div className="w-full">
            <LowPleasure numbers={localStateVal3 + "-10"} />

            <div className="pb-4">
              <Slider
                colorNumber={3}
                value={localStateVal3!}
                min={1}
                max={10}
                onChange={(e: any) => changeLocalVal3(e, 3)}
              />
            </div>
          </div>
        </div>
      )}
      {localState === 3 && !localStateSwitch && (
        <div className="mb-2">
          <div className="w-full">
            <HighPleasure numbers={"1-" + localStateVal1} />

            <div className="pb-4">
              <Slider
                colorNumber={1}
                value={localStateVal1!}
                min={1}
                max={7}
                onChange={(e: any) => changeLocalVal1(e, 5)}
              />
            </div>
          </div>
          <div className="w-full">
            <MediumPleasure
              numbers={
                localStateVal2! - 1 === localStateVal1!
                  ? localStateVal2!.toString()
                  : (localStateVal1! + 1).toString() +
                    "-" +
                    localStateVal2!.toString()
              }
            />

            <div className="pb-4">
              <Slider
                colorNumber={2}
                value={localStateVal2!}
                leftValue={localStateVal1!}
                min={1}
                max={7}
                onChange={(e: any) => changeLocalVal2(e, 6, 2)}
              />
            </div>
          </div>
          <div className="w-full">
            <LowPleasure
              numbers={
                localStateVal3! - 1 === localStateVal2!
                  ? localStateVal3!.toString()
                  : localStateVal3 + "-7"
              }
            />
            <div className="pb-4">
              <Slider
                colorNumber={3}
                value={localStateVal3!}
                min={1}
                max={7}
                onChange={(e: any) => changeLocalVal3(e, 3)}
              />
            </div>
          </div>
        </div>
      )}
      {localState === 4 && !localStateSwitch && (
        <div className="mb-2">
          <div className="w-full">
            <HighPleasure numbers={"1-" + localStateVal1} />

            <div className="pb-4">
              <Slider
                colorNumber={1}
                value={localStateVal1!}
                min={1}
                max={5}
                onChange={(e: any) => changeLocalVal1(e, 3)}
              />
            </div>
          </div>
          <div className="w-full">
            <MediumPleasure
              numbers={
                localStateVal2! - 1 === localStateVal1!
                  ? localStateVal2!.toString()
                  : (localStateVal1! + 1).toString() +
                    "-" +
                    localStateVal2!.toString()
              }
            />

            <div className="pb-4">
              <Slider
                colorNumber={2}
                value={localStateVal2!}
                leftValue={localStateVal1!}
                min={1}
                max={5}
                onChange={(e: any) => changeLocalVal2(e, 4, 2)}
              />
            </div>
          </div>
          <div className="w-full">
            <LowPleasure
              numbers={
                localStateVal3! - 1 === localStateVal2!
                  ? localStateVal3!.toString()
                  : localStateVal3 + "-5"
              }
            />
            <div className="pb-4">
              <Slider
                colorNumber={3}
                value={localStateVal3!}
                min={1}
                max={5}
                onChange={(e: any) => changeLocalVal3(e, 3)}
              />
            </div>
          </div>
        </div>
      )}
      {localState === 5 && !localStateSwitch && (
        <div className="mb-2">
          <div className="w-full">
            <HighPleasure numbers={"1"} />
            <div className="pb-4">
              <Slider
                colorNumber={1}
                value={localStateVal1!}
                min={1}
                max={3}
                onChange={(e: any) => changeLocalVal1(e, 1)}
              />
            </div>
          </div>
          <div className="w-full">
            <MediumPleasure numbers={"2"} />

            <div className="pb-4">
              <Slider
                colorNumber={2}
                value={localStateVal2!}
                leftValue={localStateVal1!}
                min={1}
                max={3}
                onChange={(e: any) => changeLocalVal2(e, 2, 2)}
              />
            </div>
          </div>
          <div className="w-full">
            <LowPleasure numbers={"3"} />
            <div className="pb-4">
              <Slider
                colorNumber={3}
                value={localStateVal3!}
                min={1}
                max={3}
                onChange={(e: any) => changeLocalVal3(e, 3)}
              />
            </div>
          </div>
        </div>
      )}
      {localState === 1 && localStateSwitch && (
        <div className="mb-2">
          <div className="w-full">
            <LowPleasure numbers={"0-" + localStateVal3} />
            <div className="pb-4">
              <Slider
                colorNumber={4}
                value={localStateVal1!}
                min={0}
                max={10}
                onChange={(e: any) => changeLocalVal1(e, 8)}
              />
            </div>
          </div>
          <div className="w-full">
            <MediumPleasure
              numbers={
                localStateVal2! - 1 === localStateVal1!
                  ? localStateVal2!.toString()
                  : (localStateVal1! + 1).toString() +
                    "-" +
                    localStateVal2!.toString()
              }
            />

            <div className="pb-4">
              <Slider
                colorNumber={2}
                value={localStateVal2!}
                leftValue={localStateVal1!}
                min={0}
                max={10}
                onChange={(e: any) => changeLocalVal2(e, 9, 1)}
              />
            </div>
          </div>
          <div className="w-full">
            <HighPleasure numbers={localStateVal1 + "-10"} />
            <div className="pb-4">
              <Slider
                colorNumber={5}
                value={localStateVal3!}
                min={0}
                max={10}
                onChange={(e: any) => changeLocalVal3(e, 2)}
              />
            </div>
          </div>
        </div>
      )}
      {localState === 2 && localStateSwitch && (
        <div className="mb-2">
          <div className="w-full">
            <LowPleasure numbers={"1-" + localStateVal3} />
            <div className="pb-4">
              <Slider
                colorNumber={4}
                value={localStateVal1!}
                min={1}
                max={10}
                onChange={(e: any) => changeLocalVal1(e, 8)}
              />
            </div>
          </div>
          <div className="w-full">
            <MediumPleasure
              numbers={
                localStateVal2! - 1 === localStateVal1!
                  ? localStateVal2!.toString()
                  : (localStateVal1! + 1).toString() +
                    "-" +
                    localStateVal2!.toString()
              }
            />

            <div className="pb-4">
              <Slider
                colorNumber={2}
                value={localStateVal2!}
                leftValue={localStateVal1!}
                min={1}
                max={10}
                onChange={(e: any) => changeLocalVal2(e, 9, 2)}
              />
            </div>
          </div>
          <div className="w-full">
            <HighPleasure numbers={localStateVal1 + "-10"} />
            <div className="pb-4">
              <Slider
                colorNumber={5}
                value={localStateVal3!}
                min={1}
                max={10}
                onChange={(e: any) => changeLocalVal3(e, 3)}
              />
            </div>
          </div>
        </div>
      )}
      {localState === 3 && localStateSwitch && (
        <div className="mb-2">
          <div className="w-full">
            <LowPleasure
              numbers={localStateVal1 === 1 ? "1" : "1-" + localStateVal1}
            />
            <div className="pb-4">
              <Slider
                colorNumber={4}
                value={localStateVal1!}
                min={1}
                max={7}
                onChange={(e: any) => changeLocalVal1(e, 5)}
              />
            </div>
          </div>
          <div className="w-full">
            <MediumPleasure
              numbers={
                localStateVal2! - 1 === localStateVal1!
                  ? localStateVal2!.toString()
                  : (localStateVal1! + 1).toString() +
                    "-" +
                    localStateVal2!.toString()
              }
            />

            <div className="pb-4">
              <Slider
                colorNumber={2}
                value={localStateVal2!}
                leftValue={localStateVal1!}
                min={1}
                max={7}
                onChange={(e: any) => changeLocalVal2(e, 6, 2)}
              />
            </div>
          </div>
          <div className="w-full">
            <HighPleasure
              numbers={
                localStateVal3! === 7!
                  ? localStateVal3!.toString()
                  : localStateVal3!.toString() + "- 7"
              }
            />

            <div className="pb-4">
              <Slider
                colorNumber={5}
                value={localStateVal3!}
                min={1}
                max={7}
                onChange={(e: any) => changeLocalVal3(e, 3)}
              />
            </div>
          </div>
        </div>
      )}
      {localState === 4 && localStateSwitch && (
        <div className="mb-2">
          <div className="w-full">
            <LowPleasure numbers={"1-" + localStateVal1} />

            <div className="pb-4">
              <Slider
                colorNumber={4}
                value={localStateVal1!}
                min={1}
                max={5}
                onChange={(e: any) => changeLocalVal1(e, 3)}
              />
            </div>
          </div>
          <div className="w-full">
            <MediumPleasure
              numbers={
                localStateVal2! - 1 === localStateVal1!
                  ? localStateVal2!.toString()
                  : (localStateVal1! + 1).toString() +
                    "-" +
                    localStateVal2!.toString()
              }
            />

            <div className="pb-4">
              <Slider
                colorNumber={2}
                value={localStateVal2!}
                leftValue={localStateVal1!}
                min={1}
                max={5}
                onChange={(e: any) => changeLocalVal2(e, 4, 2)}
              />
            </div>
          </div>
          <div className="w-full">
            <HighPleasure
              numbers={
                localStateVal3! === 5!
                  ? localStateVal3!.toString()
                  : localStateVal3!.toString() + "- 5"
              }
            />

            <div className="pb-4">
              <Slider
                colorNumber={5}
                value={localStateVal3!}
                min={1}
                max={5}
                onChange={(e: any) => changeLocalVal3(e, 3)}
              />
            </div>
          </div>
        </div>
      )}
      {localState === 5 && localStateSwitch && (
        <div className="mb-2">
          <div className="w-full">
            <LowPleasure numbers={"1"} />

            <div className="pb-4">
              <Slider
                colorNumber={4}
                value={localStateVal1!}
                min={1}
                max={3}
                onChange={(e: any) => changeLocalVal1(e, 1)}
              />
            </div>
          </div>
          <div className="w-full">
            <MediumPleasure numbers={"2"} />

            <div className="pb-4">
              <Slider
                colorNumber={2}
                value={localStateVal2!}
                leftValue={localStateVal1!}
                min={1}
                max={3}
                onChange={(e: any) => changeLocalVal2(e, 2, 2)}
              />
            </div>
          </div>
          <div className="w-full">
            <HighPleasure numbers={"3"} />

            <div className="pb-4">
              <Slider
                colorNumber={5}
                value={localStateVal3!}
                min={1}
                max={3}
                onChange={(e: any) => changeLocalVal3(e, 3)}
              />
            </div>
          </div>
        </div>
      )}
      <p className="mb-0 mt-3 mt-4 text-sm text-stone-500">
        {t("Ölçek tipini seçin")}
      </p>
    </div>
  );
};

export default CESScoring;

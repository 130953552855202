import React, { useMemo } from "react";
import {
  elementChoicesInterface,
  surveyElementsInterface,
} from "../../Interfaces/SurveyInterfaces";
import { InputButtonMulti } from "./components/FormCompoents";

type Props = {
  question: surveyElementsInterface;
  register: any;
  setValue: any;
  errors: any;
  watch: any;
  endOfPage: boolean;
  getValues?: any;
  questionIndex: number;
};
/* TODO preview kısmında bu alan patlıyor */
const MultiChoiceQuestion: React.FC<Props> = ({
  question,
  register,
  setValue,
  endOfPage,
  errors,
  watch,
  getValues,
  questionIndex,
}) => {
  const sortedChoices = useMemo(() => {
    if (question.shuffleOptions) {
      return (
        question.choices &&
        [...question.choices].sort(() => Math.random() - 0.5)
      );
    } else {
      return question.choices || [];
    }
  }, [question.choices, question.shuffleOptions]);

  const ChoicesTitle = useMemo(() => {
    return question.choices?.map(
      (choice: elementChoicesInterface, index: number) =>
        choice.title?.tr ? choice.title.tr : "seçenek " + index
    );
  }, [question.choices]);

  const bomb = question.showOtherItem ? watch(question._id)?.value : false;

  return (
    <div data-title={question.tipText?.tr}>
      <h6
        className="mb-0"
        dangerouslySetInnerHTML={{ __html: question.title?.tr ?? "" }}
      ></h6>
      <p className=" text-gray-500">{question.description?.tr}</p>
      {sortedChoices?.map((choice: elementChoicesInterface, i: number) => {
        return (
          <div className="mb-2" key={choice._id}>
            <InputButtonMulti
              getValues={getValues}
              questionIndex={questionIndex}
              options={ChoicesTitle}
              id={choice._id}
              name={question._id}
              readOnly={question.readOnly ? question.readOnly : false}
              className={`mr-2  form-check-input ${
                !!errors[question._id] && "!border-red-500"
              }`}
              required={question?.isRequired ?? false}
              register={register}
              style={{ transform: "scale(1.2)" }}
              value={
                choice?.content
                  ? choice?.content
                  : choice.title?.tr
                    ? choice.title?.tr
                    : "seçenek " + (i + 1)
              }
              defaultChecked={question.defaultChoice === choice._id}
              setValue={setValue}
              optionTitle={
                choice.title?.tr ? choice.title?.tr : "seçenek " + (i + 1)
              }
              questionTitle={question.title?.tr ?? ""}
              limitationMax={question.limitationMax}
              limitationMin={question.limitationMin}
            />
            <label htmlFor={choice._id}>
              {choice.title?.tr ? choice.title?.tr : "seçenek " + (i + 1)}
            </label>
          </div>
        );
      })}
      {question.showNoneItem && (
        <div className="mb-2 ">
          <InputButtonMulti
            getValues={getValues}
            name={question._id}
            readOnly={question.readOnly ? question.readOnly : false}
            className={`mr-2  form-check-input ${
              !!errors[question._id] && "!border-red-500"
            }`}
            required={question?.isRequired ?? false}
            register={register}
            style={{ transform: "scale(1.2)" }}
            value={"Fikrim Yok"}
            defaultChecked={false}
            id={`${question._id}_noIdea`}
            setValue={setValue}
            optionTitle={"Fikrim Yok"}
            questionTitle={question.title?.tr}
          />
          <label
            htmlFor={`${question._id}_noIdea`}
            data-title={question.noneInfo?.tr}
          >
            {question.noneText?.tr ?? "Fikrim Yok"}
          </label>
        </div>
      )}
      {question.showOtherItem && (
        <div className="mb-2 flex">
          <InputButtonMulti
            getValues={getValues}
            id={`${question._id}_other`}
            style={{ transform: "scale(1.2)" }}
            className={`mr-2  form-check-input ${
              !!errors[question._id] && "!border-red-500"
            }`}
            readOnly={question.readOnly ? question.readOnly : false}
            name={question._id}
            value={"Other"}
            required={
              question.isRequired
                ? question.isRequired
                : question.isOtherItemRequired ?? false
            }
            questionTitle={question.title?.tr}
            register={register}
            optionTitle={"Other"}
            setValue={setValue}
            defaultChecked={false}
          />

          {bomb?.length > 0 && bomb?.includes("Other") ? (
            <textarea
              className={`border rounded pl-2  focus:outline-blue-30  w-3/4 h-[90px]`}
              {...register(question._id + ".OtherValue", {
                value: null,
                required: {
                  value: question.isOtherItemRequired ?? false,
                  message: "Diğer Alanı Zorunludur",
                },
              })} //burayı başka isimle kaydettim gelecekte bize farklı durumlarda lazım olabilir
              placeholder={"Lütfen Yazınız"}
            />
          ) : (
            <label
              htmlFor={`${question._id}_other`}
              data-title={question.otherInfo?.tr}
            >
              {question.otherText?.tr ?? "Diger"}
            </label>
          )}
        </div>
      )}
      <p className="mb-2 text-sm text-red-500">
        {errors[question._id]?.value?.message ??
          errors[question._id]?.[Object.keys(errors[question._id])[0]].message}
        {/* buradaki kod error mesajını valude yoksa en baştan tutup getiriyor */}
        {/* objectin içinden getirmek için keyini error içerisinde arattım */}
      </p>
      <p className="mb-4 text-gray-500">{question.bottomTicket?.tr}</p>
      {!endOfPage && <hr />}
    </div>
  );
};

export default MultiChoiceQuestion;

import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { generateBaseValues, getRange } from "../../../../lib/externalJS";
import { useAtom, useAtomValue } from "jotai";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../../atoms/surveyAtoms";

const NPSScoring = () => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const [localState, setLocalState] = useState(
    stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]?.NPSType,
  );

  const { t } = useTranslation();

  const handleNPSChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setLocalState((prevState) => {
      let newState = prevState;
      newState = Number(newValue);
      return newState;
    });
  };

  useEffect(() => {
    const { start, end } = getRange(localState ? localState : 1);

    let newRateValues =
      stateSurveyJSON.pages?.[pageIndex]?.elements?.[
        elementIndex
      ]?.rateValues?.filter((item) => {
        const contentAsNumber = parseInt(item.content || "", 10);
        return (
          !isNaN(contentAsNumber) &&
          contentAsNumber >= start &&
          contentAsNumber <= end
        );
      }) ?? [];

    const baseValues = generateBaseValues(start, end);
    for (const baseValue of baseValues) {
      if (!newRateValues.some((item) => item.content === baseValue.content)) {
        newRateValues.push(baseValue);
      }
    }

    newRateValues.sort(
      (a, b) => parseInt(a.content || "", 10) - parseInt(b.content || "", 10),
    );

    setStateSurveyJSON((prevState) => {
      let newState = { ...prevState };
      newState.pages![pageIndex]!.elements![elementIndex]!.NPSType = localState;
      newState.pages![pageIndex]!.elements![elementIndex]!.rateValues =
        newRateValues;
      return newState;
    });
  }, [localState]);

  useEffect(() => {
    setLocalState(() => {
      return stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
        ?.NPSType;
    });
  }, [stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]?.NPSType]);

  return (
    <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
      <label className="block text-sm font-bold text-gray-500">
        NPS Puanlama sdafdasfsadf
      </label>
      <div className="mt-3 flex flex-col">
        <div className="mt-3 flex items-center">
          <input
            id="NPS0-10"
            type="radio"
            style={{ transform: "scale(1.2)" }}
            value={1}
            checked={localState === 1 ? true : false}
            onChange={(e) => handleNPSChange(e)}
            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
          />
          <label
            htmlFor="NPS0-10"
            className="ml-3 block text-sm font-medium leading-6 text-gray-900"
          >
            0-10
          </label>
        </div>
        <div className="mt-3 flex items-center">
          <input
            id="NPS1-10"
            type="radio"
            style={{ transform: "scale(1.2)" }}
            value={2}
            checked={localState === 2 ? true : false}
            onChange={(e) => handleNPSChange(e)}
            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
          />
          <label
            htmlFor="NPS1-10"
            className="ml-3 block text-sm font-medium leading-6 text-gray-900"
          >
            1-10
          </label>
        </div>
        <div className="mt-3 flex items-center">
          <input
            id="NPS1-7"
            type="radio"
            style={{ transform: "scale(1.2)" }}
            value={3}
            checked={localState === 3 ? true : false}
            onChange={(e) => handleNPSChange(e)}
            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
          />
          <label
            htmlFor="NPS1-7"
            className="ml-3 block text-sm font-medium leading-6 text-gray-900"
          >
            1-7
          </label>
        </div>
        <div className="mt-3 flex items-center">
          <input
            id="NPS1-5"
            type="radio"
            style={{ transform: "scale(1.2)" }}
            value={4}
            checked={localState === 4 ? true : false}
            onChange={(e) => handleNPSChange(e)}
            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
          />
          <label
            htmlFor="NPS1-5"
            className="ml-3 block text-sm font-medium leading-6 text-gray-900"
          >
            1-5
          </label>
        </div>
      </div>
      {localState === 1 && (
        <div className="mt-3">
          <div className="w-full">
            <p className="mb-2 text-sm text-stone-500">
              Olumsuz <span className="text-[#F33D68]">(0-3)</span>
            </p>
            <div className="relative h-[18px] w-full rounded-full bg-gray-300">
              <span
                className="absolute top-0 z-10 -ml-2 -mt-1 h-6 w-6 cursor-pointer rounded-full bg-[#F33D68] text-center text-white shadow"
                style={{ left: "74px" }}
              >
                3
              </span>
              <span
                className="absolute left-0 top-0 h-[18px] rounded-full bg-[#F33D68]"
                style={{ width: "76px" }}
              ></span>
            </div>
          </div>
          <div className="w-full">
            <p className="my-2 text-sm text-stone-500">
              Pasif <span className="text-[#FAC515]">(4-6)</span>
            </p>
            <div className="relative h-[18px] w-full rounded-full bg-gray-300">
              <span
                className="absolute right-1/2 top-0 z-10 -ml-2 -mt-1 h-6 w-6 cursor-pointer rounded-full bg-[#FAC515] text-center text-white shadow"
                style={{ right: "80px" }}
              >
                6
              </span>
              <span
                className="absolute right-[84px] top-0 flex h-[18px] rounded-full bg-[#FAC515]"
                style={{ width: "55px" }}
              ></span>
            </div>
          </div>
          <div className="w-full">
            <p className="my-2 text-sm text-stone-500">
              Olumsuz <span className="text-[#3CCB7F]">(7-10)</span>
            </p>
            <div className="relative h-[18px] w-full rounded-full bg-gray-300">
              <span
                className="absolute top-0 z-10 -ml-2 -mt-1 h-6 w-6 cursor-pointer rounded-full bg-[#3CCB7F] text-center text-white shadow"
                style={{ right: "70px" }}
              >
                7
              </span>
              <span
                className="absolute right-0 top-0 h-[18px] rounded-full bg-[#3CCB7F]"
                style={{ width: "84px" }}
              ></span>
            </div>
          </div>
        </div>
      )}
      {localState === 2 && (
        <div className="mt-3">
          <div className="w-full">
            <p className="mb-2 text-sm text-stone-500">
              Olumsuz <span className="text-[#F33D68]">(1-3)</span>
            </p>
            <div className="relative h-[18px] w-full rounded-full bg-gray-300">
              <span
                className="absolute top-0 z-10 -ml-2 -mt-1 h-6 w-6 cursor-pointer rounded-full bg-[#F33D68] text-center text-white shadow"
                style={{ left: "52px" }}
              >
                3
              </span>
              <span
                className="absolute left-0 top-0 h-[18px] rounded-full bg-[#F33D68]"
                style={{ width: "52px" }}
              ></span>
            </div>
          </div>
          <div className="w-full">
            <p className="my-2 text-sm text-stone-500">
              Pasif <span className="text-[#FAC515]">(4-6)</span>
            </p>
            <div className="relative h-[18px] w-full rounded-full bg-gray-300">
              <span
                className="absolute right-1/2 top-0 z-10 -ml-2 -mt-1 h-6 w-6 cursor-pointer rounded-full bg-[#FAC515] text-center text-white shadow"
                style={{ right: "70px" }}
              >
                6
              </span>
              <span
                className="absolute right-[84px] top-0 flex h-[18px] rounded-full bg-[#FAC515]"
                style={{ width: "60px" }}
              ></span>
            </div>
          </div>
          <div className="w-full">
            <p className="my-2 text-sm text-stone-500">
              Olumsuz <span className="text-[#3CCB7F]">(7-10)</span>
            </p>
            <div className="relative h-[18px] w-full rounded-full bg-gray-300">
              <span
                className="absolute top-0 z-10 -ml-2 -mt-1 h-6 w-6 cursor-pointer rounded-full bg-[#3CCB7F] text-center text-white shadow"
                style={{ right: "60px" }}
              >
                7
              </span>
              <span
                className="absolute right-0 top-0 h-[18px] rounded-full bg-[#3CCB7F]"
                style={{ width: "84px" }}
              ></span>
            </div>
          </div>
        </div>
      )}
      {localState === 3 && (
        <div className="mt-3">
          <div className="w-full">
            <p className="mb-2 text-sm text-stone-500">
              Olumsuz <span className="text-[#F33D68]">(1-3)</span>
            </p>
            <div className="relative h-[18px] w-full rounded-full bg-gray-300">
              <span
                className="absolute top-0 z-10 -ml-2 -mt-1 h-6 w-6 cursor-pointer rounded-full bg-[#F33D68] text-center text-white shadow"
                style={{ left: "70px" }}
              >
                3
              </span>
              <span
                className="absolute left-0 top-0 h-[18px] rounded-full bg-[#F33D68]"
                style={{ width: "70px" }}
              ></span>
            </div>
          </div>
          <div className="w-full">
            <p className="my-2 text-sm text-stone-500">
              Pasif <span className="text-[#FAC515]">(4-6)</span>
            </p>
            <div className="relative h-[18px] w-full rounded-full bg-gray-300">
              <span
                className="absolute right-1/2 top-0 z-10 -ml-2 -mt-1 h-6 w-6 cursor-pointer rounded-full bg-[#FAC515] text-center text-white shadow"
                style={{ right: "30px" }}
              >
                6
              </span>
              <span
                className="absolute right-[50px] top-0 flex h-[18px] rounded-full bg-[#FAC515]"
                style={{ width: "70px" }}
              ></span>
            </div>
          </div>
          <div className="w-full">
            <p className="my-2 text-sm text-stone-500">
              Olumsuz <span className="text-[#3CCB7F]">(7)</span>
            </p>
            <div className="relative h-[18px] w-full rounded-full bg-gray-300">
              <span className="absolute right-0 top-0 z-10 -ml-2 -mt-1 h-6 w-6 cursor-pointer rounded-full bg-[#3CCB7F] text-center text-white shadow">
                7
              </span>
              <span className="absolute right-0 top-0 h-[18px] rounded-full bg-[#3CCB7F]"></span>
            </div>
          </div>
        </div>
      )}
      {localState === 4 && (
        <div className="mt-3">
          <div className="w-full">
            <p className="mb-2 text-sm text-stone-500">
              Olumsuz <span className="text-[#F33D68]">(1-2)</span>
            </p>
            <div className="relative h-[18px] w-full rounded-full bg-gray-300">
              <span
                className="absolute top-0 z-10 -ml-2 -mt-1 h-6 w-6 cursor-pointer rounded-full bg-[#F33D68] text-center text-white shadow"
                style={{ left: "70px" }}
              >
                2
              </span>
              <span
                className="absolute left-0 top-0 h-[18px] rounded-full bg-[#F33D68]"
                style={{ width: "70px" }}
              ></span>
            </div>
          </div>
          <div className="w-full">
            <p className="my-2 text-sm text-stone-500">
              Pasif <span className="text-[#FAC515]">(3-4)</span>
            </p>
            <div className="relative h-[18px] w-full rounded-full bg-gray-300">
              <span
                className="absolute right-1/2 top-0 z-10 -ml-2 -mt-1 h-6 w-6 cursor-pointer rounded-full bg-[#FAC515] text-center text-white shadow"
                style={{ right: "30px" }}
              >
                4
              </span>
              <span
                className="absolute right-[50px] top-0 flex h-[18px] rounded-full bg-[#FAC515]"
                style={{ width: "70px" }}
              ></span>
            </div>
          </div>
          <div className="w-full">
            <p className="my-2 text-sm text-stone-500">
              Olumsuz <span className="text-[#3CCB7F]">(5)</span>
            </p>
            <div className="relative h-[18px] w-full rounded-full bg-gray-300">
              <span className="absolute right-0 top-0 z-10 -ml-2 -mt-1 h-6 w-6 cursor-pointer rounded-full bg-[#3CCB7F] text-center text-white shadow">
                5
              </span>
              <span className="absolute right-0 top-0 h-[18px] rounded-full bg-[#3CCB7F]"></span>
            </div>
          </div>
        </div>
      )}
      <p className="mb-0 mt-3 text-sm text-stone-500">
        {t("Ölçek tipini seçin")}
      </p>
    </div>
  );
};

export default NPSScoring;

import {
  Gauge,
  GaugeContainer,
  GaugeReferenceArc,
  GaugeValueArc,
  gaugeClasses,
  useGaugeState,
} from "@mui/x-charts/Gauge";
import { classNames } from "lib/externalJS";

function GaugePointer() {
  const { valueAngle, outerRadius, cx, cy } = useGaugeState();

  if (valueAngle === null) {
    // No value to display
    return null;
  }

  const target = {
    x: cx + outerRadius * Math.sin(valueAngle),
    y: cy - outerRadius * Math.cos(valueAngle),
  };
  return (
    <g>
      <circle cx={cx} cy={cy} r={5} fill="red" />
      <path
        d={`M ${cx} ${cy} L ${target.x} ${target.y}`}
        stroke="red"
        strokeWidth={3}
      />
    </g>
  );
}

export default function GaugeWithPointer({
  value,
  height = 200,
  width = 200,
  isFromZero,
  isNoPointer,
}: {
  value: number;
  height?: number;
  width?: number;
  isFromZero?: boolean;
  isNoPointer?: boolean;
}) {
  return (
    <>
      {isNoPointer && (
        <Gauge
          width={width}
          height={height}
          value={value}
          startAngle={-110}
          endAngle={110}
          sx={{
            [`& .${gaugeClasses.valueText}`]: {
              fontSize: 40,
              transform: "translate(0px, 0px)",
              fontWeight: 700,
            },
          }}
          text={({ value }: { value: any }) => `${value}%`}
        />
      )}

      {!isNoPointer && (
        <GaugeContainer
          width={width}
          height={height}
          startAngle={-110}
          endAngle={110}
          value={((value + 100) / 200) * 100}
        >
          <GaugeReferenceArc />
          <GaugeValueArc />
          <GaugePointer />
        </GaugeContainer>
      )}

      <div className="-mt-15 flex w-full items-center justify-between px-4 text-[40px] font-bold text-slate-700">
        <div
          className={classNames(
            "flex justify-center font-medium text-gray-500",
            isFromZero ? "w-7 text-base" : "w-5 text-[9px]",
          )}
        >
          {isFromZero ? 0 : -100}
        </div>

        <span className={classNames(isFromZero ? "opacity-0" : "")}>
          {value}
        </span>

        <div
          className={classNames(
            "flex justify-center font-medium text-gray-500",
            isFromZero ? "w-7 text-base" : "w-5 text-[9px]",
          )}
        >
          100
        </div>
      </div>
    </>
  );
}

import { elementChoicesInterface } from "../Interfaces/SurveyInterfaces";
import { v4 as uuidv4 } from "uuid";
import CryptoJS from "crypto-js";
import { SECRET_KEY_FOR_ASSESORS } from "../settings";
import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

type QuestionInfo = {
  dateTime: string;
  optionsDetail: string;
  questionNumber: number;
  questionParent: any;
  optionTitle: any;
  questionType: string | null;
};

type ItemType = {
  value: any[];
  otherValue: any;
  questionInfos: QuestionInfo;
  details: any;
};

export function randId(): string {
  return Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, "")
    .substr(2, 10);
}

export function debounce(func: (...args: any[]) => void, delay: number) {
  let debounceTimer: NodeJS.Timeout;
  return function (this: unknown, ...args: any[]) {
    const context = this;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
}

export const isEmptyObject = (obj: any) => Object.keys(obj).length === 0; // boş ise true dönüyor

export const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

export const testValidEmail = (email: string = ""): boolean =>
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  return `${month.toString().padStart(2, "0")}/${day
    .toString()
    .padStart(2, "0")}/${year}`;
};

export const generateBaseValues = (
  start: number,
  end: number,
): elementChoicesInterface[] => {
  return Array.from({ length: end - start + 1 }, (_, i) => ({
    _id: uuidv4(),
    content: (start + i).toString(),
  }));
};

export const getRange = (input: number): { start: number; end: number } => {
  switch (input) {
    case 1:
      return { start: 0, end: 10 };
    case 2:
      return { start: 1, end: 10 };
    case 3:
      return { start: 1, end: 7 };
    case 4:
      return { start: 1, end: 5 };
    case 5:
      return { start: 1, end: 3 };
    default:
      return { start: 1, end: 10 };
  }
};

export const encryptStrings = (
  str1: string,
  str2: string,
  str3: string,
  key = SECRET_KEY_FOR_ASSESORS
    ? SECRET_KEY_FOR_ASSESORS
    : "AFSDHGASFDHFSAHGDFSHAGFDHJGSAFDJHGSAFDSAJDGDGHJDHSHADSDGASJDAHDFASGHDFJGJSDHJSGA",
): string => {
  const combined = `${str1}|${str2}|${str3}`;
  const encrypted = CryptoJS.AES.encrypt(combined, key).toString();
  const urlSafe = encodeURIComponent(encrypted);
  return urlSafe;
};

export const decryptStrings = (
  encryptedString: string,
  key = SECRET_KEY_FOR_ASSESORS
    ? SECRET_KEY_FOR_ASSESORS
    : "AFSDHGASFDHFSAHGDFSHAGFDHJGSAFDJHGSAFDSAJDGDGHJDHSHADSDGASJDAHDFASGHDFJGJSDHJSGA",
): [string, string, string] | string => {
  try {
    const decodedString = decodeURIComponent(encryptedString);
    const bytes = CryptoJS.AES.decrypt(decodedString, key);
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    const parts = decrypted.split("|");

    if (parts.length !== 3) {
      return encryptedString;
    }
    return [parts[0], parts[1], parts[2]];
  } catch (error) {
    return encryptedString;
  }
};

export const removeDuplicates = (arr: any[]) => {
  const uniqueIds = new Set();
  return arr.filter((item) => {
    if (!uniqueIds.has(item._id)) {
      uniqueIds.add(item._id);
      return true;
    }
    return false;
  });
};

export function calculateTotalQuestions(data: any) {
  let totalQuestions = 0;

  data.pages.forEach((page: any) => {
    totalQuestions += page.elements.length;
  });

  return totalQuestions;
}

export function revertData(data: { [key: string]: ItemType } | undefined): {
  [key: string]: any;
} {
  const revertedObject: { [key: string]: any } = {};

  if (data) {
    Object.keys(data).forEach((key) => {
      const item = data[key];

      if (
        item.questionInfos &&
        item.questionInfos.questionType === "multiChoice"
      ) {
        revertedObject[key] = item;
      } else {
        if (Array.isArray(item.value) && item.value.length === 1) {
          let originalValue = item.value[0];

          //dataların hiçbirini number yapmamak için kestim belki sonra lazım olur
          /*        if (!isNaN(originalValue) && originalValue.trim() !== "") {
            originalValue = Number(originalValue);
          } */

          revertedObject[key] = {
            ...item,
            value: originalValue,
          };
        } else {
          revertedObject[key] = item;
        }
      }
    });
  }

  return revertedObject;
}

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

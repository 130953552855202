import { ChangeEvent, useEffect, useState } from "react";
import { Switch } from "@headlessui/react";
import { classNames } from "../../../lib/externalJS";
import { useTranslation } from "react-i18next";
import { useAtom, useAtomValue } from "jotai";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../atoms/surveyAtoms";

const FormSettingsInputMask = () => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const [localState, setLocalState] = useState({
    inputMask:
      stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]?.inputMask,
  });
  const { t } = useTranslation();

  const [switchState, setSwitchState] = useState(
    Boolean(
      stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]?.inputMask,
    ),
  );

  useEffect(() => {
    setStateSurveyJSON((prevState) => {
      let newState = { ...prevState };
      newState.pages![pageIndex]!.elements![elementIndex]!.inputMask =
        localState.inputMask;
      return newState;
    });
  }, [localState]);

  const handleMaskChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setLocalState((prevState) => {
      const newState = { ...prevState };
      newState.inputMask = newValue;
      return newState;
    });
  };

  return (
    <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
      <label
        className="block text-sm font-bold text-gray-500"
        htmlFor="enterTitle"
      >
        Giriş Maskesi
      </label>
      <div className="mb-2 mt-3 flex">
        <Switch
          id="flexSwitchCheckshuffleOptions"
          checked={switchState}
          onChange={(e) => {
            setSwitchState(e);
            !e &&
              setLocalState({
                inputMask: "",
              });
          }}
          className={classNames(
            switchState ? "bg-blue-600" : "bg-gray-200",
            "relative mr-2 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2",
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={classNames(
              switchState ? "translate-x-5" : "translate-x-0",
              "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
            )}
          />
        </Switch>
        <label
          className="text-gray-300"
          htmlFor="flexSwitchCheckshuffleOptions"
        >
          {switchState ? t("open") : t("close")}
        </label>
      </div>
      <div style={{ display: switchState ? "block" : "none" }}>
        <input
          id="enterTitle"
          className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow-sm focus:outline-none"
          type="text"
          value={localState.inputMask}
          onChange={(e) => handleMaskChange(e)}
          autoComplete={"off"}
        />
      </div>
      <p className="mb-0 mt-3 text-sm text-stone-500">
        Kullanıcıların belirlediğiniz formata uymalarını sağlayın.
        <br />
        <br />
        Harfleri maskelemek için @, rakamlar için # ve ikisini birden maskelemek
        için * sembollerini kullanın.
      </p>
    </div>
  );
};

export default FormSettingsInputMask;

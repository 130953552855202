import Deniz from "images/DenizOrnekAvatar.png";
import { useEffect, useRef, useState } from "react";
import PrimaryButton from "../../components/tailwindUi/PrimaryButton";
import Select, { SelectItemType } from "../../components/tailwindUi/Select";
import TextInput from "../../components/tailwindUi/TextInput";
import Trash from "../../images/avatar-trash.svg";
import { incomeSelectOptionsTRY, turkeyCities } from "../../lib/consts";

const fromServerName = "Berkay Bolat";

const fromServerLanguages = [
  { value: "en", text: "İngilizce" },
  { value: "tr", text: "Türkçe", selected: true },
];

const fromServerTimezones = [
  { value: "us", text: "United States", selected: true },
  { value: "tr", text: "Türkiye" },
];

interface FormValues {
  name: string;
  language?: string;
  timezone?: string;
}

export default function SettingsProfile({
  isAudience,
}: {
  isAudience?: boolean;
}) {
  const [initialFormValues, setInitialFormValues] = useState<FormValues>();
  const [formValues, setFormValues] = useState<FormValues>();

  const [languages, setLanguages] = useState<Array<SelectItemType>>();
  const [timezones, setTimezones] = useState<Array<SelectItemType>>();

  const inputFile = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // TODO: fetch user data
    const fetchedName = fromServerName;
    const fetchedLanguages = fromServerLanguages;
    const fetchedTimezones = fromServerTimezones;

    setLanguages(fetchedLanguages);
    setTimezones(fetchedTimezones);

    const temp = {
      name: fetchedName,
      language: fetchedLanguages.find((lang) => lang.selected)?.value,
      timezone: fetchedTimezones.find((tz) => tz.selected)?.value,
    };

    setInitialFormValues(temp);
    setFormValues(temp);
  }, []);

  const isFormChanged =
    JSON.stringify(formValues) !== JSON.stringify(initialFormValues);

  const isNameError = formValues?.name === "";

  const nameErrorMessage = isNameError ? "Name is required" : "";

  return (
    <SettingsCard title="Profil Ayarları">
      <div className="divide-y divide-white/5">
        <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 md:grid-cols-3">
          <form className="md:col-span-3">
            <div className="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
              <div className="col-span-full flex items-center gap-x-4">
                <img
                  src={Deniz}
                  alt=""
                  className="h-24 w-24 flex-none rounded-lg bg-gray-800 object-cover"
                />
                <div>
                  <div className="flex items-center gap-2">
                    <PrimaryButton
                      isSecondary
                      isMinWidth
                      onClick={() => inputFile.current?.click()}
                    >
                      Fotoğrafı Değiştir
                    </PrimaryButton>
                    <input
                      type="file"
                      id="avatarFile"
                      ref={inputFile}
                      accept=".jpg,.gif,.png,.jpeg"
                      className="hidden"
                      onChange={(event) => {
                        if (event.target.files) {
                          if (event.target.files?.[0]?.size <= 1024 * 1024) {
                            const file = event.target.files[0];
                            console.log(file);
                          } else {
                            console.log("File size is too big");
                          }
                        }
                      }}
                    />
                    <PrimaryButton isSecondary isGray isMinWidth>
                      <img
                        src={Trash}
                        alt="trash icon for profile avatar"
                        className="h-4 w-4"
                      />
                    </PrimaryButton>
                  </div>
                  <p className="mt-2 text-xs leading-5 text-gray-400">
                    JPG, GIF veya PNG. 1MB maksimum.
                  </p>
                </div>
              </div>

              <div className="sm:col-span-3">
                <TextInput
                  label="İsim"
                  name="name"
                  id="name"
                  defaultValue={formValues?.name}
                  errorMessage={nameErrorMessage}
                  onChange={(event) =>
                    setFormValues((prev) => ({
                      ...prev,
                      name: event.target.value,
                    }))
                  }
                />
              </div>

              <div className="hidden sm:col-span-3 sm:block"></div>

              <div className="sm:col-span-3">
                <Select
                  label="Dil"
                  id="language"
                  items={languages ?? []}
                  onChange={(selected) => console.log(selected)}
                />
              </div>

              <div className="sm:col-span-3">
                <Select
                  label="Saat Dilimi"
                  id="timezone"
                  items={timezones ?? []}
                  onChange={(selected) => console.log(selected)}
                />
              </div>

              {isAudience && (
                <>
                  <div className="sm:col-span-3">
                    <TextInput
                      label="Doğum Tarihi"
                      id="dateOfBirth"
                      name="dateOfBirth"
                      type="date"
                      errorMessage=""
                      onChange={(e) => console.log("Date:", e.target.value)}
                    />
                  </div>

                  <div className="sm:col-span-3">
                    <Select
                      label="Cinsiyet"
                      id="gender"
                      items={[
                        { text: "Male", value: "male" },
                        { text: "Female", value: "female" },
                      ]}
                      onChange={(selected) =>
                        console.log("Selected gender:", selected)
                      }
                      placeholder="Cinsiyet Seçin"
                    />
                  </div>

                  <div className="sm:col-span-3">
                    <Select
                      label="Şehir"
                      id="city"
                      items={turkeyCities.map((city) => ({
                        text: city,
                        value: city,
                      }))}
                      onChange={(selected) =>
                        console.log("Selected city:", selected)
                      }
                      placeholder="Şehir Seçin"
                    />
                  </div>

                  <div className="sm:col-span-3">
                    <Select
                      label="Hane Geliri"
                      id="houseHoldIncome"
                      items={incomeSelectOptionsTRY}
                      onChange={(selected) =>
                        console.log("Selected income:", selected)
                      }
                      placeholder="Hane Geliri Seçin"
                    />
                  </div>
                </>
              )}
            </div>

            <SettingsCardBtnContainer>
              <PrimaryButton isMinWidth isSecondary isGray>
                Vazgeç
              </PrimaryButton>
              <PrimaryButton disabled={!isFormChanged} isMinWidth>
                Değişiklikleri Kaydet
              </PrimaryButton>
            </SettingsCardBtnContainer>
          </form>
        </div>
      </div>
    </SettingsCard>
  );
}

export function SettingsCard({
  title,
  children,
  upRightSlot,
  isSubSetting = false,
  isError = false,
}: {
  title?: string;
  children: React.ReactNode;
  upRightSlot?: React.ReactNode;
  isSubSetting?: boolean;
  isError?: boolean;
}) {
  return (
    <div>
      <div className="flex items-end justify-between">
        {title && (
          <h3
            className={`${
              isSubSetting ? "pb-2 text-sm" : "pb-4 text-lg"
            } font-medium leading-6 text-gray-900`}
          >
            {title}
          </h3>
        )}
        {upRightSlot}
      </div>
      <div
        className={`border ${isError ? "border-red-500" : "border-gray-200"} ${
          isSubSetting ? "p-4" : "px-4 py-7 sm:px-7"
        } rounded-lg border-gray-200`}
      >
        {children}
      </div>
    </div>
  );
}

export function SettingsCardBtnContainer({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <div className="-mx-4 -mb-7 mt-7 flex justify-end gap-3 border-t p-4 sm:-mx-7 sm:px-7">
      {children}
    </div>
  );
}

import { ChangeEvent, useEffect, useState } from "react";
import { elementChoicesInterface } from "../../../Interfaces/SurveyInterfaces";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import { useAtom, useAtomValue } from "jotai";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../atoms/surveyAtoms";

const FormSettingsOptions = () => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const [inputText, setInputText] = useState<string>("");
  const [initialChoices, setInitialChoices] =
    useState<elementChoicesInterface[]>();

  const { t } = useTranslation();

  useEffect(() => {
    const initialChoices =
      stateSurveyJSON.pages![pageIndex].elements![elementIndex]?.choices;
    setInitialChoices(initialChoices);
    const initialItems: string[] = Array.isArray(initialChoices)
      ? initialChoices.map((choice, index) =>
          choice.title?.tr ? choice.title!.tr : ``,
        )
      : [];

    setInputText(initialItems.join("\n"));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(
      stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]?.choices,
    ),
  ]);

  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = event.target.value;
    setInputText(inputValue);

    const inputLines = inputValue.split("\n");
    const objectArray = inputLines.map((line) => ({
      _id: uuidv4(),
      title: { tr: line },
    }));
    if (pageIndex === undefined || elementIndex === undefined) {
      console.error("Invalid page index or element index.");
      return;
    }

    const objectArray2: elementChoicesInterface[] = [];
    objectArray.forEach((choice, index) => {
      const title = choice.title?.tr && choice.title.tr;
      objectArray2.push({
        _id: initialChoices?.[index]?._id
          ? initialChoices[index]._id
          : choice._id,
        title: { tr: title || "" },
        content: initialChoices?.[index]?.content
          ? initialChoices[index].content
          : undefined,
        info: initialChoices?.[index]?.info
          ? { tr: initialChoices[index].info!.tr }
          : undefined,
      });
    });

    const updatedState = { ...stateSurveyJSON };

    updatedState!.pages![pageIndex]!.elements![elementIndex].choices =
      objectArray2;

    setStateSurveyJSON(updatedState);
  };

  return (
    <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
      <label
        className="mb-2 block text-sm font-bold text-gray-500"
        htmlFor="enterQuestion"
      >
        {t("options")}
      </label>
      <textarea
        value={inputText}
        onChange={handleInputChange}
        placeholder="lütfen seçenek ekleyiniz"
        className="w-full resize-none rounded border border-gray-300 p-2"
        rows={inputText.split("\n").length + 1}
      />
      <p className="mb-0 mt-3 text-sm text-stone-500">
        {t("optionsDescription")}
      </p>
    </div>
  );
};

export default FormSettingsOptions;

import React, { Dispatch, SetStateAction } from "react";
import "./form.css";
import SurveyQuestionHandler from "./SurveyQuestionHandler";
import { useTranslation } from "react-i18next";
import { useAtom } from "jotai";
import { surveyAtom } from "../atoms/surveyAtoms";

type Props = {
  setShow: Dispatch<SetStateAction<boolean>>;
  show: boolean;
  setShowSettings: Dispatch<SetStateAction<boolean>>;
  setPageIndex: Dispatch<SetStateAction<any>>;
  containerRef: React.RefObject<HTMLDivElement>;
  pageIndex: number;
  setIndexToDelete: Dispatch<SetStateAction<number | undefined>>;
  placeholderProps: any;
};

const SurveyPart: React.FC<Props> = ({
  setShow,
  show,
  setShowSettings,
  setPageIndex,
  containerRef,
  pageIndex,
  setIndexToDelete,
  placeholderProps,
}) => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);

  const handleTitleChange = (e: React.FocusEvent<HTMLInputElement>) => {
    const pageTitle = e.target.value;
    if (stateSurveyJSON.title?.tr === pageTitle) return;
    setStateSurveyJSON((prev) => {
      const newState = { ...prev };
      newState.title = {
        tr: pageTitle,
      };
      return newState;
    });
  };

  const { t } = useTranslation();

  const handleDescriptiponChange = (e: React.FocusEvent<HTMLInputElement>) => {
    const pageDesc = e.target.value;
    if (stateSurveyJSON.description?.tr === pageDesc) return;
    setStateSurveyJSON((prev) => {
      const newState = { ...prev };
      newState.description = {
        tr: pageDesc,
      };
      return newState;
    });
  };

  const handleFileUpload = (event: any) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        const result = reader.result;
        if (result && typeof result === "string") {
          setStateSurveyJSON((prev) => {
            const newState = { ...prev };
            newState.logo64 = result;
            return newState;
          });
        }
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <div className="mb-5 box-border flex flex-col break-words rounded border bg-white shadow-sm">
        <div className="card-body flex-auto p-1">
          <div className="mb-2 text-center">
            <button
              className="text-dark mt-5 cursor-pointer rounded-md border-2 border-gray-300 px-3 py-2 text-center align-middle text-sm font-semibold hover:border-blue-500 hover:text-blue-500"
              onClick={() =>
                document.getElementById("upload-image-service")?.click()
              }
            >
              {t("addLogo")}
            </button>
            {/* buraya buton stilini gömmek için yaptım değiştirebilirim */}
            <input
              id="upload-image-service"
              hidden
              accept="image/*"
              type="file"
              onChange={handleFileUpload}
            />
          </div>
          {stateSurveyJSON?.logo64 && (
            <div className="flex items-center justify-center">
              <div className="w-full max-w-[100px]">
                <img src={stateSurveyJSON?.logo64} alt="Logo" />
              </div>
            </div>
          )}
          <div className="w-full flex-wrap items-stretch pl-[39px] pr-[43px]">
            <input
              onChange={handleTitleChange}
              value={
                stateSurveyJSON?.title?.tr
                  ? stateSurveyJSON.title.tr
                  : t("newForm")
              }
              className="w-full border-none px-2 text-xl font-bold text-gray-500 outline-none hover:outline-dashed hover:outline-1 hover:outline-gray-400 focus:!outline-none focus:outline-dashed"
            />
          </div>
          <div className="mb-5 mt-1 flex w-full flex-wrap items-stretch pl-[39px] pr-[43px]">
            <input
              onChange={handleDescriptiponChange}
              value={
                stateSurveyJSON?.description?.tr
                  ? stateSurveyJSON.description.tr
                  : t("pleaseFillCarefully")
              }
              className="w-full border-none px-2 text-base text-gray-500 outline-none hover:outline-dashed hover:outline-1 hover:outline-gray-400 focus:!outline-none focus:outline-dashed"
            />
          </div>
        </div>
      </div>
      <SurveyQuestionHandler
        setShow={setShow}
        setShowSettings={setShowSettings}
        show={show}
        setPageIndex={setPageIndex}
        containerRef={containerRef}
        pageIndex={pageIndex}
        setIndexToDelete={setIndexToDelete}
        placeholderProps={placeholderProps}
      />
      <div className="mb-4 box-border flex flex-col break-words rounded border bg-white p-4 shadow-sm">
        <div className="flex-auto p-1">
          <div className="mb-2 text-center">
            <button className="fw-semibold text-dark cursor-pointer rounded-md border-2 border-gray-300 px-3 py-2 text-center align-middle text-sm font-semibold hover:border-blue-500 hover:text-blue-500">
              {t("addThankYouNote")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SurveyPart;

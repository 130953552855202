import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";
import { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import RichEditor from "../../../lib/RichEditor";
import { sharedUserMailsInterface } from "../../../Interfaces/SurveyInterfaces";
import axios from "axios";
import { toast } from "react-toastify";
import { API_DOMAIN } from "../../../settings";
import { useAtom } from "jotai";
import { surveyAtom } from "../../../components/atoms/surveyAtoms";

type Props = {
  setMailState: Dispatch<SetStateAction<number>>;
  selectedSharedMail?: sharedUserMailsInterface;
};

const NewReciever: React.FC<Props> = ({ setMailState, selectedSharedMail }) => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);

  const [addUsers, setUsers] = useState<number>(1);
  const [mailValues, setMailValues] = useState<sharedUserMailsInterface>(
    selectedSharedMail ?? {
      fromName: "",
      toMail: [],
      subject: "Yeni Bir Anket",
      content: `<p style="text-align: left;">Değerli kullanıcımız. Tarafınıza bir anket gönderilmiştir.</p> <p style="text-align: left;"> <strong>Ankete gitmek için tıklayınız: </strong><em><a href="${
        API_DOMAIN + "/surveyReply/" + stateSurveyJSON._id
      }">Tıkla&nbsp;&nbsp;</a></em></p> <p style="text-align: left;"><em><br></em></p><p style="text-align: left;"><em><br></em></p>`,
      sendDate: new Date(),
    },
  );

  const updateParticipantValue = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setMailValues((prevValues: any) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const sendMail = async () => {
    try {
      const apiUrl = "http://3.144.40.16:8080/Survey/SendMail";

      await axios.post(apiUrl, mailValues);

      toast.success("E-postalar başarıyla gönderildi");
      setStateSurveyJSON((prevValue) => {
        const newSurvey = { ...prevValue };
        if (newSurvey.sharedUserMails && mailValues) {
          const splitObjects: sharedUserMailsInterface[] =
            mailValues.toMail.map((toMailAddress: string) => ({
              fromName: mailValues.fromName,
              ccMail: mailValues.ccMail,
              toMail: [toMailAddress],
              subject: mailValues.subject,
              content: mailValues.content,
              sendDate: new Date(),
            }));

          newSurvey.sharedUserMails = [
            ...newSurvey.sharedUserMails,
            ...splitObjects,
          ];
        } else if (mailValues) {
          const splitObjects: sharedUserMailsInterface[] =
            mailValues.toMail.map((toMailAddress: string) => ({
              fromName: mailValues.fromName,
              ccMail: mailValues.ccMail,
              toMail: [toMailAddress],
              subject: mailValues.subject,
              content: mailValues.content,
              sendDate: new Date(),
            }));

          newSurvey.sharedUserMails = splitObjects;
        }

        return newSurvey;
      });
      setMailValues({
        fromName: "",
        ccMail: "",
        toMail: [],
        subject: "Yeni Bir Anket",
        content: `<p style="text-align: left;">Değerli kullanıcımız. Tarafınıza bir anket gönderilmiştir.</p> <p style="text-align: left;"> <strong>Ankete gitmek için tıklayınız: </strong><em><a href="${
          API_DOMAIN + "/surveyReply/" + stateSurveyJSON._id
        }">Tıkla&nbsp;&nbsp;</a></em></p> <p style="text-align: left;"><em><br></em></p><p style="text-align: left;"><em><br></em></p>`,
        sendDate: new Date(),
      });
      setMailState(1);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <div className="mx-auto mt-5 flex w-[720px]">
        <h5 className="mx-auto w-[720px] text-start">Yeni Alıcı Ekle</h5>
        <button className="w-[111px]">
          <span
            className="flex items-center justify-center font-semibold"
            onClick={() => setMailState(1)}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mr-2"
            >
              <path
                d="M15.8346 9.99984H4.16797M4.16797 9.99984L10.0013 15.8332M4.16797 9.99984L10.0013 4.1665"
                stroke="#475467"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Geri Dön
          </span>
        </button>
      </div>
      <div className="mx-auto w-[720px] rounded-xl border bg-gray-100">
        {addUsers === 1 ? (
          <>
            <div className="flex h-[100px] w-full items-center justify-center">
              <div className="w-[280px] py-6">
                <div className="flex">
                  <div
                    className="w-1/2 cursor-pointer"
                    onClick={() => setUsers(1)}
                  >
                    <div className="relative mb-2">
                      <div className="mx-auto flex h-8 w-8 items-center rounded-full bg-blue-200 text-lg text-white">
                        <span className="flex w-full items-center justify-center text-white">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="0.75"
                              y="0.75"
                              width="22.5"
                              height="22.5"
                              rx="11.25"
                              fill="#2970FF"
                            />
                            <rect
                              x="0.75"
                              y="0.75"
                              width="22.5"
                              height="22.5"
                              rx="11.25"
                              stroke="#2970FF"
                              strokeWidth="1.5"
                            />
                            <circle cx="12" cy="12" r="4" fill="white" />
                          </svg>
                        </span>
                      </div>
                    </div>

                    <div className="text-center text-xs md:text-base">
                      Alıcılar
                    </div>
                  </div>

                  <div
                    className="w-1/2 cursor-pointer"
                    onClick={() => setUsers(2)}
                  >
                    <div className="relative mb-2">
                      <div
                        className="align-center absolute flex content-center items-center align-middle"
                        style={{
                          width: "calc(100% - 2rem)",
                          top: "65%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        <div className="align-center w-full flex-1 items-center rounded bg-gray-200 align-middle">
                          <div
                            className="w-0 rounded bg-gray-200 py-[2px]"
                            style={{ width: "100%" }}
                          ></div>
                        </div>
                      </div>

                      <div className="mx-auto flex h-6 w-6 items-center rounded-full bg-gray-200 text-lg text-white">
                        <span className="w-full text-center text-white">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="0.75"
                              y="0.75"
                              width="22.5"
                              height="22.5"
                              rx="11.25"
                              stroke="#EAECF0"
                              strokeWidth="1.5"
                            />
                            <circle cx="12" cy="12" r="4" fill="#D0D5DD" />
                          </svg>
                        </span>
                      </div>
                    </div>

                    <div className="text-center text-xs md:text-base">
                      E-posta
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col bg-white p-6">
              <label className="mb-[10px] text-start text-base">
                Gönderici Adı
              </label>
              <input
                type="text"
                key={"fromName"}
                onBlur={updateParticipantValue}
                defaultValue={mailValues?.fromName}
                className="h-10 rounded border border-gray-300 px-2 placeholder:text-gray-300"
                name="fromName"
              />

              <label className="mb-[10px] mt-6 text-start">
                Bildirim Adresi
              </label>
              <input
                className="h-10 rounded border border-gray-300 px-2 placeholder:text-gray-300"
                type="email"
                onBlur={updateParticipantValue}
                defaultValue={mailValues?.ccMail}
                name="ccMail"
              />

              <label className="mb-[10px] mt-6 text-start">Alıcı Adresi</label>
              <ReactMultiEmail
                placeholder="E-posta adresi giriniz"
                emails={mailValues?.toMail ? mailValues.toMail : []}
                onChange={(_emails: string[]) => {
                  setMailValues((prevValues: any) => ({
                    ...prevValues,
                    toMail: _emails,
                  }));
                }}
                getLabel={(email, index, removeEmail) => {
                  return (
                    <div data-tag key={index}>
                      <div data-tag-item>{email}</div>
                      <span data-tag-handle onClick={() => removeEmail(index)}>
                        ×
                      </span>
                    </div>
                  );
                }}
              />
            </div>

            <div className="rounded-b-xl bg-white p-3 text-end">
              <button
                className="h-9 w-[100px] rounded-lg bg-[#2970ff] px-3 py-2 text-center align-middle text-sm text-white hover:bg-blue-700"
                onClick={() => setUsers(2)}
              >
                İleri
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="flex h-[100px] w-full items-center justify-center">
              <div className="w-[280px] py-6">
                <div className="flex">
                  <div
                    className="w-1/2 cursor-pointer"
                    onClick={() => setUsers(1)}
                  >
                    <div className="relative mb-2">
                      <div className="mx-auto flex h-8 w-8 items-center rounded-full bg-blue-200 text-lg text-white">
                        <span className="flex w-full items-center justify-center text-white">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="0.75"
                              y="0.75"
                              width="22.5"
                              height="22.5"
                              rx="11.25"
                              fill="#2970FF"
                            />
                            <rect
                              x="0.75"
                              y="0.75"
                              width="22.5"
                              height="22.5"
                              rx="11.25"
                              stroke="#2970FF"
                              strokeWidth="1.5"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M17.098 7.39016L9.93797 14.3002L8.03797 12.2702C7.68797 11.9402 7.13797 11.9202 6.73797 12.2002C6.34797 12.4902 6.23797 13.0002 6.47797 13.4102L8.72797 17.0702C8.94797 17.4102 9.32797 17.6202 9.75797 17.6202C10.168 17.6202 10.558 17.4102 10.778 17.0702C11.138 16.6002 18.008 8.41016 18.008 8.41016C18.908 7.49016 17.818 6.68016 17.098 7.38016V7.39016Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>

                    <div className="text-center text-xs md:text-base">
                      Alıcılar
                    </div>
                  </div>

                  <div
                    className="w-1/2 cursor-pointer"
                    onClick={() => setUsers(2)}
                  >
                    <div className="relative mb-2">
                      <div
                        className="align-center absolute flex content-center items-center align-middle"
                        style={{
                          width: "calc(100% - 2rem)",
                          top: "30%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        <div className="align-center w-full flex-1 items-center rounded bg-gray-200 align-middle">
                          <div
                            className="w-0 rounded bg-blue-200 py-[2px]"
                            style={{ width: "100%" }}
                          ></div>
                        </div>
                      </div>

                      <div className="mx-auto flex h-8 w-8 items-center rounded-full bg-blue-200 text-lg text-white">
                        <span className="flex w-full items-center justify-center text-white">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="0.75"
                              y="0.75"
                              width="22.5"
                              height="22.5"
                              rx="11.25"
                              fill="#2970FF"
                            />
                            <rect
                              x="0.75"
                              y="0.75"
                              width="22.5"
                              height="22.5"
                              rx="11.25"
                              stroke="#2970FF"
                              strokeWidth="1.5"
                            />
                            <circle cx="12" cy="12" r="4" fill="white" />
                          </svg>
                        </span>
                      </div>

                      <div className="text-center text-xs md:text-base">
                        E-posta
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col bg-white p-7">
              <label className="ml-2 text-start">Konu</label>
              <input
                type="text"
                onBlur={updateParticipantValue}
                key={"subject"}
                defaultValue={mailValues?.subject}
                name="subject"
                className="mx-2 h-10 rounded border border-gray-300 px-2 placeholder:text-gray-300"
              />
              <label className="ml-2 mt-4 text-start">E-posta İçeriği</label>
              <div className="flex">
                <RichEditor
                  placeholder={`E-posta içeriğini buraya yazınız...`}
                  on={true}
                  value={mailValues?.content}
                  onChange={(e: any) =>
                    setMailValues((prevValues: any) => ({
                      ...prevValues,
                      content: e,
                    }))
                  }
                />
                {/*    <LexicalTextBox
                Placeholder={<div> buraya text girebilirsinizs</div>}
              /> */}
              </div>
            </div>
            <hr />
            <div className="flex justify-between rounded-b-xl bg-white p-3">
              <button
                className="h-9 w-[100px] cursor-pointer rounded-md border-2 border-gray-300 px-3 py-2 text-center align-middle text-sm"
                onClick={() => setUsers(1)}
              >
                Geri
              </button>
              <button
                className="h-9 w-[100px] rounded-lg bg-[#2970ff] px-3 py-2 text-center align-middle text-sm text-white hover:bg-blue-700"
                onClick={sendMail}
              >
                Gönder
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default NewReciever;

import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAtom, useAtomValue } from "jotai";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../atoms/surveyAtoms";

const FormSettingsDescription = () => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const [localState, setLocalState] = useState({
    description:
      stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]?.description,
  });

  const { t } = useTranslation();

  useEffect(() => {
    setStateSurveyJSON((prevState) => {
      let newState = { ...prevState };
      if (
        newState.pages &&
        newState.pages[pageIndex] &&
        newState.pages[pageIndex].elements &&
        newState.pages[pageIndex].elements?.[elementIndex]
      ) {
        newState.pages[pageIndex].elements![elementIndex].description =
          localState.description;
      }
      return newState;
    });
  }, [localState]);

  useEffect(() => {
    setLocalState(() => {
      let localStateObject = {
        description:
          stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
            ?.description,
      };
      return localStateObject;
    });
  }, [
    stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]?.description,
  ]);

  const handleDescChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setLocalState((prevState) => {
      const newState = { ...prevState };
      newState.description = { tr: newValue };
      return newState;
    });
  };

  return (
    <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
      <label
        className="block text-sm font-bold text-gray-500"
        htmlFor="enterTitle"
      >
        {t("descriptionTicket")}
      </label>
      <input
        id="enterTitle"
        autoComplete="off"
        className="focus:shadow-outline mt-3 w-full appearance-none rounded-lg border border-gray-300 px-3 py-2 leading-tight text-gray-700 focus:outline-none"
        type="text"
        placeholder={t("addDescription")}
        value={localState.description?.tr}
        onChange={(e) => handleDescChange(e)}
      />
    </div>
  );
};

export default FormSettingsDescription;

import { useAtom, useAtomValue } from "jotai";
import { useState } from "react";
import DatePicker from "react-datepicker";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../../atoms/surveyAtoms";

const DateDefault = () => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const [specialValue, setSpecialValue] = useState<number>(1);

  const handleButtonClick = (buttonName: any) => {
    setStateSurveyJSON((prevState) => {
      let newState = { ...prevState };
      if (
        newState.pages?.[pageIndex].elements?.[elementIndex].selectedDate &&
        buttonName
      ) {
        let previousDate = new Date(
          newState.pages![pageIndex].elements![elementIndex].selectedDate ||
            new Date(),
        );
        previousDate.setFullYear(buttonName.getFullYear());
        previousDate.setMonth(buttonName.getMonth());
        previousDate.setDate(buttonName.getDate());

        newState.pages![pageIndex].elements![elementIndex].selectedDate =
          previousDate;
      } else {
        newState.pages![pageIndex].elements![elementIndex].selectedDate =
          buttonName;
      }

      return newState;
    });
  };
  return (
    <div className="border-b-2 px-3 py-3">
      <label className="mb-2 block text-sm font-bold text-gray-500">
        Standart Tarih
      </label>
      <span className="isolate mt-[12px] inline-flex rounded-md shadow-sm">
        <button
          type="button"
          onClick={() => {
            setSpecialValue(1);
            handleButtonClick(undefined);
          }}
          className={`relative inline-flex items-center rounded-l-md ${
            specialValue === 1
              ? "bg-[#D1E0FF] font-semibold text-gray-900"
              : "button-group-ticket-position bg-white font-medium text-gray-500 hover:bg-gray-300"
          } px-3 py-2 text-sm ring-1 ring-inset ring-gray-300 focus:z-10`}
        >
          Yok
        </button>
        <button
          type="button"
          onClick={() => {
            setSpecialValue(2);
            handleButtonClick(new Date());
          }}
          className={`relative inline-flex items-center px-3 py-2 text-sm ${
            specialValue === 2
              ? "bg-[#D1E0FF] font-semibold text-gray-900"
              : "button-group-ticket-position bg-white font-medium text-gray-500 hover:bg-gray-300"
          } ring-1 ring-inset ring-gray-300 focus:z-10`}
        >
          Şu anki
        </button>
        <button
          type="button"
          onClick={() => {
            setSpecialValue(3);
          }}
          className={`-ml-px relative inline-flex items-center rounded-r-md ${
            specialValue === 3
              ? "bg-[#D1E0FF] font-semibold text-gray-900"
              : "button-group-ticket-position bg-white font-medium text-gray-500"
          } px-3 py-2 text-sm ring-1 ring-inset ring-gray-300 focus:z-10`}
        >
          Özel
        </button>
      </span>
      {specialValue === 3 && (
        <DatePicker
          onChange={(e: any) => {
            handleButtonClick(e);
          }}
          selected={
            stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
              .selectedDate || new Date()
          }
          timeFormat={
            stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
              .timeFormat
              ? "p"
              : "HH:mm"
          }
        />
      )}
      <p className="mb-0 mt-3 text-sm text-stone-500">
        Güncel ya da özel bir tarih ile önceden doldurun.
      </p>
    </div>
  );
};

export default DateDefault;

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Switch } from "@headlessui/react";
import { classNames } from "../../../lib/externalJS";
import { useAtom, useAtomValue } from "jotai";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../atoms/surveyAtoms";

const FormSettingsOptionTipsForRate = () => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const [localState, setLocalState] = useState({
    rateValues:
      stateSurveyJSON.pages![pageIndex!]!.elements![elementIndex!]?.rateValues,
  });

  const [rateStates, setRateStates] = useState(
    stateSurveyJSON.pages?.[pageIndex]?.elements?.[
      elementIndex
    ]?.rateValues?.map((rate) => rate.info?.tr),
  );

  const [switchState, setSwitchState] = useState(
    Boolean(
      stateSurveyJSON.pages?.[pageIndex!]?.elements?.[
        elementIndex!
      ]?.rateValues?.find((rate) => rate.info?.tr),
    ),
  );

  const { t } = useTranslation();

  const handleInfoChange = (index: number, newContent: any) => {
    const updatedRates = [...(localState.rateValues ?? [])];
    updatedRates[index] = { ...updatedRates[index], info: { tr: newContent } };
    if (rateStates) {
      setRateStates([
        ...rateStates.slice(0, index),
        newContent,
        ...rateStates.slice(index + 1),
      ]);
    }
    setLocalState({ ...localState, rateValues: updatedRates });
  };

  const updateAllInfoContentToUndefined = (rates: any[]) => {
    const updatedRateStates = rateStates?.map(() => undefined) || [];
    setRateStates(updatedRateStates);
    const updatedRates = rates.map((rate) => {
      return {
        ...rate,
        info: undefined,
      };
    });
    setLocalState({ ...localState, rateValues: updatedRates });
  };

  useEffect(() => {
    setStateSurveyJSON((prevState) => {
      let newState = { ...prevState };
      newState.pages![pageIndex]!.elements![elementIndex]!.rateValues =
        localState.rateValues;
      return newState;
    });
  }, [localState]);

  useEffect(() => {
    setLocalState({
      rateValues:
        stateSurveyJSON.pages![pageIndex!]!.elements![elementIndex!]
          ?.rateValues,
    });
    setRateStates(
      stateSurveyJSON.pages?.[pageIndex!]?.elements?.[
        elementIndex!
      ]?.rateValues?.map((choice) => choice.info?.tr),
    );
  }, [stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]?.rateValues]);

  return (
    <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
      <label className="mb-2 block text-sm font-bold text-gray-500">
        {t("hintOfOptions")}
      </label>

      <div className="mb-2 mt-3 flex">
        <Switch
          id="flexSwitchCheckHintOptions"
          checked={switchState}
          onChange={(e) => {
            setSwitchState(e);
            !e && updateAllInfoContentToUndefined(localState.rateValues ?? []);
          }}
          className={classNames(
            switchState ? "bg-blue-600" : "bg-gray-200",
            "relative mr-2 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2",
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={classNames(
              switchState ? "translate-x-5" : "translate-x-0",
              "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
            )}
          />
        </Switch>
        <label className="text-gray-300" htmlFor="flexSwitchCheckHintOptions">
          {switchState ? t("open") : t("close")}
        </label>
      </div>
      <div style={{ display: switchState ? "block" : "none" }}>
        {rateStates?.map((info, index) => (
          <div className={`relative ${index === 0 ? "mt-5" : "mt-4"}`}>
            <label
              htmlFor={index.toString()}
              className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-500"
            >
              {index + 1}. {t("option")}
            </label>
            <input
              type="text"
              id={index.toString()}
              autoComplete="off"
              className="py-1.5 block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              placeholder="İpucu metni giriniz"
              defaultValue={info ? info : ""}
              onBlur={(e) => {
                handleInfoChange(index, e.target.value);
              }}
            />
          </div>
        ))}
      </div>
      <p className="mb-0 mt-3 text-sm text-stone-500">
        {t("hintOfOptionsDescription")}
      </p>
    </div>
  );
};

export default FormSettingsOptionTipsForRate;

import Select from "react-select";
import { useEffect, useState } from "react";
import { useAtom, useAtomValue } from "jotai";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../atoms/surveyAtoms";

const FormSettingsInputVerify = () => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const [localState, setLocalState] = useState({
    verifyInputType:
      stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
        ?.verifyInputType,
  });

  useEffect(() => {
    setStateSurveyJSON((prevState) => {
      let newState = { ...prevState };

      newState.pages![pageIndex].elements![elementIndex].verifyInputType =
        localState.verifyInputType;

      return newState;
    });
  }, [localState]);

  const handleVerifyChange = (event: any) => {
    const newValue = event?.value;
    setLocalState((prevState) => {
      let newState = { ...prevState };
      newState.verifyInputType = newValue;
      return newState;
    });
  };

  return (
    <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
      <label
        className="mb-2 block text-sm font-bold text-gray-500"
        htmlFor="enterQuestion"
      >
        Doğrulama
      </label>

      <Select
        className="basic-single mb-3"
        placeholder="Seçim Yok"
        isSearchable={false}
        options={[
          { value: "undefined", label: "Yok" },
          { value: "Alfabetik", label: "Alfabetik" },
          { value: "Alfanumerik", label: "Alfanumerik" },
          { value: "Kiril", label: "Kiril" },
          { value: "Email", label: "E-posta" },
          { value: "Sayısal", label: "Sayısal" },
        ]}
        onChange={(e) => handleVerifyChange(e)}
        defaultValue={[{ value: "undefined", label: "Yok" }]}
      />

      <p className="mb-0 mt-3 text-sm text-stone-500">
        Girişlerin belirli bir formatla eşleşmesini zorunlu kıl
      </p>
    </div>
  );
};

export default FormSettingsInputVerify;

import axiosInstance from "../api";

export const getAssesorIds = async () => {
  return (await axiosInstance.get<any[]>("Assessor/GetAll")).data;
};

export const getAssesor = async (id: string) => {
  return (
    await axiosInstance.get<any>(`Assessor/GetById`, {
      params: { assessorId: id },
    })
  ).data;
};

export const createAssesor = async (data: any) => {
  await axiosInstance.post("/Assessor/Add", data);
};

export const updateAssesor = async (data: any) => {
  await axiosInstance.put(`/Assessor/Update`, data, {
    params: { assessorId: data._id },
  });
};

export const deleteAssesor = async (id: string) => {
  await axiosInstance.delete(`Assessor/Delete`, {
    params: { assessorId: id },
  });
};

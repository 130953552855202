import { Dialog as HeadlessDialog, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import TalentCookerLogo from "components/TalentCookerLogo";
import {
  selectedNavigationNameAtom,
  selectedUserAtom,
} from "components/atoms/dashboardAtoms";
import { HeaderRightSide } from "components/staticDesign/Header";
import Dialog from "components/tailwindUi/Dialog";
import AdminActivityIcon from "images/sidebarIcons/AdminActivity";
import AssessmentCenterIcon from "images/sidebarIcons/AssessmentCenter";
import EmployeeExperienceIcon from "images/sidebarIcons/EmployeeExperience";
import EmployeesIcon from "images/sidebarIcons/Employees";
import PanelIcon from "images/sidebarIcons/Panel";
import PerformanceIcon from "images/sidebarIcons/Performance";
import PulseIcon from "images/sidebarIcons/Pulse";
import SkillManagementIcon from "images/sidebarIcons/SkillManagement";
import { useAtom } from "jotai";
import { classNames } from "lib/externalJS";
import AdminActivity from "pages/dashboard2/AdminActivity/AdminActivity";
import DashboardCards from "pages/dashboard2/DashboardCards";
import EmployeeExperience from "pages/dashboard2/EmployeeExperience/EmployeeExperience";
import EmployeeProfile from "pages/dashboard2/EmployeeProfile/EmployeeProfile";
import Workers from "pages/dashboard2/Employees/Workers";
import Panel from "pages/dashboard2/Panel/Panel";
import Performance from "pages/dashboard2/Performance/Performance";
import PricingDialog from "pages/dashboard2/PricingDialog";
import Pulse from "pages/dashboard2/Pulse/Pulse";
import Settings from "pages/dashboard2/Settings";
import TalentManager from "pages/dashboard2/TalentManager/TalentManager";
import { Fragment, useEffect, useMemo, useState } from "react";

export const DASHBOARD_LEFT_NAVIGATION = Object.freeze([
  { name: "Panelim", icon: PanelIcon },
  { name: "Performans", icon: PerformanceIcon },
  { name: "Yönetici Etkinliği", icon: AdminActivityIcon },
  { name: "Çalışan Deneyimi", icon: EmployeeExperienceIcon },
  { name: "Nabız", icon: PulseIcon },
  { name: "Yetenek Yönetimi", icon: SkillManagementIcon, isFirstInGroup: true },
  { name: "Çalışanlar", icon: EmployeesIcon },
  {
    name: "Değerlendirme Merkezi",
    icon: AssessmentCenterIcon,
    isFirstInGroup: true,
  },
]);

export type upgradeModalValueType = "pricing" | "";

export type tierType = "Free" | "Starter" | "Pro" | "Business";

export default function Dashboard2() {
  const [selectedNavigationName, setSelectedNavigationName] = useAtom(
    selectedNavigationNameAtom,
  );
  const [selectedUser, setSelectedUser] = useAtom(selectedUserAtom);

  const [hoveredNavigationName, setHoveredNavigationName] =
    useState<string>("");

  useEffect(() => {
    setSelectedNavigationName(DASHBOARD_LEFT_NAVIGATION[0].name);
  }, [setSelectedNavigationName]);

  const tier = "Starter";

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [modalValue, setModalValue] = useState<upgradeModalValueType>("");

  const getModalParams = () => {
    switch (modalValue) {
      case "pricing":
        return {
          maxWidth: 1000,
          ModalChildren: (
            <PricingDialog setModalValue={setModalValue} tier={tier} />
          ),
          isFullHeight: true,
        };
      default:
        return {
          maxWidth: 400,
          ModalChildren: <></>,
        };
    }
  };

  const { maxWidth, ModalChildren, isFullHeight } = getModalParams() as {
    maxWidth: number;
    ModalChildren: JSX.Element;
    isFullHeight?: boolean;
  };

  const NavigationButton = (item: {
    name: string;
    icon: React.ElementType;
  }) => {
    const isActive =
      selectedUser === undefined && selectedNavigationName === item.name;

    return (
      <button
        onClick={() => {
          setSelectedNavigationName(item.name);
          setSelectedUser(undefined);
          setSidebarOpen(false);
        }}
        onMouseEnter={() => setHoveredNavigationName(item.name)}
        onMouseLeave={() => setHoveredNavigationName("")}
        className={classNames(
          isActive
            ? "bg-blue-50 text-blue-600"
            : "text-gray-700 hover:text-blue-600",
          "group flex w-full gap-x-3 rounded-md p-2 text-sm font-semibold leading-6",
        )}
      >
        <item.icon
          className={classNames(
            isActive
              ? "text-blue-600"
              : "text-gray-400 group-hover:text-blue-600",
            "h-6 w-6 shrink-0",
          )}
          aria-hidden="true"
          isActive={isActive || hoveredNavigationName === item.name}
        />
        {item.name}
      </button>
    );
  };

  const Children = useMemo(() => {
    if (selectedUser) {
      return <EmployeeProfile />;
    } else {
      switch (selectedNavigationName) {
        case "Panelim":
          return <Panel />;
        case "Performans":
          return <Performance />;
        case "Yönetici Etkinliği":
          return <AdminActivity />;
        case "Çalışan Deneyimi":
          return <EmployeeExperience />;
        case "Nabız":
          return <Pulse />;
        case "Yetenek Yönetimi":
          return <TalentManager />;
        case "Çalışanlar":
          return <Workers />;
        case "Değerlendirme Merkezi":
          return <DashboardCards />;
        case "Settings":
          return <Settings />;
        // case "debug":
        //   return <CsvParser />;
        default:
          return <div>Not found</div>;
      }
    }
  }, [selectedNavigationName, selectedUser]);

  return (
    <>
      <div className="bg-gray-50">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <HeadlessDialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <HeadlessDialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                    <div className="flex h-16 shrink-0 items-center">
                      <TalentCookerLogo width={146} />
                    </div>

                    {/* Sidebar for mobile  */}
                    <nav className="flex flex-1 flex-col">
                      <ul className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul className="-mx-2 space-y-1">
                            {DASHBOARD_LEFT_NAVIGATION.map((item) => (
                              <li key={item.name}>
                                <NavigationButton {...item} />
                              </li>
                            ))}
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </HeadlessDialog.Panel>
              </Transition.Child>
            </div>
          </HeadlessDialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-10 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-gray-50 px-6 pb-4">
            <div className="flex h-16 shrink-0 items-center">
              <TalentCookerLogo width={146} />
            </div>

            <nav className="flex flex-1 flex-col">
              <ul className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul className="-mx-2 space-y-1">
                    {DASHBOARD_LEFT_NAVIGATION.map((item) => (
                      <>
                        {item.isFirstInGroup && <hr />}
                        <li key={item.name}>
                          <NavigationButton {...item} />
                        </li>
                      </>
                    ))}
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:pl-72">
          <div className="sticky top-0 z-[12] flex h-16 shrink-0 items-center gap-x-4 bg-gray-50 px-4 sm:gap-x-6 sm:px-6 lg:px-8">
            <button
              type="button"
              className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>

            {/* Separator */}
            <div
              className="w-px h-6 bg-gray-200 lg:hidden"
              aria-hidden="true"
            />

            <div className="flex flex-1 justify-end gap-x-3 self-stretch">
              <HeaderRightSide />
            </div>
          </div>

          <main className="pb-10 pt-5">
            <div className="flex justify-center">
              <div className="w-[1000px]">{Children}</div>
            </div>
          </main>
        </div>
      </div>
      <Dialog
        isOpen={Boolean(modalValue)}
        onClose={() => setModalValue("")}
        maxWidth={maxWidth}
        isFullHeight={isFullHeight}
      >
        {ModalChildren}
      </Dialog>
    </>
  );
}

export function tierToColor(status: tierType) {
  switch (status) {
    case "Free":
      return "gray";
    case "Starter":
      return "green";
    case "Pro":
      return "blue";
    case "Business":
      return "yellow";
    default:
      return "gray";
  }
}

export function Progress({
  fontSize = "xs",
  isLabelsHidden,
  color,
  value = "50",
}: {
  fontSize?: "xs" | "sm";
  isLabelsHidden?: boolean;
  color?: string;
  value?: string;
}) {
  return (
    <div className="flex flex-col gap-2">
      {!isLabelsHidden && (
        <div className="flex justify-between">
          <div
            className={`text-indigo-950 text-${fontSize} font-medium leading-3`}
          >
            Formlar
          </div>
          <div
            className={`text-indigo-950 text-${fontSize} font-medium leading-3`}
          >
            5<span className="font-normal text-slate-500">/5 kullanıldı</span>
          </div>
        </div>
      )}
      <progress
        className={classNames(
          color === undefined
            ? "[&::-moz-progress-bar]:bg-red-600 [&::-webkit-progress-value]:bg-red-600"
            : "",
          color === "red"
            ? "[&::-moz-progress-bar]:bg-red-500 [&::-webkit-progress-value]:bg-red-500"
            : "",
          color === "amber"
            ? "[&::-moz-progress-bar]:bg-yellow-400 [&::-webkit-progress-value]:bg-yellow-400"
            : "",
          color === "green"
            ? "[&::-moz-progress-bar]:bg-green-400 [&::-webkit-progress-value]:bg-green-400"
            : "",
          color === "blue"
            ? "[&::-moz-progress-bar]:bg-blue-600 [&::-webkit-progress-value]:bg-blue-600"
            : "",
          "h-2 w-full [&::-webkit-progress-bar]:rounded-full [&::-webkit-progress-bar]:bg-slate-300 [&::-webkit-progress-value]:rounded-full",
        )}
        id="file"
        max="100"
        value={value}
      />
    </div>
  );
}

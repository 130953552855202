import React, { Dispatch, SetStateAction } from "react";
import Empty from "../surveeyQuestions/Empty";
import Likert from "../surveeyQuestions/likert/Likert";
import { motion } from "framer-motion";
import Title from "../surveeyQuestions/title/Title";
import LongText from "../surveeyQuestions/longText/LongText";
import SmallText from "../surveeyQuestions/smallText/SmallText";
import Ranking from "../surveeyQuestions/ranking/Ranking";
import Rating from "../surveeyQuestions/rating/Rating";
import OpenableList from "../surveeyQuestions/openableList/OpenableList";
import SingleChoice from "../surveeyQuestions/singleChoice/SingleChoice";
import MultiChoice from "../surveeyQuestions/multiChoice/MultiChoice";
import DataTable from "../surveeyQuestions/dataTable/DataTable";
import { Draggable, Droppable } from "@hello-pangea/dnd";
import FullName from "../surveeyQuestions/fullName/FullName";
import Email from "../surveeyQuestions/email/Email";
import Address from "../surveeyQuestions/address/Address";
import Telephone from "../surveeyQuestions/tel/Telephone";
import Number from "../surveeyQuestions/number/Number";
import Document from "../surveeyQuestions/document/Document";
import Hour from "../surveeyQuestions/hour/Hour";
import Signature from "../surveeyQuestions/signature/Signature";
import Date from "../surveeyQuestions/date/Date";
import ImagePicker from "../surveeyQuestions/imagePicker/ImagePicker";
import CES from "../surveeyQuestions/CES/CES";
import NPS from "../surveeyQuestions/NPS/NPS";
import CSAT from "../surveeyQuestions/CSAT/CSAT";
import PlusButton from "../generalButtonControllers/PlusButton";
import { toast } from "react-toastify";
import { isEmpty } from "lodash-es";
import RichTextInput from "../surveeyQuestions/RichTextInput/RichTextInput";
import { useAtomValue } from "jotai";
import { surveyAtom } from "../atoms/surveyAtoms";
import { surveyElementsInterface } from "../../Interfaces/SurveyInterfaces";

type COMPONENT_PROPS = {
  setShow: Dispatch<SetStateAction<boolean>>;
  setShowSettings: Dispatch<SetStateAction<boolean>>;
  show: boolean;
  pageIndex: number;
  element: surveyElementsInterface;
  setPageIndex: Dispatch<SetStateAction<number>>;
  index: number;
  handleDeleteButton: (index: number) => void;
  handleDeletePageButton: (index: number) => void;
  provided: any;
  snapshot: any;
  configuredPageIndex: number;
  generalPosition?: string;
  stateSurveyJSONLength?: number;
};

const COMPONENT_MAP: Readonly<{ [key: string]: React.FC<COMPONENT_PROPS> }> = {
  likert: Likert,
  title: Title,
  chapterTitle: Title,
  expText: Title,
  downChapterTitle: Title,
  longTextArea: LongText,
  textArea: SmallText,
  ranking: Ranking,
  starRating: Rating,
  openableList: OpenableList,
  singleChoice: SingleChoice,
  multiChoice: MultiChoice,
  dataTable: DataTable,
  fullName: FullName,
  email: Email,
  address: Address,
  tel: Telephone,
  number: Number,
  document: Document,
  hour: Hour,
  signature: Signature,
  date: Date,
  imagePicker: ImagePicker,
  CES: CES,
  NPS: NPS,
  CSAT: CSAT,
  richText: RichTextInput,
};

type Props = {
  setShow: Dispatch<SetStateAction<boolean>>;
  setShowSettings: Dispatch<SetStateAction<boolean>>;
  show: boolean;
  setPageIndex: Dispatch<SetStateAction<number>>;
  containerRef: any;
  pageIndex: number;
  setIndexToDelete: Dispatch<SetStateAction<number | undefined>>;
  placeholderProps: any;
};

const SurveyQuestionHandler: React.FC<Props> = ({
  setShow,
  show,
  setShowSettings,
  setPageIndex,
  containerRef,
  setIndexToDelete,
  pageIndex,
  placeholderProps,
}) => {
  const stateSurveyJSON = useAtomValue(surveyAtom);

  const handleDeletePageButton = (pageIndex2: number) => {
    stateSurveyJSON.pages?.splice(pageIndex2, 1);
    toast.error("Sayfa başarıyla silindi");
  };

  return (
    <>
      {stateSurveyJSON?.pages && stateSurveyJSON.pages.length > 0 ? (
        stateSurveyJSON.pages.map((page: any, pageIndex2: number) => (
          <Droppable key={page._id} droppableId={page._id} direction="vertical">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="relative mb-[56px] box-border rounded border bg-white"
              >
                {page.elements && page.elements.length > 0 ? (
                  <>
                    {page.elements.map((element: any, index2: number) => {
                      const DraggableChildComponent =
                        COMPONENT_MAP[element.type] ?? Empty;

                      return (
                        <div
                          key={element._id}
                          style={{
                            width: element.collapse === true ? "40%" : "100%",
                          }}
                        >
                          <Draggable draggableId={element._id} index={index2}>
                            {(provided, snapshot) => (
                              <div
                                {...provided.draggableProps}
                                ref={(el) => {
                                  provided.innerRef(el);
                                  containerRef.current = el;
                                }}
                              >
                                <motion.div
                                  key={element._id}
                                  initial={{ opacity: 0, y: -20 }}
                                  animate={{ opacity: 1, y: 0 }}
                                  exit={{ opacity: 0, y: -20 }}
                                  transition={{ duration: 0.5 }}
                                >
                                  <DraggableChildComponent
                                    provided={provided}
                                    element={element}
                                    generalPosition={
                                      stateSurveyJSON.generalQuestionPosition
                                    }
                                    stateSurveyJSONLength={
                                      stateSurveyJSON.pages?.[pageIndex2]
                                        .elements?.length
                                    }
                                    index={index2}
                                    snapshot={snapshot}
                                    configuredPageIndex={pageIndex}
                                    pageIndex={pageIndex2}
                                    setShowSettings={setShowSettings}
                                    setShow={setShow}
                                    show={show}
                                    handleDeletePageButton={() =>
                                      handleDeletePageButton(pageIndex2)
                                    }
                                    handleDeleteButton={() => {
                                      setIndexToDelete(index2);
                                    }}
                                    setPageIndex={setPageIndex}
                                  />
                                </motion.div>
                              </div>
                            )}
                          </Draggable>
                        </div>
                      );
                    })}
                    {!snapshot.draggingFromThisWith && (
                      <div className="pl-[39px] pr-[43px]">
                        <PlusButton
                          buttonSelector={"down"}
                          index={page.elements.length}
                          pageIndex={pageIndex}
                          setShow={setShow}
                          setPageIndex={setPageIndex}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <div
                    className="flex-shrink-0 flex-grow-0"
                    style={{ flexBasis: "100%" }}
                  >
                    <Empty
                      setShow={setShow}
                      setPageIndex={setPageIndex}
                      pageIndex={pageIndex2}
                    />
                  </div>
                )}
                {provided.placeholder}
                {!isEmpty(placeholderProps) && snapshot.isDraggingOver && (
                  <div
                    className="question-handler-placeholder"
                    style={{
                      top: placeholderProps.clientY,
                      left: placeholderProps.clientX,
                      height: placeholderProps.clientHeight,
                      width: placeholderProps.clientWidth,
                    }}
                  />
                )}
              </div>
            )}
          </Droppable>
        ))
      ) : (
        <Droppable
          key={"uniqueDroppableIdKey"}
          droppableId={"uniqueDroppableId"}
        >
          {(provided, snapshot) => (
            <div
              ref={(el) => {
                provided.innerRef(el);
                containerRef.current = el;
              }}
              className="flex-shrink-0 flex-grow-0"
              style={{ flexBasis: "100%" }}
            >
              <Empty setShow={setShow} setPageIndex={setPageIndex} />
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      )}
    </>
  );
};

export default SurveyQuestionHandler;

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAtom, useAtomValue } from "jotai";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../atoms/surveyAtoms";

const FormSettingsTicketPosition = () => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const [localState, setLocalState] = useState({
    position:
      stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]?.position,
  });
  const [isChecked, setIsChecked] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setStateSurveyJSON((prevState) => {
      let newState = { ...prevState };
      if (newState.pages?.[pageIndex].elements?.[elementIndex]) {
        newState.pages[pageIndex].elements =
          newState.pages[pageIndex].elements || [];
        newState.pages[pageIndex].elements![elementIndex] =
          newState.pages[pageIndex].elements![elementIndex] || {};
      }
      newState.pages![pageIndex].elements![elementIndex].position =
        localState.position ?? undefined;

      return newState;
    });
  }, [localState]);

  useEffect(() => {
    setLocalState(() => {
      let localStateObject = {
        position:
          stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]?.position,
      };
      return localStateObject;
    });
  }, [stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]?.position]);

  const handleButtonClick = (buttonName: any) => {
    if (isChecked) {
      setStateSurveyJSON((prevState) => {
        let newState = { ...prevState };
        newState.generalQuestionPosition = buttonName;
        newState.pages![pageIndex]!.elements![elementIndex]!.position =
          buttonName;
        return newState;
      });
    } else {
      setLocalState({ position: buttonName });
    }
  };
  return (
    <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
      <label className="block text-sm font-bold text-gray-500">
        {t("alignTicket")}
      </label>
      <span className="isolate mt-[12px] inline-flex rounded-md shadow-sm">
        <button
          type="button"
          onClick={() => handleButtonClick("Left")}
          className={`relative inline-flex items-center rounded-l-md ${
            localState.position === "Left"
              ? "bg-[#D1E0FF] font-semibold text-gray-900"
              : "button-group-ticket-position bg-white font-medium text-gray-500 hover:bg-gray-300"
          } px-3 py-2 text-sm ring-1 ring-inset ring-gray-300 focus:z-10`}
        >
          {t("left")}
        </button>
        <button
          type="button"
          onClick={() => handleButtonClick("Right")}
          className={`relative inline-flex items-center px-3 py-2 text-sm ${
            localState.position === "Right"
              ? "bg-[#D1E0FF] font-semibold text-gray-900"
              : "button-group-ticket-position bg-white font-medium text-gray-500 hover:bg-gray-300"
          } ring-1 ring-inset ring-gray-300 focus:z-10`}
        >
          {t("right")}
        </button>
        <button
          type="button"
          onClick={() => handleButtonClick("Top")}
          className={`-ml-px relative inline-flex items-center rounded-r-md ${
            localState.position === "Top"
              ? "bg-[#D1E0FF] font-semibold text-gray-900"
              : "button-group-ticket-position bg-white font-medium text-gray-500"
          } px-3 py-2 text-sm ring-1 ring-inset ring-gray-300 focus:z-10`}
        >
          {t("top")}
        </button>
      </span>

      <label className="mt-[12px] flex text-stone-500">
        <div className="flex h-6 items-center">
          <input
            id="candidates"
            aria-describedby="candidates-description"
            name="candidates"
            type="checkbox"
            style={{ transform: "scale(1.2)" }}
            onChange={(e) => setIsChecked(e.target.checked)}
            className="mr-2 h-4 w-4 rounded border-gray-400 text-indigo-600 focus:ring-blue-600"
          />
        </div>
        {t("SetAsDefault")}
      </label>
      <p className="mb-0 mt-3 text-sm text-stone-500">
        {t("alignTicketDescription")}
      </p>
    </div>
  );
};

export default FormSettingsTicketPosition;

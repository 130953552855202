import React, { useState } from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";

export const Input: React.FC<any> = ({
  name,
  readOnly,
  className,
  register,
  required,
  type,
  style,
  setValue,
  limitationMin,
  limitationMax,
  placeholder,
  verifyInputType,
  inputMask,
  limitationType,
  questionTitle,
  questionIndex,
  questionType,
}) => {
  const [prevValidValue, setPrevValidValue] = useState("");
  return (
    <input
      type={type}
      style={style}
      disabled={readOnly}
      className={className}
      placeholder={placeholder}
      {...register(name + ".value", {
        value: null,
        required: { value: required, message: "Bu Alan Zorunludur" },
        minLength: {
          value: limitationMin ?? 0,
          message: "Soruda Karakter Limitinin Altında Kaldınız",
        },
        maxLength: {
          value: limitationMax ?? 99999999,
          message: "Soruda Karakter Limitini Aştınız",
        },
        pattern:
          verifyInputType === "Email"
            ? {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "This input is email only.",
              }
            : verifyInputType === "Sayısal"
              ? {
                  value: /\d+/,
                  message: "This input is number only.",
                }
              : verifyInputType === "Alfabetik"
                ? {
                    value: /^[a-zA-Z ]*$/,
                    message: "This input is alphabet only.",
                  }
                : verifyInputType === "Alfanumerik"
                  ? {
                      value: /^\w+$/,
                      message: "This input is alphanumeric only.",
                    }
                  : verifyInputType === "Kiril" && {
                      value: /[\wа-я]+/gi,
                      message: "This input is cyrillic only.",
                    },
        validate: {
          validateAll: (value: any) =>
            limitationType === 2 && limitationMin && limitationMax
              ? (value.trim().split(" ").length >= limitationMin &&
                  value.trim().split(" ").length <= limitationMax) ||
                `Minimum ${limitationMin} Maksimum ${limitationMax} kadar kelime olabilir.`
              : limitationType === 2 && limitationMin
                ? value.trim().split(" ").length >= limitationMin ||
                  `Minimum ${limitationMin} kadar kelime olabilir.`
                : limitationType === 2 && limitationMax
                  ? value.trim().split(" ").length <= limitationMax ||
                    `Maksimum ${limitationMax} kadar kelime olabilir.`
                  : true,
        },
        onChange: (event: any) => {
          const { value } = event.target;
          setValue(name + ".questionInfos", {
            optionsDetail: JSON.stringify({
              values: [event.target.value],
            }),
            dateTime: new Date(),
            questionType: questionType,
            questionTitle: questionTitle,
            questionNumber: questionIndex,
          });
          if (inputMask) {
            const isMatch = Array.from(value).every((char, index) => {
              const maskChar = inputMask[index];
              if (maskChar === "@") return isNaN(Number(char));
              if (maskChar === "#") return !isNaN(Number(char));
              return true;
            });
            const isLengthValid = value.length <= inputMask.length;
            if (isMatch && isLengthValid) {
              setValue(name + ".value", value);
              setPrevValidValue(value);
            } else {
              setValue(name + ".value", prevValidValue);
            }
          }
        },
      })}
    />
  );
};

export const ButtonInput: React.FC<any> = ({
  name,
  readOnly,
  className,
  value,
  setValue,
  optionTitle,
  getValues,
  setIsClicked,
  isClicked,
  questionTitle,
  title,
  required,
  register,
  index,
  trigger,
  questionIndex,
  questionType,
  options,
}) => {
  // eslint-disable-next-line
  const condition = getValues(name + ".value") == value;

  const dynamicStyle = {
    backgroundColor: value === isClicked || condition ? "rgb(96 165 250)" : "",
    color: value === isClicked || condition ? "white" : "",
  };
  /* burada forma kayıt olabilmesi için register atadım yoksa uncontrolled olan bu elementi kontrol etmek zordu */
  return (
    <button
      type="button"
      disabled={readOnly}
      style={dynamicStyle}
      data-title={title}
      className={className}
      {...register(name + ".value", {
        value: null,
        required: {
          value: required ? required : false,
          message: "Bu Alan Zorunludur",
        },
      })}
      onClick={() => {
        if (isClicked === value) {
          setValue(name + ".questionInfos", {
            optionsDetail: JSON.stringify({
              options: [options],
              answers: [""],
              values: [""],
            }),
            dateTime: new Date(),
            questionType: questionType,
            questionTitle: questionTitle,
            questionNumber: questionIndex,
          });
          setValue(name + ".value", null);
          setIsClicked("");
          trigger(name + ".value");
        } else {
          setValue(name + ".questionInfos", {
            optionsDetail: JSON.stringify({
              options: [options],
              answers: [optionTitle],
              values: [value],
            }),
            dateTime: new Date(),
            questionType: questionType,
            questionTitle: questionTitle,
            questionNumber: questionIndex,
          });
          setValue(name + ".value", value);
          setIsClicked((prev: any) => (prev === value ? "" : value));
          trigger(name + ".value");
        }
      }}
    >
      {optionTitle ? optionTitle : index + 1}
    </button>
  );
};

export const InputButtonMulti: React.FC<any> = ({
  name,
  readOnly,
  className,
  register,
  required,
  style,
  value,
  defaultChecked,
  id,
  optionTitle,
  setValue,
  title,
  limitationMax,
  limitationMin,
  getValues,
  questionTitle,
  options,
  questionIndex,
}) => {
  return (
    <input
      type="checkbox"
      id={id}
      style={style}
      data-title={title}
      className={className}
      disabled={readOnly} //nedense register içerisinde referans hatası veriyor bu nedenle direkt element içerisine gömdüm
      value={value}
      defaultChecked={defaultChecked}
      alt={optionTitle}
      {...register(name + ".value", {
        value: null,
        required: { value: required, message: "Bu Alan Zorunludur" },
        validate: {
          validateMin: (value: any, formValues: any) =>
            limitationMin
              ? value.length >= limitationMin ||
                "En az " + limitationMin + " adet seçin lütfen"
              : true,
          validateMax: (value: any, formValues: any) =>
            limitationMax
              ? value.length <= limitationMax ||
                "En çok " + limitationMax + " adet seçin lütfen"
              : true,
        },
        onChange: (e: any) => {
          if (e.target.checked) {
            const safeQuestionInfos =
              getValues(name)?.questionInfos?.optionsDetail;
            setValue(name + ".questionInfos", {
              optionsDetail: JSON.stringify({
                options: [options],
                answers: JSON.parse(
                  typeof safeQuestionInfos === "string"
                    ? safeQuestionInfos
                    : '{"answers": []}'
                ).answers.concat(e.target.alt),
                values: getValues(name).value,
              }),
              dateTime: new Date(),
              questionType: "checkbox",
              questionTitle: questionTitle,
              questionNumber: questionIndex,
            });
          } else {
            const safeQuestionInfos =
              getValues(name)?.questionInfos?.optionsDetail;
            setValue(name + ".questionInfos", {
              optionsDetail: JSON.stringify({
                options: [options],
                answers: JSON.parse(safeQuestionInfos).answers.filter(
                  (x: any) => x !== e.target.alt
                ),
                values: getValues(name).value,
              }),
              questionType: "checkbox",
              dateTime: new Date(),
              questionTitle: questionTitle,
              questionNumber: questionIndex,
            });
          }
        },
      })}
    />
  );
};

export const InputButton: React.FC<any> = ({
  name,
  readOnly,
  className,
  type,
  register,
  required,
  style,
  value,
  defaultChecked,
  id,
  setValue,
  optionTitle,
  questionTitle,
  questionIndex,
  options,
}) => {
  return (
    <input
      type={type}
      id={id}
      style={style}
      value={value}
      className={className}
      defaultChecked={defaultChecked}
      disabled={readOnly}
      alt={optionTitle}
      {...register(name + ".value", {
        value: null,
        required: { value: required, message: "Bu Alan Zorunludur" },
        onChange: (e: any) => {
          setValue(name + ".questionInfos", {
            optionsDetail: JSON.stringify({
              options: [options],
              answers: [e.target.alt],
              values: [e.target.value],
            }),
            dateTime: new Date(),
            questionType: type,
            questionTitle: questionTitle,
            questionNumber: questionIndex,
          });
        },
      })}
    />
  );
};

export const SelectItem: React.FC<any> = ({
  control,
  className,
  options,
  name,
  required,
  placeholder,
  isClearable,
  isSearchable,
  defaultValue,
  selectMulti,
  limitationMin,
  limitationMax,
  style,
  questionTitle,
  questionIndex,
  optionsTitles,
  questionType,
}) => {
  //TODO DEFAULT VALUE KOYACAGIM SİMDİLİK HIZLICA ÇIKAMAK İÇİN ATLIYORUM
  return (
    <Controller
      control={control}
      rules={{
        validate: {
          validateRequiredGroup: (value: any) =>
            required
              ? (value &&
                  ((value.value !== null &&
                    value.value !== undefined &&
                    value.value !== "") ||
                    value.values?.length > 0)) ||
                "Bu Alan Zorunludur"
              : true,
          validateLimitationMin: (value: any) =>
            limitationMin && Array.isArray(value.values)
              ? value.values?.length >= limitationMin || "az seçenek seçtiniz"
              : true,
          validateLimitationMax: (value: any) =>
            limitationMax && Array.isArray(value.values)
              ? value.values?.length <= limitationMax ||
                "fazla seçenek seçtiniz"
              : true,
        },
      }}
      name={name}
      defaultValue={{ value: null }}
      render={({ field: { onChange, value, name, ref } }: any) => (
        <Select
          ref={ref}
          name={name}
          onChange={(select) => {
            if (!Array.isArray(select)) {
              onChange({
                value: [select?.value],
                label: [select?.label],
                questionInfos: {
                  optionsDetail: JSON.stringify({
                    options: [optionsTitles],
                    answers: [select?.label],
                    values: [select?.value],
                  }),
                  dateTime: new Date(),
                  questionType: questionType,
                  questionTitle: questionTitle,
                  questionNumber: questionIndex,
                },
              });
            } else {
              onChange({
                value: select,
                questionInfos: {
                  optionsDetail: JSON.stringify({
                    options: [optionsTitles],
                    answers: select?.map((c: any) => c.label),
                    values: select?.map((c: any) => c.value),
                  }),
                  dateTime: new Date(),
                  questionType: questionType,
                  questionTitle: questionTitle,
                  questionNumber: questionIndex,
                },
              });
            }
          }}
          defaultValue={undefined}
          options={options}
          value={options.find((c: any) => c.value === value)}
          styles={style}
          className={className}
          placeholder={placeholder}
          isClearable={isClearable}
          isSearchable={isSearchable}
          isMulti={selectMulti}
        />
      )}
    />
  );
};

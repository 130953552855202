import {
  templatesSearchQueryAtom,
  templatesSelectedCategoryFilterAtom,
} from "components/atoms/templatesAtoms";
import { useAtomValue } from "jotai";
import GridWithFilters from "pages/dashboard2/Templates/GridWithFilters";
import TemplateCard from "pages/dashboard2/Templates/TemplateCard";

export default function Templates() {
  const searchQuery = useAtomValue(templatesSearchQueryAtom);
  const selectedCategoryFilter = useAtomValue(
    templatesSelectedCategoryFilterAtom,
  );

  const temp = [
    {
      mainCategoryId: "category1",
      title: "BEST-N Testi",
      description:
        "Kişilik analizi ile ekip dinamiklerini güçlendirmek ve çalışanlar arası iletişimi geliştirmek için kullanılır.",
      src: "Kisilik1",
    },
    {
      mainCategoryId: "category1",
      title: "16 Profiles Testi",
      description:
        "Çalışanların değerlerini ve motivasyonlarını anlayarak kurumsal bağlılığı artırmak için tasarlanmış değerlendirme.",
      src: "Kisilik2",
    },
    {
      mainCategoryId: "category1",
      title: "Big Five Testi",
      description:
        "Çalışanların kariyer gelişimlerini desteklemek ve iç pozisyonlara yönlendirmek için kullanılan yetenek haritası.",
      src: "Kisilik3",
    },
    {
      mainCategoryId: "category1",
      title: "RIASEC Meslek Testi",
      description:
        "Kişilik özelliklerini belirleyerek çalışanların güçlü yönlerini ve potansiyellerini ortaya çıkarır.",
      src: "Kisilik4",
    },
    {
      mainCategoryId: "category2",
      title: "BEST-Values Testi",
      description:
        "Farklı düşünme ve çalışma stillerini analiz ederek ekip uyumunu ve verimliliği artırır.",
      src: "Degerler1",
    },
    {
      mainCategoryId: "category3",
      title: "Liderlik Testi",
      description:
        "Liderlik Testi Liderlik becerilerinizi ölçen kapsamlı bir değerlendirme aracı.",
      src: "Liderlik1",
    },
    {
      mainCategoryId: "category3",
      title: "Yönetici Potansiyeli Testi",
      description:
        "Yönetici Potansiyeli Testi Yöneticilik yetkinliklerinizi ve gelişim alanlarınızı belirleyen etkili bir test.",
      src: "Liderlik2",
    },
    {
      mainCategoryId: "category3",
      title: "Yönetici Etkinliği Testi",
      description:
        "Yönetici Etkinliği Testi Yöneticilik performansınızı ve verimliliğinizi ölçen detaylı bir analiz.",
      src: "Liderlik3",
    },
    {
      mainCategoryId: "category4",
      title: "BEST-N Takım Kültürü Testi",
      description:
        "BEST-N Takım Kültürü Testi Ekip dinamiklerini ve iş birliği potansiyelini değerlendiren benzersiz bir ölçüm aracı.",
      src: "Takimkulturu1",
    },
    {
      mainCategoryId: "category4",
      title: "16 Profiles Takım Kültürü Testi",
      description:
        "16 Profiles Takım Kültürü Testi Ekip içi iletişim ve uyumu 16 farklı profile göre analiz eden test.",
      src: "Takimkulturu2",
    },
    {
      mainCategoryId: "category4",
      title: "RIASEC Takım Kültürü Testi",
      description:
        "RIASEC Takım Kültürü Testi Kariyer yönelimleri ve takım uyumunu değerlendiren çok yönlü bir test.",
      src: "Takimkulturu3",
    },
    {
      mainCategoryId: "category5",
      title: "Sayısal Akıl Yürütme Testleri",
      description:
        "Sayısal Akıl Yürütme Testleri Matematiksel problem çözme ve analitik düşünme becerilerinizi ölçen test.",
      src: "Sayisaltestler1",
    },
    {
      mainCategoryId: "category5",
      title: "Sayısal Karşılaştırmalar ve Bilgi Yeterliliği Testleri",
      description:
        "Sayısal Karşılaştırmalar ve Bilgi Yeterliliği Testleri Sayısal verileri yorumlama ve karşılaştırma yeteneğinizi değerlendiren test.",
      src: "Sayisaltestler2",
    },
    {
      mainCategoryId: "category5",
      title: "Sayısal Kavrama Testleri",
      description:
        "Sayısal Kavrama Testleri Sayısal bilgileri hızlı ve doğru şekilde anlama kabiliyetinizi ölçen test.",
      src: "Sayisaltestler3",
    },
    {
      mainCategoryId: "category5",
      title: "Sayısal Eleştirel Akıl Yürütme Testleri",
      description:
        "Sayısal Eleştirel Akıl Yürütme Testleri Sayısal verileri kullanarak eleştirel düşünme becerilerinizi değerlendiren test.",
      src: "Sayisaltestler4",
    },
    {
      mainCategoryId: "category6",
      title: "Kıyaslama Testleri",
      description:
        "Kıyaslama Testleri Farklı veriler arasında ilişki kurma ve karşılaştırma yapma yeteneğinizi ölçen test.",
      src: "Sozeltestler1",
    },
    {
      mainCategoryId: "category6",
      title: "Sözel Anlama Testleri",
      description:
        "Sözel Anlama Testleri Metinleri okuma, anlama ve yorumlama becerilerinizi değerlendiren kapsamlı test.",
      src: "Sozeltestler2",
    },
    {
      mainCategoryId: "category6",
      title: "Sözel Testler",
      description:
        "Sözel Testler Dil becerilerinizi ve sözel iletişim yeteneğinizi ölçen çok yönlü bir değerlendirme.",
      src: "Sozeltestler3",
    },
    {
      mainCategoryId: "category7",
      title: "Tümevarımsal Akıl Yürütme Testleri",
      description:
        "Tümevarımsal Akıl Yürütme Testleri Özel durumlardan genel kurallara ulaşma yeteneğinizi ölçen analitik test.",
      src: "Tumevarimtestleri1",
    },
    {
      mainCategoryId: "category7",
      title: "Diyagramatik Akıl Yürütme Testleri",
      description:
        "Diyagramatik Akıl Yürütme Testleri Görsel şemalar ve diyagramları analiz etme becerinizi değerlendiren test.",
      src: "Tumevarimtestleri2",
    },
    {
      mainCategoryId: "category7",
      title: "Mantıksal Akıl Yürütme Testleri",
      description:
        "Mantıksal Akıl Yürütme Testleri Mantıksal çıkarım yapma ve problem çözme yeteneğinizi ölçen kapsamlı test.",
      src: "Tumevarimtestleri3",
    },
    {
      mainCategoryId: "category8",
      title: "Tümdengelimsel Akıl Yürütme Testleri",
      description:
        "Tümdengelimsel Akıl Yürütme Testleri Genel ilkelerden özel sonuçlara ulaşma becerinizi değerlendiren analitik test.",
      src: "Tumdengelimtestleri1",
    },
    {
      mainCategoryId: "category9",
      title: "Durumsal Yargı Testleri",
      description:
        "Durumsal Yargı Testleri İş ortamındaki karmaşık durumlarda karar verme yeteneğinizi ölçen test.",
      src: "DurumsalYargıTestleri1",
    },
    {
      mainCategoryId: "category10",
      title: "Mekanik Akıl Yürütme Testleri",
      description:
        "Mekanik Akıl Yürütme Testleri Fiziksel ve mekanik ilkeleri anlama ve uygulama becerinizi değerlendiren test.",
      src: "MekanikAkılYürütmeTestleri1",
    },
    {
      mainCategoryId: "category11",
      title: "İç-Evrak Sepeti Testleri",
      description:
        "İç-Evrak Sepeti Testleri Ofis ortamında önceliklendirme ve iş yönetimi becerilerinizi ölçen simülasyon testi.",
      src: "İcdisevraktestleri1",
    },
    {
      mainCategoryId: "category11",
      title: "Dış-Evrak Sepeti Testleri",
      description:
        "Dış-Evrak Sepeti Testleri Dış paydaşlarla iletişim ve problem çözme yeteneğinizi değerlendiren simülasyon testi.",
      src: "İcdisevraktestleri2",
    },
    {
      mainCategoryId: "category12",
      title: "Örnek olay ve Sunum Testleri",
      description:
        "Örnek Olay ve Sunum Testleri Analiz, problem çözme ve sunum becerilerinizi ölçen kapsamlı değerlendirme.",
      src: "Alistirmalar1",
    },
    {
      mainCategoryId: "category12",
      title: "Hata Denetimi Testleri",
      description:
        "Hata Denetimi Testleri Detaylara dikkat etme ve hataları tespit etme yeteneğinizi ölçen hassas test.",
      src: "Alistirmalar2",
    },
    {
      mainCategoryId: "category12",
      title: "Eleştirel Düşünme Testleri",
      description:
        "Eleştirel Düşünme Testleri Analitik ve eleştirel düşünme becerilerinizi değerlendiren çok yönlü test.",
      src: "Alistirmalar3",
    },
  ];

  const TEMPLATES: Readonly<any[]> = Object.freeze(
    temp.map((x, index) => ({
      _id: "t" + index,
      layout: "single",
      mainCategoryId: x.mainCategoryId,
      title: x.title,
      description: x.description,
      path:
        x.src === "Kisilik2"
          ? "/createSurvey/8fa40c63-e0f2-4094-b95b-33dc0149ad19"
          : "/" + x.src,
      images: [
        {
          src: "/templates/" + x.src + ".png",
          alt: "alt1",
        },
      ],
      faqs: [],
    })),
  );

  const categoryFilteredTemplates = TEMPLATES.filter(
    (template) =>
      selectedCategoryFilter === "category0" ||
      template.mainCategoryId === selectedCategoryFilter,
  );

  const searchedTemplates = categoryFilteredTemplates.filter(
    (template) =>
      template.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      template.description.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  return (
    <GridWithFilters forPath={"templates"}>
      <h2 id="template-heading" className="sr-only">
        Templates
      </h2>

      <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:gap-x-8 xl:grid-cols-3">
        <TemplateCard items={searchedTemplates} />
      </div>
    </GridWithFilters>
  );
}

import { useAtom, useAtomValue } from "jotai";
import { useTranslation } from "react-i18next";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../atoms/surveyAtoms";

const FormSettingsFullNameBottomTicket = () => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const { t } = useTranslation();

  return (
    <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
      <label
        className="block text-sm font-bold text-gray-500"
        htmlFor="enterTitle"
      >
        {t("bottomTicket")}
      </label>
      {stateSurveyJSON.pages![pageIndex].elements![elementIndex]
        .showNickname && (
        <div className="mb-1 mt-3">
          <div className={`relative mt-4`}>
            <label
              htmlFor={"Ad"}
              className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-500"
            >
              Ön Ad
            </label>
            <input
              type="text"
              id={"Ad"}
              autoComplete="off"
              className="py-1.5 block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              value={
                stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
                  ?.bottomTicketNickname?.tr || ""
              }
              onChange={(e) => {
                setStateSurveyJSON((prevState) => {
                  const newState = { ...prevState };
                  newState.pages![pageIndex].elements![
                    elementIndex
                  ].bottomTicketNickname = {
                    tr: e.target.value,
                  };
                  return newState;
                });
              }}
            />
          </div>
        </div>
      )}
      <div className="mb-1 mt-3">
        <div className={`relative mt-4`}>
          <label
            htmlFor={"Ad2"}
            className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-500"
          >
            Ad
          </label>
          <input
            type="text"
            id={"Ad2"}
            autoComplete="off"
            className="py-1.5 block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            value={
              stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
                ?.bottomTicket?.tr || ""
            }
            onChange={(e) => {
              setStateSurveyJSON((prevState) => {
                const newState = { ...prevState };
                newState.pages![pageIndex].elements![
                  elementIndex
                ].bottomTicket = {
                  tr: e.target.value,
                };
                return newState;
              });
            }}
          />
        </div>
      </div>
      {stateSurveyJSON.pages![pageIndex].elements![elementIndex]
        .showSecondaryName && (
        <div className="mb-1 mt-3">
          <div className={`relative mt-4`}>
            <label
              htmlFor={"Ad3"}
              className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-500"
            >
              İkinci Ad
            </label>
            <input
              type="text"
              id={"Ad3"}
              autoComplete="off"
              className="py-1.5 block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              value={
                stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
                  ?.bottomTicketSecondName?.tr || ""
              }
              onChange={(e) => {
                setStateSurveyJSON((prevState) => {
                  const newState = { ...prevState };
                  newState.pages![pageIndex].elements![
                    elementIndex
                  ].bottomTicketSecondName = {
                    tr: e.target.value,
                  };
                  return newState;
                });
              }}
            />
          </div>
        </div>
      )}
      <div className="mb-1 mt-3">
        <div className={`relative mt-4`}>
          <label
            htmlFor={"surname"}
            className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-500"
          >
            Soyad
          </label>
          <input
            type="text"
            id={"surname"}
            autoComplete="off"
            className="py-1.5 block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            value={
              stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
                ?.bottomTicketSurname?.tr || ""
            }
            onChange={(e) => {
              setStateSurveyJSON((prevState) => {
                const newState = { ...prevState };
                newState.pages![pageIndex].elements![
                  elementIndex
                ].bottomTicketSurname = {
                  tr: e.target.value,
                };
                return newState;
              });
            }}
          />
        </div>
      </div>
      {stateSurveyJSON.pages![pageIndex].elements![elementIndex]
        .showLastName && (
        <div className="mb-1 mt-3">
          <div className={`relative mt-4`}>
            <label
              htmlFor={"surname2"}
              className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-500"
            >
              Son Ad
            </label>
            <input
              type="text"
              id={"surname2"}
              autoComplete="off"
              className="py-1.5 block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              onChange={(e) => {
                setStateSurveyJSON((prevState) => {
                  const newState = { ...prevState };
                  newState.pages![pageIndex].elements![
                    elementIndex
                  ].bottomTicketLastName = {
                    tr: e.target.value,
                  };
                  return newState;
                });
              }}
              value={
                stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
                  ?.bottomTicketLastName?.tr || ""
              }
            />
          </div>
        </div>
      )}
      <p className="mb-0 mt-3 text-sm text-stone-500">
        {t("bottomTicketDescription")}
      </p>
    </div>
  );
};

export default FormSettingsFullNameBottomTicket;

import FormSettingsWidth from "../../components/FormSettingsWidth";
import FormSettingsEnterLimit from "../../components/FormSettingsEnterLimit";
import FormSettingsInputVerify from "../../components/FormSettingsInputVerify";
import FormSettingsInputMask from "../../components/FormSettingsInputMask";

export default function SmallTextOptionSettings() {
  return (
    <>
      <FormSettingsWidth />
      <FormSettingsInputVerify />
      <FormSettingsEnterLimit />
      <FormSettingsInputMask />
    </>
  );
}

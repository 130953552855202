import { isEmpty } from "lodash-es";

const calculateStatistics = (labelType: string, series: any[]) => {
  switch (labelType) {
    case "number":
      const sum = series.reduce((acc: any, value: any) => acc + value, 0);
      const average = sum / series.length;
      const sortedSeries = [...series].sort((a, b) => a - b);
      const median =
        sortedSeries.length % 2 === 0
          ? (sortedSeries[sortedSeries.length / 2 - 1] +
              sortedSeries[sortedSeries.length / 2]) /
            2
          : sortedSeries[Math.floor(sortedSeries.length / 2)];
      // Mod hesaplaması
      // ...
      const standardError = Math.sqrt(
        series.reduce(
          (acc: number, value: number) => acc + Math.pow(value - average, 2),
          0
        ) /
          (series.length - 1)
      ).toFixed(2);
      const variance =
        series.reduce(
          (acc: number, value: number) => acc + Math.pow(value - average, 2),
          0
        ) / series.length;
      const range = Math.max(...series) - Math.min(...series);
      const minimum = Math.min(...series);
      const maximum = Math.max(...series);
      const calculateMode = (series: number[]): number[] => {
        const frequencyMap: Record<number, number> = {};

        // Frekans haritasını oluştur
        series.forEach((value) => {
          frequencyMap[value] = (frequencyMap[value] || 0) + 1;
        });

        // En yüksek frekansa sahip değeri bul
        const maxFrequency = Math.max(...Object.values(frequencyMap));

        // Eğer tüm değerler birer kez tekrar ediyorsa mod yoktur
        if (maxFrequency === 1) {
          return [];
        }

        const modeValues: number[] = Object.keys(frequencyMap)
          .filter((value) => frequencyMap[Number(value)] === maxFrequency)
          .map((value) => parseFloat(value));

        return modeValues;
      };

      return {
        N: series.length ? series.length : "-",
        Ortalama: average ? average : "-",
        Medyan: median ? median : "-",
        Mod: calculateMode(series)
          ? isEmpty(calculateMode(series)) || isNaN(calculateMode(series)[0])
            ? "-"
            : calculateMode(series)
          : "-",
        "Standard Hata":
          standardError && !isNaN(Number(standardError)) ? standardError : "-",
        Varyans: variance ? variance : "-",
        Aralık: range ? range : "-",
        Minimum: minimum ? minimum : "-",
        Maximum: maximum ? maximum : "-",
      };

    default:
      return {};
  }
};

const Statistics: React.FC<{ chartData: any }> = ({ chartData }) => {
  const labelType = typeof Number(chartData?.options.labels[0]);
  const series = chartData?.options.labels.map((value: any) =>
    parseFloat(value)
  );

  const statistics = calculateStatistics(labelType, series);

  return (
    <div>
      <span className="font-semibold">Tanımlayıcı İstatistikler</span>
      <div className="w-[664px] mx-auto">
        <div className="flex mt-4 border-b">
          {Object.keys(statistics).map((statistic, index) => (
            <div
              key={index}
              className="w-[74px] h-11 text-sm flex items-center justify-start"
            >
              {statistic}
            </div>
          ))}
        </div>

        <div className="flex bg-white h-[60px] border-b">
          {Object.values(statistics).map((value, index) => (
            <div key={index} className="w-[74px] text-sm text-start my-auto">
              {value}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Statistics;

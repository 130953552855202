import { useTranslation } from "react-i18next";
import { Switch } from "@headlessui/react";
import { classNames } from "../../../../lib/externalJS";
import { useAtom, useAtomValue } from "jotai";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../../atoms/surveyAtoms";

const FormSettingsBottomTicketTel = () => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const { t } = useTranslation();

  const handleChangeCountry = (e: boolean) => {
    const newValue = e;
    setStateSurveyJSON((prevState) => {
      let newState = { ...prevState };
      newState.pages![pageIndex]!.elements![elementIndex]!.showExtra1 =
        newValue;
      return newState;
    });
  };
  const contryCodeSwitchState = stateSurveyJSON.pages?.[pageIndex].elements?.[
    elementIndex
  ]?.showExtra1
    ? stateSurveyJSON.pages[pageIndex].elements![elementIndex].showExtra1
    : false;

  return (
    <>
      <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
        <label className="block text-sm font-bold text-gray-500">
          {t("Ülke Kodu")}
        </label>
        <div className="mt-3 flex">
          <Switch
            id="flexSwitchCheckCountryCode"
            checked={contryCodeSwitchState}
            onChange={(e) => handleChangeCountry(e)}
            className={classNames(
              contryCodeSwitchState ? "bg-blue-600" : "bg-gray-200",
              "relative mr-2 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2",
            )}
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={classNames(
                contryCodeSwitchState ? "translate-x-5" : "translate-x-0",
                "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
              )}
            />
          </Switch>
          <label className="text-gray-500" htmlFor="flexSwitchCheckCountryCode">
            {stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
              ?.showExtra1
              ? t("open")
              : t("close")}
          </label>
        </div>
        <p className="mb-0 mt-3 text-sm text-stone-500">
          {t("Ülke kodu için bir numara alanı ekleyin.")}
        </p>
      </div>
    </>
  );
};

export default FormSettingsBottomTicketTel;

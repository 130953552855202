import { atom } from "jotai";
import { surveyInterface } from "../../Interfaces/SurveyInterfaces";
import { focusAtom } from "jotai-optics";

export const surveyAtom = atom<surveyInterface>({
  _id: "",
  title: { tr: "" },
  pages: [],
  created_date: "",
  created_by: "",
  modified_date: "",
  modified_by: "",
  linkId: "",
  isActive: false,
});

export const pagesAtom = focusAtom(surveyAtom, (optic) => optic.prop("pages"));

export const elementIndexAtom = atom(0);

export const elementIndexForQuestionAtom = atom(0);

export const pageIndexAtom = atom(0);

export const showSettingsAtom = atom(false);

export const showModalAtom = atom(-1);

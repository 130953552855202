import FormSettingsOptions from "../../components/FormSettingsOptions";
import FormSettingsSelectLibrary from "../../components/FormSettingsSelectLibrary";
import FormSettingsCalculation from "../../components/FormSettingsCalculation";
import FormSettingsAreaShape from "../../components/FormSettingsAreaShape";

export default function LikertOptionSettings() {
  return (
    <>
      <FormSettingsOptions />
      <FormSettingsSelectLibrary />
      <FormSettingsCalculation />
      <FormSettingsAreaShape />
    </>
  );
}

import { TrashIcon } from "@heroicons/react/24/outline";
import { dashboardModalValueType } from "./DashboardCards";
import PrimaryButton from "../../components/tailwindUi/PrimaryButton";
import { surveyInterface } from "../../Interfaces/SurveyInterfaces";

interface NewFunctionProps {
  selectedSurvey: surveyInterface;
  setModalValue: React.Dispatch<React.SetStateAction<dashboardModalValueType>>;
  deleteSurveyMutation: any;
}

export default function DeleteFormDialog({
  selectedSurvey,
  setModalValue,
  deleteSurveyMutation,
}: NewFunctionProps) {
  const formName = selectedSurvey!.title?.["tr"]
    ? selectedSurvey!.title!["tr"]
    : "";
  const isError = formName === "";

  return (
    <>
      <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
        <TrashIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
      </div>
      <div className="mt-1 mb-4 text-center sm:mt-3">
        <h3 className="text-base font-semibold leading-6 text-gray-900">
          Delete Form
        </h3>
      </div>
      <div className="text-center text-gray-500 text-sm">
        Warning: Deleting
        <span className="text-slate-700 font-semibold">{` '${formName}' `}</span>
        and
        <span className="text-slate-700 font-semibold"> all responses </span>
        {"from Formcooker will result in permanent removal, with "}
        <span className="text-red-400 font-semibold">
          no option for recovery.
        </span>
        {" Confirm deletion?"}
      </div>

      <div className="flex items-center my-5">
        <input
          tabIndex={-1}
          type="checkbox"
          id="confirmDeleteForm"
          name="confirmDeleteForm"
          className="rounded border border-gray-300 focus:ring-0 focus:ring-offset-0"
        />
        <label
          className="ml-2 text-sm text-slate-700 font-medium leading-tight"
          htmlFor="confirmDeleteForm"
        >
          Yes, delete
          <span className="font-semibold"> "{formName}"</span>
        </label>
      </div>

      <div className="mt-1 sm:mt-2 flex gap-x-3">
        <PrimaryButton onClick={() => setModalValue("")} isSecondary isGray>
          Cancel
        </PrimaryButton>
        <PrimaryButton
          onClick={() => {
            deleteSurveyMutation.mutate(selectedSurvey!._id);
            setModalValue("");
          }}
          isRed
          disabled={isError}
        >
          Delete
        </PrimaryButton>
      </div>
    </>
  );
}

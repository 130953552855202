import { Dispatch, SetStateAction, useState } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";

type Props = {
  setPageState: Dispatch<SetStateAction<number>>;
  setPlans: Dispatch<SetStateAction<any>>;
};

const NewPlanCreator: React.FC<Props> = ({ setPageState, setPlans }) => {
  const [planTime, setPlanTime] = useState<number>(1);
  const [title, setTitle] = useState<string>("Plan");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [endBool, setEndBool] = useState<boolean>(false);
  const [sendHour, setSendHour] = useState<string>("");
  const [hourSetting, setHourSetting] = useState<string>("");
  const [days, setDays] = useState<string>("");

  const handlePlanSaver = () => {
    setPlans((prev: any) => [
      ...prev,
      {
        title,
        planTime,
        startDate,
        hourSetting,
        endDate: endBool ? undefined : endDate,
        sendHour: sendHour,
        days,
      },
    ]);
  };

  return (
    <>
      <div className="flex w-[720px] mx-auto mt-5">
        <input
          type="text"
          className="w-[720px] mx-auto text-start"
          onBlur={(e) => setTitle(e.target.value)}
          value={title}
        />

        <button className="w-[111px]">
          <span
            className="flex justify-center items-center font-semibold"
            onClick={() => setPageState(1)}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mr-2"
            >
              <path
                d="M15.8346 9.99984H4.16797M4.16797 9.99984L10.0013 15.8332M4.16797 9.99984L10.0013 4.1665"
                stroke="#475467"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Geri Dön
          </span>
        </button>
      </div>
      <div className="w-[720px] border rounded-xl text-start px-7 pt-4  mx-auto">
        <div>Tekrarla</div>
        <div className="my-4 inline-flex items-center p-[2px] rounded-xl cursor-pointer bg-gray-100 dark:text-gray-800">
          <span
            className={`p-2  w-[102px] rounded-xl text-center  ${
              planTime === 1 ? "bg-white text-blue-600" : "bg-gray-100"
            }`}
            onClick={() => setPlanTime(1)}
          >
            Günlük
          </span>
          <span
            className={`p-2 w-[102px] rounded-xl text-center ${
              planTime === 2 ? "bg-white text-blue-600" : "bg-gray-100"
            }`}
            onClick={() => setPlanTime(2)}
          >
            Aylık
          </span>
          <span
            className={`p-2  w-[102px] rounded-xl text-center ${
              planTime === 3 ? "bg-white text-blue-600" : "bg-gray-100"
            }`}
            onClick={() => setPlanTime(3)}
          >
            Yıllık
          </span>
        </div>

        <div>Gönderim Tarihi</div>
        <Select
          className="basic-single w-[318px] mb-3"
          placeholder="Seçim Yok"
          isSearchable
          isMulti
          options={[
            { value: "1", label: "Pazartesi" },
            { value: "2", label: "Salı" },
            { value: "3", label: "Çarşamba" },
            { value: "4", label: "Perşembe" },
            { value: "5", label: "Cuma" },
            { value: "6", label: "Cumartesi" },
            { value: "7", label: "Pazar" },
          ]}
          onChange={(e) => setDays(e?.map((item) => item.value).join(","))}
        />
        <div className="flex items-center justify-between">
          <div>
            <div>Gönderim Saati</div>
            <Select
              className="basic-single w-[318px] mb-3"
              placeholder="Seçim Yok"
              isSearchable
              options={[
                { value: "06:00", label: "06:00" },
                { value: "06:15", label: "06:15" },
                { value: "06:30", label: "06:30" },
                { value: "06:45", label: "06:45" },
                { value: "07:00", label: "07:00" },
                { value: "07:15", label: "07:15" },
                { value: "07:30", label: "07:30" },
                { value: "07:45", label: "07:45" },
                { value: "08:00", label: "08:00" },
                { value: "08:15", label: "08:15" },
                { value: "08:30", label: "08:30" },
                { value: "08:45", label: "08:45" },
                { value: "09:00", label: "09:00" },
                { value: "09:15", label: "09:15" },
                { value: "09:30", label: "09:30" },
                { value: "09:45", label: "09:45" },
                { value: "10:00", label: "10:00" },
                { value: "10:15", label: "10:15" },
                { value: "10:30", label: "10:30" },
                { value: "10:45", label: "10:45" },
                { value: "11:00", label: "11:00" },
                { value: "12:00", label: "12:00" },
                { value: "13:00", label: "13:00" },
                { value: "14:00", label: "14:00" },
                { value: "15:00", label: "15:00" },
                { value: "16:00", label: "16:00" },
                { value: "17:00", label: "17:00" },
                { value: "18:00", label: "18:00" },
                { value: "19:00", label: "19:00" },
                { value: "20:00", label: "20:00" },
                { value: "21:00", label: "21:00" },
                { value: "22:00", label: "22:00" },
                { value: "23:00", label: "23:00" },
                { value: "00:00", label: "00:00" },
                { value: "01:00", label: "01:00" },
                { value: "02:00", label: "02:00" },
                { value: "03:00", label: "03:00" },
                { value: "04:00", label: "04:00" },
                { value: "05:00", label: "05:00" },
              ]}
              onChange={(e) => setSendHour(e?.value ?? "")}
            />
          </div>
          <div>
            <div>Zaman Dilimi</div>
            <Select
              className="basic-single  w-[318px] mb-3"
              placeholder="Seçim Yok"
              isSearchable
              options={[
                {
                  value: "-12:00",
                  label: "International Date Line West (GMT-12:00)",
                },
                {
                  value: "-11:00",
                  label: "Coordinated Universal Time-11 (GMT-11:00)",
                },
                { value: "-10:00", label: "Hawaii (GMT-10:00)" },
                { value: "-09:00", label: "Alaska (GMT-09:00)" },
                {
                  value: "-08:00",
                  label: "Pacific Time (US & Canada) (GMT-08:00)",
                },
                {
                  value: "-07:00",
                  label: "Mountain Time (US & Canada) (GMT-07:00)",
                },
                {
                  value: "-06:00",
                  label: "Central Time (US & Canada) (GMT-06:00)",
                },
                {
                  value: "-05:00",
                  label: "Eastern Time (US & Canada) (GMT-05:00)",
                },
                {
                  value: "-04:00",
                  label: "Atlantic Time (Canada) (GMT-04:00)",
                },
                { value: "-03:00", label: "Brasilia (GMT-03:00)" },
                { value: "-02:00", label: "Mid-Atlantic (GMT-02:00)" },
                { value: "-01:00", label: "Azores (GMT-01:00)" },
                {
                  value: "+00:00",
                  label: "London, Dublin, Edinburgh (GMT+00:00)",
                },
                { value: "+01:00", label: "Paris, Berlin, Rome (GMT+01:00)" },
                {
                  value: "+02:00",
                  label: "Athens, Istanbul, Sofia (GMT+02:00)",
                },
                {
                  value: "+03:00",
                  label: "Moscow, St. Petersburg (GMT+03:00)",
                },
                { value: "+04:00", label: "Dubai, Abu Dhabi (GMT+04:00)" },
                { value: "+05:00", label: "Tashkent, Islamabad (GMT+05:00)" },
                { value: "+06:00", label: "Almaty, Nur-Sultan (GMT+06:00)" },
                { value: "+07:00", label: "Bangkok, Jakarta (GMT+07:00)" },
                { value: "+08:00", label: "Beijing, Hong Kong (GMT+08:00)" },
                { value: "+09:00", label: "Tokyo, Seoul (GMT+09:00)" },
                { value: "+10:00", label: "Sydney, Melbourne (GMT+10:00)" },
                { value: "+11:00", label: "Solomon Islands (GMT+11:00)" },
                { value: "+12:00", label: "Fiji, Wellington (GMT+12:00)" },
              ]}
              onChange={(e) => setHourSetting(e?.value ?? "")}
            />
          </div>
        </div>

        <div className="flex items-start justify-between border-b pb-4">
          <div>
            <div>Başlangıç Tarihi</div>
            <DatePicker
              className="border rounded px-2 h-[38px]  w-[318px]"
              selected={startDate}
              onChange={(date: any) => setStartDate(date)}
            />
          </div>
          <div className="w-[318px]">
            <div>Bitiş Tarihi</div>
            <div className="mt-2">
              <input
                type="checkbox"
                id="dateEnd"
                className="form-check-input mr-2"
                checked={endBool}
                onChange={() => setEndBool(!endBool)}
              />
              <label htmlFor="dateEnd">Bitiş Tarihi Yok</label>
            </div>
            <DatePicker
              disabled={endBool}
              className="border rounded px-2 h-[38px] mt-3 w-[318px]"
              selected={endDate}
              onChange={(date: any) => setEndDate(date)}
            />
          </div>
        </div>
        <div className="flex justify-between bg-white rounded-b-xl p-3">
          <button
            className="border-2 border-gray-300 text-sm rounded-md text-center align-middle px-3 py-2 cursor-pointer w-[100px] h-9"
            onClick={() => setPageState(1)}
          >
            Geri
          </button>
          <button
            className="bg-[#2970ff] text-sm rounded-lg text-center align-middle hover:bg-blue-700 px-3 py-2 text-white w-[100px] h-9"
            disabled={!sendHour || (endBool ? false : !endDate)}
            onClick={() => {
              handlePlanSaver();
              setPageState(1);
            }}
          >
            Oluştur
          </button>
        </div>
      </div>
    </>
  );
};

export default NewPlanCreator;

import FormSettingsTip from "../../components/FormSettingsTip";
import FormSettingsTimeCounter from "../../components/FormSettingsTimeCounter";
import FormSettingsCollapse from "../../components/FormSettingsCollapse";
import FormSettingsReadOnly from "../../components/FormSettingsReadOnly";
import FormSettingsAddToLibrary from "../../components/FormSettingsAddToLibrary";
import FormSettingsUpOrDown from "../../components/FormSettingsUpOrDown";
import FormSettingsOptionTipsForRate from "../../components/FormSettingsOptionTipsForRate";

export default function RankingFormAdvanceSettings() {
  return (
    <>
      <FormSettingsTip />
      <FormSettingsOptionTipsForRate />
      <FormSettingsTimeCounter />
      <FormSettingsCollapse />
      <FormSettingsReadOnly />
      <FormSettingsUpOrDown />
      <FormSettingsAddToLibrary />
    </>
  );
}

import { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import { XMarkIcon, LockClosedIcon } from "@heroicons/react/24/outline";
import { classNames } from "../../lib/externalJS";
import Badge from "../../components/tailwindUi/Badge";
import TextInput from "./TextInput";

export type SelectItemType = {
  value: string;
  text: string;
  selected?: boolean;
  disabled?: boolean;
  isSeperator?: boolean;
  icon?: React.ReactNode;
  [key: string]: any;
};

type SelectValue = SelectItemType | SelectItemType[] | undefined;

interface SelectProps {
  label?: string;
  labelIcon?: React.ReactNode;
  placeholder?: string;
  id: string;
  items: SelectItemType[];
  onChange: (newValue: SelectValue) => void;
  disabled?: boolean;
  isMultiSelect?: boolean;
  isBlue?: boolean;
  isSearchable?: boolean;
}

export default function Select({
  label,
  labelIcon,
  placeholder,
  id,
  items,
  onChange,
  disabled,
  isMultiSelect,
  isBlue,
  isSearchable,
}: SelectProps) {
  const getSelected = () => {
    if (isMultiSelect) {
      return items.filter((item) => item.selected);
    } else {
      return items.find((item) => item.selected);
    }
  };

  const [localSelected, setSelected] = useState<SelectValue>(getSelected());
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    setSelected(getSelected());
  }, [items]);

  const localOnChange = (x: SelectValue) => {
    onChange(x);
    setSelected(x);
  };

  const isSomethingSelected = Array.isArray(localSelected)
    ? localSelected.length > 0
    : Boolean(localSelected);

  const filteredItems = items.filter((item) =>
    item.text.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <Listbox
      disabled={disabled}
      value={localSelected}
      onChange={localOnChange}
      multiple={isMultiSelect}
    >
      {({ open }) => (
        <div className="min-w-[78px]">
          {(label || labelIcon) && (
            <Listbox.Label
              htmlFor={id}
              className="mb-2 block text-sm font-medium leading-6 text-gray-900"
            >
              <div className="flex items-center gap-2">
                {label}
                {labelIcon}
              </div>
            </Listbox.Label>
          )}
          <div className="relative">
            <Listbox.Button
              className={`relative w-full cursor-default rounded-md py-2 pl-3 pr-10 text-left shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 ${
                isSomethingSelected
                  ? isBlue
                    ? "text-blue-600"
                    : "text-gray-900"
                  : "text-gray-400"
              } ${disabled ? "bg-gray-100" : "bg-white"}`}
            >
              <span className="block truncate">
                {!isSomethingSelected ? (
                  placeholder
                ) : Array.isArray(localSelected) ? (
                  <div className="flex w-fit gap-[6px]">
                    {localSelected.map((selected) => (
                      <Badge color={"blue"} textSize={12} isCloseIconVisible>
                        <div className="flex items-center gap-1">
                          {selected.text}
                          <XMarkIcon
                            onClick={(event) => {
                              event.stopPropagation();
                              console.log("Icon clicked");

                              const newSelected = localSelected.filter(
                                (x) => x.value !== selected.value,
                              );
                              localOnChange(newSelected);
                            }}
                            className="h-3 w-3 cursor-pointer"
                          />
                        </div>
                      </Badge>
                    ))}
                  </div>
                ) : (
                  `${localSelected?.text}`
                )}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={classNames(
                  isSearchable ? "pt-0" : "pt-1",
                  "absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white pb-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm",
                )}
              >
                {isSearchable && (
                  <TextInput
                    placeholder="Search"
                    name="search"
                    id="search"
                    defaultValue={searchTerm}
                    errorMessage=""
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onBlur={() => setSearchTerm("")}
                    isForSelectSearch
                  />
                )}
                {filteredItems.map((item) => (
                  <Listbox.Option
                    key={item.value}
                    disabled={item.disabled}
                    className={({ active }) =>
                      classNames(
                        active ? "bg-blue-600 text-white" : "",
                        item.disabled ? "text-gray-400" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9",
                      )
                    }
                    value={item}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          {item.icon && <div className="mr-2">{item.icon}</div>}

                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "block truncate",
                            )}
                          >
                            {item.text}
                          </span>

                          {item.isSeperator && (
                            <div className="ml-2 mr-5 flex grow items-center">
                              <div className="grow border-t border-dashed border-black"></div>
                            </div>
                          )}
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-blue-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4",
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : item.disabled ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-blue-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4",
                            )}
                          >
                            <LockClosedIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  );
}

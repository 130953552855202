import { RocketLaunchIcon } from "@heroicons/react/24/outline";
import { classNames } from "../../lib/externalJS";
import { SURVER_MAIN_TABS } from "../SurveyMain";
import PrimaryButton from "../tailwindUi/PrimaryButton";

export default function Navbar({
  selectedTab,
  setSelectedTab,
}: {
  selectedTab: string;
  setSelectedTab: React.Dispatch<React.SetStateAction<string>>;
}) {
  // const stateSurveyJSON = useAtomValue(surveyAtom);

  // const navigate = useNavigate();
  // const toPreview = () => {
  //   navigate("/survey/" + stateSurveyJSON._id, { state: stateSurveyJSON });
  // };

  return (
    <div className="relative flex h-12 items-center justify-between border-b px-5 py-[9px]">
      <div />

      <div className="absolute left-1/2 z-10 flex -translate-x-1/2 transform gap-x-2">
        <div className="flex h-12">
          {SURVER_MAIN_TABS.map((tab) => (
            <button
              key={tab}
              onClick={() => setSelectedTab(tab)}
              className={classNames(
                "flex flex-1 items-center justify-center whitespace-nowrap border-b-2 px-4 py-2 text-sm font-medium",
                tab === selectedTab
                  ? "border-blue-500 text-blue-600"
                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
              )}
            >
              {tab}
            </button>
          ))}
        </div>
      </div>

      <div className="flex gap-x-3">
        {/* <PrimaryButton
          onClick={() => toPreview()}
          isMinWidth
          isSecondary
          isGray
        >
          <div className="flex items-center gap-x-1">
            <EyeIcon className="h-5 w-5" />
            Preview
          </div>
        </PrimaryButton> */}
        <PrimaryButton isMinWidth>
          <div className="flex items-center gap-x-1">
            <RocketLaunchIcon className="h-5 w-5" />
            Yayınla
          </div>
        </PrimaryButton>
      </div>
    </div>
  );
}

import { useEffect, useState } from "react";
import { SettingsCard } from "./SettingsProfile";
import { SelectItemType } from "../../components/tailwindUi/Select";
import { Progress } from "./Dashboard2";

const fromServerDataCenters = [
  { value: "us", text: "United States" },
  { value: "tr", text: "Türkiye", selected: true },
];

const fromServerIsGdprDataCenter = false;

interface FormValues {
  dataCenter?: string;
  isGdprDataCenter: boolean;
}

export default function SettingsData() {
  const [formValues, setFormValues] = useState<FormValues>();

  const [dataCenters, setDataCenters] = useState<Array<SelectItemType>>();

  useEffect(() => {
    // TODO: fetch user data
    const fetchedDataCenters = fromServerDataCenters;
    const fetchedIsGdprDataCenter = fromServerIsGdprDataCenter;

    setDataCenters(fetchedDataCenters);

    const temp = {
      dataCenter: fetchedDataCenters.find((dc) => dc.selected)?.value,
      isGdprDataCenter: fetchedIsGdprDataCenter,
    };

    setFormValues(temp);
  }, []);

  return (
    <SettingsCard title="Your Usage">
      <div>
        <SettingsCard title="Montly Usage" isSubSetting>
          <div className="flex flex-col gap-y-6">
            <Progress fontSize="sm" />
            <Progress fontSize="sm" />
          </div>
        </SettingsCard>
        <div className="mt-2 text-gray-500 text-sm font-normal leading-normal">
          {"Your monthly limits will reset on "}
          <span className="font-semibold">{"May 1, 2024."}</span>
        </div>
      </div>

      <div className="mt-6">
        <SettingsCard title="Montly Usage" isSubSetting>
          <div className="flex flex-col gap-y-6">
            <Progress fontSize="sm" />
            <Progress fontSize="sm" />
            <Progress fontSize="sm" />
          </div>
        </SettingsCard>
        <div className="mt-2 text-gray-500 text-sm font-normal leading-normal">
          {"Your total limits on your current plan."}
        </div>
      </div>
    </SettingsCard>
  );
}

import React, { useState } from "react";

const LongTextQuestion: React.FC<any> = ({
  question,
  endOfPage,
  register,
  setValue,
  errors,
  questionIndex,
}) => {
  const placeholder = question.inputAreaPlaceholder?.tr;
  const questionID = question._id;
  const inputMask = question.inputMask;
  const [prevValidValue, setPrevValidValue] = useState("");
  return (
    <>
      {question.userInputAnswerType === undefined ||
      question.userInputAnswerType === "Straight" ? (
        <>
          {question.position === null ||
          question.position === undefined ||
          question.position === "Top" ? (
            <>
              <div
                data-title={
                  question.tipText?.tr ? question.tipText.tr : undefined
                }
                className={`${question.collapse ? "w-1/2 mx-auto" : "w-full"} `}
              >
                <h6
                  className="mb-0"
                  dangerouslySetInnerHTML={{ __html: question.title?.tr ?? "" }}
                ></h6>
                <p className=" text-gray-500">{question.description?.tr}</p>
                <textarea
                  disabled={question.readOnly ? question.readOnly : false}
                  {...register(questionID + ".value", {
                    value: null,
                    required: {
                      value: question.isRequired ?? false,
                      message: "Bu Alan Zorunludur",
                    },
                    minLength: {
                      value:
                        question.limitationMin && question.limitationType !== 2
                          ? question.limitationMin
                          : 0,
                      message: "Soruda Karakter Limitinin Altında Kaldınız",
                    },
                    maxLength: {
                      value:
                        question.limitationMax && question.limitationType !== 2
                          ? question.limitationMax
                          : 99999999,
                      message: "Soruda Karakter Limitini Aştınız",
                    },
                    validate: (value: any) =>
                      question.limitationType === 2
                        ? (value.trim().split(" ").length >=
                            question.limitationMin &&
                            value.trim().split(" ").length <=
                              question.limitationMax) ||
                          `Minimum ${question.limitationMin} Maksimum ${question.limitationMax} kadar kelime olabilir.`
                        : true,
                    pattern:
                      question.verifyInputType === "Email"
                        ? {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "This input is email only.",
                          }
                        : question.verifyInputType === "Sayısal"
                          ? {
                              value: /\d+/,
                              message: "This input is number only.",
                            }
                          : question.verifyInputType === "Alfabetik"
                            ? {
                                value: /^[a-zA-Z ]*$/,
                                message: "This input is alphabet only.",
                              }
                            : question.verifyInputType === "Alfanumerik"
                              ? {
                                  value: /^\w+$/,
                                  message: "This input is alphanumeric only.",
                                }
                              : question.verifyInputType === "Kiril" && {
                                  value: /[\wа-я]+/gi,
                                  message: "This input is cyrillic only.",
                                },
                    onChange: (event: any) => {
                      const { value } = event.target;
                      setValue(questionID + ".questionInfos", {
                        optionsDetail: JSON.stringify({
                          values: [value],
                        }),
                        dateTime: new Date(),
                        questionType: question.type,
                        questionTitle: question.title?.tr,
                        questionNumber: questionIndex,
                      });
                      if (inputMask) {
                        const isMatch = Array.from(value).every(
                          (char, index) => {
                            const maskChar = inputMask[index];
                            if (maskChar === "@") return isNaN(Number(char));
                            if (maskChar === "#") return !isNaN(Number(char));
                            return true;
                          }
                        );

                        const isLengthValid = value.length <= inputMask.length;
                        if (isMatch && isLengthValid) {
                          setValue(questionID + ".value", value);
                          setPrevValidValue(value);
                        } else {
                          setValue(questionID + ".value", prevValidValue);
                        }
                      }
                    },
                  })}
                  className={`border-1 rounded pl-2  ${
                    !!errors[questionID]
                      ? "!outline-red-500 border-red-700"
                      : "focus:outline-blue-300"
                  } !h-[90px]`}
                  style={{
                    width: question.inputWidth
                      ? question.inputWidth + "px"
                      : "100%",
                    height: question.inputHeight
                      ? question.inputHeight + "px"
                      : "100%",
                  }}
                  placeholder={placeholder}
                />
                <p className="mb-2 text-sm text-red-500">
                  {errors[questionID]?.value?.message}
                </p>
                <p className="mb-4 text-gray-500">
                  {question.bottomTicket?.tr}
                </p>
              </div>
              {!endOfPage && <hr />}
            </>
          ) : (
            <>
              <div
                data-title={
                  question.tipText?.tr ? question.tipText.tr : undefined
                }
                className={`${
                  question.collapse ? "w-1/2 mx-auto" : "w-full"
                } flex flex-wrap`}
              >
                <div
                  className={`w-1/2 ${
                    question.position === "Right" && "text-right pr-4"
                  }`}
                >
                  <h6
                    className="mb-0"
                    dangerouslySetInnerHTML={{
                      __html: question.title?.tr ?? "",
                    }}
                  ></h6>
                  <p className=" text-gray-500">{question.description?.tr}</p>
                </div>
                <div className="w-1/2">
                  <textarea
                    disabled={question.readOnly ? question.readOnly : false}
                    {...register(questionID + ".value", {
                      value: null,
                      required: {
                        value: question.isRequired ?? false,
                        message: "Bu Alan Zorunludur",
                      },
                      minLength: {
                        value:
                          question.limitationMin &&
                          question.limitationType !== 2
                            ? question.limitationMin
                            : 0,
                        message: "Soruda Karakter Limitinin Altında Kaldınız",
                      },
                      maxLength: {
                        value:
                          question.limitationMax &&
                          question.limitationType !== 2
                            ? question.limitationMax
                            : 99999999,
                        message: "Soruda Karakter Limitini Aştınız",
                      },
                      validate: {
                        validateAll: (value: any) =>
                          question.limitationType === 2 &&
                          question.limitationMin &&
                          question.limitationMax
                            ? (value.trim().split(" ").length >=
                                question.limitationMin &&
                                value.trim().split(" ").length <=
                                  question.limitationMax) ||
                              `Minimum ${question.limitationMin} Maksimum ${question.limitationMax} kadar kelime olabilir.`
                            : question.limitationType === 2 &&
                                question.limitationMin
                              ? value.trim().split(" ").length >=
                                  question.limitationMin ||
                                `Minimum ${question.limitationMin} kadar kelime olabilir.`
                              : question.limitationType === 2 &&
                                  question.limitationMax
                                ? value.trim().split(" ").length <=
                                    question.limitationMax ||
                                  `Maksimum ${question.limitationMax} kadar kelime olabilir.`
                                : true,
                      },
                      pattern:
                        question.verifyInputType === "Email"
                          ? {
                              value:
                                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                              message: "This input is email only.",
                            }
                          : question.verifyInputType === "Sayısal"
                            ? {
                                value: /\d+/,
                                message: "This input is number only.",
                              }
                            : question.verifyInputType === "Alfabetik"
                              ? {
                                  value: /^[a-zA-Z ]*$/,
                                  message: "This input is alphabet only.",
                                }
                              : question.verifyInputType === "Alfanumerik"
                                ? {
                                    value: /^\w+$/,
                                    message: "This input is alphanumeric only.",
                                  }
                                : question.verifyInputType === "Kiril" && {
                                    value: /[\wа-я]+/gi,
                                    message: "This input is cyrillic only.",
                                  },
                      onChange: (event: any) => {
                        const { value } = event.target;
                        setValue(questionID + ".questionInfos", {
                          optionsDetail: JSON.stringify({
                            values: [value],
                          }),
                          dateTime: new Date(),
                          questionType: question.type,
                          questionTitle: question.title?.tr,
                          questionNumber: questionIndex,
                        });
                        if (inputMask) {
                          const isMatch = Array.from(value).every(
                            (char, index) => {
                              const maskChar = inputMask[index];
                              if (maskChar === "@") return isNaN(Number(char));
                              if (maskChar === "#") return !isNaN(Number(char));
                              return true;
                            }
                          );

                          const isLengthValid =
                            value.length <= inputMask.length;
                          if (isMatch && isLengthValid) {
                            setValue(questionID + ".value", value);
                            setPrevValidValue(value);
                          } else {
                            setValue(questionID + ".value", prevValidValue);
                          }
                        }
                      },
                    })}
                    className={`border-1 rounded pl-2   ${
                      !!errors[questionID]
                        ? "!outline-red-500 border-red-700"
                        : "focus:outline-blue-300"
                    }  w-full h-[90px]`}
                    placeholder={placeholder}
                  />
                  <p className="mb-2 text-sm text-red-500">
                    {errors[questionID]?.value?.message}
                  </p>
                  <p className="mb-4 text-gray-500">
                    {question.bottomTicket?.tr}
                  </p>
                </div>
              </div>
              {!endOfPage && <hr />}
            </>
          )}
        </>
      ) : (
        <>
          {question.position === "Top" || question.position === undefined ? (
            <>
              <div
                data-title={
                  question.tipText?.tr ? question.tipText.tr : undefined
                }
                className={`${question.collapse ? "w-1/2 mx-auto" : "w-full"} `}
              >
                <h6
                  className="mb-0"
                  dangerouslySetInnerHTML={{ __html: question.title?.tr ?? "" }}
                ></h6>
                <p className=" text-gray-500">{question.description?.tr}</p>
                <textarea
                  disabled={question.readOnly ? question.readOnly : false}
                  {...register(questionID + ".value", {
                    value: null,
                    required: {
                      value: question.isRequired ?? false,
                      message: "Bu Alan Zorunludur",
                    },
                    minLength: {
                      value:
                        question.limitationMin && question.limitationType !== 2
                          ? question.limitationMin
                          : 0,
                      message: "Soruda Karakter Limitinin Altında Kaldınız",
                    },
                    maxLength: {
                      value:
                        question.limitationMax && question.limitationType !== 2
                          ? question.limitationMax
                          : 99999999,
                      message: "Soruda Karakter Limitini Aştınız",
                    },
                    validate: {
                      validateAll: (value: any) =>
                        question.limitationType === 2 &&
                        question.limitationMin &&
                        question.limitationMax
                          ? (value.trim().split(" ").length >=
                              question.limitationMin &&
                              value.trim().split(" ").length <=
                                question.limitationMax) ||
                            `Minimum ${question.limitationMin} Maksimum ${question.limitationMax} kadar kelime olabilir.`
                          : question.limitationType === 2 &&
                              question.limitationMin
                            ? value.trim().split(" ").length >=
                                question.limitationMin ||
                              `Minimum ${question.limitationMin} kadar kelime olabilir.`
                            : question.limitationType === 2 &&
                                question.limitationMax
                              ? value.trim().split(" ").length <=
                                  question.limitationMax ||
                                `Maksimum ${question.limitationMax} kadar kelime olabilir.`
                              : true,
                    },
                    pattern:
                      question.verifyInputType === "Email"
                        ? {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "This input is email only.",
                          }
                        : question.verifyInputType === "Sayısal"
                          ? {
                              value: /\d+/,
                              message: "This input is number only.",
                            }
                          : question.verifyInputType === "Alfabetik"
                            ? {
                                value: /^[a-zA-Z ]*$/,
                                message: "This input is alphabet only.",
                              }
                            : question.verifyInputType === "Alfanumerik"
                              ? {
                                  value: /^\w+$/,
                                  message: "This input is alphanumeric only.",
                                }
                              : question.verifyInputType === "Kiril" && {
                                  value: /[\wа-я]+/gi,
                                  message: "This input is cyrillic only.",
                                },
                    onChange: (event: any) => {
                      const { value } = event.target;
                      setValue(questionID + ".questionInfos", {
                        optionsDetail: JSON.stringify({
                          values: [value],
                        }),
                        dateTime: new Date(),
                        questionType: question.type,
                        questionTitle: question.title?.tr,
                        questionNumber: questionIndex,
                      });
                      if (inputMask) {
                        const isMatch = Array.from(value).every(
                          (char, index) => {
                            const maskChar = inputMask[index];
                            if (maskChar === "@") return isNaN(Number(char));
                            if (maskChar === "#") return !isNaN(Number(char));
                            return true;
                          }
                        );

                        const isLengthValid = value.length <= inputMask.length;
                        if (isMatch && isLengthValid) {
                          setValue(questionID + ".value", value);
                          setPrevValidValue(value);
                        } else {
                          setValue(questionID + ".value", prevValidValue);
                        }
                      }
                    },
                  })}
                  className={`border-1 rounded pl-2   ${
                    !!errors[questionID]
                      ? "!outline-red-500 border-red-700"
                      : "focus:outline-blue-300"
                  }  w-full h-[90px]`}
                  placeholder={placeholder}
                />
                {/*   <ReactQuill
                  theme="snow"
                  modules={modules}
                  //react-quill kullanmak için useEffect ile sürekli kontrol etmek gerekiyor burayı bir alt componente taşıyacağım ama şimdilik dursun

                  {...register(questionID + ".value", {
                    required: {
                      value: question.isRequired,
                      message: "Bu Alan Zorunludur",
                    },
                    disabled: question.readOnly,
                    minLength: {
                      value: question.limitationMin ?? 0,
                      message: "Soruda Karakter Limitinin Altında Kaldınız",
                    },
                    maxLength: {
                      value: question.limitationMax ?? 99999999,
                      message: "Soruda Karakter Limitini Aştınız",
                    },
                    pattern:
                      question.verifyInputType === "Email"
                        ? {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "This input is email only.",
                          }
                        : question.verifyInputType === "Sayısal"
                          ? {
                              value: /\d+/,
                              message: "This input is number only.",
                            }
                          : question.verifyInputType === "Alfabetik"
                            ? {
                                value: /^[a-zA-Z ]*$/,
                                message: "This input is alphabet only.",
                              }
                            : question.verifyInputType === "Alfanumerik"
                              ? {
                                  value: /^\w+$/,
                                  message: "This input is alphanumeric only.",
                                }
                              : question.verifyInputType === "Kiril" && {
                                  value: /[\wа-я]+/gi,
                                  message: "This input is cyrillic only.",
                                },
                    onChange: (event: any) => {
                      const { value } = event.target;
                     setValue(questionID + ".questionInfos", {
                        optionsDetail: JSON.stringify({
                          values: [value],
                        }),
                        dateTime: new Date(),
                        questionType: question.type,
                        questionTitle: question.title?.tr,
                        questionNumber: questionIndex,
                      });
                      if (inputMask) {
                        const isMatch = Array.from(value).every(
                          (char, index) => {
                            const maskChar = inputMask[index];
                            if (maskChar === "@") return isNaN(Number(char));
                            if (maskChar === "#") return !isNaN(Number(char));
                            return true;
                          }
                        );

                        const isLengthValid = value.length <= inputMask.length;
                        if (isMatch && isLengthValid) {
                          setValue(questionID + ".value", value);
                          setPrevValidValue(value);
                        } else {
                          setValue(questionID + ".value", prevValidValue);
                        }
                      }
                    },
                  })}
                  style={{
                    width: question.inputWidth
                      ? question.inputWidth + "px"
                      : "100%",
                    height: question.inputHeight
                      ? question.inputHeight + "px"
                      : "100%",
                  }}
                  placeholder={placeholder}
                /> */}
                <p className="mb-2 text-sm text-red-500">
                  {errors[questionID]?.value?.message}
                </p>
                <p className="mb-4 text-gray-500">
                  {question.bottomTicket?.tr}
                </p>
              </div>
              {!endOfPage && <hr />}
            </>
          ) : (
            <>
              <div
                data-title={
                  question.tipText?.tr ? question.tipText.tr : undefined
                }
                className={`${
                  question.collapse ? "w-1/2 mx-auto" : "w-full"
                } flex flex-wrap`}
              >
                <div
                  className={`w-1/2 ${
                    question.position === "Right" && "text-right pr-4"
                  }`}
                >
                  <h6
                    className="mb-0"
                    dangerouslySetInnerHTML={{
                      __html: question.title?.tr ?? "",
                    }}
                  ></h6>
                  <p className=" text-gray-500">{question.description?.tr}</p>
                </div>
                <div className="w-1/2">
                  <textarea
                    disabled={question.readOnly ? question.readOnly : false}
                    {...register(questionID + ".value", {
                      value: null,
                      required: {
                        value: question.isRequired ?? false,
                        message: "Bu Alan Zorunludur",
                      },
                      minLength: {
                        value:
                          question.limitationMin &&
                          question.limitationType !== 2
                            ? question.limitationMin
                            : 0,
                        message: "Soruda Karakter Limitinin Altında Kaldınız",
                      },
                      maxLength: {
                        value:
                          question.limitationMax &&
                          question.limitationType !== 2
                            ? question.limitationMax
                            : 99999999,
                        message: "Soruda Karakter Limitini Aştınız",
                      },
                      validate: {
                        validateAll: (value: any) =>
                          question.limitationType === 2 &&
                          question.limitationMin &&
                          question.limitationMax
                            ? (value.trim().split(" ").length >=
                                question.limitationMin &&
                                value.trim().split(" ").length <=
                                  question.limitationMax) ||
                              `Minimum ${question.limitationMin} Maksimum ${question.limitationMax} kadar kelime olabilir.`
                            : question.limitationType === 2 &&
                                question.limitationMin
                              ? value.trim().split(" ").length >=
                                  question.limitationMin ||
                                `Minimum ${question.limitationMin} kadar kelime olabilir.`
                              : question.limitationType === 2 &&
                                  question.limitationMax
                                ? value.trim().split(" ").length <=
                                    question.limitationMax ||
                                  `Maksimum ${question.limitationMax} kadar kelime olabilir.`
                                : true,
                      },
                      pattern:
                        question.verifyInputType === "Email"
                          ? {
                              value:
                                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                              message: "This input is email only.",
                            }
                          : question.verifyInputType === "Sayısal"
                            ? {
                                value: /\d+/,
                                message: "This input is number only.",
                              }
                            : question.verifyInputType === "Alfabetik"
                              ? {
                                  value: /^[a-zA-Z ]*$/,
                                  message: "This input is alphabet only.",
                                }
                              : question.verifyInputType === "Alfanumerik"
                                ? {
                                    value: /^\w+$/,
                                    message: "This input is alphanumeric only.",
                                  }
                                : question.verifyInputType === "Kiril" && {
                                    value: /[\wа-я]+/gi,
                                    message: "This input is cyrillic only.",
                                  },
                      onChange: (event: any) => {
                        const { value } = event.target;
                        setValue(questionID + ".questionInfos", {
                          optionsDetail: JSON.stringify({
                            values: [value],
                          }),
                          dateTime: new Date(),
                          questionType: question.type,
                          questionTitle: question.title?.tr,
                          questionNumber: questionIndex,
                        });
                        if (inputMask) {
                          const isMatch = Array.from(value).every(
                            (char, index) => {
                              const maskChar = inputMask[index];
                              if (maskChar === "@") return isNaN(Number(char));
                              if (maskChar === "#") return !isNaN(Number(char));
                              return true;
                            }
                          );

                          const isLengthValid =
                            value.length <= inputMask.length;
                          if (isMatch && isLengthValid) {
                            setValue(questionID + ".value", value);
                            setPrevValidValue(value);
                          } else {
                            setValue(questionID + ".value", prevValidValue);
                          }
                        }
                      },
                    })}
                    className={`border-1 rounded pl-2   ${
                      !!errors[questionID]
                        ? "!outline-red-500 border-red-700"
                        : "focus:outline-blue-300"
                    }  w-full h-[90px]`}
                    placeholder={placeholder}
                  />

                  <p className="mb-2 text-sm text-red-500">
                    {errors[questionID]?.value?.message}
                  </p>
                  <p className="mb-4 text-gray-500">
                    {question.bottomTicket?.tr}
                  </p>
                </div>
              </div>
              {!endOfPage && <hr />}
            </>
          )}
        </>
      )}
    </>
  );
};

export default LongTextQuestion;

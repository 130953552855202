import { ProgressCircle } from "@tremor/react";
import companyData from "data/companyData.json";
import talentData from "data/talentData.json";
import ArrowDownIcon from "images/ArrowDownIcon";
import ArrowUpIcon from "images/ArrowUpIcon";
import PanelIcon from "images/sidebarIcons/Panel";
import ThumbsDownIcon from "images/thumbs-down.svg";
import ThumbsNotrIcon from "images/thumbs-notr.svg";
import ThumbsUpIcon from "images/thumbs-up.svg";
import { classNames } from "lib/externalJS";
import EmployeeProfileCard from "pages/dashboard2/EmployeeProfileCard";
import { getSortedDegerlendirme } from "pages/dashboard2/EmployeeProfileHighlights";
import GaugeWithPointer from "pages/dashboard2/Panel/GaugeWithPointer";
import ReactApexChart from "react-apexcharts";

export default function Panel() {
  const latestMutlulukScore = +getSortedDegerlendirme(companyData.Mutluluk)[0]
    .score.genel;

  const latestCalisanMemnuniyeti = getSortedDegerlendirme(
    companyData["Çalışan Memnuniyet Skoru"],
  )[0];

  const sortedPerformans = getSortedDegerlendirme(companyData.Performans);

  return (
    <div className="flex max-w-[1000px] flex-col">
      {/* page title */}
      <div className="mb-[30px] flex items-center">
        <div className="p-1.5 mr-3 inline-flex h-9 w-9 items-center justify-center rounded bg-blue-100">
          <PanelIcon aria-hidden="true" isActive={true} />
        </div>

        <div className="inline-flex text-2xl font-semibold leading-normal text-gray-800">
          Panelim
        </div>
      </div>

      <div className="grid grid-cols-4 gap-x-4 gap-y-5">
        <PanelCardChild label="Genel Performans">
          <PanelCardChildWithSpline
            data={sortedPerformans}
            label="Genel Performans"
            colors={["#47CD89"]}
          />
        </PanelCardChild>

        <PanelCardChild label="Yönetici Etkinliği">
          <PanelCardChildWithSpline
            data={getSortedDegerlendirme(companyData["Yönetici Etkinliği"])}
            label="Yönetici Etkinliği"
            colors={["#008FFB"]}
          />
        </PanelCardChild>

        <PanelCardChild label="Adanmışlık">
          <PanelCardChildWithSpline
            data={getSortedDegerlendirme(companyData["Adanmışlık"])}
            label="Adanmışlık"
            colors={["#9880FA"]}
          />
        </PanelCardChild>

        <PanelCardChild label="Kurum Nabzı">
          <div className="mt-4 p-6">
            <ProgressCircle
              value={latestMutlulukScore}
              color={"rose-400"}
              radius={55}
              strokeWidth={12}
            >
              <span className="text-[28px] font-bold text-gray-800">
                %{latestMutlulukScore}
              </span>
            </ProgressCircle>
          </div>
        </PanelCardChild>

        <PanelCardChild label="Kurum Performansı" span={3}>
          <PanelLineChartDataLabels
            {...getSeriesAndCategories(sortedPerformans, "Performans")}
            colors={SERIES_COLORS}
          />
        </PanelCardChild>

        <PanelCardChild label="Çalışan Memnuniyet Skoru">
          <GaugeWithPointer
            value={+latestCalisanMemnuniyeti.score.genel.eNPS}
          />

          <EmployeeSatisfactionScorePercentages
            data={latestCalisanMemnuniyeti}
          />
        </PanelCardChild>
      </div>
    </div>
  );
}

export function EmployeeSatisfactionScorePercentages(data: any) {
  const latestGenelScore = data.data.score.genel;

  return (
    <div className="mt-[21px] flex flex-col gap-y-5">
      {[
        {
          title: "Pozitif (9-10)",
          src: ThumbsUpIcon,
          percentage: latestGenelScore.pozitif,
          color: "green",
        },
        {
          title: "Nötr (7-8)",
          src: ThumbsNotrIcon,
          percentage: latestGenelScore.notr,
          color: "yellow",
        },
        {
          title: "Negatif (0-6)",
          src: ThumbsDownIcon,
          percentage: latestGenelScore.negatif,
          color: "red",
        },
      ].map((item, index) => (
        <div key={index} className="flex items-center justify-between">
          <div className="flex items-center text-sm text-gray-500">
            <img src={item.src} alt="" className="mr-2 h-6 w-6" />

            <span className="text-sm font-medium text-slate-600">
              {item.title}
            </span>
          </div>

          <div
            className={classNames(
              "text-[21px] font-semibold",
              item.color === "green" ? "text-green-400" : "",
              item.color === "yellow" ? "text-yellow-400" : "",
              item.color === "red" ? "text-rose-500" : "",
            )}
          >
            {item.percentage + "%"}
          </div>
        </div>
      ))}
    </div>
  );
}

export function PanelCardChild({
  label,
  span,
  children,
}: {
  label?: string;
  span?: number;
  children: React.ReactNode;
}) {
  return (
    <div
      className={classNames(
        span && span > 0 ? "col-span-" + span : "col-span-1",
      )}
    >
      <EmployeeProfileCard>
        {label && (
          <span className="mb-1 text-sm font-medium leading-normal text-gray-500">
            {label}
          </span>
        )}

        {children}
      </EmployeeProfileCard>
    </div>
  );
}

function PanelCardChildWithSpline({
  data,
  label,
  colors,
}: {
  data: any[];
  label: string;
  colors?: string[];
}) {
  const latestData = data[0]["score"]["genel"];
  const changeOfData = +data[1]["score"]["genel"] - +latestData;

  const isPositive = changeOfData > -1;
  const ArrowIcon = isPositive ? ArrowUpIcon : ArrowDownIcon;

  return (
    <>
      <div className="flex items-end">
        <div className="mr-1 font-bold text-slate-700">
          <span className="text-base">%</span>
          <span className="text-[40px]">{+latestData}</span>
        </div>

        <div className="mb-[10px]">
          <ArrowIcon isPositive={isPositive} />
        </div>

        <div
          className={classNames(
            "mb-[7px] font-bold",
            isPositive ? "text-green-400" : "text-red-400",
          )}
        >
          <span className="text-[8px]">%</span>
          <span className="text-sm">{Math.abs(changeOfData)}</span>
        </div>
      </div>

      <PanelSplineGraph
        series={[{ name: label, data: data.map((x) => +x.score.genel) }]}
        colors={colors}
        categories={data.map((x: any) => x.date.toISOString())}
      />
    </>
  );
}

export function PanelSplineGraph({
  series,
  colors,
  isBigger,
  isForMutluluk,
  categories,
  range,
}: {
  series: any[];
  colors?: string[];
  isBigger?: boolean;
  isForMutluluk?: boolean;
  categories?: string[];
  range?: number;
}) {
  return (
    <div className="-mx-2 -my-5">
      <ReactApexChart
        options={{
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "smooth",
          },
          xaxis: {
            type: "datetime",
            categories: categories ?? [
              "2018-09-19T00:00:00.000Z",
              "2018-09-19T01:30:00.000Z",
              "2018-09-19T02:30:00.000Z",
              "2018-09-19T03:30:00.000Z",
              "2018-09-19T04:30:00.000Z",
              "2018-09-19T05:30:00.000Z",
              "2018-09-19T06:30:00.000Z",
            ],
            labels: {
              show: isBigger,
            },
            axisBorder: {
              show: isBigger,
            },
            axisTicks: {
              show: isBigger,
            },
            range: isBigger
              ? isForMutluluk
                ? new Date().getTime() - new Date("2023-11-28").getTime()
                : new Date().getTime() - new Date("2023-05-28").getTime()
              : undefined,
          },
          yaxis: {
            show: isBigger,
            labels: {
              style: {
                colors: "#98A2B3",
              },
            },
          },
          tooltip: {
            x: {
              format: "dd/MM/yy",
            },
          },
          chart: {
            toolbar: isBigger
              ? {
                  show: true,
                  offsetY: -28,
                }
              : { show: false },
            events: {
              beforeZoom: function (ctx: any) {
                ctx.w.config.xaxis.range = undefined;
              },
              mounted: (chart) => {
                series.forEach((x, index) => {
                  if (index !== 0) {
                    chart.toggleSeries(x.name);
                  }
                });
              },
            },
          },
          grid: {
            show: isBigger,
            borderColor: "#f3f4f6",
          },
          colors: colors || ["#3B82F6"],
          legend: isBigger
            ? {
                position: "bottom",
                horizontalAlign: "left",
              }
            : {},
        }}
        type={"area"}
        height={isBigger ? 420 : 140}
        width={"100%"}
        series={series}
      />
    </div>
  );
}

function PanelLineChartDataLabels({
  series,
  colors,
  categories,
}: {
  series: any[];
  colors?: string[];
  categories?: string[];
}) {
  return (
    <ReactApexChart
      options={{
        chart: {
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
          events: {
            mounted: (chart) => {
              series.forEach((x, index) => {
                if (index !== 0) {
                  chart.toggleSeries(x.name);
                }
              });
            },
          },
        },
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: "smooth",
        },
        grid: {
          borderColor: "#e7e7e7",
        },
        markers: {
          size: 1,
        },
        xaxis: {
          type: "datetime",
          categories: categories ?? [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
          ],
        },
        yaxis: {
          show: false,
        },
        legend: {
          position: "bottom",
          horizontalAlign: "left",
        },
        colors: colors || ["#3B82F6"],
      }}
      type={"line"}
      height={340}
      width={"100%"}
      series={series}
    />
  );
}

export const SERIES_COLORS = [
  "#3CCB7F",
  "#528BFF",
  "#22CCEE",
  "#9880FA",
  "#667085",
  "#F97066",
  "#F670C7",
  "#FF9C66",
];

export function getSeriesAndCategories(sortedScores: any[], forr: string) {
  let keyForCeo;
  if (forr === "Performans") {
    keyForCeo = "degerlendirme_performans";
  } else if (forr === "Yönetici Etkinliği") {
    keyForCeo = "degerlendirme_yöneticiEtkinliği";
  } else if (forr === "Adanmışlık") {
    keyForCeo = "degerlendirme_adanmışlık";
  } else if (forr === "Mutluluk") {
    keyForCeo = "degerlendirme_mutluluk";
  } else {
    keyForCeo = "degerlendirme_performans";
  }

  return {
    series: [
      {
        name: `Global ${forr === "Mutluluk" ? "Nabız" : forr}`,
        data: sortedScores.map((x) => +x.score.genel),
      },
      {
        name: "Üst Yönetim",
        data: (talentData as any).at(0)[keyForCeo].map((x: any) => +x.score),
      },
      {
        name: "İnsan Kaynakları",
        data: sortedScores.map((x) => +x.score.insanKaynaklari),
      },
      {
        name: "Finans",
        data: sortedScores.map((x) => +x.score.finans),
      },
      {
        name: "İdari İşler",
        data: sortedScores.map((x) => +x.score.idari),
      },
      {
        name: "Hukuk Müşavirliği",
        data: sortedScores.map((x) => +x.score.hukuk),
      },
      {
        name: "Üretim",
        data: sortedScores.map((x) => +x.score.uretim),
      },
      {
        name: "Pazarlama",
        data: sortedScores.map((x) => +x.score.pazarlama),
      },
    ],
    categories: sortedScores.map((x) => x.date.toISOString()),
  };
}

import { useState } from "react";
import { motion } from "framer-motion";
import LongTextGeneralSettings from "../longText/pages/LongTextGeneralSettings";
import LongTextAdvanceSettings from "../longText/pages/LongTextAdvanceSettings";
import NumberOptionSettings from "./pages/NumberOptionSettings";
import FormButtons from "../../staticDesign/formSettings/FormButtons";
import { useTranslation } from "react-i18next";
import { framerSidebarPanel } from "../../../lib/consts";
import { useSetAtom } from "jotai";
import { showSettingsAtom } from "../../atoms/surveyAtoms";

const NumberFormSettings = () => {
  const setShowSettings = useSetAtom(showSettingsAtom);

  const [openTab, setOpenTab] = useState(1);
  const { t } = useTranslation();

  const tabs3 = [
    { index: 1, label: t("general"), flexBasis: "92px" },
    { index: 2, label: t("options"), flexBasis: "92px" },
    { index: 3, label: t("advance"), flexBasis: "92px" },
  ];
  return (
    <motion.div
      className={`w-full max-w-full border-l-2`}
      {...framerSidebarPanel}
    >
      <div className="flex flex-wrap">
        <div className="relative flex w-full min-w-0 flex-wrap items-center justify-between break-words border-b-2 p-2 text-sm font-semibold">
          Numara Özellikleri
          <span
            className="cursor-pointer rounded-md p-2 transition-all delay-100 hover:bg-blue-200"
            onClick={() => {
              setShowSettings(false);
            }}
          >
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 7.27757L1.52852 11.7491C1.36122 11.9164 1.14829 12 0.889734 12C0.631179 12 0.418251 11.9164 0.250951 11.7491C0.0836502 11.5817 0 11.3688 0 11.1103C0 10.8517 0.0836502 10.6388 0.250951 10.4715L4.72243 6L0.250951 1.52852C0.0836502 1.36122 0 1.14829 0 0.889734C0 0.631179 0.0836502 0.418251 0.250951 0.250951C0.418251 0.0836502 0.631179 0 0.889734 0C1.14829 0 1.36122 0.0836502 1.52852 0.250951L6 4.72243L10.4715 0.250951C10.6388 0.0836502 10.8517 0 11.1103 0C11.3688 0 11.5817 0.0836502 11.7491 0.250951C11.9164 0.418251 12 0.631179 12 0.889734C12 1.14829 11.9164 1.36122 11.7491 1.52852L7.27757 6L11.7491 10.4715C11.9164 10.6388 12 10.8517 12 11.1103C12 11.3688 11.9164 11.5817 11.7491 11.7491C11.5817 11.9164 11.3688 12 11.1103 12C10.8517 12 10.6388 11.9164 10.4715 11.7491L6 7.27757Z"
                fill="#475467"
              />
            </svg>
          </span>
        </div>
        <ul className="m-0 flex list-none p-0" role="tablist">
          {tabs3.map((tab) => (
            <FormButtons
              key={tab.index}
              index={tab.index}
              openTab={openTab}
              onClick={() => setOpenTab(tab.index)}
              label={tab.label?.toString() || ""}
              flexBasis={tab.flexBasis}
            />
          ))}
        </ul>
        <div className="relative mb-6 flex h-[calc(100vh-185px)] min-w-0 flex-col break-words bg-white shadow-lg">
          <div className="custom-scrollbar-container flex-auto py-0">
            <div
              className="tab-content tab-space mt-2 flex-auto"
              style={{ scrollbarGutter: "stable" }}
            >
              <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                <LongTextGeneralSettings />
              </div>
              <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                <NumberOptionSettings />
              </div>
              <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                <LongTextAdvanceSettings />
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default NumberFormSettings;

import FormSettingsTitle from "../../components/FormSettingsTitle";
import FormSettingsDescription from "../../components/FormSettingsDescription";
import FormSettingsTicketPosition from "../../components/FormSettingsTicketPosition";
import FormSettingsRequired from "../../components/FormSettingsRequired";
import FormSettingsCopy from "../../components/FormSettingsCopy";
import FormSettingsQuestionFix from "../../components/FormSettingsQuestionFix";
import FormSettingsHide from "../../components/FormSettingsHide";
import FormSettingsAddressBottomTicket from "../../components/FormSettingsAddressBottomTicket";

export default function AddressGeneralSettings() {
  return (
    <>
      <FormSettingsTitle />
      <FormSettingsDescription />
      <FormSettingsTicketPosition />
      <FormSettingsRequired />
      <FormSettingsAddressBottomTicket />
      <FormSettingsCopy />
      <FormSettingsQuestionFix />
      <FormSettingsHide />
    </>
  );
}

import { useEffect, useState } from "react";
import { Switch } from "@headlessui/react";
import { classNames } from "../../../lib/externalJS";
import { useAtom, useAtomValue } from "jotai";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../atoms/surveyAtoms";

const FormSettingsSelectMulti = () => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const [localState, setLocalState] = useState({
    selectMulti:
      stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]?.selectMulti,
  });

  const handleShuffleChange = (e: boolean) => {
    const newValue = e;
    setLocalState((prevState) => {
      let newState = { ...prevState };
      newState.selectMulti = newValue;
      return newState;
    });
  };

  useEffect(() => {
    setStateSurveyJSON((prevState) => {
      let newState = { ...prevState };
      newState.pages![pageIndex]!.elements![elementIndex]!.selectMulti =
        localState.selectMulti;
      return newState;
    });
  }, [localState]);

  return (
    <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
      <label className="mb-2 block text-sm font-bold text-gray-500">
        Çoklu Seçim
      </label>
      <div className="mb-2 mt-3 flex">
        <Switch
          id="flexSwitchCheckSelectMulti"
          checked={localState.selectMulti ? true : false}
          onChange={(e) => handleShuffleChange(e)}
          className={classNames(
            localState.selectMulti ? "bg-blue-600" : "bg-gray-200",
            "relative mr-2 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2",
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={classNames(
              localState.selectMulti ? "translate-x-5" : "translate-x-0",
              "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
            )}
          />
        </Switch>
        <label className="text-gray-500" htmlFor="flexSwitchCheckSelectMulti">
          {localState.selectMulti ? "Açık" : "Kapalı"}
        </label>
      </div>
      <p className="mb-0 mt-3 text-sm text-stone-500">
        Kullanıcılar birden fazla seçim yapabilsin
      </p>
    </div>
  );
};

export default FormSettingsSelectMulti;

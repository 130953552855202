import DescriptionButtonSurvey from "./headerComponents/DescriptionButtonSurvey";
import TitleChangerForSurvey from "./headerComponents/TitleChangerForSurvey";

type HeaderQuestion = {
  placement: string;
  pageIndex: number;
  index: number;
  isSelected: boolean;
  descValue: string | undefined;
  editorValue: string | undefined;
  shouldDisplay: boolean;
};

const QuestionHeader: React.FC<HeaderQuestion> = ({
  isSelected,
  placement,
  index,
  descValue,
  editorValue,
  pageIndex,
  shouldDisplay,
}) => {
  return (
    <div
      className={`!border-b-none relative box-border flex w-full min-w-0 flex-col break-words rounded !rounded-b-none !rounded-t-md border-x border-t bg-white ${
        isSelected ? "!border-blue-400" : ""
      }`}
      style={{
        gridColumn:
          placement === "Left" || placement === "Right" ? "span 6" : "",
      }}
    >
      <div className="flex flex-auto flex-wrap items-center justify-center px-5 py-3">
        <TitleChangerForSurvey
          index={index}
          editorValue={editorValue}
          pageIndex={pageIndex}
        />
        <DescriptionButtonSurvey
          shouldDisplay={shouldDisplay}
          placement={placement}
          value={descValue}
          index={index}
          pageIndex={pageIndex}
        />
      </div>
    </div>
  );
};

export default QuestionHeader;

import { useState, useEffect, ChangeEvent, FormEvent } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import FormCookerLogo from "../components/FormCookerLogo";
import PrimaryButton from "../components/tailwindUi/PrimaryButton";
import CheckSvg from "../images/checkSuccessfulRegister.svg";
import Warning from "../images/warning.svg";
import { useCreateUser } from "../services/mutations/userMutations";
import Dialog from "../components/tailwindUi/Dialog";
import Select from "../components/tailwindUi/Select";
import { incomeSelectOptionsTRY, turkeyCities } from "../lib/consts";
import DatePicker from "react-datepicker";
import TextInput from "../components/tailwindUi/TextInput";

type user = {
  name?: string;
  surname?: string;
  password?: string;
  confirmPassword: string;
  email: string;
};

export default function Register() {
  const location = useLocation();
  const isAudienceRegister = location.pathname === "/audience-register";

  const [modalOpen, setModalOpen] = useState(false);

  const navigateTo = useNavigate();

  const userInitial = {
    name: "",
    password: "",
    confirmPassword: "",
    email: "",
  };
  const [registerValues, setRegisterValues] = useState<user>(userInitial);
  const [passwordMismatch, setPasswordMismatch] = useState<boolean>(false);
  const createUserMutation = useCreateUser();

  const [isSuccessful, setIsSuccessful] = useState(false);
  const [countDown, setCountDown] = useState(5);

  // useEffect(() => {
  //   let interval = null;
  //   if (isSuccessful) {
  //     interval = setInterval(() => {
  //       setCountDown((prevState) => {
  //         if (prevState === 0) {
  //           if (interval) {
  //             clearInterval(interval);
  //           }
  //           return -1;
  //         } else {
  //           return prevState - 1;
  //         }
  //       });
  //     }, 1000);
  //   } else {
  //     if (interval) {
  //       clearInterval(interval);
  //     }
  //   }
  // }, [isSuccessful]);

  const updateUserValue = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setRegisterValues((prevValues: any) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (registerValues.password !== registerValues.confirmPassword) {
      setPasswordMismatch(true);
      console.error("Passwords do not match");
      return;
    }

    setPasswordMismatch(false);

    try {
      createUserMutation.mutate({
        ...registerValues,
        isActive: true,
        modified_date: new Date(),
        created_date: new Date(),
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const RegisterFirst = () => {
    return (
      <>
        <div className="mt-7 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Name
              </label>
              <div className="mt-2">
                <input
                  id="name"
                  name="name"
                  type="text"
                  autoComplete="name"
                  onBlur={updateUserValue}
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                E-mail
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  onBlur={updateUserValue}
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Password
                </label>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="new-password"
                  onBlur={updateUserValue}
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="confirm-password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Confirm Password
                </label>
              </div>
              <div className="mt-2">
                <input
                  id="confirm-password"
                  name="confirmPassword"
                  type="password"
                  autoComplete="new-password"
                  onBlur={updateUserValue}
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <PrimaryButton
              type={"submit"}
              onClick={() => {
                if (isAudienceRegister) {
                  setModalOpen(true);
                }
              }}
            >
              {!isAudienceRegister ? "Sign up" : "Participant sign up"}
            </PrimaryButton>
          </form>

          <p className="mt-6 text-center text-sm text-slate-700">
            {"If you have an account, "}
            <Link
              className="font-bold leading-6 text-blue-600 hover:text-blue-500"
              to="/login2"
            >
              Login
            </Link>
          </p>

          {!isAudienceRegister && (
            <p className="mt-6 text-center text-sm text-slate-700">
              To open an audience participant profile, <br />
              <Link
                className="font-bold leading-6 text-blue-600 hover:text-blue-500"
                to="/audience-register"
              >
                Audience sign up
              </Link>
            </p>
          )}

          <p className="mt-6 text-center text-[11px]  text-gray-500">
            By signing up to formcooker, you are agreeing to our{" "}
            <a className="border-black border-b font-semibold" href="#">
              privacy policy
            </a>
            {" and "}
            <a className="border-black border-b font-semibold" href="#">
              terms
            </a>
            .
          </p>
        </div>

        <AudienceFields />
      </>
    );
  };

  const RegisterSuccessful = () => {
    return (
      <div className="w-fit mx-auto mt-7 overflow-hidden rounded-lg bg-white shadow p-6">
        <div className="flex flex-col mt-7 sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="mx-auto w-12 h-12 px-3 pt-[11.63px] pb-[12.37px] bg-green-100 rounded-full justify-center items-center inline-flex">
            <div className="w-6 h-6 relative flex-col justify-start items-start flex">
              <img src={CheckSvg} />
            </div>
          </div>
          <p className="mt-3 text-center font-semibold font-['Inter'] text-base text-gray-900">
            Activation Code Send on Email!
          </p>

          <p className="mt-4 text-center font-normal font-['Inter'] text-sm text-gray-500">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur
            amet labore.
          </p>
          <form className="mt-5 space-y-5" action="#" method="POST">
            <div>
              <label
                htmlFor="one-time-code"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Activation Code
              </label>
              <div className="mt-2">
                <input
                  id="one-time-code"
                  name="one-time-code"
                  type="text"
                  autoComplete="one-time-code"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <PrimaryButton>Confirm</PrimaryButton>
            <PrimaryButton>Send back again ({countDown} seconds)</PrimaryButton>
          </form>
        </div>
      </div>
    );
  };

  const AudienceFields = () => {
    return (
      <Dialog
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        maxWidth={432}
      >
        <div className="flex flex-col gap-[20px]">
          <div className="flex-col justify-start items-center gap-[13px] inline-flex">
            <div className="w-12 h-12 p-3 bg-amber-100 rounded-full justify-center items-center inline-flex">
              <img src={Warning} />
            </div>

            <span className="text-center text-gray-900 text-base font-semibold leading-normal">
              Complete The Following Information
            </span>

            <span className="text-center text-gray-500 text-sm font-normal leading-tight">
              To join the audience platform you need to complete the following
              information
            </span>
          </div>

          <TextInput
            label="Date of Birth"
            id="dateOfBirth"
            name="dateOfBirth"
            type="date"
            errorMessage=""
            onChange={(e) => console.log("Date:", e.target.value)}
          />

          <Select
            label="Gender"
            id="gender"
            items={[
              { text: "Male", value: "male" },
              { text: "Female", value: "female" },
            ]}
            onChange={(selected) => console.log("Selected gender:", selected)}
            placeholder="Select Gender"
          />

          <Select
            label="City"
            id="city"
            items={turkeyCities.map((city) => ({ text: city, value: city }))}
            onChange={(selected) => console.log("Selected city:", selected)}
            placeholder="Select City"
          />

          <Select
            label="Household Income"
            id="houseHoldIncome"
            items={incomeSelectOptionsTRY}
            onChange={(selected) => console.log("Selected income:", selected)}
            placeholder="Select Household Income"
          />

          <PrimaryButton>Confirm</PrimaryButton>
        </div>
      </Dialog>
    );
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <FormCookerLogo className="mx-auto" width={150} />
        {!isSuccessful && (
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Create your free account
          </h2>
        )}
      </div>

      {!isSuccessful && RegisterFirst()}

      {isSuccessful && RegisterSuccessful()}
    </div>
  );
}

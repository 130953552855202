import axiosInstance from "../api";

export const getSurveyIds = async () => {
  return (await axiosInstance.get<any[]>("Survey/GetAll")).data;
};

export const getSurvey = async (id: string) => {
  return (
    await axiosInstance.get<any>(`Survey/GetById`, {
      params: { surveyId: id },
    })
  ).data;
};

export const getSurveyByUserID = async (id: string) => {
  return (
    await axiosInstance.get<any>(`Survey/GetById`, {
      params: { userId: id },
    })
  ).data;
};

export const createSurvey = async (data: any) => {
  await axiosInstance.post("/Survey/Add", data);
};

export const updateSurvey = async (data: any) => {
  await axiosInstance.put(`/Survey/Update`, data, {
    params: { surveyId: data._id },
  });
};

export const deleteSurvey = async (id: string) => {
  await axiosInstance.delete(`Survey/Delete`, {
    params: { surveyId: id },
  });
};

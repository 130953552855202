import { useState } from "react";
import { FilterChip } from "./DashboardCards";
import EmployeeProfileSkillsPanel from "./EmployeeProfileSkillsPanel";
import EmployeeProfileSkillsTech from "./EmployeeProfileSkillsTech";
import EmployeeProfileSkillsGeneral from "./EmployeeProfileSkillsGeneral";
import EmployeeProfileSkillsWorkingStyle from "./EmployeeProfileSkillsWorkingStyle";
import EmployeeProfileSkillsKnowledge from "./EmployeeProfileSkillsKnowledge";
import EmployeeProfileSkillsSkills from "./EmployeeProfileSkillsSkills";

const TABS = Object.freeze([
  "Yetenek Paneli",
  "Teknoloji Becerileri",
  "Genel Beceriler",
  "Çalışma Tarzı",
  "Bilgi",
  "Yetkinlikler",
]);

export default function EmployeeProfileSkills() {
  const [currentTab, setCurrentTab] = useState(TABS[0]);

  const CurrentTabComp = () => {
    switch (currentTab) {
      case "Yetenek Paneli":
        return <EmployeeProfileSkillsPanel />;
      case "Teknoloji Becerileri":
        return <EmployeeProfileSkillsTech />;
      case "Genel Beceriler":
        return <EmployeeProfileSkillsGeneral />;
      case "Çalışma Tarzı":
        return <EmployeeProfileSkillsWorkingStyle />;
      case "Bilgi":
        return <EmployeeProfileSkillsKnowledge />;
      case "Yetkinlikler":
        return <EmployeeProfileSkillsSkills />;
      default:
        return null;
    }
  };

  return (
    <div className="mt-10 flex flex-col gap-7">
      <div className="flex gap-x-[6px]">
        {TABS.map((tab, i) => (
          <button key={i} onClick={() => setCurrentTab(tab)}>
            <FilterChip key={tab} isActive={currentTab === tab} isForTabs>
              {tab}
            </FilterChip>
          </button>
        ))}
      </div>

      <CurrentTabComp />
    </div>
  );
}

import React from "react";
import { useTranslation } from "react-i18next";

const FormSettingsAddToLibrary = () => {
  const { t } = useTranslation();

  return (
    <div className="pt-3 pb-5 pl-5 pr-3 border-b-2">
      <label className="block text-gray-500 text-sm font-bold mb-2">
        {t("addToLibrary")}
      </label>
      <button className="icon-save-library-button border-2 border-blue-500 text-sm font-semibold rounded-md text-center align-middle hover:bg-blue-100 text-blue-600 px-3 py-2 mt-3 mb-2">
        <div className="flex items-center">
          <svg
            className="icon-save-library"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.0001 10.8333V5.83333M7.50008 8.33333H12.5001M15.8334 17.5V6.5C15.8334 5.09987 15.8334 4.3998 15.5609 3.86502C15.3212 3.39462 14.9388 3.01217 14.4684 2.77248C13.9336 2.5 13.2335 2.5 11.8334 2.5H8.16675C6.76662 2.5 6.06655 2.5 5.53177 2.77248C5.06137 3.01217 4.67892 3.39462 4.43923 3.86502C4.16675 4.3998 4.16675 5.09987 4.16675 6.5V17.5L10.0001 14.1667L15.8334 17.5Z"
              stroke="#2970FF"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          {t("add")}
        </div>
      </button>
      <p className="text-sm text-stone-500 mt-3 mb-0 ">
        {t("addToLibraryDescription")}
      </p>
    </div>
  );
};

export default FormSettingsAddToLibrary;

import { useMutation, useQueryClient } from "react-query";
import {
  createSurveyReply,
  deleteSurveyReply,
  updateSurveyReply,
} from "../api/surveyReplyApi";

export function useCreateSurveyReply() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: any) => createSurveyReply(data),
    onMutate: () => {},

    onError: (e) => {},

    onSuccess: () => {},
    onSettled: async (_: any, error: any) => {
      await queryClient.invalidateQueries("SurveyReplayBySurveyID");
    },
  });
}

export function useUpdateSurveyReply() {

  return useMutation({
    mutationFn: (data: any) => updateSurveyReply(data),

    onSuccess: async () => {},

    onSettled: async (_: any, error: any, variables: any) => {
      if (error) {
        console.error(error);
      } else {
      }
    },
  });
}

export function useDeleteSurveyReply() {

  return useMutation({
    mutationFn: (data: any) => deleteSurveyReply(data.id),
    onMutate: (data: any) => {},

    onError: (e) => {},

    /*  onSuccess: async () => {
      await queryClient.invalidateQueries("SurveyReplayBySurveyID");
    }, */

    onSettled: async (_: any, error: any) => {},
  });
}

import { ProgressCircle } from "@tremor/react";
import { selectedUserAtom } from "components/atoms/dashboardAtoms";
import { useAtomValue } from "jotai";
import { PanelSplineGraph } from "pages/dashboard2/Panel/Panel";
import { classNames } from "../../lib/externalJS";
import { Progress } from "./Dashboard2";
import EmployeeProfileCard from "./EmployeeProfileCard";

const PERSONALITY_TRAITS = Object.freeze([
  {
    leftTitle: "Dışa Dönük",
    leftLetter: "E",
    rightLetter: "I",
    rightTitle: "İçe Dönük",
  },
  {
    leftTitle: "Sağ Duyulu",
    leftLetter: "S",
    rightLetter: "N",
    rightTitle: "Sezgisel",
  },
  {
    leftTitle: "Düşünen",
    leftLetter: "T",
    rightLetter: "F",
    rightTitle: "Hisseden",
  },
  {
    leftTitle: "Yargılayan",
    leftLetter: "J",
    rightLetter: "P",
    rightTitle: "Algılayan",
  },
]);

export default function EmployeeProfileHighlights() {
  const selectedUser = useAtomValue(selectedUserAtom);

  const activatedTraits = PERSONALITY_TRAITS.map((trait, traitIndex) => ({
    ...trait,
    active:
      selectedUser.kisilikProfili.charAt(traitIndex) === trait.leftLetter
        ? "L"
        : "R",
  }));

  const latestPerformansScore = getLatestDegerlendirme(
    selectedUser.degerlendirme_performans,
  ).score;

  const latestKurumDegerleriScore = getLatestDegerlendirme(
    selectedUser.degerlendirme_kurumDeğerleri,
  ).score;

  const latestHedefScore = getLatestDegerlendirme(
    selectedUser.degerlendirme_hedef,
  ).score;

  const latestYetkinliklerScore = getLatestDegerlendirme(
    selectedUser.degerlendirme_yetkinlikler,
  ).score;

  const mutlulukScores = getSortedDegerlendirme(
    selectedUser.degerlendirme_mutluluk,
  );

  return (
    <div className="mt-10 flex flex-col gap-y-10">
      <EmployeeProfileCard
        titleEmoji="🎭"
        title="Kişisel Özelliklerim"
        bgClassName="bg-orange-100"
      >
        <div className="flex gap-x-15 px-[30px]">
          <div className="flex w-[320px] flex-col gap-y-[6px]">
            {activatedTraits.map((trait) => {
              return (
                <div
                  className="relative flex h-13 items-center justify-between"
                  key={trait.leftTitle}
                >
                  <TitleComp
                    title={trait.leftTitle}
                    isActive={trait.active === "L"}
                    isLeft
                  />

                  <div className="absolute left-1/2 flex -translate-x-1/2 transform">
                    <LetterComp
                      letter={trait.leftLetter}
                      isActive={trait.active === "L"}
                    />
                    <LetterComp
                      letter={trait.rightLetter}
                      isActive={trait.active === "R"}
                    />
                  </div>

                  <TitleComp
                    title={trait.rightTitle}
                    isActive={trait.active === "R"}
                  />
                </div>
              );
            })}
          </div>

          <div className="items-left flex w-[520px] flex-col justify-center">
            <h1 className="text-base font-medium leading-normal text-gray-800">
              Öne Çıkanlar:
            </h1>
            <ul className="list-disc pl-5">
              {selectedUser.estj.map((highlight: any) => (
                <li>{highlight}</li>
              ))}
            </ul>
          </div>
        </div>
      </EmployeeProfileCard>

      <EmployeeProfileCard
        titleEmoji="🔋"
        title="Performansım"
        bgClassName="bg-lime-100"
      >
        <div className="flex items-center justify-center gap-x-22">
          <ProgressCircle
            value={+latestPerformansScore}
            color={"green"}
            radius={75}
            strokeWidth={12}
          >
            <div className="flex flex-col">
              <span className="text-center text-[28px] font-bold text-gray-800">
                %{latestPerformansScore}
              </span>
              <span className="text-center text-xs font-normal text-gray-900">
                Performans
              </span>
            </div>
          </ProgressCircle>

          <div className="flex flex-col gap-y-4">
            {[
              ["Kurum Değerleri", latestKurumDegerleriScore, "red"],
              ["Hedef", latestHedefScore, "amber"],
              ["Yetkinlikler", latestYetkinliklerScore, "green"],
            ].map((x) => (
              <div className="flex items-center text-sm font-medium leading-tight">
                <span className="mr-4 w-28 text-gray-800">{x[0]}</span>

                <div className="w-[278px]">
                  <Progress isLabelsHidden color={x[2]} value={x[1]} />
                </div>

                <span className="ml-3 text-slate-700">{x[1] + "%"}</span>
              </div>
            ))}
          </div>
        </div>
      </EmployeeProfileCard>

      <EmployeeProfileCard
        titleEmoji="😊"
        title="Mutluluk Düzeyim"
        bgClassName="bg-yellow-100"
      >
        <PanelSplineGraph
          series={[
            {
              name: "Mutluluk",
              data: mutlulukScores.map((x: any) => +x.score),
            },
          ]}
          colors={["#3B82F6"]}
          isBigger
          isForMutluluk
          categories={mutlulukScores.map((x: any) => x.date.toISOString())}
          range={
            mutlulukScores[0].date.getTime() - new Date("2022-09-16").getTime()
          }
        />
      </EmployeeProfileCard>
    </div>
  );
}

const TitleComp = ({
  title,
  isActive,
  isLeft,
}: {
  title: string;
  isActive: boolean;
  isLeft?: boolean;
}) => (
  <span
    className={classNames(
      "text-sm font-semibold leading-tight",
      isActive ? "text-orange-500" : "text-gray-500",
      isLeft ? "pr-13" : "pl-13",
    )}
  >
    {title}
  </span>
);

const LetterComp = ({
  letter,
  isActive,
}: {
  letter: string;
  isActive: boolean;
}) => (
  <div
    className={classNames(
      "flex h-13 w-13 items-center justify-center rounded text-[28px] font-semibold",
      isActive ? "bg-orange-100 text-orange-500" : "bg-white text-gray-500",
    )}
  >
    {letter}
  </div>
);

const TURKISH_SHORT_MONTHS = Object.freeze([
  "Oca",
  "Şub",
  "Mar",
  "Nis",
  "May",
  "Haz",
  "Tem",
  "Ağu",
  "Eyl",
  "Eki",
  "Kas",
  "Ara",
]);

const ENGLISH_SHORT_MONTHS = Object.freeze([
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
]);

function turkishShortMonthToMonthNumber(trMonth: string): number {
  let index = TURKISH_SHORT_MONTHS.indexOf(trMonth);
  if (index === -1) {
    index = ENGLISH_SHORT_MONTHS.indexOf(trMonth);
  }
  if (index === -1) {
    throw new Error("Invalid month");
  }
  return index;
}

function parseDegerlendirmeDates(arr: any): any {
  return arr.map((x: any) => {
    const splitted =
      x.date.indexOf("-") !== -1 ? x.date.split("-") : x.date.split(".");
    return {
      date: new Date(
        parseInt("20" + splitted[2]),
        turkishShortMonthToMonthNumber(splitted[1]),
        parseInt(splitted[0]),
      ),
      score: x.score,
    };
  });
}

export function getSortedDegerlendirme(arr: any[]): any[] {
  return parseDegerlendirmeDates(arr).sort((a: any, b: any) => {
    return b.date.getTime() - a.date.getTime();
  });
}

function getLatestDegerlendirme(arr: any): any {
  return getSortedDegerlendirme(arr)[0];
}

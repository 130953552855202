import { useAtom, useAtomValue } from "jotai";
import { ChangeEvent, useEffect, useState } from "react";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../atoms/surveyAtoms";

const FormSettingsDefaultInput = () => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const [localState, setLocalState] = useState({
    inputAreaDefaultValue:
      stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
        ?.inputAreaDefaultValue,
  });

  useEffect(() => {
    setStateSurveyJSON((prevState) => {
      let newState = { ...prevState };
      newState.pages![pageIndex]!.elements![
        elementIndex
      ]!.inputAreaDefaultValue = localState.inputAreaDefaultValue;
      return newState;
    });
  }, [localState]);

  const handleDefaultInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    setLocalState((prevState) => {
      const newState = { ...prevState };
      newState.inputAreaDefaultValue = { tr: newValue };
      return newState;
    });
  };

  return (
    <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
      <label
        className="block text-sm font-bold text-gray-500"
        htmlFor="enterQuestion"
      >
        Varsayılan Değer
      </label>
      <textarea
        className="mt-3 w-full resize-none rounded border border-gray-300 focus:outline-none"
        onChange={(e) => {
          handleDefaultInputChange(e);
        }}
        value={localState.inputAreaDefaultValue?.tr}
      />
      <p className="mb-0 mt-3 text-sm text-stone-500">
        Bu alana varsayılan bir değeri önceden girin
      </p>
    </div>
  );
};

export default FormSettingsDefaultInput;

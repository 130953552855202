import { useAtom } from "jotai";
import React, { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { surveyAtom } from "../atoms/surveyAtoms";

type Props = {
  setAlterPageStatus: Dispatch<SetStateAction<number>>;
  alterPageStatus: number;
};

const ShareNavbar: React.FC<Props> = ({
  setAlterPageStatus,
  alterPageStatus,
}) => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);

  const navigate = useNavigate();

  const toPreview = () => {
    navigate("/survey/" + stateSurveyJSON._id, { state: stateSurveyJSON });
  };

  return (
    <div>
      <header className="flex flex-wrap justify-between border-b py-1">
        <div className="col-lg-2 w-[213px]"></div>

        <ul className="mb-0 flex items-center justify-center">
          <li className="px-2">
            <span
              onClick={() => setAlterPageStatus(1)}
              className={`nav-link px-2 ${
                alterPageStatus === 1
                  ? "border-3 border-b border-blue-500 py-3 text-blue-500"
                  : "text-gray-500"
              } cursor-pointer`}
            >
              Hızlı Paylaş
            </span>
          </li>
          <li className="px-2">
            <span
              onClick={() => setAlterPageStatus(2)}
              className={`nav-link cursor-pointer px-2 ${
                alterPageStatus === 2
                  ? "border-3 border-b border-blue-500 py-3 text-blue-500"
                  : "text-gray-500"
              }`}
            >
              E-postayla Paylaş
            </span>
          </li>
          <li className="px-2">
            <span
              onClick={() => setAlterPageStatus(3)}
              className={`nav-link cursor-pointer px-2 ${
                alterPageStatus === 3
                  ? "border-3 border-b border-blue-500 py-3 text-blue-500"
                  : "text-gray-500"
              }`}
            >
              360° Paylaş
            </span>
          </li>
          {/*      <li className="px-2">
            <span
              onClick={() => setAlterPageStatus(4)}
              className={`nav-link px-2 link-secondary cursor-pointer ${
                alterPageStatus === 4
                  ? "link-primary  border-bottom  border-3 border-primary"
                  : "link-secondary"
              }`}
            >
              360°/Referans ile Paylaş
            </span>
          </li> */}
        </ul>

        <div className="flex flex-wrap justify-center text-end">
          <button
            onClick={() => toPreview()}
            type="button"
            className="delay-50 fw-semibold justify-content-center align-items-center mr-2 flex w-30 items-center justify-center whitespace-nowrap rounded-md border px-[0.75rem] py-[0.375rem] text-center align-middle opacity-0 shadow-sm transition-all"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="mr-1"
              viewBox="0 0 16 16"
            >
              <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
              <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
            </svg>
            Ön İzleme
          </button>

          <button
            onClick={() =>
              setStateSurveyJSON((prev) => ({ ...prev, isActive: true }))
            }
            type="button"
            className="border-1 border-secondary fw-semibold justify-content-center align-items-center me-2 flex flex-wrap rounded-lg bg-[#2970ff] px-3 py-2 text-center align-middle text-sm text-white shadow-sm hover:bg-blue-700"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="bi bi-eye me-2"
            >
              <path
                d="M9.9987 12.5L7.4987 10M9.9987 12.5C11.1627 12.0573 12.2794 11.499 13.332 10.8334M9.9987 12.5V16.6667C9.9987 16.6667 12.5237 16.2084 13.332 15C14.232 13.65 13.332 10.8334 13.332 10.8334M7.4987 10C7.94215 8.84957 8.50053 7.74676 9.16536 6.70838C10.1363 5.15587 11.4884 3.87758 13.0929 2.99512C14.6973 2.11266 16.5009 1.65535 18.332 1.66671C18.332 3.93338 17.682 7.91671 13.332 10.8334M7.4987 10H3.33203C3.33203 10 3.79036 7.47504 4.9987 6.66671C6.3487 5.76671 9.16536 6.66671 9.16536 6.66671M3.7487 13.75C2.4987 14.8 2.08203 17.9167 2.08203 17.9167C2.08203 17.9167 5.1987 17.5 6.2487 16.25C6.84036 15.55 6.83203 14.475 6.1737 13.825C5.84979 13.5159 5.42311 13.3372 4.97555 13.3234C4.528 13.3096 4.09109 13.4615 3.7487 13.75Z"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Yayınla
          </button>
        </div>
      </header>
    </div>
  );
};

export default ShareNavbar;

import FormSettingsWidth from "../../components/FormSettingsWidth";
import FormSettingsEnterLimit from "../../components/FormSettingsEnterLimit";
import FormSettingsDoubleVerify from "../../components/FormSettingsDoubleVerify";
import FormSettingsDoNotAllowFreeMail from "../../components/FormSettingsDoNotAllowFreeMail";

export default function EmailOptionSettings() {
  return (
    <>
      <FormSettingsWidth />
      <FormSettingsEnterLimit />
      <FormSettingsDoubleVerify />
      <FormSettingsDoNotAllowFreeMail />
    </>
  );
}

import FormSettingsTip from "../../components/FormSettingsTip";
import FormSettingsCollapse from "../../components/FormSettingsCollapse";
import FormSettingsReadOnly from "../../components/FormSettingsReadOnly";
import FormSettingsAddToLibrary from "../../components/FormSettingsAddToLibrary";
import FormSettingsDefaultInput from "../../components/FormSettingsDefaultInput";
import FormSettingsPlaceholder from "../../components/placeholder/FormSettingsPlaceholder";

export default function LongTextAdvanceSettings() {
  return (
    <>
      <FormSettingsPlaceholder />
      <FormSettingsTip />
      <FormSettingsDefaultInput />
      <FormSettingsCollapse />
      <FormSettingsReadOnly />
      <FormSettingsAddToLibrary />
    </>
  );
}

import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { DocumentTextIcon } from "@heroicons/react/24/outline";
import AssessmentCenterIcon from "images/assessment-center.svg";
import { FC, Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { surveyInterface } from "../../Interfaces/SurveyInterfaces";
import Badge from "../../components/tailwindUi/Badge";
import Dialog from "../../components/tailwindUi/Dialog";
import {
  useDeleteSurvey,
  useUpdateSurveyDashboard,
} from "../../services/mutations/surveyMutations";
import { API_DOMAIN } from "../../settings";
import DeleteFormDialog from "./DeleteFormDialog";
import NewFormDialog from "./NewFormDialog";
import PickTemplateDialog from "./PickTemplateDialog";
import RenameFormDialog from "./RenameFormDialog";

const threeDotMenuItems = [
  { text: "Rename", value: "rename" },
  { text: "Duplicate", value: "duplicate" },
  { text: "Disable Form", value: "disable" },
  { text: "Delete", value: "delete" },
] as const;

export type dashboardModalValueType =
  | (typeof threeDotMenuItems)[number]["value"]
  | "new"
  | "pickTemplate"
  | "";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}
interface DashboardTypes {
  userSurveys?: surveyInterface[] | undefined;
  isAudience?: boolean;
}

const DashboardCards: FC<DashboardTypes> = ({ userSurveys, isAudience }) => {
  const filters = !isAudience
    ? [
        { text: "Tümü", value: "all" },
        { text: "Tamamlananlar", value: "completed" },
        { text: "Devam Edenler", value: "notCompleted" },
      ]
    : [
        { text: "All", value: "all" },
        { text: "New", value: "new" },
        { text: "Completed", value: "completed" },
      ];

  const [activeFilter, setActiveFilter] = useState(filters[0].value);
  const [modalValue, setModalValue] = useState<dashboardModalValueType>("");
  const [selectedSurvey, setSelectedSurvey] = useState<surveyInterface>();
  const deleteSurveyMutation = useDeleteSurvey();
  const updateSurveyMutation = useUpdateSurveyDashboard();
  const navigate = useNavigate();
  /* TODO isActive false olunca data veri tabanından gelmiyor aktifliğe göre getir getirme durumu olmamalı */

  const getModalParams = () => {
    switch (modalValue) {
      case "new":
        return {
          maxWidth: 628,
          ModalChildren: <NewFormDialog setModalValue={setModalValue} />,
        };
      case "pickTemplate":
        return {
          maxWidth: -1,
          ModalChildren: <PickTemplateDialog setModalValue={setModalValue} />,
          isFullHeight: true,
        };
      case "rename":
        return {
          maxWidth: 400,
          ModalChildren: selectedSurvey && (
            <RenameFormDialog
              selectedSurvey={selectedSurvey}
              setModalValue={setModalValue}
              updateSurveyMutation={updateSurveyMutation}
            />
          ),
        };
      case "duplicate":
        return {
          maxWidth: 400,
          ModalChildren: <div>Duplicate</div>,
        };
      case "disable":
        return {
          maxWidth: 400,
          ModalChildren: (
            <button
              className="padding h-full w-full rounded border"
              onClick={() => {
                updateSurveyMutation.mutate({
                  ...selectedSurvey,
                  isActive: !selectedSurvey?.isActive,
                });
                setModalValue("");
              }}
            >
              {selectedSurvey?.isActive ? "Disable Form" : "Enable Form"}
            </button>
          ),
        };
      case "delete":
        return {
          maxWidth: 400,
          ModalChildren: selectedSurvey && (
            <DeleteFormDialog
              selectedSurvey={selectedSurvey}
              setModalValue={setModalValue}
              deleteSurveyMutation={deleteSurveyMutation}
            />
          ),
        };
      default:
        return {
          maxWidth: 400,
          ModalChildren: <></>,
        };
    }
  };

  const handleItemClick = (itemValue: any, client: surveyInterface) => {
    setModalValue(itemValue);
    setSelectedSurvey(client);
  };

  const toSurvey = (id: string) => {
    navigate("/createSurvey/" + id);
  };

  const { maxWidth, ModalChildren, isFullHeight } = getModalParams() as {
    maxWidth: number;
    ModalChildren: JSX.Element;
    isFullHeight?: boolean;
  };

  const surveys =
    userSurveys ??
    ([
      {
        _id: "asd",
        title: { tr: "ABC_360_4rd_2023" },
        created_date: "2023-12-04T03:24:00",
        isActive: true,
      },
      {
        _id: "asd2",
        title: { tr: "ABC_360_3rd_2023" },
        created_date: "2023-09-17T03:24:00",
        isActive: false,
      },
    ] as unknown as surveyInterface[]);

  return (
    <div>
      <div className="mb-6 flex items-center">
        <div className="p-1.5 mr-3 inline-flex h-9 w-9 items-center justify-center rounded bg-teal-100">
          <img src={AssessmentCenterIcon} className="h-6 w-6" />
        </div>

        <div className="inline-flex text-2xl font-semibold leading-normal text-gray-800">
          Değerlendirme Merkezi
        </div>
      </div>

      <div className="mb-6 inline-flex gap-1">
        {filters.map((filter, i) => (
          <button key={i} onClick={() => setActiveFilter(filter.value)}>
            <FilterChip
              key={filter.value}
              isActive={activeFilter === filter.value}
            >
              {filter.text}
            </FilterChip>
          </button>
        ))}
      </div>

      <ul className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
        <CreateNewFormButton setModalValue={setModalValue} />

        {surveys
          ?.filter(
            (x) =>
              activeFilter === "all" ||
              (activeFilter === "completed" && !x.isActive) ||
              (activeFilter === "notCompleted" && x.isActive),
          )
          .map((client) => (
            <li
              key={client._id}
              className="rounded-xl border border-gray-200 bg-white"
            >
              <div className="flex items-center gap-x-4 rounded-t-xl border-b border-gray-900/5 bg-white px-5 py-7">
                <div
                  className="cursor-pointer rounded-lg border bg-white p-[6px]"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      API_DOMAIN + "/survey/replay/" + client._id,
                    );
                    toast.warning("sınav linki kopyalandı");
                  }}
                >
                  <DocumentTextIcon className="h-6 w-6 text-blue-600" />
                </div>
                <div
                  className="cursor-pointer text-sm font-medium leading-6 text-gray-900" /* hover:bg-slate-200 */
                  onClick={() => toSurvey(client._id)}
                >
                  {client.title ? client.title["tr"] : "İsimsiz Form"}
                </div>
                {!isAudience && (
                  <Menu as="div" className="relative ml-auto">
                    <Menu.Button
                      className="-m-2.5 p-2.5 block text-gray-400 hover:text-gray-500"
                      type="button"
                    >
                      <span className="sr-only">Open options</span>
                      <EllipsisVerticalIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="mt-0.5 absolute right-0 z-10 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                        {threeDotMenuItems.map((item, index) => (
                          <Menu.Item key={item.value}>
                            {({ active }) => (
                              <button
                                onClick={() =>
                                  handleItemClick(item.value, client)
                                }
                                className={classNames(
                                  active ? "bg-gray-50" : "",
                                  index === threeDotMenuItems.length - 1
                                    ? "border-t text-red-500"
                                    : "text-gray-900",
                                  "flex w-full px-3 py-1 text-sm leading-6",
                                )}
                              >
                                {item.text}
                                <span className="sr-only">
                                  {client.title
                                    ? client.title["tr"]
                                    : "İsimsiz Form"}
                                </span>
                              </button>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                )}
              </div>
              <dl className="-my-3 divide-y divide-gray-100 px-5 py-4 text-sm leading-6">
                <div className="flex justify-between gap-x-4 py-3">
                  <dt className="text-gray-500">
                    {!isAudience ? "Oluşturulma Tarihi" : "Response Time"}
                  </dt>
                  <dd className="text-gray-700">
                    <time
                      dateTime={new Date(
                        !isAudience ? client.created_date : client.created_date, // TODO: client.response_date
                      ).toISOString()}
                    >
                      {new Date(
                        !isAudience ? client.created_date : client.created_date, // TODO: client.response_date
                      ).toLocaleDateString()}
                    </time>
                  </dd>
                </div>
                <div className="flex justify-between gap-x-4 py-3">
                  <dt className="text-gray-500">
                    {!isAudience ? "Yanıtlayanlar" : "Prize"}
                  </dt>
                  <dd className="flex items-start gap-x-2">
                    <div className="font-medium text-gray-900">
                      {/* TODO henüz gelen cevaplarin sayisi surveyinterface tutmuyor veya backendde birleştirilip gönderilmiyor */}
                      {Math.floor(Math.random() * 2000) +
                        1 +
                        (isAudience ? " TL" : "")}
                    </div>
                    {!isAudience ? (
                      <Badge
                        color={statusToColor(
                          client.isActive ? "Devam Ediyor" : "Tamamlandı",
                        )}
                      >
                        {client.isActive ? "Devam Ediyor" : "Tamamlandı"}
                      </Badge>
                    ) : (
                      <Badge
                        color={statusToColor(
                          client.isActive ? "Progress" : "Completed",
                        )}
                      >
                        {/* // TODO: audienceStatus */}
                        {client.isActive ? "Progress" : "Completed"}
                      </Badge>
                    )}
                  </dd>
                </div>
              </dl>
            </li>
          ))}
      </ul>

      <Dialog
        isOpen={Boolean(modalValue)}
        onClose={() => {
          setModalValue("");
          setSelectedSurvey(undefined);
        }}
        maxWidth={maxWidth}
        isFullHeight={isFullHeight}
      >
        {ModalChildren}
      </Dialog>
    </div>
  );
};

export function FilterChip({
  children,
  isActive,
  isForTabs,
}: {
  children?: React.ReactNode;
  isActive?: boolean;
  isForTabs?: boolean;
}) {
  return (
    <div
      className={classNames(
        "gap-2.5 inline-flex flex-col items-center justify-center rounded-md",
        isActive ? "bg-blue-50" : "",
        isForTabs ? "px-3 py-2" : "px-4 py-1",
      )}
    >
      <div
        className={classNames(
          "text-sm font-medium leading-normal hover:text-blue-600",
          isActive ? "text-blue-600" : "text-gray-500",
        )}
      >
        {children}
      </div>
    </div>
  );
}

function CreateNewFormButton({
  setModalValue,
}: {
  setModalValue: React.Dispatch<React.SetStateAction<dashboardModalValueType>>;
}) {
  return (
    <button
      type="button"
      className="icon-path-parent relative block w-full rounded-lg border-2 border-dashed border-gray-300 bg-white p-12 text-center text-gray-900 hover:border-blue-600 hover:bg-slate-50 hover:text-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
      onClick={() => setModalValue("new")}
    >
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="mx-auto"
      >
        <g id="clipboard-plus">
          <path
            id="Icon"
            d="M32.8818 8C34.7418 8 35.6718 8 36.4347 8.20444C38.5053 8.75925 40.1226 10.3765 40.6774 12.4471C40.8818 13.2101 40.8818 14.1401 40.8818 16V34.4C40.8818 37.7603 40.8818 39.4405 40.2279 40.7239C39.6526 41.8529 38.7348 42.7708 37.6058 43.346C36.3223 44 34.6422 44 31.2818 44H18.4818C15.1215 44 13.4414 44 12.1579 43.346C11.0289 42.7708 10.111 41.8529 9.5358 40.7239C8.88184 39.4405 8.88184 37.7603 8.88184 34.4V16C8.88184 14.1401 8.88184 13.2101 9.08628 12.4471C9.64108 10.3765 11.2584 8.75925 13.3289 8.20444C14.0919 8 15.0219 8 16.8818 8M24.8818 34V22M18.8818 28H30.8818M20.0818 12H29.6818C30.8019 12 31.362 12 31.7898 11.782C32.1661 11.5903 32.4721 11.2843 32.6638 10.908C32.8818 10.4802 32.8818 9.92011 32.8818 8.8V7.2C32.8818 6.0799 32.8818 5.51984 32.6638 5.09202C32.4721 4.71569 32.1661 4.40973 31.7898 4.21799C31.362 4 30.8019 4 29.6818 4H20.0818C18.9617 4 18.4017 4 17.9739 4.21799C17.5975 4.40973 17.2916 4.71569 17.0998 5.09202C16.8818 5.51984 16.8818 6.0799 16.8818 7.2V8.8C16.8818 9.92011 16.8818 10.4802 17.0998 10.908C17.2916 11.2843 17.5975 11.5903 17.9739 11.782C18.4017 12 18.9617 12 20.0818 12Z"
            stroke={"#6B7280"}
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="icon-path"
          />
        </g>
      </svg>
      <span className="mt-2 block text-sm font-semibold">
        Yeni Değerlendirme Oluştur
      </span>
    </button>
  );
}

function statusToColor(status: string) {
  switch (status) {
    case "Active":
      return "green";
    case "Disabled":
      return "red";
    case "Completed":
      return "green";
    case "Progress":
      return "amber";
    case "Not started":
      return "red";
    case "Devam Ediyor":
      return "green";
    case "Tamamlandı":
      return "red";
    default:
      return "gray";
  }
}

export default DashboardCards;

import React from "react";
import {
  elementChoicesInterface,
  surveyElementsInterface,
} from "../../Interfaces/SurveyInterfaces";
import {
  Input,
  InputButton,
  InputButtonMulti,
  SelectItem,
} from "./components/FormCompoents";

type Props = {
  question: surveyElementsInterface;
  endOfPage: boolean;
  control: any;
  register: any;
  setValue: any;
  errors: any;
};

const DataTableQuestion: React.FC<Props> = ({
  question,
  endOfPage,
  control,
  register,
  setValue,
  errors,
}) => {
  const dynamicPaths = question.rows!.map(
    (sId) => `${question._id}dataTable${sId._id}`
  );

  const matchedPath = dynamicPaths.find((path) => {
    const key = Object.keys(errors).find((key) => path.startsWith(key));
    return key && errors[key]?.value?.message;
  });
  return (
    <div>
      <h6
        className="mb-0"
        dangerouslySetInnerHTML={{ __html: question.title?.tr ?? "" }}
      ></h6>
      <p className=" text-gray-500">{question.description?.tr}</p>
      <div
        className={`pl-3 !pr-0 pt-3 pb-1  min-w-[300px] `}
        style={{
          overflowX: "auto",
        }}
      >
        <table
          className="border-separate border-spacing-0 min-w-[300px]"
          style={{
            width: question.rowWidth ? question.rowWidth : "100%",
          }}
          data-title={question.tipText?.tr}
        >
          <tbody className="">
            <tr className="">
              <th></th>
              {question.columns?.map(
                (column: elementChoicesInterface, index: number) => (
                  <th
                    className="bg-[#EFF4FF]  font-normal h-full  border border-stone-300 px-0 "
                    key={column._id}
                    style={{
                      borderRadius:
                        index === 0
                          ? "12px 0px 0px 0px"
                          : index ===
                                (question.columns
                                  ? question.columns?.length - 1
                                  : 0) && question.showNoneItem !== true
                            ? "0px 12px 0px 0px"
                            : "",
                      width: question.columnWidth
                        ? question.columnWidth
                        : "96px",
                    }}
                  >
                    <span className=" flex flex-wrap items-center justify-center text-center min-w-[100px] flex-auto">
                      {column.title?.tr ? column.title?.tr : column._id}
                    </span>
                  </th>
                )
              )}
              {question.showNoneItem ? (
                <th
                  className="bg-[#EFF4FF]  font-normal h-full border border-stone-300 px-0"
                  style={{
                    borderRadius: "0px 12px 0px 0px",
                    width: question.columnWidth ? question.columnWidth : "96px",
                  }}
                >
                  <span className=" flex flex-wrap items-center justify-center text-center min-w-[100px] flex-auto">
                    {question.noneText?.tr
                      ? question.noneText.tr
                      : "Fikrim Yok"}
                  </span>
                </th>
              ) : null}
            </tr>

            {question.rows?.map(
              (row: elementChoicesInterface, index: number) => {
                const numbers: string[] = row.content?.split(",") || [];
                const infos: string[] = row.info?.tr.split(",") || [];

                return (
                  <tr key={row._id}>
                    <td
                      className="bg-[#EFF4FF] font-normal  h-full   ! border border-stone-300 px-0"
                      style={{
                        borderRadius:
                          index === 0
                            ? "12px 0px 0px 0px"
                            : index ===
                                (question.rows ? question.rows?.length - 1 : 0)
                              ? "0px 0px 0px 12px"
                              : "",
                      }}
                    >
                      <span className=" flex flex-wrap items-center justify-start text-center px-2 min-h-[2.5rem] break-all  w-24 flex-auto">
                        {row.title?.tr ? row.title?.tr : row._id}
                      </span>
                    </td>
                    {question.columns?.map(
                      (_: elementChoicesInterface, i: number) => (
                        <td className="border text-center" key={_._id}>
                          {question.cellType === "RadioButton" ? (
                            <InputButton
                              name={question._id + "dataTable" + row._id}
                              readOnly={question.readOnly ? question.readOnly : false}
                              className={`form-check-input ${
                                !!matchedPath && "!border-red-500"
                              }`}
                              style={{ transform: "scale(1.2)" }}
                              required={question?.isRequired ?? false}
                              register={register}
                              type="radio"
                              value={numbers?.[i] ?? _.title?.tr}
                              setValue={setValue}
                              optionTitle={_.title?.tr}
                              questionTitle={
                                row.title?.tr
                                  ? row.title.tr
                                  : "Satır " + (i + 1)
                              }
                              title={infos?.[i]}
                            />
                          ) : question.cellType === "CheckBox" ? (
                            <InputButtonMulti
                              name={question._id + "dataTable" + row._id}
                              readOnly={question.readOnly ? question.readOnly : false}
                              className={`w-full h-5 ${
                                !!matchedPath && "!border-red-500"
                              }`}
                              required={question?.isRequired ?? false}
                              register={register}
                              type="checkbox"
                              value={numbers?.[i] ?? _.title?.tr}
                              setValue={setValue}
                              optionTitle={_.title?.tr}
                              questionTitle={
                                row.title?.tr
                                  ? row.title.tr
                                  : "Satır " + (i + 1)
                              }
                              title={infos?.[i]}
                            />
                          ) : question.cellType === "SelectList" ? (
                            <SelectItem
                              control={control}
                              className="basic-single w-full h-full z-50"
                              questionTitle={
                                row.title?.tr
                                  ? row.title.tr
                                  : "Satır " + (i + 1)
                              }
                              options={[
                                ...(question.choices || []).map(
                                  (choice: elementChoicesInterface) => ({
                                    value: choice._id,
                                    label: choice.title?.tr
                                      ? choice.title.tr
                                      : choice._id,
                                    additionalInfo:
                                      choice.info?.tr ?? infos?.[i],
                                  })
                                ),
                              ]}
                              name={question._id + "dataTable" + row._id}
                              placeholder="Seçim Yok"
                              isClearable
                              isSearchable
                            />
                          ) : question.cellType === "TextArea" ? (
                            <input
                              type="text"
                              className="w-full h-full outline-none "
                              data-title={infos?.[i]}
                            />
                          ) : question.cellType === "NumberInput" ? (
                            <input
                              type="number"
                              className="w-full h-full outline-none "
                              data-title={infos?.[i]}
                            />
                          ) : question.cellType === "ShuffleColumns" ? (
                            <React.Fragment>
                              {i === 0 ? (
                                <InputButton
                                  name={question._id + "dataTable" + row._id}
                                  readOnly={question.readOnly ? question.readOnly : false}
                                  className={`form-check-input ${
                                    !!matchedPath && "!border-red-500"
                                  }`}
                                  style={{ transform: "scale(1.2)" }}
                                  required={question?.isRequired ?? false}
                                  register={register}
                                  type="radio"
                                  value={numbers?.[i] ?? _.title?.tr}
                                  setValue={setValue}
                                  optionTitle={_.title?.tr}
                                  questionTitle={
                                    row.title?.tr
                                      ? row.title.tr
                                      : "Satır " + (i + 1)
                                  }
                                  title={infos?.[i]}
                                />
                              ) : i % 4 === 0 ? (
                                <SelectItem
                                  control={control}
                                  className="basic-single w-full h-full z-50"
                                  questionTitle={
                                    row.title?.tr
                                      ? row.title.tr
                                      : "Satır " + (i + 1)
                                  }
                                  options={[
                                    ...(question.choices || []).map(
                                      (choice: elementChoicesInterface) => ({
                                        value: choice._id,
                                        label: choice.title?.tr
                                          ? choice.title.tr
                                          : choice._id,
                                        additionalInfo:
                                          choice.info?.tr ?? infos?.[i],
                                      })
                                    ),
                                  ]}
                                  name={question._id + "dataTable" + row._id}
                                  placeholder="Seçim Yok"
                                  isClearable
                                  isSearchable
                                />
                              ) : i % 2 === 0 ? (
                                <Input
                                  name={question._id + "dataTable" + row._id}
                                  readOnly={question.readOnly ? question.readOnly : false}
                                  className={`w-full h-full outline-none ${
                                    !!matchedPath && "border-red-500"
                                  }`}
                                  register={register}
                                  required={question?.isRequired ?? false}
                                  type="text"
                                  title={infos?.[i]}
                                />
                              ) : i % 3 === 0 ? (
                                <Input
                                  name={question._id + "dataTable" + row._id}
                                  readOnly={question.readOnly ? question.readOnly : false}
                                  className="w-full h-full outline-none "
                                  register={register}
                                  required={question?.isRequired ?? false}
                                  type="number"
                                  title={infos?.[i]}
                                />
                              ) : (
                                <InputButtonMulti
                                  name={question._id + "dataTable" + row._id}
                                  readOnly={question.readOnly ? question.readOnly : false}
                                  className={`w-full h-5 ${
                                    matchedPath && "!border-red-500"
                                  }`}
                                  required={question?.isRequired ?? false}
                                  register={register}
                                  type="checkbox"
                                  value={numbers?.[i] ?? _.title?.tr}
                                  setValue={setValue}
                                  optionTitle={_.title?.tr}
                                  questionTitle={
                                    row.title?.tr
                                      ? row.title.tr
                                      : "Satır " + (i + 1)
                                  }
                                  title={infos?.[i]}
                                />
                              )}
                            </React.Fragment>
                          ) : (
                            <InputButton
                              name={question._id + "dataTable" + row._id}
                              readOnly={question.readOnly ? question.readOnly : false}
                              className={`form-check-input ${
                                !!matchedPath && "!border-red-500"
                              }`}
                              style={{ transform: "scale(1.2)" }}
                              required={question?.isRequired ?? false}
                              register={register}
                              type="radio"
                              value={numbers?.[i] ?? _.title?.tr}
                              setValue={setValue}
                              optionTitle={_.title?.tr}
                              questionTitle={
                                row.title?.tr
                                  ? row.title.tr
                                  : "Satır " + (i + 1)
                              }
                              title={infos?.[i]}
                            />
                          )}
                        </td>
                      )
                    )}
                    {question.showNoneItem ? (
                      <td className="border text-center">
                        {question.cellType === "RadioButton" ? (
                          <InputButton
                            name={question._id + "dataTable" + row._id}
                            readOnly={question.readOnly ? question.readOnly : false}
                            className={`form-check-input ${
                              !!matchedPath && "!border-red-500"
                            }`}
                            style={{ transform: "scale(1.2)" }}
                            required={question?.isRequired ?? false}
                            register={register}
                            type="radio"
                            value={"Fikrim Yok"}
                            title={question.noneInfo?.tr}
                            setValue={setValue}
                            optionTitle={question.noneText?.tr}
                            questionTitle={
                              row.title?.tr ? row.title.tr : "Fikrim Yok"
                            }
                          />
                        ) : question.cellType === "CheckBox" ? (
                          <InputButtonMulti
                            name={question._id + "dataTable" + row._id}
                            readOnly={question.readOnly ? question.readOnly : false}
                            className={`w-full h-5 ${
                              matchedPath && "!border-red-500"
                            }`}
                            required={question?.isRequired ?? false}
                            register={register}
                            type="checkbox"
                            value={"Fikrim Yok"}
                            title={question.noneInfo?.tr}
                            setValue={setValue}
                            optionTitle={question.noneText?.tr}
                            questionTitle={
                              row.title?.tr ? row.title.tr : "Fikrim Yok"
                            }
                          />
                        ) : question.cellType === "SelectList" ? (
                          <SelectItem
                            control={control}
                            className="basic-single w-full h-full z-50"
                            questionTitle={
                              row.title?.tr ? row.title.tr : "Fikrim Yok"
                            }
                            options={[
                              ...(question.choices || []).map(
                                (choice: elementChoicesInterface) => ({
                                  value: "Fikrim Yok",
                                  label: "Fikrim Yok",
                                  additionalInfo:
                                    choice.info?.tr ?? question.noneInfo?.tr,
                                })
                              ),
                            ]}
                            name={question._id + "dataTable" + row._id}
                            placeholder="Seçim Yok"
                            isClearable
                            isSearchable
                          />
                        ) : question.cellType === "TextArea" ? (
                          <Input
                            name={question._id + "dataTable" + row._id}
                            readOnly={question.readOnly ? question.readOnly : false}
                            className="w-full h-full outline-none "
                            register={register}
                            required={question?.isRequired ?? false}
                            type="text"
                            questionTitle={
                              row.title?.tr ? row.title.tr : "Fikrim Yok"
                            }
                            title={question.noneInfo?.tr}
                          />
                        ) : question.cellType === "NumberInput" ? (
                          <Input
                            name={question._id + "dataTable" + row._id}
                            readOnly={question.readOnly ? question.readOnly : false}
                            className="w-full h-full outline-none "
                            register={register}
                            required={question?.isRequired ?? false}
                            type="number"
                            questionTitle={
                              row.title?.tr ? row.title.tr : "Fikrim Yok"
                            }
                            title={question.noneInfo?.tr}
                          />
                        ) : (
                          <InputButton
                            name={question._id + "dataTable" + row._id}
                            readOnly={question.readOnly ? question.readOnly : false}
                            className={`form-check-input ${
                              !!matchedPath && "!border-red-500"
                            }`}
                            style={{ transform: "scale(1.2)" }}
                            required={question?.isRequired ?? false}
                            register={register}
                            type="radio"
                            value={"Fikrim Yok"}
                            title={question.noneInfo?.tr}
                            setValue={setValue}
                            optionTitle={question.noneText?.tr}
                            questionTitle={
                              row.title?.tr ? row.title.tr : "Fikrim Yok"
                            }
                          />
                        )}
                      </td>
                    ) : null}
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </div>
      <p className="mb-2 text-sm text-red-500">
        <div>{matchedPath && errors[matchedPath]?.value?.message}</div>
      </p>
      <p className="mb-4 text-gray-500">{question.bottomTicket?.tr}</p>
      {!endOfPage && <hr />}
    </div>
  );
};

export default DataTableQuestion;

import { Draggable } from "@hello-pangea/dnd";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import {
  elementIndexForQuestionAtom,
  pageIndexAtom,
  showModalAtom,
  surveyAtom,
} from "../atoms/surveyAtoms";
import { surveyElementsInterface } from "../../Interfaces/SurveyInterfaces";
import { sidebarItems } from "../../lib/consts";

interface DraggableListItemProps {
  draggableId: string;
  index: number;
  type: string;
  icon: string;
}

const DraggableListItem: React.FC<DraggableListItemProps> = ({
  draggableId,
  index,
  type,
  icon,
}) => {
  const setStateSurveyJSON = useSetAtom(surveyAtom);
  const [elementIndexForQuestion, setElementIndexForQuestion] = useAtom(
    elementIndexForQuestionAtom,
  );

  const pageIndex = useAtomValue(pageIndexAtom);
  const setShowModal = useSetAtom(showModalAtom);

  const { t } = useTranslation();

  const handleAddElement = (
    pageIndex: number | undefined,
    elementIndex: number | undefined,
    questionType: string,
  ) => {
    setStateSurveyJSON((prevState) => {
      const updatedPages = prevState?.pages ? [...prevState?.pages] : [];

      if (pageIndex !== undefined && pageIndex >= 0) {
        let sidebarItemsObj: { [key: string]: surveyElementsInterface } = {};
        sidebarItems.forEach((item) => {
          sidebarItemsObj[item.type] = {
            ...item,
            created_date: new Date(),
            created_by: "1",
            modified_date: new Date(),
            modified_by: "1",
            isActive: true,
            _id: uuidv4(),
          };
        });
        let likertObject = sidebarItemsObj[questionType];
        if (likertObject) {
          if (updatedPages[pageIndex]) {
            let elements = updatedPages[pageIndex].elements || [];

            let index =
              elementIndex !== undefined &&
              elementIndex >= 0 &&
              elementIndex <= elements.length
                ? elementIndex
                : elements.length;
            elements.splice(index, 0, likertObject);
            updatedPages[pageIndex].elements = elements;
          } else {
            updatedPages[pageIndex] = {
              _id: "Sayfa" + uuidv4(),
              elements: [likertObject] as surveyElementsInterface[],
            };
          }
        }
      }
      toast.success("Soru başarıyla çalışma alanına eklendi!");

      return { ...prevState, pages: updatedPages };
    });

    setElementIndexForQuestion(elementIndex ? elementIndex + 1 : 1);
  };

  return (
    <Draggable draggableId={draggableId} index={index}>
      {(provided, snapshot) => (
        <li
          className={`form-elements-hover-effect-parent !left-auto mt-[10px] flex h-9 w-[250px] cursor-pointer flex-wrap items-center justify-between rounded-md border px-3 py-[6px] text-sm font-medium text-gray-700 hover:bg-[#F9FAFB] ${
            snapshot.isDragging && "border-blue-100 bg-blue-100"
          }`}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          style={{
            ...provided.draggableProps.style,
            top: "auto",
            left: "auto",
          }}
          onClick={() =>
            handleAddElement(pageIndex, elementIndexForQuestion, type)
          }
        >
          <div className="flex">
            <img className="mr-2 h-[22px] w-[22px]" src={icon} />
            {t(type)}
          </div>
          <div
            className="form-elements-hover-effect-child cursor-pointer"
            onClick={(event) => {
              event.stopPropagation();
              setShowModal(index);
            }}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="right-0"
            >
              <path
                d="M18.6667 2H5.33333C3.49239 2 2 3.49239 2 5.33333V18.6667C2 20.5076 3.49239 22 5.33333 22H18.6667C20.5076 22 22 20.5076 22 18.6667V5.33333C22 3.49239 20.5076 2 18.6667 2Z"
                fill="#EAECF0"
                stroke="#D1D1D6"
                strokeWidth="0.5"
              />
              <path
                d="M5.61342 12.4754C5.52262 12.3316 5.47723 12.2597 5.45182 12.1489C5.43273 12.0656 5.43273 11.9343 5.45182 11.851C5.47723 11.7401 5.52262 11.6682 5.61341 11.5245C6.36369 10.3365 8.59693 7.33325 12.0003 7.33325C15.4036 7.33325 17.6369 10.3365 18.3871 11.5245C18.4779 11.6682 18.5233 11.7401 18.5487 11.851C18.5678 11.9343 18.5678 12.0656 18.5487 12.1489C18.5233 12.2597 18.4779 12.3316 18.3871 12.4754C17.6369 13.6634 15.4036 16.6666 12.0003 16.6666C8.59693 16.6666 6.36369 13.6634 5.61342 12.4754Z"
                stroke="#51525C"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12.0003 13.9999C13.1048 13.9999 14.0003 13.1045 14.0003 11.9999C14.0003 10.8953 13.1048 9.99992 12.0003 9.99992C10.8957 9.99992 10.0003 10.8953 10.0003 11.9999C10.0003 13.1045 10.8957 13.9999 12.0003 13.9999Z"
                stroke="#51525C"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </li>
      )}
    </Draggable>
  );
};

export default DraggableListItem;

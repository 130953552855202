import { selectedUserAtom } from "components/atoms/dashboardAtoms";
import { useAtomValue } from "jotai";
import { getRowsForThisKeys } from "pages/dashboard2/EmployeeProfileSkillsTech";
import EmployeeProfileCard from "./EmployeeProfileCard";
import { SkillsTable } from "./EmployeeProfileSkillsPanel";

const basariOdaklilik = ["Başarı/Çaba", "İnisiyatif Alma", "Dayanıklılık"];

const icUyum = ["Uyum Sağlama/Esneklik", "Özdenetim", "Strese Tolerans"];

const sorumluluk = ["Detaylara Dikkat Etme", "Güvenilirlik", "Dürüstlük"];

const bagimsizlik = ["Bağımsızlık"];

const kisilerarasiYonelim = ["Başkalarını Düşünme", "İşbirliği", "Sosyallik"];

const pratikZeka = ["Analitik Düşünme", "İnovasyon"];

const sosyalEtki = ["Liderlik"];

export const workingStyleSkills = [
  ...basariOdaklilik,
  ...icUyum,
  ...sorumluluk,
  ...bagimsizlik,
  ...kisilerarasiYonelim,
  ...pratikZeka,
  ...sosyalEtki,
].map((x) => ({ text: x, value: x }));

export default function EmployeeProfileSkillsTech() {
  const selectedUser = useAtomValue(selectedUserAtom);

  const getRowsForThisTab = (keys: string[]) => {
    return getRowsForThisKeys(selectedUser, "puanCalismatarzi_", keys);
  };

  const CARDS = Object.freeze([
    {
      titleEmoji: "🏅",
      title: "Başarı Odaklılık",
      bgClassName: "bg-yellow-100",
      score: selectedUser.puanCalismatarzi_basariOdaklilik.yuzde,
      rows: getRowsForThisTab(basariOdaklilik),
    },
    {
      titleEmoji: "☯",
      title: "İç Uyum",
      bgClassName: "bg-pink-100",
      score: selectedUser.puanCalismatarzi_i̇cUyum.yuzde,
      rows: getRowsForThisTab(icUyum),
    },
    {
      titleEmoji: "🤝",
      title: "Sorumluluk",
      bgClassName: "bg-orange-100",
      score: selectedUser.puanCalismatarzi_sorumluluk.yuzde,
      rows: getRowsForThisTab(sorumluluk),
    },
    {
      titleEmoji: "🕊️",
      title: "Bağımsızlık",
      bgClassName: "bg-stone-100",
      score: selectedUser.puanCalismatarzi_bagimsizlik.yuzde,
      rows: getRowsForThisTab(bagimsizlik),
    },
    {
      titleEmoji: "👥",
      title: "Kişilerarası Yönelim",
      bgClassName: "bg-violet-100",
      score: selectedUser.puanCalismatarzi_kisilerarasiYönelim.yuzde,
      rows: getRowsForThisTab(kisilerarasiYonelim),
    },
    {
      titleEmoji: "🧠",
      title: "Pratik Zeka",
      bgClassName: "bg-pink-100",
      score: selectedUser.puanCalismatarzi_pratikZeka.yuzde,
      rows: getRowsForThisTab(pratikZeka),
    },
    {
      titleEmoji: "✨",
      title: "Sosyal Etki",
      bgClassName: "bg-amber-100",
      score: selectedUser.puanCalismatarzi_sosyalEtki.yuzde,
      rows: getRowsForThisTab(sosyalEtki),
    },
  ]);
  return (
    <>
      {CARDS.map((card, index) => (
        <EmployeeProfileCard {...card} isCollapsible>
          <SkillsTable rows={card.rows} />
        </EmployeeProfileCard>
      ))}
    </>
  );
}

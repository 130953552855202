import { Dispatch, FC, SetStateAction, useState } from "react";
import ListItemAttendents from "./ListItemAttendents";
import SidebarAddUser from "./SidebarAddUser";
import axios from "axios";
import { toast } from "react-toastify";
import CheckboxBootstrap from "../../../components/staticDesign/elements/CheckboxBootstrap";
import { API_DOMAIN } from "../../../settings";
import { encryptStrings } from "../../../lib/externalJS";
import {
  useDeleteParticipant,
  useUpdateParticipant,
} from "../../../services/mutations/ParticipationsMutations";
import { useDeleteAssesor } from "../../../services/mutations/assesorsMutations";
import { useAtom } from "jotai";
import { surveyAtom } from "../../../components/atoms/surveyAtoms";

type Props = {
  setState360: Dispatch<SetStateAction<number>>;
};

const Attendents: FC<Props> = ({ setState360 }) => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);

  const [showAddUser, setShowAddUser] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [users, setUsers] = useState<any[]>(stateSurveyJSON.participants || []);
  const [selectedParticipants, setSelectedParticipants] = useState<string[]>(
    [],
  );

  const deleteParticipantMutation = useDeleteParticipant();
  const deleteAssesorMutation = useDeleteAssesor();
  const updateParticipantMutation = useUpdateParticipant();

  const deleteUser = (_id: string) => {
    setUsers((prevUsers) => {
      const updatedUsers = prevUsers.filter((user) => user._id !== _id);
      const updatedUser2 = prevUsers.filter((user) => user._id === _id);
      setStateSurveyJSON((prevSurveyJSON) => ({
        ...prevSurveyJSON,
        participants: updatedUsers,
      }));
      deleteParticipantMutation.mutate(updatedUser2[0]._id);
      return updatedUsers;
    });
  };

  const deleteEvaluator = (_id: string, parent_id: string) => {
    const updatedUsers = users.map((user) => {
      if (
        user.assessors &&
        user.assessors.some((evaluator: any) => evaluator._id === _id)
      ) {
        user.assessors = user.assessors.filter(
          (evaluator: any) => evaluator._id !== _id,
        );
      }
      return user;
    });

    const updatedUser = users.find((user) => user._id === parent_id);
    updatedUser!.assessors!.filter((evaluator: any) => evaluator._id !== _id);
    updatedUser.assessorIds = updatedUser?.assessors?.map(
      (user: any) => user._id,
    );
    deleteAssesorMutation.mutate(_id);
    updateParticipantMutation.mutate(updatedUser);

    setUsers(updatedUsers);
    setStateSurveyJSON((prevSurveyJSON) => ({
      ...prevSurveyJSON,
      participants: updatedUsers,
    }));
  };

  const usersCheckChangeHandle = (e: any, id: string) => {
    if (e.target.checked) {
      setSelectedParticipants((prevSelectedParticipants) => [
        ...prevSelectedParticipants,
        id,
      ]);
    } else {
      setSelectedParticipants((prevSelectedParticipants) =>
        prevSelectedParticipants.filter((user) => user !== id),
      );
    }
  };

  const constCheckAllHandler = (e: any) => {
    if (e.target.checked) {
      setSelectedParticipants(users.map((user) => user._id));
    } else {
      setSelectedParticipants([]);
    }
  };

  const sendMails = async () => {
    const apiUrl = "http://3.144.40.16:8080/Survey/SendMail";
    for (let index = 0; index < users.length; index++) {
      let user = users[index];

      if (user.assessors) {
        for (let j = 0; j < user.assessors.length; j++) {
          let assessor = user.assessors[j];
          let mailValues = {
            fromName: assessor.name,
            toMail: [assessor.mail],
            subject: "Form Cooker Yeni Bir Ankete Davetlisiniz!",
            content: `<p style="text-align: left;">Değerli kullanıcımız. ${
              user.name
            } Tarafından bir anket gönderilmiştir.</p> <p style="text-align: left;"> <strong>Ankete gitmek için tıklayınız: </strong><em><a href="${
              API_DOMAIN +
              "/surveyReply/" +
              encryptStrings(stateSurveyJSON._id, user._id, assessor._id)
            }">Tıkla&nbsp;&nbsp;</a></em></p> <p style="text-align: left;"><em><br></em></p><p style="text-align: left;"><em><br></em></p>`,
            sendDate: new Date(),
          };

          await axios.post(apiUrl, mailValues);
        }
      }
    }
    toast.success("Tüm Değerlendiricilere e-mail gönderilmiştir");
  };

  return (
    <>
      <div className="h-[861px] w-[276px] flex-shrink-0 flex-grow-0"></div>
      <div className="hide-scrollbar mx-auto h-[calc(100vh-170px)] w-full overflow-y-auto overflow-x-hidden">
        <div className="my-4 flex items-center justify-center">
          <div className="inline-flex w-[350px] cursor-pointer rounded-xl bg-gray-100 p-[2px] text-gray-800">
            <span
              className={`w-44 rounded-xl p-2 text-center outline-none ${"bg-gray-100"}`}
              onClick={() => setState360(1)}
            >
              Roller
            </span>
            <span
              className={`w-44 rounded-xl p-2 text-center ${"bg-white text-blue-600"}`}
              onClick={() => setState360(2)}
            >
              Katılımcılar
            </span>
          </div>
        </div>
        <h5 className="mx-auto w-[720px] text-start">Katılımcılar</h5>
        <div className="mx-auto w-[720px] rounded-xl border bg-gray-100">
          <div className="flex py-2">
            <div className="flex h-11 w-[320px] items-center justify-start pl-4">
              <CheckboxBootstrap
                onChange={constCheckAllHandler}
                style={{ transform: "scale(1.2)", marginRight: "0.5em" }}
                id="UserAllCheckerDefault"
                name="UserAllCheckerDefault"
              />
              <p className="m-0 text-sm">Katılımcı</p>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="ml-2"
              >
                <path
                  d="M7.9987 3.3335V12.6668M7.9987 12.6668L12.6654 8.00016M7.9987 12.6668L3.33203 8.00016"
                  stroke="#475467"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="flex w-[110px] items-center justify-center text-sm">
              Değerlendirenler
            </div>
            <div className="flex w-[170px] items-center justify-center text-sm">
              Tamamlanma Oranı
            </div>
            <div className="w-[120px]"></div>
          </div>

          {users.map((user) => (
            <ListItemAttendents
              key={user._id}
              item={user}
              deleteUser={deleteUser}
              deleteEvaluator={deleteEvaluator}
              setSelectedUser={setSelectedUser}
              setShowAddUser={setShowAddUser}
              selectedParticipants={selectedParticipants}
              usersCheckChangeHandle={usersCheckChangeHandle}
              surveyId={stateSurveyJSON._id}
              setIsUpdating={setIsUpdating}
            />
          ))}

          <div className="flex rounded-b-xl bg-white p-3 text-start">
            <button
              className="box-button-svg rounded-md border-2 border-blue-400 px-3 py-2 text-center align-middle text-sm hover:bg-blue-300 hover:text-white"
              onClick={() => setShowAddUser(true)}
            >
              <span className="flex items-center justify-center">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon-svg"
                >
                  <path
                    d="M10.0013 4.1665V15.8332M4.16797 9.99984H15.8346"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Yeni Katılımcı Ekle
              </span>
            </button>
            <button
              className="ml-auto rounded-lg bg-[#2970ff] px-3 py-2 text-center align-middle text-sm text-white hover:!bg-transparent hover:bg-blue-700 hover:!text-[#0d6ffd]"
              disabled={!(selectedParticipants.length >= 1)}
              onClick={sendMails}
            >
              Davet Gönder
            </button>
          </div>
        </div>
      </div>
      <div
        className="hide-scrollbar h-[860px] flex-shrink-0 flex-grow-0 overflow-hidden"
        style={{
          flexBasis: "276px",
          scrollbarGutter: "stable",
        }}
      >
        {showAddUser && (
          <SidebarAddUser
            setShowAddUser={setShowAddUser}
            showAddUser={showAddUser}
            setUsers={setUsers}
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            setIsUpdating={setIsUpdating}
            isUpdating={isUpdating}
          />
        )}
      </div>
    </>
  );
};

export default Attendents;

export default function AdminActivity({ isActive }: { isActive: boolean }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <mask
        id="mask0_2283_2188"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2283_2188)">
        <path
          d="M2.90909 9.45455V5.81818C2.90909 5.31818 3.08712 4.89015 3.44318 4.53409C3.79924 4.17803 4.22727 4 4.72727 4H19.2727C19.7727 4 20.2008 4.17803 20.5568 4.53409C20.9129 4.89015 21.0909 5.31818 21.0909 5.81818V9.45455H19.2727V5.81818H4.72727V9.45455H2.90909ZM4.72727 17.6364C4.22727 17.6364 3.79924 17.4583 3.44318 17.1023C3.08712 16.7462 2.90909 16.3182 2.90909 15.8182V11.2727H4.72727V15.8182H19.2727V11.2727H21.0909V15.8182C21.0909 16.3182 20.9129 16.7462 20.5568 17.1023C20.2008 17.4583 19.7727 17.6364 19.2727 17.6364H4.72727ZM2.90909 20.3636C2.65152 20.3636 2.43561 20.2765 2.26136 20.1023C2.08712 19.928 2 19.7121 2 19.4545C2 19.197 2.08712 18.9811 2.26136 18.8068C2.43561 18.6326 2.65152 18.5455 2.90909 18.5455H21.0909C21.3485 18.5455 21.5644 18.6326 21.7386 18.8068C21.9129 18.9811 22 19.197 22 19.4545C22 19.7121 21.9129 19.928 21.7386 20.1023C21.5644 20.2765 21.3485 20.3636 21.0909 20.3636H2.90909ZM2.90909 11.2727V9.45455H8.36364C8.5303 9.45455 8.68939 9.5 8.84091 9.59091C8.99242 9.68182 9.10606 9.80303 9.18182 9.95455L10.25 12.0682L13.0455 7.18182C13.1212 7.04545 13.2273 6.93561 13.3636 6.85227C13.5 6.76894 13.6515 6.72727 13.8182 6.72727C13.9848 6.72727 14.1439 6.76894 14.2955 6.85227C14.447 6.93561 14.5606 7.06061 14.6364 7.22727L15.75 9.45455H21.0909V11.2727H15.1818C15.0152 11.2727 14.8561 11.2311 14.7045 11.1477C14.553 11.0644 14.4394 10.9394 14.3636 10.7727L13.7727 9.56818L10.9773 14.4545C10.9015 14.6061 10.7879 14.7197 10.6364 14.7955C10.4848 14.8712 10.3258 14.9091 10.1591 14.9091C9.99242 14.9091 9.83712 14.8636 9.69318 14.7727C9.54924 14.6818 9.43939 14.5606 9.36364 14.4091L7.79545 11.2727H2.90909Z"
          fill={isActive ? "#2970FF" : "#667085"}
        />
      </g>
    </svg>
  );
}

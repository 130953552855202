import React from "react";
import { surveyElementsInterface } from "../../../Interfaces/SurveyInterfaces";
import Select from "react-select";

type Props = {
  localState: surveyElementsInterface;
  deleteColmn: (value: string) => void;
  deleteRow: (value: string) => void;
  changeNoIdeaButton: () => void;
};

const MatrixTable: React.FC<Props> = ({
  localState,
  deleteColmn,
  deleteRow,
  changeNoIdeaButton,
}) => {
  return (
    <div
      className={`pl-3 !pr-0 pt-3 pb-1  min-w-[300px] `}
      style={{
        overflowX: "auto",
      }}
    >
      <table
        className="border-separate border-spacing-0 min-w-[300px]"
        style={{
          width: localState.rowWidth ? localState.rowWidth : "100%",
        }}
      >
        <tbody className="">
          <tr className="">
            <th></th>
            {localState.columns?.map((column, index) => (
              <th
                className="bg-[#EFF4FF]  font-normal h-full  border border-stone-300 px-0 "
                key={column._id}
                style={{
                  borderRadius:
                    index === 0
                      ? "12px 0px 0px 0px"
                      : index ===
                            (localState.columns
                              ? localState.columns?.length - 1
                              : 0) && localState.showNoneItem !== true
                        ? "0px 12px 0px 0px"
                        : "",
                  width: localState.columnWidth
                    ? localState.columnWidth
                    : "96px",
                }}
              >
                <span className=" !grid grid-cols-12 !w-40">
                  <span className="col-span-2"></span>
                  <span className="col-span-8 text-center !min-h-[40px] ">
                    {column.title?.tr ? column.title?.tr : column._id}
                  </span>
                  <span
                    className="col-span-2 flex  items-center  justify-center cursor-pointer"
                    onClick={() => deleteColmn(column._id)}
                  >
                    <MySVG />
                  </span>
                </span>
              </th>
            ))}
            {localState.showNoneItem ? (
              <th
                className="bg-[#EFF4FF]  font-normal h-full border border-stone-300 px-0"
                style={{
                  borderRadius: "0px 12px 0px 0px",
                  width: localState.columnWidth
                    ? localState.columnWidth
                    : "96px",
                }}
              >
                <span className=" !grid grid-cols-12 !w-40">
                  <span className="col-span-2"></span>
                  <span className="col-span-8 text-center !min-h-[40px] ">
                    {localState.noneText?.tr
                      ? localState.noneText.tr
                      : "Fikrim Yok"}
                  </span>
                  <span
                    className="col-span-2 flex  items-center  justify-center cursor-pointer"
                    onClick={() => changeNoIdeaButton()}
                  >
                    <MySVG />
                  </span>
                </span>
              </th>
            ) : null}
          </tr>

          {localState.rows?.map((row, index) => (
            <tr key={row._id}>
              <td
                className="bg-[#EFF4FF] font-normal  h-full   ! border border-stone-300 px-0"
                style={{
                  borderRadius:
                    index === 0
                      ? "12px 0px 0px 0px"
                      : index ===
                          (localState.rows ? localState.rows?.length - 1 : 0)
                        ? "0px 0px 0px 12px"
                        : "",
                }}
              >
                <span className=" !grid grid-cols-12 min-h-[2.5rem] w-24">
                  <span className="col-span-1 "></span>
                  <span className="col-span-9 my-auto  ">
                    {row.title?.tr ? row.title?.tr : row._id}
                  </span>
                  <span
                    className="col-span-2 flex  items-center  justify-center cursor-pointer"
                    onClick={() => deleteRow(row._id)}
                  >
                    <MySVG />
                  </span>
                </span>
              </td>
              {localState.columns?.map((_, i) => (
                <td className="border text-center" key={_._id}>
                  {localState.cellType === "RadioButton" ? (
                    <input
                      id={index.toString()}
                      name={index.toString()}
                      type="radio"
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  ) : localState.cellType === "CheckBox" ? (
                    <input type="checkbox" className="w-full h-5" />
                  ) : localState.cellType === "SelectList" ? (
                    <Select
                      className="basic-single w-full h-full"
                      options={[]}
                      isDisabled
                      placeholder="Seçiniz"
                    />
                  ) : localState.cellType === "TextArea" ? (
                    <input
                      type="text"
                      className="w-full h-full outline-none "
                    />
                  ) : localState.cellType === "NumberInput" ? (
                    <input
                      type="number"
                      className="w-full h-full outline-none "
                    />
                  ) : localState.cellType === "ShuffleColumns" ? (
                    <React.Fragment>
                      {i === 0 ? (
                        <input
                          id={index.toString()}
                          name={index.toString()}
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                      ) : i % 4 === 0 ? (
                        <Select
                          className="basic-single w-full h-full z-50"
                          options={[]}
                          isDisabled
                          placeholder="Seçiniz"
                        />
                      ) : i % 2 === 0 ? (
                        <input
                          type="text"
                          className="w-full h-full outline-none "
                        />
                      ) : i % 3 === 0 ? (
                        <input
                          type="number"
                          className="w-full h-full outline-none "
                        />
                      ) : (
                        <input
                          type="checkbox"
                          className="w-full h-5 "
                          name={index.toString()}
                        />
                      )}
                    </React.Fragment>
                  ) : (
                    <input
                      id={index.toString()}
                      name={index.toString()}
                      type="radio"
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  )}
                </td>
              ))}
              {localState.showNoneItem ? (
                <td className="border">
                  {localState.cellType === "RadioButton" ? (
                    <input
                      id={index.toString()}
                      name={index.toString()}
                      type="radio"
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  ) : localState.cellType === "CheckBox" ? (
                    <input type="checkbox" className="w-full h-5" />
                  ) : localState.cellType === "SelectList" ? (
                    <Select
                      className="basic-single w-full h-full"
                      options={[]}
                      placeholder="Seçiniz"
                      isDisabled
                    />
                  ) : localState.cellType === "TextArea" ? (
                    <input
                      type="text"
                      className="w-full h-full outline-none "
                    />
                  ) : localState.cellType === "NumberInput" ? (
                    <input
                      type="number"
                      className="w-full h-full outline-none "
                    />
                  ) : (
                    <input
                      id={index.toString()}
                      name={index.toString()}
                      type="radio"
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  )}
                </td>
              ) : null}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
export default MatrixTable;

const MySVG: React.FC<React.SVGProps<SVGSVGElement>> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      className="bi bi-x-lg "
      viewBox="0 0 16 16"
    >
      <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
    </svg>
  );
};

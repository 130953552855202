import { useState } from "react";
import TimePicker from "react-time-picker";
import { useTranslation } from "react-i18next";
import { Switch } from "@headlessui/react";
import { classNames } from "../../../../lib/externalJS";
import { useAtom, useAtomValue } from "jotai";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../../atoms/surveyAtoms";

const DateHour = () => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const [specialValue, setSpecialValue] = useState<number>(1);
  const { t } = useTranslation();

  const handleButtonClick = (buttonName: any) => {
    setStateSurveyJSON((prevState) => {
      let newState = { ...prevState };
      newState.pages![pageIndex].elements![elementIndex].selectedDate =
        buttonName;
      return newState;
    });
  };
  const handleChangeHour = (e: boolean) => {
    const newValue = e;
    setStateSurveyJSON((prevState) => {
      const newState = { ...prevState };
      newState.pages![pageIndex].elements![elementIndex].showExtra1 = newValue;
      return newState;
    });
  };

  const timeFormatClickHandler = (e: boolean) => {
    setStateSurveyJSON((prevState) => {
      let newState = { ...prevState };
      newState.pages![pageIndex]!.elements![elementIndex]!.timeFormat = e;
      return newState;
    });
  };

  const checkedHour = stateSurveyJSON.pages?.[pageIndex].elements?.[
    elementIndex
  ].showExtra1
    ? true
    : false;

  return (
    <>
      <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
        <label className="block text-sm font-bold text-gray-500">
          Saat Alanı
        </label>
        <div className="mt-3 flex">
          <Switch
            id="flexSwitchCheckReq"
            checked={checkedHour}
            onChange={handleChangeHour}
            className={classNames(
              checkedHour ? "bg-blue-600" : "bg-gray-200",
              "relative mr-2 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2",
            )}
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={classNames(
                checkedHour ? "translate-x-5" : "translate-x-0",
                "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
              )}
            />
          </Switch>
          <label className="text-gray-300" htmlFor="flexSwitchCheckReq">
            {checkedHour ? t("open") : t("close")}
          </label>
        </div>
        <p className="mb-0 mt-3 text-sm text-stone-500">
          Tabloya fikrim yok sütunu ekleyin
        </p>
      </div>
      <div
        style={{
          display: stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
            .showExtra1
            ? "block"
            : "none",
        }}
      >
        <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
          <label className="block text-sm font-bold text-gray-500">
            Saat Formatı
          </label>
          <div className="mt-3 w-full">
            <label
              htmlFor="Toggle3"
              className="inline-flex cursor-pointer items-center rounded-md p-2 dark:text-gray-800"
            >
              <input
                id="Toggle3"
                type="checkbox"
                className="peer hidden"
                checked={
                  stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
                    ?.timeFormat
                }
                onChange={(e) => {
                  timeFormatClickHandler(e.target.checked);
                }}
              />
              <span
                className={`relative inline-flex items-center rounded-l-md ${
                  stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
                    ?.timeFormat
                    ? "bg-[#D1E0FF] font-semibold text-gray-900"
                    : "button-group-ticket-position bg-white font-medium text-gray-500 hover:bg-gray-300"
                } px-3 py-2 text-sm ring-1 ring-inset ring-gray-300 focus:z-10`}
              >
                24Saat
              </span>
              <span
                className={`-ml-px relative inline-flex items-center rounded-r-md ${
                  !stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
                    ?.timeFormat
                    ? "bg-[#D1E0FF] font-semibold text-gray-900"
                    : "button-group-ticket-position bg-white font-medium text-gray-500"
                } px-3 py-2 text-sm ring-1 ring-inset ring-gray-300 focus:z-10`}
              >
                AM/PM
              </span>
            </label>
          </div>
        </div>
        <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
          <label className="block text-sm font-bold text-gray-500">
            Varsayılan Saat
          </label>
          <span className="isolate mt-3 inline-flex rounded-md shadow-sm">
            <button
              type="button"
              onClick={() => {
                setSpecialValue(1);
                handleButtonClick(
                  stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
                    .selectedDate
                    ? stateSurveyJSON.pages![pageIndex].elements![elementIndex]
                        .selectedDate
                    : undefined,
                );
              }}
              className={`relative inline-flex items-center rounded-l-md ${
                specialValue === 1
                  ? "bg-[#D1E0FF] font-semibold text-gray-900"
                  : "button-group-ticket-position bg-white font-medium text-gray-500 hover:bg-gray-300"
              } px-3 py-2 text-sm ring-1 ring-inset ring-gray-300 focus:z-10`}
            >
              Yok
            </button>
            <button
              type="button"
              onClick={() => {
                setSpecialValue(2);
                handleButtonClick(new Date());
              }}
              className={`relative inline-flex items-center px-3 py-2 text-sm ${
                specialValue === 2
                  ? "bg-[#D1E0FF] font-semibold text-gray-900"
                  : "button-group-ticket-position bg-white font-medium text-gray-500 hover:bg-gray-300"
              } ring-1 ring-inset ring-gray-300 focus:z-10`}
            >
              Şu anki
            </button>
            <button
              type="button"
              onClick={() => {
                handleButtonClick(new Date());
                setSpecialValue(3);
              }}
              className={`-ml-px relative inline-flex items-center rounded-r-md ${
                specialValue === 3
                  ? "bg-[#D1E0FF] font-semibold text-gray-900"
                  : "button-group-ticket-position bg-white font-medium text-gray-500"
              } px-3 py-2 text-sm ring-1 ring-inset ring-gray-300 focus:z-10`}
            >
              Özel
            </button>
          </span>
          {specialValue === 3 && (
            <TimePicker
              onChange={(e: any) => {
                setStateSurveyJSON((prevState) => {
                  let newState = { ...prevState };
                  let currentDate = stateSurveyJSON.pages?.[pageIndex]
                    .elements?.[elementIndex].selectedDate
                    ? stateSurveyJSON.pages![pageIndex].elements![elementIndex]
                        .selectedDate
                    : new Date();
                  let [hour, minute] = e.split(":");
                  if (currentDate) {
                    currentDate.setHours(Number(hour));
                    currentDate.setMinutes(Number(minute));
                  }

                  newState.pages![pageIndex]!.elements![
                    elementIndex
                  ]!.selectedDate = currentDate;

                  return newState;
                });
              }}
              value={
                stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
                  .selectedDate
                  ? stateSurveyJSON.pages![pageIndex].elements![elementIndex]
                      .selectedDate
                  : new Date()
              }
              format={
                stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
                  .timeFormat
                  ? "hh:mm a"
                  : "HH:mm"
              }
            />
          )}
          <p className="mb-0 mt-3 text-sm text-stone-500">
            Güncel ya da özel bir saat ile önceden doldurun
          </p>
        </div>
      </div>
    </>
  );
};
export default DateHour;

import React, { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Switch } from "@headlessui/react";
import { classNames } from "../../../../lib/externalJS";
import { useAtom, useAtomValue } from "jotai";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../../atoms/surveyAtoms";

const DocumentFileCount = () => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const [switchState, setSwitchState] = useState(
    Boolean(
      stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
        ?.limitationType,
    ),
  );
  const { t } = useTranslation();

  const handleWidthChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setStateSurveyJSON((prevState) => {
      let newState = { ...prevState };
      newState.pages![pageIndex]!.elements![elementIndex]!.limitationType =
        Number(newValue);
      return newState;
    });
  };

  return (
    <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
      <label className="mb-2 block text-sm font-bold text-gray-500">
        Dosya Limiti
      </label>
      <div className="mb-2 mt-3 flex">
        <Switch
          id="flexSwitchCheckshuffleOptions"
          checked={switchState}
          onChange={(e) => {
            setSwitchState(e);
            !e &&
              setStateSurveyJSON((prevState) => {
                let newState = { ...prevState };
                newState.pages![pageIndex]!.elements![
                  elementIndex
                ]!.limitationType = undefined;
                return newState;
              });
          }}
          className={classNames(
            switchState ? "bg-blue-600" : "bg-gray-200",
            "relative mr-2 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2",
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={classNames(
              switchState ? "translate-x-5" : "translate-x-0",
              "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
            )}
          />
        </Switch>
        <label
          className="text-gray-500"
          htmlFor="flexSwitchCheckshuffleOptions"
        >
          {switchState ? t("open") : t("close")}
        </label>
      </div>
      <div className="form-check form-switch mb-2 mt-3 !flex">
        <div style={{ display: switchState ? "block" : "none" }}>
          <div className="flex">
            <div className="relative flex w-32">
              <input
                type="number"
                className="z-20 block w-24 rounded-l-lg border border-gray-300 bg-white p-[10px] text-sm text-gray-900 outline-none"
                value={
                  stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
                    .limitationType
                    ? stateSurveyJSON.pages[pageIndex].elements![elementIndex]
                        .limitationType
                    : ""
                }
                onChange={(e) => handleWidthChange(e)}
              />
              <button className="cursor-default rounded-r-lg border-b border-r border-t border-gray-300 px-2 text-gray-600">
                Adet
              </button>
            </div>
          </div>
        </div>
      </div>
      <p className="mb-0 mt-3 text-sm text-stone-500">
        Her gönderim için kabul edilen dosya sayısını sınırlandırın
      </p>
    </div>
  );
};

export default DocumentFileCount;

import { useMutation } from "react-query";

import { toast } from "react-toastify";
import {
  createParticipant,
  deleteParticipant,
  updateParticipant,
} from "../api/participants";

export function useCreateParticipant() {
  return useMutation({
    mutationFn: (data: any) => {
      return createParticipant(data);
    },
    onMutate: () => {},

    onError: () => {},

    onSuccess: () => {},

    onSettled: async (_: any, error: any) => {},
  });
}

export function useUpdateParticipant() {
  return useMutation({
    mutationFn: (data: any) => updateParticipant(data),

    onSettled: async (_: any, error: any, variables: any) => {
      if (error) {
        console.error(error);
      } else {
      }
    },
  });
}

export function useDeleteParticipant() {
  return useMutation({
    mutationFn: (id: string) => deleteParticipant(id),

    onSuccess: () => {
      toast.error("Anket başarıyla silindi");
    },

    onSettled: async (_: any, error: any) => {},
  });
}

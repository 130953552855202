import { ChangeEvent, useEffect, useState } from "react";
import { Switch } from "@headlessui/react";
import { classNames } from "../../../lib/externalJS";
import { useAtom, useAtomValue } from "jotai";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../atoms/surveyAtoms";

const FormSettingsSelectHolder = () => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const [localState, setLocalState] = useState(
    stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
      ?.selectPlaceholder,
  );
  const [choiceState, setChoiceState] = useState(
    Boolean(
      stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
        ?.selectPlaceholder,
    ),
  );

  const handleNoneTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setLocalState((prevState) => {
      let newState = prevState;
      newState = { tr: newValue };
      return newState;
    });
  };

  useEffect(() => {
    setStateSurveyJSON((prevState) => ({
      ...prevState,
      pages: (prevState?.pages ?? []).map((page, i) =>
        i === pageIndex
          ? {
              ...page,
              elements: (page?.elements ?? []).map((element, j) =>
                j === elementIndex
                  ? {
                      ...element,
                      selectPlaceholder: localState,
                    }
                  : element,
              ),
            }
          : page,
      ),
    }));
  }, [localState]);

  return (
    <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
      <label className="mb-2 block text-sm font-bold text-gray-500">
        Boş Seçenekte Metin Göster
      </label>
      <div className="mb-2 mt-3 flex">
        <Switch
          id="flexSwitchCheckNoVisible"
          checked={choiceState}
          onChange={(e) => {
            setChoiceState(e);
            !e && setLocalState(undefined);
          }}
          className={classNames(
            choiceState ? "bg-blue-600" : "bg-gray-200",
            "relative mr-2 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2",
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={classNames(
              choiceState ? "translate-x-5" : "translate-x-0",
              "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
            )}
          />
        </Switch>
        <label className="text-gray-500" htmlFor="flexSwitchCheckNoVisible">
          {choiceState ? "Açık" : "Kapalı"}
        </label>
      </div>
      <div style={{ display: choiceState ? "block" : "none" }}>
        <input
          id="enterTitle"
          className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow-sm focus:outline-none"
          type="text"
          value={localState?.tr ? localState.tr : ""}
          autoComplete="off"
          onChange={(e) => handleNoneTextChange(e)}
        />
      </div>
      <p className="mb-0 mt-3 text-sm text-stone-500">
        Boş seçenek yerine metin gösterin. Bu seçenek boş cevap olarak kabul
        edilir.
      </p>
    </div>
  );
};

export default FormSettingsSelectHolder;

import FormSettingsTip from "../../components/FormSettingsTip";
import FormSettingsCollapse from "../../components/FormSettingsCollapse";
import FormSettingsReadOnly from "../../components/FormSettingsReadOnly";
import FormSettingsAddToLibrary from "../../components/FormSettingsAddToLibrary";

export default function DocumentAdvanceSettings() {
  return (
    <>
      <FormSettingsTip />
      <FormSettingsCollapse />
      <FormSettingsReadOnly />
      <FormSettingsAddToLibrary />
    </>
  );
}

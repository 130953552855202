import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { AnimatePresence, motion } from "framer-motion";
import { useAtom } from "jotai";
import React, { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { surveyElementsInterface } from "../../Interfaces/SurveyInterfaces";
import { svgArrayForQuestionTypes } from "../../lib/SvgElements";
import { surveyAtom } from "../atoms/surveyAtoms";
import FormButtons from "../staticDesign/formSettings/FormButtons";
import SidebarItems from "./SidebarItems";

type Props = {
  setShow: Dispatch<SetStateAction<boolean>>;
  show: boolean;
  containerRef: any;
};

const FormElements: React.FC<Props> = ({ show, setShow, containerRef }) => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const [openTab, setOpenTab] = useState(1);

  const { t } = useTranslation();

  const tabsElement = [
    { index: 1, label: t("addQuestion"), flexBasis: "92px" },
    { index: 2, label: t("sorting"), flexBasis: "92px" },
    // { index: 3, label: t("library"), flexBasis: "92px" },
  ];

  const onQueueDragEnd = (result: any) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    const pageIndex =
      stateSurveyJSON.pages?.findIndex(
        (page) => page._id === destination.droppableId,
      ) || 0;

    const updatedPages = [...(stateSurveyJSON.pages || [])];
    const [movedElement] =
      updatedPages[pageIndex].elements?.splice(source.index, 1) || [];
    updatedPages[pageIndex].elements?.splice(
      destination.index,
      0,
      movedElement,
    );
    setStateSurveyJSON((prevState) => ({
      ...prevState,
      pages: updatedPages,
    }));
  };

  const handleSidebarItemClick = (elementName: string) => {
    const element = document.getElementById(elementName);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const getBackgroundColor = (element: surveyElementsInterface): string => {
    const mappingKey = typeToKeyMap[element.type] || "other";
    return backgroundColors[mappingKey];
  };

  return (
    <AnimatePresence mode="wait" initial={false}>
      {show && (
        <motion.div
          className={`h-[calc(100vh-100px)] w-full max-w-full border-r`}
          {...framerSidebarPanel}
        >
          <div className="flex flex-wrap">
            <div className="flex w-full min-w-0 flex-wrap items-center justify-between break-words border-b-2 px-2 pb-[6px] pt-2">
              {t("formElements")}

              <span
                className="cursor-pointer rounded-md p-2 transition-all delay-100 hover:bg-blue-200"
                onClick={() => {
                  setShow(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className=""
                  viewBox="0 0 16 16"
                >
                  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                </svg>
              </span>
            </div>
            <div className="h-[calc(100vh-187px)] w-full">
              <ul
                className="m-0 flex list-none justify-around p-0"
                role="tablist"
              >
                {tabsElement.map((tab) => (
                  <FormButtons
                    key={tab.index}
                    index={tab.index}
                    openTab={openTab}
                    onClick={() => setOpenTab(tab.index)}
                    label={tab.label?.toString() || ""}
                    flexBasis={tab.flexBasis}
                  />
                ))}
              </ul>
              <div className="mb-6 flex h-[calc(100vh-187px)] min-w-0 flex-col break-words bg-white shadow-lg">
                <div>
                  <div
                    className={`custom-scrollbar-container tab-content tab-space h-[calc(100vh-187px)] py-1 pl-3 pr-1 ${
                      openTab === 1 ? "block" : "hidden"
                    }`}
                    id="link1"
                  >
                    <SidebarItems />
                  </div>
                  <div
                    className={`custom-scrollbar-container tab-content tab-space h-[calc(100vh-187px)] w-[277px] py-1 pl-3 pr-1 ${
                      openTab === 2 ? "block" : "hidden"
                    }`}
                    id="link2"
                  >
                    <DragDropContext onDragEnd={onQueueDragEnd}>
                      {stateSurveyJSON?.pages?.map((page, index) => (
                        <Droppable droppableId={page._id} key={page._id}>
                          {(provided) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              className="border-b"
                            >
                              {stateSurveyJSON?.pages![index].elements?.map(
                                (element, i) => (
                                  <Draggable
                                    key={element._id}
                                    draggableId={element._id}
                                    index={i}
                                  >
                                    {(provided) => (
                                      <div
                                        className="relative left-auto top-auto mb-2 rounded-md bg-white py-1 pl-2 hover:bg-blue-100"
                                        style={{
                                          ...provided.draggableProps.style,
                                        }}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <div
                                          className="flex items-center"
                                          ref={containerRef}
                                        >
                                          <div
                                            className={`formElementsQueueList flex !h-6 !w-[54px] items-center justify-between rounded-md border border-transparent px-1 text-end text-xs ${getBackgroundColor(
                                              element,
                                            )}`}
                                          >
                                            {
                                              svgArrayForQuestionTypes.find(
                                                (item) =>
                                                  item.key === element.type,
                                              )?.value
                                            }
                                            {i + 1}
                                          </div>
                                          <div
                                            className="two-line-text-sorting ml-2 h-[45px] w-[190px] text-xs"
                                            data-title={
                                              element.inputAreaDefaultValue?.tr
                                            }
                                            onClick={() =>
                                              handleSidebarItemClick(
                                                element._id,
                                              )
                                            }
                                          >
                                            {element.title?.tr}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                ),
                              )}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      ))}
                    </DragDropContext>
                  </div>
                  <div
                    className={`custom-scrollbar-container tab-content tab-space h-[calc(100vh-187px)] w-[277px] py-1 pl-3 pr-1 ${
                      openTab === 3 ? "block" : "hidden"
                    }`}
                    id="link3"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default FormElements;

const framerSidebarPanel = {
  initial: { x: "-100%" },
  animate: { x: 0 },
  exit: { x: "-100%" },
  transition: { duration: 0.3 },
};

const backgroundColors: { [key: string]: string } = {
  titleOrTextArea: "bg-purple-100",
  ranking: "bg-red-100",
  selectable: "bg-[#F4EBFF]",
  table: "bg-gray-100",
  xm: "bg-green-100",
  contact: "bg-blue-50",
  time: "bg-orange-100",
  other: "bg-pink-100",
};

const typeToKeyMap: { [key: string]: string } = {
  title: "titleOrTextArea",
  textArea: "titleOrTextArea",
  longTextArea: "titleOrTextArea",
  richText: "titleOrTextArea",
  number: "titleOrTextArea",
  likert: "ranking",
  starRating: "ranking",
  ranking: "ranking",
  openableList: "selectable",
  singleChoice: "selectable",
  multiChoice: "selectable",
  imagePicker: "selectable",
  dataTable: "table",
  CSAT: "xm",
  CES: "xm",
  NPS: "xm",
  email: "contact",
  tel: "contact",
  address: "contact",
  fullName: "contact",
  date: "time",
  hour: "time",
};

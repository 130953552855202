import TalentCookerLogo from "components/TalentCookerLogo";
import { ChangeEvent, FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSignIn } from "../services/mutations/userMutations";

export default function Login() {
  const navigateTo = useNavigate();

  const userInitial = {
    password: "",
    email: "",
  };

  const [loginValues, setLoginValues] = useState(userInitial);
  const loginUserMutation = useSignIn();

  const updateUserValue = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setLoginValues((prevValues: any) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      loginUserMutation.mutate({
        ...loginValues,
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <TalentCookerLogo className="mx-auto" width={150} />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Welcome Back!
        </h2>
      </div>

      <div className="mt-7 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              E-mail
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                onChange={updateUserValue}
                required
                className="py-1.5 block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Password
              </label>
              {/* <div className="text-sm">
                <a
                  href="#"
                  className="font-semibold text-blue-600 hover:text-blue-500"
                >
                  Forgot password?
                </a>
              </div> */}
            </div>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                onChange={updateUserValue}
                required
                className="py-1.5 block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="py-1.5 flex h-10 w-full items-center justify-center rounded-md bg-blue-600 px-3 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              Login
            </button>
          </div>
        </form>

        {/* <p className="mt-6 text-center text-sm text-slate-700">
          If you don't have an account,{" "}
          <Link
            className="font-bold leading-6 text-blue-600 hover:text-blue-500"
            to="/register2"
          >
            Sign up
          </Link>
        </p> */}

        {/* <p className="mt-6 text-center text-[11px] text-gray-500">
          By using formcooker, you are agreeing to our{" "}
          <a className="border-b border-black font-semibold" href="#">
            privacy policy
          </a>
          {" and "}
          <a className="border-b border-black font-semibold" href="#">
            terms
          </a>
          .
        </p> */}
      </div>
    </div>
  );
}

import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import PrimaryButton from "../../components/tailwindUi/PrimaryButton";
import TextInput from "../../components/tailwindUi/TextInput";
import { testValidEmail } from "../../lib/externalJS";
import { SettingsCard, SettingsCardBtnContainer } from "./SettingsProfile";

const fromServerEmail = "deneme123@gmail.com";

export default function SettingsAccount() {
  return (
    <div className="flex flex-col gap-6">
      <EmailCard />
      <PasswordCard />
    </div>
  );
}

function EmailCard() {
  const [initialEmail, setInitialEmail] = useState<string | undefined>();
  const [email, setEmail] = useState<string | undefined>();

  useEffect(() => {
    // TODO: fetch user data
    const fetchedEmail = fromServerEmail;

    setInitialEmail(fetchedEmail);
    setEmail(fetchedEmail);
  }, []);

  const isFormChanged = JSON.stringify(email) !== JSON.stringify(initialEmail);

  const emailErrorMessage =
    email === ""
      ? "Email is required"
      : !testValidEmail(email)
        ? "Email is not valid"
        : "";

  const isFormValid = emailErrorMessage === "";

  return (
    <SettingsCard title="E-posta">
      <div className="divide-y divide-white/5">
        <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 md:grid-cols-3">
          <form className="md:col-span-3">
            <div className="grid grid-cols-1 gap-x-6 gap-y-3 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <TextInput
                  label="E-posta"
                  name="email"
                  id="email"
                  type="email"
                  defaultValue={email}
                  errorMessage={emailErrorMessage}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </div>

              <div className="col-span-full text-sm font-normal leading-normal text-gray-500">
                Hesabınızla ilişkili e-posta adresinizi güncelleyin. Bu e-posta
                faturalandırmayla ilişkili değil. Fatura adresinizi güncellemek
                istiyorsanız{" "}
                <span className="font-medium text-blue-600">
                  assist@paddle.com
                </span>{" "}
                ile iletişime geçin.
              </div>

              <div className="gap-2.5 mt-2 inline-flex rounded-md bg-blue-50 p-4 sm:col-span-4">
                <div className="inline-flex gap-3">
                  <InformationCircleIcon className="h-5 w-5 text-blue-600" />
                  <div className="inline-flex flex-col gap-2 text-xs font-normal leading-tight text-blue-600">
                    <span className="text-sm font-medium">
                      E-posta Doğrulaması Bekleniyor
                    </span>
                    <div>
                      Lütfen <span className="font-semibold">{email}</span>{" "}
                      e-postanızı kontrol edin ve bu kişinin siz olduğunuzu
                      doğrulayın.
                    </div>
                    <div>
                      <button className="font-semibold text-green-400 underline">
                        E-postayı Yeniden Gönder
                      </button>
                      <span>{" veya "}</span>
                      <button className="font-semibold text-red-400 underline">
                        Vazgeç
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <SettingsCardBtnContainer>
              <PrimaryButton isMinWidth isSecondary isGray>
                Vazgeç
              </PrimaryButton>
              <PrimaryButton
                disabled={!isFormChanged || !isFormValid}
                isMinWidth
              >
                Doğrulama Bağlantısını Gönder
              </PrimaryButton>
            </SettingsCardBtnContainer>
          </form>
        </div>
      </div>
    </SettingsCard>
  );
}

type fieldObjectType = { value: string; error: string | undefined } | undefined;

function PasswordCard() {
  const [password, setPassword] = useState<fieldObjectType>();
  const [newPassword, setNewPassword] = useState<fieldObjectType>();
  const [confirmPassword, setConfirmPassword] = useState<fieldObjectType>();

  const getPasswordErrorMessage = (value: string = ""): string => {
    return value === "" ? "Password is required" : "";
  };

  const getNewPasswordErrorMessage = (value: string = ""): string => {
    return value === ""
      ? "New Password is required"
      : value && value.length < 8
        ? "Password must be at least 8 characters long"
        : "";
  };

  const getConfirmPasswordErrorMessage = (value: string = ""): string => {
    return value === ""
      ? "Confirm Password is required"
      : value !== newPassword?.value
        ? "Passwords do not match"
        : "";
  };

  useEffect(() => {
    if (confirmPassword?.error === "Passwords do not match") {
      setConfirmPassword((prevState) => {
        return {
          value: prevState?.value ?? "",
          error: getConfirmPasswordErrorMessage(prevState?.value),
        };
      });
    }
  }, [newPassword?.value]);

  return (
    <SettingsCard title="Şifre">
      <div className="divide-y divide-white/5">
        <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 md:grid-cols-3">
          <form className="md:col-span-3">
            <div className="grid grid-cols-1 gap-x-6 gap-y-3 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <TextInput
                  label="Mevcut Şifre"
                  name="currentPassword"
                  id="currentPassword"
                  type="password"
                  errorMessage={password?.error ?? ""}
                  onChange={(event) =>
                    setPassword((prevState) => {
                      return {
                        value: event.target.value,
                        error: prevState?.error
                          ? getPasswordErrorMessage(event.target.value)
                          : prevState?.error,
                      };
                    })
                  }
                  labelRightSide={
                    <a
                      href="#"
                      className="text-sm font-semibold leading-tight text-blue-600"
                    >
                      Şifremi Unuttum
                    </a>
                  }
                />
              </div>

              <div className="hidden sm:col-span-3 sm:block"></div>

              <div className="mt-3 sm:col-span-3">
                <TextInput
                  label="Yeni Şifre"
                  name="newPassword"
                  id="newPassword"
                  type="password"
                  errorMessage={newPassword?.error ?? ""}
                  onChange={(event) =>
                    setNewPassword((prevState) => {
                      return {
                        value: event.target.value,
                        error: prevState?.error
                          ? getNewPasswordErrorMessage(event.target.value)
                          : prevState?.error,
                      };
                    })
                  }
                />
              </div>

              <div className="mt-3 sm:col-span-3">
                <TextInput
                  label="Yeni Şifre Doğrulama"
                  name="confirmPassword"
                  id="confirmPassword"
                  type="password"
                  errorMessage={confirmPassword?.error ?? ""}
                  onChange={(event) =>
                    setConfirmPassword((prevState) => {
                      return {
                        value: event.target.value,
                        error: prevState?.error
                          ? getConfirmPasswordErrorMessage(event.target.value)
                          : prevState?.error,
                      };
                    })
                  }
                />
              </div>

              <div className="text-sm font-normal leading-normal text-gray-500 sm:col-span-full">
                Şifre en az
                <span className="font-semibold">
                  {" 8 karakter uzunluğunda "}
                </span>
                olmalıdır.
              </div>
            </div>

            <SettingsCardBtnContainer>
              <PrimaryButton isMinWidth isSecondary isGray>
                Vazgeç
              </PrimaryButton>
              <PrimaryButton
                onClick={() => {
                  setPassword((prevState) => {
                    return {
                      value: prevState?.value ?? "",
                      error: getPasswordErrorMessage(prevState?.value),
                    };
                  });
                  setNewPassword((prevState) => {
                    return {
                      value: prevState?.value ?? "",
                      error: getNewPasswordErrorMessage(prevState?.value),
                    };
                  });
                  setConfirmPassword((prevState) => {
                    return {
                      value: prevState?.value ?? "",
                      error: getConfirmPasswordErrorMessage(prevState?.value),
                    };
                  });
                }}
                isMinWidth
              >
                Değişiklikleri Kaydet
              </PrimaryButton>
            </SettingsCardBtnContainer>
          </form>
        </div>
      </div>
    </SettingsCard>
  );
}

import companyData from "data/companyData.json";
import { getSortedDegerlendirme } from "pages/dashboard2/EmployeeProfileHighlights";
import {
  PanelCardChild,
  PanelSplineGraph,
  SERIES_COLORS,
  getSeriesAndCategories,
} from "pages/dashboard2/Panel/Panel";
import CommonTable from "pages/dashboard2/Performance/CommonTable";
import { TimeFilters } from "pages/dashboard2/Performance/Performance";

export default function Dedication() {
  return (
    <div className="flex flex-col">
      <PanelCardChild span={4}>
        <TimeFilters color={"purple"} />

        <div className="-mx-3">
          <PanelSplineGraph
            {...getSeriesAndCategories(
              getSortedDegerlendirme(companyData["Adanmışlık"]),
              "Adanmışlık",
            )}
            colors={SERIES_COLORS}
            isBigger
          />
        </div>
      </PanelCardChild>

      <CommonTable forTable="adanmislik" />
    </div>
  );
}

interface CheckboxProps {
  upperLabel?: string;
  checkboxLabelComp?: React.ReactNode;
  name: string;
  id: string;
  checked?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function Checkbox({
  upperLabel,
  checkboxLabelComp,
  name,
  id,
  checked,
  onChange,
}: CheckboxProps) {
  return (
    <div>
      {upperLabel && (
        <div className="flex mb-2 justify-between items-center">
          <label className="block text-sm font-medium leading-6 text-gray-900">
            {upperLabel}
          </label>
        </div>
      )}
      <div className="relative rounded-md">
        <div className="relative flex items-center">
          <div className="flex h-5 items-center">
            <input
              id={id}
              aria-describedby={`${id}-description`}
              name={name}
              className="h-5 w-5 rounded border-gray-300 text-blue-600 focus:ring-transparent cursor-pointer"
              type="checkbox"
              checked={checked}
              onChange={onChange}
            />
          </div>
          <label className="pl-3 cursor-pointer" htmlFor={id}>
            {checkboxLabelComp}
          </label>
        </div>
      </div>
    </div>
  );
}

import { ChevronRightIcon } from "@heroicons/react/24/outline";
import TalentCookerLogo from "components/TalentCookerLogo";
import { selectedNavigationNameAtom } from "components/atoms/dashboardAtoms";
import Bell from "images/bell-01.svg";
import { useSetAtom } from "jotai";
import { FilterChip } from "pages/dashboard2/DashboardCards";
import ProfileDropdown from "pages/dashboard2/ProfileDropdown";
import SettingsDropdown from "pages/dashboard2/SettingsDropdown";
import { Dispatch } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const filters = Object.freeze([
  { text: "Düzenle", value: 1 },
  { text: "Paylaş", value: 2 },
  // { text: "Result", value: 3 },
]);

type Props = {
  pageStatus: number;
  setPageStatus: Dispatch<number>;
  headerTitle?: string;
};

const Header: React.FC<Props> = ({
  headerTitle,
  pageStatus,
  setPageStatus,
}) => {
  const { t } = useTranslation();

  return (
    <header className="relative flex h-[54px] items-center justify-between border-b px-5 py-[9px]">
      <div className="flex items-center gap-x-[10px]">
        <Link to="/">
          <TalentCookerLogo width={146} />
        </Link>

        <ChevronRightIcon className="h-5 w-5 text-gray-400" />

        <p className="text-base font-medium leading-6 text-gray-700">
          {headerTitle ?? t("noNamePlayground")}
        </p>
      </div>

      <div className="absolute left-1/2 z-10 flex -translate-x-1/2 transform gap-x-2">
        {filters.map((filter, i) => (
          <button key={i} onClick={() => setPageStatus(filter.value)}>
            <FilterChip
              key={filter.value}
              isActive={pageStatus === filter.value}
              isForTabs
            >
              {filter.text}
            </FilterChip>
          </button>
        ))}
      </div>

      <HeaderRightSide />
    </header>
  );
};

export function HeaderRightSide() {
  const setSelectedNavigationName = useSetAtom(selectedNavigationNameAtom);

  return (
    <div className="flex items-center gap-x-3">
      <ProfileDropdown />

      <div className="p-2">
        <img src={Bell} className="h-5 w-5" alt="bell" />
      </div>

      <SettingsDropdown />
    </div>
  );
}

export default Header;

import React, { FC } from "react";

interface TabProps {
  index: number;
  openTab: number;
  onClick: () => void;
  label: React.ReactNode;
  flexBasis: string;
}

const FormButtons: FC<TabProps> = ({
  openTab,
  label,
  index,
  onClick,
  flexBasis,
}) => {
  return (
    <li
      className={`text-center flex-shrink-0 flex-grow-0 h-10 border-b-2 `}
      style={{ flexBasis: flexBasis }}
    >
      <span
        className={
          `no-underline text-sm text-center font-semibold h-10 py-2 block leading-normal cursor-pointer ` +
          (openTab === index
            ? "text-blueGray-600 bg-white border-b-2 border-blue-600"
            : "text-slate-600")
        }
        onClick={(e) => {
          e.preventDefault();
          onClick();
        }}
        data-toggle={`tab${index}`}
        role="tablist"
      >
        {label}
      </span>
    </li>
  );
};

export default FormButtons;

import HourTimeFormat from "../../components/hourSettings/HourTimeFormat";
import HourTimeDefault from "../../components/hourSettings/HourTimeDefault";

export default function HourOptionSettings() {
  return (
    <>
      <HourTimeFormat />
      <HourTimeDefault />
    </>
  );
}

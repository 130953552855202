import { useContext } from "react";
import { dashboardModalValueType } from "./DashboardCards";
import { XMarkIcon } from "@heroicons/react/20/solid";
import ClipboardPlus from "../../images/clipboard-plus.svg";
import TemplateFrame from "../../images/template-frame.svg";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { useCreateSurvey } from "../../services/mutations/surveyMutations";
import { useCreateSurveySetting } from "../../services/mutations/surveySettingsMutations";
import { AuthContext } from "../../context/AuthContext";

export default function NewFormDialog({
  setModalValue,
}: {
  setModalValue: React.Dispatch<React.SetStateAction<dashboardModalValueType>>;
}) {
  const navigate = useNavigate();
  const createSurveyMutation = useCreateSurvey();
  const createSurveySettingsMutation = useCreateSurveySetting();

  const { user } = useContext(AuthContext);

  const navigateSurveyCreator = () => {
    const newId = uuidv4();
    createSurveyMutation.mutate({
      _id: newId,
      linkId: uuidv4(),
      created_date: new Date(),
      created_by: user?._id,
      modified_date: new Date(),
      modified_by: user?._id,
      isActive: true,
      isDeleted: false,
    });

    createSurveySettingsMutation.mutate({
      _id: uuidv4(),
      surveyID: newId,
      publishType: undefined,
      publishPermission: undefined,
      plans: undefined,
      reminderMailInstant: {
        subject: "Anlık Mail Bildirimi",
        content: "Anlık Mail",
        lastTimeSended: new Date(),
      },
      reminderMailNotStarted: {
        subject: "Başlamamış Mail Bildirimi",
        content: "Başlamamış Mail",
        lastTimeSended: new Date(),
      },
      reminderMailContinue: {
        subject: "Devam Eden Mail Bildirimi",
        content: "Devam Eden Mail",
        lastTimeSended: new Date(),
      },
      Roles: undefined,
    });

    navigate("/createSurvey/" + newId);
  };

  return (
    <>
      <div className="-mx-6 -mt-1 mb-7 grid border-b border-gray-200 pb-5">
        <div
          className="flex items-center justify-center"
          style={{ gridColumn: 1, gridRow: 1 }}
        >
          <span className="text-2xl font-semibold leading-8 text-slate-600">
            Değerlendirme Oluştur
          </span>
        </div>
        <div
          className="flex items-center justify-end"
          style={{ gridColumn: 1, gridRow: 1 }}
        >
          <XMarkIcon
            className="mr-5 h-8 w-8 cursor-pointer text-gray-500"
            aria-hidden="true"
            onClick={() => setModalValue("")}
          />
        </div>
      </div>

      <div className="mb-1 flex justify-center gap-10">
        <NewFormDialogChildCard
          iconSrc={ClipboardPlus}
          title="Değerlendirme Oluştur"
          desc="Kendi değerlendirmeni oluştur"
          appendToClassName="hover:border-blue-600 hover:bg-blue-50"
          bgColor="blue"
          onClick={navigateSurveyCreator}
        />
        <NewFormDialogChildCard
          iconSrc={TemplateFrame}
          title="Hazır Testler"
          desc="Hızlı değerlendirme için hazır testleri kullan"
          appendToClassName="hover:border-cyan-500 hover:bg-cyan-50"
          bgColor="cyan"
          onClick={() => setModalValue("pickTemplate")}
        />
      </div>
    </>
  );
}

function NewFormDialogChildCard({
  iconSrc,
  title,
  desc,
  appendToClassName,
  bgColor = "blue",
  onClick,
}: {
  iconSrc: string;
  title: string;
  desc: string;
  appendToClassName: string;
  bgColor?: "blue" | "cyan";
  onClick: () => void;
}) {
  return (
    <button
      className={
        "inline-flex h-[244px] w-[244px] flex-col items-center justify-start gap-4 rounded-lg border border-white py-12 shadow " +
        appendToClassName
      }
      onClick={onClick}
    >
      <div
        className={"inline-flex rounded-lg border p-4" + ` bg-${bgColor}-50`}
      >
        <img src={iconSrc} />
      </div>

      <div className="flex flex-col items-center gap-1 font-medium leading-normal">
        <span className="text-lg text-black">{title}</span>
        <span className="text-sm text-gray-400">{desc}</span>
      </div>
    </button>
  );
}

import React, { useMemo } from "react";
import {
  surveyElementsInterface,
  elementChoicesInterface,
} from "../../../Interfaces/SurveyInterfaces";
import { InputButton } from "../components/FormCompoents";

type Props = {
  question: surveyElementsInterface;
  register: any;
  endOfPage: boolean;
  setValue: any;
  errors: any;
  getValues: any;
  watch: any;
  clearErrors: any;
  questionIndex: number;
};

const SingleChoiceQuestion: React.FC<Props> = ({
  question,
  register,
  watch,
  endOfPage,
  setValue,
  clearErrors,
  errors,
  questionIndex,
}) => {
  const sortedChoices = useMemo(() => {
    if (question.shuffleOptions) {
      return (
        question.choices &&
        [...question.choices].sort(() => Math.random() - 0.5)
      );
    } else {
      return question.choices || [];
    }
  }, [question.choices, question.shuffleOptions]);

  const ChoicesTitle = useMemo(() => {
    return question.choices?.map(
      (choice: elementChoicesInterface) => choice.title?.tr
    );
  }, [question.choices]);

  const watchFunc = question.showOtherItem
    ? watch(question._id)?.value === "Other"
    : false;

  return (
    <div
      className={`mx-auto ${question.collapse ? "w-1/2" : "w-full"} position`}
      data-title={question.tipText?.tr}
    >
      <h6
        className={`mb-0`}
        dangerouslySetInnerHTML={{ __html: question.title?.tr ?? "" }}
      />
      <p className="text-gray-500">{question.description?.tr}</p>
      {sortedChoices?.map((choice: elementChoicesInterface, i: number) => (
        <div className="mb-2 " key={choice._id}>
          <InputButton
            name={question._id}
            questionIndex={questionIndex}
            options={ChoicesTitle}
            readOnly={question.readOnly ? question.readOnly : false}
            className={`mr-2  form-check-input ${
              !!errors[question._id] && "!border-red-500"
            }`}
            required={question.isRequired ?? false}
            register={register}
            questionTitle={question.title?.tr}
            type="radio"
            style={{ transform: "scale(1.2)" }}
            value={
              choice?.content
                ? choice?.content
                : choice.title?.tr
                  ? choice.title?.tr
                  : "seçenek " + (i + 1)
            }
            defaultChecked={question.defaultChoice === choice._id ?? false}
            id={`${question._id}_${choice._id}`}
            setValue={setValue}
            optionTitle={
              choice.title?.tr ? choice.title.tr : "seçenek " + (i + 1)
            }
            clearErrors={clearErrors}
          />
          <label
            htmlFor={`${question._id}_${choice._id}`}
            data-title={choice.info?.tr}
          >
            {choice.title?.tr ? choice.title?.tr : "Seçenek " + (i + 1)}
          </label>
        </div>
      ))}
      {question.showNoneItem && (
        <div className="mb-2 ">
          <InputButton
            name={question._id}
            questionIndex={questionIndex}
            options={ChoicesTitle}
            readOnly={question.readOnly ? question.readOnly : false}
            className={`mr-2  form-check-input ${
              !!errors[question._id] && "!border-red-500"
            }`}
            questionTitle={question.title?.tr}
            required={question?.isRequired ?? false}
            register={register}
            type="radio"
            style={{ transform: "scale(1.2)" }}
            value={"Fikrim Yok"}
            defaultChecked={false}
            id={`${question._id}_noIdea`}
            setValue={setValue}
            optionTitle={"Fikrim Yok"}
            clearErrors={clearErrors}
          />
          <label
            htmlFor={`${question._id}_noIdea`}
            data-title={question.noneInfo?.tr}
          >
            {question.noneText?.tr}
          </label>
        </div>
      )}
      {question.showOtherItem && (
        <div className="mb-2 flex">
          <InputButton
            name={question._id}
            questionIndex={questionIndex}
            options={ChoicesTitle}
            type={"radio"}
            questionTitle={question.title?.tr}
            id={`${question._id}_other`}
            style={{ transform: "scale(1.2)" }}
            required={
              question?.isRequired
                ? question?.isRequired
                : question?.isOtherItemRequired ?? false
            }
            value={"Other"}
            className={`mr-2  form-check-input ${
              !!errors[question._id] && "!border-red-500"
            }`}
            readOnly={question.readOnly ? question.readOnly : false}
            optionTitle={"Diğer"}
            register={register}
            defaultChecked={false}
            setValue={setValue}
            clearErrors={clearErrors}
          />
          {watchFunc ? (
            <textarea
              className={`border rounded pl-2  focus:outline-blue-30  w-3/4 h-[90px]`}
              {...register(question._id + ".OtherValue", { value: null })} //burayı başka isimle kaydettim gelecekte bize farklı durumlarda lazım olabilir
              placeholder={"Lütfen Yazınız"}
            />
          ) : (
            <label
              htmlFor={`${question._id}_other`}
              data-title={question.otherInfo?.tr}
            >
              {question.otherText?.tr ?? "Diğer"}
            </label>
          )}
        </div>
      )}

      <p className="mb-2 text-sm text-red-500">
        {errors[question._id]?.value?.message}
      </p>
      <p className="mb-4 text-gray-500">{question.bottomTicket?.tr}</p>
      {!endOfPage && question?.startWithNewLine !== true && <hr />}
    </div>
  );
};

export default SingleChoiceQuestion;

import { selectedUserAtom } from "components/atoms/dashboardAtoms";
import { useAtomValue } from "jotai";
import { classNames } from "../../../../lib/externalJS";

export default function EmployeeProfileBusinessTasks() {
  const selectedUser = useAtomValue(selectedUserAtom);

  const ROWS = selectedUser.gorevler.map(
    (gorev: { title: string; score: string }) => {
      const scoreAsNumber = parseInt(gorev.score);
      return {
        desc: gorev.title,
        priority: scoreAsNumber < 40 ? 3 : scoreAsNumber < 65 ? 2 : 1,
      };
    },
  );

  return (
    <div>
      {/* table header */}
      <div className="flex rounded-t-xl border border-gray-200 bg-gray-50 text-xs font-medium leading-[18px] text-gray-500">
        <div className="flex items-center p-3">Görevler</div>
      </div>

      {/* table items */}
      {ROWS.map((row: any, index: number) => (
        <div
          className={
            "flex border-x border-b border-gray-200 bg-white p-4 text-sm font-medium leading-tight text-gray-800 " +
            (index === ROWS.length - 1 ? "rounded-b-xl" : "")
          }
          key={index}
        >
          <div
            className={classNames(
              "w-full rounded-full px-3 py-[6px] text-sm font-medium text-black",
              getBgClass(row.priority),
            )}
          >
            {"• " + row.desc}
          </div>
        </div>
      ))}
    </div>
  );
}

function getBgClass(priority: number) {
  switch (priority) {
    case 1:
      return "bg-rose-200";
    case 2:
      return "bg-orange-200";
    case 3:
      return "bg-sky-200";
    default:
      return "bg-gray-100";
  }
}

import { useAtom, useAtomValue } from "jotai";
import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import {
  elementIndexAtom,
  pageIndexAtom,
  pagesAtom,
} from "../../atoms/surveyAtoms";

const FormSettingsTitle = () => {
  const [surveyPages, setSurveyPages] = useAtom(pagesAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const { t } = useTranslation();

  const handleTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSurveyPages((prevPages) => {
      return prevPages?.map((page, i) => {
        if (i === pageIndex && page.elements) {
          return {
            ...page,
            elements: page.elements.map((element, j) => {
              if (j === elementIndex) {
                return {
                  ...element,
                  title: {
                    tr: event.target.value,
                  },
                };
              }
              return element;
            }),
          };
        }
        return page;
      });
    });
  };

  return (
    <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
      <label
        className="block text-sm font-bold text-gray-500"
        htmlFor="enterQuestion"
      >
        {t("questionTag")}
      </label>
      <input
        id="enterQuestion"
        className="focus:shadow-outline mt-3 w-full appearance-none rounded-lg border border-gray-300 px-3 py-2 leading-tight text-gray-700 focus:outline-none"
        type="text"
        placeholder={`Soru ${elementIndex + 1}`}
        value={surveyPages?.[pageIndex].elements![elementIndex].title?.tr}
        onChange={(e) => handleTitleChange(e)}
      />
    </div>
  );
};

export default FormSettingsTitle;

import { useState } from "react";
import { elementChoicesInterface } from "../../../Interfaces/SurveyInterfaces";
import Select from "react-select";
import {
  selectLibraryItemsEn,
  selectLibraryItemsTR,
} from "../../../lib/consts";
import { useTranslation } from "react-i18next";
import { useAtomValue, useSetAtom } from "jotai";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../atoms/surveyAtoms";

const FormSettingsSelectLibrary = () => {
  const setStateSurveyJSON = useSetAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const [language, setLanguage] = useState("");

  const { t } = useTranslation();

  const handleLibrarySelection = (e: any) => {
    const newValue = e && e.value;
    const items =
      language === "TR"
        ? selectLibraryItemsTR
        : language === "EN"
          ? selectLibraryItemsEn
          : undefined;
    const selectedItems =
      items &&
      (items as { key: string; elements: elementChoicesInterface[] }[]).find(
        (item) => item.key === newValue,
      );
    if (selectedItems) {
      setStateSurveyJSON((prevState) => {
        let newState = { ...prevState };
        newState.pages![pageIndex!]!.elements![elementIndex!]!.choices =
          selectedItems.elements;
        return newState;
      });
    }
  };

  return (
    <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
      <label
        className="block text-sm font-bold text-gray-500"
        htmlFor="enterQuestion"
      >
        {t("selectLibrary")}
      </label>
      <Select
        className="basic-single mt-3"
        placeholder="Dil Seçiniz"
        isClearable={true}
        isSearchable={false}
        options={[
          { value: "TR", label: "Turkish" },
          { value: "EN", label: "English" },
        ]}
        onChange={(e) => setLanguage(e?.value || "")}
      />

      <Select
        className="basic-single mt-3"
        placeholder="Seçenekler Kütüphanesi"
        isClearable={true}
        isSearchable={false}
        options={
          language === "TR"
            ? [
                { value: "Cinsiyet", label: "Cinsiyet" },
                { value: "Günler", label: "Günler" },
                { value: "Aylar", label: "Aylar" },
              ]
            : language === "EN"
              ? [
                  { value: "Gender", label: "Gender" },
                  { value: "Days", label: "Days" },
                  { value: "Months", label: "Months" },
                ]
              : []
        }
        onChange={(e) => {
          handleLibrarySelection(e);
        }}
      />

      <p className="mb-0 mt-3 text-sm text-stone-500">
        {t("selectLibraryDescription")}
      </p>
    </div>
  );
};

export default FormSettingsSelectLibrary;

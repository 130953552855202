import { useAtom, useAtomValue } from "jotai";
import { useState } from "react";
import TimePicker from "react-time-picker";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../../atoms/surveyAtoms";

const HourTimeDefault = () => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const [specialValue, setSpecialValue] = useState<number>(1);

  const handleButtonClick = (buttonName: any) => {
    setStateSurveyJSON((prevState) => {
      let newState = { ...prevState };
      newState.pages![pageIndex].elements![elementIndex].otherValue =
        buttonName;
      return newState;
    });
  };
  return (
    <div className="border-b-2 px-3 py-3">
      <label className="mb-2 block text-sm font-bold text-gray-500">
        Varsayılan Saat
      </label>
      <div className="mb-1">
        <button
          onClick={() => {
            setSpecialValue(1);
            handleButtonClick(undefined);
          }}
          className={`${
            specialValue === 1 && "!bg-blue-500"
          } focus:shadow-outline h-9 w-16 !rounded-none !rounded-s-xl bg-slate-100 px-1 text-base text-slate-900 transition-colors duration-150 hover:bg-blue-200 hover:text-slate-100`}
        >
          Yok
        </button>
        <button
          onClick={() => {
            setSpecialValue(2);
            handleButtonClick(new Date().toLocaleTimeString());
          }}
          className={`${
            specialValue === 2 && "!bg-blue-500"
          } focus:shadow-outline h-9 w-16 !rounded-none bg-slate-100 px-1 text-base text-slate-900 transition-colors duration-150 hover:bg-blue-200 hover:text-slate-100`}
        >
          Şu anki
        </button>
        <button
          onClick={() => {
            handleButtonClick(new Date().toLocaleTimeString());
            setSpecialValue(3);
          }}
          className={`${
            specialValue === 3 && "!bg-blue-500"
          } focus:shadow-outline h-9 w-16 !rounded-none !rounded-e-xl bg-slate-100 px-1 text-base text-slate-900 transition-colors duration-150 hover:bg-blue-200 hover:text-slate-100`}
        >
          Özel
        </button>
      </div>
      {specialValue === 3 && (
        <TimePicker
          onChange={(e: any) => {
            setStateSurveyJSON((prevState) => {
              let newState = { ...prevState };
              newState.pages![pageIndex]!.elements![elementIndex]!.otherValue =
                e;
              return newState;
            });
          }}
          value={
            stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
              .otherValue
          }
          format={
            stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
              .timeFormat
              ? "hh:mm a"
              : "HH:mm"
          }
        />
      )}
      <p className="mb-0 mt-3 text-sm text-stone-500">
        Güncel ya da özel bir saat ile önceden doldurun
      </p>
    </div>
  );
};

export default HourTimeDefault;

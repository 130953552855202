import { useEffect, useState } from "react";
import { useAtom, useAtomValue } from "jotai";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../atoms/surveyAtoms";

const FormSettingsTitleSize = () => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const [localState, setLocalState] = useState({
    titleSize:
      stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]?.titleSize,
  });

  useEffect(() => {
    setStateSurveyJSON((prevState) => {
      let newState = { ...prevState };
      newState.pages![pageIndex].elements![elementIndex].titleSize =
        localState.titleSize;
      return newState;
    });
  }, [localState]);

  useEffect(() => {
    setLocalState(() => {
      let localStateObject = {
        titleSize:
          stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
            ?.titleSize,
      };
      return localStateObject;
    });
  }, [stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]?.titleSize]);

  const handleButtonClick = (buttonName: any) => {
    setLocalState({ titleSize: buttonName });
  };

  return (
    <div className="border-b-2 px-3 py-3">
      <label className="mb-2 block text-sm font-bold text-gray-500">
        Başlık Boyutu
      </label>
      <div className="mb-1">
        <button
          onClick={() => handleButtonClick("xl")}
          className={`${
            localState.titleSize === "xl" && "!bg-blue-500"
          } focus:shadow-outline h-9 w-22 !rounded-none !rounded-s-xl bg-slate-100 px-1 text-base text-slate-900 transition-colors duration-150 hover:bg-blue-200 hover:text-slate-100`}
        >
          Varsayılan
        </button>
        <button
          onClick={() => handleButtonClick("3xl")}
          className={`${
            localState.titleSize === "3xl" && "!bg-blue-500"
          } focus:shadow-outline h-9 w-16 !rounded-none bg-slate-100 px-1 text-base text-slate-900 transition-colors duration-150 hover:bg-blue-200 hover:text-slate-100`}
        >
          Geniş
        </button>
        <button
          onClick={() => handleButtonClick("md")}
          className={`${
            localState.titleSize === "md" && "!bg-blue-500"
          } focus:shadow-outline h-9 w-16 !rounded-none !rounded-e-xl bg-slate-100 px-1 text-base text-slate-900 transition-colors duration-150 hover:bg-blue-200 hover:text-slate-100`}
        >
          Küçük
        </button>
      </div>
    </div>
  );
};

export default FormSettingsTitleSize;

import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import Checkbox from "components/tailwindUi/Checkbox";
import { SelectItemType } from "components/tailwindUi/Select";
import TextInput from "components/tailwindUi/TextInput";
import { useEffect, useState } from "react";

interface SelectProps {
  items: SelectItemType[];
  onChange: (newValue: SelectItemType[]) => void;
  initialSelection?: SelectItemType[];
}

export default function SelectFilters({ items, onChange }: SelectProps) {
  const [localItems, setLocalItems] = useState(items as SelectItemType[]);

  const [searchTerm, setSearchTerm] = useState("");

  const filteredItems = localItems.filter((item) =>
    item.text.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const handleChange = (checked: boolean, item: SelectItemType) => {
    setLocalItems((prev) => {
      const newLocalItems = prev.map((x) => {
        if (x.value === item.value) {
          return { ...x, selected: checked };
        }
        return x;
      });

      onChange(newLocalItems);
      return newLocalItems;
    });

    // setSelectedItems((prev) => {
    //   let newSelection;

    //   if (checked) {
    //     newSelection = [...prev, item];
    //   } else {
    //     newSelection = prev.filter((x) => x !== item);
    //   }

    //   onChange(newSelection);
    //   return newSelection;
    // });
  };

  useEffect(() => {
    setLocalItems(items);
  }, [items]);

  return (
    <div className="rounded-md border border-gray-200 bg-gray-50 px-2 py-3">
      <TextInput
        placeholder="Ara"
        name="search"
        id="search"
        value={searchTerm}
        errorMessage=""
        onChange={(e) => setSearchTerm(e.target.value)}
        onClear={() => setSearchTerm("")}
        // onBlur={() => setSearchTerm("")}
        preInnerIconSlot={
          <MagnifyingGlassIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        }
        noAutoComplete
      />

      <div className="mt-2 flex h-50 flex-col gap-y-3 overflow-auto">
        {filteredItems.map((item) => {
          return (
            <Checkbox
              checkboxLabelComp={
                <span className="text-sm font-medium leading-[18px] text-slate-600">
                  {item.text}
                </span>
              }
              name={item.value}
              id={item.value}
              key={item.value}
              checked={item.selected}
              onChange={(event) => handleChange(event.target.checked, item)}
            />
          );
        })}
      </div>
    </div>
  );
}

import React, { ChangeEvent } from "react";
import { classNames } from "../../lib/externalJS";

type Props = {
  changeNoIdeaButton: () => void;
  handleNoneTextChange: (e: ChangeEvent<HTMLInputElement>) => void;
  areaGridNumber?: number;
  noneText?: { [key: string]: string };
  noneInfo?: { [key: string]: string };
  elementType: string;
  shouldDisplay: boolean;
};

const NoIdeaButton: React.FC<Props> = ({
  handleNoneTextChange,
  changeNoIdeaButton,
  areaGridNumber,
  noneText,
  noneInfo,
  elementType,
  shouldDisplay,
}) => {
  return (
    <div
      className="flex h-6 my-[6px] radio-button-elements-display-parent justify-center items-center"
      style={{
        left: "Auto !important",
        flexBasis: areaGridNumber ? `${100 / areaGridNumber}%` : "100%",
      }}
      data-title={noneInfo?.tr}
    >
      <input
        type={
          elementType === "likert" || elementType === "singleChoice"
            ? "radio"
            : "checkbox"
        }
        name="radios"
        className="!flex-grow-0 !flex-shrink-0 border-gray-400 flex-initial !h-5 !w-5"
      />
      <input
        type="text"
        className={classNames(
          shouldDisplay
            ? "placeholder:text-gray-400  hover:outline-dashed hover:outline-1 hover:outline-gray-400 focus:!outline-none"
            : "",
          "border-none w-full ml-1 p-2 radio-button-elements-display-input h-6"
        )}
        placeholder={`Fikrim Yok`}
        value={noneText?.tr ? noneText.tr : ""}
        onChange={(e) => handleNoneTextChange(e)}
      />
      <span
        className="!h-[24px] radio-button-elements-display"
        style={{ display: shouldDisplay ? "flex" : "none" }}
      >
        <span
          className="flex justify-center items-center transition-all 
      !h-5 !w-5 hover:bg-blue-200 p-3  delay-100 cursor-pointer rounded-lg"
          onClick={() => changeNoIdeaButton()}
        >
          <svg
            className="!flex-grow-0 !flex-shrink-0 flex-initial w-5 cursor-pointer"
            style={{ transform: "scale(1.2)" }}
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 4.85171L1.01901 7.8327C0.907478 7.94423 0.765526 8 0.593156 8C0.420786 8 0.278834 7.94423 0.1673 7.8327C0.0557668 7.72117 0 7.57921 0 7.40684C0 7.23447 0.0557668 7.09252 0.1673 6.98099L3.14829 4L0.1673 1.01901C0.0557668 0.907478 0 0.765526 0 0.593156C0 0.420786 0.0557668 0.278834 0.1673 0.1673C0.278834 0.0557668 0.420786 0 0.593156 0C0.765526 0 0.907478 0.0557668 1.01901 0.1673L4 3.14829L6.98099 0.1673C7.09252 0.0557668 7.23447 0 7.40684 0C7.57921 0 7.72117 0.0557668 7.8327 0.1673C7.94423 0.278834 8 0.420786 8 0.593156C8 0.765526 7.94423 0.907478 7.8327 1.01901L4.85171 4L7.8327 6.98099C7.94423 7.09252 8 7.23447 8 7.40684C8 7.57921 7.94423 7.72117 7.8327 7.8327C7.72117 7.94423 7.57921 8 7.40684 8C7.23447 8 7.09252 7.94423 6.98099 7.8327L4 4.85171Z"
              fill="#475467"
            />
          </svg>
        </span>
      </span>
    </div>
  );
};

export default NoIdeaButton;

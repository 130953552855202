import { Dispatch, useState } from "react";
import RichEditor from "../../../lib/RichEditor";

type Props = {
  setMailState: Dispatch<number>;
  formName: string;
};

const ReminderMailsEdit: React.FC<Props> = ({ setMailState, formName }) => {
  const [emailDetail, setEmailDetail] = useState<string>("");

  return (
    <>
      <div className="flex w-[720px] mx-auto mt-5">
        <h5 className="w-[720px] mx-auto text-start">{formName}</h5>
        <button className="w-[111px]">
          <span
            className="flex justify-center items-center font-semibold"
            onClick={() => setMailState(3)}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mr-2"
            >
              <path
                d="M15.8346 9.99984H4.16797M4.16797 9.99984L10.0013 15.8332M4.16797 9.99984L10.0013 4.1665"
                stroke="#475467"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Geri Dön
          </span>
        </button>
      </div>
      <div className="w-[720px] border rounded-xl  mx-auto">
        <div className="flex flex-col border-b pb-4 p-2 ">
          <label className="text-start ml-4 my-2 font-semibold">Konu</label>
          <input
            type="text"
            className="border rounded w-[664px] mx-3 px-2 h-10"
          />
          <label className="text-start ml-4 my-2 font-semibold">
            E-posta İçeriği
          </label>
          <div className="h-full ">
            <RichEditor
              placeholder={`E-posta içeriğini buraya yazınız...`}
              on={true}
              value={emailDetail}
              onChange={(e: any) => setEmailDetail(e)}
            />
          </div>
        </div>

        <div className="text-end bg-white rounded-b-xl p-2 mr-5">
          <button
            className="bg-[#2970ff] text-sm rounded-lg text-center align-middle hover:bg-blue-700 px-3 py-2 text-white w-[100px] h-9"
            onClick={() => setMailState(3)}
          >
            Kaydet
          </button>
        </div>
      </div>
    </>
  );
};

export default ReminderMailsEdit;

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Switch } from "@headlessui/react";
import { classNames } from "../../../lib/externalJS";
import { useAtom, useAtomValue } from "jotai";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../atoms/surveyAtoms";

const FormSettingsQuestionFix = () => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const [localState, setLocalState] = useState({
    noShuffle:
      stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]?.noShuffle,
  });

  const { t } = useTranslation();

  const handleNoShuffleChange = (e: boolean) => {
    const newValue = e;
    setLocalState((prevState) => {
      let newState = { ...prevState };
      newState.noShuffle = newValue;
      return newState;
    });
  };

  useEffect(() => {
    setStateSurveyJSON((prevState) => {
      let newState = { ...prevState };
      newState.pages![pageIndex]!.elements![elementIndex]!.noShuffle =
        localState.noShuffle;
      return newState;
    });
  }, [localState]);

  useEffect(() => {
    setLocalState(() => {
      let localStateObject = {
        noShuffle:
          stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
            ?.noShuffle,
      };
      return localStateObject;
    });
  }, [stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]?.noShuffle]);

  //burayı sonra yapacağım
  return (
    <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
      <label className="mb-2 block text-sm font-bold text-gray-500">
        {t("pinArea")}
      </label>
      <div className="flexs mb-2 mt-3">
        <Switch
          id="flexSwitchCheckShuffle"
          checked={localState.noShuffle ? localState.noShuffle : false}
          onChange={(e) => handleNoShuffleChange(e)}
          className={classNames(
            localState.noShuffle ? "bg-blue-600" : "bg-gray-200",
            "relative mr-2 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2",
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={classNames(
              localState.noShuffle ? "translate-x-5" : "translate-x-0",
              "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
            )}
          />
        </Switch>
        <label className="text-gray-500" htmlFor="flexSwitchCheckShuffle">
          {localState.noShuffle ? t("open") : t("close")}
        </label>
      </div>
      <p className="mb-0 mt-3 text-sm text-stone-500">
        {t("pinAreaDescription")}
      </p>
    </div>
  );
};

export default FormSettingsQuestionFix;

import { selectedUserAtom } from "components/atoms/dashboardAtoms";
import { useAtomValue } from "jotai";
import { Progress } from "../../Dashboard2";

export default function EmployeeProfileBusinessActivities() {
  const selectedUser = useAtomValue(selectedUserAtom);

  return (
    <div>
      {/* table header */}
      <div className="flex rounded-t-xl border border-gray-200 bg-gray-50 text-xs font-medium leading-[18px] text-gray-500">
        <div className="flex w-[158px] items-center border-r border-gray-200 p-3">
          Önem
        </div>
        <div className="flex w-[840px] items-center p-3">İş Faaliyetleri</div>
      </div>

      {/* table items */}
      {selectedUser.isFaaliyetleri.map((row: any, index: number) => (
        <div
          className={
            "flex border-x border-b border-gray-200 bg-white text-sm font-medium leading-tight text-gray-800 " +
            (index === selectedUser.isFaaliyetleri.length - 1
              ? "rounded-b-xl"
              : "")
          }
          key={index}
        >
          <div className="flex w-[158px] items-center justify-center border-r border-gray-200 py-3">
            <span className="mr-2 flex w-[26px] text-center text-sm font-semibold text-black">
              {row.score}
            </span>

            <div className="w-[100px]">
              <Progress isLabelsHidden color="blue" value={row.score} />
            </div>
          </div>

          <div className="w-[840px] p-4">
            <span className="text-sm font-medium text-black">
              {"• " + row.title + ":"}
            </span>

            {row.desc && (
              <span className="pl-1 text-sm font-light text-black">
                {row.desc}
              </span>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export type TemplateResponse = {
  _id: string;
  layout: "single" | "multiple";
  mainCategoryId: string;
  otherCategoryIds?: string[];
  title: string;
  description: string;
  path: string;
  images: {
    src: string;
    alt: string;
  }[];
  faqs: {
    question: string;
    answer: string;
  }[];
};

export default function TemplateCard({ items }: { items: TemplateResponse[] }) {
  return (
    <>
      {items.map((item) => (
        <div
          key={item.path}
          className="group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white shadow-sm"
        >
          <div className="aspect-h-2 aspect-w-3 sm:aspect-none bg-gray-200 group-hover:opacity-75 sm:h-43">
            <img
              src={item.images[0].src}
              alt={item.images[0].alt}
              className="h-full w-full object-cover object-center sm:h-full sm:w-full"
            />
          </div>
          <div className="flex flex-1 flex-col px-5 py-[14px]">
            <h3 className="text-sm font-medium text-gray-900">
              <a href={item.path}>
                <span aria-hidden="true" className="absolute inset-0" />
                {item.title}
              </a>
            </h3>
            <p className="truncate text-sm text-gray-500">{item.description}</p>
          </div>
        </div>
      ))}
    </>
  );
}

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Switch } from "@headlessui/react";
import { classNames } from "../../../lib/externalJS";
import { useAtom, useAtomValue } from "jotai";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../atoms/surveyAtoms";

const FormSettingsReadOnly = () => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const [localState, setLocalState] = useState({
    readOnly:
      stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]?.readOnly,
  });

  const { t } = useTranslation();

  const handleHideChange = (e: boolean) => {
    const newValue = e;
    setLocalState((prevState) => {
      let newState = { ...prevState };
      newState.readOnly = newValue;
      return newState;
    });
  };

  useEffect(() => {
    setStateSurveyJSON((prevState) => {
      let newState = { ...prevState };
      newState.pages![pageIndex]!.elements![elementIndex]!.readOnly =
        localState.readOnly;
      return newState;
    });
  }, [localState]);

  useEffect(() => {
    setLocalState(() => {
      let localStateObject = {
        readOnly:
          stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]?.readOnly,
      };
      return localStateObject;
    });
  }, [stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]?.readOnly]);

  return (
    <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
      <label className="mb-2 block text-sm font-bold text-gray-500">
        {t("readOnly")}
      </label>
      <div className="mb-2 mt-3 flex">
        <Switch
          id="flexSwitchCheckshuffleOptions"
          checked={localState.readOnly}
          onChange={(e) => handleHideChange(e)}
          className={classNames(
            localState.readOnly ? "bg-blue-600" : "bg-gray-200",
            "relative mr-2 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2",
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={classNames(
              localState.readOnly ? "translate-x-5" : "translate-x-0",
              "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
            )}
          />
        </Switch>
        <label
          className="text-gray-300"
          htmlFor="flexSwitchCheckshuffleOptions"
        >
          {localState.readOnly ? t("open") : t("close")}
        </label>
      </div>
      <p className="mb-0 mt-3 text-sm text-stone-500">
        {t("readOnlyDescription")}
      </p>
    </div>
  );
};

export default FormSettingsReadOnly;

import Select, { SelectItemType } from "../tailwindUi/Select";
import { Condition, LogicItem, conditionMap } from "./Logic";
import CardWithPlusMinusButtons from "./CardWithPlusMinusButtons";
import { SettingsCard } from "../../pages/dashboard2/SettingsProfile";
import TextInput from "../tailwindUi/TextInput";

export default function IfCard({
  ifItem,
  updateIfItem,
  addNewIfItem,
  deleteIfItem,
  ifSelectItems,
  isFirst,
  isLast,
}: {
  ifItem: LogicItem["ifs"][0];
  updateIfItem: (newIfItem: LogicItem["ifs"][0]) => void;
  addNewIfItem: () => void;
  deleteIfItem: () => void;
  ifSelectItems: SelectItemType[];
  isFirst?: boolean;
  isLast?: boolean;
}) {
  return (
    <CardWithPlusMinusButtons
      plusButtonOnClick={() => {
        if (isIfItemValid(ifItem)) {
          addNewIfItem();
        } else {
          updateIfItem({
            ...ifItem,
            isError: true,
          });
        }
      }}
      minusButtonOnClick={deleteIfItem}
      isPlusVisible={isFirst}
      isMinusVisible={!(isFirst && isLast)}
    >
      <SettingsCard isSubSetting isError={ifItem.isError}>
        <div className="grid gap-y-5">
          {/* IF */}
          <Select
            label="IF"
            placeholder="Select Field"
            id="if"
            items={ifSelectItems.map((item) => ({
              ...item,
              selected: item.value === ifItem.question,
            }))}
            onChange={(selected) => {
              selected = selected as SelectItemType;

              updateIfItem({
                ...ifItem,
                question: selected.value,
                questionId: selected.questionId,
                questionType: selected.questionType,
                condition: "",
                value: "",
                possibleValues: selected.possibleValues,
                isError: false,
                dataTableRows: selected.dataTableRows,
                dataTableCols: selected.dataTableCols,
                cellType: selected.cellType,
              });
            }}
            isSearchable={true}
          />

          {/* ALLORSOMEROWS */}
          {ifItem.questionType === "dataTable" && (
            <Select
              label="ALLORSOMEROWS"
              placeholder="Select ALLORSOMEROWS"
              id="allOrSomeRows"
              items={selectRowItems.map((item) => ({
                ...item,
                selected: item.value === ifItem.allOrSomeRows,
              }))}
              onChange={(selected) => {
                selected = selected as SelectItemType;

                updateIfItem({
                  ...ifItem,
                  allOrSomeRows: selected.value,
                  isError: false,
                });
              }}
            />
          )}

          {/* ROWS */}
          {ifItem.questionType === "dataTable" &&
            (ifItem.allOrSomeRows === "selectedRowsAll" ||
              ifItem.allOrSomeRows === "selectedRowsAny") && (
              <Select
                label="ROWS"
                placeholder="Select ROWS"
                id="rows"
                items={
                  ifItem.dataTableRows?.map((item) => ({
                    text: item.title.tr,
                    value: item._id,
                    selected: ifItem.selectedRows?.includes(item._id),
                  })) ?? []
                }
                isMultiSelect={true}
                onChange={(selected) => {
                  selected = selected as SelectItemType[];

                  updateIfItem({
                    ...ifItem,
                    selectedRows: selected.map((item) => item.value),
                    isError: false,
                  });
                }}
              />
            )}

          {/* ALLORSOMECOLS */}
          {ifItem.questionType === "dataTable" &&
            (ifItem.cellType === "SelectList" ||
              ifItem.cellType === "TextArea" ||
              ifItem.cellType === "NumberInput") && (
              <Select
                label="ALLORSOMECOLS"
                placeholder="Select ALLORSOMECOLS"
                id="allOrSomeCols"
                items={selectColItems.map((item) => ({
                  ...item,
                  selected: item.value === ifItem.allOrSomeCols,
                }))}
                onChange={(selected) => {
                  selected = selected as SelectItemType;

                  updateIfItem({
                    ...ifItem,
                    allOrSomeCols: selected.value,
                    isError: false,
                  });
                }}
              />
            )}

          {/* COLS */}
          {ifItem.questionType === "dataTable" &&
            (ifItem.allOrSomeCols === "selectedColsAll" ||
              ifItem.allOrSomeCols === "selectedColsAny") && (
              <Select
                label="COLS"
                placeholder="Select COLS"
                id="cols"
                items={
                  ifItem.dataTableCols?.map((item) => ({
                    text: item.title.tr,
                    value: item._id,
                    selected: ifItem.selectedCols?.includes(item._id),
                  })) ?? []
                }
                isMultiSelect={true}
                onChange={(selected) => {
                  selected = selected as SelectItemType[];

                  updateIfItem({
                    ...ifItem,
                    selectedCols: selected.map((item) => item.value),
                    isError: false,
                  });
                }}
              />
            )}

          {/* CONDITION */}
          <Select
            label="STATE"
            placeholder="Select State"
            id="state"
            items={getConditionsForQuestionType(
              ifItem.questionType,
              ifItem.cellType,
            ).map((item) => ({
              ...item,
              selected: item.value === ifItem.condition,
            }))}
            onChange={(selected) => {
              selected = selected as SelectItemType;

              updateIfItem({
                ...ifItem,
                condition: selected.value as Condition,
                isValueSelectable: getIsValueSelectable(
                  selected.value as Condition,
                ),
                isMultipleValuesSelectable: getIsMultipleValuesSelectable(
                  selected.value as Condition,
                  ifItem.questionType,
                  ifItem.cellType,
                ),
                value: "",
                isError: false,
              });
            }}
            disabled={ifItem.question === ""}
          />

          {/* VALUE */}
          {ifItem.isValueSelectable &&
            (ifItem.possibleValues && ifItem.possibleValues.length > 0 ? (
              <Select
                label="VALUE"
                placeholder="Value"
                id="value"
                items={
                  ifItem.possibleValues?.map((value) => ({
                    text: value,
                    value,
                    selected: ifItem.isMultipleValuesSelectable
                      ? ifItem.value.includes(value)
                      : ifItem.value === value,
                  })) ?? []
                }
                isMultiSelect={ifItem.isMultipleValuesSelectable}
                onChange={(selected) => {
                  if (ifItem.isMultipleValuesSelectable) {
                    selected = selected as SelectItemType[];
                    updateIfItem({
                      ...ifItem,
                      value: selected.map((item) => item.value),
                      isError: false,
                    });
                  } else {
                    selected = selected as SelectItemType;
                    updateIfItem({
                      ...ifItem,
                      value: selected.value,
                      isError: false,
                    });
                  }
                }}
                disabled={ifItem.condition === ""}
              />
            ) : (
              <TextInput
                label="VALUE"
                placeholder="Value"
                name="value"
                id="value"
                type={getTextInputType(ifItem.questionType)}
                defaultValue={""}
                errorMessage={""}
                onChange={(event) =>
                  updateIfItem({
                    ...ifItem,
                    value: event.target.value,
                    isError: false,
                  })
                }
                disabled={ifItem.condition === ""}
                isMultiline={
                  ifItem.questionType === "email" &&
                  ifItem.isMultipleValuesSelectable
                }
                hint={
                  ifItem.questionType === "email" &&
                  ifItem.isMultipleValuesSelectable
                    ? "Add each email address as a new line."
                    : undefined
                }
              />
            ))}
        </div>
      </SettingsCard>
    </CardWithPlusMinusButtons>
  );
}

function getConditionsForQuestionType(
  questionType: string | undefined,
  cellType?: string,
): { text: string; value: Condition }[] {
  console.log("getConditionsForQuestionType -> cellType", cellType);
  if (cellType) {
    questionType += cellType;
  }

  console.log("getConditionsForQuestionType -> questionType", questionType);
  console.log("getConditionsForQuestionType -> questionType", questionType);
  console.log("getConditionsForQuestionType -> questionType", questionType);

  if (!questionType) return [];

  if (
    questionType === "openableList" ||
    questionType === "singleChoice" ||
    questionType === "multiChoice" ||
    questionType === "dataTable" ||
    questionType === "dataTableRadioButton" ||
    questionType === "dataTableCheckBox" ||
    questionType === "dataTableSelectList" ||
    questionType === "likert"
  ) {
    return [
      {
        text: conditionMap.IS_EQUAL_TO,
        value: "IS_EQUAL_TO",
      },
      {
        text: conditionMap.IS_NOT_EQUAL_TO,
        value: "IS_NOT_EQUAL_TO",
      },
      {
        text: conditionMap.IS_ANY_OF,
        value: "IS_ANY_OF",
      },
      {
        text: conditionMap.IS_NOT_ANY_OF,
        value: "IS_NOT_ANY_OF",
      },
      {
        text: conditionMap.IS_EMPTY,
        value: "IS_EMPTY",
      },
      {
        text: conditionMap.IS_FILLED,
        value: "IS_FILLED",
      },
    ];
  }

  if (
    questionType === "starRating" ||
    questionType === "ranking" ||
    questionType === "NPS" ||
    questionType === "CES" ||
    questionType === "CSAT"
  ) {
    return [
      {
        text: conditionMap.IS_EQUAL_TO,
        value: "IS_EQUAL_TO",
      },
      {
        text: conditionMap.IS_NOT_EQUAL_TO,
        value: "IS_NOT_EQUAL_TO",
      },
      {
        text: conditionMap.IS_ANY_OF,
        value: "IS_ANY_OF",
      },
      {
        text: conditionMap.IS_NOT_ANY_OF,
        value: "IS_NOT_ANY_OF",
      },
      {
        text: conditionMap.IS_EMPTY,
        value: "IS_EMPTY",
      },
      {
        text: conditionMap.IS_FILLED,
        value: "IS_FILLED",
      },
      {
        text: conditionMap.IS_LESS_THAN,
        value: "IS_LESS_THAN",
      },
      {
        text: conditionMap.IS_GREATER_THAN,
        value: "IS_GREATER_THAN",
      },
    ];
  }

  if (questionType === "number" || questionType === "dataTableNumberInput") {
    return [
      {
        text: conditionMap.IS_EQUAL_TO,
        value: "IS_EQUAL_TO",
      },
      {
        text: conditionMap.IS_NOT_EQUAL_TO,
        value: "IS_NOT_EQUAL_TO",
      },
      {
        text: conditionMap.IS_EMPTY,
        value: "IS_EMPTY",
      },
      {
        text: conditionMap.IS_FILLED,
        value: "IS_FILLED",
      },
      {
        text: conditionMap.IS_LESS_THAN,
        value: "IS_LESS_THAN",
      },
      {
        text: conditionMap.IS_GREATER_THAN,
        value: "IS_GREATER_THAN",
      },
    ];
  }

  if (questionType === "hour" || questionType === "date") {
    return [
      {
        text: conditionMap.IS_EQUAL_TO,
        value: "IS_EQUAL_TO",
      },
      {
        text: conditionMap.IS_NOT_EQUAL_TO,
        value: "IS_NOT_EQUAL_TO",
      },
      {
        text: conditionMap.IS_ANY_OF,
        value: "IS_ANY_OF",
      },
      {
        text: conditionMap.IS_NOT_ANY_OF,
        value: "IS_NOT_ANY_OF",
      },
      {
        text: conditionMap.IS_EMPTY,
        value: "IS_EMPTY",
      },
      {
        text: conditionMap.IS_FILLED,
        value: "IS_FILLED",
      },
      {
        text: conditionMap.IS_BEFORE,
        value: "IS_BEFORE",
      },
      {
        text: conditionMap.IS_AFTER,
        value: "IS_AFTER",
      },
    ];
  }

  if (questionType === "email") {
    return [
      {
        text: conditionMap.IS_EQUAL_TO,
        value: "IS_EQUAL_TO",
      },
      {
        text: conditionMap.IS_NOT_EQUAL_TO,
        value: "IS_NOT_EQUAL_TO",
      },
      {
        text: conditionMap.IS_ANY_OF,
        value: "IS_ANY_OF",
      },
      {
        text: conditionMap.IS_NOT_ANY_OF,
        value: "IS_NOT_ANY_OF",
      },
      {
        text: conditionMap.CONTAINS,
        value: "CONTAINS",
      },
      {
        text: conditionMap.DOES_NOT_CONTAIN,
        value: "DOES_NOT_CONTAIN",
      },
      {
        text: conditionMap.IS_EMPTY,
        value: "IS_EMPTY",
      },
      {
        text: conditionMap.IS_FILLED,
        value: "IS_FILLED",
      },
    ];
  }

  if (
    questionType === "textArea" ||
    questionType === "longTextArea" ||
    questionType === "dataTableTextArea"
  ) {
    return [
      {
        text: conditionMap.IS_EQUAL_TO,
        value: "IS_EQUAL_TO",
      },
      {
        text: conditionMap.IS_NOT_EQUAL_TO,
        value: "IS_NOT_EQUAL_TO",
      },
      {
        text: conditionMap.CONTAINS,
        value: "CONTAINS",
      },
      {
        text: conditionMap.DOES_NOT_CONTAIN,
        value: "DOES_NOT_CONTAIN",
      },
      {
        text: conditionMap.STARTS_WITH,
        value: "STARTS_WITH",
      },
      {
        text: conditionMap.DOES_NOT_START_WITH,
        value: "DOES_NOT_START_WITH",
      },
      {
        text: conditionMap.ENDS_WITH,
        value: "CONTAINS",
      },
      {
        text: conditionMap.DOES_NOT_END_WITH,
        value: "DOES_NOT_CONTAIN",
      },
      {
        text: conditionMap.IS_EMPTY,
        value: "IS_EMPTY",
      },
      {
        text: conditionMap.IS_FILLED,
        value: "IS_FILLED",
      },
    ];
  }

  if (questionType === "address") {
    return [
      {
        text: conditionMap.CITY_IS_EQUAL_TO,
        value: "CITY_IS_EQUAL_TO",
      },
      {
        text: conditionMap.CITY_IS_NOT_EQUAL_TO,
        value: "CITY_IS_NOT_EQUAL_TO",
      },
      {
        text: conditionMap.STATE_IS_EQUAL_TO,
        value: "STATE_IS_EQUAL_TO",
      },
      {
        text: conditionMap.STATE_IS_NOT_EQUAL_TO,
        value: "STATE_IS_NOT_EQUAL_TO",
      },
      {
        text: conditionMap.COUNTRY_IS_EQUAL_TO,
        value: "COUNTRY_IS_EQUAL_TO",
      },
      {
        text: conditionMap.COUNTRY_IS_NOT_EQUAL_TO,
        value: "COUNTRY_IS_NOT_EQUAL_TO",
      },
      {
        text: conditionMap.CITY_IS_ANY_OF,
        value: "CITY_IS_ANY_OF",
      },
      {
        text: conditionMap.CITY_IS_NOT_ANY_OF,
        value: "CITY_IS_NOT_ANY_OF",
      },
      {
        text: conditionMap.STATE_IS_ANY_OF,
        value: "STATE_IS_ANY_OF",
      },
      {
        text: conditionMap.STATE_IS_NOT_ANY_OF,
        value: "STATE_IS_NOT_ANY_OF",
      },
      {
        text: conditionMap.COUNTRY_IS_ANY_OF,
        value: "COUNTRY_IS_ANY_OF",
      },
      {
        text: conditionMap.COUNTRY_IS_NOT_ANY_OF,
        value: "COUNTRY_IS_NOT_ANY_OF",
      },
      {
        text: conditionMap.IS_EMPTY,
        value: "IS_EMPTY",
      },
      {
        text: conditionMap.IS_FILLED,
        value: "IS_FILLED",
      },
    ];
  }

  if (
    questionType === "fullName" ||
    questionType === "tel" ||
    questionType === "signature" ||
    questionType === "document"
  ) {
    return [
      {
        text: conditionMap.IS_EMPTY,
        value: "IS_EMPTY",
      },
      {
        text: conditionMap.IS_FILLED,
        value: "IS_FILLED",
      },
    ];
  }

  if (questionType === "dataTableShuffleColumns") {
    return [
      {
        text: conditionMap.CONTAINS,
        value: "CONTAINS",
      },
      {
        text: conditionMap.DOES_NOT_CONTAIN,
        value: "DOES_NOT_CONTAIN",
      },
      {
        text: conditionMap.IS_EMPTY,
        value: "IS_EMPTY",
      },
      {
        text: conditionMap.IS_FILLED,
        value: "IS_FILLED",
      },
    ];
  }

  return [
    {
      text:
        "getConditionsForQuestionType unhandled questionType: " + questionType,
      value: undefined as any,
    },
  ];
}

function getTextInputType(questionType: string | undefined) {
  if (questionType === "number") return "number";
  return "text";
}

function getIsValueSelectable(condition: Condition): boolean {
  return condition !== "IS_EMPTY" && condition !== "IS_FILLED";
}

function getIsMultipleValuesSelectable(
  condition: Condition,
  questionType?: string,
  cellType?: string,
): boolean {
  if (cellType) {
    questionType += cellType;
  }

  return (
    condition === "IS_ANY_OF" ||
    condition === "IS_NOT_ANY_OF" ||
    questionType === "multiChoice" ||
    questionType === "dataTableCheckBox"
  );
}

export function isIfItemValid(ifItem: LogicItem["ifs"][0]) {
  console.log("isIfItemValid -> ifItem", JSON.stringify(ifItem, null, 2));

  return Boolean(
    ifItem.question &&
      ifItem.condition &&
      (!ifItem.isValueSelectable ||
        (ifItem.isMultipleValuesSelectable
          ? ifItem.value.length > 0
          : ifItem.value)),
  );
}

const selectRowItems = [
  {
    text: "All rows",
    value: "allRows",
  },
  {
    text: "Selected rows (And)",
    value: "selectedRowsAll",
  },
  {
    text: "Selected rows (Or)",
    value: "selectedRowsAny",
  },
];

const selectColItems = [
  {
    text: "All cols",
    value: "allCols",
  },
  {
    text: "Selected cols (And)",
    value: "selectedColsAll",
  },
  {
    text: "Selected cols (Or)",
    value: "selectedColsAny",
  },
];

import { ChangeEvent, useEffect, useState } from "react";
import { elementChoicesInterface } from "../../../Interfaces/SurveyInterfaces";
import { v4 as uuidv4 } from "uuid";
import { useAtom, useAtomValue } from "jotai";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../atoms/surveyAtoms";

const FormSettingsAddTableRow = () => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const [inputText, setInputText] = useState<string>("");

  useEffect(() => {
    const initialRows =
      stateSurveyJSON.pages![pageIndex!].elements![elementIndex!]?.rows;
    const initialItems = Array.isArray(initialRows)
      ? initialRows.map((row, index) =>
          row.title?.tr ? row.title.tr : `Satır ${index + 1}`,
        )
      : [];

    setInputText(initialItems.join("\n"));
  }, [stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]?.rows]);

  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = event.target.value;
    setInputText(inputValue);

    const inputLines = inputValue.split("\n").map((line) => line.trim());
    const nonEmptyLines = inputLines.filter((line) => line !== "");
    let uniqueLines = Array.from(new Set(nonEmptyLines));
    const objectArray = uniqueLines.map((line) => ({
      _id: uuidv4(),
      title: { tr: line },
    }));
    setRows(pageIndex, elementIndex, objectArray);
  };

  const setRows = (
    pageIndex: number | undefined,
    elementIndex: number | undefined,
    newRows: elementChoicesInterface[],
  ) => {
    if (pageIndex === undefined || elementIndex === undefined) {
      console.error("Invalid page index or element index.");
      return;
    }

    const objectArray: elementChoicesInterface[] = [];
    newRows.forEach((row) => {
      const existingData = stateSurveyJSON.pages?.[pageIndex]?.elements?.[
        elementIndex
      ].rows?.find((data) => data.title === row.title);
      const content = existingData ? existingData.content : undefined;
      const _id = row._id;
      const title = row.title;

      objectArray.push({
        title,
        _id,
        content: content,
      });
    });

    const updatedState = { ...stateSurveyJSON };

    updatedState!.pages![pageIndex]!.elements![elementIndex].rows = objectArray;

    setStateSurveyJSON(updatedState);
  };

  return (
    <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
      <label className="mb-2 block text-sm font-bold text-gray-500">
        Satırlar
      </label>
      <textarea
        value={inputText}
        onChange={handleInputChange}
        placeholder="test"
        className="w-full resize-none rounded border p-2"
        rows={inputText.split("\n").length + 1}
      />
      <p className="mb-0 mt-3 text-sm text-stone-500">
        Tablodaki satırların etiketlerini özelleştirin
      </p>
    </div>
  );
};

export default FormSettingsAddTableRow;

import { tierToColor, tierType, upgradeModalValueType } from "./Dashboard2";
import { ArrowLongLeftIcon, XMarkIcon } from "@heroicons/react/20/solid";
import PrimaryButton from "../../components/tailwindUi/PrimaryButton";
import Badge from "../../components/tailwindUi/Badge";

export default function PickTemplateDialog({
  setModalValue,
  tier,
}: {
  setModalValue: React.Dispatch<React.SetStateAction<upgradeModalValueType>>;
  tier: tierType;
}) {
  return (
    <>
      <div className="h-full flex flex-col">
        <div className="grid border-b border-gray-200 -mx-6 -mt-1 pb-5 mb-7">
          <div
            className="flex justify-center items-center"
            style={{ gridColumn: 1, gridRow: 1 }}
          >
            <span className="text-slate-600 text-2xl font-semibold leading-8">
              Pricing Plans
            </span>
          </div>
          <div
            className="flex justify-between items-center px-7"
            style={{ gridColumn: 1, gridRow: 1 }}
          >
            <div className="flex items-center gap-3 text-slate-600 text-lg font-medium leading-loose">
              Your plan:
              <Badge color={tierToColor(tier)} textSize={16}>
                {tier}
              </Badge>
            </div>
            <XMarkIcon
              className="h-8 w-8 text-gray-500 cursor-pointer"
              aria-hidden="true"
              onClick={() => setModalValue("")}
            />
          </div>
        </div>
        <embed
          src="https://en.wikipedia.org/wiki/Next.js"
          className="w-full h-full"
        />
      </div>
    </>
  );
}

import {
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
  Transition,
} from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { FilterOption, Person } from "Interfaces/general";
import TextInput from "components/tailwindUi/TextInput";
import { ChangeEvent, useState } from "react";

const filters2 = [
  {
    id: "departman",
    name: "Departman",
  },
  {
    id: "pozisyon",
    name: "Pozisyon",
  },
  {
    id: "cinsiyet",
    name: "Cinsiyet",
  },
  {
    id: "yas",
    name: "Yaş",
  },
];

interface FilterProps {
  people: Person[];
  originalPeople: Person[];
  onFilterChange: (filters: FilterOption[], searchQuery: string) => void;
  searchBarOn?: boolean;
}

export default function Filter({
  originalPeople,
  onFilterChange,
  searchBarOn,
}: FilterProps) {
  const [filters, setFilters] = useState<FilterOption[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const handleSearchChange = (value: string) => {
    setSearchQuery(value);
    onFilterChange(filters, value);
  };

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = event.target;

    let newFilters: FilterOption[] = [];

    if (checked) {
      newFilters = [...filters, { id: name, value }];
    } else {
      newFilters = filters.filter((filter) => filter.value !== value);
    }

    setFilters(newFilters);
    onFilterChange(newFilters, searchQuery);
  };

  const getUniqueValues = (key: keyof Person) => {
    return Array.from(
      new Set(originalPeople.map((person) => person[key])),
    ).filter(Boolean);
  };

  const getFilterCountByCategory = (category: string) => {
    return filters.filter((filter) => filter.id === category).length;
  };

  return (
    <div className="mt-2 flex items-center justify-between border-y">
      {searchBarOn ? (
        <div className="relative w-[290px] rounded-md shadow-sm">
          <TextInput
            placeholder="Çalışan Ara"
            name="search"
            id="search"
            value={searchQuery}
            errorMessage=""
            onChange={(e) => handleSearchChange(e.target.value)}
            preInnerIconSlot={
              <MagnifyingGlassIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            }
            noAutoComplete
          />
        </div>
      ) : (
        <div />
      )}

      <div className="flex items-center justify-end text-center">
        <section aria-labelledby="filter-heading" className="py-5">
          <h2 id="filter-heading" className="sr-only">
            Product filters
          </h2>

          <div className="flex items-center justify-between">
            <button
              type="button"
              className="inline-block text-sm font-medium text-gray-700 opacity-0 hover:text-gray-900 sm:hidden"
            >
              Filters
            </button>

            <PopoverGroup className="hidden sm:flex sm:items-baseline sm:space-x-8">
              {filters2.map((section, sectionIdx) => (
                <Popover
                  as="div"
                  key={section.name}
                  id={`desktop-menu-${sectionIdx}`}
                  className="relative inline-block text-left"
                >
                  <div>
                    <PopoverButton className="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                      <span>{section.name}</span>
                      {getFilterCountByCategory(section.id) !== 0 && (
                        <span className="ml-[6px] rounded bg-gray-200 px-[6px] py-[2px] text-xs font-semibold tabular-nums text-gray-700">
                          {getFilterCountByCategory(section.id)}
                        </span>
                      )}

                      <ChevronDownIcon
                        className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                    </PopoverButton>
                  </div>

                  <Transition
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <PopoverPanel className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <form className="space-y-4">
                        {section.id === "departman" && (
                          <>
                            {getUniqueValues("departman").map(
                              (value, optionIdx) => {
                                return (
                                  <div
                                    key={optionIdx}
                                    className="flex items-center"
                                  >
                                    <input
                                      type="checkbox"
                                      id={value.toString()}
                                      checked={filters.some(
                                        (filter) =>
                                          filter.value === value.toString(),
                                      )}
                                      name="departman"
                                      value={value.toString()}
                                      onChange={handleCheckboxChange}
                                      className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                                    />
                                    <label
                                      htmlFor={value.toString()}
                                      className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"
                                    >
                                      {value.toString()}
                                    </label>
                                  </div>
                                );
                              },
                            )}
                          </>
                        )}

                        {section.id === "pozisyon" && (
                          <>
                            {getUniqueValues("pozisyon").map(
                              (value, optionIdx) => {
                                return (
                                  <div
                                    key={optionIdx}
                                    className="flex items-center"
                                  >
                                    <input
                                      type="checkbox"
                                      id={value.toString()}
                                      name="pozisyon"
                                      checked={filters.some(
                                        (filter) =>
                                          filter.value === value.toString(),
                                      )}
                                      value={value.toString()}
                                      onChange={handleCheckboxChange}
                                      className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                                    />
                                    <label
                                      htmlFor={value.toString()}
                                      className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"
                                    >
                                      {value.toString()}
                                    </label>
                                  </div>
                                );
                              },
                            )}
                          </>
                        )}

                        {section.id === "cinsiyet" && (
                          <>
                            {["Erkek", "Kadın"].map((x) => (
                              <div className="flex items-center" key={x}>
                                <input
                                  type="checkbox"
                                  id={x}
                                  name="cinsiyet"
                                  value={x}
                                  checked={filters.some(
                                    (filter) => filter.value === x,
                                  )}
                                  onChange={handleCheckboxChange}
                                  className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                                />
                                <label
                                  htmlFor={x}
                                  className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"
                                >
                                  {x}
                                </label>
                              </div>
                            ))}
                          </>
                        )}

                        {section.id === "yas" && (
                          <>
                            {["18-24", "25-34", "35-44", "45-54", "55-65"].map(
                              (x) => (
                                <div className="flex items-center" key={x}>
                                  <input
                                    type="checkbox"
                                    id={x}
                                    name="yas"
                                    value={x}
                                    onChange={handleCheckboxChange}
                                    checked={filters.some(
                                      (filter) => filter.value === x,
                                    )}
                                    className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                                  />
                                  <label
                                    htmlFor={x}
                                    className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"
                                  >
                                    {x}
                                  </label>
                                </div>
                              ),
                            )}
                          </>
                        )}
                      </form>
                    </PopoverPanel>
                  </Transition>
                </Popover>
              ))}
            </PopoverGroup>
          </div>
        </section>
      </div>
    </div>
  );
}

import {
  SettingsCard,
  SettingsCardBtnContainer,
} from "../../pages/dashboard2/SettingsProfile";
import PrimaryButton from "../tailwindUi/PrimaryButton";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import Select, { SelectItemType } from "../tailwindUi/Select";
import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import { surveyElementsInterface } from "../../Interfaces/SurveyInterfaces";
import { LogicItem, doActionMap } from "./Logic";
import { useState } from "react";
import IfCard, { isIfItemValid } from "./IfCard";
import DoCard, { isDoItemValid } from "./DoCard";
import getIconForQuestionType from "./getIconForQuestionType";
import { useAtomValue } from "jotai";
import { surveyAtom } from "../atoms/surveyAtoms";

export default function AddCondition({
  setLogicTabState,
  addNewLogicItem,
  updateLogicItem,
  selectedRowToUpdate,
}: {
  setLogicTabState: (state: "table" | "addNewCondition") => void;
  addNewLogicItem: (newLogicItem: LogicItem) => void;
  updateLogicItem: (index: number, newLogicItem: LogicItem) => void;
  selectedRowToUpdate?: { logicItem: LogicItem; index: number };
}) {
  const stateSurveyJSON = useAtomValue(surveyAtom);

  const [newLogicItem, setNewLogicItemState] = useState<LogicItem>(
    selectedRowToUpdate?.logicItem ?? {
      ifs: [{ question: "", questionId: "", condition: "", value: "" }],
      allAny: "ANY",
      dos: [{ action: "", target: "", targetId: "" }],
    },
  );

  const isEdit = !!selectedRowToUpdate;

  let questionCount = 0;
  const ifSelectItems: SelectItemType[] =
    stateSurveyJSON.pages?.flatMap((page, pageCount) => {
      const pageText = `Page ${pageCount + 1}`;

      return [
        {
          text: pageText,
          value: pageText,
          isSeperator: true,
          icon: getIconForQuestionType("page"),
          disabled: true,
        },
        ...(page.elements?.map((question) => {
          questionCount += 1;

          const questionText = `${questionCount}. ${question.title?.tr}`;

          console.log(
            "AddCondition.tsx question",
            JSON.stringify(question, null, 2),
          );

          return {
            text: questionText,
            value: questionText,
            questionId: question._id,
            questionType: question.type,
            possibleValues: getPossibleValuesBasedOnQuestionType(question),
            icon: getIconForQuestionType(question.type),
            dataTableRows: question.rows,
            dataTableCols: question.columns,
            cellType: question.cellType,
            disabled: question.type === "title",
          };
        }) ?? []),
      ];
    }) ?? [];

  const doSelectItems: SelectItemType[] = Object.entries(doActionMap).map(
    ([action, actionText]) => ({
      text: actionText,
      value: action,
    }),
  );

  return (
    <div className="flex justify-center">
      <div className="my-9 w-full max-w-[720px]">
        <SettingsCard
          title="Add Condition"
          upRightSlot={
            <div className="pb-[6px]">
              <PrimaryButton
                isMinWidth
                isSecondary
                isGray
                isBorderless
                onClick={() => {
                  setLogicTabState("table");
                }}
              >
                <div className="flex items-center">
                  <ArrowLeftIcon className="mr-[6px] h-5 w-5" />
                  Back
                </div>
              </PrimaryButton>
            </div>
          }
        >
          {newLogicItem.ifs.map((ifItem, index) => (
            <IfCard
              key={index}
              ifItem={ifItem}
              updateIfItem={(newIfItem) => {
                newLogicItem.ifs[index] = newIfItem;
                setNewLogicItemState({ ...newLogicItem });
              }}
              addNewIfItem={() => {
                newLogicItem.ifs.push({
                  question: "",
                  questionId: "",
                  condition: "",
                  value: "",
                });
                setNewLogicItemState({ ...newLogicItem });
              }}
              deleteIfItem={() => {
                newLogicItem.ifs.splice(index, 1);
                setNewLogicItemState({ ...newLogicItem });
              }}
              ifSelectItems={ifSelectItems}
              isFirst={index === 0}
              isLast={index === newLogicItem.ifs.length - 1}
            />
          ))}

          {newLogicItem.ifs.length === 1 ? (
            <hr className="my-5 mr-15 border-t" />
          ) : (
            <div className="my-5 mr-15 flex items-center justify-between whitespace-nowrap text-base font-medium leading-tight text-black">
              <hr className="w-full border-t border-blue-600" />

              <span className="ml-5 mr-3">If</span>
              <Select
                id="do"
                items={[
                  {
                    text: "Any",
                    value: "ANY",
                    selected: newLogicItem.allAny === "ANY",
                  },
                  {
                    text: "All",
                    value: "ALL",
                    selected: newLogicItem.allAny === "ALL",
                  },
                ]}
                onChange={(selected) => {
                  console.log("selected", selected);
                  selected = selected as SelectItemType;
                  setNewLogicItemState({
                    ...newLogicItem,
                    allAny: selected.value as "ANY" | "ALL",
                  });
                }}
                isBlue
              />
              <span className="ml-3 mr-5">of the “If” rules are matched</span>

              <hr className="w-full border-t border-blue-600" />
            </div>
          )}

          {newLogicItem.dos.map((doItem, index) => (
            <DoCard
              key={index}
              doItem={doItem}
              updateDoItem={(newDoItem) => {
                newLogicItem.dos[index] = newDoItem;
                setNewLogicItemState({ ...newLogicItem });
              }}
              addNewDoItem={() => {
                newLogicItem.dos.push({
                  action: "",
                  target: "",
                  targetId: "",
                });
                setNewLogicItemState({ ...newLogicItem });
              }}
              deleteDoItem={() => {
                newLogicItem.dos.splice(index, 1);
                setNewLogicItemState({ ...newLogicItem });
              }}
              doSelectItems={doSelectItems}
              isFirst={index === 0}
              isLast={index === newLogicItem.dos.length - 1}
            />
          ))}

          <SettingsCardBtnContainer>
            <div className="flex w-full items-center justify-between">
              <div
                className={`flex items-center ${
                  isAnyError(newLogicItem) ? "" : "invisible"
                }`}
              >
                <ExclamationCircleIcon className="mr-2 h-5 w-5 text-red-500" />
                <span className="text-sm font-medium leading-normal text-red-500">
                  Please fill in the required fields before saving
                </span>
              </div>
              <PrimaryButton
                isMinWidth
                onClick={() => {
                  validateAllIfsAndDos(newLogicItem, setNewLogicItemState);
                  if (!isAnyError(newLogicItem)) {
                    if (isEdit) {
                      updateLogicItem(selectedRowToUpdate.index, newLogicItem);
                    } else {
                      addNewLogicItem(newLogicItem);
                    }
                    setLogicTabState("table");
                  }
                }}
              >
                Save
              </PrimaryButton>
            </div>
          </SettingsCardBtnContainer>
        </SettingsCard>
      </div>
    </div>
  );
}

function isAnyError(newLogicItem: LogicItem) {
  return (
    newLogicItem.ifs.some((ifItem) => ifItem.isError) ||
    newLogicItem.dos.some((doItem) => doItem.isError)
  );
}

function validateAllIfsAndDos(
  newLogicItem: LogicItem,
  setNewLogicItemState: any,
) {
  newLogicItem.ifs.forEach((ifItem, index) => {
    if (!isIfItemValid(ifItem)) {
      newLogicItem.ifs[index] = {
        ...ifItem,
        isError: true,
      };
    }
  });

  newLogicItem.dos.forEach((doItem, index) => {
    if (!isDoItemValid(doItem)) {
      newLogicItem.dos[index] = {
        ...doItem,
        isError: true,
      };
    }
  });

  setNewLogicItemState({ ...newLogicItem });
}

export function getPossibleValuesBasedOnQuestionType(
  question: surveyElementsInterface,
  isWithId = false,
) {
  console.log(
    "getPossibleValuesBasedOnQuestionType -> question",
    JSON.stringify(question, null, 2),
  );

  const questionType = question.type;

  if (
    questionType === "openableList" ||
    questionType === "singleChoice" ||
    questionType === "multiChoice" ||
    questionType === "likert"
  ) {
    if (isWithId) {
      return question.choices?.map((choice) => ({
        text: choice.title?.tr,
        value: choice._id,
      }));
    }
    return question.choices?.map((choice) => choice.title?.tr);
  }

  if (
    questionType === "NPS" ||
    questionType === "CES" ||
    questionType === "CSAT"
  ) {
    if (isWithId) {
      return question.rateValues?.map((rate) => ({
        text: rate.content,
        value: rate._id,
      }));
    }
    return question.rateValues?.map((rate) => rate.content);
  }

  if (questionType === "starRating" || questionType === "ranking") {
    if (isWithId) {
      return question.rateValues?.map((value) => ({
        text: value.content,
        value: value._id,
      }));
    }
    return question.rateValues?.map((value) => value.content);
  }

  if (questionType === "dataTable") {
    if (question.cellType === "SelectList") {
      return question.dropDownButton?.split("\n");
    }
    if (
      question.cellType === "TextArea" ||
      question.cellType === "NumberInput" ||
      question.cellType === "ShuffleColumns"
    ) {
      return [];
    }
    return question.columns?.map((column) => column.title?.tr);
  }

  if (
    questionType === "number" ||
    questionType === "hour" ||
    questionType === "date" ||
    questionType === "email" ||
    questionType === "textArea" ||
    questionType === "longTextArea"
  ) {
    return [];
  }

  return [
    "getPossibleValuesBasedOnQuestionType unhandled questionType: " +
      questionType,
  ];
}

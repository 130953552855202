import { ProgressCircle } from "@tremor/react";
import { selectedUserAtom } from "components/atoms/dashboardAtoms";
import { useAtomValue } from "jotai";
import { Progress } from "pages/dashboard2/Dashboard2";
import EmployeeProfileCard from "pages/dashboard2/EmployeeProfileCard";

export default function EmployeeProfileJobHarmony() {
  const selectedUser = useAtomValue(selectedUserAtom);

  return (
    <div className="mt-10 flex flex-col gap-y-10">
      <EmployeeProfileCard
        titleEmoji="💼"
        title="İşim"
        bgClassName="bg-orange-100"
      >
        <span className="text-sm font-normal leading-[21px] text-gray-800">
          {selectedUser.isOzeti}
        </span>
      </EmployeeProfileCard>

      <EmployeeProfileCard
        titleEmoji="📌"
        title="İş Uyumu"
        bgClassName="bg-red-200"
      >
        <div className="flex items-center justify-center gap-x-22">
          <ProgressCircle
            value={+selectedUser.uyumIsUyumu}
            color={getColorBasedOnValue(selectedUser.uyumIsUyumu)}
            radius={75}
            strokeWidth={12}
          >
            <div className="flex flex-col">
              <span className="text-center text-[28px] font-bold text-gray-800">
                %{selectedUser.uyumIsUyumu}
              </span>
              <span className="text-center text-xs font-normal text-gray-900">
                İş Uyumu
              </span>
            </div>
          </ProgressCircle>

          <div className="flex flex-col gap-y-4">
            {[
              ["Teknolojik Beceriler", selectedUser.uyumTeknolojiBecerileri],
              ["Beceriler", selectedUser.uyumBeceriler],
              ["Çalışma Tarzı", selectedUser.uyumCalismaTarzi],
              ["Bilgi", selectedUser.uyumBilgi],
              ["Kabiliyetler", selectedUser.uyumKabiliyetler],
            ].map((x) => (
              <div className="flex items-center text-sm font-medium leading-tight">
                <span className="mr-4 w-[150px] text-gray-800">{x[0]}</span>

                <div className="w-[278px]">
                  <Progress
                    isLabelsHidden
                    color={getColorBasedOnValue(x[1])}
                    value={x[1]}
                  />
                </div>

                <span className="ml-3 text-slate-700">{x[1] + "%"}</span>
              </div>
            ))}
          </div>
        </div>
      </EmployeeProfileCard>
    </div>
  );
}

function getColorBasedOnValue(value: string) {
  const val = +value;

  if (val < 50) return "red";
  if (val < 75) return "amber";
  return "green";
}

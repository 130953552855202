import DateType from "../../components/dateSettings/DateType";
import DateDefault from "../../components/dateSettings/DateDefault";
import DateMonths from "../../components/dateSettings/DateMonths";
import DateDays from "../../components/dateSettings/DateDays";

export default function DateOptionsSettings() {
  return (
    <>
      <DateType />
      <DateDefault />
      <DateMonths />
      <DateDays />
    </>
  );
}

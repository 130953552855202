import React, { useMemo } from "react";
import {
  elementChoicesInterface,
  surveyElementsInterface,
} from "../../Interfaces/SurveyInterfaces";
import { SelectItem } from "./components/FormCompoents";

type Props = {
  question: surveyElementsInterface;
  endOfPage: boolean;
  control: any;
  errors: any;
  questionIndex: number;
};

const OpenableListQuestion: React.FC<Props> = ({
  control,
  question,
  endOfPage,
  errors,
  questionIndex,
}) => {
  const sortedOptions = useMemo(() => {
    if (question.shuffleOptions) {
      return (
        question.choices &&
        [...question.choices].sort(() => Math.random() - 0.5)
      );
    } else {
      return question.choices || [];
    }
  }, [question.choices, question.shuffleOptions]);

  const style = {
    control: (base: any) => ({
      ...base,
      borderColor: errors[question._id]?.value.message ? "#EF4444" : "#2684FF",
      boxShadow: errors[question._id]?.value.message ? "none" : "#2684FF",
    }),
  };

  const optionsTitle = useMemo(() => {
    return question.choices?.map(
      (choice: elementChoicesInterface) => choice.title?.tr
    );
  }, [question.choices]);

  return (
    <div data-title={question.tipText?.tr}>
      <h6
        className="mb-0"
        dangerouslySetInnerHTML={{ __html: question.title?.tr ?? "" }}
      ></h6>
      <p className=" text-gray-500">{question.description?.tr}</p>
      <SelectItem
        control={control}
        className={`mb-2 w-1/2 `}
        optionsTitles={optionsTitle}
        questionIndex={questionIndex}
        questionType={question.type}
        style={style}
        options={[
          ...(sortedOptions || []).map((choice: elementChoicesInterface) => ({
            value: choice.content ?? choice.title?.tr ?? choice._id,
            label: choice.title?.tr ? choice.title.tr : choice._id,
            additionalInfo: choice.info?.tr,
          })),
        ]}
        name={question._id}
        placeholder={question.selectPlaceholder?.tr ?? "Seçim Yok"}
        isClearable={true}
        isSearchable={false}
        required={question.isRequired}
        limitationMin={question.limitationMin}
        limitationMax={question.limitationMax}
        questionTitle={question.title?.tr}
        defaultValue={
          question.defaultChoice && {
            value: ((): any => {
              const findIndexByContent = (content: any) =>
                sortedOptions?.findIndex((item) => item.content === content);
              const findIndexByTitle = (title: any) =>
                sortedOptions?.findIndex((item) => item.title?.tr === title);
              const findIndexById = (_id: any) =>
                sortedOptions?.findIndex((item) => item._id === _id);

              const contentIndex = findIndexByContent(question.defaultChoice);
              const titleIndex = findIndexByTitle(question.defaultChoice);
              const idIndex = findIndexById(question.defaultChoice);

              if (contentIndex !== -1) {
                return contentIndex;
              } else if (titleIndex !== -1) {
                return titleIndex;
              } else if (idIndex !== -1) {
                return idIndex;
              } else {
                return {};
              }
            })(),
          }
        }
        selectMulti={question.selectMulti ?? false}
      />
      <p className="mb-2 text-sm text-red-500">
        {errors[question._id]?.value.message}
      </p>
      <p className="mb-4 text-gray-500">{question.bottomTicket?.tr}</p>
      {!endOfPage && <hr />}
    </div>
  );
};

export default OpenableListQuestion;

import { useState } from "react";
import { PauseIcon } from "@heroicons/react/20/solid";
import { PlusIcon, TrashIcon, PlayIcon } from "@heroicons/react/24/outline";
import Checkbox from "../tailwindUi/Checkbox";
import PrimaryButton from "../tailwindUi/PrimaryButton";
import AddCondition from "./AddCondition";
import ThreeDotMenu from "../tailwindUi/ThreeDotMenu";
import { classNames } from "../../lib/externalJS";
import { useAtom } from "jotai";
import { surveyAtom } from "../atoms/surveyAtoms";

export const doActionMap = Object.freeze({
  SHOW: "Show",
  SHOW_PAGE: "Show Page",
  HIDE: "Hide",
  HIDE_PAGE: "Hide Page",
  SHOW_MULTIPLE: "Show Multiple",
  HIDE_MULTIPLE: "Hide Multiple",
  SHOW_OPTION: "Show Option",
  HIDE_OPTION: "Hide Option",
  GO_TO_QUESTION: "Go to Question",
  GO_TO_THANK_YOU_PAGE: "Go to Thank You Page",
  SHOW_CUSTOM_MESSAGE: "Show Custom Message",
  GO_TO_SURVEY: "Go to Survey",
  MAKE_REQUIRED: "Make Required",
  MAKE_NOT_REQUIRED: "Make Not Required",
  SET_MASK: "Set Mask",
});

export type DoAction = keyof typeof doActionMap;

export const conditionMap = Object.freeze({
  IS_EQUAL_TO: "Is equal to",
  IS_NOT_EQUAL_TO: "Is not equal to",
  IS_ANY_OF: "Is any of",
  IS_NOT_ANY_OF: "Is not any of",
  IS_EMPTY: "Is empty",
  IS_FILLED: "Is filled",
  IS_LESS_THAN: "Is less than",
  IS_GREATER_THAN: "Is greater than",
  IS_BEFORE: "Is before",
  IS_AFTER: "Is after",
  CONTAINS: "Contains",
  DOES_NOT_CONTAIN: "Doesn't contain",
  STARTS_WITH: "Starts with",
  DOES_NOT_START_WITH: "Doesn't start with",
  ENDS_WITH: "Ends with",
  DOES_NOT_END_WITH: "Doesn't end with",
  CITY_IS_EQUAL_TO: "City is equal to",
  CITY_IS_NOT_EQUAL_TO: "City is not equal to",
  CITY_IS_ANY_OF: "City is any of",
  CITY_IS_NOT_ANY_OF: "City is not any of",
  STATE_IS_EQUAL_TO: "State is equal to",
  STATE_IS_NOT_EQUAL_TO: "State is not equal to",
  STATE_IS_ANY_OF: "State is any of",
  STATE_IS_NOT_ANY_OF: "State is not any of",
  COUNTRY_IS_EQUAL_TO: "Country is equal to",
  COUNTRY_IS_NOT_EQUAL_TO: "Country is not equal to",
  COUNTRY_IS_ANY_OF: "Country is any of",
  COUNTRY_IS_NOT_ANY_OF: "Country is not any of",
});

export type Condition = keyof typeof conditionMap;

export type LogicItem = {
  ifs: {
    question: string;
    questionId: string;
    questionType?: string;
    condition: Condition | "";
    value: string | string[];
    possibleValues?: string[];
    isValueSelectable?: boolean;
    isMultipleValuesSelectable?: boolean;
    isError?: boolean;
    allOrSomeRows?: string;
    dataTableRows?: { _id: string; title: { [key: string | "tr"]: string } }[];
    selectedRows?: string[];
    allOrSomeCols?: string;
    dataTableCols?: { _id: string; title: { [key: string | "tr"]: string } }[];
    selectedCols?: string[];
    cellType?: string;
  }[];
  allAny: "ALL" | "ANY";
  dos: {
    action: DoAction | "";
    target: string | string[];
    targetId: string | string[];
    option?: string | string[];
    optionId?: string | string[];
    conditionMask?: string;
    isTargetSelectable?: boolean;
    isMultipleTargetsSelectable?: boolean;
    isOptionSelectable?: boolean;
    isCustomMessage?: boolean;
    isMaskAction?: boolean;
    isError?: boolean;
  }[];
  isDisabled?: boolean;
};

export default function Logic() {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);

  const logicItems = stateSurveyJSON?.logic ?? [];

  const [selectedIndexes, setSelectedIndexes] = useState<number[]>([]);
  const isAllSelectedDisabled =
    selectedIndexes.length > 0 &&
    selectedIndexes.every((x) => logicItems[x].isDisabled);

  const [logicTabState, setLogicTabState] = useState<
    "table" | "addNewCondition"
  >("table");

  const [selectedRow, setSelectedRow] = useState<
    { logicItem: LogicItem; index: number } | undefined
  >(undefined);

  const addNewLogicItem = (newLogicItem: LogicItem) => {
    setStateSurveyJSON((prev) => {
      const newState = { ...prev };
      newState.logic = [...(prev.logic ?? []), newLogicItem];
      return newState;
    });
  };

  const removeLogicItem = (index: number) => {
    setStateSurveyJSON((prev) => {
      const newState = { ...prev };
      newState.logic = prev.logic?.filter((_, i) => i !== index);
      return newState;
    });
  };

  const updateLogicItem = (index: number, updatedLogicItem: LogicItem) => {
    setStateSurveyJSON((prev) => {
      const newState = { ...prev };
      newState.logic = prev.logic?.map((item, i) =>
        i === index ? updatedLogicItem : item,
      );
      return newState;
    });
  };

  return (
    <>
      {logicTabState === "table" && (
        <div className="flex justify-center">
          <div className="my-9 w-full max-w-[1002px]">
            <span className="text-lg font-medium leading-tight text-gray-800">
              Conditions
            </span>

            {/* table header */}
            <div className="mt-4 flex rounded-t-xl border border-gray-200 bg-gray-50 text-xs font-medium leading-[18px] text-gray-500">
              <div className="flex w-[420px] items-center p-3">
                <Checkbox
                  name="checkAllLogicItems"
                  id="checkAllLogicItems"
                  checked={
                    logicItems.length !== 0 &&
                    logicItems.length === selectedIndexes.length
                  }
                  onChange={(event) =>
                    event.target.checked
                      ? setSelectedIndexes(logicItems.map((_, i) => i))
                      : setSelectedIndexes([])
                  }
                />
                IF
              </div>
              <div className="flex w-[100px] items-center justify-center border-l border-r border-gray-200 p-3">
                ALL-ANY
              </div>
              <div className="flex w-[420px] items-center p-3">DO</div>
            </div>

            {/* table items */}
            {logicItems.map((logicItem, index) => (
              <div
                className={classNames(
                  logicItem.isDisabled
                    ? "bg-gray-50 text-gray-400"
                    : "text-gray-800",
                  "flex border-x border-b border-gray-200 text-sm font-medium leading-tight",
                )}
                key={index}
              >
                <div className="flex w-[420px] items-center p-3">
                  <Checkbox
                    checked={selectedIndexes.includes(index)}
                    name="checkAllLogicItems"
                    id="checkAllLogicItems"
                    onChange={(event) =>
                      event.target.checked
                        ? setSelectedIndexes([...selectedIndexes, index])
                        : setSelectedIndexes(
                            selectedIndexes.filter((i) => i !== index),
                          )
                    }
                  />
                  <div className="grid gap-1">
                    {logicItem.ifs.map((ifItem, index) => {
                      const questionParsed = ifItem.question.substring(
                        ifItem.question.indexOf(". ") + 2,
                      );

                      return (
                        <div
                          className="overflow-hidden text-ellipsis whitespace-nowrap"
                          key={index}
                        >
                          <span
                            className={classNames(
                              !logicItem.isDisabled ? "text-blue-600" : "",
                              "font-semibold",
                            )}
                          >
                            {"IF "}
                          </span>
                          {questionParsed + " "}
                          <span
                            className={classNames(
                              !logicItem.isDisabled ? "text-blue-600" : "",
                              "font-semibold",
                            )}
                          >
                            {ifItem.condition.replaceAll("_", " ") + " "}
                          </span>
                          {Array.isArray(ifItem.value)
                            ? ifItem.value.join(", ")
                            : ifItem.value}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="flex w-[100px] items-center justify-center border-l border-r border-gray-200 p-3">
                  {logicItem.ifs.length === 1 ? (
                    "-"
                  ) : (
                    <span
                      className={classNames(
                        !logicItem.isDisabled ? "text-blue-600" : "",
                        "font-semibold",
                      )}
                    >
                      {logicItem.allAny}
                    </span>
                  )}
                </div>
                <div className="flex w-[420px] items-center border-r p-3">
                  <div className="grid gap-1">
                    {logicItem.dos.map((doItem, index) => {
                      const target = Array.isArray(doItem.target)
                        ? doItem.target
                        : [doItem.target];
                      const targetParsed = target
                        .map((targetItem) => {
                          const targetItemParsed = targetItem.substring(
                            targetItem.indexOf(". ") + 2,
                          );
                          return targetItemParsed;
                        })
                        .join(", ");

                      return (
                        <div
                          className="overflow-hidden text-ellipsis whitespace-nowrap"
                          key={index}
                        >
                          <span
                            className={classNames(
                              !logicItem.isDisabled ? "text-blue-600" : "",
                              "font-semibold",
                            )}
                          >
                            {doItem.action.replaceAll("_", " ") + " "}
                          </span>
                          {targetParsed}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="flex w-[60px] items-center bg-white p-3 pr-5">
                  <ThreeDotMenu
                    items={[
                      {
                        text: "Edit",
                        onClick: () => {
                          setSelectedRow({ logicItem, index });
                          setLogicTabState("addNewCondition");
                        },
                      },
                      {
                        text: "Duplicate",
                        onClick: () => addNewLogicItem(logicItem),
                      },
                      {
                        text: logicItem.isDisabled ? "Resume" : "Disable",
                        onClick: () =>
                          updateLogicItem(index, {
                            ...logicItem,
                            isDisabled: !logicItem.isDisabled,
                          }),
                      },
                      {
                        text: "Delete",
                        onClick: () => removeLogicItem(index),
                        isRed: true,
                        isTopBorder: true,
                      },
                    ]}
                  />
                </div>
              </div>
            ))}

            {/* table footer */}
            <div className="flex justify-between rounded-b-xl border-x border-b border-gray-200 bg-white py-4 pl-3 pr-5 text-sm font-semibold leading-tight">
              <div className="flex">
                <div className="mr-3">
                  <PrimaryButton
                    disabled={selectedIndexes.length === 0}
                    isSecondary={!isAllSelectedDisabled}
                    isGray={!isAllSelectedDisabled}
                    isMinWidth
                    onClick={() => {
                      selectedIndexes.forEach((selectedIndex) =>
                        updateLogicItem(selectedIndex, {
                          ...logicItems[selectedIndex],
                          isDisabled: isAllSelectedDisabled ? false : true,
                        }),
                      );
                      setSelectedIndexes([]);
                    }}
                  >
                    <div className="flex items-center">
                      {isAllSelectedDisabled ? (
                        <>
                          <PlayIcon className="mr-[6px] h-5 w-5" />
                          Resume
                        </>
                      ) : (
                        <>
                          <PauseIcon className="mr-[6px] h-5 w-5" />
                          Disable
                        </>
                      )}
                    </div>
                  </PrimaryButton>
                </div>
                <PrimaryButton
                  disabled={selectedIndexes.length === 0}
                  isRed
                  isMinWidth
                  onClick={() => {
                    selectedIndexes
                      .reverse()
                      .forEach((index) => removeLogicItem(index));
                    setSelectedIndexes([]);
                  }}
                >
                  <div className="flex items-center">
                    <TrashIcon className="mr-[6px] h-5 w-5" />
                    Delete
                  </div>
                </PrimaryButton>
              </div>

              <PrimaryButton
                isMinWidth
                onClick={() => {
                  setSelectedRow(undefined);
                  setLogicTabState("addNewCondition");
                }}
              >
                <div className="flex items-center">
                  <PlusIcon className="mr-[6px] h-5 w-5" />
                  New Condition
                </div>
              </PrimaryButton>
            </div>
          </div>
        </div>
      )}
      {logicTabState === "addNewCondition" && (
        <AddCondition
          setLogicTabState={setLogicTabState}
          addNewLogicItem={addNewLogicItem}
          updateLogicItem={updateLogicItem}
          selectedRowToUpdate={selectedRow}
        />
      )}
    </>
  );
}

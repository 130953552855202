import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { register, signIn } from "../api/userApi";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";

export function useCreateUser() {
  const navigateTo = useNavigate();
  const { login } = useContext(AuthContext);

  return useMutation({
    mutationFn: (data: any) => register(data),
    onMutate: () => {
      console.log("onMutate");
    },
    onError: (error: any) => {
      if (error.response && error.response.status === 400) {
        toast.error("Yanlış veya eksik bilgi girdiniz");
      } else {
        toast.error("Bir hata oluştu");
      }
      console.log(error);
    },
    onSuccess: (data: any, variables: any, context: any) => {
      toast.success("Kayıt başarıyla yapıldı");
      const { _id, name, surname, email } = data.data;
      login({
        _id: _id,
        name: name,
        surname: surname,
        email: email,
        isActive: true,
      });
      navigateTo("/");
    },
    onSettled: async (
      data: any,
      error: any,
      variables: any,
      context: any
    ) => {},
  });
}

export function useSignIn() {
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);

  return useMutation(
    ({ email, password }: { email: string; password: string }) =>
      signIn(email, password),
    {
      onSuccess: (data: any) => {
        const { _id, name, surname, email } = data.data;
        login({
          _id: _id,
          name: name,
          surname: surname,
          email: email,
          isActive: true,
        });
        navigate("/");
      },
      onError: (error) => {
        toast.error("Giriş başarısız");
      },
    }
  );
}

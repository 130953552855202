import { useAtom, useAtomValue } from "jotai";
import { ChangeEvent, useEffect, useState } from "react";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../atoms/surveyAtoms";

const FormSettingsSmallestValue = () => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const [localState, setLocalState] = useState({
    rankingLowestValue:
      stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
        ?.rankingLowestValue,
  });

  useEffect(() => {
    setStateSurveyJSON((prevState) => {
      let newState = { ...prevState };
      newState.pages![pageIndex]!.elements![elementIndex]!.rankingLowestValue =
        localState.rankingLowestValue;
      return newState;
    });
  }, [localState]);

  const handlesmallestValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setLocalState((prevState) => {
      const newState = { ...prevState };
      newState.rankingLowestValue = parseInt(newValue);
      return newState;
    });
  };

  return (
    <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
      <label
        className="mb-2 block text-sm font-bold text-gray-500"
        htmlFor="enterTitle"
      >
        En Düşük Değer
      </label>
      <input
        id="enterTitle"
        className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow-sm focus:outline-none"
        type="number"
        placeholder="1"
        value={localState.rankingLowestValue}
        onChange={(e) => handlesmallestValueChange(e)}
      />
      <p className="mb-0 mt-3 text-sm text-stone-500">En Düşük Değer</p>
    </div>
  );
};

export default FormSettingsSmallestValue;

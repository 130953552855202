import { createContext, ReactNode, useEffect, useState } from "react";
import { user } from "../Interfaces/SurveyInterfaces";

const AuthContext = createContext<any>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState<user | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      setIsLoggedIn(true);
      setUser(JSON.parse(user));
    }
    setIsLoading(false);
  }, []);

  const login = (User: user) => {
    setIsLoggedIn(true);
    setUser(User);
    localStorage.setItem("user", JSON.stringify(User));
  };

  const logout = () => {
    setIsLoggedIn(false);
    setUser(null);
    localStorage.removeItem("user");
  };

  const authValue = {
    isLoggedIn,
    user,
    login,
    logout,
    isLoading,
  };

  return (
    <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };

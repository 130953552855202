import DateAgeVerify from "../../components/dateSettings/DateAgeVerify";
import DatePastFuture from "../../components/dateSettings/DatePastFuture";
import DateStartEnd from "../../components/dateSettings/DateStartEnd";

export default function DateLimitationSettings() {
  return (
    <>
      <DateAgeVerify />
      <DatePastFuture />
      <DateStartEnd />
    </>
  );
}

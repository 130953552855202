import { selectedUserAtom } from "components/atoms/dashboardAtoms";
import { useAtomValue } from "jotai";
import { getRowsForThisKeys } from "pages/dashboard2/EmployeeProfileSkillsTech";
import EmployeeProfileCard from "./EmployeeProfileCard";
import { SkillsTable } from "./EmployeeProfileSkillsPanel";

const dikkatlilik = ["Seçici Dikkat", "Zaman Paylaşımı"];

const fikir = [
  "Kategori Esnekliği",
  "Tümdengelimsel Akıl Yürütme",
  "Fikir Üretme",
  "Tümevarımsal Akıl Yürütme",
  "Bilgi Düzenleme",
  "Özgünlük",
  "Sorunlara Duyarlılık",
];

const hafiza = ["Ezberleme"];

const algisal = ["Kapanış Esnekliği", "Algısal Hız", "Kapanış Hızı"];

const sayisal = ["Matematiksel Muhakeme", "Dört İşlem Bilgisi"];

const uzamsal = ["Mekânsal Yönelim", "Görselleştirme"];

const sozel = [
  "Sözlü Anlama",
  "Sözlü Anlatım",
  "Yazılı Anlama",
  "Yazılı İfade",
];

const dayaniklilik = ["Dayanıklılık"];

const esneklik = [
  "Dinamik Esneklik",
  "Kapsam Esnekliği",
  "Kaba Vücut Koordinasyonu",
  "Kaba Vücut Dengesi",
];

const fizikselGuc = [
  "Dinamik Güç",
  "Patlayıcı Güç",
  "Statik Güç",
  "Gövde Gücü",
];

const hareket = [
  "Kontrol Hassasiyeti",
  "Çoklu Kol ve Bacak Koordinasyonu",
  "Oran Kontrolü",
  "Yanıt Yönlendirmesi",
];

const inceManevra = ["Kol-El Sabitliği", "Parmak Becerisi", "El Becerisi"];

const reaksiyon = [
  "Reaksiyon Süresi",
  "Uzuv Hareket Hızı",
  "Bilek-Parmak Hızı",
];

const isitsel = [
  "İşitsel Dikkat",
  "İşitme Hassasiyeti",
  "Ses Lokalizasyonu",
  "Konuşma Netliği",
  "Konuşma Tanıma",
];

const gorsel = [
  "Derinlik Algısı",
  "Uzak Görüş",
  "Parlama Hassasiyeti",
  "Yakın Görüş",
  "Gece Görüşü",
  "Çevresel Görüş",
  "Görsel Renk Ayrımı",
];

export const talentSkills = [
  ...dikkatlilik,
  ...fikir,
  ...hafiza,
  ...algisal,
  ...sayisal,
  ...uzamsal,
  ...sozel,
  ...dayaniklilik,
  ...esneklik,
  ...fizikselGuc,
  ...hareket,
  ...inceManevra,
  ...reaksiyon,
  ...isitsel,
  ...gorsel,
].map((x) => ({ text: x, value: x }));

export default function EmployeeProfileSkillsTech() {
  const selectedUser = useAtomValue(selectedUserAtom);

  const getRowsForThisTab = (keys: string[]) => {
    return getRowsForThisKeys(selectedUser, "puanKabiliyetler_", keys);
  };

  const CARDS = Object.freeze([
    {
      titleEmoji: "❗",
      title: "Dikkatlilik",
      bgClassName: "bg-red-100",
      score: selectedUser.puanKabiliyetler_dikkatlilik.yuzde,
      rows: getRowsForThisTab(dikkatlilik),
    },
    {
      titleEmoji: "🤔",
      title: "Fikir Üretme ve Muhakeme Yetenekleri",
      bgClassName: "bg-amber-100",
      score:
        selectedUser.puanKabiliyetler_fikirUretmeVeMuhakemeYetenekleri.yuzde,
      rows: getRowsForThisTab(fikir),
    },
    {
      titleEmoji: "💾",
      title: "Hafıza",
      bgClassName: "bg-gray-100",
      score: selectedUser.puanKabiliyetler_hafiza.yuzde,
      rows: getRowsForThisTab(hafiza),
    },
    {
      titleEmoji: "👁️‍🗨️",
      title: "Algısal Yetenekler",
      bgClassName: "bg-purple-100",
      score: selectedUser.puanKabiliyetler_algisalYetenekler.yuzde,
      rows: getRowsForThisTab(algisal),
    },
    {
      titleEmoji: "🔢",
      title: "Sayısal Yetenekler",
      bgClassName: "bg-blue-100",
      score: selectedUser.puanKabiliyetler_sayisalYetenekler.yuzde,
      rows: getRowsForThisTab(sayisal),
    },
    {
      titleEmoji: "🔮",
      title: "Uzamsal Yetenekler",
      bgClassName: "bg-violet-100",
      score: selectedUser.puanKabiliyetler_uzamsalYetenekler.yuzde,
      rows: getRowsForThisTab(uzamsal),
    },
    {
      titleEmoji: "🗣️",
      title: "Sözel Yetenekler",
      bgClassName: "bg-orange-100",
      score: selectedUser.puanKabiliyetler_sözelYetenekler.yuzde,
      rows: getRowsForThisTab(sozel),
    },
    {
      titleEmoji: "🏔",
      title: "Dayanıklılık",
      bgClassName: "bg-violet-100",
      score: selectedUser.puanKabiliyetler_dayaniklilik.yuzde,
      rows: getRowsForThisTab(dayaniklilik),
    },
    {
      titleEmoji: "🏃‍♀️",
      title: "Esneklik, Denge ve Koordinasyon",
      bgClassName: "bg-cyan-100",
      score:
        selectedUser["puanKabiliyetler_esneklik,DengeVeKoordinasyon"].yuzde,
      rows: getRowsForThisTab(esneklik),
    },
    {
      titleEmoji: "💪🏻",
      title: "Fiziksel Güç Yetenekleri",
      bgClassName: "bg-lime-100",
      score: selectedUser.puanKabiliyetler_fizikselGucYetenekleri.yuzde,
      rows: getRowsForThisTab(fizikselGuc),
    },
    {
      titleEmoji: "💨",
      title: "Hareket Yeteneklerini Kontrol Etme",
      bgClassName: "bg-cyan-100",
      score: selectedUser.puanKabiliyetler_hareketYetenekleriniKontrolEt.yuzde,
      rows: getRowsForThisTab(hareket),
    },
    {
      titleEmoji: "🖐🏻",
      title: "İnce Manevra Yetenekleri",
      bgClassName: "bg-fuchsia-100",
      score: selectedUser.puanKabiliyetler_i̇nceManevraYetenekleri.yuzde,
      rows: getRowsForThisTab(inceManevra),
    },
    {
      titleEmoji: "🏎️",
      title: "Reaksiyon Süresi ve Hız Yetenekleri",
      bgClassName: "bg-rose-100",
      score:
        selectedUser.puanKabiliyetler_reaksiyonSuresiVeHizYetenekleri.yuzde,
      rows: getRowsForThisTab(reaksiyon),
    },
    {
      titleEmoji: "🎧",
      title: "İşitsel ve Konuşma Yetenekleri",
      bgClassName: "bg-slate-100",
      score: selectedUser.puanKabiliyetler_i̇sitselVeKonusmaYetenekleri.yuzde,
      rows: getRowsForThisTab(isitsel),
    },
    {
      titleEmoji: "👁️",
      title: "Görsel Yetenekler",
      bgClassName: "bg-orange-100",
      score: selectedUser.puanKabiliyetler_görselYetenekler.yuzde,
      rows: getRowsForThisTab(gorsel),
    },
  ]);

  return (
    <>
      {CARDS.map((card, index) => (
        <EmployeeProfileCard {...card} isCollapsible>
          <SkillsTable rows={card.rows} />
        </EmployeeProfileCard>
      ))}
    </>
  );
}

import talentCooker from "../images/talentCookerLogo.svg";

export default function TalentCookerLogo({
  width,
  className,
}: {
  width: number;
  className?: string;
}) {
  return <img className={className} src={talentCooker} alt="" width={width} />;
}

export default function AssessmentCenter({ isActive }: { isActive: boolean }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <mask
        id="mask0_2283_2213"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2283_2213)">
        <path
          d="M12 22.5C11.7833 22.5 11.575 22.4667 11.375 22.4C11.175 22.3333 10.9833 22.2333 10.8 22.1L4.8 17.6C4.55 17.4167 4.35417 17.1833 4.2125 16.9C4.07083 16.6167 4 16.3167 4 16V4C4 3.45 4.19583 2.97917 4.5875 2.5875C4.97917 2.19583 5.45 2 6 2H18C18.55 2 19.0208 2.19583 19.4125 2.5875C19.8042 2.97917 20 3.45 20 4V16C20 16.3167 19.9292 16.6167 19.7875 16.9C19.6458 17.1833 19.45 17.4167 19.2 17.6L13.2 22.1C13.0167 22.2333 12.825 22.3333 12.625 22.4C12.425 22.4667 12.2167 22.5 12 22.5ZM12 20.5L18 16V4H6V16L12 20.5ZM10.95 12.15L9.55 10.75C9.35 10.55 9.11667 10.4542 8.85 10.4625C8.58333 10.4708 8.35 10.5667 8.15 10.75C7.95 10.95 7.84583 11.1875 7.8375 11.4625C7.82917 11.7375 7.925 11.975 8.125 12.175L10.25 14.3C10.45 14.5 10.6833 14.6 10.95 14.6C11.2167 14.6 11.45 14.5 11.65 14.3L15.9 10.05C16.1 9.85 16.1958 9.61667 16.1875 9.35C16.1792 9.08333 16.0833 8.85 15.9 8.65C15.7 8.45 15.4625 8.34583 15.1875 8.3375C14.9125 8.32917 14.675 8.425 14.475 8.625L10.95 12.15Z"
          fill={isActive ? "#2970FF" : "#667085"}
        />
      </g>
    </svg>
  );
}

import { ChevronLeftIcon, ChevronRightIcon } from "lucide-react";
import { FC, useState } from "react";

interface PaginationProps {
  peopleNumber: number;
}

const Pagination: FC<PaginationProps> = ({ peopleNumber }) => {
  return <></>;

  const [pageIndex, setPageIndex] = useState(0);
  const pageCount = Math.ceil(peopleNumber / 10);
  return (
    <nav
      className="-space-x-px isolate inline-flex rounded-md shadow-sm"
      aria-label="Pagination"
    >
      <button
        className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:bg-gray-200"
        onClick={() => setPageIndex((prev) => prev - 1)}
        disabled={pageIndex === 0}
      >
        <span className="sr-only">Previous</span>
        <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
      </button>

      <button
        onClick={() => setPageIndex(0)}
        disabled={pageIndex === 0}
        className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:bg-gray-200"
      >
        1
      </button>
      {pageIndex > 2 && (
        <span className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:bg-gray-200">
          ...
        </span>
      )}
      {pageIndex === pageCount - 1 && pageCount > 2 && (
        <button
          onClick={() => setPageIndex(pageIndex - 2)}
          className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:bg-gray-200"
        >
          {pageIndex - 1}
        </button>
      )}
      {pageIndex > 1 && (
        <button
          onClick={() => setPageIndex(pageIndex - 1)}
          className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:bg-gray-200"
        >
          {pageIndex}
        </button>
      )}
      {pageIndex !== 0 && pageIndex !== pageCount - 1 && (
        <button
          onClick={() => setPageIndex(pageIndex)}
          disabled
          className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:bg-gray-200"
        >
          {pageIndex + 1}
        </button>
      )}
      {pageIndex < pageCount - 2 && (
        <button
          onClick={() => setPageIndex(pageIndex + 1)}
          className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:bg-gray-200"
        >
          {pageIndex + 2}
        </button>
      )}
      {pageIndex === 0 && pageCount > 3 && (
        <button
          onClick={() => setPageIndex(pageIndex + 2)}
          className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:bg-gray-200"
        >
          {pageIndex + 3}
        </button>
      )}
      {pageIndex < pageCount - 3 && (
        <span className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:bg-gray-200">
          ...
        </span>
      )}

      <button
        onClick={() => setPageIndex(pageCount - 1)}
        disabled={pageIndex === pageCount - 1}
        className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:bg-gray-200"
      >
        {pageCount}
      </button>
      <button
        className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:bg-gray-200"
        onClick={() => setPageIndex((prev) => prev + 1)}
        disabled={pageIndex === pageCount - 1}
      >
        <span className="sr-only">Sonraki</span>
        <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
      </button>
    </nav>
  );
};

export default Pagination;

import { selectedUserAtom } from "components/atoms/dashboardAtoms";
import { useAtomValue } from "jotai";
import EmployeeProfileCard from "./EmployeeProfileCard";
import { SkillsTable, parsePuans } from "./EmployeeProfileSkillsPanel";

export default function EmployeeProfileSkillsTech() {
  const selectedUser = useAtomValue(selectedUserAtom);

  return (
    <EmployeeProfileCard
      titleEmoji="💻"
      title="Teknoloji Becerileri"
      bgClassName="bg-gray-100"
      isCollapsible
      score={selectedUser.puanParent_teknoloji̇Beceri̇leri̇.yuzde}
    >
      <SkillsTable rows={getRows(selectedUser, "puanTeknoloji_")} />
    </EmployeeProfileCard>
  );
}

export function objectKeyToScreenName(key: string) {
  return key
    .split("_")[1]
    .split(/(?=[A-Z])/)
    .map((n) => n.charAt(0).toUpperCase() + n.slice(1))
    .join(" ");
}

export function getRows(selectedUser: any, keyPrefix: string) {
  const rows: (string | number)[][] = [];
  Object.keys(selectedUser).forEach((key) => {
    if (key.startsWith(keyPrefix)) {
      rows.push([objectKeyToScreenName(key), ...parsePuans(selectedUser[key])]);
    }
  });
  return rows;
}

export function getObjectKeyFromDisplayText(
  displayText: string,
  keyPrefix?: string,
) {
  switch (displayText) {
    case "Başarı/Çaba":
      return "puanCalismatarzi_basari/caba";
    case "Uyum Sağlama/Esneklik":
      return "puanCalismatarzi_uyumSaglama/esneklik";
    case "Kol-El Sabitliği":
      return "puanKabiliyetler_kol-elSabitligi";
    case "Bilek-Parmak Hızı":
      return "puanKabiliyetler_bilek-parmakHizi";
    case "İnsan Kaynaklarının Yönetimi":
      return "puanBeceriler_i̇nsanKaynaklarininYönetimi";
    case "İnisiyatif Alma":
      return "puanCalismatarzi_i̇nisiyatifAlma";
    case "İşbirliği":
      return "puanCalismatarzi_i̇sbirligi";
    case "İnovasyon":
      return "puanCalismatarzi_i̇novasyon";
    case "İdari İşler Bilgisi":
      return "puanBilgi_i̇dariİslerBilgisi";
    case "İnsan Kaynakları Yönetimi Bilgisi":
      return "puanBilgi_i̇nsanKaynaklariYönetimiBilgisi";
    case "İletişim ve Medya Bilgisi":
      return "puanBilgi_i̇letisimVeMedyaBilgisi";
    case "İşitsel Dikkat":
      return "puanKabiliyetler_i̇sitselDikkat";
    case "İşitme Hassasiyeti":
      return "puanKabiliyetler_i̇sitmeHassasiyeti";
    case "İzleme":
      return "puanBeceriler_i̇zleme";
    default:
      if (keyPrefix) {
        return getKeyWithPrefix(displayText, keyPrefix);
      }

      return [
        "puanCalismatarzi_",
        "puanKabiliyetler_",
        "puanYetenekler_",
        "puanBeceriler_",
        "puanBilgi_",
      ].map((prefix) => getKeyWithPrefix(displayText, prefix));
  }
}

function getKeyWithPrefix(displayText: string, keyPrefix: string) {
  return (
    keyPrefix +
    replaceTurkishChars(
      (
        displayText.charAt(0).toLocaleLowerCase() + displayText.slice(1)
      ).replace(" ve ", " Ve "),
    )
      .split(" ")
      .join("")
  );
}

export function getRowsForThisKeys(
  selectedUser: any,
  keyPrefix: string,
  keys: string[],
) {
  return keys.map((key) => {
    const newKey = getObjectKeyFromDisplayText(key, keyPrefix);

    if (typeof newKey !== "string") {
      throw new Error("error at getRowsForThisKeys");
    }

    if (selectedUser[newKey] === undefined) {
      throw new Error(`Key ${newKey} not found in selectedUser`);
    }

    return [key, ...parsePuans(selectedUser[newKey])];
  });
}

function replaceTurkishChars(str: string) {
  const turkishCharMap: { [key: string]: string } = {
    ç: "c",
    Ç: "C",
    ğ: "g",
    Ğ: "G",
    ı: "i",
    I: "I",
    ş: "s",
    Ş: "S",
    ü: "u",
    Ü: "U",
  };

  return str.replace(/[çÇğĞıİöÖşŞüÜ]/g, (char) => turkishCharMap[char] || char);
}

import { useTranslation } from "react-i18next";
import { Droppable } from "@hello-pangea/dnd";
import DraggableListItem from "./DraggableListItem";
import NumberIcon from "../../images/questionTypeIcons-22/number.svg";
import StarRatingIcon from "../../images/questionTypeIcons-22/star-rating.svg";
import ScaleRatingIcon from "../../images/questionTypeIcons-22/scale-rating.svg";
import OpenableListIcon from "../../images/questionTypeIcons-22/acilir-liste.svg";
import SingleChoiceIcon from "../../images/questionTypeIcons-22/single-choice.svg";
import MultipeChoiceIcon from "../../images/questionTypeIcons-22/multiple-choice.svg";
import HourIcon from "../../images/questionTypeIcons-22/time-picker.svg";
import DateIcon from "../../images/questionTypeIcons-22/date-picker.svg";
import EmailIcon from "../../images/questionTypeIcons-22/email.svg";
import TelIcon from "../../images/questionTypeIcons-22/phone.svg";
import SignatureIcon from "../../images/questionTypeIcons-22/signature.svg";
import DocumentIcon from "../../images/questionTypeIcons-22/file-upload.svg";
import DataTableIcon from "../../images/questionTypeIcons-22/input-table.svg";
import TitleIcon from "../../images/questionTypeIcons-22/heading.svg";
import TextAreaIcon from "../../images/questionTypeIcons-22/short-text.svg";
import LongTextAreaIcon from "../../images/questionTypeIcons-22/long-text.svg";
import AddressIcon from "../../images/questionTypeIcons-22/address.svg";
import RichTextIcon from "../../images/questionTypeIcons-22/zengin-metin.svg";
import LikertIcon from "../../images/questionTypeIcons-22/likert.svg";
import ImagePickerIcon from "../../images/questionTypeIcons-22/picture-choice.svg";
import npsIcon from "../../images/questionTypeIcons-22/nps.svg";
import cesIcon from "../../images/questionTypeIcons-22/ces.svg";
import csatIcon from "../../images/questionTypeIcons-22/csat.svg";
import FullNameIcon from "../../images/questionTypeIcons-22/full-name.svg";

const sidebarItemsConfig = Object.freeze([
  {
    group: "text",
    items: [
      { type: "title", icon: TitleIcon, index: 3 },
      { type: "textArea", icon: TextAreaIcon, index: 4 },
      { type: "longTextArea", icon: LongTextAreaIcon, index: 5 },
      { type: "richText", icon: RichTextIcon, index: 1 },
      { type: "number", icon: NumberIcon, index: 17 },
    ],
  },
  {
    group: "rating",
    items: [
      { type: "likert", icon: LikertIcon, index: 6 },
      { type: "starRating", icon: StarRatingIcon, index: 8 },
      { type: "ranking", icon: ScaleRatingIcon, index: 7 },
    ],
  },
  {
    group: "multipleChoice",
    items: [
      { type: "openableList", icon: OpenableListIcon, index: 9 },
      { type: "singleChoice", icon: SingleChoiceIcon, index: 10 },
      { type: "multiChoice", icon: MultipeChoiceIcon, index: 11 },
      { type: "imagePicker", icon: ImagePickerIcon, index: 12 },
    ],
  },
  {
    group: "table",
    items: [{ type: "dataTable", icon: DataTableIcon, index: 12 }],
  },
  {
    group: "XM Ölçekleri",
    items: [
      { type: "NPS", icon: npsIcon, index: 24 },
      { type: "CES", icon: cesIcon, index: 23 },
      { type: "CSAT", icon: csatIcon, index: 25 },
    ],
  },
  {
    group: "Iletisim Bilgileri",
    items: [
      { type: "email", icon: EmailIcon, index: 14 },
      { type: "tel", icon: TelIcon, index: 16 },
      { type: "address", icon: AddressIcon, index: 15 },
      { type: "fullName", icon: FullNameIcon, index: 13 },
    ],
  },
  {
    group: "Zaman",
    items: [
      { type: "date", icon: DateIcon, index: 21 },
      { type: "hour", icon: HourIcon, index: 19 },
    ],
  },
  {
    group: "Diger",
    items: [
      { type: "document", icon: DocumentIcon, index: 18 },
      { type: "signature", icon: SignatureIcon, index: 20 },
    ],
  },
]);

const FormElements = () => {
  const { t } = useTranslation();

  return (
    <Droppable droppableId="sidebarItems" isDropDisabled={true}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.droppableProps}>
          {sidebarItemsConfig.map((group) => (
            <div key={group.group}>
              <h6 className="mt-5 font-semibold text-slate-600">
                {t(group.group)}
              </h6>
              <ul className="my-0 p-0">
                {group.items.map((item) => (
                  <DraggableListItem
                    key={item.type}
                    draggableId={item.type}
                    index={item.index}
                    type={item.type}
                    icon={item.icon}
                  />
                ))}
              </ul>
            </div>
          ))}
        </div>
      )}
    </Droppable>
  );
};

export default FormElements;

import FormSettingsDataType from "../../components/FormSettingsDataType";
import FormSettingsAddTableColumns from "../../components/FormSettingsAddTableColumns";
import FormSettingsAddTableRow from "../../components/FormSettingsAddTableRow";
import FormSettingsSelectOptions from "../../components/FormSettingsSelectOptions";

export default function DataTableAreaSettings() {
  return (
    <>
      <FormSettingsDataType />
      <FormSettingsAddTableColumns />
      <FormSettingsAddTableRow />
      <FormSettingsSelectOptions />
    </>
  );
}

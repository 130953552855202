import { useState } from "react";
import { PencilIcon } from "@heroicons/react/24/outline";
import { dashboardModalValueType } from "./DashboardCards";
import TextInput from "../../components/tailwindUi/TextInput";
import PrimaryButton from "../../components/tailwindUi/PrimaryButton";
import { surveyInterface } from "../../Interfaces/SurveyInterfaces";

interface NewFunctionProps {
  selectedSurvey: surveyInterface;
  setModalValue: React.Dispatch<React.SetStateAction<dashboardModalValueType>>;
  updateSurveyMutation: any;
}

export default function RenameFormDialog({
  selectedSurvey,
  setModalValue,
  updateSurveyMutation,
}: NewFunctionProps) {
  const [renameFormName, setRenameFormName] = useState(
    selectedSurvey!.title?.["tr"] ? selectedSurvey!.title!["tr"] : ""
  );

  const isError = renameFormName === "";

  const errorMessage = isError ? "Form Name is required" : "";

  return (
    <>
      <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
        <PencilIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
      </div>
      <div className="mt-1 mb-4 text-center sm:mt-3">
        <h3 className="text-base font-semibold leading-6 text-gray-900">
          Rename Form
        </h3>
      </div>
      <TextInput
        label="Form Name"
        name="renameFormName"
        id="renameFormName"
        defaultValue={renameFormName}
        errorMessage={errorMessage}
        onChange={(event) => setRenameFormName(event.target.value)}
      />
      <div className="mt-1 sm:mt-2 flex gap-x-3">
        <PrimaryButton onClick={() => setModalValue("")} isSecondary isGray>
          Cancel
        </PrimaryButton>
        <PrimaryButton
          onClick={() => {
            updateSurveyMutation.mutate({
              ...selectedSurvey,
              title: { tr: renameFormName },
            });
            setModalValue("");
          }}
          disabled={isError}
        >
          Confirm
        </PrimaryButton>
      </div>
    </>
  );
}

import {
  NewAreaResponseResult,
  surveyReplayQuestionAnswers,
  surveyReply,
} from "../../../../Interfaces/SurveyInterfaces";

//TODO DataTable sorulari birlesmeliymis
const newArea = (data: surveyReplayQuestionAnswers): NewAreaResponseResult => {
  const result: NewAreaResponseResult = {};
  let questionNumber = 1;

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const item = data[key];
      let keyToAdd: string;
      if (item.questionInfos && item.questionInfos.questionTitle) {
        keyToAdd = item.questionInfos.questionTitle;
        questionNumber++;
      } else {
        keyToAdd = `Soru ${questionNumber}`;
        questionNumber++;
      }

      result[keyToAdd] = item.value || [];
    }
  }
  return result;
};

export function makeData(data: surveyReply[]) {
  const makeDataLevel = (): NewAreaResponseResult[] => {
    const expandedData: surveyReply[] = [];
    data.forEach((d) => {
      for (let i = 0; i < 50; i++) {
        expandedData.push(d);
      }
    });
    return expandedData.map((d): NewAreaResponseResult => {
      return {
        ...newArea(d.questionAnswers || {}),
      };
    });
  };

  /*   const makeDataLevel = (): NewAreaResponseResult[] => {
    return data.map((d): NewAreaResponseResult => {
      return {
        ...newArea(d.questionAnswers || {}),
      };
    });
  }; */

  return makeDataLevel();
}

import { useAtom, useAtomValue } from "jotai";
import { useTranslation } from "react-i18next";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../../atoms/surveyAtoms";

const FormSettingsPlaceholderforAddress = () => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const { t } = useTranslation();

  return (
    <>
      <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
        <label
          className="block text-sm font-bold text-gray-500"
          htmlFor="enterTitle"
        >
          {t("Yer Tutucu")}
        </label>
        {stateSurveyJSON.pages![pageIndex].elements![elementIndex]
          .showNickname && (
          <div className={`relative mt-3`}>
            <label
              htmlFor={"adressArea1"}
              className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-500"
            >
              Adres Satırı 1
            </label>
            <input
              type="text"
              id={"adressArea1"}
              autoComplete="off"
              className="py-1.5 block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              onChange={(e) => {
                setStateSurveyJSON((prevState) => {
                  const newState = { ...prevState };
                  newState.pages![pageIndex].elements![
                    elementIndex
                  ].inputAreaPlaceholderNickname = {
                    tr: e.target.value,
                  };
                  return newState;
                });
              }}
              value={
                stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
                  ?.inputAreaPlaceholderNickname?.tr || ""
              }
            />
          </div>
        )}
        {stateSurveyJSON.pages![pageIndex].elements![elementIndex]
          .showExtra3 && (
          <div className={`relative mt-3`}>
            <label
              htmlFor={"adressArea2"}
              className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-500"
            >
              Adres Satırı 2
            </label>
            <input
              type="text"
              id={"adressArea2"}
              autoComplete="off"
              className="py-1.5 block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              onChange={(e) => {
                setStateSurveyJSON((prevState) => {
                  const newState = { ...prevState };
                  newState.pages![pageIndex].elements![
                    elementIndex
                  ].inputAreaPlaceholder = {
                    tr: e.target.value,
                  };
                  return newState;
                });
              }}
              value={
                stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
                  ?.inputAreaPlaceholder?.tr || ""
              }
            />
          </div>
        )}
        {stateSurveyJSON.pages![pageIndex].elements![elementIndex]
          .showSecondaryName && (
          <div className={`relative mt-3`}>
            <label
              htmlFor={"IlceArea"}
              className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-500"
            >
              İlçe
            </label>
            <input
              type="text"
              id={"adressArea"}
              autoComplete="off"
              className="py-1.5 block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              onChange={(e) => {
                setStateSurveyJSON((prevState) => {
                  const newState = { ...prevState };
                  newState.pages![pageIndex].elements![
                    elementIndex
                  ].inputAreaPlaceholderSecondaryName = {
                    tr: e.target.value,
                  };
                  return newState;
                });
              }}
              value={
                stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
                  ?.inputAreaPlaceholderSecondaryName?.tr || ""
              }
            />
          </div>
        )}
        {stateSurveyJSON.pages![pageIndex].elements![elementIndex]
          .showExtra2 && (
          <div className={`relative mt-3`}>
            <label
              htmlFor={"IlArea"}
              className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-500"
            >
              İl/Eyalet
            </label>
            <input
              type="text"
              id={"IlArea"}
              autoComplete="off"
              className="py-1.5 block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              onChange={(e) => {
                setStateSurveyJSON((prevState) => {
                  const newState = { ...prevState };
                  newState.pages![pageIndex].elements![
                    elementIndex
                  ].inputAreaPlaceholderSurname = {
                    tr: e.target.value,
                  };
                  return newState;
                });
              }}
              value={
                stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
                  ?.inputAreaPlaceholderSurname?.tr || ""
              }
            />
          </div>
        )}
        {stateSurveyJSON.pages![pageIndex].elements![elementIndex]
          .showLastName && (
          <div className={`relative mt-3`}>
            <label
              htmlFor={"PostalCodeArea"}
              className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-500"
            >
              Posta Kodu
            </label>
            <input
              type="text"
              id={"PostalCodeArea"}
              autoComplete="off"
              className="py-1.5 block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              onChange={(e) => {
                setStateSurveyJSON((prevState) => {
                  const newState = { ...prevState };
                  newState.pages![pageIndex].elements![
                    elementIndex
                  ].inputAreaPlaceholderLastname = {
                    tr: e.target.value,
                  };
                  return newState;
                });
              }}
              value={
                stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
                  ?.inputAreaPlaceholderLastname?.tr || ""
              }
            />
          </div>
        )}
        {stateSurveyJSON.pages![pageIndex].elements![elementIndex]
          .showExtra1 && (
          <div className={`relative mt-3`}>
            <label
              htmlFor={"CountryArea"}
              className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-500"
            >
              Ülke
            </label>
            <input
              type="text"
              id={"CountryArea"}
              autoComplete="off"
              className="py-1.5 block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              onChange={(e) => {
                setStateSurveyJSON((prevState) => {
                  const newState = { ...prevState };
                  newState.pages![pageIndex].elements![
                    elementIndex
                  ].inputAreaDefaultValue = {
                    tr: e.target.value,
                  };
                  return newState;
                });
              }}
              value={
                stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
                  ?.inputAreaDefaultValue?.tr || ""
              }
            />
          </div>
        )}
        <p className="mb-0 mt-3 text-sm text-stone-500">
          {t("Alanın içine bir örnek ekleyin")}
        </p>
      </div>
    </>
  );
};

export default FormSettingsPlaceholderforAddress;

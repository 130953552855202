import { useState } from "react";
import { SettingsCard } from "../../pages/dashboard2/SettingsProfile";
import Select, { SelectItemType } from "../tailwindUi/Select";
import CardWithPlusMinusButtons from "./CardWithPlusMinusButtons";
import { DoAction, LogicItem } from "./Logic";
import {
  surveyElementsInterface,
  surveyInterface,
} from "../../Interfaces/SurveyInterfaces";
import getIconForQuestionType from "./getIconForQuestionType";
import { getPossibleValuesBasedOnQuestionType } from "./AddCondition";
import TextInput from "../tailwindUi/TextInput";
import { useAtomValue } from "jotai";
import { surveyAtom } from "../atoms/surveyAtoms";

export default function DoCard({
  doItem,
  updateDoItem,
  addNewDoItem,
  deleteDoItem,
  doSelectItems,
  isFirst,
  isLast,
}: {
  doItem: LogicItem["dos"][0];
  updateDoItem: (newDoItem: LogicItem["dos"][0]) => void;
  addNewDoItem: () => void;
  deleteDoItem: () => void;
  doSelectItems: SelectItemType[];
  isFirst?: boolean;
  isLast?: boolean;
}) {
  const stateSurveyJSON = useAtomValue(surveyAtom);

  const [localDoSelectItems, setLocalDoSelectItems] =
    useState<SelectItemType[]>(doSelectItems);

  return (
    <CardWithPlusMinusButtons
      plusButtonOnClick={() => {
        if (isDoItemValid(doItem)) {
          addNewDoItem();
        } else {
          updateDoItem({
            ...doItem,
            isError: true,
          });
        }
      }}
      minusButtonOnClick={deleteDoItem}
      isPlusVisible={isFirst}
      isMinusVisible={!(isFirst && isLast)}
    >
      <SettingsCard isSubSetting isError={doItem.isError}>
        <div className="grid gap-y-5">
          {/* ACTION */}
          <Select
            label="DO"
            placeholder="Select Action"
            id="do"
            items={localDoSelectItems.map((item) => ({
              ...item,
              selected: item.value === doItem.action,
            }))}
            onChange={(selected) => {
              selected = selected as SelectItemType;

              updateDoItem({
                ...doItem,
                action: selected.value as DoAction,
                target: "",
                targetId: "",
                isTargetSelectable:
                  selected.value !== "GO_TO_THANK_YOU_PAGE" &&
                  selected.value !== "SHOW_CUSTOM_MESSAGE",
                isMultipleTargetsSelectable: getIsMultipleTargetsSelectable(
                  selected.value as DoAction,
                ),
                isOptionSelectable:
                  selected.value === "SHOW_OPTION" ||
                  selected.value === "HIDE_OPTION",
                isCustomMessage: selected.value === "SHOW_CUSTOM_MESSAGE",
                isMaskAction: selected.value === "SET_MASK",
                isError: false,
              });
              setLocalDoSelectItems((prev) =>
                prev.map((item) => ({
                  ...item,
                  selected: item.value === (selected as SelectItemType).value,
                })),
              );
            }}
          />

          {/* TARGET */}
          {doItem.isTargetSelectable && (
            <Select
              label="FIELD"
              placeholder="Select Field"
              id="field"
              items={getTargetsForAction(doItem.action, stateSurveyJSON).map(
                (item) => {
                  return {
                    ...item,
                    selected: doItem.isMultipleTargetsSelectable
                      ? doItem.targetId.includes(item.targetId)
                      : doItem.targetId === item.targetId,
                  };
                },
              )}
              isMultiSelect={doItem.isMultipleTargetsSelectable}
              onChange={(selected) => {
                if (doItem.isMultipleTargetsSelectable) {
                  selected = selected as SelectItemType[];
                  updateDoItem({
                    ...doItem,
                    target: selected.map((item) => item.value),
                    targetId: selected.map((item) => item.targetId),
                    isError: false,
                  });
                } else {
                  selected = selected as SelectItemType;
                  updateDoItem({
                    ...doItem,
                    target: selected.value,
                    targetId: selected.targetId,
                    isError: false,
                  });
                }
              }}
              disabled={doItem.action === ""}
            />
          )}

          {/* CUSTOM_MESSAGE */}
          {doItem.isCustomMessage && <>Custom message comp</>}

          {/* OPTION */}
          {doItem.isOptionSelectable && (
            <Select
              label="OPTION"
              placeholder="Select Option"
              id="option"
              items={getOptionsFromTarget(doItem.targetId, stateSurveyJSON).map(
                (item) => {
                  return {
                    ...item,
                    selected: doItem.optionId?.includes(item.optionId),
                  };
                },
              )}
              isMultiSelect={true}
              onChange={(selected) => {
                selected = selected as SelectItemType[];

                console.log("selected", selected);
                updateDoItem({
                  ...doItem,
                  option: selected.map((item) => item.value),
                  optionId: selected.map((item) => item.optionId),
                  isError: false,
                });
              }}
              disabled={doItem.target === ""}
            />
          )}

          {/* MASK */}
          {doItem.isMaskAction && (
            <TextInput
              label="MASK"
              placeholder="Mask"
              name="mask"
              id="mask"
              type="text"
              defaultValue={""}
              errorMessage={""}
              onChange={(event) =>
                updateDoItem({
                  ...doItem,
                  conditionMask: event.target.value,
                  isError: false,
                })
              }
              disabled={doItem.target === ""}
              isMultiline={false}
              hint="Enter the mask for the field. For example: ###-###-####"
            />
          )}
        </div>
      </SettingsCard>
    </CardWithPlusMinusButtons>
  );
}

function getTargetsForAction(
  action: DoAction | "",
  stateSurveyJSON: surveyInterface,
): SelectItemType[] {
  console.log("getTargetsForAction -> action", action);

  if (!action) return [{ text: "No action", value: "No action" }];

  const showHideActions: DoAction[] = [
    "SHOW",
    "HIDE",
    "SHOW_MULTIPLE",
    "HIDE_MULTIPLE",
    "SHOW_PAGE",
    "HIDE_PAGE",
    "SHOW_OPTION",
    "HIDE_OPTION",
    "GO_TO_QUESTION",
    "MAKE_REQUIRED",
    "MAKE_NOT_REQUIRED",
    "SET_MASK",
  ];
  if (showHideActions.includes(action)) {
    let questionCount = 0;
    const targets =
      stateSurveyJSON.pages?.flatMap((page, pageCount) => {
        const pageText = `Page ${pageCount + 1}`;

        return [
          {
            text: pageText,
            value: pageText,
            targetId: page._id,
            isSeperator: true,
            icon: getIconForQuestionType("page"),
            disabled: !(action === "SHOW_PAGE" || action === "HIDE_PAGE"),
          },
          ...(page.elements?.map((question) => {
            questionCount += 1;

            const questionText = `${questionCount}. ${question.title?.tr}`;

            console.log(
              "getTargetsForAction question",
              JSON.stringify(question, null, 2),
            );

            return {
              text: questionText,
              value: questionText,
              targetId: question._id,
              icon: getIconForQuestionType(question.type),
              disabled: getIsDisabled(action, question),
            };
          }) ?? []),
        ];
      }) ?? [];

    return targets;
  }

  return [
    {
      text: "getTargetsForAction unhandled action: " + action,
      value: "unhandled action",
    },
  ];
}

function getOptionsFromTarget(
  targetId: string | string[],
  stateSurveyJSON: surveyInterface,
): SelectItemType[] {
  for (const page of stateSurveyJSON.pages || []) {
    for (const question of page.elements || []) {
      if (question._id === targetId) {
        return (
          getPossibleValuesBasedOnQuestionType(question, true)?.map((option) =>
            option
              ? typeof option === "string"
                ? { text: "Should be with id", value: "Should be with id" }
                : {
                    text: option.text ?? "No text",
                    value: option.text ?? "No text",
                    optionId: option.value,
                  }
              : { text: "Option undefined", value: "Option undefined" },
          ) ?? [{ text: "No possible option", value: "No possible option" }]
        );
      }
    }
  }

  return [
    {
      text: "getOptionsFromTarget unhandled targetId: " + targetId,
      value: "unhandled targetId",
    },
  ];
}

function getIsMultipleTargetsSelectable(action: DoAction): boolean {
  return action === "SHOW_MULTIPLE" || action === "HIDE_MULTIPLE";
}

function getIsDisabled(
  action: DoAction,
  question: surveyElementsInterface,
): boolean {
  console.log("getIsDisabled -> question", JSON.stringify(question, null, 2));

  if (action === "SHOW_PAGE" || action === "HIDE_PAGE") {
    return true;
  }

  if (action === "SET_MASK") {
    return question.type !== "textArea";
  }

  if (action === "SHOW_OPTION" || action === "HIDE_OPTION") {
    return !(
      question.type === "likert" ||
      question.type === "openableList" ||
      question.type === "singleChoice" ||
      question.type === "multiChoice" ||
      question.type === "imagePicker"
    );
  }

  return false;
}

export function isDoItemValid(doItem: LogicItem["dos"][0]) {
  console.log("isDoItemValid -> doItem", JSON.stringify(doItem, null, 2));

  return Boolean(
    doItem.action &&
      (!doItem.isTargetSelectable ||
        (doItem.isMultipleTargetsSelectable
          ? doItem.target.length > 0
          : doItem.target)) &&
      (!doItem.isOptionSelectable || (doItem.option?.length ?? 0) > 0) &&
      (!doItem.isMaskAction || doItem.conditionMask),
  );
}

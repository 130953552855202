import { Dispatch, useState, SetStateAction } from "react";
import NewPlanCreator from "./NewPlanCreator";

const ListItemPlans = ({ plan }: { plan: any }) => {
  return (
    <div className="flex bg-white border-b">
      <div className="flex items-center w-[270px] h-[72px] justify-start ml-2">
        <div className="p-2">
          <h6 className="m-0 text-base text-start">
            {plan.title ? plan.title : "Plan"}
          </h6>
        </div>
      </div>
      <div className=" w-[180px] text-sm text-start my-auto">
        {plan.planTime === 1
          ? "Haftada " + plan.days.split(",").length + " gün"
          : plan.planTime === 2
            ? "Ayda " + plan.days.split(",").length + " gün"
            : "Yılda " + plan.days.split(",").length + " gün"}
        <br />
        <span className="font-semibold">2 gün sonra</span>
      </div>
      <div className=" w-[180px] text-sm text-start my-auto">
        07.10.2023D
        <br />
        09:30D
      </div>
      <div className=" w-[90px] text-sm flex justify-center items-center">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="mx-2 cursor-pointer"
        >
          <path
            d="M9.99998 16.6667H17.5M2.5 16.6667H3.89545C4.3031 16.6667 4.50693 16.6667 4.69874 16.6206C4.8688 16.5798 5.03138 16.5125 5.1805 16.4211C5.34869 16.318 5.49282 16.1739 5.78107 15.8856L16.25 5.41669C16.9404 4.72634 16.9404 3.60705 16.25 2.91669C15.5597 2.22634 14.4404 2.22634 13.75 2.91669L3.28105 13.3856C2.9928 13.6739 2.84867 13.818 2.7456 13.9862C2.65422 14.1353 2.58688 14.2979 2.54605 14.468C2.5 14.6598 2.5 14.8636 2.5 15.2713V16.6667Z"
            stroke="#475467"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="mx-2 cursor-pointer"
        >
          <path
            d="M13.3333 4.99984V4.33317C13.3333 3.39975 13.3333 2.93304 13.1517 2.57652C12.9919 2.26292 12.7369 2.00795 12.4233 1.84816C12.0668 1.6665 11.6001 1.6665 10.6667 1.6665H9.33333C8.39991 1.6665 7.9332 1.6665 7.57668 1.84816C7.26308 2.00795 7.00811 2.26292 6.84832 2.57652C6.66667 2.93304 6.66667 3.39975 6.66667 4.33317V4.99984M8.33333 9.58317V13.7498M11.6667 9.58317V13.7498M2.5 4.99984H17.5M15.8333 4.99984V14.3332C15.8333 15.7333 15.8333 16.4334 15.5608 16.9681C15.3212 17.4386 14.9387 17.821 14.4683 18.0607C13.9335 18.3332 13.2335 18.3332 11.8333 18.3332H8.16667C6.76654 18.3332 6.06647 18.3332 5.53169 18.0607C5.06129 17.821 4.67883 17.4386 4.43915 16.9681C4.16667 16.4334 4.16667 15.7333 4.16667 14.3332V4.99984"
            stroke="#475467"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </div>
  );
};

type Props = {
  setMailState: Dispatch<SetStateAction<number>>;
  setFormName: Dispatch<SetStateAction<string>>;
  setPageState: Dispatch<SetStateAction<number>>;
  pageState: number;
};

const ReminderMails: React.FC<Props> = ({
  setMailState,
  setFormName,
  setPageState,
  pageState,
}) => {
  const [plans, setPlans] = useState<any[]>([]);
  const ListItemReminder = () => {
    return (
      <>
        <div className="flex bg-white border-b ">
          <div className="flex items-center w-[450px] justify-start ml-2">
            <div className="p-2">
              <h6 className="m-0 text-base text-start">Anlık</h6>
              <p className="m-0 text-base text-start">Hatırlatma e-postası</p>
            </div>
          </div>
          <div className=" w-[180px] text-sm flex items-center justify-start">
            <div className=" w-[180px] text-sm text-start my-auto">
              07.10.2023
              <br />
              09:30
            </div>
          </div>
          <div className=" w-[90px] text-sm flex justify-center items-center">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mx-2 cursor-pointer"
              onClick={() => {
                setMailState(4);
                setFormName("Anlık Hatırlatma e-postası");
              }}
            >
              <path
                d="M9.99998 16.6667H17.5M2.5 16.6667H3.89545C4.3031 16.6667 4.50693 16.6667 4.69874 16.6206C4.8688 16.5798 5.03138 16.5125 5.1805 16.4211C5.34869 16.318 5.49282 16.1739 5.78107 15.8856L16.25 5.41669C16.9404 4.72634 16.9404 3.60705 16.25 2.91669C15.5597 2.22634 14.4404 2.22634 13.75 2.91669L3.28105 13.3856C2.9928 13.6739 2.84867 13.818 2.7456 13.9862C2.65422 14.1353 2.58688 14.2979 2.54605 14.468C2.5 14.6598 2.5 14.8636 2.5 15.2713V16.6667Z"
                stroke="#475467"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
        <div className="flex bg-white border-b">
          <div className="flex items-center w-[450px] justify-start ml-2">
            <div className="p-2">
              <h6 className="m-0 text-base text-start">Başlamayanlar</h6>
              <p className="m-0 text-base text-start">Hatırlatma e-postası</p>
            </div>
          </div>
          <div className=" w-[180px] text-sm flex items-center justify-start">
            <div className=" w-[180px] text-sm text-start my-auto">
              07.10.2023
              <br />
              09:30
            </div>
          </div>
          <div className=" w-[90px] text-sm flex justify-center items-center">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mx-2 cursor-pointer"
              onClick={() => {
                setMailState(4);
                setFormName("Başlamayanlar Hatırlatma e-postası");
              }}
            >
              <path
                d="M9.99998 16.6667H17.5M2.5 16.6667H3.89545C4.3031 16.6667 4.50693 16.6667 4.69874 16.6206C4.8688 16.5798 5.03138 16.5125 5.1805 16.4211C5.34869 16.318 5.49282 16.1739 5.78107 15.8856L16.25 5.41669C16.9404 4.72634 16.9404 3.60705 16.25 2.91669C15.5597 2.22634 14.4404 2.22634 13.75 2.91669L3.28105 13.3856C2.9928 13.6739 2.84867 13.818 2.7456 13.9862C2.65422 14.1353 2.58688 14.2979 2.54605 14.468C2.5 14.6598 2.5 14.8636 2.5 15.2713V16.6667Z"
                stroke="#475467"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
        <div className="flex bg-white border-b rounded-b-xl">
          <div className="flex items-center w-[450px] justify-start ml-2">
            <div className="p-2">
              <h6 className="m-0 text-base text-start">Devam edenler</h6>
              <p className="m-0 text-base text-start">Hatırlatma e-postası</p>
            </div>
          </div>
          <div className=" w-[180px] text-sm flex items-center justify-start">
            <div className=" w-[180px] text-sm text-start my-auto">
              07.10.2023
              <br />
              09:30
            </div>
          </div>
          <div className=" w-[90px] text-sm flex justify-center items-center">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mx-2 cursor-pointer"
              onClick={() => {
                setMailState(4);
                setFormName("Devam Edenler Hatırlatma e-postası");
              }}
            >
              <path
                d="M9.99998 16.6667H17.5M2.5 16.6667H3.89545C4.3031 16.6667 4.50693 16.6667 4.69874 16.6206C4.8688 16.5798 5.03138 16.5125 5.1805 16.4211C5.34869 16.318 5.49282 16.1739 5.78107 15.8856L16.25 5.41669C16.9404 4.72634 16.9404 3.60705 16.25 2.91669C15.5597 2.22634 14.4404 2.22634 13.75 2.91669L3.28105 13.3856C2.9928 13.6739 2.84867 13.818 2.7456 13.9862C2.65422 14.1353 2.58688 14.2979 2.54605 14.468C2.5 14.6598 2.5 14.8636 2.5 15.2713V16.6667Z"
                stroke="#475467"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {pageState === 1 ? (
        <>
          <h5 className="w-[720px] mx-auto text-start">
            Hatırlatma E-postaları
          </h5>
          <div className="w-[720px] border rounded-xl bg-gray-100 mx-auto">
            <div className="flex p-2">
              <div className="flex items-center w-[450px] justify-start ml-2">
                <p className="m-0 text-sm">Hatırlatma E-postaları</p>
              </div>
              <div className=" w-[180px] text-sm text-start">
                Son Gönderim Tarihi
              </div>
              <div className=" w-[90px] text-sm"></div>
            </div>
            <ListItemReminder />
          </div>
          <br />
          <h5 className="w-[720px] mx-auto text-start">Planlar</h5>

          <div className="w-[720px] border rounded-xl bg-gray-100 mx-auto">
            <div className="flex p-2">
              <div className="flex items-center w-[270px] justify-start ml-2">
                <p className="m-0 text-sm">Planlar</p>
              </div>
              <div className=" w-[180px] text-sm text-start">
                Gönderim Sıklığı
              </div>
              <div className=" w-[180px] text-sm text-start">
                Son Gönderim Tarihi
              </div>
              <div className=" w-[90px] text-sm"></div>
            </div>
            {plans.map((plan) => (
              <ListItemPlans plan={plan} />
            ))}
            <div className="bg-white rounded-b-xl  text-start p-3">
              <button
                className="border-2 border-blue-400 text-sm rounded-md text-center align-middle hover:bg-blue-300 hover:text-white px-3 py-2 h-9 hover:!bg-transparent hover:!text-[#0d6ffd]"
                onClick={() => setPageState(2)}
              >
                <span className="flex justify-center items-center">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.0013 4.1665V15.8332M4.16797 9.99984H15.8346"
                      stroke="#2970FF"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Yeni Plan Oluştur
                </span>
              </button>
            </div>
          </div>
        </>
      ) : (
        <NewPlanCreator setPageState={setPageState} setPlans={setPlans} />
      )}
    </>
  );
};

export default ReminderMails;

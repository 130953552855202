import { selectedUserAtom } from "components/atoms/dashboardAtoms";
import { useAtomValue } from "jotai";
import { useState } from "react";
import PrimaryButton from "../../components/tailwindUi/PrimaryButton";
import TextInput from "../../components/tailwindUi/TextInput";
import { classNames } from "../../lib/externalJS";
import EmployeeProfileCard from "./EmployeeProfileCard";

const ABOUT_ABOUT = Object.freeze([
  {
    title: "İş dışında en sevdiğim hobim veya aktivitem...",
  },
  {
    title: "Eğer dünyada biriyle akşam yemeği yiyebilseydim, bu kişi...",
  },
  {
    title:
      "Eğer yarın yeni bir nitelik ya da yetenekle uyanacak olsaydım, bu...",
  },
  {
    title: '"Mükemmel günüm"…',
  },
]);

const ABOUT_IDEAL_JOB = Object.freeze([
  {
    title: "İlgilendiğim ve keyif aldığım iş türü....",
  },
  {
    title: "Bana enerji veren iş türü...",
  },
  {
    title: "Enerjimi düşüren iş türü...",
  },
  {
    title: "En iyi işimi yapabilmem için ideal ortam...",
  },
]);

const ABOUT_DEVELOPMENT = Object.freeze([
  {
    title: "Ana motivasyon kaynaklarım...",
  },
  {
    title: "Geliştirmek istediğim nadir ve değerli bir beceri...",
  },
  {
    title: "En önemli kariyer hedeflerim...",
  },
]);

const ABOUT_PREFERENCES = Object.freeze([
  {
    title: "Stresli olduğumu gösteren işaretler şunlardır...",
  },
  {
    title:
      "Eğer kötü bir gün geçiriyorsam, bana şu şekilde destek olabilirsiniz...",
  },
  {
    title: "Geri bildirim almak için tercih ettiğim yol...",
  },
  {
    title: "Tanınmak için tercih ettiğim yol...",
  },
]);

export default function EmployeeProfileAbout() {
  const selectedUser = useAtomValue(selectedUserAtom);

  const [isEditing1, setIsEditing1] = useState(false);
  const [isEditing2, setIsEditing2] = useState(false);
  const [isEditing3, setIsEditing3] = useState(false);
  const [isEditing4, setIsEditing4] = useState(false);
  const [isEditing5, setIsEditing5] = useState(false);

  const CARDS = Object.freeze([
    {
      titleEmoji: "👤",
      title: "Hakkımda",
      bgClassName: "bg-cyan-100",
      items: ABOUT_ABOUT.map((about, i) => ({
        ...about,
        desc: selectedUser.hakkimda[i],
      })),
    },
    {
      titleEmoji: "💼",
      title: "İdeal İşim",
      bgClassName: "bg-amber-100",
      items: ABOUT_IDEAL_JOB.map((about, i) => ({
        ...about,
        desc: selectedUser.idealIsim[i],
      })),
    },
    {
      titleEmoji: "🏋️",
      title: "Güçlü Yanlarım",
      bgClassName: "bg-emerald-100",
      items: selectedUser.gucluYanlarim.map((x: any) => ({
        title: x,
        desc: "",
      })),
    },
    {
      titleEmoji: "🚀",
      title: "Gelişimim",
      bgClassName: "bg-rose-100",
      items: ABOUT_DEVELOPMENT.map((about, i) => ({
        ...about,
        desc: selectedUser.gelisimim[i],
      })),
    },
    {
      titleEmoji: "🤔",
      title: "Tercihlerim",
      bgClassName: "bg-orange-100",
      items: ABOUT_PREFERENCES.map((about, i) => ({
        ...about,
        desc: selectedUser.tercihlerim[i],
      })),
    },
  ]);

  return (
    <div className="mt-10 grid grid-cols-2 gap-7">
      {CARDS.map((card, cardIndex) => {
        const isEditing = eval(`isEditing${cardIndex + 1}`);
        const setIsEditing = eval(`setIsEditing${cardIndex + 1}`);

        return (
          <EmployeeProfileCard
            key={card.title}
            {...card}
            isEditing={isEditing}
            onEditClick={() => setIsEditing(true)}
          >
            <ul
              className={classNames(
                "flex flex-col gap-y-7",
                card.items[0].desc === "" ? "list-disc pl-5" : "",
              )}
            >
              {card.items.map((about: any) => (
                <li key={about.title}>
                  <TitleDescComp
                    title={about.title}
                    desc={about.desc}
                    isEditing={isEditing}
                  />
                </li>
              ))}
            </ul>

            {isEditing && (
              <div className="-mx-4 -mb-5 mt-5 flex justify-end gap-3 border-t p-4 sm:-mx-5 sm:px-7">
                <PrimaryButton
                  isMinWidth
                  isSecondary
                  isGray
                  onClick={() => setIsEditing(false)}
                >
                  Vazgeç
                </PrimaryButton>
                <PrimaryButton isMinWidth onClick={() => setIsEditing(false)}>
                  Kaydet
                </PrimaryButton>
              </div>
            )}
          </EmployeeProfileCard>
        );
      })}
    </div>
  );
}

function TitleDescComp({
  title,
  desc,
  isEditing,
}: {
  title: string;
  desc: string;
  isEditing: boolean;
}) {
  const [localTitle, setLocalTitle] = useState(title);
  const [localDesc, setLocalDesc] = useState(desc);

  return (
    <div className="flex flex-col gap-y-1 text-sm leading-[21px] text-gray-800">
      <span className="font-semibold">
        {localTitle}
        <br />
      </span>
      {isEditing ? (
        <TextInput
          name="desc"
          id="desc"
          errorMessage=""
          value={desc === "" ? localTitle : localDesc}
          onChange={(e) =>
            desc === ""
              ? setLocalTitle(e.target.value)
              : setLocalDesc(e.target.value)
          }
          isMultiline
        />
      ) : (
        localDesc
      )}
    </div>
  );
}

import axiosInstance from "../api";

export const getSurveyReplyIds = async () => {
  return await axiosInstance.get<any[]>("SurveyReply/GetAll");
};

export const getSurveyReply = async (id: string) => {
  return (
    await axiosInstance.get<any>(`SurveyReply/GetById/`, {
      params: { surveyReplyId: id },
    })
  ).data;
};

export const getSurveyReplys = async (id: string) => {
  return (
    await axiosInstance.get<any>(`SurveyReply/GetBySurveyId/`, {
      params: { surveyId: id },
    })
  ).data;
};
export const getSurveyReplysWith3 = async (
  id: string,
  userID: string,
  participantID: string
) => {
  return (
    await axiosInstance.get<any>(`SurveyReply/GetBySurveyUserParticipantId/`, {
      params: { surveyId: id, userId: userID, participantId: participantID },
    })
  ).data;
};

export const createSurveyReply = async (data: any) => {
  await axiosInstance.post("/SurveyReply/Add", data);
};

export const updateSurveyReply = async (data: any) => {
  await axiosInstance.put(`/SurveyReply/Update`, data, {
    params: { surveyId: data._id },
  });
};

export const updateSurveyReplyByUserIDSurveyIDParticipantID = async (
  data: any
) => {
  await axiosInstance.put(`/SurveyReply/UpdateWithUserAndParticipantId`, data, {
    params: {
      surveyId: data._id,
      userId: data.userId,
      participantId: data.participantId,
    },
  });
};

export const deleteSurveyReply = async (id: string) => {
  await axiosInstance.delete(`SurveyReply/Delete/`, {
    params: { surveyReplyId: id },
  });
};

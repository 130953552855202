import { useTranslation } from "react-i18next";
import { useAtom, useAtomValue } from "jotai";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../atoms/surveyAtoms";

const FormSettingsAddressBottomTicket = () => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const { t } = useTranslation();

  return (
    <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
      {stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
        .showNickname && (
        <>
          <label
            className="mb-2 block text-sm font-bold text-gray-500"
            htmlFor="enterTitle"
          >
            {t("bottomTicket")}
          </label>
          <div>
            <div className="mb-1 flex">
              <span className="inline-flex flex-1 items-center rounded-l-md border border-r-0 border-blue-300 bg-blue-200 px-0 text-sm text-gray-900">
                <label className="text-sm">Adres Satırı 1</label>
              </span>
              <input
                type="text"
                className="block w-full min-w-0 flex-1 rounded-none rounded-r-lg border border-gray-300 bg-gray-50 p-[10px] text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500"
                placeholder="Adres Satırı 1"
                onChange={(e) => {
                  setStateSurveyJSON((prevState) => {
                    const newState = { ...prevState };
                    newState.pages![pageIndex].elements![
                      elementIndex
                    ].bottomTicketNickname = {
                      tr: e.target.value,
                    };
                    return newState;
                  });
                }}
                value={
                  stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
                    ?.bottomTicketNickname?.tr || ""
                }
              />
            </div>
          </div>
        </>
      )}
      {stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
        .showExtra3 && (
        <>
          <div>
            <div className="mb-1 flex">
              <span className="inline-flex flex-1 items-center rounded-l-md border border-r-0 border-blue-300 bg-blue-200 px-0 text-sm text-gray-900">
                <label className="text-sm">Adres Satırı 2</label>
              </span>
              <input
                type="text"
                className="block w-full min-w-0 flex-1 rounded-none rounded-r-lg border border-gray-300 bg-gray-50 p-[10px] text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500"
                placeholder="Adres Satırı 2"
                onChange={(e) => {
                  setStateSurveyJSON((prevState) => {
                    const newState = { ...prevState };
                    newState.pages![pageIndex].elements![
                      elementIndex
                    ].bottomTicket = {
                      tr: e.target.value,
                    };
                    return newState;
                  });
                }}
                value={
                  stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
                    ?.bottomTicket?.tr || ""
                }
              />
            </div>
          </div>
        </>
      )}
      {stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
        .showSecondaryName && (
        <>
          <div>
            <div className="mb-1 flex">
              <span className="inline-flex flex-1 items-center rounded-l-md border border-r-0 border-blue-300 bg-blue-200 px-0 text-sm text-gray-900">
                <label className="text-sm">İlçe</label>
              </span>
              <input
                type="text"
                className="block w-full min-w-0 flex-1 rounded-none rounded-r-lg border border-gray-300 bg-gray-50 p-[10px] text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500"
                placeholder="İlçe"
                onChange={(e) => {
                  setStateSurveyJSON((prevState) => {
                    const newState = { ...prevState };
                    newState.pages![pageIndex].elements![
                      elementIndex
                    ].bottomTicketSecondName = {
                      tr: e.target.value,
                    };
                    return newState;
                  });
                }}
                value={
                  stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
                    ?.bottomTicketSecondName?.tr || ""
                }
              />
            </div>
          </div>
        </>
      )}
      {stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
        .showExtra2 && (
        <>
          <div>
            <div className="mb-1 flex">
              <span className="inline-flex flex-1 items-center rounded-l-md border border-r-0 border-blue-300 bg-blue-200 px-0 text-sm text-gray-900">
                <label className="text-sm">İl/Eyalet</label>
              </span>
              <input
                type="text"
                className="block w-full min-w-0 flex-1 rounded-none rounded-r-lg border border-gray-300 bg-gray-50 p-[10px] text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500"
                placeholder="İl/Eyalet"
                onChange={(e) => {
                  setStateSurveyJSON((prevState) => {
                    const newState = { ...prevState };
                    newState.pages![pageIndex].elements![
                      elementIndex
                    ].bottomTicketSurname = {
                      tr: e.target.value,
                    };
                    return newState;
                  });
                }}
                value={
                  stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
                    ?.bottomTicketSurname?.tr || ""
                }
              />
            </div>
          </div>
        </>
      )}
      {stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
        .showLastName && (
        <>
          <div>
            <div className="mb-1 flex">
              <span className="inline-flex flex-1 items-center rounded-l-md border border-r-0 border-blue-300 bg-blue-200 px-0 text-sm text-gray-900">
                <label className="text-sm">Posta Kodu</label>
              </span>
              <input
                type="text"
                className="block w-full min-w-0 flex-1 rounded-none rounded-r-lg border border-gray-300 bg-gray-50 p-[10px] text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500"
                placeholder="Posta Kodu"
                onChange={(e) => {
                  setStateSurveyJSON((prevState) => {
                    const newState = { ...prevState };
                    newState.pages![pageIndex].elements![
                      elementIndex
                    ].bottomTicketLastName = {
                      tr: e.target.value,
                    };
                    return newState;
                  });
                }}
                value={
                  stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
                    ?.bottomTicketLastName?.tr || ""
                }
              />
            </div>
          </div>
        </>
      )}
      {stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
        .showExtra1 && (
        <>
          <div>
            <div className="mb-1 flex">
              <span className="inline-flex flex-1 items-center rounded-l-md border border-r-0 border-blue-300 bg-blue-200 px-0 text-sm text-gray-900">
                <label className="text-sm">Ülke</label>
              </span>
              <input
                type="text"
                className="block w-full min-w-0 flex-1 rounded-none rounded-r-lg border border-gray-300 bg-gray-50 p-[10px] text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500"
                placeholder="Ülke"
                onChange={(e) => {
                  setStateSurveyJSON((prevState) => {
                    const newState = { ...prevState };
                    newState.pages![pageIndex].elements![
                      elementIndex
                    ].bottomTicketExtra = {
                      tr: e.target.value,
                    };
                    return newState;
                  });
                }}
                value={
                  stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
                    ?.bottomTicketExtra?.tr || ""
                }
              />
            </div>
          </div>
        </>
      )}
      <p className="mb-0 mt-3 text-sm text-stone-500">
        {t("bottomTicketDescription")}
      </p>
    </div>
  );
};

export default FormSettingsAddressBottomTicket;

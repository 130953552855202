import PageBreakIcon from "../../images/questionTypeIcons-16/page-break.svg";
import NumberIcon from "../../images/questionTypeIcons-16/number.svg";
import StarRatingIcon from "../../images/questionTypeIcons-16/star-rating.svg";
import ScaleRatingIcon from "../../images/questionTypeIcons-16/scale-rating.svg";
import OpenableListIcon from "../../images/questionTypeIcons-16/acilir-liste.svg";
import SingleChoiceIcon from "../../images/questionTypeIcons-16/single-choice.svg";
import MultipeChoiceIcon from "../../images/questionTypeIcons-16/multiple-choice.svg";
import HourIcon from "../../images/questionTypeIcons-16/time-picker.svg";
import DateIcon from "../../images/questionTypeIcons-16/date-picker.svg";
import EmailIcon from "../../images/questionTypeIcons-16/email.svg";
import TelIcon from "../../images/questionTypeIcons-16/phone.svg";
import SignatureIcon from "../../images/questionTypeIcons-16/signature.svg";
import DocumentIcon from "../../images/questionTypeIcons-16/file-upload.svg";
import DataTableIcon from "../../images/questionTypeIcons-16/input-table.svg";
import TitleIcon from "../../images/questionTypeIcons-16/heading.svg";
import TextAreaIcon from "../../images/questionTypeIcons-16/short-text.svg";
import LongTextAreaIcon from "../../images/questionTypeIcons-16/long-text.svg";
import AddressIcon from "../../images/questionTypeIcons-16/address.svg";
import RichTextIcon from "../../images/questionTypeIcons-16/zengin-metin.svg";
import LikertIcon from "../../images/questionTypeIcons-16/likert.svg";
import ImagePickerIcon from "../../images/questionTypeIcons-16/picture-choice.svg";
import npsIcon from "../../images/questionTypeIcons-16/nps.svg";
import cesIcon from "../../images/questionTypeIcons-16/ces.svg";
import csatIcon from "../../images/questionTypeIcons-16/csat.svg";

const mapping: { [key: string]: string } = {
  title: TitleIcon,
  textArea: TextAreaIcon,
  longTextArea: LongTextAreaIcon,
  richText: RichTextIcon,
  number: NumberIcon,
  likert: LikertIcon,
  starRating: StarRatingIcon,
  ranking: ScaleRatingIcon,
  openableList: OpenableListIcon,
  singleChoice: SingleChoiceIcon,
  multiChoice: MultipeChoiceIcon,
  imagePicker: ImagePickerIcon,
  dataTable: DataTableIcon,
  NPS: npsIcon,
  CES: cesIcon,
  CSAT: csatIcon,
  email: EmailIcon,
  tel: TelIcon,
  address: AddressIcon,
  fullName: TitleIcon, // TODOq
  date: DateIcon,
  hour: HourIcon,
  document: DocumentIcon,
  signature: SignatureIcon,
  page: PageBreakIcon,
};

export default function getIconForQuestionType(questionType: string) {
  const src = mapping[questionType];

  if (!src) {
    console.warn("getIconForQuestionType unhandled questionType", questionType);
  }

  return <img className="h-[18px] w-[18px]" src={src ?? TitleIcon} alt="" />;
}

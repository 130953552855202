import FormSettingsWidth from "../../components/FormSettingsWidth";
import FormSettingsEnterLimit from "../../components/FormSettingsEnterLimit";

export default function NumberOptionSettings() {
  return (
    <>
      <FormSettingsWidth />
      <FormSettingsEnterLimit />
    </>
  );
}

import { useMutation } from "react-query";
import {
  createAssesor,
  deleteAssesor,
  updateAssesor,
} from "../api/assesorsApi";
import { toast } from "react-toastify";

export function useCreateAssesor() {
  return useMutation({
    mutationFn: (data: any) => createAssesor(data),
    onMutate: () => {},

    onError: () => {},

    onSuccess: () => {},

    onSettled: async (_: any, error: any) => {

    },
  });
}

export function useUpdateAssesor() {
 /*  const queryClient = useQueryClient(); */

  return useMutation({
    mutationFn: (data: any) => updateAssesor(data),

    onSettled: async (_: any, error: any, variables: any) => {
      if (error) {
        console.error(error);
      } else {

      }
    },
  });
}

export function useDeleteAssesor() {
 /*  const queryClient = useQueryClient(); */

  return useMutation({
    mutationFn: (id: string) => deleteAssesor(id),

    onSuccess: () => {
      toast.error("Anket başarıyla silindi");
    },

    onSettled: async (_: any, error: any) => {

    },
  });
}

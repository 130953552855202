import { useMutation } from "react-query";
import { createUrl, updateUrl } from "../api/urlApi";
import { UrlData } from "../../Interfaces/SurveyInterfaces";

export function useCreateUrl() {
  return useMutation({
    mutationFn: (data: UrlData) => createUrl(data),
    onMutate: () => {
      console.log("mutate");
    },

    onError: (e) => {
      console.log("error");
      console.log(e);
    },

    onSuccess: () => {
      console.log("success");
    },

    onSettled: async (_: any, error: any) => {
      console.log("settled");
      console.log(_);
      console.log(error);
    },
  });
}

export function useUpdateUrl() {
  return useMutation({
    mutationFn: (data: any) => updateUrl(data),

    onSettled: async (_: any, error: any, variables: any) => {
      if (error) {
        console.log(error);
      } else {
        console.log(variables);
      }
    },
  });
}

import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import PrimaryButton from "components/tailwindUi/PrimaryButton";
import { classNames } from "lib/externalJS";
import EmployeeProfileScoreGraph from "pages/dashboard2/EmployeeProfileScoreGraph";
import { useState } from "react";

export default function EmployeeProfileCard({
  children,
  titleEmoji,
  title,
  bgClassName,
  isEditing,
  onEditClick,
  isCollapsible,
  score,
}: {
  children?: React.ReactNode;
  titleEmoji?: string;
  title?: string;
  bgClassName?: string;
  isEditing?: boolean;
  onEditClick?: () => void;
  isCollapsible?: boolean;
  score?: number;
}) {
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <div
      className={classNames(
        "rounded-[10px] border border-gray-200 bg-white px-5 pt-5",
        isCollapsed ? "pb-0" : "pb-5",
      )}
    >
      {title && (
        <div>
          <div className="mb-5 flex justify-between">
            <div className="flex items-center gap-x-3">
              <div
                className={classNames(
                  "flex h-9 w-9 items-center justify-center rounded text-lg",
                  bgClassName ?? "",
                )}
              >
                {titleEmoji}
              </div>

              <span className="text-lg font-medium leading-tight text-gray-800">
                {title}
              </span>
            </div>

            <div className="flex items-center">
              {isCollapsible && score !== undefined && (
                <div className="mr-[30px] flex items-center gap-x-10">
                  <div className="flex rounded bg-gray-100 px-6 py-2">
                    <div className="">
                      <span className="text-2xl font-bold leading-[14px] text-gray-800">
                        {score}
                      </span>
                      <span className="text-sm font-medium leading-[14px] text-gray-400">
                        {"/100"}
                      </span>
                    </div>
                  </div>

                  <EmployeeProfileScoreGraph score={score} />
                </div>
              )}

              {isEditing !== undefined && !isEditing && (
                <PrimaryButton
                  isMinWidth
                  isSecondary
                  isBorderless
                  onClick={onEditClick}
                >
                  Düzenle
                </PrimaryButton>
              )}

              {isCollapsible && (
                <>
                  {isCollapsed ? (
                    <ChevronRightIcon
                      onClick={() => setIsCollapsed(false)}
                      className="h-9 cursor-pointer"
                    />
                  ) : (
                    <ChevronDownIcon
                      onClick={() => setIsCollapsed(true)}
                      className="h-9 cursor-pointer"
                    />
                  )}
                </>
              )}
            </div>
          </div>
          {isCollapsible && !isCollapsed && (
            <div className="-mx-5 mb-6 border-t border-gray-200" />
          )}
        </div>
      )}

      {!isCollapsed && children}
    </div>
  );
}

import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Switch } from "@headlessui/react";
import { classNames } from "../../../../lib/externalJS";
import { useAtom, useAtomValue } from "jotai";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../../atoms/surveyAtoms";

const DocumentOptionFileSize = () => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const { t } = useTranslation();
  const [switchState, setSwitchState] = useState(
    Boolean(
      stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
        ?.limitationMin ||
        stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
          ?.limitationMax,
    ),
  );

  const handleMinChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setStateSurveyJSON((prevState) => {
      let newState = { ...prevState };
      newState.pages![pageIndex].elements![elementIndex].limitationMin =
        Number(newValue);
      return newState;
    });
  };

  const handleMaksChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setStateSurveyJSON((prevState) => {
      let newState = { ...prevState };
      newState.pages![pageIndex].elements![elementIndex].limitationMax =
        Number(newValue);
      return newState;
    });
  };

  return (
    <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
      <label className="mb-2 block text-sm font-bold text-gray-500">
        Dosya Boyutunu Sınırlandır
      </label>
      <div className="mt-3 flex">
        <Switch
          id="flexSwitchCheckshuffleOptions"
          checked={switchState}
          onChange={(e) => {
            setSwitchState(e);
            !e &&
              setStateSurveyJSON((prevState) => {
                let newState = { ...prevState };
                newState.pages![pageIndex]!.elements![
                  elementIndex
                ]!.limitationMin = undefined;
                newState.pages![pageIndex]!.elements![
                  elementIndex
                ]!.limitationMax = undefined;
                return newState;
              });
          }}
          className={classNames(
            switchState ? "bg-blue-600" : "bg-gray-200",
            "relative mr-2 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2",
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={classNames(
              switchState ? "translate-x-5" : "translate-x-0",
              "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
            )}
          />
        </Switch>
        <label
          className="text-gray-500"
          htmlFor="flexSwitchCheckshuffleOptions"
        >
          {switchState ? t("open") : t("close")}
        </label>
      </div>
      <div
        className="mt-3 grid-cols-3 gap-2"
        style={{
          display: switchState ? "grid" : "none",
        }}
      >
        <div>
          <input
            type="number"
            id="min"
            className="block w-full rounded-lg border border-gray-300 bg-white p-[10px] text-sm text-gray-900 focus:border-blue-500 focus:bg-blue-200"
            placeholder="00"
            value={
              stateSurveyJSON.pages![pageIndex].elements![elementIndex]
                .limitationMin
            }
            onChange={handleMinChange}
          />
          <label
            htmlFor="min"
            className="mt-1 block text-center text-sm font-medium text-gray-500"
          >
            Min. KB
          </label>
        </div>
        <div>
          <input
            type="number"
            id="sec"
            className="block w-full rounded-lg border border-gray-300 bg-white p-[10px] text-sm text-gray-900 focus:border-blue-500 focus:bg-blue-200"
            placeholder="10854"
            value={
              stateSurveyJSON.pages![pageIndex].elements![elementIndex]
                .limitationMax
            }
            onChange={handleMaksChange}
          />
          <label
            htmlFor="sec"
            className="mt-1 block text-center text-sm font-medium text-gray-500"
          >
            Maks. KB
          </label>
        </div>
      </div>
    </div>
  );
};

export default DocumentOptionFileSize;

import { useAtom, useAtomValue } from "jotai";
import { useTranslation } from "react-i18next";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../../atoms/surveyAtoms";

const HourTimeFormat = () => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const { t } = useTranslation();

  const timeFormatClickHandler = (e: boolean) => {
    setStateSurveyJSON((prevState) => {
      let newState = { ...prevState };
      newState.pages![pageIndex]!.elements![elementIndex]!.timeFormat = e;
      return newState;
    });
  };

  return (
    <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
      <label
        className="mb-2 block text-sm font-bold text-gray-500"
        htmlFor="enterQuestion"
      >
        {t("Saat Formatı")}
      </label>
      <div className="mb-1 w-full">
        <label
          htmlFor="Toggle3"
          className="inline-flex cursor-pointer items-center rounded-md p-2 dark:text-gray-800"
        >
          <input
            id="Toggle3"
            type="checkbox"
            className="peer hidden"
            checked={
              stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
                ?.timeFormat
            }
            onChange={(e) => {
              timeFormatClickHandler(e.target.checked);
            }}
          />
          <span className="rounded-l-md px-4 py-2 dark:bg-blue-200 peer-checked:dark:bg-blue-50">
            24Saat
          </span>
          <span className="rounded-r-md px-4 py-2 dark:bg-blue-50 peer-checked:dark:bg-blue-200">
            AM/PM
          </span>
        </label>
      </div>
    </div>
  );
};

export default HourTimeFormat;

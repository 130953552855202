import { useAtom, useAtomValue } from "jotai";
import { useTranslation } from "react-i18next";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../../atoms/surveyAtoms";

const FormSettingsPlaceholderTel = () => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const { t } = useTranslation();

  return (
    <>
      <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
        <label
          className="mb-2 block text-sm font-bold text-gray-500"
          htmlFor="enterTitle"
        >
          {t("Yer Tutucu")}
        </label>
        {stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
          .showExtra1 && (
          <div className={`relative mt-3`}>
            <label
              htmlFor={"CountryCodeArea"}
              className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-500"
            >
              Ülke Kodu
            </label>
            <input
              type="text"
              id={"CountryCodeArea"}
              autoComplete="off"
              className="py-1.5 block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              onChange={(e) => {
                setStateSurveyJSON((prevState) => {
                  const newState = { ...prevState };
                  newState.pages![pageIndex].elements![
                    elementIndex
                  ].inputAreaPlaceholderSecondaryName = {
                    tr: e.target.value,
                  };
                  return newState;
                });
              }}
              value={
                stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
                  ?.inputAreaPlaceholderSecondaryName?.tr || ""
              }
            />
          </div>
        )}
        <div className={`relative mt-3`}>
          <label
            htmlFor={"AreaCodeArea"}
            className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-500"
          >
            Alan Kodu
          </label>
          <input
            type="text"
            id={"AreaCodeArea"}
            autoComplete="off"
            className="py-1.5 block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            onChange={(e) => {
              setStateSurveyJSON((prevState) => {
                const newState = { ...prevState };
                newState.pages![pageIndex].elements![
                  elementIndex
                ].inputAreaPlaceholderNickname = {
                  tr: e.target.value,
                };
                return newState;
              });
            }}
            value={
              stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
                ?.inputAreaPlaceholderNickname?.tr || ""
            }
          />
        </div>
        <div className={`relative mt-3`}>
          <label
            htmlFor={"TelNoArea"}
            className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-500"
          >
            Telefon Numarası
          </label>
          <input
            type="text"
            id={"TelNoArea"}
            autoComplete="off"
            className="py-1.5 block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            onChange={(e) => {
              setStateSurveyJSON((prevState) => {
                const newState = { ...prevState };
                newState.pages![pageIndex].elements![
                  elementIndex
                ].inputAreaPlaceholder = {
                  tr: e.target.value,
                };
                return newState;
              });
            }}
            value={
              stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
                ?.inputAreaPlaceholder?.tr || ""
            }
          />
        </div>
        <p className="mb-0 mt-3 text-sm text-stone-500">
          {t("Alanın içine bir örnek ekleyin")}
        </p>
      </div>
    </>
  );
};

export default FormSettingsPlaceholderTel;

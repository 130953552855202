import RouteGuard from "customHooks/RouteGuard";
import Register from "pages/Register";
import "react-clock/dist/Clock.css";
import { Route, Routes } from "react-router-dom";
import "react-time-picker/dist/TimePicker.css";
import { ToastContainer } from "react-toastify";
import "./App.css";
import { AuthProvider } from "./context/AuthContext";
import Dashboard2 from "./pages/dashboard2/Dashboard2";
import Login from "./pages/Login";
import NewPassword from "./pages/NewPassword";
import PDF from "./pages/PDFGen/PDF";
import ResetPassword from "./pages/ResetPassword";
import PageNotFound from "./pages/surveyFunctional/PageNotFound";
import SurveyPreview from "./pages/surveyFunctional/SurveyPreview";
import SurveyHome from "./pages/SurveyHome";

function App() {
  return (
    <AuthProvider>
      {/* asd */}
      <div className="App">
        <Routes>
          <Route
            path="/"
            index
            element={
              <RouteGuard>
                <Dashboard2 />
              </RouteGuard>
            }
          />
          <Route
            path="/createSurvey/:surveyId"
            element={
              <RouteGuard>
                <SurveyHome />
              </RouteGuard>
            }
          />
          <Route
            path="/survey/:surveyId"
            element={
              <RouteGuard>
                <SurveyPreview />
              </RouteGuard>
            }
          />
          {/* <Route path="/login" element={<Login />} /> */}
          {/* <Route path="/register" element={<Register />} /> */}
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          {/* <Route path="/audience-register" element={<Register2 />} /> */}
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/new-password" element={<NewPassword />} />
          <Route path="/pdf" element={<PDF />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <ToastContainer />
      </div>
    </AuthProvider>
  );
}

export default App;

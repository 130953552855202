import React, { ChangeEvent } from "react";
import { useSetAtom } from "jotai";
import { surveyAtom } from "../../../atoms/surveyAtoms";

type TitleOFQuestion = {
  pageIndex: number;
  index: number;
  editorValue: string | undefined;
};

const TitleChangerForSurvey: React.FC<TitleOFQuestion> = ({
  index,
  pageIndex,
  editorValue,
}) => {
  const setStateSurveyJSON = useSetAtom(surveyAtom);

  const handleTitleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    setStateSurveyJSON((prevState) => {
      const newState = { ...prevState };
      newState.pages![pageIndex].elements![index].title = { tr: value };
      return newState;
    });
  };

  return (
    <div className="w-full">
      <textarea
        onChange={handleTitleChange}
        placeholder={`Soru ${index + 1}`}
        value={editorValue}
        className="fs-6 w-full border-0 p-1 text-sm outline-none placeholder:text-gray-400 hover:outline-dashed hover:outline-1 hover:outline-gray-400 focus:!outline-none"
      />
    </div>
  );
};

export default TitleChangerForSurvey;

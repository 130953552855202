import { selectedUserAtom } from "components/atoms/dashboardAtoms";
import { useAtomValue } from "jotai";
import { getRowsForThisKeys } from "pages/dashboard2/EmployeeProfileSkillsTech";
import EmployeeProfileCard from "./EmployeeProfileCard";
import { SkillsTable } from "./EmployeeProfileSkillsPanel";

const icerik = [
  "Okuduğunu Anlama",
  "Aktif Dinleme",
  "Yazılı İletişim",
  "Konuşma",
  "Matematiği Kullanma",
  "Bilimsel Yöntemleri Kullanma",
];

const surec = [
  "Eleştirel Düşünme",
  "Aktif Öğrenme",
  "Öğrenme Stratejileri",
  "İzleme",
];

const sosyalBeceriler = [
  "Sosyal Algı",
  "Koordine Etme",
  "Müzakere Etme",
  "Eğitmenlik",
  "Hizmet Odaklılık",
];

const karmasikProblem = ["Karmaşık Problemleri Çözme"];

const teknik = [
  "Operasyon Analizi",
  "Teknoloji Tasarımı",
  "Ekipman Seçimi",
  "Kurulum",
  "Programlama",
  "Operasyonları İzleme",
  "Operasyon ve Kontrol",
  "Ekipman Bakımı",
  "Sorun Giderme",
  "Onarım",
  "Kalite Kontrol Analizi",
];

const sistem = [
  "Muhakeme ve Karar Verme",
  "Sistem Analizi",
  "Sistem Değerlendirmesi",
];

const kaynakYonetimi = [
  "Zaman Yönetimi",
  "Mali Kaynakların Yönetimi",
  "Malzeme Kaynaklarının Yönetimi",
  "İnsan Kaynaklarının Yönetimi",
];

export const generalSkills = [
  ...icerik,
  ...surec,
  ...sosyalBeceriler,
  ...karmasikProblem,
  ...teknik,
  ...sistem,
  ...kaynakYonetimi,
].map((x) => ({ text: x, value: x }));

export default function EmployeeProfileSkillsTech() {
  const selectedUser = useAtomValue(selectedUserAtom);

  const getRowsForThisTab = (keys: string[]) => {
    return getRowsForThisKeys(selectedUser, "puanBeceriler_", keys);
  };

  const CARDS = Object.freeze([
    {
      titleEmoji: "📝",
      title: "İçerik",
      bgClassName: "bg-orange-100",
      score: selectedUser.puanBeceriler_i̇cerik.yuzde,
      rows: getRowsForThisTab(icerik),
    },
    {
      titleEmoji: "📚",
      title: "Süreç",
      bgClassName: "bg-lime-100",
      score: selectedUser.puanBeceriler_surec.yuzde,
      rows: getRowsForThisTab(surec),
    },
    {
      titleEmoji: "🔗",
      title: "Sosyal Beceriler",
      bgClassName: "bg-fuchsia-100",
      score: selectedUser.puanBeceriler_sosyalBeceriler.yuzde,
      rows: getRowsForThisTab(sosyalBeceriler),
    },
    {
      titleEmoji: "🧩",
      title: "Karmaşık Problem Çözme Becerileri",
      bgClassName: "bg-emerald-100",
      score: selectedUser.puanBeceriler_karmasikProblemCözmeBecerileri.yuzde,
      rows: getRowsForThisTab(karmasikProblem),
    },
    {
      titleEmoji: "🛠️",
      title: "Teknik Beceriler",
      bgClassName: "bg-gray-100",
      score: selectedUser.puanBeceriler_teknikBeceriler.yuzde,
      rows: getRowsForThisTab(teknik),
    },
    {
      titleEmoji: "⚙️",
      title: "Sistem Becerileri",
      bgClassName: "bg-violet-100",
      score: selectedUser.puanBeceriler_sistemBecerileri.yuzde,
      rows: getRowsForThisTab(sistem),
    },
    {
      titleEmoji: "📁",
      title: "Kaynak Yönetimi Becerileri",
      bgClassName: "bg-blue-100",
      score: selectedUser.puanBeceriler_kaynakYönetimiBecerileri.yuzde,
      rows: getRowsForThisTab(kaynakYonetimi),
    },
  ]);

  return (
    <>
      {CARDS.map((card, index) => (
        <EmployeeProfileCard {...card} isCollapsible>
          <SkillsTable rows={card.rows} />
        </EmployeeProfileCard>
      ))}
    </>
  );
}

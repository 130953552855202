import React, { Dispatch, SetStateAction, useState } from "react";
import {
  assessorsInterface,
  participantsInterface,
} from "../../../Interfaces/SurveyInterfaces";
import axios from "axios";
import { toast } from "react-toastify";
import CheckboxBootstrap from "../../../components/staticDesign/elements/CheckboxBootstrap";
import { API_DOMAIN } from "../../../settings";
import { encryptStrings } from "../../../lib/externalJS";

const ListItemAttendents = ({
  item,
  deleteUser,
  deleteEvaluator,
  setSelectedUser,
  setShowAddUser,
  selectedParticipants,
  usersCheckChangeHandle,
  surveyId,
  setIsUpdating,
}: {
  item: participantsInterface;
  deleteUser: (_id: string) => void;
  deleteEvaluator: (_id: string, parentId: string) => void;
  setSelectedUser: any;
  setShowAddUser: any;
  selectedParticipants: any[];
  usersCheckChangeHandle: (e: any, id: string) => void;
  surveyId: string;
  setIsUpdating: Dispatch<SetStateAction<boolean>>;
}) => {
  const [show, setShow] = useState<boolean>(false);

  const sendMail = async (i: number) => {
    const mailValues = {
      fromName: item.assessors![i].name,
      toMail: [item.assessors![i].mail],
      subject: "Form Cooker Yeni Bir Ankete Davetlisiniz!",
      content: `<p style="text-align: left;">Değerli kullanıcımız. ${
        item.name
      } Tarafından bir anket gönderilmiştir.</p> <p style="text-align: left;"> <strong>Ankete gitmek için tıklayınız: </strong><em><a href="${
        API_DOMAIN +
        "/surveyReply/" +
        encryptStrings(surveyId, item._id, item.assessors![i]._id)
      }">Tıkla&nbsp;&nbsp;</a></em></p> <p style="text-align: left;"><em><br></em></p><p style="text-align: left;"><em><br></em></p>`,
      sendDate: new Date(),
    };
    const apiUrl = "http://3.144.40.16:8080/Survey/SendMail";

    await axios.post(apiUrl, mailValues);

    toast.success("Kullanıcıya mail başarıyla gönderildi");
  };

  const calculationForCompleted = (assessors: assessorsInterface[]) => {
    let completed = 0;
    assessors.map(
      (assessor) =>
        assessor?.completedPercentage &&
        (completed += assessor.completedPercentage)
    );
    return completed / assessors.length;
  };

  return (
    <div>
      <div className="flex bg-white border-b h-[72px]">
        <div className="flex items-center w-[320px] justify-start ml-2">
          <div className="p-2 flex justify-center items-center">
            <CheckboxBootstrap
              onChange={(e) => usersCheckChangeHandle(e, item._id)}
              style={{ transform: "scale(1.2)", marginRight: "0.5em" }}
              id="UserCheckerSingle"
              name="UserCheckerSingle"
              checked={selectedParticipants.includes(item._id)}
            />
            <div
              className={`h-8 w-8 rounded-full mr-2 bg-gray-200 flex justify-center items-center font-bold font-mono text-xl`}
            >
              {item.name[0]}
            </div>
            <div>
              <h6 className="m-0 text-base text-start">{item.name}</h6>
              <p className="m-0 text-sm text-start">{item.mail}</p>
            </div>
          </div>
        </div>
        <div className=" w-[110px] text-sm flex justify-start mb-5 items-center">
          <div className="relative">
            {item.assessors?.[0] && (
              <div className="absolute top-0 left-0 w-6 h-6 rounded-full bg-gray-200 text-center">
                {item.assessors[0].name[0]}
              </div>
            )}
            {item.assessors?.[1] && (
              <div className="absolute top-0 left-0 w-6 h-6 rounded-full ml-4 bg-gray-200 text-center">
                {item.assessors[1].name[0]}
              </div>
            )}
            {item.assessors?.[2] && (
              <div className="absolute top-0 left-0 w-6 h-6 rounded-full ml-8 bg-gray-200 text-center">
                {item.assessors[2].name[0]}
              </div>
            )}
            {item.assessors?.[3] && (
              <div className="absolute top-0 left-0 w-6 h-6 rounded-full ml-12 bg-gray-200 text-center">
                {item.assessors[3].name[0]}
              </div>
            )}
            {item.assessors && item.assessors.length > 3 && (
              <div
                className={`absolute top-0 left-0 w-6 h-6 rounded-full bg-gray-200 text-center ml-16`}
              >
                {"+" + (item.assessors.length - 3)}
              </div>
            )}
          </div>
        </div>
        <div className=" w-[170px] text-sm flex items-center justify-start ">
          <div className="w-[105px] bg-gray-200 rounded-full h-2.5 mx-auto ">
            <div
              className={`${
                item.assessors
                  ? Math.floor(calculationForCompleted(item.assessors)) > 85
                    ? "bg-green-400"
                    : Math.floor(calculationForCompleted(item.assessors)) < 50
                      ? "bg-red-400"
                      : "bg-yellow-400"
                  : "bg-gray-400"
              } h-2.5 rounded-full`}
              style={{
                width: item.assessors
                  ? Math.floor(calculationForCompleted(item.assessors))
                  : 0,
              }}
            >
              <br />
            </div>
          </div>
          <span className="w-[45px] mx-auto">
            {item.assessors
              ? Math.floor(calculationForCompleted(item.assessors)).toString() +
                " %"
              : "-"}
          </span>
        </div>
        <div className=" w-[120px] text-sm flex justify-center items-center">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mx-2 cursor-pointer"
            onClick={() => {
              setSelectedUser(item);
              setShowAddUser(true);
              setIsUpdating(true);
            }}
          >
            <path
              d="M9.99998 16.6667H17.5M2.5 16.6667H3.89545C4.3031 16.6667 4.50693 16.6667 4.69874 16.6206C4.8688 16.5798 5.03138 16.5125 5.1805 16.4211C5.34869 16.318 5.49282 16.1739 5.78107 15.8856L16.25 5.41669C16.9404 4.72634 16.9404 3.60705 16.25 2.91669C15.5597 2.22634 14.4404 2.22634 13.75 2.91669L3.28105 13.3856C2.9928 13.6739 2.84867 13.818 2.7456 13.9862C2.65422 14.1353 2.58688 14.2979 2.54605 14.468C2.5 14.6598 2.5 14.8636 2.5 15.2713V16.6667Z"
              stroke="#475467"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mx-2 cursor-pointer"
            onClick={() => deleteUser(item._id)}
          >
            <path
              d="M13.8333 4.99984V4.33317C13.8333 3.39975 13.8333 2.93304 13.6517 2.57652C13.4919 2.26292 13.2369 2.00795 12.9233 1.84816C12.5668 1.6665 12.1001 1.6665 11.1667 1.6665H9.83333C8.89991 1.6665 8.4332 1.6665 8.07668 1.84816C7.76308 2.00795 7.50811 2.26292 7.34832 2.57652C7.16667 2.93304 7.16667 3.39975 7.16667 4.33317V4.99984M8.83333 9.58317V13.7498M12.1667 9.58317V13.7498M3 4.99984H18M16.3333 4.99984V14.3332C16.3333 15.7333 16.3333 16.4334 16.0608 16.9681C15.8212 17.4386 15.4387 17.821 14.9683 18.0607C14.4335 18.3332 13.7335 18.3332 12.3333 18.3332H8.66667C7.26654 18.3332 6.56647 18.3332 6.03169 18.0607C5.56129 17.821 5.17883 17.4386 4.93915 16.9681C4.66667 16.4334 4.66667 15.7333 4.66667 14.3332V4.99984"
              stroke="#475467"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          {show ? (
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mx-2 cursor-pointer"
              onClick={() => setShow(false)}
            >
              <path
                d="M7.16797 9.99984H13.8346M18.8346 9.99984C18.8346 14.6022 15.1037 18.3332 10.5013 18.3332C5.89893 18.3332 2.16797 14.6022 2.16797 9.99984C2.16797 5.39746 5.89893 1.6665 10.5013 1.6665C15.1037 1.6665 18.8346 5.39746 18.8346 9.99984Z"
                stroke="#475467"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mx-2 cursor-pointer"
              onClick={() => setShow(true)}
            >
              <path
                d="M10.5013 6.6665V13.3332M7.16797 9.99984H13.8346M18.8346 9.99984C18.8346 14.6022 15.1037 18.3332 10.5013 18.3332C5.89893 18.3332 2.16797 14.6022 2.16797 9.99984C2.16797 5.39746 5.89893 1.6665 10.5013 1.6665C15.1037 1.6665 18.8346 5.39746 18.8346 9.99984Z"
                stroke="#475467"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </div>
      </div>
      {show &&
        item.assessors?.map((evaluator: assessorsInterface, i: number) => (
          <div className="flex bg-white border-b h-[72px]" key={evaluator._id}>
            <div className="flex items-center w-[320px] justify-start ml-2">
              <div className="p-2 flex justify-center items-center">
                <div
                  className={`h-8 w-8 rounded-full mr-2 bg-gray-200 flex justify-center items-center font-bold font-mono text-xl`}
                >
                  {evaluator.name[0]}
                </div>
                <div>
                  <h6 className="m-0 text-base text-start">{evaluator.name}</h6>
                  <p className="m-0 text-sm text-start">{evaluator.mail}</p>
                </div>
              </div>
            </div>
            <div className=" w-[110px] text-sm text-start my-auto">
              {evaluator.roleTowardsParticipant}
            </div>
            <div className=" w-[170px] text-sm flex items-center justify-start ">
              <div className="w-[105px] bg-gray-200 rounded-full h-2.5 mx-auto ">
                <div
                  className={`${
                    evaluator.completedPercentage
                      ? evaluator.completedPercentage > 85
                        ? "bg-green-400"
                        : evaluator.completedPercentage < 50
                          ? "bg-red-400"
                          : "bg-yellow-400"
                      : "bg-gray-400"
                  } h-2.5 rounded-full`}
                  style={{
                    width: evaluator.completedPercentage
                      ? evaluator.completedPercentage
                      : 0,
                  }}
                >
                  <br />
                </div>
              </div>
              <span className="w-[45px] mx-auto">
                {evaluator.completedPercentage
                  ? evaluator.completedPercentage.toString() + " %"
                  : "-"}
              </span>
            </div>
            <div className=" w-[120px] text-sm flex justify-center items-center">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mx-2 cursor-pointer"
                onClick={() => {
                  setSelectedUser(item);
                  setShowAddUser(true);
                  setIsUpdating(true);
                }}
              >
                <path
                  d="M9.99998 16.6667H17.5M2.5 16.6667H3.89545C4.3031 16.6667 4.50693 16.6667 4.69874 16.6206C4.8688 16.5798 5.03138 16.5125 5.1805 16.4211C5.34869 16.318 5.49282 16.1739 5.78107 15.8856L16.25 5.41669C16.9404 4.72634 16.9404 3.60705 16.25 2.91669C15.5597 2.22634 14.4404 2.22634 13.75 2.91669L3.28105 13.3856C2.9928 13.6739 2.84867 13.818 2.7456 13.9862C2.65422 14.1353 2.58688 14.2979 2.54605 14.468C2.5 14.6598 2.5 14.8636 2.5 15.2713V16.6667Z"
                  stroke="#475467"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <svg
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mx-2 cursor-pointer"
                onClick={() => deleteEvaluator(evaluator._id, item._id)}
              >
                <path
                  d="M13.8333 4.99984V4.33317C13.8333 3.39975 13.8333 2.93304 13.6517 2.57652C13.4919 2.26292 13.2369 2.00795 12.9233 1.84816C12.5668 1.6665 12.1001 1.6665 11.1667 1.6665H9.83333C8.89991 1.6665 8.4332 1.6665 8.07668 1.84816C7.76308 2.00795 7.50811 2.26292 7.34832 2.57652C7.16667 2.93304 7.16667 3.39975 7.16667 4.33317V4.99984M8.83333 9.58317V13.7498M12.1667 9.58317V13.7498M3 4.99984H18M16.3333 4.99984V14.3332C16.3333 15.7333 16.3333 16.4334 16.0608 16.9681C15.8212 17.4386 15.4387 17.821 14.9683 18.0607C14.4335 18.3332 13.7335 18.3332 12.3333 18.3332H8.66667C7.26654 18.3332 6.56647 18.3332 6.03169 18.0607C5.56129 17.821 5.17883 17.4386 4.93915 16.9681C4.66667 16.4334 4.66667 15.7333 4.66667 14.3332V4.99984"
                  stroke="#475467"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <svg
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mx-2 cursor-pointer"
                onClick={() => sendMail(i)}
              >
                <path
                  d="M9.24928 11.2501L17.9993 2.50014M9.35559 11.5235L11.5457 17.1552C11.7386 17.6513 11.8351 17.8994 11.9741 17.9718C12.0946 18.0346 12.2381 18.0347 12.3587 17.972C12.4978 17.8998 12.5946 17.6518 12.7881 17.1559L18.28 3.08281C18.4547 2.63516 18.5421 2.41133 18.4943 2.26831C18.4528 2.1441 18.3553 2.04663 18.2311 2.00514C18.0881 1.95736 17.8643 2.0447 17.4166 2.21939L3.34349 7.71134C2.84759 7.90486 2.59965 8.00163 2.52739 8.14071C2.46475 8.26129 2.46483 8.40483 2.52761 8.52533C2.60004 8.66433 2.8481 8.7608 3.34422 8.95373L8.97589 11.1438C9.0766 11.183 9.12695 11.2026 9.16935 11.2328C9.20693 11.2596 9.2398 11.2925 9.2666 11.3301C9.29685 11.3725 9.31643 11.4228 9.35559 11.5235Z"
                  stroke="#475467"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        ))}
    </div>
  );
};

export default ListItemAttendents;

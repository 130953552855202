import { ChangeEvent, useEffect, useState } from "react";
import { useAtom, useAtomValue } from "jotai";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../atoms/surveyAtoms";

const FormSettingsHighestValue = () => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const [localState, setLocalState] = useState({
    rankingHighestValue:
      stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
        ?.rankingHighestValue,
  });

  useEffect(() => {
    setStateSurveyJSON((prevState) => {
      let newState = { ...prevState };
      newState.pages![pageIndex]!.elements![elementIndex]!.rankingHighestValue =
        localState.rankingHighestValue;
      return newState;
    });
  }, [localState]);

  const handleHighValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setLocalState((prevState) => {
      const newState = { ...prevState };
      newState.rankingHighestValue = parseInt(newValue);
      return newState;
    });
  };

  return (
    <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
      <label
        className="mb-2 block text-sm font-bold text-gray-500"
        htmlFor="enterTitle"
      >
        En Yüksek Değer
      </label>
      <input
        id="enterTitle"
        className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow-sm focus:outline-none"
        type="number"
        placeholder="10"
        value={localState.rankingHighestValue}
        onChange={(e) => handleHighValueChange(e)}
      />
      <p className="mb-0 mt-3 text-sm text-stone-500">En Yüksek Değer</p>
    </div>
  );
};

export default FormSettingsHighestValue;

import React, { FC, useState } from "react";
import ReactApexChart from "react-apexcharts";
import Select from "react-select";
import Statistics from "./Statistics";
import { ChartData } from "../../../../Interfaces/SurveyInterfaces";
import AnalysisListItem from "./AnalysisListItem";

type Props = {
  chartDataArray: ChartData[];
};

const Analysis: FC<Props> = ({ chartDataArray }) => {
  const [chartType, setChartType] = useState<string>("1");
  const handleBarTypeChange = (e: any) => {
    setChartType(e.value);
  };

  return (
    <>
      {chartDataArray.map((data, i) => (
        <div className="w-[720px] mx-auto" key={i}>
          <label className="font-semibold text-lg my-4">
            S.{i + 1}
            <span className="w-[61px] bg-red-100 h-[22px] border-1 border-red-300 rounded-2xl text-xs font-bold p-1 ml-4 text-center text-red-500">
              1dk 2sn
            </span>
          </label>
          <div className="w-full bg-white border rounded border-gray-300 p-4 shadow-md">
            <div className="flex justify-between ">
              <span className="font-semibold">Grafik</span>
              <Select
                className="w-[140px] h-9 mb-3"
                isSearchable
                options={[
                  { value: `1`, label: "Pasta Dilim" },
                  { value: `2`, label: "Bar Sütün" },
                  { value: "3", label: "Çizgi Grafik" },
                ]}
                defaultValue={{ value: "1", label: "Pasta Dilim" }}
                onChange={handleBarTypeChange}
              />
            </div>
            <div className="min-h-[300px] flex justify-center border-b mb-4">
              <div>
                <div id="chart" key={chartType}>
                  {chartType === "1" ? (
                    <ReactApexChart
                      options={{
                        ...data.options,
                        chart: {
                          ...data.options.chart,
                          type: "pie",
                        },
                      }}
                      series={data.series}
                      type={"pie"}
                      width={380}
                    />
                  ) : chartType === "2" ? (
                    <ReactApexChart
                      options={{
                        chart: {
                          type: "bar",
                          height: 350,
                        },
                        plotOptions: {
                          bar: {
                            borderRadius: 4,
                            horizontal: true,
                          },
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        xaxis: {
                          categories: data.options.labels,
                        },
                      }}
                      series={[{ data: data.series }]}
                      type="bar"
                      height={350}
                    />
                  ) : (
                    <ReactApexChart
                      options={{
                        chart: {
                          height: 350,
                          type: "line",
                          zoom: {
                            enabled: false,
                          },
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        stroke: {
                          curve: "straight",
                        },
                        title: {
                          text: "Product Trends by Month",
                          align: "left",
                        },
                        grid: {
                          row: {
                            colors: ["#f3f3f3", "transparent"],
                            opacity: 0.5,
                          },
                        },
                        xaxis: {
                          categories: data.options.labels,
                        },
                      }}
                      series={[
                        {
                          name: "Desktops",
                          data: data.series,
                        },
                      ]}
                      type="line"
                      height={350}
                    />
                  )}
                </div>
                <div id="html-dist"></div>
              </div>
            </div>
            <div>
              <span className="font-semibold">Yanıtlar</span>
              <div className="w-[664px]  mx-auto">
                <div className="flex mt-4 border-b">
                  <div className="flex items-center w-[374px] justify-start ">
                    <p className="m-0 text-sm">Cevap</p>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.9987 3.3335V12.6668M7.9987 12.6668L12.6654 8.00016M7.9987 12.6668L3.33203 8.00016"
                        stroke="#475467"
                        strokeWidth="1.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className=" w-[70px] h-11 text-sm text-start">Adet</div>
                  <div className=" w-[220px] h-11 text-sm text-start">Oran</div>
                </div>
                {data.options.labels.map((label: any, index: number) => (
                  <AnalysisListItem
                    key={index}
                    index={index + 1}
                    data={label}
                    generalData={data}
                  />
                ))}
              </div>
            </div>
            <div>
              <Statistics chartData={data} />
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default Analysis;

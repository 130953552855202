import React, { useMemo, useState } from "react";
import {
  elementChoicesInterface,
  surveyElementsInterface,
} from "../../Interfaces/SurveyInterfaces";
import { ButtonInput } from "./components/FormCompoents";

type Props = {
  question: surveyElementsInterface;
  register: any;
  endOfPage: boolean;
  setValue: any;
  errors: any;
  getValues: any;
  trigger: any;
  questionIndex: number;
};

const RankingQuestion: React.FC<Props> = ({
  question,
  register,
  endOfPage,
  setValue,
  getValues,
  errors,
  trigger,
  questionIndex,
}) => {
  const [isClicked, setIsClicked] = useState<string>("");

  function calculateValue(index: number) {
    const lowestValue = question.rankingLowestValue
      ? question.rankingLowestValue
      : 1;

    const highestValue = question.rankingHighestValue
      ? question.rankingHighestValue
      : question.rateValues!.length;

    const calculatedValue =
      lowestValue +
      Math.round(
        ((highestValue - lowestValue) / (question.rateValues!.length - 1)) *
          index
      );
    return calculatedValue;
  }

  const RatesTitle = useMemo(() => {
    return question.rateValues?.map(
      (choice: elementChoicesInterface, indexes) =>
        choice.title?.tr ? choice.title?.tr : calculateValue(indexes)
    );
  }, [question.rateValues]);

  return (
    <div data-title={question.tipText?.tr}>
      <h6
        className="mb-0"
        dangerouslySetInnerHTML={{ __html: question.title?.tr ?? "" }}
      ></h6>
      <p className=" text-gray-500">{question.description?.tr}</p>
      <div className="flex flex-wrap">
        {question.rateValues?.map((rate: any, index: number) => {
          return (
            <div className="text-center h-20" key={rate._id}>
              <label className="inline-block relative min-w-[2rem] min-h-[2rem] mb-1 mx-2">
                <span className="whitespace-pre px-[18px] text-white">
                  {rate.title?.tr}
                </span>
                <ButtonInput
                  name={question._id}
                  questionIndex={questionIndex}
                  questionType={question.type}
                  options={RatesTitle}
                  readOnly={question.readOnly ? question.readOnly : false}
                  className={`h-9 rounded-full border-2 p-[2px]hover:border-blue-400 shadow-blue-200 absolute 
                  align-top top-0 left-0  bg-clip-content rankingShadowBox 
                  ${!!errors[question._id] && "!border-red-500"} ${
                    rate.title?.tr?.length && rate.title?.tr?.length > 1
                      ? "w-full"
                      : "w-9"
                  } `}
                  required={question.isRequired ? question.isRequired : false}
                  value={calculateValue(index)}
                  index={index}
                  questionTitle={question.title?.tr}
                  setValue={setValue}
                  optionTitle={
                    rate.title?.tr ? rate.title?.tr : calculateValue(index)
                  }
                  getValues={getValues}
                  setIsClicked={setIsClicked}
                  isClicked={isClicked}
                  register={register}
                  trigger={trigger}
                />
              </label>
              {index === 0 ? (
                <div className="text-stone-400">
                  {question.lowestRankingText?.tr ?? "En Kötü"}
                </div>
              ) : index + 1 === question.rateValues!.length / 2 ? (
                <div className="text-stone-400">
                  {question.middleRankingText?.tr ?? "Vasat"}
                </div>
              ) : (
                index === question.rateValues!.length - 1 && (
                  <div className="text-stone-400">
                    {question.highestRankingText?.tr ?? "En İyi"}
                  </div>
                )
              )}
            </div>
          );
        })}
      </div>
      <p className="mb-2 text-sm text-red-500">
        {errors[question._id]?.value?.message}
      </p>
      <p className="mb-4 text-gray-500">{question.bottomTicket?.tr}</p>
      {!endOfPage && <hr />}
    </div>
  );
};

export default RankingQuestion;

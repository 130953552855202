export default function EmployeeExperience({
  isActive,
  isForTitle,
}: {
  isActive?: boolean;
  isForTitle?: boolean;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.0909 9.27273V13.0643C10.0316 13.4387 9.27273 14.4489 9.27273 15.6364C9.27273 17.1426 10.4938 18.3636 12 18.3636C13.5062 18.3636 14.7273 17.1426 14.7273 15.6364C14.7273 14.4489 13.9684 13.4387 12.9091 13.0643V9.27273H11.0909ZM11.0909 15.6364C11.0909 15.1343 11.4979 14.7273 12 14.7273C12.5021 14.7273 12.9091 15.1343 12.9091 15.6364C12.9091 16.1384 12.5021 16.5455 12 16.5455C11.4979 16.5455 11.0909 16.1384 11.0909 15.6364Z"
        fill={isActive ? "#2970FF" : "#667085"}
      />
      <path
        d="M12 5.63636C15.5145 5.63636 18.3636 8.48546 18.3636 12V12.9091H16.5455V12C16.5455 9.48962 14.5104 7.45455 12 7.45455C9.48962 7.45455 7.45455 9.48962 7.45455 12V12.9091H5.63636V12C5.63636 8.48546 8.48546 5.63636 12 5.63636Z"
        fill={isActive ? "#2970FF" : "#667085"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12 20.1818C16.5187 20.1818 20.1818 16.5187 20.1818 12C20.1818 7.48131 16.5187 3.81818 12 3.81818C7.48131 3.81818 3.81818 7.48131 3.81818 12C3.81818 16.5187 7.48131 20.1818 12 20.1818Z"
        fill={isForTitle ? "#A48AFB" : isActive ? "#2970FF" : "#667085"}
      />
    </svg>
  );
}

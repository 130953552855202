import { useState } from "react";
import { FilterChip } from "../../DashboardCards";
import EmployeeProfileBusinessTasks from "./EmployeeProfileBusinessTasks";
import EmployeeProfileBusinessTechSkills from "./EmployeeProfileBusinessTechSkills";
import EmployeeProfileBusinessTools from "./EmployeeProfileBusinessTools";
import EmployeeProfileBusinessActivities from "./EmployeeProfileBusinessActivities";
import EmployeeProfileBusinessActivitiesDetailed from "./EmployeeProfileBusinessActivitiesDetailed";
import EmployeeProfileBusinessWorkingEnvironment from "./EmployeeProfileBusinessWorkingEnvironment";
import EmployeeProfileBusinessDistrict from "./EmployeeProfileBusinessDistrict";

const TABS = Object.freeze([
  "Görevler",
  "Teknolojik Beceriler",
  "Kullanılan Araçlar",
  "İş Faaliyetleri",
  "Detaylı İş Faaliyetleri",
  "Çalışma Ortamı",
  "İş Bölgesi",
]);

export default function EmployeeProfileJobDescription() {
  const [currentTab, setCurrentTab] = useState(TABS[0]);

  const CurrentTabComp = () => {
    switch (currentTab) {
      case "Görevler":
        return <EmployeeProfileBusinessTasks />;
      case "Teknolojik Beceriler":
        return <EmployeeProfileBusinessTechSkills />;
      case "Kullanılan Araçlar":
        return <EmployeeProfileBusinessTools />;
      case "İş Faaliyetleri":
        return <EmployeeProfileBusinessActivities />;
      case "Detaylı İş Faaliyetleri":
        return <EmployeeProfileBusinessActivitiesDetailed />;
      case "Çalışma Ortamı":
        return <EmployeeProfileBusinessWorkingEnvironment />;
      case "İş Bölgesi":
        return <EmployeeProfileBusinessDistrict />;
      default:
        return null;
    }
  };

  return (
    <div className="mt-10 flex flex-col gap-7">
      <div className="flex gap-x-[6px]">
        {TABS.map((tab, i) => (
          <button key={i} onClick={() => setCurrentTab(tab)}>
            <FilterChip key={tab} isActive={currentTab === tab} isForTabs>
              {tab}
            </FilterChip>
          </button>
        ))}
      </div>

      <CurrentTabComp />
    </div>
  );
}

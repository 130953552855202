import React, { useState } from "react";
import {
  svgArrForError,
  svgArrForQuestions,
  svgArrForQuestionsHover,
  svgArrForQuestionsSelected,
} from "../../lib/SvgElements";
import { surveyElementsInterface } from "../../Interfaces/SurveyInterfaces";

type Props = {
  question: surveyElementsInterface;
  endOfPage: boolean;
  setValue: any;
  getValues: any;
  register: any;
  errors: any;
  trigger: any;
  questionIndex: number;
};

const RatingQuestion: React.FC<Props> = ({
  question,
  setValue,
  getValues,
  endOfPage,
  register,
  errors,
  trigger,
  questionIndex,
}) => {
  const [hoverRating, setHoverRating] = useState<number | null>(null);
  const [isFirstCall, setIsFirstCall] = useState(true);
  const questionID = question._id;

  const selectedRating = isFirstCall
    ? getValues(questionID + ".value")
      ? getValues(questionID + ".value")
      : question.inputAreaDefaultValue?.tr
    : getValues(questionID + ".value");



  const handleRatingHover = (rating: number) => {
    setHoverRating(rating);
  };

  const handleRatingLeave = () => {
    setHoverRating(null);
  };

  return (
    <>
      {question.position === null ||
      question.position === undefined ||
      question.position === "Top" ? (
        <div
          data-title={question.tipText?.tr}
          className={`${question.collapse ? "w-1/2 mx-auto" : "w-full"} `}
        >
          <h6
            className="mb-0"
            dangerouslySetInnerHTML={{ __html: question.title?.tr ?? "" }}
          />
          <p className="text-gray-500">{question.description?.tr}</p>
          <div className="flex flex-wrap items-center mb-4">
            {question.rateValues?.map((rate: any, index: number) => (
              <button
                key={rate._id}
                type="button"
                data-title={rate.info?.tr}
                disabled={question.readOnly ? question.readOnly : false}
                onMouseOver={() => handleRatingHover(index + 1)}
                onMouseLeave={handleRatingLeave}
                {...register(questionID + ".value", {
                  value: null,
                  required: {
                    value: question?.isRequired ? question?.isRequired : false,
                    message: "Bu Alan Zorunludur",
                  },
                })}
                onClick={() => {
                  setIsFirstCall(false);
                  setValue(questionID + ".questionInfos", {
                    optionsDetail: JSON.stringify({
                      options: [question.rateValues?.length],
                      answers: [index + 1],
                      values: [index + 1],
                    }),
                    dateTime: new Date(),
                    type: question.type,
                    questionTitle: question.title?.tr,
                    questionNumber: questionIndex,
                  });
                  setValue(questionID + ".value", index + 1);
                  trigger(questionID + ".value");
                }}
              >
                {selectedRating !== null && selectedRating > index
                  ? svgArrForQuestionsSelected.find(
                      (item) => item.key === (question.ratingIcon ?? "star")
                    )?.value
                  : hoverRating !== null && index < hoverRating
                    ? svgArrForQuestionsHover.find(
                        (item) => item.key === (question.ratingIcon ?? "star")
                      )?.value
                    : !!errors[questionID]
                      ? svgArrForError.find(
                          (item) => item.key === (question.ratingIcon ?? "star")
                        )?.value
                      : svgArrForQuestions.find(
                          (item) => item.key === (question.ratingIcon ?? "star")
                        )?.value}
              </button>
            ))}
          </div>
          <p className="mb-2 text-sm text-red-500">
            {errors[questionID]?.value?.message}
          </p>
          <p className="mb-4 text-gray-500">{question.bottomTicket?.tr}</p>
          {!endOfPage && <hr />}
        </div>
      ) : (
        <div
          data-title={question.tipText?.tr}
          className={`${
            question.collapse ? "w-1/2 mx-auto" : "w-full"
          } flex flex-wrap`}
        >
          <div
            className={`w-1/2 ${
              question.position === "Right" && "text-right pr-4"
            }`}
          >
            <h6
              className="mb-0"
              dangerouslySetInnerHTML={{ __html: question.title?.tr ?? "" }}
            />
            <p className="text-gray-500">{question.description?.tr}</p>
          </div>

          <div className="flex flex-wrap items-center mb-4 w-1/2">
            {question.rateValues?.map((rate: any, index: number) => (
              <button
                key={rate._id}
                type="button"
                data-title={rate.info?.tr ? rate.info?.tr : undefined}
                disabled={question.readOnly ? question.readOnly : false}
                onMouseOver={() => handleRatingHover(index + 1)}
                onMouseLeave={handleRatingLeave}
                {...register(questionID + ".value", {
                  value: null,
                  required: {
                    value: question?.isRequired ?? false,
                    message: "Bu Alan Zorunludur",
                  },
                })}
                onClick={() => {
                  setIsFirstCall(false);
                  setValue(questionID + ".questionInfos", {
                    dateTime: new Date(),
                    questionTitle: question.title?.tr,
                  });
                  setValue(questionID + ".value", index + 1);
                  trigger(questionID + ".value");
                }}
              >
                {selectedRating !== null && selectedRating > index
                  ? svgArrForQuestionsSelected.find(
                      (item) => item.key === (question.ratingIcon ?? "star")
                    )?.value
                  : hoverRating !== null && index < hoverRating
                    ? svgArrForQuestionsHover.find(
                        (item) => item.key === (question.ratingIcon ?? "star")
                      )?.value
                    : !!errors[questionID]
                      ? svgArrForError.find(
                          (item) => item.key === (question.ratingIcon ?? "star")
                        )?.value
                      : svgArrForQuestions.find(
                          (item) => item.key === (question.ratingIcon ?? "star")
                        )?.value}
              </button>
            ))}
          </div>
          <p className="mb-2 text-sm text-red-500">
            {errors[questionID]?.value?.message}
          </p>
          <p className="mb-4 text-gray-500">{question.bottomTicket?.tr}</p>
          {!endOfPage && <hr />}
        </div>
      )}
    </>
  );
};

export default RatingQuestion;

import { classNames } from "../../lib/externalJS";

export default function Badge({
  children,
  color,
  textSize,
  isCloseIconVisible,
  isDot,
  isRounded,
}: {
  children?: React.ReactNode;
  color: string;
  textSize?: number;
  isCloseIconVisible?: boolean;
  isDot?: boolean;
  isRounded?: boolean;
}) {
  return (
    <div
      className={classNames(
        isRounded ? "rounded-full" : "rounded-md",
        "flex items-center px-2 py-1 font-medium ring-1 ring-inset " +
          getColorClasses(color) +
          " " +
          getTextClass(textSize ?? 12),
      )}
    >
      {isDot && (
        <div
          className={
            "mr-1 h-[6px] w-[6px] rounded-full " + getDotClasses(color)
          }
        />
      )}
      {children}
    </div>
  );
}

function getColorClasses(color: string) {
  const mapping: { [key: string]: string } = {
    green: "text-green-700 bg-green-50 ring-green-600/20",
    red: "text-red-700 bg-red-50 ring-red-600/10",
    gray: "text-gray-600 bg-gray-50 ring-gray-500/10",
    blue: "text-blue-700 bg-blue-50 ring-blue-700/10",
    yellow: "text-yellow-800 bg-yellow-50 ring-yellow-600/20",
    amber: "text-amber-700 bg-amber-50 ring-amber-600/20",
  };
  return (
    mapping[color] ?? `text-${color}-700 bg-${color}-50 ring-${color}-600/20`
  );
}

function getDotClasses(color: string) {
  const mapping: { [key: string]: string } = {
    green: "bg-green-500",
    red: "bg-red-500",
    amber: "bg-amber-500",
  };
  return mapping[color] ?? `bg-${color}-50`;
}

function getTextClass(textSize: number) {
  const mapping: { [key: string]: string } = {
    10: "text-[10px]",
    12: "text-xs",
    14: "text-sm",
    16: "text-base",
  };
  return mapping[textSize] ?? "text-xs";
}

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { DefaultValues, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { surveyJSON } from "../../survey_json";
import { surveyElementsInterface } from "../../Interfaces/SurveyInterfaces";
import SmallTextQuestion from "../../components/surveyReplayQuestions/SmallTextQuestion";
import LongTextQuestion from "../../components/surveyReplayQuestions/LongTextQuestion";
import SingleChoiceQuestion from "../../components/surveyReplayQuestions/singleChoiceQuestion/SingleChoiceQuestion";
import MultiChoiceQuestion from "../../components/surveyReplayQuestions/MultiChoiceQuestion";
import RankingQuestion from "../../components/surveyReplayQuestions/RankingQuestion";
import DataTableQuestion from "../../components/surveyReplayQuestions/DataTableQuestion";
import OpenableListQuestion from "../../components/surveyReplayQuestions/OpenableListQuestion";
import RatingQuestion from "../../components/surveyReplayQuestions/RatingQuestion";
import TitleQuestion from "../../components/surveyReplayQuestions/TitleQuestion";
import { LogicItem } from "../../components/Logic/Logic";

export type FormValues = {
  [key: string]: any;
};

const defaultValues: DefaultValues<FormValues> = {};

const SurveyPreview = () => {
  const {
    handleSubmit,
    register,
    control,
    getValues,
    setValue,
    trigger,
    watch,
    formState: { errors },
    clearErrors,
  } = useForm<FormValues>({
    defaultValues,
  });

  const [currentPage, setCurrentPage] = useState<number>(0);
  const location = useLocation();
  const surveyCalcJson = location.state ?? surveyJSON;
  const navigate = useNavigate();
  let submitRef = useRef<HTMLFormElement>(null);

  const watchAllFields = watch();

  logicFunction(surveyCalcJson, watchAllFields);

  useEffect(() => {
    let timeoutTime: number = 0;
    surveyCalcJson.pages?.[currentPage]?.elements.forEach((element: any) => {
      timeoutTime += element.maxTimeToFinishQuestion;
    });
    if (timeoutTime && timeoutTime !== 0) {
      const timeoutId = setTimeout(() => {
        if (
          currentPage === surveyCalcJson.pages?.length - 1 &&
          submitRef.current
        ) {
          submitRef.current.dispatchEvent(
            new Event("submit", { cancelable: true, bubbles: true }),
          );
        } else {
          setCurrentPage((prev) => prev + 1);
        }
      }, timeoutTime * 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [currentPage]);

  const handleNextPage = useCallback(() => {
    setCurrentPage((prevPage) =>
      surveyCalcJson.pages.findIndex(
        (page: { noVisible: boolean }, i: number) =>
          !page.noVisible && i > prevPage,
      ),
    );
  }, []);

  const handlePrevPage = useCallback(() => {
    setCurrentPage((prevPage) =>
      surveyCalcJson.pages.findLastIndex(
        (page: { noVisible: boolean }, i: number) =>
          !page.noVisible && i < prevPage,
      ),
    );
  }, []);

  const surveyQuestions = surveyCalcJson.pages?.flatMap(
    (page: any) => page.elements,
  );

  if (surveyQuestions)
    for (const question of surveyQuestions) {
      if (question.type !== "openableList") {
        defaultValues[question._id] = { value: "" };
      }
      if (question.title?.tr) {
        defaultValues[question._id] = {
          ...defaultValues[question._id],
          details: {
            questionTitle: question.title.tr,
          },
        };
      }
      if (
        (question as surveyElementsInterface).defaultChoice &&
        question.type !== "openableList"
      ) {
        defaultValues[question._id] = {
          ...defaultValues[question._id],
          value: (question as surveyElementsInterface).defaultChoice,
        };
      }
      if ((question as surveyElementsInterface).inputAreaDefaultValue) {
        if (question.type === "starRating") {
          defaultValues[question._id] = {
            ...defaultValues[question._id],
            value: (question as surveyElementsInterface).inputAreaDefaultValue
              ?.tr,
          };
        } else {
          defaultValues[question._id] = {
            ...defaultValues[question._id],
            value: (question as surveyElementsInterface).inputAreaDefaultValue,
          };
        }
      }
    }

  const endOfPage = useMemo(() => {
    return (index: number) =>
      surveyCalcJson.pages?.[currentPage].elements.length - 1 === index;
  }, [currentPage, surveyCalcJson]);

  /*   const errorControl = useMemo(() => {
      console.log(!isEmptyObject(errors));
      return !isEmptyObject(errors);
    }, [errors]); */

  const toSurveyCreator = () => {
    navigate("/", { state: location.state });
  };

  const onSubmit = async (data: any) => {
    try {
      /*       const response = await fetch("https://api.modsoft.com.tr/8080", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
  
        if (!response.ok) {
          throw new Error("İstek başarısız");
        }
        const responseData = await response.json();
        console.log("İstek başarılı:", responseData); */
      /*       alert(JSON.stringify(Object.values(data).map((item: any) => item.value)));
       */
      setCurrentPage(surveyCalcJson.pages?.length);
    } catch (error: any) {
      console.error("İstek hatası:", error.message);
    }
  };

  return (
    <>
      {Object.keys(errors).length > 0 && (
        <div className="flex h-9 w-full items-center justify-center bg-red-500 text-center text-white">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mr-2"
          >
            <g clipPath="url(#clip0_1346_14125)">
              <path
                d="M10.0013 6.66699V10.0003M10.0013 13.3337H10.0096M18.3346 10.0003C18.3346 14.6027 14.6037 18.3337 10.0013 18.3337C5.39893 18.3337 1.66797 14.6027 1.66797 10.0003C1.66797 5.39795 5.39893 1.66699 10.0013 1.66699C14.6037 1.66699 18.3346 5.39795 18.3346 10.0003Z"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_1346_14125">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
          Lütfen soruları kurallara uygun yanıtlayın!
        </div>
      )}
      <div className="surveyInitialContainer">
        <form
          className="surveyForm flex flex-col !shadow-lg"
          onSubmit={handleSubmit(onSubmit)}
          ref={submitRef}
        >
          <button
            type="button"
            className="w-1/4 border-2 text-left"
            onClick={() => toSurveyCreator()}
          >
            Geri
          </button>
          <div className="flex items-center justify-center">
            {!(currentPage === surveyCalcJson.pages?.length) && (
              <img
                src={
                  surveyCalcJson.logo64 ? surveyCalcJson.logo64 : "/persty.png"
                }
                className="h-12 w-20"
                alt="Persty"
              />
            )}
          </div>
          {currentPage === 0 && (
            <>
              <h2 className="text-xl font-semibold">
                {surveyCalcJson.title?.tr
                  ? surveyCalcJson.title.tr
                  : "Yeni Form"}
              </h2>
              <p className="text-gray-400">
                {surveyCalcJson.description?.tr
                  ? surveyCalcJson.description.tr
                  : "Lütfen Dikkatli Doldurunuz..."}
              </p>
              <hr className="text-gray-500" />
            </>
          )}
          {surveyCalcJson.pages?.length > currentPage
            ? surveyCalcJson.pages?.[currentPage].elements.map(
                (question: surveyElementsInterface, index: number) => {
                  if (question.noVisible) {
                    return null;
                  }
                  switch (question.type) {
                    case "textArea":
                      return (
                        <SmallTextQuestion
                          key={question._id}
                          register={register}
                          question={question}
                          setValue={setValue}
                          endOfPage={endOfPage(index)}
                          errors={errors}
                          questionIndex={index}
                        />
                      );
                    case "longTextArea":
                      return (
                        <LongTextQuestion
                          key={question._id}
                          register={register}
                          question={question}
                          setValue={setValue}
                          endOfPage={endOfPage(index)}
                          errors={errors}
                          questionIndex={index}
                        />
                      );
                    case "likert":
                      return (
                        <SingleChoiceQuestion
                          key={question._id}
                          question={question}
                          register={register}
                          endOfPage={endOfPage(index)}
                          questionIndex={index}
                          setValue={setValue}
                          getValues={getValues}
                          errors={errors}
                          clearErrors={clearErrors}
                          watch={watch}
                        />
                      );
                    case "multiChoice":
                      return (
                        <MultiChoiceQuestion
                          key={question._id}
                          question={question}
                          register={register}
                          setValue={setValue}
                          questionIndex={index}
                          endOfPage={endOfPage(index)}
                          errors={errors}
                          watch={watch}
                        />
                      );
                    case "singleChoice":
                      return (
                        <SingleChoiceQuestion
                          key={question._id}
                          question={question}
                          register={register}
                          questionIndex={index}
                          endOfPage={endOfPage(index)}
                          setValue={setValue}
                          getValues={getValues}
                          errors={errors}
                          clearErrors={clearErrors}
                          watch={watch}
                        />
                      );
                    case "ranking":
                      return (
                        <RankingQuestion
                          key={question._id}
                          questionIndex={index}
                          question={question}
                          register={register}
                          endOfPage={endOfPage(index)}
                          setValue={setValue}
                          getValues={getValues}
                          errors={errors}
                          trigger={trigger}
                        />
                      );
                    case "dataTable":
                      return (
                        <DataTableQuestion
                          key={question._id}
                          question={question}
                          endOfPage={endOfPage(index)}
                          control={control}
                          register={register}
                          setValue={setValue}
                          errors={errors}
                        />
                      );
                    case "openableList":
                      return (
                        <OpenableListQuestion
                          key={question._id}
                          control={control}
                          questionIndex={index}
                          question={question}
                          endOfPage={endOfPage(index)}
                          errors={errors}
                        />
                      );
                    case "starRating":
                      return (
                        <RatingQuestion
                          key={question._id}
                          question={question}
                          questionIndex={index}
                          endOfPage={endOfPage(index)}
                          setValue={setValue}
                          register={register}
                          getValues={getValues}
                          errors={errors}
                          trigger={trigger}
                        />
                      );
                    case "expText" ||
                      "title" ||
                      "chapterTitle" ||
                      "downChapterTitle":
                      return (
                        <RatingQuestion
                          key={question._id}
                          question={question}
                          questionIndex={index}
                          endOfPage={endOfPage(index)}
                          setValue={setValue}
                          register={register}
                          getValues={getValues}
                          errors={errors}
                          trigger={trigger}
                        />
                      );
                    default:
                      return (
                        <TitleQuestion
                          key={question._id}
                          question={question}
                          endOfPage={endOfPage(index)}
                        />
                      );
                  }
                },
              )
            : surveyCalcJson.pages &&
              surveyCalcJson.pages?.length === currentPage && (
                <div className="flex flex-col items-center justify-center">
                  <svg
                    width="72"
                    height="72"
                    viewBox="0 0 72 72"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="4"
                      y="4"
                      width="64"
                      height="64"
                      rx="28"
                      fill="#DCFAE6"
                    />
                    <rect
                      x="4"
                      y="4"
                      width="64"
                      height="64"
                      rx="28"
                      stroke="#ECFDF3"
                      strokeWidth="8"
                    />
                    <path
                      d="M29.25 36L33.75 40.5L42.75 31.5M51 36C51 44.2843 44.2843 51 36 51C27.7157 51 21 44.2843 21 36C21 27.7157 27.7157 21 36 21C44.2843 21 51 27.7157 51 36Z"
                      stroke="#00C292"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <h4>Teşekkürler</h4>
                  <p>Formunuz başarıyla gönderildi.</p>
                </div>
              )}
          {currentPage === surveyCalcJson.pages?.length && (
            <>
              <hr />
              <div className="flex items-center justify-center">
                <p className="mb-0 ml-[-3rem] text-center">
                  FormCooker ile kolayca kendi formunuzu oluşturun. Hem de
                  ücretsiz!
                </p>
                <button
                  type="button"
                  onClick={() => {
                    navigate("/createSurvey/" + surveyCalcJson._id);
                  }}
                  style={{
                    display:
                      currentPage === surveyCalcJson.pages?.length
                        ? "block"
                        : "none",
                  }}
                  className="ml-2 mr-[-3rem] h-10 rounded bg-[#2970ff] px-4 py-2 text-center align-middle text-sm text-white hover:bg-blue-700"
                >
                  Oluştur
                </button>
              </div>
            </>
          )}
          <div
            className={`mt-4 justify-between ${
              currentPage === surveyCalcJson.pages?.length ? "hidden" : "flex"
            }`}
          >
            <button
              type="button"
              onClick={handlePrevPage}
              style={{
                visibility:
                  currentPage === 0 ||
                  currentPage === surveyCalcJson.pages?.length
                    ? "hidden"
                    : "visible",
              }}
              className="btn btn-outline-dark rounded px-5 py-2"
            >
              Geri
            </button>
            <button
              type="submit"
              style={{
                display:
                  currentPage === surveyCalcJson.pages?.length - 1
                    ? "block"
                    : "none",
              }}
              className="btn btn-success rounded px-4 py-2"
            >
              Gönder
            </button>
            <button
              type="button"
              onClick={() => {
                handleNextPage();
              }}
              style={{
                display:
                  currentPage === surveyCalcJson.pages?.length - 1 ||
                  surveyCalcJson.pages === undefined ||
                  currentPage === surveyCalcJson.pages?.length
                    ? "none"
                    : "block",
              }}
              className="rounded bg-[#2970ff] px-5 py-2 text-center align-middle text-sm text-white hover:bg-blue-700"
            >
              Sonraki
            </button>
          </div>
          {surveyCalcJson.pages === undefined && (
            <div className="text-center text-4xl text-red-700">
              Hiç Soru Eklemediniz
            </div>
          )}
          <div className="my-3 text-center">
            {!(currentPage === surveyCalcJson.pages?.length) && (
              <>
                <b>FormCooker</b> tarafından desteklenmektedir.
              </>
            )}
          </div>
          {surveyCalcJson.pages?.length > currentPage &&
            surveyCalcJson.pages
              .flatMap((page: any) => page.elements)
              .map((hiddenQuestion: any, index: number) => (
                <div key={hiddenQuestion._id} style={{ display: "none" }}>
                  {hiddenQuestion.noVisible
                    ? null
                    : (() => {
                        switch (hiddenQuestion.type) {
                          case "textArea":
                            return (
                              <SmallTextQuestion
                                key={hiddenQuestion._id}
                                register={register}
                                question={hiddenQuestion}
                                setValue={setValue}
                                endOfPage={endOfPage(index)}
                                errors={errors}
                                questionIndex={index}
                              />
                            );
                          case "longTextArea":
                            return (
                              <LongTextQuestion
                                key={hiddenQuestion._id}
                                register={register}
                                question={hiddenQuestion}
                                setValue={setValue}
                                endOfPage={endOfPage(index)}
                                errors={errors}
                                questionIndex={index}
                              />
                            );
                          case "likert":
                            return (
                              <SingleChoiceQuestion
                                key={hiddenQuestion._id}
                                question={hiddenQuestion}
                                register={register}
                                endOfPage={endOfPage(index)}
                                setValue={setValue}
                                questionIndex={index}
                                getValues={getValues}
                                errors={errors}
                                clearErrors={clearErrors}
                                watch={watch}
                              />
                            );
                          case "multiChoice":
                            return (
                              <MultiChoiceQuestion
                                key={hiddenQuestion._id}
                                question={hiddenQuestion}
                                register={register}
                                setValue={setValue}
                                endOfPage={endOfPage(index)}
                                errors={errors}
                                questionIndex={index}
                                watch={watch}
                              />
                            );
                          case "singleChoice":
                            return (
                              <SingleChoiceQuestion
                                key={hiddenQuestion._id}
                                question={hiddenQuestion}
                                register={register}
                                endOfPage={endOfPage(index)}
                                questionIndex={index}
                                setValue={setValue}
                                getValues={getValues}
                                errors={errors}
                                clearErrors={clearErrors}
                                watch={watch}
                              />
                            );
                          case "ranking":
                            return (
                              <RankingQuestion
                                key={hiddenQuestion._id}
                                question={hiddenQuestion}
                                register={register}
                                questionIndex={index}
                                endOfPage={endOfPage(index)}
                                setValue={setValue}
                                getValues={getValues}
                                errors={errors}
                                trigger={trigger}
                              />
                            );
                          case "dataTable":
                            return (
                              <DataTableQuestion
                                key={hiddenQuestion._id}
                                question={hiddenQuestion}
                                endOfPage={endOfPage(index)}
                                control={control}
                                register={register}
                                setValue={setValue}
                                errors={errors}
                              />
                            );
                          case "openableList":
                            return (
                              <OpenableListQuestion
                                key={hiddenQuestion._id}
                                control={control}
                                questionIndex={index}
                                question={hiddenQuestion}
                                endOfPage={endOfPage(index)}
                                errors={errors}
                              />
                            );
                          case "starRating":
                            return (
                              <RatingQuestion
                                key={hiddenQuestion._id}
                                question={hiddenQuestion}
                                endOfPage={endOfPage(index)}
                                setValue={setValue}
                                register={register}
                                getValues={getValues}
                                questionIndex={index}
                                errors={errors}
                                trigger={trigger}
                              />
                            );
                          case "expText":
                          case "title":
                          case "chapterTitle":
                          case "downChapterTitle":
                            return (
                              <RatingQuestion
                                key={hiddenQuestion._id}
                                question={hiddenQuestion}
                                endOfPage={endOfPage(index)}
                                questionIndex={index}
                                setValue={setValue}
                                register={register}
                                getValues={getValues}
                                errors={errors}
                                trigger={trigger}
                              />
                            );
                          default:
                            return (
                              <TitleQuestion
                                key={hiddenQuestion._id}
                                question={hiddenQuestion}
                                endOfPage={endOfPage(index)}
                              />
                            );
                        }
                      })()}
                </div>
              ))}
        </form>
      </div>
    </>
  );
};

function logicFunction(surveyCalcJson: any, watchAllFields: FormValues) {
  console.log("SurveyPreview -> watchAllFields", watchAllFields);
  console.log("SurveyPreview -> surveyCalcJson", surveyCalcJson);

  console.log("SurveyPreview -> surveyCalcJson.logic", surveyCalcJson.logic);

  surveyCalcJson.logic
    ?.filter((x: LogicItem) => !x.isDisabled)
    .forEach((logicItem: LogicItem) => {
      const checkCondition = (ifItem: LogicItem["ifs"][0]) => {
        const {
          questionId,
          questionType,
          condition,
          value: conditionValue,
        } = ifItem;

        const denemeFunc = (watchedValue: any) => {
          console.log("denemeFunc -> watchedValue", watchedValue);
          console.log("denemeFunc -> conditionValue", conditionValue);

          switch (condition) {
            case "IS_EQUAL_TO":
              return Array.isArray(watchedValue)
                ? watchedValue?.includes(conditionValue)
                : watchedValue == conditionValue;
            case "IS_NOT_EQUAL_TO":
              return Array.isArray(watchedValue)
                ? !watchedValue?.includes(conditionValue)
                : watchedValue != conditionValue;
            case "IS_ANY_OF":
              return Array.isArray(conditionValue)
                ? conditionValue.some((val: string) =>
                    watchedValue?.includes(val),
                  )
                : watchedValue?.includes(conditionValue);
            case "IS_NOT_ANY_OF":
              return Array.isArray(conditionValue)
                ? !conditionValue.some((val: string) =>
                    watchedValue?.includes(val),
                  )
                : !watchedValue?.includes(conditionValue);
            case "IS_FILLED":
              return watchedValue !== "";
            case "IS_EMPTY":
              return watchedValue === "";
            case "IS_GREATER_THAN":
              return watchedValue && +watchedValue > +conditionValue;
            case "IS_LESS_THAN":
              return watchedValue && +watchedValue < +conditionValue;
            default:
              return false;
          }
        };

        if (watchAllFields[questionId]) {
          console.log("aaaaaaaSurveyPreview");

          if (questionType !== "dataTable") {
            const watchedValue = watchAllFields[questionId].value;
            return denemeFunc(watchedValue);
          } else {
            const rowIds =
              ifItem.selectedRows ?? ifItem.dataTableRows?.map((x) => x._id);

            if (!rowIds) throw new Error("No rows found for condition");

            console.log("SurveyPreview -> rowIds", rowIds);
            console.log(
              "SurveyPreview -> ifItem.allOrSomeRows",
              ifItem.allOrSomeRows,
            );
            console.log(
              "SurveyPreview -> questionId + 'dataTable' + rowId",
              questionId + "dataTable" + rowIds[0],
            );

            const result =
              ifItem.allOrSomeRows === "allRows" ||
              ifItem.allOrSomeRows === "selectedRowsAll"
                ? rowIds.every((rowId) =>
                    denemeFunc(
                      watchAllFields[questionId + "dataTable" + rowId]?.value,
                    ),
                  )
                : rowIds.some((rowId) =>
                    denemeFunc(
                      watchAllFields[questionId + "dataTable" + rowId]?.value,
                    ),
                  );

            return result;
          }
        }
        return false;
      };

      const result =
        logicItem.allAny === "ALL"
          ? logicItem.ifs.every(checkCondition)
          : logicItem.ifs.some(checkCondition);

      console.log("SurveyPreview -> result", result);

      logicItem.dos.forEach((doItem) => {
        console.log("SurveyPreview -> doItem", doItem);
        const findThenSetField = ({
          feature = "visibility",
          value,
        }: {
          feature?: "visibility" | "required" | "mask";
          value: boolean;
        }) => {
          const targets = Array.isArray(doItem.targetId)
            ? doItem.targetId
            : [doItem.targetId];
          console.log(
            "SurveyPreviewSurveyPreviewSurveyPreview -> targets",
            targets,
          );

          targets.forEach((target) => {
            const found = surveyCalcJson.pages
              ?.flatMap((page: any) => [page, ...page.elements])
              .find((element: any) => element._id === target);

            if (found) {
              console.log(
                "SurveyPreviewSurveyPreviewSurveyPreview -> found",
                found,
              );

              if (feature === "visibility") {
                found.noVisible = !value;
              } else if (feature === "required") {
                found.isRequired = value;
              } else if (feature === "mask") {
                if (value) {
                  if (!found.initialInputMask) {
                    found.initialInputMask = found.inputMask;
                  }
                  found.inputMask = doItem.conditionMask;
                } else {
                  if (found.initialInputMask) {
                    found.inputMask = found.initialInputMask;
                  }
                  found.initialInputMask = undefined;
                }
              }
            }
          });
        };

        switch (doItem.action) {
          case "SHOW":
            findThenSetField({ value: result });
            break;
          case "HIDE":
            findThenSetField({ value: !result });
            break;
          case "SHOW_MULTIPLE":
            findThenSetField({ value: result });
            break;
          case "HIDE_MULTIPLE":
            findThenSetField({ value: !result });
            break;
          case "SHOW_PAGE":
            findThenSetField({ value: result });
            break;
          case "HIDE_PAGE":
            findThenSetField({ value: !result });
            break;
          case "MAKE_REQUIRED":
            findThenSetField({ feature: "required", value: result });
            break;
          case "MAKE_NOT_REQUIRED":
            findThenSetField({ feature: "required", value: !result });
            break;
          case "SET_MASK":
            findThenSetField({ feature: "mask", value: result });
            break;
          default:
            break;
        }
      });
    });
}

export default SurveyPreview;

import companyData from "data/companyData.json";
import PulseIcon from "images/sidebarIcons/Pulse";
import { getSortedDegerlendirme } from "pages/dashboard2/EmployeeProfileHighlights";
import {
  PanelCardChild,
  PanelSplineGraph,
  SERIES_COLORS,
  getSeriesAndCategories,
} from "pages/dashboard2/Panel/Panel";
import CommonTable from "pages/dashboard2/Performance/CommonTable";
import { TimeFilters } from "pages/dashboard2/Performance/Performance";

export default function Pulse() {
  return (
    <div className="flex flex-col">
      {/* page title */}
      <div className="mb-[30px] flex items-center">
        <div className="p-1.5 mr-3 inline-flex h-9 w-9 items-center justify-center rounded bg-rose-100">
          <PulseIcon aria-hidden="true" isForTitle />
        </div>

        <div className="inline-flex text-2xl font-semibold leading-normal text-gray-800">
          Nabız
        </div>
      </div>

      <PanelCardChild span={4}>
        <TimeFilters color={"rose"} />

        <div className="-mx-3">
          <PanelSplineGraph
            {...getSeriesAndCategories(
              getSortedDegerlendirme(companyData.Mutluluk),
              "Mutluluk",
            )}
            colors={SERIES_COLORS}
            isBigger
          />
        </div>
      </PanelCardChild>

      <CommonTable forTable="nabiz" />
    </div>
  );
}

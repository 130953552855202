import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/20/solid";
import { FilterOption, Person } from "Interfaces/general";
import talentData from "data/talentData.json";
import { classNames } from "lib/externalJS";
import { Progress } from "pages/dashboard2/Dashboard2";
import { getStartedDate, groupBy } from "pages/dashboard2/Employees/Workers";
import Filter from "pages/dashboard2/TalentManager/Filter";
import { useMemo, useState } from "react";

export default function CommonTable({ forTable }: { forTable?: string }) {
  const people = useMemo(() => talentData as any[], [talentData]);

  const [filteredPeople, setFilteredPeople] = useState<Person[]>(people);
  const [sortOrder, setSortOrder] = useState("none");

  const handleFilterChange = (filters: FilterOption[]) => {
    if (filters.length === 0) {
      setFilteredPeople(people);
      return;
    }

    const filtersGrouped = groupBy(filters, "id");

    const updatedPeople = people.filter((person: any) => {
      return Object.keys(filtersGrouped).every((key) => {
        return filtersGrouped[key].some((filter: any) => {
          switch (filter.id) {
            case "departman":
              return person.departman === filter.value;
            case "pozisyon":
              return person.pozisyon === filter.value;
            case "cinsiyet":
              return person.cinsiyet === filter.value;
            case "yas":
              const splittedYaslar = filter.value.split("-");
              const personYas = parseInt(person.yas);
              return (
                personYas >= parseInt(splittedYaslar[0]) &&
                personYas <= parseInt(splittedYaslar[1])
              );
            default:
              return false;
          }
        });
      });
    });

    setFilteredPeople(updatedPeople);
  };

  const handleSortClick = () => {
    if (sortOrder === "none") {
      setSortOrder("asc");
    } else if (sortOrder === "asc") {
      setSortOrder("desc");
    } else {
      setSortOrder("none");
    }
  };

  const SORTED_PEOPLE = [...filteredPeople].sort((a, b) => {
    const adSoyadA = a.ad + " " + a.soyad;
    const adSoyadB = b.ad + " " + b.soyad;
    if (sortOrder === "asc") {
      return adSoyadA.localeCompare(adSoyadB);
    } else if (sortOrder === "desc") {
      return adSoyadB.localeCompare(adSoyadA);
    } else {
      return 0;
    }
  });

  return (
    <div className="mt-5">
      <Filter
        people={SORTED_PEOPLE}
        originalPeople={people}
        onFilterChange={handleFilterChange}
      />

      <div>
        {/* table header */}
        <div className="mt-7 flex rounded-t-xl border border-gray-200 bg-gray-50 text-xs font-medium leading-[18px] text-gray-500">
          <div className="flex w-[350px] items-center px-6 py-3">
            <span className="cursor-pointer" onClick={handleSortClick}>
              Çalışanlar
            </span>
            <span onClick={handleSortClick} className="cursor-pointer pl-1">
              {sortOrder !== "none" && (
                <>
                  {sortOrder === "asc" ? (
                    <ArrowDownIcon className="h-4" />
                  ) : (
                    <ArrowUpIcon className="h-4" />
                  )}
                </>
              )}
            </span>
          </div>

          <div className="flex w-[250px] items-center border-x border-gray-200 px-4 py-3">
            Departman
          </div>

          <div className="flex w-[150px] items-center border-r border-gray-200 px-4 py-3">
            Başlangıç Tarihi
          </div>

          <div className="flex items-center px-4 py-3">Skor</div>
        </div>

        {/* table items */}
        {SORTED_PEOPLE.map((p, index) => {
          const scoreToDisplay = getScoreToDisplay(forTable, p);

          const scoreForProgress = getScoreForProgress(
            forTable,
            scoreToDisplay,
          );

          return (
            <div
              className={classNames(
                "flex border-x border-b border-gray-200 bg-white text-sm font-medium leading-tight text-gray-800",
                index === SORTED_PEOPLE.length - 1 ? "rounded-b-xl" : "",
              )}
              key={index}
            >
              {/* Çalışanlar */}
              <div className="w-[350px] px-6 py-4">
                <div className="flex items-center">
                  <img
                    className="mr-3 h-8 w-8 rounded-full"
                    src={"/avatars/" + p.id + ".png"}
                    alt=""
                    onError={(e) => {
                      (e.target as any).src = "/avatars/default.png";
                    }}
                  />

                  <div>
                    <div className="text-sm font-medium leading-tight text-gray-800">
                      {p.ad + " " + p.soyad}
                    </div>

                    <div className="text-xs font-normal leading-[18px] text-slate-600">
                      {p.pozisyon}
                    </div>
                  </div>
                </div>
              </div>

              {/* Departman */}
              <div className="w-[250px] border-x border-gray-200 p-4 text-sm leading-tight">
                <div className="font-medium text-gray-900">{p.departman}</div>

                <div
                  className={classNames(
                    "font-normal text-slate-600",
                    p.birim === undefined ? "opacity-0" : "",
                  )}
                >
                  {p.birim ?? "-"}
                </div>
              </div>

              {/* Başlangıç Tarihi */}
              <div className="flex w-[150px] items-center border-r border-gray-200 p-4 text-sm font-medium leading-tight text-gray-900">
                {getStartedDate(p.id)}
              </div>

              {/* Skor */}
              <div className="flex items-center p-4">
                <span className="mr-2 flex w-[26px] text-center text-sm font-semibold text-black">
                  {scoreToDisplay}
                </span>

                <div className="w-[180px]">
                  <Progress
                    isLabelsHidden
                    color="blue"
                    value={String(scoreForProgress)}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

function getScoreToDisplay(forTable: string | undefined, p: Person) {
  switch (forTable) {
    case "performans":
      return +p["degerlendirme_performans"][0].score;
    case "adminActivity":
      return +p["degerlendirme_yöneticiEtkinliği"][0].score;
    case "calisanMemnuniyet":
      return +p["degerlendirme_çalışanMemnuniyetSkoru"][0].score;
    case "esat":
      return +p["degerlendirme_e-SAT"][0].score;
    case "adanmislik":
      return +p["degerlendirme_adanmışlık"][0].score;
    case "nabiz":
      return +p["degerlendirme_mutluluk"][0].score;
    default:
      return 0;
  }
}

function getScoreForProgress(
  forTable: string | undefined,
  scoreToDisplay: number,
) {
  switch (forTable) {
    case "performans":
      return scoreToDisplay;
    case "adminActivity":
      return scoreToDisplay;
    case "calisanMemnuniyet":
      return scoreToDisplay * 10;
    case "esat":
      return scoreToDisplay * 20;
    case "adanmislik":
      return scoreToDisplay * 20;
    case "nabiz":
      return scoreToDisplay * 20;
    default:
      return 0;
  }
}

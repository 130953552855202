import FormSettingsWidth from "../../components/FormSettingsWidth";
import FormSettingsHeight from "../../components/FormSettingsHeight";
import FormSettingsEnterLimit from "../../components/FormSettingsEnterLimit";
import FormSettingsInputRestore from "../../components/FormSettingsInputRestore";
import FormSettingsInputVerify from "../../components/FormSettingsInputVerify";

export default function LongTextOptionSettings() {
  return (
    <>
      <FormSettingsWidth />
      <FormSettingsHeight />
      <FormSettingsEnterLimit />
      <FormSettingsInputRestore />
      <FormSettingsInputVerify />
    </>
  );
}

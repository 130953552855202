export default function ArrowUpIcon({ isPositive }: { isPositive: boolean }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M8.00016 12.6666V3.33325M8.00016 3.33325L3.3335 7.99992M8.00016 3.33325L12.6668 7.99992"
        stroke={isPositive ? "#47CD89" : "#F97066"}
        stroke-width="1.6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

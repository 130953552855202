import FormSettingsTip from "../../components/FormSettingsTip";
import FormSettingsTimeCounter from "../../components/FormSettingsTimeCounter";
import FormSettingsCollapse from "../../components/FormSettingsCollapse";
import FormSettingsReadOnly from "../../components/FormSettingsReadOnly";
import FormSettingsAddToLibrary from "../../components/FormSettingsAddToLibrary";
import FormSettingsOptionTipsForDT from "../../components/FormSettingsOptionTipsForDT";

export default function DataTableAdvanceSettings() {
  return (
    <>
      <FormSettingsTip />
      <FormSettingsOptionTipsForDT />
      <FormSettingsTimeCounter />
      <FormSettingsCollapse />
      <FormSettingsReadOnly />
      <FormSettingsAddToLibrary />
    </>
  );
}

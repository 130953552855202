import FormSettingsSelectLibrary from "../../components/FormSettingsSelectLibrary";
import FormSettingsHighest from "../../components/FormSettingsHighest";
import FormSettingsLowestRankingText from "../../components/FormSettingsLowestRankingText";
import CSATScoring from "../../components/SpecialRankings/CSATScoring";

export default function CSATFormOptionSettings() {
  return (
    <>
      <FormSettingsLowestRankingText />
      <FormSettingsHighest />
      <CSATScoring />
      <FormSettingsSelectLibrary />
    </>
  );
}

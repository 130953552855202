import { dashboardModalValueType } from "./DashboardCards";
import { ArrowLongLeftIcon, XMarkIcon } from "@heroicons/react/20/solid";
import PrimaryButton from "../../components/tailwindUi/PrimaryButton";
import Templates from "pages/dashboard2/Templates/Templates";

export default function PickTemplateDialog({
  setModalValue,
}: {
  setModalValue: React.Dispatch<React.SetStateAction<dashboardModalValueType>>;
}) {
  return (
    <>
      <div className="flex h-full flex-col">
        <div className="-mx-6 -mt-1 mb-9 grid border-b border-gray-200 pb-5">
          <div
            className="flex items-center justify-center"
            style={{ gridColumn: 1, gridRow: 1 }}
          >
            <span className="text-2xl font-semibold leading-8 text-slate-600">
              Test Seç
            </span>
          </div>
          <div
            className="flex items-center justify-between px-7"
            style={{ gridColumn: 1, gridRow: 1 }}
          >
            <PrimaryButton
              isSecondary
              isGray
              isMinWidth
              isLessYpadding
              onClick={() => setModalValue("new")}
            >
              <div className="flex items-center">
                <ArrowLongLeftIcon className="mr-2 h-4 w-4" />
                <span className="text-sm font-semibold leading-loose text-gray-500">
                  Geri
                </span>
              </div>
            </PrimaryButton>
            <XMarkIcon
              className="h-8 w-8 cursor-pointer text-gray-500"
              aria-hidden="true"
              onClick={() => setModalValue("")}
            />
          </div>
        </div>

        <Templates />
      </div>
    </>
  );
}

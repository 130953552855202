import { useEffect, useState } from "react";
import Checkbox from "../../components/tailwindUi/Checkbox";
import PrimaryButton from "../../components/tailwindUi/PrimaryButton";
import Select, { SelectItemType } from "../../components/tailwindUi/Select";
import Rocket from "../../images/rocket.svg";
import { SettingsCard, SettingsCardBtnContainer } from "./SettingsProfile";

const fromServerDataCenters = [
  { value: "us", text: "United States" },
  { value: "tr", text: "Türkiye", selected: true },
  { value: "de", text: "Germany", disabled: true },
];

const fromServerIsGdprDataCenter = false;

interface FormValues {
  dataCenter?: string;
  isGdprDataCenter: boolean;
}

export default function SettingsData() {
  const [initialFormValues, setInitialFormValues] = useState<FormValues>();
  const [formValues, setFormValues] = useState<FormValues>();

  const [dataCenters, setDataCenters] = useState<Array<SelectItemType>>();

  useEffect(() => {
    // TODO: fetch user data
    const fetchedDataCenters = fromServerDataCenters;
    const fetchedIsGdprDataCenter = fromServerIsGdprDataCenter;

    setDataCenters(fetchedDataCenters);

    const temp = {
      dataCenter: fetchedDataCenters.find((dc) => dc.selected)?.value,
      isGdprDataCenter: fetchedIsGdprDataCenter,
    };

    setInitialFormValues(temp);
    setFormValues(temp);
  }, []);

  const isFormChanged =
    JSON.stringify(formValues) !== JSON.stringify(initialFormValues);

  return (
    <SettingsCard title="Verileriniz">
      <div className="divide-y divide-white/5">
        <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 md:grid-cols-3">
          <form className="md:col-span-3">
            <div className="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <Select
                  label="Kurumsal Veri Merkezleri"
                  labelIcon={
                    <img className="h-4 w-4" src={Rocket} aria-hidden />
                  }
                  id="enterpriseDataCenter"
                  items={dataCenters ?? []}
                  onChange={(selected) =>
                    setFormValues((prev) => ({
                      ...prev,
                      dataCenter: (selected as SelectItemType).value,
                      isGdprDataCenter: prev?.isGdprDataCenter ?? false,
                    }))
                  }
                />
              </div>

              <div className="hidden sm:col-span-3 sm:block"></div>

              <div className="sm:col-span-3">
                <Checkbox
                  upperLabel="GDPR Veri Merkezi"
                  checkboxLabelComp={
                    <div className="text-sm leading-tight text-slate-700">
                      Verilerimi
                      <span className="font-bold">
                        {" sadece Avrupa (Almanya) "}
                      </span>
                      merkezlerinde depola
                    </div>
                  }
                  name="gdprDataCenter"
                  id="gdprDataCenter"
                  checked={formValues?.isGdprDataCenter ?? false}
                  onChange={(event) =>
                    setFormValues((prev) => ({
                      ...prev,
                      isGdprDataCenter: event.target.checked,
                    }))
                  }
                />
              </div>
            </div>

            <SettingsCardBtnContainer>
              <PrimaryButton isMinWidth isSecondary isGray>
                Vazgeç
              </PrimaryButton>
              <PrimaryButton disabled={!isFormChanged} isMinWidth>
                Değişiklikleri Kaydet
              </PrimaryButton>
            </SettingsCardBtnContainer>
          </form>
        </div>
      </div>
    </SettingsCard>
  );
}

import FormSettingsTitle from "../../components/FormSettingsTitle";
import FormSettingsDescription from "../../components/FormSettingsDescription";
import FormSettingsTicketPosition from "../../components/FormSettingsTicketPosition";
import FormSettingsRequired from "../../components/FormSettingsRequired";
import FormSettingsBottomTicket from "../../components/FormSettingsBottomTicket";
import FormSettingsCopy from "../../components/FormSettingsCopy";
import FormSettingsQuestionFix from "../../components/FormSettingsQuestionFix";
import FormSettingsHide from "../../components/FormSettingsHide";

export default function LikertGeneralSettings() {
  return (
    <>
      <FormSettingsTitle />
      <FormSettingsDescription />
      <FormSettingsTicketPosition />
      <FormSettingsRequired />
      <FormSettingsBottomTicket />
      <FormSettingsCopy />
      <FormSettingsQuestionFix />
      <FormSettingsHide />
    </>
  );
}

import { ChangeEvent, useEffect, useState } from "react";
import { Switch } from "@headlessui/react";
import { classNames } from "../../../lib/externalJS";
import { useAtom, useAtomValue } from "jotai";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../atoms/surveyAtoms";

const FormSettingsColumnWidth = () => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const [localState, setLocalState] = useState({
    columnWidth:
      stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]?.columnWidth,
  });
  const [switchState, setSwitchState] = useState(
    Boolean(
      stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]?.columnWidth,
    ),
  );

  const handleWidthChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setLocalState((prevState) => {
      const newState = { ...prevState };
      newState.columnWidth = parseInt(newValue);
      return newState;
    });
  };

  useEffect(() => {
    setStateSurveyJSON((prevState) => {
      let newState = { ...prevState };
      newState.pages![pageIndex]!.elements![elementIndex]!.columnWidth =
        localState.columnWidth;
      return newState;
    });
  }, [localState]);

  useEffect(() => {
    setLocalState(() => {
      let localStateObject = {
        columnWidth:
          stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
            ?.columnWidth,
      };
      return localStateObject;
    });
  }, [
    stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]?.columnWidth,
  ]);

  return (
    <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
      <label className="mb-2 block text-sm font-bold text-gray-500">
        Sütun Genişliği
      </label>
      <div className="mt-3 flex">
        <Switch
          id="flexSwitchCheckshuffleOptions"
          checked={switchState}
          onChange={(e) => {
            setSwitchState(e);
            !e &&
              setLocalState({
                columnWidth: undefined,
              });
          }}
          className={classNames(
            switchState ? "bg-blue-600" : "bg-gray-200",
            "relative mr-2 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2",
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={classNames(
              switchState ? "translate-x-5" : "translate-x-0",
              "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
            )}
          />
        </Switch>
        <label
          className="text-gray-300"
          htmlFor="flexSwitchCheckshuffleOptions"
        >
          {switchState ? "Sabit" : "Otomatik"}
        </label>
      </div>
      <div className="mt-3" style={{ display: switchState ? "block" : "none" }}>
        <div className="flex">
          <div className="relative w-32">
            <input
              type="number"
              className="z-20 block w-24 rounded-l-lg border border-gray-300 bg-white p-[10px] text-sm text-gray-900 outline-none"
              value={localState.columnWidth ? localState.columnWidth : ""}
              onChange={(e) => handleWidthChange(e)}
            />
            <button
              type="submit"
              className="absolute right-0 top-0 h-full w-8 cursor-default rounded-r-lg border bg-transparent p-1 text-sm font-medium text-stone-800 outline-none"
            >
              px
            </button>
          </div>
        </div>
      </div>
      <p className="mb-0 mt-3 text-sm text-stone-500">
        Bu alanın genişliği formunuzun genişliğine göre değişecektir
      </p>
    </div>
  );
};

export default FormSettingsColumnWidth;

import { ChangeEvent, useEffect, useState } from "react";
import Select from "react-select";
import { Switch } from "@headlessui/react";
import { classNames } from "../../../lib/externalJS";
import { useAtom, useAtomValue } from "jotai";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../atoms/surveyAtoms";

const FormSettingsEnterLimit = () => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const [localState, setLocalState] = useState({
    limitationMin:
      stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
        ?.limitationMin,
    limitationMax:
      stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
        ?.limitationMax,
    limitationType:
      stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
        ?.limitationType,
  });
  const [switchState, setSwitchState] = useState(
    Boolean(
      stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
        ?.limitationMin ||
        stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
          ?.limitationMax,
    ),
  );

  const handleminChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setLocalState((prevState) => {
      const newState = { ...prevState };
      newState.limitationMin = parseInt(newValue);
      return newState;
    });
  };

  const handlemaxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setLocalState((prevState) => {
      const newState = { ...prevState };
      newState.limitationMax = parseInt(newValue);
      return newState;
    });
  };

  const handleTrackChange = (e: any) => {
    setLocalState((prevState) => {
      const newState = { ...prevState };
      newState.limitationType = e;
      return newState;
    });
  };

  useEffect(() => {
    setStateSurveyJSON((prevState) => {
      let newState = { ...prevState };

      if (newState.pages && newState.pages[pageIndex]) {
        if (
          newState.pages[pageIndex].elements &&
          newState.pages[pageIndex].elements?.[elementIndex]
        ) {
          newState.pages[pageIndex].elements![elementIndex].limitationMin =
            localState.limitationMin;
          newState.pages[pageIndex].elements![elementIndex].limitationMax =
            localState.limitationMax;
          newState.pages[pageIndex].elements![elementIndex].limitationType =
            localState.limitationType;
        }
      }

      return newState;
    });
  }, [localState]);

  return (
    <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
      <label className="mb-2 block text-sm font-bold text-gray-500">
        Giriş Limitleri
      </label>
      <div className="mb-2 mt-3 flex">
        <Switch
          id="flexSwitchCheckenterLimit"
          checked={switchState}
          onChange={(e) => {
            setSwitchState(e);
            !e &&
              setLocalState({
                limitationMin: undefined,
                limitationMax: undefined,
                limitationType: undefined,
              });
          }}
          className={classNames(
            switchState ? "bg-blue-600" : "bg-gray-200",
            "relative mr-2 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2",
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={classNames(
              switchState ? "translate-x-5" : "translate-x-0",
              "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
            )}
          />
        </Switch>
        <label className="text-gray-300" htmlFor="flexSwitchCheckenterLimit">
          {switchState ? "Sabit" : "Otomatik"}
        </label>
      </div>
      <div style={{ display: switchState ? "block" : "none" }}>
        <div className="flex">
          <div>
            <input
              type="number"
              id="min"
              className="mr-2 block w-10 rounded-lg border border-l-2 border-gray-300 border-l-gray-100 bg-gray-50 p-2 text-sm text-gray-900 outline-none"
              value={localState.limitationMin ? localState.limitationMin : ""}
              onChange={(e) => handleminChange(e)}
            />
            <label
              htmlFor="min"
              className="mb-2 block text-center text-sm font-medium text-gray-900"
            >
              Min.
            </label>
          </div>
          <div>
            <input
              type="number"
              id="max"
              className="mr-2 block w-10 rounded-lg border border-l-2 border-gray-300 border-l-gray-100 bg-gray-50 p-2 text-sm text-gray-900 outline-none"
              value={localState.limitationMax ? localState.limitationMax : ""}
              onChange={(e) => handlemaxChange(e)}
            />
            <label
              htmlFor="max"
              className="mb-2 block text-center text-sm font-medium text-gray-900"
            >
              Maks.
            </label>
          </div>
          <div>
            <Select
              className="basic-single"
              placeholder="Seçiniz"
              isSearchable={false}
              options={[
                { value: 1, label: "Harf" },
                { value: 2, label: "Kelime" },
              ]}
              onChange={(e) => handleTrackChange(e?.value || "")}
            />
          </div>
        </div>
      </div>
      <p className="mb-0 mt-3 text-sm text-stone-500">
        Bu alana girilebilecek metin uzunluğu için alt ve üst limit belirleyin
      </p>
    </div>
  );
};

export default FormSettingsEnterLimit;

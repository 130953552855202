import { UrlData } from "../../Interfaces/SurveyInterfaces";
import axiosInstance from "../api";

export const getUrl = async (shortLink: string) => {
  return (
    await axiosInstance.get<UrlData>(`URL/GetURLByShortLink`, {
      params: { shortLink: shortLink },
    })
  ).data;
};

export const createUrl = async (data: UrlData) => {
  await axiosInstance.post("/URL/AddURL", {
    _id: data._id,
    shortURL: data.shortURL,
    examID: data.examID,
  });
};

export const updateUrl = async (data: any) => {
  await axiosInstance.put(`/URL/UpdateURL`, data);
};

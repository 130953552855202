import FormSettingsShuffle from "../../components/FormSettingsShuffle";
import FormSettingsTip from "../../components/FormSettingsTip";
import FormSettingsOptionTips from "../../components/FormSettingsOptionTips";
import FormSettingsTimeCounter from "../../components/FormSettingsTimeCounter";
import FormSettingsCollapse from "../../components/FormSettingsCollapse";
import FormSettingsReadOnly from "../../components/FormSettingsReadOnly";
import FormSettingsAddToLibrary from "../../components/FormSettingsAddToLibrary";
import FormSettingsSelectMulti from "../../components/FormSettingsSelectMulti";
import FormSettingsSelectLimit from "../../components/FormSettingsSelectLimit";

export default function OpenableListAdvanceSettings() {
  return (
    <>
      <FormSettingsSelectMulti />
      <FormSettingsShuffle />
      <FormSettingsSelectLimit />
      <FormSettingsTip />
      <FormSettingsOptionTips />
      <FormSettingsTimeCounter />
      <FormSettingsCollapse />
      <FormSettingsReadOnly />
      <FormSettingsAddToLibrary />
    </>
  );
}

import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import {
  EyeIcon,
  EyeSlashIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { useRef, useState } from "react";
import { classNames } from "../../lib/externalJS";

interface TextInputProps {
  label?: string;
  name: string;
  id: string;
  type?: "email" | "text" | "password" | "number" | "date";
  max?: number;
  disabled?: boolean;
  placeholder?: string;
  defaultValue?: string;
  errorMessage: string;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  onBlur?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  onClear?: any;
  labelRightSide?: React.ReactNode;
  isReadOnly?: boolean;
  value?: string;
  isMultiline?: boolean;
  hint?: string;
  isForSelectSearch?: boolean;
  preInnerIconSlot?: React.ReactNode;
  noAutoComplete?: boolean;
}

export default function TextInput({
  label,
  name,
  id,
  type = "text",
  max,
  disabled,
  placeholder,
  defaultValue,
  errorMessage,
  onChange,
  onBlur,
  onClear,
  labelRightSide,
  isReadOnly,
  value,
  isMultiline,
  hint,
  isForSelectSearch,
  preInnerIconSlot,
  noAutoComplete,
}: TextInputProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const handleClear = () => {
    onClear();
    inputRef.current?.focus();
  };

  const isError = errorMessage && errorMessage.length > 0;

  const [innerType, setInnerType] = useState(type);

  const Icon = innerType === "password" ? EyeSlashIcon : EyeIcon;

  const commonClassesForTextAreaAndInput = classNames(
    "py-[6px] block w-full disabled:bg-gray-100 sm:text-sm sm:leading-6 border-gray-300",
    isError
      ? "text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:border-gray-300 focus:ring-2 focus:ring-inset focus:ring-red-500"
      : "",
    isForSelectSearch ? "border-x-0 border-t-0" : "rounded-md border",
  );

  const isPreInnerIcon = preInnerIconSlot || isForSelectSearch;

  return (
    <div>
      {(label || labelRightSide) && (
        <div className="mb-2 flex items-center justify-between">
          {label && (
            <label
              htmlFor={id}
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              {label}
            </label>
          )}
          {labelRightSide}
        </div>
      )}
      <div
        className={classNames(
          "relative",
          !isForSelectSearch ? "rounded-md shadow-sm" : "",
        )}
      >
        {isMultiline ? (
          <textarea
            readOnly={isReadOnly}
            name={name}
            id={id}
            className={commonClassesForTextAreaAndInput}
            placeholder={placeholder}
            value={value}
            defaultValue={defaultValue}
            disabled={disabled}
            autoComplete={noAutoComplete ? "off" : undefined}
            aria-invalid={isError ? "true" : "false"}
            aria-describedby={isError ? `${id}-error` : undefined}
            onChange={onChange}
            onBlur={onBlur}
          />
        ) : (
          <>
            {isPreInnerIcon && (
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                {isForSelectSearch ? (
                  <MagnifyingGlassIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                ) : (
                  <div className="h-5 w-5 overflow-hidden">
                    {preInnerIconSlot}
                  </div>
                )}
              </div>
            )}
            <input
              readOnly={isReadOnly}
              type={innerType}
              name={name}
              id={id}
              className={classNames(
                commonClassesForTextAreaAndInput,
                type === "date" ? "pr-3" : "pr-10",
                isPreInnerIcon ? "pl-9" : "",
              )}
              placeholder={placeholder}
              value={value}
              defaultValue={defaultValue}
              min={type === "number" ? 0 : undefined}
              max={type === "number" && max ? max : undefined}
              disabled={disabled}
              autoComplete={noAutoComplete ? "off" : undefined}
              aria-invalid={isError ? "true" : "false"}
              aria-describedby={isError ? `${id}-error` : undefined}
              onChange={onChange}
              onBlur={onBlur}
              ref={inputRef}
            />
            {value && onClear && (
              <span
                onClick={handleClear}
                className="absolute right-2 top-1/2 -translate-y-1/2 transform cursor-pointer text-gray-400 hover:text-gray-700"
              >
                &times;
              </span>
            )}
          </>
        )}
        {isError && !isMultiline && type !== "password" && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
        {type === "password" && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3">
            <Icon
              className={`h-5 w-5 cursor-pointer ${
                isError ? "text-red-500" : "text-gray-600"
              }`}
              onClick={() =>
                setInnerType(innerType === "password" ? "text" : "password")
              }
            />
          </div>
        )}
      </div>
      {errorMessage && (
        <p
          className={"mt-1 text-sm leading-none text-red-600"}
          id={`${id}-error`}
        >
          {errorMessage}
        </p>
      )}
      {hint && (
        <p className="mt-2 text-xs font-normal leading-tight text-gray-500">
          {hint}
        </p>
      )}
    </div>
  );
}

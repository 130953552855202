import { useAtom, useAtomValue } from "jotai";
import { ChangeEvent } from "react";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../../atoms/surveyAtoms";

const DocumentFileTypes = () => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const handleWidthChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    setStateSurveyJSON((prevState) => {
      let newState = { ...prevState };
      newState.pages![pageIndex]!.elements![elementIndex]!.otherValue =
        newValue;
      return newState;
    });
  };

  return (
    <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
      <label
        className="block text-sm font-bold text-gray-500"
        htmlFor="enterQuestion"
      >
        Dosya Türleri
      </label>
      <textarea
        onChange={handleWidthChange}
        value={
          stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex].otherValue
        }
        className="mt-3 w-full resize-none rounded border border-gray-300 p-2"
      />
      <p className="mb-0 mt-3 text-sm text-stone-500">
        Kabul etmek istediğiniz dosya türlerini girin. Her dosya türünü virgülle
        ayırın.
      </p>
    </div>
  );
};
export default DocumentFileTypes;

import clsx from "clsx";
import { templatesSelectedCategoryFilterAtom } from "components/atoms/templatesAtoms";
import { useAtom } from "jotai";
import { Category } from "pages/dashboard2/Templates/data";

function PathFilterItem({ item }: { item: Category }) {
  const [selectedCategoryFilter, setSelectedCategoryFilter] = useAtom(
    templatesSelectedCategoryFilterAtom,
  );

  const isActive = selectedCategoryFilter === item._id;

  return (
    <button
      className={clsx(
        "group flex w-full cursor-pointer items-center gap-x-3 rounded-md p-2 pl-3 text-sm font-semibold leading-6",
        {
          "bg-gray-50 text-blue-600": isActive,
          "text-gray-700 hover:text-blue-600": !isActive,
        },
      )}
      onClick={(event) => {
        event.preventDefault();
        setSelectedCategoryFilter(item._id);
      }}
    >
      <span className="flex justify-start text-start">{item.title}</span>
      {item.newCount !== -1 && (
        <span
          className="px-2.5 py-0.5 ml-auto w-8 min-w-max whitespace-nowrap rounded bg-gray-50 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
          aria-hidden="true"
        >
          {item.newCount}
        </span>
      )}
    </button>
  );
}

export default function FilterListCategoryItem({
  filterItems,
}: {
  filterItems: Category[];
}) {
  return (
    <>
      {filterItems.map((item, i) => (
        <div key={i}>
          <PathFilterItem item={{ ...item, path: item.path }} />
        </div>
      ))}
    </>
  );
}

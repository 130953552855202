import { selectedUserAtom } from "components/atoms/dashboardAtoms";
import { useAtomValue } from "jotai";
import EmployeeProfileJobHarmony from "pages/dashboard2/EmployeeProfile/EmployeeProfileJobHarmony/EmployeeProfileJobHarmony";
import { useEffect, useState } from "react";
import AbcLogo from "../../../images/ABCInc.png";
import { classNames } from "../../../lib/externalJS";
import EmployeeProfileAbout from "../EmployeeProfileAbout";
import EmployeeProfileHighlights from "../EmployeeProfileHighlights";
import EmployeeProfileSkills from "../EmployeeProfileSkills";
import EmployeeProfileJobDescription from "./EmployeeProfileJobDescription/EmployeeProfileJobDescription";
import EmployeeProfileTeam from "./EmployeeProfileTeam/EmployeProfileTeam";

const TABS = Object.freeze([
  "Öne Çıkanlar",
  "Hakkımda",
  "Yetenekler",
  "İş Tanımı",
  "İş Uyumu",
  "Takım",
]);

export default function EmployeeProfile() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const selectedUser = useAtomValue(selectedUserAtom);

  const [currentTab, setCurrentTab] = useState(TABS[0]);

  if (!selectedUser) {
    return <>{"err"}</>;
  }

  const CurrentTabComp = () => {
    switch (currentTab) {
      case "Öne Çıkanlar":
        return <EmployeeProfileHighlights />;
      case "Hakkımda":
        return <EmployeeProfileAbout />;
      case "Yetenekler":
        return <EmployeeProfileSkills />;
      case "İş Tanımı":
        return <EmployeeProfileJobDescription />;
      case "İş Uyumu":
        return <EmployeeProfileJobHarmony />;
      case "Takım":
        return <EmployeeProfileTeam />;
      default:
        return null;
    }
  };

  return (
    <div className="">
      {/* EmployeeInfoHeader */}
      <div className="flex justify-between rounded-tl-[10px] rounded-tr-[10px] border border-gray-200 bg-white px-10 py-5">
        <div className="flex items-center gap-x-5">
          <img
            className="h-[82px] w-[82px] rounded-full"
            src={"/avatars/" + selectedUser.id + ".png"}
            alt=""
            onError={(e) => {
              (e.target as any).src = "/avatars/default.png";
            }}
          />

          <div className="flex flex-col">
            <span className="text-2xl font-bold text-gray-900">
              {selectedUser.ad + " " + selectedUser.soyad}
            </span>

            <span className="text-sm font-medium leading-tight text-gray-500">
              {selectedUser.isUnvani}
            </span>

            <span className="text-sm font-medium leading-tight text-gray-500">
              ABC A.Ş.
            </span>
          </div>
        </div>

        <img className="h-[82px]" src={AbcLogo} />
      </div>

      {/* Tabs */}
      <div className="flex h-12 border border-t-0 border-gray-200 bg-white">
        {TABS.map((tab) => (
          <button
            key={tab}
            onClick={() => setCurrentTab(tab)}
            className={classNames(
              "flex flex-1 items-center justify-center whitespace-nowrap border-b-[3px] text-sm font-medium",
              tab === currentTab
                ? "border-blue-500 text-blue-600"
                : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
            )}
          >
            {tab}
          </button>
        ))}
      </div>

      <CurrentTabComp />
    </div>
  );
}

import React from "react";
import { surveyElementsInterface } from "../../Interfaces/SurveyInterfaces";

type Props = {
  question: surveyElementsInterface;
  endOfPage: boolean;
};

const TitleQuestion: React.FC<Props> = ({ question, endOfPage }) => {
  return (
    <div
      className={`mx-auto ${question.collapse ? "w-1/2" : "w-full"} position`}
      data-title={question.tipText?.tr}
    >
      <h6
        className={`mb-0 ${
          question.textPosition === "Right"
            ? "text-Right"
            : question.textPosition === "Middle"
            ? "text-center"
            : "text-left"
        }`}
        dangerouslySetInnerHTML={{ __html: question.title?.tr ?? "" }}
      />
      <p className=" text-gray-500">{question.description?.tr}</p>
      <p className="mb-4 text-gray-500">{question.bottomTicket?.tr}</p>
      {!endOfPage && question?.startWithNewLine !== true && <hr />}
    </div>
  );
};

export default TitleQuestion;

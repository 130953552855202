import React, { useState } from "react";
import Analysis from "./components/analysis/Analysis";
import Responses from "./components/responses/Responses";
import { Switch } from "@headlessui/react";
import { classNames } from "../../lib/externalJS";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getSurveyReplys } from "../../services/api/surveyReplyApi";
import { ChartData } from "../../Interfaces/SurveyInterfaces";

const SurveyResult = () => {
  const [pageState, setPageState] = useState<number>(2);
  const { t } = useTranslation();
  const [showValues, setShowValues] = useState<boolean>(false);
  const { surveyId } = useParams<{ surveyId: string }>();

  const { isLoading, data, error } = useQuery({
    queryKey: ["SurveyReplaysBySurveyId"],
    queryFn: () => getSurveyReplys(surveyId ?? ""),
  });

  if (error) {
    return <div> Bir hata oluştu</div>;
  }

  if (isLoading) {
    return <div> lütfen bekleyiniz</div>;
  }
  const readDeal = data;

  if (
    !Array.isArray(readDeal) ||
    readDeal[0] === null ||
    readDeal[0] === undefined
  ) {
    return <div> Gösterilecek sonuç bulunmuyor</div>;
  }

  const chartDataArray: ChartData[] = [];
  (readDeal as any[]).forEach((data) => {
    for (const key in data.questionAnswers) {
      if (data.questionAnswers.hasOwnProperty(key)) {
        const answer = data.questionAnswers[key];

        if (answer.value && answer.value.length > 0) {
          const value = answer.value[0];

          const chartData = chartDataArray.find((item) => item.key === key);

          if (chartData) {
            const index = chartData.options.labels.indexOf(value);
            if (index === -1) {
              chartData.series.push(1);
              chartData.options.labels.push(value);
            } else {
              chartData.series[index]++;
            }
          } else {
            const newChartData: ChartData = {
              key,
              series: [1],
              options: {
                chart: {
                  width: 380,
                  type: "pie",
                },
                labels: [value],
                responsive: [
                  {
                    breakpoint: 480,
                    options: {
                      chart: {
                        width: 200,
                      },
                      legend: {
                        position: "bottom",
                      },
                    },
                  },
                ],
              },
            };
            chartDataArray.push(newChartData);
          }
        }
      }
    }
  });

  return (
    <div className="flex flex-col justify-start items-center py-4 min-h-screen bg-white">
      <div className="my-4 flex items-center justify-center">
        <div className=" w-[350px] inline-flex  p-[2px] rounded-xl cursor-pointer bg-gray-200 text-gray-800">
          <span
            className={`p-2  w-44 rounded-xl h-9 text-center outline-none ${
              pageState === 1 ? "bg-white text-blue-600" : "bg-gray-200"
            }`}
            onClick={() => setPageState(1)}
          >
            Yanıtlar
          </span>
          <span
            className={`p-2 w-44 rounded-xl h-9 text-center ${
              pageState === 2 ? "bg-white text-blue-600" : "bg-gray-200"
            }`}
            onClick={() => setPageState(2)}
          >
            Analiz
          </span>
          <span
            className={`p-2 w-44 rounded-xl h-9 text-center ${
              pageState === 3 ? "bg-white text-blue-600" : "bg-gray-200"
            }`}
            onClick={() => setPageState(3)}
          >
            İstatistikler
          </span>
        </div>
      </div>
      {pageState === 1 ? (
        <div className="w-[1216px] mx-auto">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <p className="mr-2 font-semibold">Yanıtlar</p>
              <div className="w-[90px] bg-[#EFF4FF] h-[22px] border border-[#B2CCFF] rounded-2xl text-xs font-semibold py-[2px] px-1  flex items-center justify-center text-[#2970FF]">
                100 katılımcı
              </div>
            </div>
            <div className="flex items-center justify-center ">
              <Switch
                id="flexSwitchCheckShowValues"
                checked={showValues}
                onChange={(e) => setShowValues(e)}
                className={classNames(
                  showValues ? "bg-blue-600" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 mr-2 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
                )}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    showValues ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
              <label
                className="text-gray-500 mr-[30px]"
                htmlFor="flexSwitchCheckShowValues"
              >
                {showValues ? "Başlıkları Göster" : "Değerleri Göster"}
              </label>
              <button
                className="inline-flex py-2 px-3 justify-center gap-1 border border-[#2970FF] rounded-lg bg-[#2970FF]"
                style={{ boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  className="w-[18px] h-[18px]"
                >
                  <path
                    d="M15.75 15.75H2.25M13.5 8.25L9 12.75M9 12.75L4.5 8.25M9 12.75V2.25"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span className="text-white font-bold text-sm">
                  Excel İndir
                </span>
              </button>
            </div>
          </div>
          <Responses userDatas={data} />
        </div>
      ) : pageState === 2 ? (
        <Analysis chartDataArray={chartDataArray} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default SurveyResult;

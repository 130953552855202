import formCooker from "../images/formCookerLogo.svg";

export default function FormCookerLogo({
  width,
  className,
}: {
  width: number;
  className?: string;
}) {
  return <img className={className} src={formCooker} alt="" width={width} />;
}

import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import { useAtom, useAtomValue } from "jotai";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../atoms/surveyAtoms";

const FormSettingsCopy = () => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const { t } = useTranslation();

  const handleCopyButton = () => {
    setStateSurveyJSON((prevState) => {
      const updatedPages = prevState.pages ? [...prevState.pages] : [];
      if (pageIndex !== undefined && pageIndex >= 0) {
        const originalElement =
          stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]!;
        const sidebarItemsObj = {
          ...originalElement,
          _id: uuidv4(),
        };
        if (sidebarItemsObj) {
          if (updatedPages[pageIndex]) {
            let elements = updatedPages[pageIndex].elements || [];
            let index =
              elementIndex !== undefined &&
              elementIndex >= 0 &&
              elementIndex <= elements.length
                ? elementIndex
                : elements.length;
            elements.splice(index + 1, 0, sidebarItemsObj);
            updatedPages[pageIndex].elements = elements;
          } else {
            updatedPages[pageIndex] = {
              _id: "Sayfa" + uuidv4(),
              elements: [sidebarItemsObj],
            };
          }
        }
      }
      return { ...prevState, pages: updatedPages };
    });
  };

  return (
    <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
      <label
        className="block text-sm font-bold text-gray-500"
        htmlFor="enterTitle"
      >
        {t("copyArea")}
      </label>
      <button
        className="mb-2 mt-3 rounded-md border-2 border-blue-500 px-3 py-2 text-center align-middle text-sm font-semibold text-blue-600 hover:bg-blue-100"
        onClick={() => handleCopyButton()}
      >
        <div className="flex items-center">{t("copy")}</div>
      </button>
      <p className="mb-0 mt-3 text-sm text-stone-500">
        {t("copyAreaDescription")}
      </p>
    </div>
  );
};

export default FormSettingsCopy;

import { ChangeEvent, useEffect, useState } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { Switch } from "@headlessui/react";
import { classNames } from "../../../lib/externalJS";
import { useAtom, useAtomValue } from "jotai";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../atoms/surveyAtoms";

const FormSettingsTimeCounter = () => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const [total, setTotal] = useState({
    maxTimeToFinishQuestion:
      stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
        ?.maxTimeToFinishQuestion,
  });
  const [localState, setLocalState] = useState(
    stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]?.timeoutAction,
  );
  const [minute, setMinute] = useState<number>(0);
  const [second, setSecond] = useState<number>(0);
  const { t } = useTranslation();

  useEffect(() => {
    if (total.maxTimeToFinishQuestion !== undefined) {
      const minutes: number = Math.floor(total.maxTimeToFinishQuestion / 60);
      const seconds: number = total.maxTimeToFinishQuestion % 60;
      setMinute(minutes);
      setSecond(seconds);
    }

    setStateSurveyJSON((prevState) => {
      let newState = { ...prevState };
      newState.pages![pageIndex]!.elements![
        elementIndex
      ]!.maxTimeToFinishQuestion = total.maxTimeToFinishQuestion;
      newState.pages![pageIndex]!.elements![elementIndex]!.timeoutAction =
        localState;
      return newState;
    });
  }, [total, localState]);

  useEffect(() => {
    setTotal(() => {
      let localStateObject = {
        maxTimeToFinishQuestion:
          stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
            ?.maxTimeToFinishQuestion,
      };
      return localStateObject;
    });
  }, [
    stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
      ?.maxTimeToFinishQuestion,
  ]);

  const handleMinuteChange = (e: ChangeEvent<HTMLInputElement>) => {
    const minutes: number = parseInt(e.target.value, 10);
    setMinute(minutes);
    const totalSeconds: number = minutes * 60 + second;
    setTotal({ maxTimeToFinishQuestion: totalSeconds });
  };

  const handleSecondChange = (e: ChangeEvent<HTMLInputElement>) => {
    const seconds: number = parseInt(e.target.value, 10);
    setSecond(seconds);
    const totalSeconds: number = minute * 60 + seconds;
    setTotal({ maxTimeToFinishQuestion: totalSeconds });
  };

  const handleProcessChange = (e: any) => {
    setLocalState(e);
  };

  const switchState =
    total.maxTimeToFinishQuestion || total.maxTimeToFinishQuestion === 0
      ? true
      : false;

  return (
    <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
      <label className="block text-sm font-bold text-gray-500">
        {t("timeCounter")}
      </label>
      <div className="mb-2 mt-3 flex">
        <Switch
          id="flexSwitchCheckshuffleOptions"
          checked={switchState}
          onChange={(e) => {
            setTotal((prev) => ({
              ...prev,
              maxTimeToFinishQuestion: e ? 60 : undefined,
            }));
            setLocalState(undefined);
          }}
          className={classNames(
            switchState ? "bg-blue-600" : "bg-gray-200",
            "relative mr-2 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2",
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={classNames(
              switchState ? "translate-x-5" : "translate-x-0",
              "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
            )}
          />
        </Switch>
        <label
          className="text-gray-300"
          htmlFor="flexSwitchCheckshuffleOptions"
        >
          {switchState ? t("open") : t("close")}
        </label>
      </div>
      <div
        className="mt-3 grid-cols-8 gap-3"
        style={{
          display:
            total.maxTimeToFinishQuestion || total.maxTimeToFinishQuestion === 0
              ? "grid"
              : "none",
        }}
      >
        <div className="col-span-3">
          <input
            type="number"
            id="min"
            className="block w-full rounded-lg border border-gray-300 bg-white p-2 text-sm text-gray-900 focus:border-blue-500"
            placeholder="1"
            value={minute}
            onChange={handleMinuteChange}
          />
          <label
            htmlFor="min"
            className="mt-1 block text-center text-sm font-medium text-gray-500"
          >
            Dakika
          </label>
        </div>
        <div className="col-span-3">
          <input
            type="number"
            id="sec"
            max={59}
            className="block w-full rounded-lg border-gray-300 bg-white p-2 text-sm text-gray-900 focus:border-blue-500"
            placeholder="00"
            value={second}
            onChange={handleSecondChange}
          />
          <label
            htmlFor="sec"
            className="mt-1 block text-center text-sm font-medium text-gray-500"
          >
            Saniye
          </label>
        </div>
      </div>
      <label
        className="mt-3 block text-sm font-bold text-gray-500"
        style={{
          display:
            total.maxTimeToFinishQuestion || total.maxTimeToFinishQuestion === 0
              ? "block"
              : "none",
        }}
      >
        {t("actionToTakenWhenTimeIsOut")}
      </label>
      <div
        className="mt-3"
        style={{
          display:
            total.maxTimeToFinishQuestion || total.maxTimeToFinishQuestion === 0
              ? "block"
              : "none",
        }}
      >
        <Select
          className="basic-single"
          placeholder={t("choose")}
          isSearchable={false}
          isClearable={true}
          options={[
            { value: 1, label: t("settingOnSurvey") },
            { value: 2, label: t("goToNextPage") },
            { value: 3, label: t("endForm") },
          ]}
          onChange={(e) => handleProcessChange(e?.value)}
        />
      </div>
      <p className="mb-0 mt-3 text-sm text-stone-500">
        {total.maxTimeToFinishQuestion || total.maxTimeToFinishQuestion === 0
          ? t("determineWhatToDoUsersDoNotAnswerQuestionWithinTimeLimit")
          : t("setTimeLimitForAnsweringQuestion")}
      </p>
    </div>
  );
};

export default FormSettingsTimeCounter;

import { FilterOption, Person } from "Interfaces/general";
import { selectedUserAtom } from "components/atoms/dashboardAtoms";
import talentData from "data/talentData.json";
import { useSetAtom } from "jotai";
import { classNames } from "lib/externalJS";
import Filter from "pages/dashboard2/TalentManager/Filter";
import Pagination from "pages/dashboard2/TalentManager/Pagination";
import { useEffect, useMemo, useState } from "react";
import "react-circular-progressbar/dist/styles.css";

const Workers = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const people = useMemo(() => talentData as any[], [talentData]);

  const setSelectedUser = useSetAtom(selectedUserAtom);
  const [filteredPeople, setFilteredPeople] = useState<Person[]>(people);

  const handleFilterChange = (filters: FilterOption[], searchQuery: string) => {
    let updatedPeople = people;

    const filtersGrouped = groupBy(filters, "id");

    if (filters.length > 0) {
      updatedPeople = updatedPeople.filter((person: any) => {
        return Object.keys(filtersGrouped).every((key) => {
          return filtersGrouped[key].some((filter: any) => {
            switch (filter.id) {
              case "departman":
                return person.departman === filter.value;
              case "pozisyon":
                return person.pozisyon === filter.value;
              case "cinsiyet":
                return person.cinsiyet === filter.value;
              case "yas":
                const splittedYaslar = filter.value.split("-");
                const personYas = parseInt(person.yas);
                return (
                  personYas >= parseInt(splittedYaslar[0]) &&
                  personYas <= parseInt(splittedYaslar[1])
                );
              default:
                return false;
            }
          });
        });
      });
    }

    if (searchQuery) {
      updatedPeople = updatedPeople.filter((person: any) =>
        (person.ad + " " + person.soyad)
          .toLowerCase()
          .includes(searchQuery.toLowerCase()),
      );
    }

    setFilteredPeople(updatedPeople);
  };

  return (
    <div className="w-full max-w-[1000px]">
      <div className="sm:flex-auto">
        <h1 className="mb-[30px] flex items-center text-base font-semibold leading-6 text-gray-900">
          <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="36" height="36" rx="4" fill="#D1E9FF" />
            <path
              d="M22 9.46776C23.4817 10.2041 24.5 11.7331 24.5 13.5C24.5 15.2669 23.4817 16.7959 22 17.5322M24 22.7664C25.5115 23.4503 26.8725 24.565 28 26M8 26C9.94649 23.5226 12.5892 22 15.5 22C18.4108 22 21.0535 23.5226 23 26M20 13.5C20 15.9853 17.9853 18 15.5 18C13.0147 18 11 15.9853 11 13.5C11 11.0147 13.0147 9 15.5 9C17.9853 9 20 11.0147 20 13.5Z"
              stroke="#528BFF"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <span className="ml-3 text-xl font-medium">Çalışanlar</span>
        </h1>
      </div>

      <Filter
        people={filteredPeople}
        originalPeople={people}
        onFilterChange={handleFilterChange}
        searchBarOn={true}
      />

      <div className="-mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full pb-[27px] pt-5 align-middle sm:px-6 lg:px-8">
          <table className="min-w-full">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="px-5 text-left text-sm font-semibold text-gray-900"
                >
                  <div className="pb-1 text-xs font-medium leading-[18px] text-gray-500">
                    Çalışanlar
                  </div>
                </th>
                <th
                  scope="col"
                  className="px-5 text-left text-sm font-semibold text-gray-900"
                >
                  <div className="pb-1 text-xs font-medium leading-[18px] text-gray-500">
                    Departman
                  </div>
                </th>
                <th
                  scope="col"
                  className="px-5 text-left text-sm font-semibold text-gray-900"
                >
                  <div className="pb-1 text-xs font-medium leading-[18px] text-gray-500">
                    İletişim
                  </div>
                </th>
                <th
                  scope="col"
                  className="px-5 text-center text-sm font-semibold text-gray-900"
                >
                  <div className="pb-1 text-xs font-medium leading-[18px] text-gray-500">
                    Başlangıç Tarihi
                  </div>
                </th>
                <th
                  scope="col"
                  className="px-5 text-center text-sm font-semibold text-gray-900"
                >
                  <div className="pb-1 text-xs font-medium leading-[18px] text-gray-500">
                    İncele
                  </div>
                </th>
              </tr>
            </thead>

            <tbody>
              {filteredPeople.map((p, index) => {
                const TdChildWrapper = ({
                  children,
                }: {
                  children: React.ReactNode;
                }) => (
                  <div
                    className={
                      "bg-gray-50 " + (index !== 0 ? "py-[5px]" : "pb-[5px]")
                    }
                  >
                    {children}
                  </div>
                );

                return (
                  <tr key={p.id} className="">
                    <td className="whitespace-nowrap p-0 text-sm">
                      <TdChildWrapper>
                        <div className="flex items-center rounded-bl-lg rounded-tl-lg border border-r-0 border-gray-200 bg-white px-5 py-[14px]">
                          <div className="h-11 w-11 flex-shrink-0">
                            <img
                              className="h-11 w-11 rounded-full"
                              src={"/avatars/" + p.id + ".png"}
                              alt=""
                              onError={(e) => {
                                (e.target as any).src = "/avatars/default.png";
                              }}
                            />
                          </div>

                          <div className="ml-4">
                            <div className="text-base font-medium text-gray-900">
                              {p.ad + " " + p.soyad}
                            </div>
                            <div className="mt-1 text-gray-500">
                              {p.pozisyon}
                            </div>
                          </div>
                        </div>
                      </TdChildWrapper>
                    </td>

                    <td className="whitespace-nowrap p-0 text-sm text-gray-500">
                      <TdChildWrapper>
                        <div className="flex flex-col border border-gray-200 bg-white px-5 py-[14px]">
                          <div className="text-base font-medium text-gray-900">
                            {p.departman}
                          </div>
                          <div
                            className={classNames(
                              "mt-1 text-gray-500",
                              p.birim === undefined ? "opacity-0" : "",
                            )}
                          >
                            {p.birim ?? "-"}
                          </div>
                        </div>
                      </TdChildWrapper>
                    </td>

                    <td className="whitespace-nowrap p-0 text-sm text-gray-500">
                      <TdChildWrapper>
                        <div className="flex flex-col border border-l-0 border-r-0 border-gray-200 bg-white px-5 py-[14px]">
                          <div className="text-base font-medium text-gray-900">
                            {getRandomTurkishPhone(p.id)}
                          </div>
                          <div className="mt-1 text-gray-500">
                            {getRandomEmail(p.id)}
                          </div>
                        </div>
                      </TdChildWrapper>
                    </td>

                    <td className="whitespace-nowrap p-0 text-center text-sm text-gray-500">
                      <TdChildWrapper>
                        <div className="flex flex-col border border-gray-200 bg-white px-5 py-[28px]">
                          <span className="text-base font-medium leading-tight text-gray-900">
                            {getStartedDate(p.id)}
                          </span>
                        </div>
                      </TdChildWrapper>
                    </td>

                    <td className="p-0">
                      <TdChildWrapper>
                        <div className="flex flex-col items-center rounded-br-lg rounded-tr-lg border border-l-0 border-gray-200 bg-white px-5 py-[28px]">
                          <button
                            onClick={() => setSelectedUser(p)}
                            className="w-fit"
                          >
                            <svg
                              width="26"
                              height="20"
                              viewBox="0 0 26 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.82348 10.832C1.66459 10.5805 1.58515 10.4547 1.54068 10.2607C1.50727 10.1149 1.50727 9.88508 1.54068 9.73935C1.58515 9.54533 1.66459 9.41954 1.82348 9.16796C3.13645 7.08898 7.04463 1.83333 13.0005 1.83333C18.9563 1.83333 22.8645 7.08898 24.1775 9.16796C24.3364 9.41954 24.4158 9.54533 24.4603 9.73935C24.4937 9.88508 24.4937 10.1149 24.4603 10.2607C24.4158 10.4547 24.3364 10.5805 24.1775 10.832C22.8645 12.911 18.9563 18.1667 13.0005 18.1667C7.04463 18.1667 3.13645 12.911 1.82348 10.832Z"
                                stroke="#2970FF"
                                strokeWidth="2.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M13.0005 13.5C14.9335 13.5 16.5005 11.933 16.5005 10C16.5005 8.067 14.9335 6.5 13.0005 6.5C11.0675 6.5 9.50047 8.067 9.50047 10C9.50047 11.933 11.0675 13.5 13.0005 13.5Z"
                                stroke="#2970FF"
                                strokeWidth="2.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                        </div>
                      </TdChildWrapper>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <hr />
      <div className="mt-6 flex items-center justify-center">
        <Pagination peopleNumber={people.length ?? 1} />
      </div>
    </div>
  );
};

const randomStartedDates: any = {};

export function getStartedDate(id: string) {
  if (randomStartedDates[id]) return randomStartedDates[id];

  const date = new Date();
  date.setMonth(date.getMonth() - Math.floor(Math.random() * 12));
  date.setFullYear(date.getFullYear() - (Math.floor(Math.random() * 2) + 8));
  randomStartedDates[id] = date.toLocaleDateString();
  return randomStartedDates[id];
}

const randomEmails: any = {};

export function getRandomEmail(id: string) {
  if (randomEmails[id]) return randomEmails[id];

  const email = `${Math.random().toString(36).substring(2, 7)}@abc.com`;
  randomEmails[id] = email;
  return email;
}

const randomPhones: any = {};

export function getRandomTurkishPhone(id: string) {
  if (randomPhones[id]) return randomPhones[id];

  const phone = `0${Math.floor(Math.random() * 10000000000)}`;
  randomPhones[id] = phone;
  return phone;
}

export function groupBy(array: any[], key: any) {
  return array.reduce((result, item) => {
    (result[item[key]] = result[item[key]] || []).push(item);
    return result;
  }, {});
}

export default Workers;

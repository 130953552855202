import FormSettingsOptions from "../../components/FormSettingsOptions";
import FormSettingsSelectLibrary from "../../components/FormSettingsSelectLibrary";
import FormSettingsCalculation from "../../components/FormSettingsCalculation";
import FormSettingsAreaShape from "../../components/FormSettingsAreaShape";
import FormSettingsNoIdea from "../../components/FormSettingsNoIdea";
import FromSettingsOtherOption from "../../components/FromSettingsOtherOption";

export default function SingleChoiceOptionSettings() {
  return (
    <>
      <FormSettingsOptions />
      <FormSettingsSelectLibrary />
      <FormSettingsNoIdea />
      <FromSettingsOtherOption />
      <FormSettingsCalculation />
      <FormSettingsAreaShape />
    </>
  );
}

import React from "react";

const AnalysisListItem = ({
  index,
  data,
  generalData,
}: {
  index: number;
  data: any;
  generalData: any;
}) => {
  const totalSeries = generalData.series.reduce(
    (acc: any, val: any) => acc + val,
    0
  );
  const remainingPercentage =
    totalSeries > 0
      ? ((generalData.series[index - 1] / totalSeries) * 100).toFixed(2)
      : 0;
  return (
    <div className="flex bg-white border-b">
      <div className="flex items-center w-[374px] justify-start h-[60px]">
        <div className="p-2">
          <h6 className="m-0 text-base text-start">
            ({index}) {data}
          </h6>
        </div>
      </div>
      <div className=" w-[70px] text-sm text-start my-auto">
        {generalData.series[index - 1]}
      </div>
      <div className=" w-[220px] text-sm flex items-center justify-start ">
        <div className="w-[154px] bg-gray-200 rounded-full h-2.5  ">
          <div
            className="bg-blue-500  h-2.5 rounded-full "
            style={{ width: `${remainingPercentage}%` }}
          ></div>
        </div>
        <span className="w-[30px] mx-auto">{`${remainingPercentage}%`}</span>
      </div>
    </div>
  );
};

export default AnalysisListItem;

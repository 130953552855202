import { selectedUserAtom } from "components/atoms/dashboardAtoms";
import FireIcon from "images/local_fire_department.svg";
import UpIcon from "images/trending_up.svg";
import { useAtomValue } from "jotai";

export default function EmployeeProfileBusinessTechSkills() {
  const selectedUser = useAtomValue(selectedUserAtom);

  const ROWS: any = [];

  selectedUser.teknolojikBeceriler.forEach((teknolojikBeceri: any) => {
    const foundIndex = ROWS.findIndex(
      (row: any) => row.title === teknolojikBeceri.title,
    );

    if (foundIndex === -1) {
      teknolojikBeceri.toolsArr = [];
      ROWS.push(teknolojikBeceri);
    }

    ROWS[foundIndex !== -1 ? foundIndex : ROWS.length - 1].toolsArr.push({
      toolTitle: teknolojikBeceri.tools,
      isPopular: teknolojikBeceri.isPopular === "Y",
      isInDemand: teknolojikBeceri.isInDemand === "Y",
    });
  });

  return (
    <div>
      {/* table header */}
      <div className="flex rounded-t-xl border border-gray-200 bg-gray-50 text-xs font-medium leading-[18px] text-gray-500">
        <div className="flex w-[82px] items-center p-3">Popüler</div>
        <div className="flex w-[102px] items-center border-l border-r border-gray-200 p-3">
          Talep Edilen
        </div>
        <div className="flex items-center p-3">Teknolojik Beceriler</div>
      </div>

      {/* table items */}
      {ROWS.map((row: any, index: number) => {
        return (
          <div
            className={
              "flex border-x border-b border-gray-200 bg-white text-sm font-medium leading-tight text-gray-800 " +
              (index === ROWS.length - 1 ? "rounded-b-xl" : "")
            }
            key={index}
          >
            <span className="flex w-[82px] items-center justify-center py-3">
              {row.toolsArr.some((x: any) => x.isPopular) && (
                <img src={FireIcon} alt="fire icon" className="h-7 w-7" />
              )}
            </span>

            <span className="flex w-[102px] items-center justify-center border-l border-r border-gray-200 py-3">
              {row.toolsArr.some((x: any) => x.isInDemand) && (
                <img src={UpIcon} alt="up icon" className="h-7 w-7" />
              )}
            </span>

            <li className="flex w-[816px] list-none flex-wrap items-center p-4">
              <span className="text-sm font-medium text-black">
                {"• " + row.title + " — "}
              </span>

              {row.toolsArr.map((tool: any) => (
                <span className="flex items-center">
                  <span className="ml-3 text-sm font-light text-black">
                    {tool.toolTitle}
                  </span>
                  {tool.isPopular && (
                    <img
                      src={FireIcon}
                      alt="fire icon"
                      className="inline-block h-5 w-5"
                    />
                  )}
                  {tool.isInDemand && (
                    <img
                      src={UpIcon}
                      alt="up icon"
                      className="inline-block h-5 w-5"
                    />
                  )}
                </span>
              ))}
            </li>
          </div>
        );
      })}
    </div>
  );
}

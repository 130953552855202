import companyData from "data/companyData.json";
import EmployeeProfileCard from "pages/dashboard2/EmployeeProfileCard";
import { getSortedDegerlendirme } from "pages/dashboard2/EmployeeProfileHighlights";
import GaugeWithPointer from "pages/dashboard2/Panel/GaugeWithPointer";
import { EmployeeSatisfactionScorePercentages } from "pages/dashboard2/Panel/Panel";
import CommonTable from "pages/dashboard2/Performance/CommonTable";

export default function EmployeeSatisfactionScore() {
  const latestCalisanMemnuniyeti = getSortedDegerlendirme(
    companyData["Çalışan Memnuniyet Skoru"],
  )[0];

  return (
    <>
      <EmployeeProfileCard
        titleEmoji="😊"
        title="Çalışan Memnuniyet Skoru"
        bgClassName="bg-yellow-100"
      >
        <div className="flex items-center justify-center gap-x-25">
          <div>
            <GaugeWithPointer
              value={+latestCalisanMemnuniyeti.score.genel.eNPS}
              height={240}
              width={240}
            />
          </div>

          <div className="w-[268px]">
            <EmployeeSatisfactionScorePercentages
              data={latestCalisanMemnuniyeti}
            />
          </div>
        </div>
      </EmployeeProfileCard>

      <CommonTable forTable="calisanMemnuniyet" />
    </>
  );
}

import React, { Dispatch, SetStateAction } from "react";
import { motion } from "framer-motion";
import { useSetAtom } from "jotai";
import { elementIndexAtom } from "../atoms/surveyAtoms";

type Props = {
  setShow: Dispatch<SetStateAction<boolean>>;
  setPageIndex: Dispatch<SetStateAction<number>>;
  pageIndex?: number;
};

const Empty: React.FC<Props> = ({ setShow, setPageIndex, pageIndex }) => {
  const setElementIndex = useSetAtom(elementIndexAtom);

  return (
    <div className="relative mb-5 box-border flex flex-col break-words rounded border bg-white shadow-sm">
      <div className="card-body flex flex-auto flex-wrap items-center justify-center p-5">
        <motion.span
          className="max-h-5 cursor-pointer"
          onClick={() => {
            setShow((show) => !show);
            setPageIndex(pageIndex ? pageIndex : 0);
            setElementIndex(0);
          }}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          whileHover={{ scale: 1.2 }}
          transition={{ delay: 0.175 }}
        >
          <svg
            className="transition-all delay-75 hover:bg-[#EFF4FF]"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.0001 4.1665V15.8332M4.16675 9.99984H15.8334"
              stroke="#2970FF"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </motion.span>
      </div>
    </div>
  );
};

export default Empty;

import axiosInstance from "../api";

export const getSurveySettingIds = async () => {
  return (await axiosInstance.get<any[]>("SurveySetting/GetAll")).data;
};

export const getSurveySetting = async (id: string) => {
  return (
    await axiosInstance.get<any>(`SurveySetting/GetById`, {
      params: { settingId: id },
    })
  ).data;
};

export const createSurveySetting = async (data: any) => {
  await axiosInstance.post("/SurveySetting/Add", data);
};

export const updateSurveySetting = async (data: any) => {
  await axiosInstance.put(`/SurveySetting/Update`, data, {
    params: { settingId: data._id },
  });
};

export const deleteSurveySetting = async (id: string) => {
  await axiosInstance.delete(`SurveySetting/Delete`, {
    params: { settingId: id },
  });
};

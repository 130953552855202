import { useTranslation } from "react-i18next";
import { Switch } from "@headlessui/react";
import { classNames } from "../../../lib/externalJS";
import { useAtom, useAtomValue } from "jotai";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../atoms/surveyAtoms";

const FormSettingsBottomTicketAddress = () => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const { t } = useTranslation();

  const handleRequiredChangeNick = (e: boolean) => {
    const newValue = e;
    setStateSurveyJSON((prevState) => {
      let newState = { ...prevState };
      newState.pages![pageIndex]!.elements![elementIndex]!.showNickname =
        newValue;
      return newState;
    });
  };
  const handleRequiredChangeSecondary = (e: boolean) => {
    const newValue = e;
    setStateSurveyJSON((prevState) => {
      let newState = { ...prevState };
      newState.pages![pageIndex]!.elements![elementIndex]!.showSecondaryName =
        newValue;
      return newState;
    });
  };
  const handleRequiredChangeLast = (e: boolean) => {
    const newValue = e;
    setStateSurveyJSON((prevState) => {
      let newState = { ...prevState };
      newState.pages![pageIndex]!.elements![elementIndex]!.showLastName =
        newValue;
      return newState;
    });
  };

  const handleEyaletChange = (e: boolean) => {
    const newValue = e;
    setStateSurveyJSON((prevState) => {
      let newState = { ...prevState };
      newState.pages![pageIndex]!.elements![elementIndex]!.showExtra2 =
        newValue;
      return newState;
    });
  };

  const handleCountryChange = (e: boolean) => {
    const newValue = e;
    setStateSurveyJSON((prevState) => {
      let newState = { ...prevState };
      newState.pages![pageIndex]!.elements![elementIndex]!.showExtra1 =
        newValue;
      return newState;
    });
  };

  const handleChangeAddress2 = (e: boolean) => {
    const newValue = e;
    setStateSurveyJSON((prevState) => {
      let newState = { ...prevState };
      newState.pages![pageIndex]!.elements![elementIndex]!.showExtra3 =
        newValue;
      return newState;
    });
  };

  const Address1 = stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
    ?.showNickname
    ? stateSurveyJSON.pages[pageIndex].elements![elementIndex].showNickname
    : false;

  const Address2 = stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
    ?.showExtra3
    ? stateSurveyJSON.pages[pageIndex].elements![elementIndex].showExtra3
    : false;

  const Ilce = stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
    ?.showSecondaryName
    ? stateSurveyJSON.pages[pageIndex].elements![elementIndex].showSecondaryName
    : false;

  const City = stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
    ?.showExtra2
    ? stateSurveyJSON.pages[pageIndex].elements![elementIndex].showExtra2
    : false;

  const PostalCode = stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
    ?.showLastName
    ? stateSurveyJSON.pages[pageIndex].elements![elementIndex].showLastName
    : false;

  const Country = stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
    ?.showExtra1
    ? stateSurveyJSON.pages[pageIndex].elements![elementIndex].showExtra1
    : false;
  return (
    <>
      <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
        <label className="block text-sm font-bold text-gray-500">
          {t("Adres Satırı 1")}
        </label>
        <div className="mt-3 flex">
          <Switch
            id="flexSwitchCheckAddress"
            checked={Address1}
            onChange={handleRequiredChangeNick}
            className={classNames(
              Address1 ? "bg-blue-600" : "bg-gray-200",
              "relative mr-2 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2",
            )}
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={classNames(
                Address1 ? "translate-x-5" : "translate-x-0",
                "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
              )}
            />
          </Switch>
          <label className="text-gray-300" htmlFor="flexSwitchCheckAddress">
            {Address1 ? "Sabit" : "Otomatik"}
          </label>
        </div>
        <p className="mb-0 mt-3 text-sm text-stone-500">
          {t("Bu alan üzerinden kullanıcının birincil adresini alabilirsiniz.")}
        </p>
      </div>
      <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
        <label className="mb-2 block text-sm font-bold text-gray-500">
          {t("Adres Satırı 2")}
        </label>
        <div className="mt-3 flex">
          <Switch
            id="flexSwitchCheckAddress2"
            checked={Address2}
            onChange={handleChangeAddress2}
            className={classNames(
              Address2 ? "bg-blue-600" : "bg-gray-200",
              "relative mr-2 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2",
            )}
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={classNames(
                Address2 ? "translate-x-5" : "translate-x-0",
                "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
              )}
            />
          </Switch>
          <label className="text-gray-300" htmlFor="flexSwitchCheckAddress2">
            {Address2 ? "Sabit" : "Otomatik"}
          </label>
        </div>
        <p className="mb-0 mt-3 text-sm text-stone-500">
          {t("Adres Satırı 2 desc")}
        </p>
      </div>
      <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
        <label className="mb-2 block text-sm font-bold text-gray-500">
          {t("İlçe")}
        </label>
        <div className="mt-3 flex">
          <Switch
            id="flexSwitchCheckIlce"
            checked={Ilce}
            onChange={handleRequiredChangeSecondary}
            className={classNames(
              Ilce ? "bg-blue-600" : "bg-gray-200",
              "relative mr-2 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2",
            )}
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={classNames(
                Ilce ? "translate-x-5" : "translate-x-0",
                "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
              )}
            />
          </Switch>
          <label className="text-gray-300" htmlFor="flexSwitchCheckIlce">
            {Ilce ? "Sabit" : "Otomatik"}
          </label>
        </div>
        <p className="mb-0 mt-3 text-sm text-stone-500">{t("İlçe Desc")}</p>
      </div>
      <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
        <label className="mb-2 block text-sm font-bold text-gray-500">
          {t("İl/Eyalet")}
        </label>
        <div className="mt-3 flex">
          <Switch
            id="flexSwitchCheckCity"
            checked={City}
            onChange={handleEyaletChange}
            className={classNames(
              City ? "bg-blue-600" : "bg-gray-200",
              "relative mr-2 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2",
            )}
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={classNames(
                City ? "translate-x-5" : "translate-x-0",
                "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
              )}
            />
          </Switch>
          <label className="text-gray-300" htmlFor="flexSwitchCheckCity">
            {City ? "Sabit" : "Otomatik"}
          </label>
        </div>
        <p className="mb-0 mt-3 text-sm text-stone-500">
          {t("İl/Eyalet Desc")}
        </p>
      </div>
      <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
        <label className="mb-2 block text-sm font-bold text-gray-500">
          {t("Posta Kodu")}
        </label>
        <div className="mt-3 flex">
          <Switch
            id="flexSwitchCheckPostalCode"
            checked={PostalCode}
            onChange={handleRequiredChangeLast}
            className={classNames(
              PostalCode ? "bg-blue-600" : "bg-gray-200",
              "relative mr-2 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2",
            )}
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={classNames(
                PostalCode ? "translate-x-5" : "translate-x-0",
                "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
              )}
            />
          </Switch>
          <label className="text-gray-300" htmlFor="flexSwitchCheckPostalCode">
            {PostalCode ? "Sabit" : "Otomatik"}
          </label>
        </div>
        <p className="mb-0 mt-3 text-sm text-stone-500">
          {t("Posta Kodu desc")}
        </p>
      </div>
      <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
        <label className="mb-2 block text-sm font-bold text-gray-500">
          {t("Ülke")}
        </label>
        <div className="mt-3 flex">
          <Switch
            id="flexSwitchCheckCountry"
            checked={Country}
            onChange={handleCountryChange}
            className={classNames(
              Country ? "bg-blue-600" : "bg-gray-200",
              "relative mr-2 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2",
            )}
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={classNames(
                Country ? "translate-x-5" : "translate-x-0",
                "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
              )}
            />
          </Switch>
          <label className="text-gray-300" htmlFor="flexSwitchCheckCountry">
            {Country ? "Sabit" : "Otomatik"}
          </label>
        </div>
        <p className="mb-0 mt-3 text-sm text-stone-500">{t("Ülke")}</p>
      </div>
    </>
  );
};

export default FormSettingsBottomTicketAddress;

import companyData from "data/companyData.json";
import PerformanceIcon from "images/sidebarIcons/Performance";
import { classNames } from "lib/externalJS";
import { getSortedDegerlendirme } from "pages/dashboard2/EmployeeProfileHighlights";
import {
  PanelCardChild,
  PanelSplineGraph,
  SERIES_COLORS,
  getSeriesAndCategories,
} from "pages/dashboard2/Panel/Panel";
import CommonTable from "pages/dashboard2/Performance/CommonTable";

export default function Performance() {
  return (
    <div className="flex flex-col">
      {/* page title */}
      <div className="mb-[30px] flex items-center">
        <div className="p-1.5 mr-3 inline-flex h-9 w-9 items-center justify-center rounded bg-green-100">
          <PerformanceIcon aria-hidden="true" isForTitle />
        </div>

        <div className="inline-flex text-2xl font-semibold leading-normal text-gray-800">
          Performans
        </div>
      </div>

      <PanelCardChild span={4}>
        <TimeFilters />

        <div className="-mx-3">
          <PanelSplineGraph
            {...getSeriesAndCategories(
              getSortedDegerlendirme(companyData.Performans),
              "Performans",
            )}
            colors={SERIES_COLORS}
            isBigger
          />
        </div>
      </PanelCardChild>

      <CommonTable forTable="performans" />
    </div>
  );
}

const TIME_FILTERS = Object.freeze(["1A", "6A", "1Y", "YTD", "TÜM"]);

export function TimeFilters({
  color = "green",
}: {
  color?: "green" | "blue" | "purple" | "rose";
}) {
  return (
    <div className="flex gap-x-1 opacity-0">
      {TIME_FILTERS.map((timeFilter, index) => {
        const isActive = index % 2 === 0;

        return (
          <button
            key={timeFilter}
            className={classNames(
              "rounded-md border px-[18px] py-1",
              isActive
                ? getBorderAndBgColor(color)
                : "border-neutral-200 bg-white",
            )}
          >
            <span
              className={classNames(
                "text-sm font-normal",
                isActive ? "text-white" : "text-gray-500",
              )}
            >
              {timeFilter}
            </span>
          </button>
        );
      })}
    </div>
  );
}

const MAPPING: { [key: string]: string } = Object.freeze({
  green: "border-emerald-600 bg-green-400",
  blue: "border-blue-600 bg-blue-500",
  purple: "border-violet-600 bg-violet-400",
  rose: "bg-rose-400 border-rose-600",
});

function getBorderAndBgColor(color: string) {
  return MAPPING[color];
}

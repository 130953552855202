import { Menu, Transition } from "@headlessui/react";
import { useAtom } from "jotai";
import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { toast } from "react-toastify";
import ShortUniqueId from "short-unique-id";
import { v4 as uuidv4 } from "uuid";
import { surveyAtom } from "../../components/atoms/surveyAtoms";
import CheckboxBootstrap from "../../components/staticDesign/elements/CheckboxBootstrap";
import { surveySettingInterface } from "../../Interfaces/SurveyInterfaces";
import { useCreateUrl } from "../../services/mutations/urlMutations";

type Props = {
  surveySetting: surveySettingInterface;
  setSurveySetting: Dispatch<SetStateAction<surveySettingInterface>>;
};
const SurveyFastShare: React.FC<Props> = ({
  surveySetting,
  setSurveySetting,
}) => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);

  const [shortenedUrl, setShortenedUrl] = useState<string>("");
  const [localState, setLocalState] = useState(surveySetting);
  const createSurveyUrl = useCreateUrl();

  const shortenUrl = async () => {
    try {
      const uid = new (ShortUniqueId as any)({ length: 10 });
      const slug = uid.rnd();
      createSurveyUrl.mutate({
        _id: uuidv4(),
        shortURL: slug,
        examID: stateSurveyJSON._id,
      });
      return `${window.location.origin}/${slug}`;
    } catch (error) {
      console.error("Error adding url:", error);
      return "";
    }
  };

  const saveURL = async () => {
    const url = await shortenUrl();
    if (url) {
      setShortenedUrl(url);
    }
  };

  useEffect(() => {
    saveURL();
  }, []);
  const handleSaveChanges = () => {
    setSurveySetting(localState);
  };
  return (
    <>
      <div className="surveyInitialContainer flex-wrap">
        <div className="w-[720px]">
          <div className="flex">
            <p className="mr-2 font-semibold">Paylaşım Bağlantısı</p>
            {stateSurveyJSON.isActive ? (
              <div className="flex h-7 w-[160px] items-center justify-center rounded-md border border-green-600 bg-green-100 p-1 text-sm font-bold text-green-800">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mr-1"
                >
                  <circle cx="4" cy="4" r="3" fill="#17B26A" />
                </svg>
                Formunuz Yayında
              </div>
            ) : (
              <div className="flex h-7 w-[160px] items-center justify-center rounded-md border border-red-600 bg-red-100 p-1 text-sm font-bold text-red-800">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                  fill="none"
                  className="mr-1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="4" cy="4" r="3" fill="#F04438" />
                </svg>
                Form Yayında Değil
              </div>
            )}
            <span className="ml-auto cursor-pointer">
              <div className="text-right">
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="inline-flex w-full justify-center rounded-md px-1 py-2 text-sm font-medium text-white hover:bg-black/5 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.0013 10.8335C10.4615 10.8335 10.8346 10.4604 10.8346 10.0002C10.8346 9.53993 10.4615 9.16683 10.0013 9.16683C9.54106 9.16683 9.16797 9.53993 9.16797 10.0002C9.16797 10.4604 9.54106 10.8335 10.0013 10.8335Z"
                          stroke="#667085"
                          strokeWidth="1.66667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10.0013 5.00016C10.4615 5.00016 10.8346 4.62707 10.8346 4.16683C10.8346 3.70659 10.4615 3.3335 10.0013 3.3335C9.54106 3.3335 9.16797 3.70659 9.16797 4.16683C9.16797 4.62707 9.54106 5.00016 10.0013 5.00016Z"
                          stroke="#667085"
                          strokeWidth="1.66667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10.0013 16.6668C10.4615 16.6668 10.8346 16.2937 10.8346 15.8335C10.8346 15.3733 10.4615 15.0002 10.0013 15.0002C9.54106 15.0002 9.16797 15.3733 9.16797 15.8335C9.16797 16.2937 9.54106 16.6668 10.0013 16.6668Z"
                          stroke="#667085"
                          strokeWidth="1.66667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                      <div className="px-1 py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className={`${
                                active
                                  ? "bg-blue-500 text-white"
                                  : "text-gray-900"
                              } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                              onClick={() => saveURL()}
                            >
                              Linki Yeniden Oluştur
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className={`${
                                active
                                  ? "bg-blue-500 text-white"
                                  : "text-gray-900"
                              } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                              onClick={() =>
                                setStateSurveyJSON((prev) => ({
                                  ...prev,
                                  isActive: false,
                                }))
                              }
                            >
                              Yayını Durdur
                            </button>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </span>
          </div>
          <div className="h-32 w-full rounded-xl border bg-white px-6 py-6">
            <div className="flex">
              <input
                className="mr-2 w-[472px] rounded-xl border-2 border-gray-200 p-2 outline-none"
                value={shortenedUrl}
              />
              <button
                className="ml-2 flex h-10 w-44 items-center justify-center rounded-xl bg-white font-semibold text-gray-500"
                onClick={() => {
                  navigator.clipboard.writeText(shortenedUrl);
                  toast.success("Bağlantı kopyalandı");
                }}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mr-1"
                >
                  <g clipPath="url(#clip0_1623_6088)">
                    <path
                      d="M6.66797 6.6665V4.33317C6.66797 3.39975 6.66797 2.93304 6.84962 2.57652C7.00941 2.26292 7.26438 2.00795 7.57798 1.84816C7.9345 1.6665 8.40121 1.6665 9.33464 1.6665H15.668C16.6014 1.6665 17.0681 1.6665 17.4246 1.84816C17.7382 2.00795 17.9932 2.26292 18.153 2.57652C18.3346 2.93304 18.3346 3.39975 18.3346 4.33317V10.6665C18.3346 11.5999 18.3346 12.0666 18.153 12.4232C17.9932 12.7368 17.7382 12.9917 17.4246 13.1515C17.0681 13.3332 16.6014 13.3332 15.668 13.3332H13.3346M4.33464 18.3332H10.668C11.6014 18.3332 12.0681 18.3332 12.4246 18.1515C12.7382 17.9917 12.9932 17.7368 13.153 17.4232C13.3346 17.0666 13.3346 16.5999 13.3346 15.6665V9.33317C13.3346 8.39975 13.3346 7.93304 13.153 7.57652C12.9932 7.26292 12.7382 7.00795 12.4246 6.84816C12.0681 6.6665 11.6014 6.6665 10.668 6.6665H4.33464C3.40121 6.6665 2.9345 6.6665 2.57798 6.84816C2.26438 7.00795 2.00941 7.26292 1.84962 7.57652C1.66797 7.93304 1.66797 8.39975 1.66797 9.33317V15.6665C1.66797 16.5999 1.66797 17.0666 1.84962 17.4232C2.00941 17.7368 2.26438 17.9917 2.57798 18.1515C2.9345 18.3332 3.40121 18.3332 4.33464 18.3332Z"
                      stroke="#475467"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </svg>
                Bağlantıyı Kopyala
              </button>
            </div>
          </div>
          <div className="flex">
            <p className="mt-3 font-semibold">Ayarlar</p>
          </div>
          <div className="mt-3 h-[545px] w-full rounded-xl border bg-white px-7 py-7 font-medium">
            <p>Erişim ayarları</p>
            <div
              className={`mt-3 flex items-center rounded-md border p-4 ${
                localState.publishType === 1
                  ? "border-blue-500 bg-blue-100"
                  : "border-gray-200"
              }`}
            >
              <label
                htmlFor="bordered-checkbox-1"
                className="flex w-full cursor-pointer items-center justify-start text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mr-2"
                >
                  <g style={{ mixBlendMode: "multiply" }}>
                    <rect
                      x="2"
                      y="2"
                      width="32"
                      height="32"
                      rx="16"
                      fill="#D1E0FF"
                    />
                    <rect
                      x="2"
                      y="2"
                      width="32"
                      height="32"
                      rx="16"
                      stroke="#EFF4FF"
                      strokeWidth="4"
                    />
                    <path
                      d="M14.668 17.3333V15.3333C14.668 13.4924 16.1604 12 18.0013 12C19.6139 12 20.9591 13.1452 21.268 14.6667M15.868 24H20.1346C21.2547 24 21.8148 24 22.2426 23.782C22.6189 23.5903 22.9249 23.2843 23.1166 22.908C23.3346 22.4802 23.3346 21.9201 23.3346 20.8V20.5333C23.3346 19.4132 23.3346 18.8532 23.1166 18.4254C22.9249 18.049 22.6189 17.7431 22.2426 17.5513C21.8148 17.3333 21.2547 17.3333 20.1346 17.3333H15.868C14.7479 17.3333 14.1878 17.3333 13.76 17.5513C13.3837 17.7431 13.0777 18.049 12.886 18.4254C12.668 18.8532 12.668 19.4132 12.668 20.5333V20.8C12.668 21.9201 12.668 22.4802 12.886 22.908C13.0777 23.2843 13.3837 23.5903 13.76 23.782C14.1878 24 14.7479 24 15.868 24Z"
                      stroke="#2970FF"
                      strokeWidth="1.33333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </svg>
                <div>
                  <p className="m-0 font-medium text-gray-950">
                    Herkese Açık Form
                  </p>
                  <p className="m-0 font-light text-gray-400">
                    Form Herkese Açıktır
                  </p>
                </div>
              </label>
              <CheckboxBootstrap
                id="bordered-checkbox-1"
                name="bordered-checkbox-1"
                onChange={(e) => {
                  if (e.target.checked) {
                    setLocalState((prev) => {
                      const newChanges = { ...prev };
                      newChanges.publishType = 1;
                      return newChanges;
                    });
                  }
                }}
                checked={localState.publishType === 1 ? true : false}
              />
            </div>
            <div
              className={`mb-6 mt-3 flex items-center rounded-md border p-4 ${
                localState.publishType === 2
                  ? "border-blue-500 bg-blue-100"
                  : "border-gray-200"
              }`}
            >
              <label
                htmlFor="bordered-checkbox-2"
                className="flex w-full cursor-pointer items-center justify-start text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mr-2"
                >
                  <g style={{ mixBlendMode: "multiply" }}>
                    <rect
                      x="2"
                      y="2"
                      width="32"
                      height="32"
                      rx="16"
                      fill="#D1E0FF"
                    />
                    <rect
                      x="2"
                      y="2"
                      width="32"
                      height="32"
                      rx="16"
                      stroke="#EFF4FF"
                      strokeWidth="4"
                    />
                    <path
                      d="M21.3346 17.3333V15.3333C21.3346 13.4924 19.8423 12 18.0013 12C16.1604 12 14.668 13.4924 14.668 15.3333V17.3333M15.868 24H20.1346C21.2547 24 21.8148 24 22.2426 23.782C22.6189 23.5903 22.9249 23.2843 23.1166 22.908C23.3346 22.4802 23.3346 21.9201 23.3346 20.8V20.5333C23.3346 19.4132 23.3346 18.8532 23.1166 18.4254C22.9249 18.049 22.6189 17.7431 22.2426 17.5513C21.8148 17.3333 21.2547 17.3333 20.1346 17.3333H15.868C14.7479 17.3333 14.1878 17.3333 13.76 17.5513C13.3837 17.7431 13.0777 18.049 12.886 18.4254C12.668 18.8532 12.668 19.4132 12.668 20.5333V20.8C12.668 21.9201 12.668 22.4802 12.886 22.908C13.0777 23.2843 13.3837 23.5903 13.76 23.782C14.1878 24 14.7479 24 15.868 24Z"
                      stroke="#2970FF"
                      strokeWidth="1.33333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </svg>

                <div>
                  <p className="m-0 font-medium text-gray-950">Gizli Form</p>
                  <p className="m-0 font-light text-gray-400">
                    Yalnızca davet ettiğiniz kişiler tarafından erişilebilir
                  </p>
                </div>
              </label>
              <CheckboxBootstrap
                id="bordered-checkbox-2"
                name="bordered-checkbox"
                onChange={(e) => {
                  if (e.target.checked) {
                    setLocalState((prev) => {
                      const newChanges = { ...prev };
                      newChanges.publishType = 2;
                      return newChanges;
                    });
                  }
                }}
                checked={localState.publishType === 2 ? true : false}
              />
            </div>
            <hr />
            <p className="mt-6 font-medium">İzinler</p>
            <div className="mt-[10px] flex">
              <input
                id="1"
                type="radio"
                name="chooseSenders"
                className="form-check-input my-1 mr-2"
                checked={localState.publishPermission === 1 ? true : false}
                onChange={() =>
                  setLocalState((prev) => {
                    const newChanges = { ...prev };
                    newChanges.publishPermission = 1;
                    return newChanges;
                  })
                }
              />
              <label htmlFor="1">
                <p className="m-0">Sadece Gönder</p>
                <p className="m-0 text-sm font-normal">
                  Davet edilen kişiler formu sadece görüntüleyebilir ve
                  doldurabilirler.
                </p>
              </label>
            </div>
            <div className="mt-4 flex">
              <input
                id="2"
                type="radio"
                name="chooseSenders"
                className="form-check-input my-1 mr-2"
                checked={localState.publishPermission === 2 ? true : false}
                onChange={() =>
                  setLocalState((prev) => {
                    const newChanges = { ...prev };
                    newChanges.publishPermission = 2;
                    return newChanges;
                  })
                }
              />
              <label htmlFor="2">
                <p className="m-0">Gönder ve Daha Sonra Görüntüle</p>
                <p className="m-0 text-sm font-normal">
                  Davet edilen kişiler formu doldurabilir ve yanıtlarını
                  sonradan görüntüleyebilirler.
                </p>
              </label>
            </div>
            <div className="my-4 flex">
              <input
                id="3"
                type="radio"
                name="chooseSenders"
                className="form-check-input my-1 mr-2"
                checked={localState.publishPermission === 3 ? true : false}
                onChange={() =>
                  setLocalState((prev) => {
                    const newChanges = { ...prev };
                    newChanges.publishPermission = 3;
                    return newChanges;
                  })
                }
              />
              <label htmlFor="3">
                <p className="m-0">Gönder ve Daha Sonra Düzenle</p>
                <p className="m-0 text-sm font-normal">
                  Davet edilen kişiler formu doldurabilir ve yanıtlarını
                  sonradan görüntüleyip değiştirebilirler.
                </p>
              </label>
            </div>
            <hr />
            <div className="float-right mt-2">
              <button className="mr-2 cursor-pointer rounded-lg border-2 border-gray-300 px-3 py-2 text-center align-middle text-sm !text-gray-950 hover:!text-gray-400">
                Vazgeç
              </button>
              <button
                className="rounded-lg bg-[#2970ff] px-3 py-2 text-center align-middle text-sm text-white hover:bg-blue-700"
                onClick={handleSaveChanges}
              >
                Değişiklikleri Kaydet
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SurveyFastShare;

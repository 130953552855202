import { selectedUserAtom } from "components/atoms/dashboardAtoms";
import { useAtomValue } from "jotai";
import { getRowsForThisKeys } from "pages/dashboard2/EmployeeProfileSkillsTech";
import EmployeeProfileCard from "./EmployeeProfileCard";
import { SkillsTable } from "./EmployeeProfileSkillsPanel";

const sanatVeBeseriBilimler = [
  "Türkçe Dil Bilgisi",
  "Güzel Sanatlar Bilgisi",
  "Yabancı Dil Bilgisi",
  "Tarih ve Arkeoloji Bilgisi",
  "Felsefe ve Teoloji Bilgisi",
];

const isletmeVeYonetim = [
  "Yönetim Bilgisi",
  "İdari İşler Bilgisi",
  "Müşteri ve Kişisel Hizmet Bilgisi",
  "Ekonomi ve Muhasebe Bilgisi",
  "İnsan Kaynakları Yönetimi Bilgisi",
];

const iletisim = ["İletişim ve Medya Bilgisi", "Telekomünikasyon Bilgisi"];

const egitimVeOgretim = ["Eğitim ve Öğretim"];

const muhendislikVeTeknoloji = [
  "Yapı ve İnşaat Bilgisi",
  "Bilgisayar ve Elektronik Bilgisi",
  "Tasarım Bilgisi",
  "Mühendislik ve Teknoloji Bilgisi",
  "Mekanik Bilgisi",
];

const saglikHizmetleri = [
  "Tıp ve Diş Hekimliği Bilgisi",
  "Terapi ve Danışmanlık Bilgisi",
];

const hukukVeKamuGuvenligi = [
  "Hukuk ve Kamu Yönetimi Bilgisi",
  "Kamu Güvenliği ve Emniyeti Bilgisi",
];

const uretimVeImalat = ["Gıda Üretimi Bilgisi", "Üretim ve İşleme Bilgisi"];

const ulastirma = ["Ulaştırma"];

export const knowledgeSkills = [
  ...sanatVeBeseriBilimler,
  ...isletmeVeYonetim,
  ...iletisim,
  ...egitimVeOgretim,
  ...muhendislikVeTeknoloji,
  ...saglikHizmetleri,
  ...hukukVeKamuGuvenligi,
  ...uretimVeImalat,
  ...ulastirma,
].map((x) => ({ text: x, value: x }));

export default function EmployeeProfileSkillsTech() {
  const selectedUser = useAtomValue(selectedUserAtom);

  const getRowsForThisTab = (keys: string[]) => {
    return getRowsForThisKeys(selectedUser, "puanBilgi_", keys);
  };

  const CARDS = Object.freeze([
    {
      titleEmoji: "🎨",
      title: "Sanat ve Beşeri Bilimler",
      bgClassName: "bg-violet-100",
      score: selectedUser.puanBilgi_sanatVeBeseriBilimler.yuzde,
      rows: getRowsForThisTab(sanatVeBeseriBilimler),
    },
    {
      titleEmoji: "📈",
      title: "İşletme ve Yönetim",
      bgClassName: "bg-rose-100",
      score: selectedUser.puanBilgi_i̇sletmeVeYönetim.yuzde,
      rows: getRowsForThisTab(isletmeVeYonetim),
    },
    {
      titleEmoji: "📢",
      title: "İletişim",
      bgClassName: "bg-emerald-100",
      score: selectedUser.puanBilgi_i̇letisim.yuzde,
      rows: getRowsForThisTab(iletisim),
    },
    {
      titleEmoji: "🎓",
      title: "Eğitim ve Öğretim",
      bgClassName: "bg-amber-100",
      score: selectedUser.puanBilgi_egitimVeÖgretim.yuzde,
      rows: getRowsForThisTab(egitimVeOgretim),
    },
    {
      titleEmoji: "⚙️",
      title: "Mühendislik ve Teknoloji",
      bgClassName: "bg-gray-100",
      score: selectedUser.puanBilgi_muhendislikVeTeknoloji.yuzde,
      rows: getRowsForThisTab(muhendislikVeTeknoloji),
    },
    {
      titleEmoji: "🩺",
      title: "Sağlık Hizmetleri",
      bgClassName: "bg-cyan-100",
      score: selectedUser.puanBilgi_saglikHizmetleri.yuzde,
      rows: getRowsForThisTab(saglikHizmetleri),
    },
    {
      titleEmoji: "⚖️",
      title: "Hukuk ve Kamu Güvenliği",
      bgClassName: "bg-teal-100",
      score: selectedUser.puanBilgi_hukukVeKamuGuvenligi.yuzde,
      rows: getRowsForThisTab(hukukVeKamuGuvenligi),
    },
    {
      titleEmoji: "🏭",
      title: "Üretim ve İmalat",
      bgClassName: "bg-rose-100",
      score: selectedUser.puanBilgi_uretimVeİmalat.yuzde,
      rows: getRowsForThisTab(uretimVeImalat),
    },
    // {
    //   titleEmoji: "🚎",
    //   title: "Matematik ve Fen Bilimleri",
    //   bgClassName: "bg-blue-100",
    //   score: selectedUser.puanBilgi_matematikVeFenBilimleri.yuzde,
    //   rows: getRowsForThisTab(["aaa", "aaa", "aaa"]),
    // },
    {
      titleEmoji: "🚎",
      title: "Ulaştırma",
      bgClassName: "bg-blue-100",
      score: selectedUser.puanBilgi_ulastirma.yuzde,
      rows: getRowsForThisTab(ulastirma),
    },
  ]);

  return (
    <>
      {CARDS.map((card, index) => (
        <EmployeeProfileCard {...card} isCollapsible>
          <SkillsTable rows={card.rows} />
        </EmployeeProfileCard>
      ))}
    </>
  );
}

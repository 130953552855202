import { DragDropContext } from "@hello-pangea/dnd";
import { useAtom, useSetAtom } from "jotai";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import {
  surveyElementsInterface,
  surveyInterface,
} from "../Interfaces/SurveyInterfaces";
import { sidebarItems } from "../lib/consts";
import Logic from "./Logic/Logic";
import FormElements from "./SurveyDesign/FormElements";
import FormSettings from "./SurveyDesign/FormSettings";
import SurveyPart from "./SurveyDesign/SurveyPart";
import {
  elementIndexAtom,
  pageIndexAtom,
  showModalAtom,
  showSettingsAtom,
  surveyAtom,
} from "./atoms/surveyAtoms";
import Navbar from "./staticDesign/Navbar";
import QuestionDelete from "./staticDesign/QuestionDelete";
import QuestionPreview from "./staticDesign/QuestionPreview";

export const SURVER_MAIN_TABS = Object.freeze([
  "Oluştur",
  "Koşullar",
  // "Settings",
]);

interface PlaceholderProps {
  clientHeight: number;
  clientWidth: number;
  clientY: number;
  clientX: number;
}

const SurveyMain: React.FC = () => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const [elementIndex, setElementIndex] = useAtom(elementIndexAtom);
  const [pageIndex, setPageIndex] = useAtom(pageIndexAtom);
  const setShowSettings = useSetAtom(showSettingsAtom);
  const setShowModal = useSetAtom(showModalAtom);

  const [selectedTab, setSelectedTab] = useState<string>(SURVER_MAIN_TABS[0]);

  const queryAttr = "data-rfd-droppable-id";
  const [placeholderProps, setPlaceholderProps] = useState<
    PlaceholderProps | object
  >({});
  const [show, setShow] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const previousDraggableIdRef = useRef<string | null>(null);

  const [indexToDelete, setIndexToDelete] = useState<number>();

  useEffect(() => {
    if (containerRef.current) {
      // data-rbd-draggable-id değerini al
      const currentDraggableId = containerRef.current.getAttribute(
        "data-rfd-draggable-id",
      );

      if (
        currentDraggableId &&
        currentDraggableId !== previousDraggableIdRef.current
      ) {
        containerRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });

        // Mevcut draggableId'yi önceki draggableId olarak sakla.
        previousDraggableIdRef.current = currentDraggableId;
      }
    }
  }, [containerRef.current]);

  /*   const copy = (
    source: surveyElementsInterface[],
    destination: surveyElementsInterface[],
    droppableSource: { index: number },
    droppableDestination: { index: number }
  ): surveyElementsInterface[] => {
    const sourceClone = [...source];
    const destinationClone = [...destination];

    const [removedItem] = sourceClone.splice(droppableSource.index, 1);
    destinationClone.splice(droppableDestination.index, 0, {
      ...removedItem,
      _id: uuidv4(),
    });

    return destinationClone;
  }; */

  const onDragEnd = (result: any) => {
    const { source, destination, draggableId } = result;
    setPlaceholderProps({});

    if (!destination) {
      return;
    }
    switch (true) {
      case source.droppableId.includes("Sayfa"):
        const pageIndex = stateSurveyJSON.pages!.findIndex(
          (page) => page._id === destination.droppableId,
        );
        const pageIndexSource = stateSurveyJSON.pages!.findIndex(
          (page) => page._id === source.droppableId,
        );
        const updatedPages = [...(stateSurveyJSON.pages || [])];
        const [movedElement] =
          updatedPages[pageIndexSource].elements?.splice(source.index, 1) || [];
        updatedPages[pageIndex].elements?.splice(
          destination.index,
          0,
          movedElement,
        );
        setStateSurveyJSON((prevState) => ({
          ...prevState,
          pages: updatedPages,
        }));
        break;

      case source.droppableId === "sidebarItems":
        const draggedItem = sidebarItems.find(
          (sidebarItems) => sidebarItems.type === draggableId,
        );

        if (
          Boolean(stateSurveyJSON.pages) &&
          stateSurveyJSON.pages?.length === 0
        ) {
          const newElement = {
            _id: uuidv4(),
            created_by: "1",
            created_date: new Date(),
            modified_date: new Date(),
            isActive: true,
            surveyId: stateSurveyJSON._id,
            modified_by: "1",
            ...draggedItem,
          };

          const newPage = {
            _id: "Sayfa" + uuidv4(),
            elements: [newElement] as surveyElementsInterface[],
          };

          setStateSurveyJSON((prevState) => ({
            ...prevState,
            pages: [newPage],
          }));
        } else {
          const destinationPage = stateSurveyJSON.pages?.find(
            (page) => page._id === destination.droppableId,
          );

          if (!destinationPage) {
            const newElement = {
              _id: uuidv4(),
              created_by: "1",
              created_date: new Date(),
              modified_date: new Date(),
              isActive: true,
              surveyId: stateSurveyJSON._id,
              modified_by: "1",
              ...draggedItem,
            };

            const newPage = {
              _id: "Sayfa" + uuidv4(),
              elements: [newElement] as surveyElementsInterface[],
            };

            setStateSurveyJSON((prevState) => ({
              ...prevState,
              pages: [...(prevState.pages || []), newPage],
            }));
          } else {
            const pageIndex2 = stateSurveyJSON.pages!.findIndex(
              (page) => page._id === destination.droppableId,
            );
            const updatedPages2 = [...(stateSurveyJSON.pages || [])];

            const newElement = {
              _id: uuidv4(),
              created_by: "1",
              created_date: new Date(),
              modified_date: new Date(),
              isActive: true,
              surveyId: stateSurveyJSON._id,
              modified_by: "1",
              ...draggedItem,
            };

            updatedPages2[pageIndex2].elements?.splice(
              destination.index,
              0,
              newElement as surveyElementsInterface,
            );

            setStateSurveyJSON((prevState) => ({
              ...prevState,
              pages: updatedPages2,
            }));
          }
        }
        toast.success("Soru başarıyla çalışma alanına eklendi!");
        break;

      default:
        /* kaynağı her zaman oluyor destination zaten null olamaz ayarı koyduk ama ne olur ne olmaz */
        // TODO Bu alan son commitlerin gerisinde kaldı. Bu alanı düzenlemek gerekecek
        const defaultUpdatedPages = { ...stateSurveyJSON.pages };

        const sourcePageName = source.droppableId;
        const destinationPageName = destination.droppableId;

        const sourcePageIndex = defaultUpdatedPages.findIndex!(
          (page) => page._id === sourcePageName,
        );
        const destinationPageIndex = defaultUpdatedPages.findIndex!(
          (page) => page._id === destinationPageName,
        );

        if (sourcePageIndex !== -1 && destinationPageIndex !== -1) {
          const sourceElements = [
            ...(defaultUpdatedPages[sourcePageIndex].elements || []),
          ];
          const destinationElements = [
            ...(defaultUpdatedPages[destinationPageIndex].elements || []),
          ];

          const [removed] = sourceElements.splice(source.index, 1);
          destinationElements.splice(destination.index, 0, removed);

          defaultUpdatedPages[sourcePageIndex].elements = sourceElements;
          defaultUpdatedPages[destinationPageIndex].elements =
            destinationElements;

          setStateSurveyJSON(
            (prevState: surveyInterface) =>
              ({
                ...prevState,
                pages: defaultUpdatedPages,
                title: prevState.title,
              }) as surveyInterface,
          );
        }
    }
  };

  const handleOutsideClick = (e: any) => {
    if (
      e.target.classList.contains("fixed") ||
      e.target.classList.contains("inset-0")
    ) {
      setShowModal(-1);
    }
  };

  const handleOutsideClick2 = (e: any) => {
    if (
      e.target.classList.contains("fixed") ||
      e.target.classList.contains("inset-0") ||
      e.target.classList.contains("btn-danger") ||
      e.target.classList.contains("btn-outline-light")
    ) {
      setIndexToDelete(undefined);
    }
  };

  const handleDeleteElement = () => {
    if (typeof indexToDelete === "number") {
      if (indexToDelete === elementIndex) {
        setShowSettings(false);
      }
      if (elementIndex! > indexToDelete!) {
        setElementIndex(elementIndex! - 1);
      }

      setStateSurveyJSON((prevState) => {
        const updatedState = { ...prevState };

        if (
          updatedState.pages &&
          updatedState.pages.length > 0 &&
          updatedState.pages?.[pageIndex!]?.elements
        ) {
          updatedState.pages[pageIndex!].elements!.splice(indexToDelete!, 1);
        }

        return updatedState;
      });
      toast.error("Soru çalışma alanınızdan silindi!");
      setIndexToDelete(undefined);
    }
  };

  const handleDragUpdate = (event: any) => {
    if (!event.destination || event.source.droppableId !== "sidebarItems") {
      return;
    }
    // Olurda bir gün sorular arasında geçişte animasyon istenirse moved item kullanacağım
    const draggedDOM = getDestinationDoms(event.destination.droppableId) as any;

    if (!draggedDOM) {
      return;
    }
    const clientHeight = 40;
    const clientWidth = 636;
    const destinationIndex = event.destination.index;
    const sourceIndex = event.source.index;

    const childrenArray = [...draggedDOM.children];
    /*     const movedItem = childrenArray[sourceIndex];
    childrenArray.splice(sourceIndex, 1); */

    const updatedArray = [
      ...childrenArray.slice(0, destinationIndex),
      /*  movedItem, */
      ...childrenArray.slice(destinationIndex + 1),
    ];

    var clientY =
      parseFloat(window.getComputedStyle(draggedDOM.children[0]).paddingTop) +
      updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
        if (curr) {
          const style = window.getComputedStyle(curr);
          const marginBottom = parseFloat(style.marginBottom);
          return total + curr.clientHeight + marginBottom;
        } else {
          return total;
        }
      }, 0);

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(
        window.getComputedStyle(draggedDOM.parentNode.parentNode).paddingLeft,
      ),
    });
  };
  //kendi içerisinde array düzenlemesinde dragged dom kullanmam gerekecek
  /*   const getDraggedDom = (draggableId: any) => {
    const domQuery = `[${queryAttr}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery);

    return draggedDOM;
  }; */

  const getDestinationDoms = (destinationId: any) => {
    const domQuery = `[${queryAttr}='${destinationId}']`;
    const destDOMs = document.querySelectorAll(domQuery);
    //burada 2 tane aynı id li dom geliyordu 2. seçtim direkt best practise olmayabilir belki sonra class condition eklerim gerekirse
    return destDOMs[1];
  };

  return (
    <div className="flex flex-col">
      <Navbar selectedTab={selectedTab} setSelectedTab={setSelectedTab} />

      {selectedTab === "Oluştur" ? (
        <div className="flex">
          <DragDropContext
            onDragEnd={onDragEnd}
            onDragUpdate={handleDragUpdate}
          >
            <div
              className="hide-scrollbar relative z-10 basis-[276px]"
              style={{
                scrollbarGutter: "stable",
              }}
            >
              <FormElements
                show={show}
                setShow={setShow}
                containerRef={containerRef}
              />
            </div>
            <div className="hide-scrollbar mx-auto mt-10 h-[calc(100vh-108px)] min-w-[720px] max-w-[720px] flex-1 overflow-y-auto overflow-x-hidden">
              {/* TODO burada alan mx-auto yapılmamak isteniyormuş @media ile padding ayarlamalıyım */}
              <SurveyPart
                show={show}
                setShow={setShow}
                placeholderProps={placeholderProps}
                setShowSettings={setShowSettings}
                setPageIndex={setPageIndex}
                pageIndex={pageIndex ?? 0}
                containerRef={containerRef}
                setIndexToDelete={setIndexToDelete}
              />
            </div>
            <div
              className="hide-scrollbar h-[calc(100vh-108px)] basis-[277px] overflow-hidden"
              style={{
                scrollbarGutter: "stable",
              }}
            >
              <FormSettings />
            </div>
          </DragDropContext>
          <QuestionPreview handleOutsideClick={handleOutsideClick} />
          <QuestionDelete
            handleOutsideClick={handleOutsideClick2}
            handleDeleteElement={handleDeleteElement}
            indexToDelete={indexToDelete}
          />
        </div>
      ) : selectedTab === "Koşullar" ? (
        <Logic />
      ) : selectedTab === "Settings" ? (
        <div>Settings</div>
      ) : (
        <div>Not found</div>
      )}
    </div>
  );
};

export default SurveyMain;

import FormSettingsOptions from "../../components/FormSettingsOptions";
import FormSettingsSelectLibrary from "../../components/FormSettingsSelectLibrary";
import FormSettingsCalculation from "../../components/FormSettingsCalculation";
import FormSettingsWidth from "../../components/FormSettingsWidth";
import FormSettingsDefaultChoice from "../../components/FormSettingsDefaultChoice";
import FormSettingsSelectHolder from "../../components/FormSettingsSelectHolder";
import FormSettingsNoIdea from "../../components/FormSettingsNoIdea";
import FromSettingsOtherOption from "../../components/FromSettingsOtherOption";

export default function OpenableListOptionSettings() {
  return (
    <>
      <FormSettingsWidth />
      <FormSettingsOptions />
      <FormSettingsSelectLibrary />
      <FormSettingsNoIdea />
      <FromSettingsOtherOption />
      <FormSettingsDefaultChoice />
      <FormSettingsCalculation />
      <FormSettingsSelectHolder />
    </>
  );
}

import { useState } from "react";
import { Link } from "react-router-dom";
import FormCookerLogo from "../components/FormCookerLogo";
import PrimaryButton from "../components/tailwindUi/PrimaryButton";
import Alert from "../components/tailwindUi/Alert";
import LockResetPassword from "../images/lock-reset-password.svg";
import SentLetterResetPassword from "../images/sent-letter-reset-password.svg";

export default function ResetPassword() {
  const [isSent, setIsSent] = useState(false);

  const ResetPasswordFirst = () => {
    return (
      <div className="w-fit mx-auto mt-7 overflow-hidden rounded-lg bg-white shadow p-6">
        <div className="flex flex-col mt-7 sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="mx-auto w-12 h-12 px-3 pt-[11.63px] pb-[12.37px] bg-blue-100 rounded-full justify-center items-center inline-flex">
            <div className="w-6 h-6 relative flex-col justify-start items-start flex">
              <img src={LockResetPassword} />
            </div>
          </div>
          <p className="mt-3 text-center font-semibold font-['Inter'] text-base text-gray-900">
            Reset Password
          </p>

          <p className="mt-4 text-center font-normal font-['Inter'] text-sm text-gray-500">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur
            amet labore.
          </p>
          <form className="mt-5 space-y-5" action="#" method="POST">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                E-mail
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <PrimaryButton>Confirm</PrimaryButton>
          </form>
        </div>
      </div>
    );
  };

  const ResetPasswordSent = () => {
    return (
      <div className="w-fit mx-auto mt-7 overflow-hidden rounded-lg bg-white shadow p-6">
        <div className="flex flex-col mt-7 sm:mx-auto sm:w-full sm:max-w-sm">
          <img className="mx-auto w-12 h-12" src={SentLetterResetPassword} />

          <p className="mt-3 text-center font-semibold font-['Inter'] text-base text-gray-900">
            Reset Password
          </p>

          <p className="mt-4 text-center font-normal font-['Inter'] text-sm text-gray-500">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur
            amet labore.
          </p>
          <div className="my-5">
            <Alert
              title="We have sent a reset link to your e-mail"
              desc="Contact us at info@formcooker.com for help."
            />
          </div>

          <PrimaryButton isSecondary={true}>Try another e-mail</PrimaryButton>
        </div>
      </div>
    );
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <FormCookerLogo className="mx-auto" width={150} />
      </div>

      {!isSent && ResetPasswordFirst()}

      {isSent && ResetPasswordSent()}

      <div className="w-fit mx-auto mt-7">
        <Link
          className="text-blue-600 text-sm font-bold font-['Inter'] leading-tight"
          to="/login2"
        >
          Login
        </Link>
        <span className="text-slate-700 text-sm font-normal font-['Inter'] leading-tight">
          {" or "}
        </span>
        <Link
          className="text-blue-600 text-sm font-semibold font-['Inter'] leading-tight"
          to="/register2"
        >
          Sign up
        </Link>
      </div>
      {!isSent && <button onClick={() => setIsSent(true)}>set is sent</button>}
    </div>
  );
}

import FormSettingsTip from "../../components/FormSettingsTip";
import FormSettingsCollapse from "../../components/FormSettingsCollapse";
import FormSettingsReadOnly from "../../components/FormSettingsReadOnly";
import FormSettingsAddToLibrary from "../../components/FormSettingsAddToLibrary";
import FormSettingsPlaceholderforAddress from "../../components/placeholder/FormSettingsPlaceholderforAddress";

export default function AddressAdvanceSettings() {
  return (
    <>
      <FormSettingsPlaceholderforAddress />
      <FormSettingsTip />
      <FormSettingsCollapse />
      <FormSettingsReadOnly />
      <FormSettingsAddToLibrary />
    </>
  );
}

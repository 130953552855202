import { FC } from "react";
import ReactSlider from "react-slider";

type SliderProps = {
  colorNumber: number;
  onChange: (value: number) => void;
  min: number;
  max: number;
  value: number;
  leftValue?: number;
  localStateSwitch?: boolean;
};

const Slider: FC<SliderProps> = ({
  colorNumber,
  value,
  min,
  max,
  leftValue,
  onChange,
  localStateSwitch,
}) => {
  const conditionalStyle =
    colorNumber === 2 ? { left: (leftValue! + 1) * 18 } : {};

  return (
    <ReactSlider
      className="customSlider"
      trackClassName={
        colorNumber === 1
          ? "customSlider-track"
          : colorNumber === 2
            ? "customSlider-track-2"
            : colorNumber === 3
              ? "customSlider-track-3"
              : colorNumber === 4
                ? "customSlider-track-4"
                : "customSlider-track-5"
      }
      thumbClassName={
        colorNumber === 1
          ? "customSlider-thumb"
          : colorNumber === 2
            ? "customSlider-thumb-2"
            : colorNumber === 3
              ? "customSlider-thumb-3"
              : colorNumber === 4
                ? "customSlider-thumb-4"
                : "customSlider-thumb-5"
      }
      markClassName="customSlider-mark"
      marks={1}
      min={min}
      max={max}
      value={value}
      onChange={onChange}
      renderMark={(props: any) => {
        if (props.key < value) {
          props.className = "customSlider-mark customSlider-mark-before";
        } else if (props.key === value) {
          props.className = "customSlider-mark customSlider-mark-active";
        }
        return <span {...props} />;
      }}
      renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
      renderTrack={(props, state) => (
        <div
          {...props}
          style={{
            ...props.style,
            ...conditionalStyle,
          }}
        ></div>
      )}
    />
  );
};

export default Slider;

import { ReactNode, useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

interface RouteGuardProps {
  children: ReactNode;
}

function RouteGuard({ children }: RouteGuardProps) {
  const { isLoggedIn, isLoading } = useContext(AuthContext);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }

  if (!children) {
    return <div>Error: No content provided</div>;
  }

  return <>{children}</>;
}

export default RouteGuard;

import ScoreGraph1 from "../../images/score-graph-1.svg";
import ScoreGraph2 from "../../images/score-graph-2.svg";
import ScoreGraph3 from "../../images/score-graph-3.svg";
import ScoreGraph4 from "../../images/score-graph-4.svg";
import ScoreGraph5 from "../../images/score-graph-5.svg";

export default function EmployeeProfileScoreGraph({
  score,
}: {
  score: number;
}) {
  const scoreGraphs = [
    ScoreGraph1,
    ScoreGraph2,
    ScoreGraph3,
    ScoreGraph4,
    ScoreGraph5,
  ];

  return (
    <img
      src={scoreGraphs[Math.floor(score / 20)] ?? ScoreGraph5}
      alt="Score Graph"
      className="h-[38px] w-[256px]"
    />
  );
}

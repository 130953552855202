import { useMutation } from "react-query";
import {
  createSurveySetting,
  deleteSurveySetting,
  updateSurveySetting,
} from "../api/surveySettingsApi";

export function useCreateSurveySetting() {
  return useMutation({
    mutationFn: (data: any) => createSurveySetting(data),
    onMutate: () => {
      console.log("mutate");
    },

    onError: () => {
      console.log("error");
    },

    onSuccess: () => {
      console.log("success");
    },

    onSettled: async (_: any, error: any) => {
      console.log("settled");
      console.log(_);
      console.log(error);
    },
  });
}

export function useUpdateSurveySetting() {
  return useMutation({
    mutationFn: (data: any) => updateSurveySetting(data),

    onSettled: async (_: any, error: any, variables: any) => {
      if (error) {
        console.log(error);
      } else {
      }
    },
  });
}

export function useDeleteSurveySetting() {
  return useMutation({
    mutationFn: (id: string) => deleteSurveySetting(id),

    onSuccess: () => {
      console.log("deleted successfully");
    },

    onSettled: async (_: any, error: any) => {
      if (error) {
        console.log(error);
      } else {
      }
    },
  });
}

import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { AnimatePresence } from "framer-motion";
import LikertFormSettings from "../formSettings/likert/LikertFormSettings";
import TitleFormSettings from "../formSettings/title/TitleFormSettings";
import LongTextFormSettings from "../formSettings/longText/LongTextFormSettings";
import SmallTextFormSettings from "../formSettings/smallText/SmallTextFormSettings";
import OpenableListFormSettings from "../formSettings/openableList/OpenableListFormSettings";
import RankingFormSettings from "../formSettings/ranking/RankingFormSettings";
import RatingFormSettings from "../formSettings/rating/RatingFormSettings";
import SingleChoiceFormSettings from "../formSettings/singleChoice/SingleChoiceFormSettings";
import MultiChoiceFromSettings from "../formSettings/multiChoice/MultiChoiceFromSettings";
import DataTableFormSettings from "../formSettings/dataTable/DataTableFormSettings";
import FullNameFormSettings from "../formSettings/fullName/FullNameFormSettings";
import EmailFormSettings from "../formSettings/email/EmailFormSettings";
import AddressFormSettings from "../formSettings/address/AddressFormSettings";
import TelephoneFormSettings from "../formSettings/telephone/TelephoneFormSettings";
import NumberFormSettings from "../formSettings/number/NumberFormSettings";
import DocumentFormSettings from "../formSettings/document/DocumentFormSettings";
import HourFormSettings from "../formSettings/Hour/HourFormSettings";
import SignatureFormSettings from "../formSettings/signature/SignatureFormSettings";
import DateFormSettings from "../formSettings/date/DateFormSettings";
import ImagePickerFormSettings from "../formSettings/imagePicker/ImagePickerFormSettings";
import CSATFormSettings from "../formSettings/CSAT/CSATFormSettings";
import NPSFormSettings from "../formSettings/NPS/NPSFormSettings";
import CESFormSettings from "../formSettings/CES/CESFormSettings";
import { useAtomValue } from "jotai";
import {
  elementIndexAtom,
  pageIndexAtom,
  showSettingsAtom,
  surveyAtom,
} from "../atoms/surveyAtoms";

type SettingsProps = {
  formTitle?: string;
};

const settingsComponents: Readonly<{ [key: string]: React.FC<SettingsProps> }> =
  Object.freeze({
    likert: LikertFormSettings,
    title: TitleFormSettings,
    chapterTitle: TitleFormSettings,
    downChapterTitle: TitleFormSettings,
    longTextArea: LongTextFormSettings,
    textArea: SmallTextFormSettings,
    openableList: OpenableListFormSettings,
    ranking: RankingFormSettings,
    starRating: RatingFormSettings,
    singleChoice: SingleChoiceFormSettings,
    multiChoice: MultiChoiceFromSettings,
    dataTable: DataTableFormSettings,
    fullName: FullNameFormSettings,
    email: EmailFormSettings,
    address: AddressFormSettings,
    tel: TelephoneFormSettings,
    number: NumberFormSettings,
    document: DocumentFormSettings,
    hour: HourFormSettings,
    signature: SignatureFormSettings,
    date: DateFormSettings,
    imagePicker: ImagePickerFormSettings,
    CSAT: CSATFormSettings,
    NPS: NPSFormSettings,
    CES: CESFormSettings,
  });

const formTitleMapping: Readonly<{ [key: string]: string }> = Object.freeze({
  title: "Başlık Metni",
  chapterTitle: "Bölüm Başlığı Metni",
  downChapterTitle: "Alt Bölüm Başlığı Metni",
});

export default function FormSettings() {
  const stateSurveyJSON = useAtomValue(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);
  const showSettings = useAtomValue(showSettingsAtom);

  const [currentSettings, setCurrentSettings] = useState<string | undefined>();

  useEffect(() => {
    setCurrentSettings(
      stateSurveyJSON?.pages?.[pageIndex]?.elements?.[elementIndex]?.type,
    );
  }, [pageIndex, elementIndex, stateSurveyJSON?.pages]);

  const SettingsComponent = currentSettings
    ? settingsComponents[currentSettings]
    : null;

  const formTitle = formTitleMapping[currentSettings ?? ""];

  return (
    <AnimatePresence mode="wait" initial={false}>
      {showSettings && SettingsComponent && (
        <SettingsComponent formTitle={formTitle} />
      )}
    </AnimatePresence>
  );
}

/* eslint-disable react-hooks/rules-of-hooks */
import React, { CSSProperties, FC, HTMLProps, useRef } from "react";

import {
  Column,
  Table,
  ColumnDef,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  flexRender,
  RowData,
  SortingState,
  getSortedRowModel,
} from "@tanstack/react-table";
import { makeData } from "./makeData";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import {
  NewAreaResponseResult,
  surveyReply,
} from "../../../../Interfaces/SurveyInterfaces";
import { useDeleteSurveyReply } from "../../../../services/mutations/surveyReplyMutations";
import { useParams } from "react-router-dom";
import {
  svgArrForQuestionReplays,
  svgArrForQuestionReplaysHover,
  svgArrForQuestionReplaysSelected,
} from "../../../../lib/SvgElements";
import { MultiValueGenericProps } from "react-select/dist/declarations/src/components/MultiValue";
import { ValueContainerProps, components } from "react-select";

declare module "@tanstack/react-table" {
  interface TableMeta<TData extends RowData> {
    updateData: (rowIndex: number, columnId: string, value: unknown) => void;
  }
}

const getCommonPinningStyles = (column: Column<any>): CSSProperties => {
  const isPinned = column.getIsPinned();
  const isLastLeftPinnedColumn =
    isPinned === "left" && column.getIsLastColumn("left");
  const isFirstRightPinnedColumn =
    isPinned === "right" && column.getIsFirstColumn("right");

  return {
    boxShadow: isLastLeftPinnedColumn
      ? "-4px 0 4px -4px gray inset"
      : isFirstRightPinnedColumn
        ? "4px 0 4px -4px gray inset"
        : undefined,
    left: isPinned === "left" ? `${column.getStart("left")}px` : undefined,
    right: isPinned === "right" ? `${column.getAfter("right")}px` : undefined,
    opacity: isPinned ? 0.95 : 1,
    position: isPinned ? "sticky" : "relative",
    zIndex: isPinned ? 1 : 0,
  };
};

// Varsayılan sütun hücreleri düzenleme
const defaultColumn: Partial<ColumnDef<NewAreaResponseResult>> = {
  cell: ({ getValue, row: { index }, column: { id }, table }) => {
    const initialValue = getValue();
    const contentHeight = useRef<HTMLDivElement | null>(null);
    // Hücrenin durumunu korumamız ve güncellememiz için
    const [value, setValue] = React.useState(initialValue);
    const [previewMode, setPreviewMode] = React.useState(false);
    const [selectMode, setSelectMode] = React.useState(false);
    const [hoverRating, setHoverRating] = React.useState<number | null>(null);

    // blur sonrası update oluyor
    const onBlur = () => {
      table.options.meta?.updateData(index, id, value);
    };

    // dışarıdan değişirse direkt senkron
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    const handleRatingHover = (rating: number) => {
      setHoverRating(rating);
    };

    const handleRatingLeave = () => {
      setHoverRating(null);
    };

    // >>>>>>>>>>>>>>>>>>>>>> Text <<<<<<<<<<<<<<<<<<<<<<<<
    /*   
    <textarea
        className="text-sm text-[#475467] min-w-[124px] !border-none w-full resize-none overflow-hidden focus:overflow-auto text-area-result-page focus:min-h-[180px]"
        value={value as string}
        onChange={(e) => setValue(e.target.value)}
        onBlur={onBlur}
      />
    */

    // >>>>>>>>>>>>>>>>>>>>>> RATİNG <<<<<<<<<<<<<<<<<<<<<<<<
    /* 
      <div
        className={`flex items-center gap-1 flex-shrink-0 h-full w-full ${
          previewMode && "border border-blue-400"
        }`}
        onClick={() => setPreviewMode((prew) => !prew)}
      >
        {previewMode ? (
          <div className=" w-full flex justify-between items-center gap-1">
            <div className="flex flex-shrink-0 items-center gap-1">
              {[...Array(Number(10))].map((_, i) => (
                <button
                  key={i}
                  type="button"
                  onMouseOver={() => handleRatingHover(i + 1)}
                  onMouseLeave={handleRatingLeave}
                  onClick={() => {
                    setValue(i + 1);
                    setPreviewMode(false);
                  }}
                  onBlur={onBlur}
                >
                  {Number(value) !== null && Number(value) > i
                    ? svgArrForQuestionReplaysSelected.find(
                        (item) => item.key === "star"
                      )?.value
                    : hoverRating !== null && i < hoverRating
                      ? svgArrForQuestionReplaysHover.find(
                          (item) => item.key === "star"
                        )?.value
                      : svgArrForQuestionReplays.find(
                          (item) => item.key === "star"
                        )?.value}
                </button>
              ))}
            </div>
            <button onClick={() => setValue(initialValue)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <g clip-path="url(#clip0_1917_20858)">
                  <path
                    d="M12.5013 7.49935L7.5013 12.4993M7.5013 7.49935L12.5013 12.4993M18.3346 9.99935C18.3346 14.6017 14.6037 18.3327 10.0013 18.3327C5.39893 18.3327 1.66797 14.6017 1.66797 9.99935C1.66797 5.39698 5.39893 1.66602 10.0013 1.66602C14.6037 1.66602 18.3346 5.39698 18.3346 9.99935Z"
                    stroke="#667085"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1917_20858">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
          </div>
        ) : (
          <>
            {Number(value) ? (
              <>
                {[...Array(Number(value))].map((_, i) => (
                  <button key={i} type="button">
                    {
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="#2970FF"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.40156 2.8778C9.59363 2.48869 9.68966 2.29413 9.82004 2.23197C9.93347 2.17789 10.0653 2.17789 10.1787 2.23197C10.3091 2.29413 10.4051 2.48869 10.5972 2.8778L12.4194 6.56944C12.4761 6.68432 12.5045 6.74176 12.5459 6.78635C12.5826 6.82584 12.6266 6.85783 12.6754 6.88056C12.7306 6.90623 12.794 6.91549 12.9208 6.93402L16.9968 7.5298C17.4261 7.59253 17.6407 7.6239 17.74 7.72874C17.8264 7.81995 17.8671 7.94529 17.8506 8.06985C17.8317 8.21302 17.6763 8.36436 17.3656 8.66703L14.4172 11.5387C14.3253 11.6282 14.2794 11.673 14.2497 11.7263C14.2234 11.7734 14.2066 11.8252 14.2001 11.8788C14.1928 11.9393 14.2036 12.0025 14.2253 12.129L14.921 16.1851C14.9944 16.6129 15.031 16.8269 14.9621 16.9538C14.9021 17.0642 14.7955 17.1417 14.6719 17.1646C14.5299 17.1909 14.3378 17.0899 13.9536 16.8879L10.3097 14.9716C10.1961 14.9119 10.1394 14.882 10.0795 14.8703C10.0266 14.8599 9.97213 14.8599 9.91918 14.8703C9.85937 14.882 9.8026 14.9119 9.68906 14.9716L6.04512 16.8879C5.66094 17.0899 5.46886 17.1909 5.32683 17.1646C5.20325 17.1417 5.09662 17.0642 5.03663 16.9538C4.96768 16.8269 5.00437 16.6129 5.07774 16.1851L5.77342 12.129C5.79511 12.0025 5.80595 11.9393 5.79862 11.8788C5.79212 11.8252 5.77527 11.7734 5.74902 11.7263C5.71937 11.673 5.67341 11.6282 5.5815 11.5387L2.63315 8.66702C2.3224 8.36436 2.16703 8.21302 2.14812 8.06985C2.13167 7.94529 2.17231 7.81995 2.25872 7.72874C2.35804 7.6239 2.57266 7.59253 3.00189 7.5298L7.07794 6.93402C7.2047 6.91549 7.26808 6.90623 7.32328 6.88056C7.37215 6.85783 7.41615 6.82584 7.45284 6.78635C7.49427 6.74176 7.52262 6.68432 7.57933 6.56944L9.40156 2.8778Z"
                          stroke="#528BFF"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    }
                  </button>
                ))}
              </>
            ) : (
              <>
                {[...Array(Number(5))].map((_, i) => (
                  <button key={i} type="button">
                    {
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M9.40156 2.8778C9.59363 2.48869 9.68966 2.29413 9.82004 2.23197C9.93347 2.17789 10.0653 2.17789 10.1787 2.23197C10.3091 2.29413 10.4051 2.48869 10.5972 2.8778L12.4194 6.56944C12.4761 6.68432 12.5045 6.74176 12.5459 6.78635C12.5826 6.82584 12.6266 6.85783 12.6754 6.88056C12.7306 6.90623 12.794 6.91549 12.9208 6.93402L16.9968 7.5298C17.4261 7.59253 17.6407 7.6239 17.74 7.72874C17.8264 7.81995 17.8671 7.94529 17.8506 8.06985C17.8317 8.21302 17.6763 8.36436 17.3656 8.66703L14.4172 11.5387C14.3253 11.6282 14.2794 11.673 14.2497 11.7263C14.2234 11.7734 14.2066 11.8252 14.2001 11.8788C14.1928 11.9393 14.2036 12.0025 14.2253 12.129L14.921 16.1851C14.9944 16.6129 15.031 16.8269 14.9621 16.9538C14.9021 17.0642 14.7955 17.1417 14.6719 17.1646C14.5299 17.1909 14.3378 17.0899 13.9536 16.8879L10.3097 14.9716C10.1961 14.9119 10.1394 14.882 10.0795 14.8703C10.0266 14.8599 9.97213 14.8599 9.91918 14.8703C9.85937 14.882 9.8026 14.9119 9.68906 14.9716L6.04512 16.8879C5.66094 17.0899 5.46886 17.1909 5.32683 17.1646C5.20325 17.1417 5.09662 17.0642 5.03663 16.9538C4.96768 16.8269 5.00437 16.6129 5.07774 16.1851L5.77342 12.129C5.79511 12.0025 5.80595 11.9393 5.79862 11.8788C5.79212 11.8252 5.77527 11.7734 5.74902 11.7263C5.71937 11.673 5.67341 11.6282 5.5815 11.5387L2.63315 8.66702C2.3224 8.36436 2.16703 8.21302 2.14812 8.06985C2.13167 7.94529 2.17231 7.81995 2.25872 7.72874C2.35804 7.6239 2.57266 7.59253 3.00189 7.5298L7.07794 6.93402C7.2047 6.91549 7.26808 6.90623 7.32328 6.88056C7.37215 6.85783 7.41615 6.82584 7.45284 6.78635C7.49427 6.74176 7.52262 6.68432 7.57933 6.56944L9.40156 2.8778Z"
                          stroke="#D0D5DD"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    }
                  </button>
                ))}
              </>
            )}
          </>
        )}
      </div>
    
          */

    // >>>>>>>>>>>>>>>>>>>>>> Ranking <<<<<<<<<<<<<<<<<<<<<<<<
    /* 
     <div className="flex justify-center items-center gap-1 w-full">
      <label className="inline-block relative min-w-[2rem] min-h-[2rem] ">
        <button
          className="h-[26px] w-[26px] rounded-full border-2 p-[2px] border-[#2970FF] hover:border-blue-400 shadow-blue-200 absolute 
          align-top top-1 left-1  bg-clip-content rankingShadowBox text-white bg-[#2970FF] text-xs"
        >
          {5}
        </button>
      </label>
    </div>
  
   */

    // >>>>>>>>>>>>>>>>>>>>>> Tekli seçim  <<<<<<<<<<<<<<<<<<<<<<<<
    /* 
 <>
        {!Number(value) ? (
          <>
            <div
              className={`h-full w-full ${previewMode && "hidden"}`}
              onClick={() => setPreviewMode(true)}
            />
            {previewMode && (
              <>
                <Select
                  className="basic-single w-full"
                  placeholder={
                    <button className="flex h-[22px] p-[2px] justify-center items-center gap-[2px]rounded border border-[#2970FF] bg-[#2970FF]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                      >
                        <path
                          d="M7.0013 2.91602V11.0827M2.91797 6.99935H11.0846"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span className="text-white text-xs font-medium">
                        Ekle
                      </span>
                    </button>
                  }
                  isSearchable={false}
                  isClearable={true}
                  options={[
                    { value: "Mısır", label: "Mısır" },
                    { value: "Ispanak", label: "Ispanak" },
                    { value: "Soğan", label: "Soğan" },
                    { value: "Sarımsak", label: "Sarımsak" },
                  ]}
                  components={{ Option: CustomOption, DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                  onChange={(e) => setValue(e?.value)}
                  defaultValue={
                    value
                      ? {
                          value: value,
                          label: value,
                        }
                      : undefined
                  }
                />
              </>
            )}
          </>
        ) : (
          <button className="flex py-[2px] px-[6px] items-center border mx-1 rounded-2xl bg-[#EEF4FF] border-[C7D7FE]">
            <span className="text-center text-xs font-medium text-[#3538CD]">
              {(value as string).toString()}
            </span>
          </button>
        )}
      </>
*/

    // >>>>>>>>>>>>>>>>>>>>>> Çoklu seçim ve dropdown <<<<<<<<<<<<<<<<<<<<<<<<
    /* 
   <>
        {!Number(value) ? (
          <>
            <div
              className={`h-full w-full ${previewMode && "hidden"}`}
              onClick={() => setPreviewMode(true)}
            />
            {previewMode && (
              <>
                <Select
                  className="basic-single w-full"
                  styles={{
                    multiValue: (base) => ({
                      ...base,
                      display: "flex",
                      padding: "0px 2px",
                      alignItems: "center",
                      border: `1px solid ${"#C7D7FE"}`,
                      backgroundColor: "#EEF4FF",
                      borderRadius: "4px",
                      margin: "0px 4px",
                      fontSize: "12px",
                      fontWeight: "500",
                      color: "3538CD",
                      textAlign: "center",
                    }),
                  }}
                  placeholder={
                    <button className="flex h-[22px] p-[2px] justify-center items-center gap-[2px]rounded border border-[#2970FF] bg-[#2970FF]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                      >
                        <path
                          d="M7.0013 2.91602V11.0827M2.91797 6.99935H11.0846"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span className="text-white text-xs font-medium">
                        Ekle
                      </span>
                    </button>
                  }
                  isSearchable={false}
                  isClearable={true}
                  options={[
                    { value: "Mısır", label: "Mısır" },
                    { value: "Ispanak", label: "Ispanak" },
                    { value: "Soğan", label: "Soğan" },
                    { value: "Sarımsak", label: "Sarımsak" },
                    { value: "Sarımsak1", label: "Sarımsak1" },
                    { value: "Sarımsak2", label: "Sarımsak2" },
                    { value: "Sarımsak3", label: "Sarımsak3" },
                    { value: "Sarımsak4", label: "Sarımsak4" },
                    { value: "Sarımsak5", label: "Sarımsak5" },
                  ]}
                  isMulti={true}
                  components={{
                    Option: CustomOptionForMulti,
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                    MultiValueContainer: MultiValueContainerElement,
                  }}
                  onChange={(e) => setValue(e)}
                  defaultValue={
                    value
                      ? {
                          value: value,
                          label: value,
                        }
                      : undefined
                  }
                />
              </>
            )}
          </>
        ) : (
          <button className="flex py-[2px] px-[6px] items-center border mx-1 rounded-2xl bg-[#EEF4FF] border-[C7D7FE]">
            <span className="text-center text-xs font-medium text-[#3538CD]">
              {(value as string).toString()}
            </span>
          </button>
        )}
      </>
*/

    // >>>>>>>>>>>>>>>>>>>>>> dropdown <<<<<<<<<<<<<<<<<<<<<<<<
    /* 
         <>
        {!Number(value) ? (
          <>
            <div
              className={`h-full w-full ${previewMode && "hidden"}`}
              onClick={() => setPreviewMode(true)}
            />
            {previewMode && (
              <>
                <Select
                  className="basic-single w-full"
                  styles={{
                    multiValue: (base) => ({
                      ...base,
                      display: "flex",
                      padding: "0px 2px",
                      alignItems: "center",
                      border: `1px solid ${"#C7D7FE"}`,
                      backgroundColor: "#EEF4FF",
                      borderRadius: "4px",
                      margin: "0px 4px",
                      fontSize: "12px",
                      fontWeight: "500",
                      color: "3538CD",
                      textAlign: "center",
                    }),
                  }}
                  placeholder={
                    <button className="flex h-[22px] p-[2px] justify-center items-center gap-[2px]rounded border border-[#2970FF] bg-[#2970FF]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                      >
                        <path
                          d="M7.0013 2.91602V11.0827M2.91797 6.99935H11.0846"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span className="text-white text-xs font-medium">
                        Ekle
                      </span>
                    </button>
                  }
                  isSearchable={false}
                  isClearable={true}
                  options={[
                    { value: "Mısır", label: "Mısır" },
                    { value: "Ispanak", label: "Ispanak" },
                    { value: "Soğan", label: "Soğan" },
                    { value: "Sarımsak", label: "Sarımsak" },
                    { value: "Sarımsak1", label: "Sarımsak1" },
                    { value: "Sarımsak2", label: "Sarımsak2" },
                    { value: "Sarımsak3", label: "Sarımsak3" },
                    { value: "Sarımsak4", label: "Sarımsak4" },
                    { value: "Sarımsak5", label: "Sarımsak5" },
                  ]}
                  isMulti={true}
                  components={{
                    Option: CustomOptionForMulti,
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                    MultiValueContainer: MultiValueContainerElement,
                  }}
                  onChange={(e) => setValue(e)}
                  defaultValue={
                    value
                      ? {
                          value: value,
                          label: value,
                        }
                      : undefined
                  }
                />
              </>
            )}
          </>
        ) : (
          <button className="flex py-[2px] px-[6px] items-center border mx-1 rounded-2xl bg-[#EEF4FF] border-[C7D7FE]">
            <span className="text-center text-xs font-medium text-[#3538CD]">
              {(value as string).toString()}
            </span>
          </button>
        )}
      </>

*/

    // >>>>>>>>>>>>>>>>>>>>>> Ranking ama update <<<<<<<<<<<<<<<<<<<<<<<<
    /* 
     <div className="flex items-center px-2 gap-1 w-full h-full">
        {Number(value) ? (
          <>
            <label
              className={`flex justify-center items-center w-full h-hull relative min-w-[2rem] min-h-[2rem] ${
                previewMode && "hidden"
              }`}
              onClick={() => setPreviewMode(true)}
            >
              <button
                className="h-[26px] w-[26px] rounded-full border-2 p-[2px] border-[#2970FF] hover:border-blue-400 shadow-blue-200 absolute 
              align-top bg-clip-content rankingShadowBox text-white bg-[#2970FF] text-xs"
              >
                {Number(value) ? Number(value) : 99}
              </button>
            </label>
            {previewMode && (
              <>
                {[...Array(Number(10))].map((_, i) => (
                  <label
                    key={i}
                    className="inline-block relative min-w-[2rem] min-h-[2rem] "
                    onClick={() => {
                      setValue(i + 1);
                      setPreviewMode(false);
                      setSelectMode(false);
                    }}
                    onBlur={onBlur}
                  >
                    {Number(value) === i + 1 ? (
                      <button
                        className="h-[26px] w-[26px] rounded-full border p-[2px] border-[#2970FF] hover:border-blue-400 shadow-blue-200 absolute 
                    align-top top-1 left-1 bg-clip-content rankingShadowBox text-white bg-[#2970FF] text-xs"
                      >
                        {Number(value) ? Number(value) : 99}
                      </button>
                    ) : (
                      <button
                        className="h-[26px] w-[26px] rounded-full  p-[2px] border border-[#98A2B3] hover:border-blue-400 hover:text-[#2970FF] shadow-blue-200 absolute 
                        align-top top-1 left-1  bg-clip-content rankingShadowBox text-[#98A2B3] bg-[#fff] text-xs"
                      >
                        {i + 1}
                      </button>
                    )}
                  </label>
                ))}
              </>
            )}
          </>
        ) : (
          <>
            <div
              className={`h-full w-full ${previewMode && "hidden"}`}
              onClick={() => setPreviewMode(true)}
            />
            {previewMode && (
              <>
                <button
                  className={`flex h-[22px] p-[2px] justify-center items-center gap-[2px]rounded border rounded-md border-[#2970FF] bg-[#2970FF] ${
                    selectMode && "hidden"
                  }`}
                  onClick={() => setSelectMode(true)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                  >
                    <path
                      d="M7.0013 2.91602V11.0827M2.91797 6.99935H11.0846"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span className="text-white text-xs font-medium">Ekle</span>
                </button>
                {selectMode && (
                  <>
                    {[...Array(Number(10))].map((_, i) => (
                      <label
                        key={i}
                        className="inline-block relative min-w-[2rem] min-h-[2rem] "
                        onClick={() => {
                          setValue(i + 1);
                          setPreviewMode(false);
                          setSelectMode(false);
                        }}
                        onBlur={onBlur}
                      >
                        <button
                          className="h-[26px] w-[26px] rounded-full  p-[2px] border border-[#98A2B3] hover:border-blue-400 hover:text-[#2970FF] shadow-blue-200 absolute 
                      align-top top-1 left-1  bg-clip-content rankingShadowBox text-[#98A2B3] bg-[#fff] text-xs"
                        >
                          {i + 1}
                        </button>
                      </label>
                    ))}
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    );

    */
    /* 
    <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< dataTable >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
     <>
        {Number(value) && (
          <>
            <div
              className={`h-full w-full ${previewMode && "hidden"}`}
              onClick={() => setPreviewMode(true)}
            ></div>
            {previewMode && (
              <div
                className="max-w-[550px] w-full absolute top-1/2 left-1/2"
                style={{ transform: "translate(-50%, -50%)" }}
              >
                <div className="overflow-hidden">
                  <button
                    className={`flex h-[22px] p-[2px] justify-center items-center gap-[2px]rounded border border-[#2970FF] bg-[#2970FF] ${
                      selectMode && "hidden"
                    }`}
                    onClick={() => setSelectMode(true)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <path
                        d="M7.0013 2.91602V11.0827M2.91797 6.99935H11.0846"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span className="text-white text-xs font-medium">Ekle</span>
                  </button>
                  <div
                    ref={contentHeight}
                    className="px-4"
                    style={
                      selectMode
                        ? { height: contentHeight.current?.scrollHeight }
                        : { height: "0px" }
                    }
                  >
                    <div className="flex items-start">
                      <MatrixTableResponse /> 
                      <div className="text-blue-400  rotate-90 w-4 whitespace-nowrap  mx-2">
                        <span className="flex justify-start items-center ml-4 w-24 cursor-pointer hover:bg-blue-50 p-1">
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className=""
                              viewBox="0 0 16 16"
                            >
                              <path
                                fillRule="evenodd"
                                d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                              />
                            </svg>
                          </span>
                          Sütün ekle
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </>
    */
    return (
      <div
        className={`flex h-full w-full flex-shrink-0 items-center gap-1 ${
          previewMode && "border border-blue-400"
        }`}
        onClick={() => setPreviewMode((prew) => !prew)}
      >
        {previewMode ? (
          <div className="flex w-full items-center justify-between gap-1">
            <div className="flex flex-shrink-0 items-center gap-1">
              {[...Array(Number(10))].map((_, i) => (
                <button
                  key={i}
                  type="button"
                  onMouseOver={() => handleRatingHover(i + 1)}
                  onMouseLeave={handleRatingLeave}
                  onClick={() => {
                    setValue(i + 1);
                    setPreviewMode(false);
                  }}
                  onBlur={onBlur}
                >
                  {Number(value) !== null && Number(value) > i
                    ? svgArrForQuestionReplaysSelected.find(
                        (item) => item.key === "star",
                      )?.value
                    : hoverRating !== null && i < hoverRating
                      ? svgArrForQuestionReplaysHover.find(
                          (item) => item.key === "star",
                        )?.value
                      : svgArrForQuestionReplays.find(
                          (item) => item.key === "star",
                        )?.value}
                </button>
              ))}
            </div>
            <button onClick={() => setValue(initialValue)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <g clip-path="url(#clip0_1917_20858)">
                  <path
                    d="M12.5013 7.49935L7.5013 12.4993M7.5013 7.49935L12.5013 12.4993M18.3346 9.99935C18.3346 14.6017 14.6037 18.3327 10.0013 18.3327C5.39893 18.3327 1.66797 14.6017 1.66797 9.99935C1.66797 5.39698 5.39893 1.66602 10.0013 1.66602C14.6037 1.66602 18.3346 5.39698 18.3346 9.99935Z"
                    stroke="#667085"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1917_20858">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
          </div>
        ) : (
          <>
            {Number(value) ? (
              <>
                {[...Array(Number(value))].map((_, i) => (
                  <button key={i} type="button">
                    {
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="#2970FF"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.40156 2.8778C9.59363 2.48869 9.68966 2.29413 9.82004 2.23197C9.93347 2.17789 10.0653 2.17789 10.1787 2.23197C10.3091 2.29413 10.4051 2.48869 10.5972 2.8778L12.4194 6.56944C12.4761 6.68432 12.5045 6.74176 12.5459 6.78635C12.5826 6.82584 12.6266 6.85783 12.6754 6.88056C12.7306 6.90623 12.794 6.91549 12.9208 6.93402L16.9968 7.5298C17.4261 7.59253 17.6407 7.6239 17.74 7.72874C17.8264 7.81995 17.8671 7.94529 17.8506 8.06985C17.8317 8.21302 17.6763 8.36436 17.3656 8.66703L14.4172 11.5387C14.3253 11.6282 14.2794 11.673 14.2497 11.7263C14.2234 11.7734 14.2066 11.8252 14.2001 11.8788C14.1928 11.9393 14.2036 12.0025 14.2253 12.129L14.921 16.1851C14.9944 16.6129 15.031 16.8269 14.9621 16.9538C14.9021 17.0642 14.7955 17.1417 14.6719 17.1646C14.5299 17.1909 14.3378 17.0899 13.9536 16.8879L10.3097 14.9716C10.1961 14.9119 10.1394 14.882 10.0795 14.8703C10.0266 14.8599 9.97213 14.8599 9.91918 14.8703C9.85937 14.882 9.8026 14.9119 9.68906 14.9716L6.04512 16.8879C5.66094 17.0899 5.46886 17.1909 5.32683 17.1646C5.20325 17.1417 5.09662 17.0642 5.03663 16.9538C4.96768 16.8269 5.00437 16.6129 5.07774 16.1851L5.77342 12.129C5.79511 12.0025 5.80595 11.9393 5.79862 11.8788C5.79212 11.8252 5.77527 11.7734 5.74902 11.7263C5.71937 11.673 5.67341 11.6282 5.5815 11.5387L2.63315 8.66702C2.3224 8.36436 2.16703 8.21302 2.14812 8.06985C2.13167 7.94529 2.17231 7.81995 2.25872 7.72874C2.35804 7.6239 2.57266 7.59253 3.00189 7.5298L7.07794 6.93402C7.2047 6.91549 7.26808 6.90623 7.32328 6.88056C7.37215 6.85783 7.41615 6.82584 7.45284 6.78635C7.49427 6.74176 7.52262 6.68432 7.57933 6.56944L9.40156 2.8778Z"
                          stroke="#528BFF"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    }
                  </button>
                ))}
              </>
            ) : (
              <>
                {[...Array(Number(5))].map((_, i) => (
                  <button key={i} type="button">
                    {
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M9.40156 2.8778C9.59363 2.48869 9.68966 2.29413 9.82004 2.23197C9.93347 2.17789 10.0653 2.17789 10.1787 2.23197C10.3091 2.29413 10.4051 2.48869 10.5972 2.8778L12.4194 6.56944C12.4761 6.68432 12.5045 6.74176 12.5459 6.78635C12.5826 6.82584 12.6266 6.85783 12.6754 6.88056C12.7306 6.90623 12.794 6.91549 12.9208 6.93402L16.9968 7.5298C17.4261 7.59253 17.6407 7.6239 17.74 7.72874C17.8264 7.81995 17.8671 7.94529 17.8506 8.06985C17.8317 8.21302 17.6763 8.36436 17.3656 8.66703L14.4172 11.5387C14.3253 11.6282 14.2794 11.673 14.2497 11.7263C14.2234 11.7734 14.2066 11.8252 14.2001 11.8788C14.1928 11.9393 14.2036 12.0025 14.2253 12.129L14.921 16.1851C14.9944 16.6129 15.031 16.8269 14.9621 16.9538C14.9021 17.0642 14.7955 17.1417 14.6719 17.1646C14.5299 17.1909 14.3378 17.0899 13.9536 16.8879L10.3097 14.9716C10.1961 14.9119 10.1394 14.882 10.0795 14.8703C10.0266 14.8599 9.97213 14.8599 9.91918 14.8703C9.85937 14.882 9.8026 14.9119 9.68906 14.9716L6.04512 16.8879C5.66094 17.0899 5.46886 17.1909 5.32683 17.1646C5.20325 17.1417 5.09662 17.0642 5.03663 16.9538C4.96768 16.8269 5.00437 16.6129 5.07774 16.1851L5.77342 12.129C5.79511 12.0025 5.80595 11.9393 5.79862 11.8788C5.79212 11.8252 5.77527 11.7734 5.74902 11.7263C5.71937 11.673 5.67341 11.6282 5.5815 11.5387L2.63315 8.66702C2.3224 8.36436 2.16703 8.21302 2.14812 8.06985C2.13167 7.94529 2.17231 7.81995 2.25872 7.72874C2.35804 7.6239 2.57266 7.59253 3.00189 7.5298L7.07794 6.93402C7.2047 6.91549 7.26808 6.90623 7.32328 6.88056C7.37215 6.85783 7.41615 6.82584 7.45284 6.78635C7.49427 6.74176 7.52262 6.68432 7.57933 6.56944L9.40156 2.8778Z"
                          stroke="#D0D5DD"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    }
                  </button>
                ))}
              </>
            )}
          </>
        )}
      </div>
    );
  },
};

function useSkipper() {
  const shouldSkipRef = React.useRef(true);
  const shouldSkip = shouldSkipRef.current;

  // pagination sıfırlamasını geçici olarak atlamak için bu işlevle sardık
  const skip = React.useCallback(() => {
    shouldSkipRef.current = false;
  }, []);

  React.useEffect(() => {
    shouldSkipRef.current = true;
  });

  return [shouldSkip, skip] as const;
}
interface ResponsesI {
  userDatas: surveyReply[];
}

const Responses: FC<ResponsesI> = ({ userDatas }) => {
  const [rowSelection, setRowSelection] = React.useState({});
  const [data, setData] = React.useState(() => makeData(userDatas));
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const deleteSurveyReplyMutation = useDeleteSurveyReply();
  const { surveyId } = useParams<{ surveyId: string }>();
  const columnsForRow1: any = [];
  const addedKeys = new Set();
  data.forEach((element) => {
    Object.keys(element).forEach((key) => {
      if (!addedKeys.has(key)) {
        columnsForRow1.push({
          accessorFn: (row: any) => row[key.replace(/\s/g, "")],
          accessorKey: key.replace(/\s/g, ""),
          id: key.replace(/\s/g, ""),
          header: () => (
            <span className="text-xs font-semibold text-[#475467]">{key}</span>
          ),
          footer: (props: any) => props.column.id,
        });
        addedKeys.add(key);
      }
    });
  });

  const columns = React.useMemo<ColumnDef<NewAreaResponseResult>[]>(
    () => [
      {
        id: "select",
        header: ({ table }) => (
          <div className="flex items-center justify-center gap-3 text-xs font-semibold text-[#475467]">
            <IndeterminateCheckbox
              {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler(),
              }}
            />
            {"ID"}
          </div>
        ),
        cell: ({ row }) => {
          return (
            <div className="flex items-center justify-center gap-3 px-2 py-1 text-sm font-semibold text-[#475467]">
              <IndeterminateCheckbox
                {...{
                  checked: row.getIsSelected(),
                  disabled: !row.getCanSelect(),
                  indeterminate: row.getIsSomeSelected(),
                  onChange: row.getToggleSelectedHandler(),
                }}
              />
              {100000 + Number(row.index)}
            </div>
          );
        },
      },
      {
        id: "beGone",
        columns: columnsForRow1,
      },
    ],
    [],
  );

  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();
  const duzenlenmisArray = React.useMemo(() => {
    return data.map((obj) => {
      const newObj: any = {};
      Object.keys(obj).forEach((key) => {
        const newKey = key.replace(/\s/g, "");
        newObj[newKey] = obj[key];
      });
      return newObj;
    });
  }, [data]);

  const table = useReactTable({
    data: duzenlenmisArray,
    columns,
    defaultColumn,
    columnResizeMode: "onChange",
    enableRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onRowSelectionChange: setRowSelection,
    getSortedRowModel: getSortedRowModel(),
    autoResetPageIndex,
    /* getRowId: (row, index) => {
      return userDatas[index]._id;
    }, */
    state: {
      sorting,
      rowSelection,
    },
    onSortingChange: setSorting,
    //  Tablo meta verimize updateData işlevimiz
    meta: {
      updateData: (rowIndex, columnId, value) => {
        // Sayfa dizinini bir sonraki rerender sonra sıfırlamayı atlar
        skipAutoResetPageIndex();
        setData((old: NewAreaResponseResult[]) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex]!,
                [columnId]: value,
              } as NewAreaResponseResult;
            }
            return row;
          }),
        );
      },
    },
    debugTable: true,
    debugHeaders: true,
    debugColumns: true,
  });

  const columnSizeVars = React.useMemo(() => {
    const headers = table.getFlatHeaders();
    const colSizes: { [key: string]: number } = {};
    for (let i = 0; i < headers.length; i++) {
      const header = headers[i]!;
      colSizes[`--header-${header.id}-size`] = header.getSize();
      colSizes[`--col-${header.column.id}-size`] = header.column.getSize();
    }
    return colSizes;
  }, [table.getState().columnSizingInfo]);

  return (
    <div className="mt-4 rounded-xl border border-[#eaecf0] p-2">
      <div className="table-result-page-scroller relative mx-auto mb-2 overflow-auto pb-2">
        <div
          {...{
            className: "divTable",
            style: {
              ...columnSizeVars,
              width: table.getTotalSize(),
            },
          }}
        >
          <thead className="thead">
            {table.getHeaderGroups().map((headerGroup) => (
              <div
                {...{
                  key: headerGroup.id,
                  className: `tr-table-response`,
                  style: {
                    display: headerGroup.id === "0" ? "none" : "flex",
                  } /* bu alanı üstü yok etmek için kullandım? */,
                }}
              >
                {headerGroup.headers.map((header) => {
                  const { column } = header;
                  return (
                    <div
                      {...{
                        key: header.id,
                        colSpan: header.colSpan,
                        className:
                          "flex justify-between items-center th-table-response",
                        style: {
                          width: `calc(var(--header-${header?.id}-size) * 1px)`,
                          ...getCommonPinningStyles(column),
                        },
                      }}
                    >
                      {header.isPlaceholder ? null : (
                        <div
                          className={
                            header.column.getCanSort()
                              ? "cursor-pointer select-none"
                              : ""
                          }
                          onClick={header.column.getToggleSortingHandler()}
                          title={
                            header.column.getCanSort()
                              ? header.column.getNextSortingOrder() === "asc"
                                ? "Sort ascending"
                                : header.column.getNextSortingOrder() === "desc"
                                  ? "Sort descending"
                                  : "Clear sort"
                              : undefined
                          }
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                          {{
                            asc: " 🔼 ",
                            desc: " 🔽",
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      )}
                      {!header.isPlaceholder && header.column.getCanPin() && (
                        <div className="flex justify-center gap-1">
                          {header.column.getIsPinned() !== "left" ? (
                            <div
                              className="h-4 w-4 flex-shrink-0 cursor-pointer"
                              onClick={() => {
                                header.column.pin("left");
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <path
                                  d="M4 6L8 10L12 6"
                                  stroke="#475467"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                          ) : null}
                          {header.column.getIsPinned() ? (
                            <div
                              className="h-4 w-4 flex-shrink-0 cursor-pointer"
                              onClick={() => {
                                header.column.pin(false);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <path
                                  d="M4 6L8 10L12 6"
                                  stroke="#475467"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                          ) : null}
                        </div>
                      )}
                      <div
                        {...{
                          onDoubleClick: () => header.column.resetSize(),
                          onMouseDown: header.getResizeHandler(),
                          onTouchStart: header.getResizeHandler(),
                          className: `resizer ${
                            header.column.getIsResizing() ? "isResizing" : ""
                          }`,
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            ))}
          </thead>
          <TableBody table={table} />
        </div>
      </div>
      <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
        <div className="inline-flex items-start gap-4">
          <button
            className="flex items-center justify-center gap-1 rounded-lg border border-[#528BFF] bg-white px-3 py-2"
            style={{ boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              className="h-5 w-5"
            >
              <g clipPath="url(#clip0_2587_23833)">
                <path
                  d="M10.0013 6.66602V13.3327M6.66797 9.99935H13.3346M18.3346 9.99935C18.3346 14.6017 14.6037 18.3327 10.0013 18.3327C5.39893 18.3327 1.66797 14.6017 1.66797 9.99935C1.66797 5.39698 5.39893 1.66602 10.0013 1.66602C14.6037 1.66602 18.3346 5.39698 18.3346 9.99935Z"
                  stroke="#2970FF"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_2587_23833">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span className="text-sm font-bold text-[#2970FF]">
              Yeni Yanıt Ekle
            </span>
          </button>
          <button
            className={`flex items-center justify-center gap-1 rounded-lg border bg-white px-3 py-2 ${
              Object.keys(rowSelection).length === 0
                ? "border-[#EAECF0] text-[#98A2B3]"
                : "border-[#afb0b3] text-[#3c4046]"
            }`}
            style={{ boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)" }}
            disabled={Object.keys(rowSelection).length === 0}
            onClick={() => {
              const confirmed = window.confirm(
                "Are you sure you want to delete?",
              );

              if (confirmed) {
                Object.keys(table.getState().rowSelection)
                  .filter((key) => table.getState().rowSelection[key])
                  .forEach((key: string) => {
                    deleteSurveyReplyMutation.mutate({
                      id: key,
                      surveyId: surveyId,
                    });
                  });
              }
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              className="h-5 w-5"
            >
              <path
                d="M13.3333 4.99935V4.33268C13.3333 3.39926 13.3333 2.93255 13.1517 2.57603C12.9919 2.26243 12.7369 2.00746 12.4233 1.84767C12.0668 1.66602 11.6001 1.66602 10.6667 1.66602H9.33333C8.39991 1.66602 7.9332 1.66602 7.57668 1.84767C7.26308 2.00746 7.00811 2.26243 6.84832 2.57603C6.66667 2.93255 6.66667 3.39926 6.66667 4.33268V4.99935M8.33333 9.58268V13.7493M11.6667 9.58268V13.7493M2.5 4.99935H17.5M15.8333 4.99935V14.3327C15.8333 15.7328 15.8333 16.4329 15.5608 16.9677C15.3212 17.4381 14.9387 17.8205 14.4683 18.0602C13.9335 18.3327 13.2335 18.3327 11.8333 18.3327H8.16667C6.76654 18.3327 6.06647 18.3327 5.53169 18.0602C5.06129 17.8205 4.67883 17.4381 4.43915 16.9677C4.16667 16.4329 4.16667 15.7328 4.16667 14.3327V4.99935"
                stroke="#98A2B3"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span className="text-sm font-bold text-[#98A2B3]">
              Seçilen Yanıtları Sil
            </span>
          </button>
          <button
            className="flex items-center justify-center gap-1 rounded-lg border border-[#F97066] bg-[#F97066] px-3 py-2"
            style={{ boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)" }}
            onClick={() => {
              const confirmed = window.confirm(
                "Are you sure you want to delete?",
              );

              if (confirmed) {
                userDatas.forEach((element) => {
                  deleteSurveyReplyMutation.mutate({
                    id: element._id,
                    surveyId: surveyId,
                  });
                });
              }
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              className="h-5 w-5"
            >
              <path
                d="M13.3333 4.99935V4.33268C13.3333 3.39926 13.3333 2.93255 13.1517 2.57603C12.9919 2.26243 12.7369 2.00746 12.4233 1.84767C12.0668 1.66602 11.6001 1.66602 10.6667 1.66602H9.33333C8.39991 1.66602 7.9332 1.66602 7.57668 1.84767C7.26308 2.00746 7.00811 2.26243 6.84832 2.57603C6.66667 2.93255 6.66667 3.39926 6.66667 4.33268V4.99935M8.33333 9.58268V13.7493M11.6667 9.58268V13.7493M2.5 4.99935H17.5M15.8333 4.99935V14.3327C15.8333 15.7328 15.8333 16.4329 15.5608 16.9677C15.3212 17.4381 14.9387 17.8205 14.4683 18.0602C13.9335 18.3327 13.2335 18.3327 11.8333 18.3327H8.16667C6.76654 18.3327 6.06647 18.3327 5.53169 18.0602C5.06129 17.8205 4.67883 17.4381 4.43915 16.9677C4.16667 16.4329 4.16667 15.7328 4.16667 14.3327V4.99935"
                stroke="white"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span className="text-sm font-bold text-white">
              Tüm Yanıtları Sil
            </span>
          </button>
        </div>
        <div className="flex flex-1 items-center justify-end">
          <select
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
            className="mr-3 h-9 rounded-lg border border-[#D0D5DD] bg-white" /* TODO yazı ve ikon paralel olacak */
            style={{ boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)" }}
          >
            {[10, 20, 50, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
          <nav
            className="-space-x-px isolate inline-flex rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <button
              className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:bg-gray-200"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>

            <button
              onClick={() => table.setPageIndex(0)}
              disabled={table.getState().pagination.pageIndex === 0}
              className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:bg-gray-200"
            >
              1
            </button>
            {table.getState().pagination.pageIndex > 2 && (
              <span className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:bg-gray-200">
                ...
              </span>
            )}
            {table.getState().pagination.pageIndex ===
              table.getPageCount() - 1 &&
              table.getPageCount() > 2 && (
                <button
                  onClick={() =>
                    table.setPageIndex(
                      table.getState().pagination.pageIndex - 2,
                    )
                  }
                  className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:bg-gray-200"
                >
                  {table.getState().pagination.pageIndex - 1}
                </button>
              )}
            {table.getState().pagination.pageIndex > 1 && (
              <button
                onClick={() =>
                  table.setPageIndex(table.getState().pagination.pageIndex - 1)
                }
                className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:bg-gray-200"
              >
                {table.getState().pagination.pageIndex}
              </button>
            )}
            {table.getState().pagination.pageIndex !== 0 &&
              table.getState().pagination.pageIndex !==
                table.getPageCount() - 1 && (
                <button
                  onClick={() =>
                    table.setPageIndex(table.getState().pagination.pageIndex)
                  }
                  disabled
                  className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:bg-gray-200"
                >
                  {table.getState().pagination.pageIndex + 1}
                </button>
              )}
            {table.getState().pagination.pageIndex <
              table.getPageCount() - 2 && (
              <button
                onClick={() =>
                  table.setPageIndex(table.getState().pagination.pageIndex + 1)
                }
                className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:bg-gray-200"
              >
                {table.getState().pagination.pageIndex + 2}
              </button>
            )}
            {table.getState().pagination.pageIndex === 0 &&
              table.getPageCount() > 3 && (
                <button
                  onClick={() =>
                    table.setPageIndex(
                      table.getState().pagination.pageIndex + 2,
                    )
                  }
                  className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:bg-gray-200"
                >
                  {table.getState().pagination.pageIndex + 3}
                </button>
              )}
            {table.getState().pagination.pageIndex <
              table.getPageCount() - 3 && (
              <span className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:bg-gray-200">
                ...
              </span>
            )}

            <button
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={
                table.getState().pagination.pageIndex ===
                table.getPageCount() - 1
              }
              className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:bg-gray-200"
            >
              {table.getPageCount()}
            </button>
            <button
              className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:bg-gray-200"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              <span className="sr-only">Sonraki</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
        <span className="flex items-center gap-1">
          <input
            type="number"
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              table.setPageIndex(page);
            }}
            className="ml-2 w-8 rounded border p-1"
          />
        </span>
      </div>
    </div>
  );
};
/* function Filter({
  column,
  table,
}: {
  column: Column<any, any>;
  table: Table<any>;
}) {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  return typeof firstValue === "number" ? (
    <div className="flex space-x-2">
      <input
        type="number"
        value={(columnFilterValue as [number, number])?.[0] ?? ""}
        onChange={(e) =>
          column.setFilterValue((old: [number, number]) => [
            e.target.value,
            old?.[1],
          ])
        }
        placeholder={`Min`}
        className="w-24 border shadow rounded"
      />
      <input
        type="number"
        value={(columnFilterValue as [number, number])?.[1] ?? ""}
        onChange={(e) =>
          column.setFilterValue((old: [number, number]) => [
            old?.[0],
            e.target.value,
          ])
        }
        placeholder={`Max`}
        className="w-24 border shadow rounded"
      />
    </div>
  ) : (
    <input
      type="text"
      value={(columnFilterValue ?? "") as string}
      onChange={(e) => column.setFilterValue(e.target.value)}
      placeholder={`Search...`}
      className="w-36 border shadow rounded"
    />
  );
} */

function TableBody({ table }: { table: Table<NewAreaResponseResult> }) {
  return (
    <div
      {...{
        className: "tbody",
      }}
    >
      {table.getRowModel().rows.map((row) => (
        <div
          {...{
            key: row.id,
            className: "tr-table-response",
          }}
        >
          {row.getVisibleCells().map((cell) => {
            const { column } = cell;
            return (
              <div
                {...{
                  key: cell.id,
                  className: "td-table-response",
                  style: {
                    width: `calc(var(--col-${cell.column.id}-size) * 1px)`,
                    padding: "1px 1px",
                    ...getCommonPinningStyles(column),
                  },
                }}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
}

//daha sonra kullanılabilir ama şuan checkbox memonun anlamını yitirtiyor sonra checkboxın paramını memoya atıcam
/* export const MemoizedTableBody = React.memo(
  TableBody,
  (prev, next) => prev.table.options.data === next.table.options.data
) as typeof TableBody; */

function IndeterminateCheckbox({
  indeterminate,
  className = "",
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = React.useRef<HTMLInputElement>(null!);

  React.useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className + " float-left cursor-pointer rounded bg-white"}
      {...rest}
    />
  );
}

const CustomOption: React.FC<any> = ({ innerProps, label, data }) => (
  <div
    {...innerProps}
    title={data.additionalInfo}
    className="my-2 flex items-center px-2"
  >
    <input
      id={label}
      name="notification-method"
      type="radio"
      style={{ transform: "scale(1.2)" }}
      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
    />
    <label
      htmlFor={label}
      className="ml-3 block text-sm font-medium leading-6 text-gray-900"
    >
      {label}
    </label>
  </div>
);

const CustomOptionForMulti: React.FC<any> = ({ innerProps, label, data }) => (
  <div
    className="relative my-2 flex items-start px-2"
    {...innerProps}
    title={data.additionalInfo}
  >
    <div className="flex h-6 items-center">
      <input
        id={label}
        name="comments"
        type="checkbox"
        style={{ transform: "scale(1.2)" }}
        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
      />
    </div>
    <div className="ml-3 text-sm leading-6">
      <label htmlFor={label} className="font-medium text-gray-900">
        {label}
      </label>
    </div>
  </div>
);

const MultiValueContainerElement: React.FC<any> = (
  props: MultiValueGenericProps<any>,
) => {
  return (
    <div>
      <components.MultiValueContainer {...props} />
    </div>
  );
};

const ValueContainer = ({ children, ...props }: ValueContainerProps<any>) => (
  <components.ValueContainer {...props}>{children}</components.ValueContainer>
);

export default Responses;

import { useMutation, useQueryClient } from "react-query";
import { createSurvey, deleteSurvey, updateSurvey } from "../api/surveyApi";
import { toast } from "react-toastify";

export function useCreateSurvey() {
  return useMutation({
    mutationFn: (data: any) => createSurvey(data),
    onMutate: () => {},

    onError: () => {},

    onSuccess: () => {},

    onSettled: async (_: any, error: any) => {},
  });
}

export function useUpdateSurvey() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: any) => updateSurvey(data),

    onSettled: async (_: any, error: any, variables: any) => {
      if (error) {
        console.error(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: ["getSurvey"] });
      }
    },
  });
}

export function useUpdateSurveyDashboard() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: any) => updateSurvey(data),

    onSettled: async (data, error, variables) => {
      if (error) {
        console.log(error);
      } else {
        toast.success("Başariyla degisti");
        await queryClient.invalidateQueries(["surveysUser"], { exact: true });
      }
    },
  });
}

export function useDeleteSurvey() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => deleteSurvey(id),

    onSuccess: () => {
      toast.error("Anket başarıyla silindi");
    },

    onSettled: async (_: any, error: any) => {
      if (error) {
      } else {
        await queryClient.invalidateQueries({ queryKey: ["surveysUser"] });
      }
    },
  });
}

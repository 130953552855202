import { Dialog as HeadlessDia, Transition } from "@headlessui/react";
import { Fragment } from "react";

export default function Dialog({
  children,
  isOpen,
  onClose,
  maxWidth,
  isFullHeight,
}: {
  children?: React.ReactNode;
  isOpen: boolean;
  onClose: (isOpen: boolean) => void;
  maxWidth: number;
  isFullHeight?: boolean;
}) {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <HeadlessDia as="div" className="relative z-20" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div
            className={`flex min-h-full justify-center px-4 py-12 sm:px-12 text-center${
              !isFullHeight ? "items-end sm:items-center" : ""
            }`}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <HeadlessDia.Panel
                className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:p-6"
                style={{ maxWidth: maxWidth === -1 ? "none" : maxWidth + "px" }}
              >
                {children}
              </HeadlessDia.Panel>
            </Transition.Child>
          </div>
        </div>
      </HeadlessDia>
    </Transition.Root>
  );
}

import { lazy, useState } from "react";
import Dialog from "../../components/tailwindUi/Dialog";
import { FilterChip, dashboardModalValueType } from "./DashboardCards";
import SettingsAccount from "./SettingsAccount";
import SettingsData from "./SettingsData";
import SettingsProfile from "./SettingsProfile";
import SettingsUsage from "./SettingsUsage";

const NewFormDialog = lazy(() => import("./NewFormDialog"));

export default function Settings({ isAudience }: { isAudience?: boolean }) {
  const tabs = !isAudience
    ? [
        { text: "Profil", value: "profile" },
        { text: "Hesap", value: "account" },
        { text: "Veri", value: "data" },
      ]
    : [
        { text: "Profile", value: "profile" },
        { text: "Account", value: "account" },
      ];

  type tabsType = (typeof tabs)[number]["value"];

  const [activeTab, setActiveTab] = useState<tabsType>(tabs[0].value);
  const [modalValue, setModalValue] = useState<dashboardModalValueType>("");

  const getModalParams = () => {
    switch (modalValue) {
      case "new":
        return {
          maxWidth: 628,
          ModalChildren: <NewFormDialog setModalValue={setModalValue} />,
        };
      default:
        return {
          maxWidth: 400,
          ModalChildren: <></>,
        };
    }
  };

  const { maxWidth, ModalChildren, isFullHeight } = getModalParams() as {
    maxWidth: number;
    ModalChildren: JSX.Element;
    isFullHeight?: boolean;
  };

  const SelectedSettingsTab = () => {
    switch (activeTab) {
      case "profile":
        return <SettingsProfile isAudience />;
      case "account":
        return <SettingsAccount />;
      case "billing":
        return <div>Billing</div>;
      case "data":
        return <SettingsData />;
      case "usage":
        return <SettingsUsage />;
      default:
        return <div></div>;
    }
  };

  return (
    <>
      <div className="mb-6 inline-flex w-full gap-1 overflow-x-scroll">
        {tabs.map((tab) => (
          <button onClick={() => setActiveTab(tab.value)}>
            <FilterChip key={tab.value} isActive={activeTab === tab.value}>
              {tab.text}
            </FilterChip>
          </button>
        ))}
      </div>

      <SelectedSettingsTab />

      <Dialog
        isOpen={Boolean(modalValue)}
        onClose={() => setModalValue("")}
        maxWidth={maxWidth}
        isFullHeight={isFullHeight}
      >
        {ModalChildren}
      </Dialog>
    </>
  );
}

import EmployeeExperienceIcon from "images/sidebarIcons/EmployeeExperience";
import { FilterChip } from "pages/dashboard2/DashboardCards";
import Dedication from "pages/dashboard2/EmployeeExperience/Dedication";
import ESAT from "pages/dashboard2/EmployeeExperience/ESAT";
import EmployeeSatisfactionScore from "pages/dashboard2/EmployeeExperience/EmployeeSatisfactionScore";
import { useState } from "react";

const TABS = Object.freeze(["Çalışan Memnuniyet Skoru", "E-SAT", "Adanmışlık"]);

export default function EmployeeExperience() {
  const [currentTab, setCurrentTab] = useState(TABS[0]);

  const CurrentTabComp = () => {
    switch (currentTab) {
      case "Çalışan Memnuniyet Skoru":
        return <EmployeeSatisfactionScore />;
      case "E-SAT":
        return <ESAT />;
      case "Adanmışlık":
        return <Dedication />;
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col">
      {/* page title */}
      <div className="mb-[30px] flex items-center">
        <div className="p-1.5 mr-3 inline-flex h-9 w-9 items-center justify-center rounded bg-violet-100">
          <EmployeeExperienceIcon aria-hidden="true" isForTitle />
        </div>

        <div className="inline-flex text-2xl font-semibold leading-normal text-gray-800">
          Çalışan Deneyimi
        </div>
      </div>

      <div className="mb-5 flex gap-x-[6px]">
        {TABS.map((tab, i) => (
          <button key={i} onClick={() => setCurrentTab(tab)}>
            <FilterChip key={tab} isActive={currentTab === tab} isForTabs>
              {tab}
            </FilterChip>
          </button>
        ))}
      </div>

      <CurrentTabComp />
    </div>
  );
}

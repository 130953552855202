import React from "react";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <div className="notFoundBody">
      <h1 className="notFoundHeader">404 Error</h1>
      <p className="zoom-area">Aradığınız sayfa bulunamadı</p>
      <section className="error-container">
        <span className="four">
          <span className="screen-reader-text">4</span>
        </span>
        <span className="zero">
          <span className="screen-reader-text">0</span>
        </span>
        <span className="four">
          <span className="screen-reader-text">4</span>
        </span>
      </section>
      <div className="link-container">
        <Link to={"/"} className="more-link">
          Ana Sayfa
        </Link>
      </div>
    </div>
  );
};

export default PageNotFound;

import { ChangeEvent, useEffect, useState } from "react";
import { Switch } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { classNames } from "../../../lib/externalJS";
import { useAtom, useAtomValue } from "jotai";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../atoms/surveyAtoms";

const FromSettingsOtherOption = () => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const [localState, setLocalState] = useState({
    showOtherItem:
      stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
        ?.showOtherItem,
    otherText:
      stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]?.otherText,
    isOtherItemRequired:
      stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
        ?.isOtherItemRequired,
  });

  const { t } = useTranslation();

  const changeOTherButton = (e: any) => {
    setLocalState((prevState) => {
      const newState = { ...prevState };
      newState.showOtherItem = newState.showOtherItem
        ? !newState.showOtherItem
        : true;
      if (e === false) {
        newState.isOtherItemRequired = false;
      }

      newState.otherText = newState.showOtherItem ? newState.otherText : {};
      return newState;
    });
  };

  const changeOTherButtonRequired = () => {
    setLocalState((prevState) => {
      const newState = { ...prevState };
      newState.isOtherItemRequired = newState.isOtherItemRequired
        ? !newState.isOtherItemRequired
        : true;
      return newState;
    });
  };

  const handleOtherChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setLocalState((prevState) => {
      const newState = { ...prevState };
      newState.otherText = { tr: newValue };
      return newState;
    });
  };

  useEffect(() => {
    setStateSurveyJSON((prevState) => {
      let newState = { ...prevState };
      newState.pages![pageIndex]!.elements![elementIndex]!.showOtherItem =
        localState.showOtherItem;
      newState.pages![pageIndex]!.elements![elementIndex]!.otherText =
        localState.otherText;
      return newState;
    });
  }, [localState]);
  return (
    <>
      <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
        <label className="mb-2 block text-sm font-bold text-gray-500">
          Diğer Seçeneği
        </label>
        <div className="mb-2 mt-3 flex">
          <Switch
            id="flexSwitchCheckOtherItem"
            checked={
              localState.showOtherItem ? localState.showOtherItem : false
            }
            onChange={(e) => changeOTherButton(e)}
            className={classNames(
              localState.showOtherItem ? "bg-blue-600" : "bg-gray-200",
              "relative mr-2 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2",
            )}
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={classNames(
                localState.showOtherItem ? "translate-x-5" : "translate-x-0",
                "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
              )}
            />
          </Switch>
          <label className="text-gray-500" htmlFor="flexSwitchCheckOtherItem">
            {localState.showOtherItem ? t("open") : t("close")}
          </label>
        </div>
        <div style={{ display: localState.showOtherItem ? "block" : "none" }}>
          <input
            id="enterTitle"
            className="focus:shadow-outline w-full appearance-none rounded-lg border border-gray-300 px-3 py-2 leading-tight text-gray-700 shadow-sm focus:outline-none"
            type="text"
            autoComplete="off"
            value={localState.otherText?.tr ? localState.otherText.tr : ""}
            onChange={(e) => handleOtherChange(e)}
          />
        </div>
        <p className="mb-0 mt-3 text-sm text-stone-500">
          Bu alana diğer seçeneği ekleyin
        </p>
      </div>
      <div
        className="border-b-2 px-[20px] pb-[20px] pt-[12px]"
        style={{ display: localState.showOtherItem ? "block" : "none" }}
      >
        <label className="mb-2 block text-sm font-bold text-gray-500">
          Diğer Seçeneği Zorunluluğu
        </label>
        <div className="mb-2 mt-3 flex">
          <Switch
            id="flexSwitchCheckOtherRequired"
            checked={
              localState.isOtherItemRequired
                ? localState.isOtherItemRequired
                : false
            }
            onChange={() => changeOTherButtonRequired()}
            className={classNames(
              localState.isOtherItemRequired ? "bg-blue-600" : "bg-gray-200",
              "relative mr-2 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2",
            )}
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={classNames(
                localState.isOtherItemRequired
                  ? "translate-x-5"
                  : "translate-x-0",
                "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
              )}
            />
          </Switch>
          <label
            className="text-gray-500"
            htmlFor="flexSwitchCheckOtherRequired"
          >
            {localState.isOtherItemRequired ? t("open") : t("close")}
          </label>
        </div>
        <p className="mb-0 mt-3 text-sm text-stone-500">
          Bu alan boşsa formun gönderilmesine izin vermeyin
        </p>
      </div>
    </>
  );
};

export default FromSettingsOtherOption;

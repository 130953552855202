import { useAtom, useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../atoms/surveyAtoms";

const FormSettingsUpOrDown = () => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const [localState, setLocalState] = useState({
    upOrDown:
      stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]?.upOrDown,
  });

  useEffect(() => {
    setStateSurveyJSON((prevState) => {
      let newState = { ...prevState };
      newState.pages![pageIndex]!.elements![elementIndex]!.upOrDown =
        localState.upOrDown;
      return newState;
    });
  }, [localState]);

  useEffect(() => {
    setLocalState(() => {
      let localStateObject = {
        upOrDown:
          stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]?.upOrDown,
      };
      return localStateObject;
    });
  }, [stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]?.upOrDown]);

  const handleButtonClick = (buttonName: any) => {
    setLocalState({ upOrDown: buttonName });
  };

  return (
    <div className="border-b-2 px-3 py-3">
      <label className="mb-2 block text-sm font-bold text-gray-500">
        Değerlendirme Metni Düzeni
      </label>
      <div className="mb-1">
        <button
          onClick={() => handleButtonClick(false)}
          className={`${
            (localState.upOrDown === false ||
              localState.upOrDown === undefined) &&
            "!bg-blue-500"
          } focus:shadow-outline h-9 w-24 !rounded-s-xl bg-slate-100 px-1 text-base text-slate-900 transition-colors duration-150 hover:bg-blue-200 hover:text-slate-100`}
        >
          Aşşağıda
        </button>
        <button
          onClick={() => handleButtonClick(true)}
          className={`${
            localState.upOrDown === true && "!bg-blue-500"
          } focus:shadow-outline h-9 w-24 !rounded-e-xl bg-slate-100 px-1 text-base text-slate-900 transition-colors duration-150 hover:bg-blue-200 hover:text-slate-100`}
        >
          Yukarıda
        </button>
      </div>
      <p className="mb-0 mt-3 text-sm text-stone-500">
        Değerlendirme metinlerinin pozisyonunu belirleyin
      </p>
    </div>
  );
};

export default FormSettingsUpOrDown;

import axios from "axios";

const BASE_URL = "http://3.144.40.16:8080/";
export default axios.create({ baseURL: BASE_URL });

//User

//survey

//reply

//setting

import FormSettingsTitle from "../../components/FormSettingsTitle";
import FormSettingsDescription from "../../components/FormSettingsDescription";
import FormSettingsCopy from "../../components/FormSettingsCopy";
import FormSettingsQuestionFix from "../../components/FormSettingsQuestionFix";
import FormSettingsHide from "../../components/FormSettingsHide";
import FormSettingsAddToLibrary from "../../components/FormSettingsAddToLibrary";
import FormSettingsTextAlign from "../../components/FormSettingsTextAlign";
import FormSettingsTitleSize from "../../components/FormSettingsTitleSize";

export default function TitleGeneralSettings() {
  return (
    <>
      <FormSettingsTitle />
      <FormSettingsDescription />
      <FormSettingsTitleSize />
      <FormSettingsTextAlign />
      <FormSettingsCopy />
      <FormSettingsQuestionFix />
      <FormSettingsHide />
      <FormSettingsAddToLibrary />
    </>
  );
}

import { Menu, Transition } from "@headlessui/react";
import { Cog8ToothIcon } from "@heroicons/react/24/outline";
import { AuthContext } from "context/AuthContext";
import Deniz from "images/DenizOrnekAvatar.png";
import { useSetAtom } from "jotai";
import { Fragment, useContext } from "react";
import { selectedNavigationNameAtom } from "../../components/atoms/dashboardAtoms";
import Badge from "../../components/tailwindUi/Badge";
import LogOut from "../../images/log-out.svg";
import { classNames } from "../../lib/externalJS";
import {
  getBadgeColorByRole,
  getTurkishRole,
} from "./EmployeeProfileSkillsPanel";

export default function ProfileDropdown() {
  const setSelectedNavigationName = useSetAtom(selectedNavigationNameAtom);

  const role = "manager";

  const { logout } = useContext(AuthContext);

  const USER_NAVIGATION = Object.freeze([
    {
      name: "Hesap Ayarları",
      href: "#",
      icon: <Cog8ToothIcon className="h-5 w-5 text-gray-600" />,
      onClick: () => {
        setSelectedNavigationName("Settings");
      },
    },
    {
      name: "Çıkış",
      href: "#",
      icon: <img src={LogOut} />,
      onClick: () => {
        logout();
      },
    },
  ]);

  return (
    <Menu as="div" className="relative">
      {({ open }) => (
        <>
          <Menu.Button className="-m-1.5 p-1.5 flex items-center gap-x-2">
            <span className="sr-only">Open user menu</span>
            <img
              className="h-8 w-8 rounded-full border"
              src={Deniz}
              alt=""
              style={{
                boxShadow: open ? "0px 0px 1px 3px #276FFF33" : "",
              }}
            />

            <div className="text-sm font-medium leading-normal text-slate-600">
              Deniz
            </div>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-1 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
              <div className="inline-flex w-[300px] items-end justify-between p-5">
                <div className="text-sm font-normal leading-tight text-gray-800">
                  Merhaba,
                  <br />
                  <span className="font-medium">Deniz Örnek</span>
                </div>
                <Badge color={getBadgeColorByRole(role)} textSize={14}>
                  {getTurkishRole(role)}
                </Badge>
              </div>

              {USER_NAVIGATION.map((item) => (
                <Menu.Item key={item.name}>
                  {({ active }) => (
                    <>
                      <hr className="border-gray-200" />
                      <button
                        onClick={item.onClick}
                        className={classNames(
                          active ? "bg-gray-50" : "",
                          "block w-full px-4 py-3 text-sm leading-6 text-gray-900",
                        )}
                      >
                        <div className="flex items-center gap-2">
                          {item.icon}
                          {item.name}
                        </div>
                      </button>
                    </>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
}

import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { useSetAtom } from "jotai";
import { selectedNavigationNameAtom } from "../../components/atoms/dashboardAtoms";
import { classNames } from "../../lib/externalJS";
import SettingsIcon from "../../images/settings-01.svg";
import InstitutionSettingsIcon from "../../images/settingsDropdownIcons/institution-settings.svg";
import HumanResourcesIcon from "../../images/settingsDropdownIcons/human-resources.svg";
import RolesIcon from "../../images/settingsDropdownIcons/roles.svg";
import DepartmentsIcon from "../../images/settingsDropdownIcons/departments.svg";

export default function ProfileDropdown() {
  const setSelectedNavigationName = useSetAtom(selectedNavigationNameAtom);

  const USER_NAVIGATION = Object.freeze([
    {
      name: "Kurum Ayarları",
      href: "#",
      icon: <img src={InstitutionSettingsIcon} />,
      onClick: () => {
        setSelectedNavigationName("Settings");
      },
    },
    {
      name: "İnsan Kaynağı",
      href: "#",
      icon: <img src={HumanResourcesIcon} />,
      onClick: () => {
        console.log("İnsan Kaynağı");
      },
    },
    {
      name: "Roller",
      href: "#",
      icon: <img src={RolesIcon} />,
      onClick: () => {
        console.log("Roller");
      },
    },
    {
      name: "Departmanlar",
      href: "#",
      icon: <img src={DepartmentsIcon} />,
      onClick: () => {
        console.log("Departmanlar");
      },
    },
  ]);

  return (
    <Menu as="div" className="relative">
      {({ open }) => (
        <>
          <Menu.Button className="-m-1.5 p-1.5 flex items-center gap-x-2">
            <div className="p-2">
              <img src={SettingsIcon} className="h-5 w-5" alt="bell" />
            </div>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-1 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
              {USER_NAVIGATION.map((item, navigationIndex) => (
                <Menu.Item key={item.name}>
                  {({ active }) => (
                    <>
                      {navigationIndex !== 0 && (
                        <hr className="border-gray-200" />
                      )}
                      <button
                        onClick={item.onClick}
                        className={classNames(
                          active ? "bg-gray-50" : "",
                          "block w-[300px] px-4 py-3 text-sm leading-6 text-gray-900",
                        )}
                      >
                        <div className="flex items-center gap-2">
                          {item.icon}
                          {item.name}
                        </div>
                      </button>
                    </>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
}

import { selectedUserAtom } from "components/atoms/dashboardAtoms";
import { useAtomValue } from "jotai";

export default function EmployeeProfileBusinessActivitiesDetailed() {
  const selectedUser = useAtomValue(selectedUserAtom);

  return (
    <div>
      {/* table header */}
      <div className="flex rounded-t-xl border border-gray-200 bg-gray-50 text-xs font-medium leading-[18px] text-gray-500">
        <div className="flex items-center p-3">Detaylı İş Faaliyetleri</div>
      </div>

      {/* table items */}
      {selectedUser.detayliIsFaaliyetleri.map((row: any, index: number) => (
        <div
          className={
            "flex border-x border-b border-gray-200 bg-white text-sm font-medium leading-tight text-gray-800 " +
            (index === selectedUser.detayliIsFaaliyetleri.length - 1
              ? "rounded-b-xl"
              : "")
          }
          key={index}
        >
          <div className="p-4">
            <span className="text-sm font-medium text-black">{"• " + row}</span>
          </div>
        </div>
      ))}
    </div>
  );
}

import { useAtom, useAtomValue } from "jotai";
import { ChangeEvent, useEffect, useState } from "react";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../atoms/surveyAtoms";

const FormSettingsHeight = () => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const [localState, setLocalState] = useState({
    inputHeight:
      stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]?.inputHeight,
  });

  useEffect(() => {
    setStateSurveyJSON((prevState) => {
      let newState = { ...prevState };
      newState.pages![pageIndex]!.elements![elementIndex]!.inputHeight =
        localState.inputHeight;
      return newState;
    });
  }, [localState]);

  useEffect(() => {
    setLocalState(() => {
      let localStateObject = {
        inputHeight:
          stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
            ?.inputHeight,
      };
      return localStateObject;
    });
  }, [
    stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]?.inputHeight,
  ]);

  const handleDescChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setLocalState((prevState) => {
      const newState = { ...prevState };
      newState.inputHeight = parseInt(newValue);
      return newState;
    });
  };

  return (
    <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
      <label
        className="mb-2 block text-sm font-bold text-gray-500"
        htmlFor="enterHeight"
      >
        Yükseklik
      </label>
      <div className="flex">
        <div className="relative w-full">
          <input
            type="number"
            placeholder="Yükseklik Ekle"
            id="enterHeight"
            className="z-20 block w-full rounded-lg border border-l-2 border-gray-300 border-l-gray-100 bg-gray-50 p-[10px] text-sm text-gray-900 outline-none"
            value={localState.inputHeight && localState.inputHeight}
            onChange={(e) => handleDescChange(e)}
          />
          <button
            type="submit"
            className="absolute right-0 top-0 h-full rounded-r-lg border bg-transparent p-1 text-sm font-medium text-stone-800 outline-none"
          >
            px
          </button>
        </div>
      </div>
    </div>
  );
};

export default FormSettingsHeight;

import { useEffect, useRef, useState } from "react";
import Header from "../components/staticDesign/Header";
import SurveyMain from "../components/SurveyMain";
import { surveySettingInterface } from "../Interfaces/SurveyInterfaces";
import { useParams } from "react-router-dom";
import ShareNavbar from "../components/staticDesign/ShareNavbar";
import SurveyFastShare from "./surveyshare/SurveyFastShare";
import SurveyMailShare from "./surveyshare/SurveyMailShare";
import Survey360Share from "./surveyshare/Survey360Share";
import { useUpdateSurvey } from "../services/mutations/surveyMutations";
import { useQuery } from "react-query";
import axios from "axios";
import ShareViaReference from "./surveyshare/shareViaReference/ShareViaReference";
import { useUpdateSurveySetting } from "../services/mutations/surveySettingsMutations";
import SurveyResult from "./surveyResult/SurveyResult";
import { useAtom } from "jotai";
import { surveyAtom } from "../components/atoms/surveyAtoms";

const SurveyHome = () => {
  const updateSurveyMutation = useUpdateSurvey();
  const updateSurveySettingMutation = useUpdateSurveySetting();
  const hasMutatedRef = useRef(false);
  const { surveyId } = useParams();

  const { data, refetch } = useQuery(
    "getSurvey",
    () =>
      axios.get("http://3.144.40.16:8080/Survey/GetById", {
        params: { surveyId: surveyId },
      }),
    {
      enabled: false,
      cacheTime: 0,
    },
  );
  const { data: data2 } = useQuery(
    "getSurveySetting",
    () =>
      axios.get("http://3.144.40.16:8080/SurveySetting/GetBySurveyId", {
        params: { surveyId: surveyId },
      }),
    {
      cacheTime: 0,
    },
  );

  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);

  useEffect(() => {
    if (data) {
      setStateSurveyJSON(data.data);
    }
  }, [data]);

  const [surveySetting, setSurveySetting] = useState<surveySettingInterface>({
    surveyID: surveyId,
    publishType: undefined,
    publishPermission: undefined,
    plans: undefined,
    reminderMailInstant: {
      subject: "Anlık Mail Bildirimi",
      content: "Anlık Mail",
      lastTimeSended: new Date(),
    },
    reminderMailNotStarted: {
      subject: "Başlamamış Mail Bildirimi",
      content: "Başlamamış Mail",
      lastTimeSended: new Date(),
    },
    reminderMailContinue: {
      subject: "Devam Eden Mail Bildirimi",
      content: "Devam Eden Mail",
      lastTimeSended: new Date(),
    },
    Roles: undefined,
  });

  useEffect(() => {
    if (data2?.data) {
      setSurveySetting(data2.data);
    }
  }, [data2]);

  const [pageStatus, setPageStatus] = useState<number>(1); // 1: SurveyHome, 2: surveyShare, 3: surveyResult,
  const [alterPageStatus, setAlterPageStatus] = useState<number>(1); // 1: SurveyHome, 2: surveyShare, 3: surveyResult,

  /*   const {
    isLoading: isLoading2,
    error: error2,
    data: data2,
  } = useQuery("getSurveys", () =>
    axios.get("http://3.144.40.16:8080/Survey/GetAll")
  ); */

  const updateData = (data: any): any => {
    if (data.participants) {
      data.participantIds = data.participants?.map((part: any) => {
        return part._id;
      });
      return data;
    } else return data;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (data?.data) {
        const updatedData = updateData(stateSurveyJSON);
        updateSurveyMutation.mutate(updatedData);
      }
    }, 1000);
    return () => clearTimeout(timer);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateSurveyJSON]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (data2?.data) {
        updateSurveySettingMutation.mutate(surveySetting);
      }
    }, 1000);
    return () => clearTimeout(timer);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveySetting]);

  /* const [language, Language] = useState<string>(); */

  useEffect(() => {
    if (!data) {
      refetch();
      return;
    }

    if (!hasMutatedRef.current) {
      hasMutatedRef.current = true;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // if (
  //   isLoading ||
  //   data === undefined ||
  //   data.status === 204 ||
  //   data.data === "" ||
  //   isError ||
  //   !stateSurveyJSON ||
  //   isError2 ||
  //   isLoading2
  // ) {
  //   // bu condition allah ne verdiyse
  //   if (data?.status === 204) refetch();
  //   return <div>bekleyiniz</div>;
  // }

  return (
    <div className="!h-screen">
      <Header
        headerTitle={stateSurveyJSON?.title?.tr}
        pageStatus={pageStatus}
        setPageStatus={setPageStatus}
      />
      {pageStatus === 1 ? (
        <SurveyMain />
      ) : pageStatus === 2 ? (
        <>
          <ShareNavbar
            setAlterPageStatus={setAlterPageStatus}
            alterPageStatus={alterPageStatus}
          />
          {alterPageStatus === 1 ? (
            <SurveyFastShare
              surveySetting={surveySetting}
              setSurveySetting={setSurveySetting}
            />
          ) : alterPageStatus === 2 ? (
            <SurveyMailShare />
          ) : alterPageStatus === 3 ? (
            <Survey360Share
              setSurveySetting={setSurveySetting}
              surveySetting={surveySetting}
            />
          ) : (
            <ShareViaReference />
          )}
        </>
      ) : (
        <>
          <SurveyResult />
        </>
      )}
    </div>
  );
};

export default SurveyHome;

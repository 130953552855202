import { useSetAtom } from "jotai";
import { ChangeEvent } from "react";
import { surveyAtom } from "../../../atoms/surveyAtoms";

type Props = {
  placement: string;
  value: string | undefined;
  pageIndex: number;
  index: number;
  shouldDisplay: boolean;
};

const DescriptionButtonSurvey: React.FC<Props> = ({
  placement,
  value,
  shouldDisplay,
  pageIndex,
  index,
}) => {
  const setStateSurveyJSON = useSetAtom(surveyAtom);

  const handleDescChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setStateSurveyJSON((prevState) => {
      const newState = { ...prevState };
      newState.pages![pageIndex].elements![index].description = {
        tr: newValue,
      };
      return newState;
    });
  };

  return (
    <div
      className="relative mt-2 w-full flex-wrap items-stretch"
      style={{ display: value || shouldDisplay ? "flex" : "none" }}
    >
      <input
        onChange={(e: any) => handleDescChange(e)}
        className="fs-6 w-full resize-none overflow-hidden border-0 p-1 text-sm outline-none placeholder:text-gray-400 hover:outline-dashed hover:outline-1 hover:outline-gray-400 focus:!outline-none"
        placeholder="Açıklama Ekle"
        style={{
          textAlign: placement === "Right" ? "right" : "left",
        }}
        value={value}
      />
    </div>
  );
};

export default DescriptionButtonSurvey;

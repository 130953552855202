import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { templatesSearchQueryAtom } from "components/atoms/templatesAtoms";
import { useAtom } from "jotai";

export default function Search({ placeholder }: { placeholder: string }) {
  const [searchQuery, setSearchQuery] = useAtom(templatesSearchQueryAtom);

  const handleSearch = (value: string) => {
    setSearchQuery(value);
  };

  return (
    <div className="relative flex flex-1 flex-shrink-0">
      <label htmlFor="search" className="sr-only">
        Search
      </label>
      <input
        type="search"
        className="peer block w-full rounded-md border border-gray-200 py-[9px] pl-10 text-sm outline-2 placeholder:text-gray-500"
        placeholder={placeholder}
        onChange={(e) => {
          handleSearch(e.target.value);
        }}
      />
      <MagnifyingGlassIcon className="absolute left-3 top-1/2 h-[18px] w-[18px] -translate-y-1/2 text-gray-500 peer-focus:text-gray-900" />
    </div>
  );
}

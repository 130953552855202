import { ChangeEvent, useEffect, useState } from "react";
import { Switch } from "@headlessui/react";
import { classNames } from "../../../lib/externalJS";
import { useTranslation } from "react-i18next";
import { useAtom, useAtomValue } from "jotai";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../atoms/surveyAtoms";

const FormSettingsNoIdea = () => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const { t } = useTranslation();

  const [localState, setLocalState] = useState({
    showNoneItem:
      stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]?.showNoneItem,
    noneText:
      stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]?.noneText,
  });

  const changeNoIdeaButton = () => {
    setLocalState((prevState) => ({
      ...prevState,
      showNoneItem: !prevState.showNoneItem,
      noneText: prevState.showNoneItem ? prevState.noneText : {},
    }));
  };

  const handleNoneTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setLocalState((prevState) => {
      const newState = { ...prevState };
      newState.noneText = { tr: newValue };
      return newState;
    });
  };

  useEffect(() => {
    setStateSurveyJSON((prevState) => ({
      ...prevState,
      pages: (prevState?.pages ?? []).map((page, i) =>
        i === pageIndex
          ? {
              ...page,
              elements: (page?.elements ?? []).map((element, j) =>
                j === elementIndex
                  ? {
                      ...element,
                      showNoneItem: localState.showNoneItem,
                      noneText: localState.noneText,
                    }
                  : element,
              ),
            }
          : page,
      ),
    }));
  }, [localState]);

  useEffect(() => {
    setLocalState(() => {
      let newState = {
        showNoneItem:
          stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
            ?.showNoneItem,
        noneText:
          stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]?.noneText,
      };
      return newState;
    });
  }, [
    stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]?.showNoneItem,
    stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]?.noneText,
  ]);

  //sonradan performans için üçüncü bir ui state döndürebililirim

  return (
    <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
      <label className="mb-2 block text-sm font-bold text-gray-500">
        Fikrim yok
      </label>
      <div className="mb-2 mt-3 flex">
        <Switch
          id="flexSwitchCheckNoIdea"
          checked={localState.showNoneItem ? localState.showNoneItem : false}
          onChange={(e) => changeNoIdeaButton()}
          className={classNames(
            localState.showNoneItem ? "bg-blue-600" : "bg-gray-200",
            "relative mr-2 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2",
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={classNames(
              localState.showNoneItem ? "translate-x-5" : "translate-x-0",
              "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
            )}
          />
        </Switch>
        <label className="text-gray-300" htmlFor="flexSwitchCheckNoIdea">
          {localState.showNoneItem ? t("open") : t("close")}
        </label>
      </div>
      <div style={{ display: localState.showNoneItem ? "block" : "none" }}>
        <input
          id="enterTitle"
          className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow-sm focus:outline-none"
          type="text"
          autoComplete="off"
          value={localState.noneText?.tr ? localState.noneText.tr : ""}
          onChange={(e) => handleNoneTextChange(e)}
        />
      </div>
      <p className="mb-0 mt-3 text-sm text-stone-500">
        Tabloya fikrim yok sütunu ekleyin
      </p>
    </div>
  );
};

export default FormSettingsNoIdea;

import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAtom, useAtomValue } from "jotai";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../atoms/surveyAtoms";

const FormSettingsTip = () => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const [localState, setLocalState] = useState({
    tipText:
      stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]?.tipText,
  });

  const { t } = useTranslation();

  useEffect(() => {
    setStateSurveyJSON((prevState) => {
      let newState = { ...prevState };
      newState.pages![pageIndex]!.elements![elementIndex]!.tipText =
        localState.tipText;
      return newState;
    });
  }, [localState]);

  useEffect(() => {
    setLocalState(() => {
      let localStateObject = {
        tipText:
          stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]?.tipText,
      };
      return localStateObject;
    });
  }, [stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]?.tipText]);

  const handleTipChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    setLocalState((prevState) => {
      const newState = { ...prevState };
      newState.tipText = { tr: newValue };
      return newState;
    });
  };

  return (
    <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
      <label
        className="block text-sm font-bold text-gray-500"
        htmlFor="enterQuestion"
      >
        {t("hintText")}
      </label>
      <textarea
        className="mt-3 w-full resize-none rounded border border-gray-300 focus:outline-none"
        onChange={(e) => {
          handleTipChange(e);
        }}
        value={localState.tipText?.tr}
      />
      <p className="mb-0 mt-3 text-sm text-stone-500">
        {t("hintTextDescription")}
      </p>
    </div>
  );
};

export default FormSettingsTip;

import PrimaryButton from "../tailwindUi/PrimaryButton";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";

export default function CardWithPlusMinusButtons({
  children,
  plusButtonOnClick,
  isPlusVisible = true,
  minusButtonOnClick,
  isMinusVisible = true,
}: {
  children?: React.ReactNode;
  plusButtonOnClick?: () => void;
  isPlusVisible?: boolean;
  isMinusVisible?: boolean;
  minusButtonOnClick?: () => void;
}) {
  return (
    <div className="flex mb-5">
      <div className="w-full">{children}</div>

      <div className="flex flex-col gap-4 justify-center ml-5">
        {isPlusVisible && (
          <PrimaryButton onClick={plusButtonOnClick} isMinWidth isEqualPadding>
            <PlusIcon className="w-5 h-5" />
          </PrimaryButton>
        )}

        {isMinusVisible && (
          <PrimaryButton
            onClick={minusButtonOnClick}
            isMinWidth
            isEqualPadding
            isGray
          >
            <MinusIcon className="w-5 h-5" />
          </PrimaryButton>
        )}
      </div>
    </div>
  );
}

import { Dispatch, SetStateAction } from "react";
import MailsList from "./MailsList";
import { useAtomValue } from "jotai";
import { surveyAtom } from "../../../components/atoms/surveyAtoms";

type Props = {
  setMailState: Dispatch<SetStateAction<number>>;
  setSelectedSharedMail: any;
};

const Mails: React.FC<Props> = ({ setMailState, setSelectedSharedMail }) => {
  const stateSurveyJSON = useAtomValue(surveyAtom);

  return (
    <>
      <h5 className="mx-auto w-[720px] text-start">Paylaşılan E-postalar</h5>
      <div className="mx-auto w-[720px] rounded-xl border bg-gray-100">
        <div className="flex p-2">
          <div className="ml-2 flex w-[300px] items-center justify-start">
            <p className="m-0 text-sm">Alıcı</p>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.9987 3.3335V12.6668M7.9987 12.6668L12.6654 8.00016M7.9987 12.6668L3.33203 8.00016"
                stroke="#475467"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="w-[140px] text-start text-sm">Gönderim Tarihi</div>
          <div className="w-[160px] text-start text-sm">Yanıt Durumu</div>
          <div className="w-[120px] text-sm"></div>
        </div>
        {stateSurveyJSON.sharedUserMails?.map((item, index) => (
          <MailsList
            key={index}
            item={item}
            setSelectedSharedMail={setSelectedSharedMail}
            setMailState={setMailState}
          />
        ))}

        <div className="rounded-b-xl bg-white p-3 text-start">
          <button
            className="rounded-md border-2 border-blue-400 px-3 py-2 text-center align-middle text-sm hover:!bg-transparent hover:bg-blue-300 hover:!text-[#0d6ffd]"
            onClick={() => setMailState(2)}
          >
            <span className="flex items-center justify-center">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.0013 4.1665V15.8332M4.16797 9.99984H15.8346"
                  stroke="#2970FF"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Yeni Alıcı Ekle
            </span>
          </button>
        </div>
      </div>
    </>
  );
};

export default Mails;

import { useState } from "react";
import { Link } from "react-router-dom";
import FormCookerLogo from "../components/FormCookerLogo";
import PrimaryButton from "../components/tailwindUi/PrimaryButton";
import LockNewPassword from "../images/lock-reset-password.svg";
import CheckSuccessfulNewPassword from "../images/checkSuccessfulNewPassword.svg";
import RedirectToLogin from "../images/redirectToLogin.gif";

export default function NewPassword() {
  const [isSuccessful, setIsSuccessful] = useState(false);

  const NewPasswordFirst = () => {
    return (
      <div className="w-fit mx-auto mt-7 overflow-hidden rounded-lg bg-white shadow p-6">
        <div className="flex flex-col mt-7 sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="mx-auto w-12 h-12 px-3 pt-[11.63px] pb-[12.37px] bg-blue-100 rounded-full justify-center items-center inline-flex">
            <div className="w-6 h-6 relative flex-col justify-start items-start flex">
              <img src={LockNewPassword} />
            </div>
          </div>
          <p className="mt-3 text-center font-semibold font-['Inter'] text-base text-gray-900">
            Create New Password
          </p>

          <p className="mt-4 text-center font-normal font-['Inter'] text-sm text-gray-500">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur
            amet labore.
          </p>
          <form className="mt-5 space-y-5" action="#" method="POST">
            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  New Password
                </label>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="new-password"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="confirm-password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Confirm New Password
                </label>
              </div>
              <div className="mt-2">
                <input
                  id="confirm-password"
                  name="password"
                  type="password"
                  autoComplete="new-password"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <PrimaryButton>Confirm</PrimaryButton>
          </form>
        </div>
      </div>
    );
  };

  const NewPasswordSuccessful = () => {
    return (
      <div className="w-fit mx-auto mt-7 overflow-hidden rounded-lg bg-white shadow p-6">
        <div className="flex flex-col sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="mx-auto w-12 h-12 px-3 pt-[11.63px] pb-[12.37px] bg-blue-100 rounded-full justify-center items-center inline-flex">
            <div className="w-6 h-6 relative flex-col justify-start items-start flex">
              <img src={CheckSuccessfulNewPassword} />
            </div>
          </div>

          <p className="mt-3 text-center font-semibold font-['Inter'] text-base text-gray-900">
            Password Renewed
          </p>

          <p className="mt-4 text-center font-normal font-['Inter'] text-sm text-gray-500">
            Login sayfasına yönlendiriliyorsunuz
          </p>

          <img
            className="mx-auto"
            width="150"
            height="45"
            src={RedirectToLogin}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <FormCookerLogo className="mx-auto" width={150} />
      </div>

      {!isSuccessful && NewPasswordFirst()}

      {isSuccessful && NewPasswordSuccessful()}

      <div className="w-fit mx-auto mt-7">
        <Link
          className="text-blue-600 text-sm font-bold font-['Inter'] leading-tight"
          to="/login2"
        >
          Login
        </Link>
        <span className="text-slate-700 text-sm font-normal font-['Inter'] leading-tight">
          {" or "}
        </span>
        <Link
          className="text-blue-600 text-sm font-semibold font-['Inter'] leading-tight"
          to="/register2"
        >
          Sign up
        </Link>
      </div>
      {!isSuccessful && (
        <button onClick={() => setIsSuccessful(true)}>set is Successful</button>
      )}
    </div>
  );
}

import { Dispatch, SetStateAction } from "react";
import { sharedUserMailsInterface } from "../../../Interfaces/SurveyInterfaces";
import axios from "axios";
import { toast } from "react-toastify";
import { useSetAtom } from "jotai";
import { surveyAtom } from "../../../components/atoms/surveyAtoms";

const MailsList = ({
  item,
  setSelectedSharedMail,
  setMailState,
}: {
  item: sharedUserMailsInterface;
  setSelectedSharedMail: any;
  setMailState: Dispatch<SetStateAction<number>>;
}) => {
  const setStateSurveyJSON = useSetAtom(surveyAtom);

  const sendMail = async () => {
    try {
      const apiUrl = "http://3.144.40.16:8080/Survey/SendMail";

      await axios.post(apiUrl, item);

      toast.success("E-posta başarıyla gönderildi");
      setStateSurveyJSON((prevValue) => {
        const newSurvey = { ...prevValue };
        newSurvey.sharedUserMails = newSurvey.sharedUserMails?.map(
          (mail: any) => {
            if (mail.toMail[0] === item.toMail[0]) {
              mail.sendDate = new Date();
            }
            return mail;
          },
        );
        return newSurvey;
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const deleteSharedMail = () => {
    setStateSurveyJSON((prevValue) => {
      const newSurvey = { ...prevValue };
      newSurvey.sharedUserMails = newSurvey.sharedUserMails?.filter(
        (mail: any) => mail.toMail[0] !== item.toMail[0],
      );
      return newSurvey;
    });
  };

  const editSharedMail = () => {
    setSelectedSharedMail(item);
  };

  return (
    <div className="flex border-b bg-white">
      <div className="ml-2 flex w-[300px] items-center justify-start">
        <div className="p-2">
          <h6 className="m-0 text-start text-base">
            {item.toMail[0].split("@")[0]}
          </h6>
          <p className="m-0 text-start text-base">{item.toMail[0]}</p>
        </div>
      </div>
      <div className="my-auto w-[140px] text-start text-sm">
        {`${(new Date(item?.sendDate).getMonth() + 1)
          .toString()
          .padStart(2, "0")}.${new Date(item.sendDate)
          .getDate()
          .toString()
          .padStart(2, "0")}.${new Date(item.sendDate).getFullYear()}`}
        <br />
        {`${new Date(item.sendDate)
          .getHours()
          .toString()
          .padStart(2, "0")}:${new Date(item.sendDate)
          .getMinutes()
          .toString()
          .padStart(2, "0")}`}
      </div>
      <div className="flex w-[160px] items-center justify-start text-sm">
        {/*           <div className="w-[105px] bg-orange-100 h-[22px] border-1 border-orange-300 rounded-2xl text-xs font-bold p-1 flex items-center justify-start text-orange-500">
              <svg
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mr-2"
              >
                <circle cx="4" cy="4" r="3" fill="#F79009" />
              </svg>
              Devam Ediyor
            </div> */}
        <div className="border-1 flex h-[22px] w-[85px] items-center justify-start rounded-2xl border-red-300 bg-red-100 p-1 text-xs font-bold text-red-500">
          <svg
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
            className="mr-2"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="4" cy="4" r="3" fill="#F04438" />
          </svg>
          Başlamadı
        </div>
        {/*         <div className="w-[90px] bg-green-100 h-[22px] border-1 border-green-300 rounded-2xl text-xs font-bold p-1 flex items-center justify-start text-green-500">
              <svg
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
                className="mr-1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="4" cy="4" r="3" fill="#17B26A" />
              </svg>
              Tamamlandı
            </div> */}
      </div>
      <div className="flex w-[120px] items-center justify-center text-sm">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="mx-2 cursor-pointer"
          onClick={() => {
            setMailState(2);
            editSharedMail();
          }}
        >
          <path
            d="M9.99998 16.6667H17.5M2.5 16.6667H3.89545C4.3031 16.6667 4.50693 16.6667 4.69874 16.6206C4.8688 16.5798 5.03138 16.5125 5.1805 16.4211C5.34869 16.318 5.49282 16.1739 5.78107 15.8856L16.25 5.41669C16.9404 4.72634 16.9404 3.60705 16.25 2.91669C15.5597 2.22634 14.4404 2.22634 13.75 2.91669L3.28105 13.3856C2.9928 13.6739 2.84867 13.818 2.7456 13.9862C2.65422 14.1353 2.58688 14.2979 2.54605 14.468C2.5 14.6598 2.5 14.8636 2.5 15.2713V16.6667Z"
            stroke="#475467"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="mx-2 cursor-pointer"
          onClick={sendMail}
        >
          <path
            d="M8.74928 11.2501L17.4993 2.50014M8.85559 11.5235L11.0457 17.1552C11.2386 17.6513 11.3351 17.8994 11.4741 17.9718C11.5946 18.0346 11.7381 18.0347 11.8587 17.972C11.9978 17.8998 12.0946 17.6518 12.2881 17.1559L17.78 3.08281C17.9547 2.63516 18.0421 2.41133 17.9943 2.26831C17.9528 2.1441 17.8553 2.04663 17.7311 2.00514C17.5881 1.95736 17.3643 2.0447 16.9166 2.21939L2.84349 7.71134C2.34759 7.90486 2.09965 8.00163 2.02739 8.14071C1.96475 8.26129 1.96483 8.40483 2.02761 8.52533C2.10004 8.66433 2.3481 8.7608 2.84422 8.95373L8.47589 11.1438C8.5766 11.183 8.62695 11.2026 8.66935 11.2328C8.70693 11.2596 8.7398 11.2925 8.7666 11.3301C8.79685 11.3725 8.81643 11.4228 8.85559 11.5235Z"
            stroke="#475467"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="mx-2 cursor-pointer"
          onClick={deleteSharedMail}
        >
          <path
            d="M13.3333 4.99984V4.33317C13.3333 3.39975 13.3333 2.93304 13.1517 2.57652C12.9919 2.26292 12.7369 2.00795 12.4233 1.84816C12.0668 1.6665 11.6001 1.6665 10.6667 1.6665H9.33333C8.39991 1.6665 7.9332 1.6665 7.57668 1.84816C7.26308 2.00795 7.00811 2.26292 6.84832 2.57652C6.66667 2.93304 6.66667 3.39975 6.66667 4.33317V4.99984M8.33333 9.58317V13.7498M11.6667 9.58317V13.7498M2.5 4.99984H17.5M15.8333 4.99984V14.3332C15.8333 15.7333 15.8333 16.4334 15.5608 16.9681C15.3212 17.4386 14.9387 17.821 14.4683 18.0607C13.9335 18.3332 13.2335 18.3332 11.8333 18.3332H8.16667C6.76654 18.3332 6.06647 18.3332 5.53169 18.0607C5.06129 17.821 4.67883 17.4386 4.43915 16.9681C4.16667 16.4334 4.16667 15.7333 4.16667 14.3332V4.99984"
            stroke="#475467"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </div>
  );
};

export default MailsList;

import React, { useMemo, useRef } from "react";
import JoditEditor from 'jodit-react';

const RichEditor = ({ placeholder, on, value, onChange }) => {
  const editor = useRef(null);
  const config = useMemo(() => { // memoladım ki ayarlar değişmediği sürece rerender yemesin
    return {
      readonly: false,
      toolbar: on ? true : false,
      toolbarSticky: false,
      spellcheck: true,
      language: localStorage.getItem("lang") === "en" ? "en" : localStorage.getItem("lang") === "tr" ? "tr" : "auto",
      toolbarButtonSize: "medium",
      toolbarAdaptive: false,
      showCharsCounter: on ? true : false,
      showWordsCounter: on ? true : false,
      showXPathInStatusbar: on ? true : false,
      askBeforePasteHTML: true,
      askBeforePasteFromWord: true,
      //defaultActionOnPaste: "insert_clear_html", şimdilik kapatıyorum
      buttons: "bold,italic,underline,strikethrough,eraser,ul,ol,font,fontsize,paragraph,classSpan,lineHeight,superscript,subscript,file,image,video,spellcheck,cut,copy,paste,selectall,copyformat,hr,table,link,symbols,indent,outdent,left,brush,undo,redo,find,source,fullsize,preview,print",
      uploader: {
        insertImageAsBase64URI: true // bu olmazssa resim yüklenmeyecek ama link verebiliriz
      },
      maxWidth: 600,
      height: on !== true && 30,
      minHeight: on ? 300 : 30,
      maxHeight: 1200,
      border: "none",
      backgroundColor: "Transparent",
      placeholder: placeholder,
      buttonsXS: [
        'bold',
        'image', '|',
        'brush',
        'paragraph', '|',
        'align', '|',
        'undo', 'redo', '|',
        'eraser',
        'dots'
      ],
    };
  }, [on, placeholder]);

  return (
    <JoditEditor
      key={on} // keyi parametre değişimi sonrası componenti yok etmek için koydum
      ref={editor}
      value={value}
      config={config}
      tabIndex={1}
      onBlur={(newContent) => onChange(newContent)}
      onChange={(newContent) => { }}
      className="custom-jodit-editor"
    />
  )
};

export default RichEditor;

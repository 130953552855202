import { useTranslation } from "react-i18next";
import { defDays } from "../../../../lib/consts";
import { useAtom, useAtomValue } from "jotai";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../../atoms/surveyAtoms";

const DateDays = () => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const { t } = useTranslation();

  const handleContentChange = (index: number, newContent: any) => {
    setStateSurveyJSON((prevState) => {
      const newState = { ...prevState };
      newState.pages![pageIndex].elements![elementIndex].days![index] =
        newContent;

      return newState;
    });
  };

  return (
    <div className="border-b-2 px-1 py-2">
      <label
        className="mb-2 block text-sm font-bold text-gray-500"
        htmlFor="enterQuestion"
      >
        {t("günler")}
      </label>
      <div>
        {stateSurveyJSON.pages?.[pageIndex!]?.elements?.[
          elementIndex!
        ]?.days?.map((day, index) => (
          <div className={`relative ${index === 0 ? "mt-5" : "mt-4"}`}>
            <label
              htmlFor={index.toString()}
              className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-500"
            >
              {defDays[index]}
            </label>
            <input
              type="text"
              id={index.toString()}
              autoComplete="off"
              className="py-1.5 block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              defaultValue={day}
              onBlur={(e) => {
                handleContentChange(index, e.target.value);
              }}
            />
          </div>
        ))}
      </div>

      <p className="mb-0 mt-3 text-sm text-stone-500">
        {t("Takvimde gösterilen günler")}
      </p>
    </div>
  );
};
export default DateDays;

import React from "react";
import { surveyElementsInterface } from "../../Interfaces/SurveyInterfaces";
import { Input } from "./components/FormCompoents";

type Props = {
  question: surveyElementsInterface;
  endOfPage: boolean;
  register: any;
  setValue: any;
  errors: any;
  questionIndex: number;
};

const SmallTextQuestion: React.FC<Props> = ({
  question,
  endOfPage,
  register,
  questionIndex,
  setValue,
  errors,
}) => {
  const questionID = question._id;
  return (
    <>
      {question.position === null ||
      question.position === undefined ||
      question.position === "Top" ? (
        <>
          <div
            data-title={question.tipText?.tr}
            className={`${question.collapse ? "w-1/2 mx-auto" : "w-full"} `}
          >
            <h6
              className="mb-0"
              dangerouslySetInnerHTML={{ __html: question.title?.tr ?? "" }}
            ></h6>
            <p className=" text-gray-500">{question.description?.tr}</p>
            <Input
              name={question._id}
              questionIndex={questionIndex}
              questionType={question.type}
              readOnly={question.readOnly ? question.readOnly : false}
              style={{
                width: question.inputWidth
                  ? question.inputWidth + "px"
                  : "100%",
              }}
              className={`border-1  rounded h-6 p-3  ${
                !!errors[questionID]
                  ? "!outline-red-500 border-red-700"
                  : "focus:outline-blue-300"
              } !max-w-full`}
              register={register}
              required={question?.isRequired ?? false}
              type="text"
              questionTitle={question.title?.tr}
              limitationMin={
                question.limitationMin &&
                question.limitationType !== 2 &&
                question.limitationMin
              }
              limitationMax={
                question.limitationMax &&
                question.limitationType !== 2 &&
                question.limitationMax
              }
              limitationType={question.limitationType}
              placeholder={question.inputAreaPlaceholder?.tr}
              setValue={setValue}
              verifyInputType={question.verifyInputType}
              inputMask={question.inputMask}
            />
            <p className="mb-2 text-sm text-red-500">
              {errors[questionID]?.value.message}
            </p>
            <p className="mb-2 text-gray-500">{question.bottomTicket?.tr}</p>
          </div>
          {!endOfPage && <hr />}
        </>
      ) : (
        <>
          <div
            data-title={question.tipText?.tr}
            className={`${
              question.collapse ? "w-1/2 mx-auto" : "w-full"
            } flex flex-wrap`}
          >
            <div
              className={`w-1/2 ${
                question.position === "Right" && "text-right pr-4"
              }`}
            >
              <h6
                className="mb-0"
                dangerouslySetInnerHTML={{ __html: question.title?.tr ?? "" }}
              ></h6>
              <p className=" text-gray-500">{question.description?.tr}</p>
            </div>
            <div className="w-1/2">
              <Input
                name={question._id}
                questionIndex={questionIndex}
                readOnly={question.readOnly ? question.readOnly : false}
                style={{
                  width: question.inputWidth
                    ? question.inputWidth + "px"
                    : "100%",
                }}
                className={`border-1 rounded h-6 p-3  ${
                  !!errors[questionID]
                    ? "!outline-red-500 border-red-700"
                    : "focus:outline-blue-300"
                } !max-w-full`}
                register={register}
                required={question?.isRequired ?? false}
                type="text"
                limitationMin={
                  question.limitationMin &&
                  question.limitationType !== 2 &&
                  question.limitationMin
                }
                limitationMax={
                  question.limitationMax &&
                  question.limitationType !== 2 &&
                  question.limitationMax
                }
                limitationType={question.limitationType}
                placeholder={question.inputAreaPlaceholder?.tr}
                setValue={setValue}
                verifyInputType={question.verifyInputType}
                inputMask={question.inputMask}
              />
              <p className="mb-2 text-sm text-red-500">
                {errors[questionID]?.value?.message}
              </p>
              <p className="mb-2 text-gray-500">{question.bottomTicket?.tr}</p>
            </div>
          </div>
          {!endOfPage && <hr />}
        </>
      )}
    </>
  );
};

export default SmallTextQuestion;

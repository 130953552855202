import Categories from "pages/dashboard2/Templates/Categories";

export default function GridWithFilters({
  children,
  forPath,
}: {
  children: React.ReactNode;
  forPath: "templates" | "blog" | "terms";
}) {
  return (
    <div className="bg-white">
      <div>
        <div className="w-full px-1">
          <div className="pb-24 lg:grid lg:grid-cols-3 lg:gap-x-8 xl:grid-cols-4">
            <aside>
              <h2 className="sr-only">Filters</h2>

              <div className="hidden lg:block">
                <Categories forPath={forPath} />
              </div>
            </aside>

            <section
              aria-labelledby="template-heading"
              className="mt-6 lg:col-span-2 lg:mt-0 xl:col-span-3"
            >
              {children}
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

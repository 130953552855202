import { useTranslation } from "react-i18next";
import { Switch } from "@headlessui/react";
import { classNames } from "../../../lib/externalJS";
import { useAtom, useAtomValue } from "jotai";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../atoms/surveyAtoms";

const FormSettingsAddNames = () => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const { t } = useTranslation();

  const handleRequiredChangeNick = (e: boolean) => {
    const newValue = e;
    setStateSurveyJSON((prevState) => {
      let newState = { ...prevState };
      newState.pages![pageIndex]!.elements![elementIndex]!.showNickname =
        newValue;
      return newState;
    });
  };
  const handleRequiredChangeSecondary = (e: boolean) => {
    const newValue = e;
    setStateSurveyJSON((prevState) => {
      let newState = { ...prevState };
      newState.pages![pageIndex]!.elements![elementIndex]!.showSecondaryName =
        newValue;
      return newState;
    });
  };
  const handleRequiredChangeLast = (e: boolean) => {
    const newValue = e;
    setStateSurveyJSON((prevState) => {
      let newState = { ...prevState };
      newState.pages![pageIndex]!.elements![elementIndex]!.showLastName =
        newValue;
      return newState;
    });
  };

  const checkedNick = stateSurveyJSON.pages?.[pageIndex].elements?.[
    elementIndex
  ]?.showNickname
    ? stateSurveyJSON.pages[pageIndex].elements![elementIndex].showNickname
    : false;

  const checkedSecondary = stateSurveyJSON.pages?.[pageIndex].elements?.[
    elementIndex
  ]?.showSecondaryName
    ? stateSurveyJSON.pages[pageIndex].elements![elementIndex].showSecondaryName
    : false;

  const checkedLast = stateSurveyJSON.pages?.[pageIndex].elements?.[
    elementIndex
  ]?.showLastName
    ? stateSurveyJSON.pages[pageIndex].elements![elementIndex].showLastName
    : false;

  return (
    <>
      <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
        <label className="block text-sm font-bold text-gray-500">
          {t("nickname")}
        </label>
        <div className="mt-3 flex">
          <Switch
            id="flexSwitchCheckFirstNameChanger"
            checked={checkedNick}
            onChange={(e) => handleRequiredChangeNick(e)}
            className={classNames(
              checkedNick ? "bg-blue-600" : "bg-gray-200",
              "relative mr-2 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2",
            )}
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={classNames(
                checkedNick ? "translate-x-5" : "translate-x-0",
                "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
              )}
            />
          </Switch>
          <label
            className="text-gray-300"
            htmlFor="flexSwitchCheckFirstNameChanger"
          >
            {checkedNick ? t("open") : t("close")}
          </label>
        </div>
        <p className="mb-0 mt-3 text-sm text-stone-500">
          {t("nicknameDescription")}
        </p>
      </div>
      <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
        <label className="block text-sm font-bold text-gray-500">
          {t("İkincil Ad")}
        </label>
        <div className="mt-3 flex">
          <Switch
            id="flexSwitchCheckSecondName"
            checked={checkedSecondary}
            onChange={(e) => handleRequiredChangeSecondary(e)}
            className={classNames(
              checkedSecondary ? "bg-blue-600" : "bg-gray-200",
              "relative mr-2 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2",
            )}
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={classNames(
                checkedSecondary ? "translate-x-5" : "translate-x-0",
                "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
              )}
            />
          </Switch>
          <label className="text-gray-300" htmlFor="flexSwitchCheckSecondName">
            {checkedSecondary ? t("open") : t("close")}
          </label>
        </div>
        <p className="mb-0 mt-3 text-sm text-stone-500">
          {t("secondaryNameDescription")}
        </p>
      </div>
      <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
        <label className="block text-sm font-bold text-gray-500">
          {t("lastName")}
        </label>
        <div className="mt-3 flex">
          <Switch
            id="flexSwitchCheckSurnameState"
            checked={checkedLast}
            onChange={(e) => handleRequiredChangeLast(e)}
            className={classNames(
              checkedLast ? "bg-blue-600" : "bg-gray-200",
              "relative mr-2 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2",
            )}
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={classNames(
                checkedLast ? "translate-x-5" : "translate-x-0",
                "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
              )}
            />
          </Switch>
          <label
            className="text-gray-300"
            htmlFor="flexSwitchCheckSurnameState"
          >
            {checkedLast ? t("open") : t("close")}
          </label>
        </div>
        <p className="mb-0 mt-3 text-sm text-stone-500">
          {t("lastNameDescription")}
        </p>
      </div>
    </>
  );
};

export default FormSettingsAddNames;

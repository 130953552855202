import React, { FC } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFViewer,
} from "@react-pdf/renderer";
const BORDER_COLOR = "#bdbdbd";
const COL1_WIDTH = 40;
const COLN_WIDTH = (100 - COL1_WIDTH) / 3;
const BLACK_COLOR = "#25292D";
const RED_COLOR = "#EB5757";
const LIGHT_WHITE_COLOR = "#EAEAEA";
const PURPLE_COLOR = "#7158f5";
const GRAY_COLOR = "#ded9fd";

const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    margin: 0,
    padding: 0,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  cover: {
    padding: "56px 0 0",
    justifyContent: "space-between",
    minHeight: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  intro: {
    minHeight: "100vh",
    width: "100%",
    padding: "64px 48px",
    /*  backgroundColor: "#fff", */
    display: "flex",
    flexDirection: "column",
    /*  position: "absolute", */ /* bu kısmı koyarsam position ayarı yanlış oluyor taa tepeye çıkıyor */
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  bullet: {
    height: "100%",
  },
  table: {
    //@ts-ignore
    display: "table",
    width: "auto",
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    display: "flex",
  },
  tableRowHeader: {
    margin: "auto",
    flexDirection: "row",
    display: "flex",
  },
  tableCol1Header: {
    width: COL1_WIDTH + "%",
    borderBottom: "1px thin solid " + BORDER_COLOR,
  },
  tableColHeader: {
    width: COLN_WIDTH + "%",
    borderBottom: "1px thin solid " + BORDER_COLOR,
  },
  tableColHeaderCenter: {
    width: COLN_WIDTH + "%",
    borderBottom: "1px thin solid " + BORDER_COLOR,
    textAlign: "center",
  },
  tableCol1: {
    width: COL1_WIDTH + "%",
    padding: "8px 12px",
    textAlign: "left",
    color: BLACK_COLOR,
    fontWeight: 400,
    fontSize: 14,
    textOverflow: "ellipsis",
    minWidth: 96,
  },
  tableColRate: {
    width: COLN_WIDTH + "%",
    padding: "8px 12px 8px 0",
    textAlign: "left",
    color: BLACK_COLOR,
    fontWeight: 400,
    fontSize: 14,
    textOverflow: "ellipsis",
    minWidth: 96,
  },
  tableColCenter: {
    width: COLN_WIDTH + "%",
    padding: "8px 12px",
    textAlign: "center",
    color: BLACK_COLOR,
    fontWeight: 400,
    fontSize: 14,
    textOverflow: "ellipsis",
    minWidth: 96,
  },
  tableCellHeader: {
    padding: "8px 12px",
    textAlign: "left",
    textTransform: "uppercase",
    fontSize: 12,
    color: "rgba(99, 104, 111, 0.5)",
    fontWeight: 700,
    /* verticalAlign: "sub", */
  },
  tableCellHeaderCenter: {
    padding: "8px",
    textAlign: "center",
    textTransform: "uppercase",
    fontSize: 12,
    color: "rgba(99, 104, 111, 0.5)",
    fontWeight: 700,
    /* verticalAlign: "sub", */
  },
  tableCell: {
    margin: 0,
    fontWeight: 500,
    fontSize: 16,
    color: BLACK_COLOR,
  },
  tableCellRate: {
    marginLeft: 16,
    fontWeight: 400,
    fontSize: 14,
    color: BLACK_COLOR,
  },
  tableCellCenter: {
    margin: 0,
    fontWeight: 500,
    fontSize: 16,
    color: BLACK_COLOR,
  },
  rateRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  progressWrapper: {
    display: "flex",
    width: "75%",
    minHeight: 8,
    backgroundColor: "#bdbdbd80",
    borderRadius: 10,
    overflow: "hidden",
  },
  semiboldHeaderRed: {
    color: RED_COLOR,
    fontSize: 18,
    padding: 8,
    fontWeight: 600,
  },
  borderLeftSemiboldHeaderRed: {
    borderLeft: "2px solid " + RED_COLOR,
    color: RED_COLOR,
    fontSize: 18,
    padding: 8,
    fontWeight: 600,
  },
  coverImage: {
    height: 624,
    margin: 0,
    objectFit: "contain",
  },
});

const PDF: FC = () => {
  const TableContent = [
    {
      RELATIONSHIP: "Self",
      NOMINATED: "1",
      COMPLETED: "1",
    },
    {
      RELATIONSHIP: "Peer",
      NOMINATED: "2",
      COMPLETED: "1",
    },
    {
      RELATIONSHIP: "Reportee",
      NOMINATED: "2",
      COMPLETED: "2",
    },
    {
      RELATIONSHIP: "Manager",
      NOMINATED: "1",
      COMPLETED: "1",
    },
  ];
  return (
    <div className="flex-grow max-w-full">
      <div className="items-start flex flex-row ">
        <div className="w-[452px] min-w-[452px] max-w-[452px]">Sidebar</div>
        <div className="px-16 h-[calc(100vh - 68px)] flex-grow items-center justify-start flex-col flex text-base overflow-x-hidden overflow-y-auto p-8 ">
          <div className="w-full mb-4 flex-row flex items-center">
            Sample Preview
          </div>
          <div className="w-full max-w-[920px]">
            <div className="relative">
              <div className="w-full mb-8 flex justify-center ">
                {/*         <PDFViewer
                  style={{
                    margin: 0,
                    padding: 0,
                    height: "calc(100vh - 68px)",
                  }}
                  width={820}
                  showToolbar={false}
                > */}
                <Document>
                  <Page size={"A4"} style={styles.page} wrap={false}>
                    <View id="cover" style={styles.cover}>
                      <View
                        style={{
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <View
                          style={{
                            marginTop: 20,
                            marginBottom: 20,
                            textAlign: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 40,
                              color: BLACK_COLOR,
                              fontWeight: 700,
                            }}
                          >
                            360 Degree Feedback Survey
                          </Text>
                        </View>
                        <View
                          style={{
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                          }}
                        >
                          <View
                            style={{
                              border: "2px solid " + RED_COLOR,
                              maxWidth: "80%",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Text style={styles.semiboldHeaderRed}>
                              John Doe
                            </Text>
                            <Text style={styles.borderLeftSemiboldHeaderRed}>
                              Jan 30th 2024
                            </Text>
                          </View>
                        </View>
                      </View>
                      <View style={{ height: 624, width: "100%" }}>
                        <Image style={styles.coverImage} src="/persty.png" />
                      </View>
                      <View style={{ width: "100%", padding: "0 0 32px" }}>
                        <Text
                          style={{
                            marginBottom: "4px",
                            textAlign: "center",
                            color: "#63686F",
                            fontSize: "14px",
                            fontWeight: 400,
                            margin: 0,
                          }}
                        >
                          This report is confidential and should not be
                          distributed without permission.
                        </Text>
                      </View>
                    </View>
                    <View style={styles.intro} id="intro">
                      <Header>Introduction</Header>
                      <Paragraph>
                        You recently participated in Employee 360 Feedback. The
                        goal of Employee 360 Feedback is for your personal
                        development, to help you grow and achieve more in your
                        career. You and your nominees were asked to provide
                        feedback on your effectiveness on various competencies.
                      </Paragraph>
                      <Header>Purpose</Header>
                      <ListItem>Modern HTML, JS, CSS, SASS</ListItem>
                      <ListItem>Javascript, Typescript</ListItem>
                      <ListItem>Adobe Air</ListItem>
                      <SmallHeader>Participants</SmallHeader>
                      <Paragraph>
                        The following is a summary of the group of respondents
                        who were invited to participate and provide feedback for
                        you.
                      </Paragraph>
                      <View style={styles.table}>
                        <View style={styles.tableRowHeader}>
                          <View style={styles.tableCol1Header}>
                            <Text style={styles.tableCellHeader}>
                              RELATIONSHIP
                            </Text>
                          </View>
                          <View style={styles.tableColHeaderCenter}>
                            <Text style={styles.tableCellHeaderCenter}>
                              NOMINATED
                            </Text>
                          </View>
                          <View style={styles.tableColHeaderCenter}>
                            <Text style={styles.tableCellHeaderCenter}>
                              COMPLETED
                            </Text>
                          </View>
                          <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>
                              COMPLETION RATE
                            </Text>
                          </View>
                        </View>
                        {TableContent.map((item) => {
                          const rate =
                            (Number(item.COMPLETED) / Number(item.NOMINATED)) *
                            100;
                          return (
                            <View style={styles.tableRow}>
                              <View style={styles.tableCol1}>
                                <Text style={styles.tableCell}>
                                  {item.RELATIONSHIP}
                                </Text>
                              </View>
                              <View style={styles.tableColCenter}>
                                <Text style={styles.tableCellCenter}>
                                  {item.NOMINATED}
                                </Text>
                              </View>
                              <View style={styles.tableColCenter}>
                                <Text style={styles.tableCellCenter}>
                                  {item.COMPLETED}
                                </Text>
                              </View>
                              <View style={styles.tableColRate}>
                                <View style={styles.rateRow}>
                                  <View style={styles.progressWrapper}>
                                    <View
                                      style={{
                                        width: `${
                                          (Number(item.COMPLETED) /
                                            Number(item.NOMINATED)) *
                                          100
                                        }%`,
                                        backgroundColor: `${
                                          rate === 100
                                            ? "#448e97"
                                            : rate > 70
                                              ? "#f2c94c"
                                              : "#ff3b3b"
                                        } `,
                                        height: 8,
                                      }}
                                    ></View>
                                  </View>
                                  <Text style={styles.tableCellRate}>
                                    {rate} %
                                  </Text>
                                </View>
                              </View>
                            </View>
                          );
                        })}
                      </View>
                    </View>
                    <View id="summary" style={styles.intro}>
                      <Header>Competency Summary</Header>
                      <SmallHeader>Overall Summary</SmallHeader>
                      <Paragraph>
                        This section will give you an idea on overall score of
                        an employee
                      </Paragraph>
                      <View
                        style={{
                          marginTop: 32,
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <View
                          style={{
                            paddingBottom: "4px",
                            marginBottom: 24,
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 66,
                              fontWeight: 300,
                              color: "black",
                            }}
                          >
                            3.5
                          </Text>
                          <Text
                            style={{
                              color: "black",
                              fontSize: 16,
                              fontWeight: 500,
                            }}
                          >
                            /5
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            marginBottom: 16,
                            alignItems: "center",
                          }}
                        >
                          <Text
                            style={{
                              marginRight: 4,
                              color: BLACK_COLOR,
                              fontSize: 14,
                              fontWeight: 500,
                            }}
                          >
                            Leadership Skills
                          </Text>
                          <SummaryItem>36</SummaryItem>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            marginBottom: 16,
                            alignItems: "center",
                          }}
                        >
                          <Text
                            style={{
                              marginRight: 4,
                              color: BLACK_COLOR,
                              fontSize: 14,
                              fontWeight: 500,
                            }}
                          >
                            Organizational Alignment
                          </Text>
                          <SummaryItem>64</SummaryItem>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            marginBottom: 16,
                            alignItems: "center",
                          }}
                        >
                          <Text
                            style={{
                              marginRight: 4,
                              color: BLACK_COLOR,
                              fontSize: 14,
                              fontWeight: 500,
                            }}
                          >
                            Organizational Skills
                          </Text>
                          <SummaryItem>54</SummaryItem>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            marginBottom: 16,
                            alignItems: "center",
                          }}
                        >
                          <Text
                            style={{
                              marginRight: 4,
                              color: BLACK_COLOR,
                              fontSize: 14,
                              fontWeight: 500,
                            }}
                          >
                            Creativity Skills
                          </Text>
                          <SummaryItem>81</SummaryItem>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            marginBottom: 16,
                            alignItems: "center",
                          }}
                        >
                          <Text
                            style={{
                              marginRight: 4,
                              color: BLACK_COLOR,
                              fontSize: 14,
                              fontWeight: 500,
                            }}
                          >
                            bombaaa
                          </Text>
                          <SummaryItem>98</SummaryItem>
                        </View>
                      </View>
                      <SubjectAnalys
                        generalPoint={2.5}
                        header="test"
                        paragraph="test2"
                        progressValues={[
                          { title: "sdf", point: 5 },
                          { title: "qewe", point: 7 },
                          { title: "sadsadsda", point: 12 },
                        ]}
                      ></SubjectAnalys>
                      <SubjectAnalys
                        generalPoint={3.8}
                        header="Communication Skills"
                        paragraph="This section will be used to rate the employee based on their Communication Skills"
                        progressValues={[
                          { title: "Self", point: 4.0 },
                          { title: "Peer", point: 3.2 },
                          { title: "Reportee", point: 4.0 },
                          { title: "Manager", point: 4.7 },
                        ]}
                      ></SubjectAnalys>
                      <View id="strength" style={styles.intro}>

                      </View>
                    </View>
                  </Page>
                </Document>
                {/*  </PDFViewer> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ListItem = ({ children }: { children: React.ReactNode }) => {
  return (
    <View style={styles.row}>
      <View style={styles.bullet}>
        <Text>{`\u2022 `}</Text>
      </View>
      <Text>{children}</Text>
    </View>
  );
};

const Paragraph = ({ children }: { children: React.ReactNode }) => {
  return (
    <Text
      style={{
        fontWeight: 400,
        fontSize: "16px",
        marginBottom: "16px",
        lineHeight: "1.6",
        color: "#63686F",
      }}
    >
      {children}
    </Text>
  );
};

const Header = ({ children }: { children: React.ReactNode }) => {
  return (
    <Text
      style={{
        backgroundColor: "#fff",
        fontWeight: 600,
        fontSize: "28px",
        marginBottom: "20px",
      }}
    >
      {children}
    </Text>
  );
};

const SmallHeader = ({ children }: { children: React.ReactNode }) => {
  return (
    <Text
      style={{
        backgroundColor: "#fff",
        fontWeight: 600,
        fontSize: "20px",
        marginBottom: "12px",
        marginTop: "24px",
      }}
    >
      {children}
    </Text>
  );
};

const SummaryItem = ({ children }: { children: React.ReactNode }) => {
  const rateFrom5 = Number(children) / 20;
  return (
    <View
      style={{
        marginBottom: 16,
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <View style={{ flexShrink: 0, width: 250, height: 24 }}>
        <View
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <View
            style={{
              height: 24,
              width: "100%",
              borderRadius: 35,
              overflow: "hidden",
              backgroundColor: LIGHT_WHITE_COLOR,
            }}
          >
            <View
              style={{
                width: children!.toString() + "%",
                backgroundColor: "yellow",
                fontSize: 11,
                borderRadius: 20,
                height: "100%",
                display: "flex",
                alignItems: "center",
              }}
            ></View>
          </View>
          <Text
            style={{
              color: BLACK_COLOR,
              fontSize: 14,
              marginRight: 4,
              fontWeight: 500,
            }}
          >
            {rateFrom5}
          </Text>
        </View>
      </View>
    </View>
  );
};

const SubjectAnalys = ({
  header,
  paragraph,
  generalPoint,
  progressValues,
}: {
  header: string;
  paragraph: string;
  generalPoint: number;
  progressValues: { title: string; point: number }[];
}) => {
  return (
    <View style={{ marginBottom: 56, marginTop: 8, width: "100%" }}>
      <View style={{ display: "flex" }}>
        <SmallHeader>{header}</SmallHeader>
      </View>
      <Paragraph>{paragraph}</Paragraph>
      <View
        style={{
          marginTop: 32,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <View style={{ width: "75%" }}>
          <View
            style={{
              paddingBottom: "4px",
              display: "flex",
              flexDirection: "row",
              height: 70,
            }}
          >
            <Text
              style={{
                fontSize: 66,
                fontWeight: 300,
                color: "black",
              }}
            >
              {generalPoint}
            </Text>
            <Text
              style={{
                color: "black",
                fontSize: 16,
                fontWeight: 500,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              /5
            </Text>
          </View>
        </View>
        <View
          style={{
            paddingLeft: 8,
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {progressValues.map((item) => (
            <View
              style={{
                paddingBottom: 2,
                marginBottom: 4,
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Text
                style={{
                  marginRight: 4,
                  color: BLACK_COLOR,
                  fontSize: 14,
                  fontWeight: 500,
                }}
              >
                {item.title}
              </Text>
              <View style={{ width: 250, height: 24, flexShrink: 0 }}>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <View
                    style={{
                      height: 24,
                      width: "100%",
                      borderRadius: 35,
                      overflow: "hidden",
                      backgroundColor: LIGHT_WHITE_COLOR,
                    }}
                  >
                    <View
                      style={{
                        width: "80%",
                        backgroundColor: PURPLE_COLOR,
                        fontSize: 11,
                        borderRadius: 20,
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <View style={{ color: GRAY_COLOR, marginLeft: 8 }}>
                        <Text style={{ color: GRAY_COLOR, fontWeight: 600 }}>
                          {item.point}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          ))}
        </View>
      </View>
    </View>
  );
};

export default PDF;

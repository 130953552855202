import FormSettingsTip from "../../components/FormSettingsTip";
import FormSettingsCollapse from "../../components/FormSettingsCollapse";
import FormSettingsReadOnly from "../../components/FormSettingsReadOnly";
import FormSettingsAddToLibrary from "../../components/FormSettingsAddToLibrary";
import FormSettingsPlaceholderTel from "../../components/placeholder/FormSettingsPlaceholderTel";

export default function TelephoneAdvanceSettings() {
  return (
    <>
      <FormSettingsPlaceholderTel />
      <FormSettingsTip />
      <FormSettingsCollapse />
      <FormSettingsReadOnly />
      <FormSettingsAddToLibrary />
    </>
  );
}

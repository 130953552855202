import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/20/solid";
import { selectedUserAtom } from "components/atoms/dashboardAtoms";
import Badge from "components/tailwindUi/Badge";
import talentData from "data/talentData.json";
import { useAtomValue } from "jotai";
import {
  getRandomEmail,
  getRandomTurkishPhone,
} from "pages/dashboard2/Employees/Workers";
import { useMemo, useState } from "react";

export default function EmployeProfileTeam() {
  const people = useMemo(
    () =>
      (talentData as any[]).map((x) => ({
        ...x,
        adSoyad: x.ad + " " + x.soyad,
      })),
    [talentData],
  );
  const selectedUser = useAtomValue(selectedUserAtom);

  const TEAM = useMemo(() => {
    const filteredByDepartment = people.filter((p) =>
      selectedUser.departman === "Üst Yönetim"
        ? p.pozisyon === "Yönetici"
        : p.departman === selectedUser.departman,
    );
    const ownIndex = filteredByDepartment.findIndex(
      (p) => p.id === selectedUser.id,
    );

    return [
      filteredByDepartment[ownIndex],
      ...filteredByDepartment.slice(0, ownIndex),
      ...filteredByDepartment.slice(ownIndex + 1),
    ];
  }, [people, selectedUser]);

  const isOwn = (person: any) => person.id === selectedUser.id;

  const [sortOrder, setSortOrder] = useState("none");

  const handleSortClick = () => {
    if (sortOrder === "none") {
      setSortOrder("asc");
    } else if (sortOrder === "asc") {
      setSortOrder("desc");
    } else {
      setSortOrder("none");
    }
  };

  const SORTED_TEAM = Object.freeze(
    [...TEAM].sort((a, b) => {
      if (sortOrder === "asc") {
        return a.adSoyad.localeCompare(b.adSoyad);
      } else if (sortOrder === "desc") {
        return b.adSoyad.localeCompare(a.adSoyad);
      } else {
        return 0;
      }
    }),
  );

  return (
    <div className="mt-[30px] flex flex-col items-center">
      {/* table header */}
      <div className="flex w-[710px] rounded-t-xl border border-gray-200 bg-gray-50 text-xs font-medium leading-[18px] text-gray-500">
        <div className="flex w-[350px] px-6 py-3">
          <span className="cursor-pointer" onClick={handleSortClick}>
            Çalışanlar
          </span>
          <span onClick={handleSortClick} className="cursor-pointer pl-1">
            {sortOrder !== "none" && (
              <>
                {sortOrder === "asc" ? (
                  <ArrowDownIcon className="h-4" />
                ) : (
                  <ArrowUpIcon className="h-4" />
                )}
              </>
            )}
          </span>
        </div>
        <div className="flex w-[240px] items-center border-l border-r border-gray-200 px-6 py-3">
          İletişim
        </div>
        <div className="flex w-[120px] items-center justify-center p-3">
          Hiyerarşi
        </div>
      </div>

      {/* table items */}
      {SORTED_TEAM.map((member, index) => (
        <div
          className={
            "flex w-[710px] border-x border-b border-gray-200 bg-white text-sm font-medium leading-tight text-gray-800 " +
            (index === SORTED_TEAM.length - 1 ? "rounded-b-xl" : "")
          }
          key={index}
        >
          <div className="flex w-[350px] px-6 py-3">
            <img
              className="mr-3 h-[32px] w-[32px] rounded-full"
              src={"/avatars/" + member.id + ".png"}
              alt=""
              onError={(e) => {
                (e.target as any).src = "/avatars/default.png";
              }}
            />

            <div className="flex flex-col">
              <div className="text-sm font-medium leading-tight text-gray-800">
                {member.adSoyad}
              </div>

              <div className="text-xs font-normal leading-[18px] text-slate-600">
                {member.isUnvani}
              </div>
            </div>
          </div>

          <div className="flex w-[240px] flex-col justify-center border-l border-r border-gray-200 px-6 py-3">
            <div className="text-sm font-medium leading-tight text-gray-900">
              {getRandomTurkishPhone(member.id)}
            </div>

            <div className="text-sm font-normal leading-tight text-slate-600">
              {getRandomEmail(member.id)}
            </div>
          </div>

          <div className="flex w-[120px] items-center justify-center p-4">
            <Badge
              color={getBadgeColorBasedOnHierarchy(
                isOwn(member) ? "Kendi" : member.pozisyon,
              )}
            >
              <span className="px-1 py-[2px] text-sm font-semibold leading-none">
                {isOwn(member) ? "Kendi" : member.pozisyon}
              </span>
            </Badge>
          </div>
        </div>
      ))}
    </div>
  );
}

const BADGE_COLOR_MAPPING: { [key: string]: string } = Object.freeze({
  Kendi: "gray",
  Yönetici: "red",
  Çalışan: "blue",
});

function getBadgeColorBasedOnHierarchy(hierarchy: string) {
  return BADGE_COLOR_MAPPING[hierarchy];
}

const HIERARCHY_TEXT_MAPPING: { [key: string]: string } = Object.freeze({
  own: "Kendi",
  manager: "Yönetici",
  subordinate: "Ast",
  superior: "Üst",
  equal: "Eşit",
});

function getHierarchyText(hierarchy: string) {
  return HIERARCHY_TEXT_MAPPING[hierarchy];
}

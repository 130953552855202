import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { AnimatePresence, motion } from "framer-motion";
import CreatableSelect from "react-select/creatable";
import { v4 as uuidv4 } from "uuid";
import {
  encryptStrings,
  isValidEmail,
  removeDuplicates,
} from "../../../lib/externalJS";
import { assessorsInterface } from "../../../Interfaces/SurveyInterfaces";
import { useCreateAssesor } from "../../../services/mutations/assesorsMutations";
import {
  useCreateParticipant,
  useUpdateParticipant,
} from "../../../services/mutations/ParticipationsMutations";
import { framerSidebarPanel } from "../../../lib/consts";
import { useAtom } from "jotai";
import { surveyAtom } from "../../../components/atoms/surveyAtoms";

type Props = {
  showAddUser: boolean;
  selectedUser: any;
  setShowAddUser: Dispatch<SetStateAction<boolean>>;
  setUsers: Dispatch<SetStateAction<any[]>>;
  setSelectedUser: Dispatch<SetStateAction<any>>;
  setIsUpdating: Dispatch<SetStateAction<boolean>>;
  isUpdating: boolean;
};

const SidebarAddUser: React.FC<Props> = ({
  showAddUser,
  setShowAddUser,
  setUsers,
  selectedUser,
  setSelectedUser,
  setIsUpdating,
  isUpdating,
}) => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);

  const [addAssessor, setAddAssessor] = useState(false);
  const [addAssessors, setAddAssessors] = useState<assessorsInterface[]>(
    selectedUser?.assessors || [],
  );
  const [assessorValues, setAssessorsValues] = useState<any>();
  const [participantValues, setParticipantValues] = useState<any>({
    _id: selectedUser?._id,
    name: selectedUser?.name,
    mail: selectedUser?.mail,
  });
  const createAssesorMutation = useCreateAssesor();
  const createParticipantMutation = useCreateParticipant();
  const updateParticipantMutation = useUpdateParticipant();

  useEffect(() => {
    setParticipantValues({
      _id: selectedUser?._id,
      name: selectedUser?.name,
      mail: selectedUser?.mail,
    });
    setAddAssessors(selectedUser?.assessors || []);
  }, [selectedUser]);

  const updateEvaluatorValue = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setAssessorsValues((prevValues: any) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const updateParticipantValue = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setParticipantValues((prevValues: any) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  //BURASI sidebar içini siliyor gerek yok
  const deleteEvulator = (_id: string) => {
    setAddAssessors((prevRoles) =>
      prevRoles.filter((evaluator) => evaluator._id !== _id),
    );
  };

  const saveEvaluators = () => {
    setAddAssessors((prevEvaluators) => [
      ...prevEvaluators,
      {
        ...assessorValues,
        _id: uuidv4(),
        completedPercentage: 0,
        isCompleted: false,
      },
    ]);
    setAssessorsValues(undefined);
    setAddAssessor(false);
  };

  const saveParticipant = () => {
    setUsers((prevValues) => {
      let updatedUsers = prevValues.map((participant) => {
        return participant._id === participantValues._id
          ? {
              ...participantValues,
              assessors: addAssessors,
            }
          : participant;
      });
      let updatedUsersForParticipants = prevValues.map((participant) =>
        participant._id === participantValues._id
          ? {
              ...participantValues,
              assessorIds: addAssessors.map((assessor) => assessor._id),
            }
          : participant,
      );

      updatedUsers = removeDuplicates(updatedUsers);
      updatedUsersForParticipants = removeDuplicates(
        updatedUsersForParticipants,
      );

      if (prevValues.length === 0) {
        let uuidForData = uuidv4();
        updatedUsers.push({
          ...participantValues,
          _id: participantValues?._id
            ? participantValues._id
            : selectedUser?._id
              ? selectedUser._id
              : uuidForData,
          assessors: addAssessors,
        });
        updatedUsersForParticipants.push({
          ...participantValues,
          _id: participantValues?._id
            ? participantValues._id
            : selectedUser?._id
              ? selectedUser._id
              : uuidForData,
          assessorIds: addAssessors.map((assessor) => assessor._id),
        });
      } else if (!participantValues._id) {
        let uuidForData = uuidv4();
        updatedUsers.push({
          ...participantValues,
          _id: uuidForData,
          assessors: addAssessors,
        });
        updatedUsersForParticipants.push({
          ...participantValues,
          _id: uuidForData,
          assessorIds: addAssessors.map((assessor) => assessor._id),
        });
      } else {
        updatedUsers.push({
          ...participantValues,
          _id: participantValues._id,
          assessors: addAssessors,
        });
        updatedUsersForParticipants.push({
          ...participantValues,
          _id: participantValues._id,
          assessorIds: addAssessors.map((assessor) => assessor._id),
        });
      }

      updatedUsers = removeDuplicates(updatedUsers);
      updatedUsersForParticipants = removeDuplicates(
        updatedUsersForParticipants,
      );

      setStateSurveyJSON((prevSurveyJSON) => ({
        ...prevSurveyJSON,
        participants: updatedUsers,
      }));

      if (isUpdating) {
        updatedUsersForParticipants.forEach((participant) => {
          updateParticipantMutation.mutate(participant);
        });
      } else {
        updatedUsersForParticipants.forEach((participant) => {
          createParticipantMutation.mutate(participant);
        });
      }

      addAssessors.forEach((assessor) => {
        const relatedParticipant = updatedUsersForParticipants.find(
          (participant) => {
            return participant.assessorIds.includes(assessor._id);
          },
        );
        console.log("relatedParticipant", relatedParticipant);

        if (relatedParticipant) {
          createAssesorMutation.mutate({
            ...assessor,
            surveyProperties: {
              url: encryptStrings(
                stateSurveyJSON._id,
                relatedParticipant._id,
                assessor._id,
              ),
              title: stateSurveyJSON.title,
            },
          });
        }
      });

      return updatedUsers;
    });

    setAddAssessor(false);
    setAddAssessors([]);
    setParticipantValues({});
    setShowAddUser(false);
    setSelectedUser(null);
  };

  return (
    <AnimatePresence mode="wait" initial={false}>
      {showAddUser ? (
        <motion.div
          className={`w-full max-w-full border`}
          {...framerSidebarPanel}
        >
          <div className="flex flex-wrap">
            <div className="relative flex w-full min-w-0 flex-wrap items-center justify-between break-words border-b-2 p-2 text-sm font-semibold">
              {selectedUser ? "Katılımcı Düzenle" : "Yeni Katılımcı Ekle"}
              <span
                className="cursor-pointer rounded-md p-2 transition-all delay-100 hover:bg-blue-200"
                onClick={() => {
                  setShowAddUser(false);
                  setAddAssessors([]);
                  setParticipantValues({});
                  setSelectedUser(null);
                  setIsUpdating(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-x-lg"
                  viewBox="0 0 16 16"
                >
                  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                </svg>
              </span>
            </div>
            <form className="relative mb-6 flex h-[calc(100vh-170px)] w-full min-w-0 flex-col break-words bg-white">
              <div className="custom-scrollbar-container flex-auto py-0">
                <div
                  className="tab-content tab-space flex h-full flex-auto flex-col pt-2"
                  style={{ scrollbarGutter: "stable" }}
                >
                  <div className={"block w-full"} id="link1">
                    <div className="p-3">
                      <h6 className="font-bold">Katılımcı</h6>
                      <div className="flex flex-col">
                        <label htmlFor="inputName">İsim</label>
                        <input
                          type="text"
                          id="inputName"
                          className="rounded-md border-2 border-gray-200 px-2 py-[6px] outline-none"
                          placeholder="İsim"
                          defaultValue={selectedUser?.name}
                          name="name"
                          required
                          onChange={(e) => updateParticipantValue(e)}
                        />
                      </div>
                      <div className="mt-2 flex flex-col">
                        <label htmlFor="inputEmail">E-posta</label>
                        <input
                          type="email"
                          id="inputEmail"
                          defaultValue={selectedUser?.mail}
                          className="rounded-md border-2 border-gray-200 px-2 py-[6px] outline-none"
                          placeholder="E-posta"
                          name="mail"
                          required
                          onChange={(e) => updateParticipantValue(e)}
                        />
                      </div>
                    </div>
                    <hr />
                    <div className="px-3">
                      <h6 className="font-bold">Değerlendiriciler</h6>
                      {addAssessor ? (
                        <div>
                          <div>
                            <label>Rol</label>
                            <CreatableSelect
                              className="mb-1"
                              placeholder="Rol"
                              isSearchable={false}
                              formatCreateLabel={(inputValue) =>
                                `Yeni rol: ${inputValue}`
                              }
                              isClearable
                              onChange={(e) => {
                                setAssessorsValues((prevValues: any) => ({
                                  ...prevValues,
                                  roleTowardsParticipant: e ? e.value : null,
                                  _id: uuidv4(),
                                }));
                              }}
                              required
                              /* TODO buraya eklenmiş olan tüm roller + default roller gelmeli */
                              options={[
                                { value: "Üst", label: "Üst" },
                                { value: "As", label: "As" },
                                { value: "Kendisi", label: "Kendisi" },
                                { value: "Mevkidaş", label: "Mevkidaş" },
                              ]}
                            />
                          </div>
                          <div>
                            <label
                              htmlFor="inputNameForEvaluator"
                              className="my-2"
                            >
                              İsim
                            </label>
                            <input
                              id="inputNameForEvaluator"
                              required
                              type="text"
                              className="mb-1 h-10 w-full rounded border px-2 outline-none"
                              placeholder="İsim"
                              name="name"
                              onChange={(e) => updateEvaluatorValue(e)}
                            />
                          </div>
                          <div>
                            <label
                              htmlFor="inputEmailForEvaluator"
                              className="my-2"
                            >
                              E-posta
                            </label>
                            <input
                              id="inputEmailForEvaluator"
                              type="email"
                              className="h-10 w-full rounded border px-2 outline-none"
                              placeholder="E-posta"
                              required
                              name="mail"
                              onChange={(e) => updateEvaluatorValue(e)}
                            />
                          </div>
                          <div className="flex justify-end">
                            <button
                              className="mt-3 font-semibold text-blue-700"
                              type="button"
                              onClick={saveEvaluators}
                              disabled={
                                !assessorValues?.name ||
                                !assessorValues?.mail ||
                                !assessorValues?.roleTowardsParticipant ||
                                !assessorValues.mail.match(isValidEmail)
                              }
                            >
                              Kaydet
                            </button>
                          </div>
                        </div>
                      ) : (
                        <>
                          <button
                            className="w-full rounded-md border-2 border-blue-400 px-3 py-2 text-center align-middle text-sm hover:bg-blue-300 hover:text-white"
                            onClick={() => setAddAssessor(true)}
                          >
                            <span>Yeni Değerlendirici Ekle</span>
                          </button>
                          {addAssessors.length > 0 &&
                            addAssessors.map((evaluator, index) => (
                              <div key={evaluator._id}>
                                <div className="mt-2 flex h-10 w-[230px] items-center justify-between">
                                  <div className="flex flex-col">
                                    <span className="font-semibold">
                                      {evaluator.name}
                                    </span>
                                    <span className="font-light">
                                      {evaluator.roleTowardsParticipant}
                                    </span>
                                  </div>
                                  <div>
                                    <svg
                                      width="20"
                                      height="20"
                                      viewBox="0 0 20 20"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="cursor-pointer"
                                      onClick={() =>
                                        deleteEvulator(evaluator._id)
                                      }
                                    >
                                      <path
                                        d="M13.3333 4.99984V4.33317C13.3333 3.39975 13.3333 2.93304 13.1517 2.57652C12.9919 2.26292 12.7369 2.00795 12.4233 1.84816C12.0668 1.6665 11.6001 1.6665 10.6667 1.6665H9.33333C8.39991 1.6665 7.9332 1.6665 7.57668 1.84816C7.26308 2.00795 7.00811 2.26292 6.84832 2.57652C6.66667 2.93304 6.66667 3.39975 6.66667 4.33317V4.99984M8.33333 9.58317V13.7498M11.6667 9.58317V13.7498M2.5 4.99984H17.5M15.8333 4.99984V14.3332C15.8333 15.7333 15.8333 16.4334 15.5608 16.9681C15.3212 17.4386 14.9387 17.821 14.4683 18.0607C13.9335 18.3332 13.2335 18.3332 11.8333 18.3332H8.16667C6.76654 18.3332 6.06647 18.3332 5.53169 18.0607C5.06129 17.821 4.67883 17.4386 4.43915 16.9681C4.16667 16.4334 4.16667 15.7333 4.16667 14.3332V4.99984"
                                        stroke="#475467"
                                        strokeWidth="1.66667"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </div>
                                </div>
                                {index !== addAssessors.length - 1 && <hr />}
                              </div>
                            ))}
                        </>
                      )}
                    </div>
                    <hr />
                  </div>
                  <div className="mt-auto">
                    <hr />
                    <div className="flex items-center justify-around">
                      <button
                        className="h-9 w-[83px] cursor-pointer rounded-md border-2 border-gray-300 px-3 py-2 text-center align-middle text-sm"
                        onClick={() => {
                          setShowAddUser(false);
                          setAddAssessors([]);
                          setParticipantValues({});
                          setSelectedUser(null);
                        }}
                      >
                        Vazgeç
                      </button>
                      <button
                        type="submit"
                        onClick={(e) => {
                          e.preventDefault();
                          saveParticipant();
                        }}
                        className="h-9 w-[110px] rounded-lg bg-[#2970ff] px-3 py-2 text-center align-middle text-sm text-white hover:bg-blue-700"
                        disabled={
                          !participantValues?.name ||
                          !participantValues?.mail ||
                          !participantValues.mail.match(isValidEmail)
                        }
                      >
                        Onayla
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
};

export default SidebarAddUser;

import { selectedUserAtom } from "components/atoms/dashboardAtoms";
import Badge from "components/tailwindUi/Badge";
import ArrowDownLeft from "images/arrow-down-left.svg";
import ArrowUpRight from "images/arrow-up-right.svg";
import CheckIndeterminateSmall from "images/check_indeterminate_small.svg";
import { useAtomValue } from "jotai";
import { classNames } from "lib/externalJS";
import { objectKeyToScreenName } from "pages/dashboard2/EmployeeProfileSkillsTech";
import EmployeeProfileCard from "./EmployeeProfileCard";

const SKILLS_PANEL_CARDS = Object.freeze([
  {
    titleEmoji: "💻",
    title: "Teknoloji Becerileri",
    bgClassName: "bg-gray-100",
    puanKey: "puanParent_teknoloji̇Beceri̇leri̇",
    avgPuanKeys: [
      "puanTeknoloji_computereaseConstructionAccountingSoftware",
      "puanTeknoloji_fonMuhasebesiYazilimi",
      "puanTeknoloji_intuitQuickbooks",
    ],
  },
  {
    titleEmoji: "🛠️",
    title: "Genel Beceriler",
    bgClassName: "bg-violet-100",
    puanKey: "puanParent_beceri̇ler",
    avgPuanKeys: [
      "puanBeceriler_i̇cerik",
      "puanBeceriler_surec",
      "puanBeceriler_sosyalBeceriler",
    ],
  },
  {
    titleEmoji: "👩‍💻",
    title: "Çalışma Tarzı",
    bgClassName: "bg-gray-100",
    puanKey: "puanParent_calismaTarzi",
    avgPuanKeys: [
      "puanCalismatarzi_basariOdaklilik",
      "puanCalismatarzi_i̇cUyum",
      "puanCalismatarzi_sorumluluk",
    ],
  },
  {
    titleEmoji: "📚",
    title: "Bilgi",
    bgClassName: "bg-green-100",
    puanKey: "puanParent_bi̇lgi̇",
    avgPuanKeys: [
      "puanBilgi_sanatVeBeseriBilimler",
      "puanBilgi_i̇sletmeVeYönetim",
      "puanBilgi_i̇letisim",
    ],
  },
  {
    titleEmoji: "💫",
    title: "Yetkinlikler",
    bgClassName: "bg-yellow-100",
    puanKey: "puanParent_kabi̇li̇yetler",
    avgPuanKeys: [
      "puanKabiliyetler_dikkatlilik",
      "puanKabiliyetler_fikirUretmeVeMuhakemeYetenekleri",
      "puanKabiliyetler_hafiza",
    ],
  },
]);

export function parsePuans(puanObject: {
  kendisi: string;
  ustu1: string;
  ustu2: string;
  asti: string;
  esiti: string;
  ortalama: string;
  yuzde: string;
}) {
  return [
    puanObject.kendisi,
    puanObject.ustu1,
    puanObject.asti,
    puanObject.ustu2,
    puanObject.esiti,
  ];
}

export default function EmployeeProfileSkillsPanel() {
  const selectedUser = useAtomValue(selectedUserAtom);

  console.log("selectedUser");
  console.log(selectedUser);
  console.log("selectedUser");

  return (
    <>
      <EmployeeProfileCard
        titleEmoji="🌟"
        title="Yeteneklerim"
        bgClassName="bg-yellow-100"
      >
        <SkillsTable
          rows={[
            [
              "Teknoloji Becerileri",
              ...parsePuans(selectedUser.puanParent_teknoloji̇Beceri̇leri̇),
            ],
            [
              "Genel Beceriler",
              ...parsePuans(selectedUser.puanParent_beceri̇ler),
            ],
            [
              "Çalışma Tarzı",
              ...parsePuans(selectedUser.puanParent_calismaTarzi),
            ],
            ["Bilgi", ...parsePuans(selectedUser.puanParent_bi̇lgi̇)],
            [
              "Yetkinlikler",
              ...parsePuans(selectedUser.puanParent_kabi̇li̇yetler),
            ],
          ]}
          moveAbove
        />
      </EmployeeProfileCard>

      <div className="grid grid-cols-3 gap-[26px]">
        {SKILLS_PANEL_CARDS.map((n) => (
          <EmployeeProfileCard key={n.title} {...n}>
            <div className="mb-4 flex flex-col items-center gap-y-[6px] rounded bg-gray-100 py-5">
              <span className="text-xs font-semibold leading-[18px] text-gray-500">
                ORTALAMA
              </span>

              <div className="text-[40px]">
                <span className="font-bold leading-[14px] text-gray-800">
                  {selectedUser[n.puanKey].yuzde + " "}
                </span>
                <span className="font-normal leading-[14px] text-gray-400">
                  {"/ 100"}
                </span>
              </div>
            </div>

            <div className="flex flex-col gap-y-3">
              {n.avgPuanKeys?.map((avgPuanKey) => (
                <div className="flex flex-col gap-y-[6px]">
                  <div className="text-sm font-medium leading-[18px] text-slate-600">
                    {objectKeyToScreenName(avgPuanKey)}
                  </div>

                  <ScoreAverageBar
                    score={Math.trunc(
                      +selectedUser[avgPuanKey].ortalama.replace(",", "."),
                    )}
                    isLonger
                  />
                </div>
              ))}
            </div>
          </EmployeeProfileCard>
        ))}
      </div>
    </>
  );
}

const ROLE_TO_BADGE_COLOR_MAPPING: { [key: string]: string } = Object.freeze({
  own: "gray",
  manager: "red",
  subordinate: "green",
  superior: "yellow",
  equal: "blue",
});

export function getBadgeColorByRole(role: string) {
  return ROLE_TO_BADGE_COLOR_MAPPING[role] ?? "gray";
}

const ROLE_TO_TURKISH_MAPPING: { [key: string]: string } = Object.freeze({
  own: "Kendi",
  manager: "Yönetici",
  subordinate: "Ast",
  superior: "Üst",
  equal: "Eşit",
});

export function getTurkishRole(role: string) {
  return ROLE_TO_TURKISH_MAPPING[role] ?? "hata";
}

export function SkillsTable({
  rows,
  own,
  manager,
  subordinate,
  superior,
  equal,
  moveAbove,
}: {
  rows: (string | number)[][];
  own?: string;
  manager?: string;
  subordinate?: string;
  superior?: string;
  equal?: string;
  moveAbove?: boolean;
}) {
  const selectedUser = useAtomValue(selectedUserAtom);

  const HEADERS = Object.freeze([
    {
      title: "Kendisi",
      color: "gray",
      name: own ?? selectedUser.ad.charAt(0) + ". " + selectedUser.soyad,
      role: "own",
    },
    { title: "Yöneticisi", color: "red", name: "Anonim", role: "manager" },
    { title: "Astı", color: "green", name: "Anonim", role: "subordinate" },
    { title: "Üstü", color: "yellow", name: "Anonim", role: "superior" },
    { title: "Eşiti", color: "blue", name: "Anonim", role: "equal" },
  ]);

  return (
    <>
      {/* table header */}
      <div
        className={classNames(
          "mb-3 ml-[175px] flex gap-x-[2px] pl-3 leading-[18px] text-gray-500",
          moveAbove ? "-mt-12" : "",
        )}
      >
        {HEADERS.map((header) => (
          <div className="flex w-[86px] flex-col gap-y-1 text-[10px] font-semibold leading-none">
            <div className="w-fit">
              <Badge color={getBadgeColorByRole(header.role)} textSize={10}>
                <span className="font-semibold">{header.title}</span>
              </Badge>
            </div>

            {header.name}
          </div>
        ))}
      </div>

      {/* table items */}
      <div className="flex flex-col gap-y-3">
        {rows.map((item, rowIndex) => {
          const numbers = item.map((n) => {
            const parsed = +String(n).replace(",", ".");
            return isNaN(parsed) ? 0 : parsed;
          });
          const nonZeroCount = numbers.filter((n) => n !== 0).length;
          const avg =
            Math.round(
              (numbers.reduce((a, b) => a + b, 0) / nonZeroCount) * 10,
            ) / 10;
          const fark = avg - numbers[1];

          return (
            <div className="flex gap-x-[2px]">
              {item.map((field, fieldIndex) => (
                <div
                  className={classNames(
                    "flex h-[38px] items-center rounded-sm text-base font-semibold leading-[18px]",
                    fieldIndex === 0
                      ? "mr-[10px] w-[175px] text-slate-600"
                      : "w-[86px] justify-center text-slate-700 " +
                          getBgColor(
                            Math.trunc(+String(field).replace(",", ".")),
                          ),
                    fieldIndex === 0 && !moveAbove ? "text-sm" : "",
                  )}
                >
                  {field === "0,0"
                    ? "⎯"
                    : field === "Computerease Construction Accounting Software"
                      ? "Computerease Construction Accounti..."
                      : field}
                </div>
              ))}

              <div className="ml-4 flex items-center gap-2 rounded bg-gray-100 px-2 py-[7px]">
                <div className="w-[41px]">
                  <span className="text-base font-bold leading-[18px] text-gray-800">
                    {avg.toFixed(1)}
                  </span>
                  <span className="text-xs font-medium leading-[18px] text-gray-400">
                    /9
                  </span>
                </div>

                <ScoreAverageBar score={Math.trunc(avg)} />

                <div className="flex w-[50px] items-center gap-x-[2px]">
                  <img
                    className="h-[18px] w-[18px]"
                    src={
                      fark < 0
                        ? ArrowDownLeft
                        : fark === 0
                          ? CheckIndeterminateSmall
                          : ArrowUpRight
                    }
                    alt="arrow"
                  />

                  <span className="text-xs font-semibold leading-[18px] text-gray-800">
                    {(fark > 0 ? "+" : "") + fark.toFixed(1)}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

const SCORE_TO_TEXT_BG_MAPPING: { [key: number]: string } = Object.freeze({
  9: "bg-green-500",
  8: "bg-green-400",
  7: "bg-emerald-300",
  6: "bg-emerald-200",
  5: "bg-emerald-100",
  4: "bg-orange-100",
  3: "bg-red-200",
  2: "bg-red-300",
  1: "bg-red-400",
});

const SCORE_TO_BAR_BG_MAPPING: { [key: number]: string } = Object.freeze({
  9: "bg-green-400",
  8: "bg-green-400",
  7: "bg-emerald-300",
  6: "bg-emerald-200",
  5: "bg-emerald-100",
  4: "bg-orange-300",
  3: "bg-red-400",
  2: "bg-red-400",
  1: "bg-red-500",
});

function getBgColor(score: number | string, isForBar = false) {
  if (typeof score === "number") {
    return isForBar
      ? SCORE_TO_BAR_BG_MAPPING[score] ?? "bg-gray-50"
      : SCORE_TO_TEXT_BG_MAPPING[score] ?? "bg-gray-50";
  }
  return "bg-gray-50";
}

function ScoreAverageBar({
  score,
  isLonger,
}: {
  score: number;
  isLonger?: boolean;
}) {
  return (
    <div className="relative h-[24px] w-[208px]">
      <div
        className={classNames(
          "absolute inset-y-0 flex gap-x-6 rounded bg-gray-200 px-6 py-[5px]",
          isLonger ? "gap-x-[30px] px-[30px]" : "gap-x-6 px-6",
        )}
      >
        {Array.from({ length: 7 }).map((_, i) => (
          <div key={i} className="h-[14px] rounded-sm border border-gray-400" />
        ))}
      </div>
      <div
        className={classNames(
          "absolute inset-y-0 flex rounded py-[5px]",
          isLonger ? "gap-x-[30px]" : "gap-x-6",
          score === 1 ? "w-[1px]" : "",
          score > 1 && !isLonger ? "pl-6" : "",
          score > 1 && isLonger ? "pl-[30px]" : "",
          score > 2 && !isLonger ? "pr-6" : "",
          score > 2 && isLonger ? "pr-[30px]" : "",
          getBgColor(score, true),
        )}
      >
        {score > 1 &&
          Array.from({ length: score - 2 }).map((_, i) => (
            <div
              key={i}
              className="h-[14px] rounded-sm border border-gray-400"
            />
          ))}
      </div>
    </div>
  );
}

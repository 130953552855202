import FormSettingsSelectLibrary from "../../components/FormSettingsSelectLibrary";
import FormSettingsHighest from "../../components/FormSettingsHighest";
import FormSettingsMiddleRankingText from "../../components/FormSettingsMiddleRankingText";
import FormSettingsLowestRankingText from "../../components/FormSettingsLowestRankingText";
import FormSettingsSmallestValue from "../../components/FormSettingsSmallestValue";
import FormSettingsHighestValue from "../../components/FormSettingsHighestValue";

export default function RankingFormOptionSettings() {
  return (
    <>
      <FormSettingsLowestRankingText />
      <FormSettingsMiddleRankingText />
      <FormSettingsHighest />
      <FormSettingsSmallestValue />
      <FormSettingsHighestValue />
      <FormSettingsSelectLibrary />
    </>
  );
}

import React, { Dispatch, SetStateAction, useState } from "react";
import {
  roleInterface,
  surveySettingInterface,
} from "../../../Interfaces/SurveyInterfaces";
import { v4 as uuidv4 } from "uuid";
import CheckboxBootstrap from "../../../components/staticDesign/elements/CheckboxBootstrap";

type Props = {
  setState360: Dispatch<SetStateAction<number>>;
  setSurveySetting: Dispatch<SetStateAction<surveySettingInterface>>;
  surveySetting: surveySettingInterface;
};

const Roles: React.FC<Props> = ({
  setState360,
  setSurveySetting,
  surveySetting,
}) => {
  const [roles, setRoles] = useState<roleInterface[]>(
    surveySetting?.Roles || [],
  );

  const addRole = () => {
    setRoles([
      ...roles,
      {
        _id: uuidv4(),
        title: "",
        minEvaluator: 1,
        maxEvaluator: 5,
        scorWeight: 0,
        isActive: false,
      },
    ]);
  };

  const stateHasChanges = () => {
    if (totalScoreWeight !== 100) {
      return true;
    }

    if (roles.length !== surveySetting?.Roles?.length) {
      return false;
    }

    for (let i = 0; i < roles.length; i++) {
      const obj1 = roles[i];
      const obj2 = surveySetting?.Roles?.[i];

      if (
        obj1._id !== obj2._id ||
        obj1.title !== obj2.title ||
        obj1.minEvaluator !== obj2.minEvaluator ||
        obj1.maxEvaluator !== obj2.maxEvaluator ||
        obj1.scorWeight !== obj2.scorWeight ||
        obj1.isActive !== obj2.isActive
      ) {
        return false;
      }
    }

    return true;
  };

  const deleteRole = (_id: string) => {
    setRoles((prevRoles) => prevRoles.filter((role) => role._id !== _id));
  };

  const updateRoleValue = (
    id: string,
    name: string,
    value: string | number | boolean,
  ) => {
    setRoles((prevRoles) =>
      prevRoles.map((role) =>
        role._id === id ? { ...role, [name]: value } : role,
      ),
    );
  };

  const handleSaveRolesChanges = () => {
    setSurveySetting((prevState: surveySettingInterface) => {
      const newState = { ...prevState };
      newState.Roles = roles;
      return newState;
    });
  };

  const totalScoreWeight = roles
    .filter((role) => role.isActive)
    .reduce((sum, role) => sum + Number(role.scorWeight), 0);

  return (
    <>
      <div className="flex w-full justify-center">
        <div className="my-4 inline-flex cursor-pointer items-center rounded-xl bg-gray-100 p-[2px] dark:text-gray-800">
          <span
            className={`w-44 rounded-xl p-2 text-center outline-none ${"bg-white text-blue-600"}`}
            onClick={() => setState360(1)}
          >
            Roller
          </span>
          <span
            className={`w-44 rounded-xl p-2 text-center ${"bg-gray-100"}`}
            onClick={() => setState360(2)}
          >
            Katılımcılar
          </span>
        </div>
      </div>
      <div className="mx-auto mt-3 flex w-[720px] justify-between">
        <h5 className="max-w-[720px] px-2 text-start outline-none">Roller</h5>
        <div className="flex h-14 w-[294px] items-center justify-center">
          <div className="text-em mr-4 w-[114px] text-xs">
            Toplam Skor Ağırlığı
          </div>
          <div className="h-2.5 mx-auto w-[116px] rounded-full bg-gray-200">
            <div
              className={`${
                totalScoreWeight === 100 ? "bg-green-500" : "bg-red-500"
              } h-2.5 max-w-full rounded-full`}
              style={{ width: `${totalScoreWeight}%` }}
            ></div>
          </div>
          <div className="ml-2 w-[30px]">{totalScoreWeight}%</div>
        </div>
      </div>
      <div className="mx-auto w-[720px] rounded-xl border bg-gray-100">
        {roles.length === 0 ? (
          <div className="flex p-2">
            <div className="ml-2 flex w-[257px] items-center justify-start">
              <CheckboxBootstrap
                onChange={(e: any) =>
                  setRoles((prevRoles) => {
                    const newRoles = [...prevRoles];
                    newRoles.forEach(
                      (role) =>
                        (role.isActive = e.target.checked ? true : false),
                    );
                    return newRoles;
                  })
                }
                style={{ transform: "scale(1.2)", marginRight: "0.5em" }}
                id="RoleAllCheckerDefault"
                name="RoleAllCheckerDefault"
              />
              <p className="mb-0 mr-1 text-sm">Rol</p>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.9987 3.3335V12.6668M7.9987 12.6668L12.6654 8.00016M7.9987 12.6668L3.33203 8.00016"
                  stroke="#475467"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="w-[110px] text-sm">Min. Değerlen.</div>
            <div className="w-[170px] text-sm">Max. Değerlend.</div>
            <div className="w-[183px] text-sm"> Skor Ağırlığı</div>
          </div>
        ) : (
          <div className="flex p-2">
            <div className="ml-2 flex w-[320px] items-center justify-start">
              <CheckboxBootstrap
                onChange={(e: any) =>
                  setRoles((prevRoles) => {
                    const newRoles = [...prevRoles];
                    newRoles.forEach(
                      (role) =>
                        (role.isActive = e.target.checked ? true : false),
                    );
                    return newRoles;
                  })
                }
                style={{ transform: "scale(1.2)", marginRight: "0.5em" }}
                id="RoleAllChecker"
                name="RoleAllChecker"
              />
              <p className="mb-0 mr-1 text-sm">Rol</p>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.9987 3.3335V12.6668M7.9987 12.6668L12.6654 8.00016M7.9987 12.6668L3.33203 8.00016"
                  stroke="#475467"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="w-[110px] text-sm">Min. Değerlen.</div>
            <div className="w-[110px] text-sm">Max. Değerlend.</div>
            <div className="w-[110px] text-sm"> Skor Ağırlığı</div>
            <div className="w-[70px] text-sm"></div>
          </div>
        )}
        {roles.map((role, index) => (
          <div className="flex items-center justify-center" key={role._id}>
            <div className="flex h-[72px] w-[320px] items-center justify-start pl-4">
              <CheckboxBootstrap
                onChange={(e) =>
                  updateRoleValue(role._id, "isActive", e.target.checked)
                }
                checked={role.isActive}
                style={{ transform: "scale(1.2)", marginRight: "0.5em" }}
                id="RoleChecker"
                name="RoleChecker"
              />
              <input
                type="text"
                disabled={!role.isActive}
                className="h-[40px] w-[240px] rounded border px-2"
                value={role.title}
                placeholder="Adsız Rol"
                onChange={(e) =>
                  updateRoleValue(role._id, "title", e.target.value)
                }
              />
            </div>

            <div className="w-[110px]">
              <input
                type="number"
                name="min"
                disabled={!role.isActive}
                value={role.minEvaluator}
                placeholder="1"
                onChange={(e) =>
                  updateRoleValue(role._id, "minEvaluator", e.target.value)
                }
                className="h-[40px] w-[40px] rounded border border-gray-400 text-center outline-none"
              />
            </div>
            <div className="w-[110px]">
              <input
                type="number"
                name="max"
                disabled={!role.isActive}
                value={role.maxEvaluator}
                placeholder="5"
                onChange={(e) =>
                  updateRoleValue(role._id, "maxEvaluator", e.target.value)
                }
                className="h-[40px] w-[40px] rounded border border-gray-400 text-center outline-none"
              />
            </div>
            <div className="w-[110px]">
              <input
                type="number"
                name="scorWeight"
                disabled={!role.isActive}
                value={role.scorWeight}
                placeholder="0"
                onChange={(e) =>
                  updateRoleValue(role._id, "scorWeight", e.target.value)
                }
                className="h-[40px] w-[40px] rounded border border-gray-400 text-center outline-none"
              />
            </div>
            <div className="flex w-[70px] cursor-pointer justify-center">
              <span>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => deleteRole(role._id)}
                >
                  <path
                    d="M13.3333 4.99984V4.33317C13.3333 3.39975 13.3333 2.93304 13.1517 2.57652C12.9919 2.26292 12.7369 2.00795 12.4233 1.84816C12.0668 1.6665 11.6001 1.6665 10.6667 1.6665H9.33333C8.39991 1.6665 7.9332 1.6665 7.57668 1.84816C7.26308 2.00795 7.00811 2.26292 6.84832 2.57652C6.66667 2.93304 6.66667 3.39975 6.66667 4.33317V4.99984M8.33333 9.58317V13.7498M11.6667 9.58317V13.7498M2.5 4.99984H17.5M15.8333 4.99984V14.3332C15.8333 15.7333 15.8333 16.4334 15.5608 16.9681C15.3212 17.4386 14.9387 17.821 14.4683 18.0607C13.9335 18.3332 13.2335 18.3332 11.8333 18.3332H8.16667C6.76654 18.3332 6.06647 18.3332 5.53169 18.0607C5.06129 17.821 4.67883 17.4386 4.43915 16.9681C4.16667 16.4334 4.16667 15.7333 4.16667 14.3332V4.99984"
                    stroke="#475467"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
          </div>
        ))}

        <div className="flex items-center justify-between rounded-b-xl bg-white p-3">
          <button
            className="box-button-svg rounded-md border-2 border-blue-400 px-3 py-2 text-center align-middle text-sm hover:bg-blue-300 hover:text-white"
            onClick={() => addRole()}
          >
            <span className="flex items-center justify-center">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
                className="icon-svg"
              >
                <path
                  d="M10.0013 4.1665V15.8332M4.16797 9.99984H15.8346"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Yeni Rol Ekle
            </span>
          </button>
          <div>
            <button
              className="mr-2 w-[83px] cursor-pointer rounded-md border-2 border-gray-300 px-3 py-2 text-center align-middle text-sm"
              onClick={() => setRoles(surveySetting?.Roles || [])}
            >
              Vazgeç
            </button>
            <button
              disabled={stateHasChanges()}
              onClick={() => handleSaveRolesChanges()}
              className="w-[170px] rounded-lg bg-[#2970ff] px-3 py-2 text-center align-middle text-sm text-white hover:bg-blue-700"
            >
              Değişiklikleri Kaydet
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Roles;

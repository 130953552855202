import Select from "react-select";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAtom, useAtomValue } from "jotai";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../atoms/surveyAtoms";

const FormSettingsDefaultChoice = () => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const [localState, setLocalState] = useState({
    defaultChoice:
      stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
        ?.defaultChoice,
  });
  const [items, setItems] = useState({
    choices:
      stateSurveyJSON.pages?.[pageIndex!]?.elements?.[elementIndex!]?.choices,
  });

  const { t } = useTranslation();

  useEffect(() => {
    const initialChoices =
      stateSurveyJSON.pages?.[pageIndex!]?.elements?.[elementIndex!]?.choices;

    setItems({ choices: initialChoices });
  }, [stateSurveyJSON.pages?.[pageIndex!]?.elements?.[elementIndex!]]);

  useEffect(() => {
    setStateSurveyJSON((prevState) => {
      let newState = { ...prevState };

      if (newState.pages && newState.pages[pageIndex]) {
        const elements = newState.pages[pageIndex].elements;
        if (elements && elements[elementIndex]) {
          elements[elementIndex].defaultChoice = localState.defaultChoice;
        }
      }

      return newState;
    });
  }, [localState]);

  useEffect(() => {
    setItems(() => {
      let localStateObject = {
        choices:
          stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]?.choices,
      };
      return localStateObject;
    });
  }, [stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]?.choices]);

  const handleDefaultChoiceChange = (event: any) => {
    const newValue = event;
    setLocalState((prevState) => {
      let newState = { ...prevState };
      newState.defaultChoice = newValue?.value ? newValue.value : undefined;
      return newState;
    });
  };
  return (
    <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
      <label
        className="block text-sm font-bold text-gray-500"
        htmlFor="enterQuestion"
      >
        {t("defaultChoice")}
      </label>
      <Select
        className="basic-single mt-3"
        placeholder={t("singleSelection")}
        isClearable={true}
        isSearchable={false}
        onChange={(e) => handleDefaultChoiceChange(e)}
        options={items.choices?.map((choice) => ({
          value: choice._id,
          label: choice.title?.tr ? choice.title!.tr : choice._id,
        }))}
        defaultValue={
          localState.defaultChoice
            ? {
                value: localState.defaultChoice,
                label: (
                  items.choices?.find(
                    (item) => item.content === localState.defaultChoice,
                  ) || {}
                ).title?.tr,
              }
            : undefined
        }
      />
      <p className="mb-0 mt-3 text-sm text-stone-500">
        {t("defaultChoiceDescription")}
      </p>
    </div>
  );
};

export default FormSettingsDefaultChoice;

import FormSettingsRowWidth from "../../components/FormSettingsRowWidth";
import FormSettingsColumnWidth from "../../components/FormSettingsColumnWidth";
import FormSettingsNoIdea from "../../components/FormSettingsNoIdea";
import FormSettingsShuffle from "../../components/FormSettingsShuffle";
import FormSettingsOptionsForDT from "../../components/FormSettingsOptionsForDT";

export default function DataTableOptionSettings() {
  return (
    <>
      <FormSettingsRowWidth />
      <FormSettingsColumnWidth />
      <FormSettingsOptionsForDT />
      <FormSettingsShuffle title="Satırları Karıştır" />
      <FormSettingsNoIdea />
    </>
  );
}

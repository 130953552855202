import { ProgressCircle } from "@tremor/react";
import { FilterOption, Person, SelectedOption } from "Interfaces/general";
import { selectedUserAtom } from "components/atoms/dashboardAtoms";
import PrimaryButton from "components/tailwindUi/PrimaryButton";
import talentData from "data/talentData.json";
import { useSetAtom } from "jotai";
import { classNames } from "lib/externalJS";
import { getObjectKeyFromDisplayText } from "pages/dashboard2/EmployeeProfileSkillsTech";
import { getStartedDate, groupBy } from "pages/dashboard2/Employees/Workers";
import { useEffect, useMemo, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import Filter from "./Filter";
import Pagination from "./Pagination";
import SelectFeature from "./SelectFeature";

const TalentManager = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const people = useMemo(() => talentData as any[], [talentData]);

  const setSelectedUser = useSetAtom(selectedUserAtom);

  const [selectedOptions, setSelectedOptions] = useState<SelectedOption[]>([]);
  const [filteredPeople, setFilteredPeople] = useState<Person[]>(people);

  useEffect(() => {
    const calculateScore = (person: Person) => {
      if (selectedOptions.length === 0) return 0;

      let scoreSum = 0;
      let multipleSum = 0;

      selectedOptions.forEach((option) => {
        const { value, min, max, multiple } = option;

        const possibleKeys = getObjectKeyFromDisplayText(value);

        const keyToUse =
          typeof possibleKeys === "string"
            ? possibleKeys
            : possibleKeys.find((key) => person[key] !== undefined);

        if (!keyToUse) {
          throw new Error(`Key not found: ${keyToUse} -- ${value}`);
        }

        if (person[keyToUse] === undefined) {
          throw new Error(`value not found with the key: ${keyToUse}`);
        }

        if (!min || !max || !multiple) {
          throw new Error(
            `min, max or multiple not found: ${min} ${max} ${multiple}`,
          );
        }

        const personAttributeValue = +person[keyToUse].yuzde;

        if (personAttributeValue >= min && personAttributeValue <= max) {
          scoreSum += 100 * multiple;
          multipleSum += multiple;
          return;
        }

        const limitToUse = personAttributeValue < min ? min : max;
        const differenceWithLimit = Math.abs(personAttributeValue - limitToUse);

        if (differenceWithLimit > limitToUse) {
          scoreSum += 0;
          multipleSum += multiple;
          return;
        }

        const scoreToAdd = (1 - differenceWithLimit / limitToUse) * 100;

        scoreSum += scoreToAdd * multiple;
        multipleSum += multiple;
      });

      return Math.round(scoreSum / multipleSum);
    };

    const updatedPeople = people.map((person) => ({
      ...person,
      score: calculateScore(person),
    }));

    setFilteredPeople(updatedPeople);
  }, [selectedOptions]);

  const handleFilterChange = (filters: FilterOption[]) => {
    if (filters.length === 0) {
      setFilteredPeople(people);
      return;
    }

    const filtersGrouped = groupBy(filters, "id");

    const updatedPeople = people.filter((person: any) => {
      return Object.keys(filtersGrouped).every((key) => {
        return filtersGrouped[key].some((filter: any) => {
          switch (filter.id) {
            case "departman":
              return person.departman === filter.value;
            case "pozisyon":
              return person.pozisyon === filter.value;
            case "cinsiyet":
              return person.cinsiyet === filter.value;
            case "yas":
              const splittedYaslar = filter.value.split("-");
              const personYas = parseInt(person.yas);
              return (
                personYas >= parseInt(splittedYaslar[0]) &&
                personYas <= parseInt(splittedYaslar[1])
              );
            default:
              return false;
          }
        });
      });
    });

    setFilteredPeople(updatedPeople);
  };

  const removeSelectedOption = (indexToRemove: number) => {
    setSelectedOptions((prevSelectedOptions) => {
      const newSelectedOptions = prevSelectedOptions.filter(
        (_, index) => index !== indexToRemove,
      );

      return newSelectedOptions;
    });
  };

  return (
    <div className="w-full max-w-[1000px]">
      <div>
        <div className="sm:flex-auto">
          <h1 className="mb-[30px] flex items-center text-base font-semibold leading-6 text-gray-900">
            <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="36" height="36" rx="4" fill="#FFE6D5" />
              <path
                d="M28 18H26M25.071 25.0711L23.6567 23.6569M10 18H8M12.3429 12.3432L10.9287 10.929M18 10V8M23.6567 12.3432L25.071 10.929M18 28V26M10.9287 25.0711L12.3429 23.6569M18 13L19.545 16.13L23 16.635L20.5 19.07L21.09 22.51L18 20.885L14.91 22.51L15.5 19.07L13 16.635L16.455 16.13L18 13Z"
                stroke="#FF692E"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <span className="ml-3 text-xl font-medium">Yetenek Yönetimi</span>
          </h1>

          <p className="text-lg font-semibold text-slate-600">Özellikler</p>
        </div>

        <div className="mt-4 flex flex-wrap items-center">
          <div className="mb-3 w-[82px]">
            <SelectFeature
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
              trigger={<PrimaryButton>Seç</PrimaryButton>}
            />
          </div>

          {selectedOptions.length > 0 && (
            <>
              <div className="mx-4 mb-3 h-7 border-x border-gray-200"></div>

              {selectedOptions.map((option, index) => (
                <span
                  key={option.value}
                  className="mb-3 mr-2 flex h-9 w-fit items-center rounded-md bg-[#EFF4FF] px-2 py-1 text-sm font-semibold leading-[18px] text-slate-700 ring-1 ring-inset ring-[#D1E0FF]"
                >
                  {`${option.label} - (${option.min}-${option.max})`}
                  <span className="ml-1 text-gray-400">
                    x{option.multiple ?? 1}
                  </span>
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="ml-1 cursor-pointer"
                    onClick={() => removeSelectedOption(index)}
                  >
                    <path
                      d="M12.75 5.25L5.25 12.75M5.25 5.25L12.75 12.75"
                      stroke="#84ADFF"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              ))}
            </>
          )}
        </div>
      </div>
      <Filter
        people={filteredPeople}
        originalPeople={people}
        onFilterChange={handleFilterChange}
      />
      <div className="-mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full pb-[27px] pt-5 align-middle sm:px-6 lg:px-8">
          <table className="min-w-full">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="px-5 text-left text-sm font-semibold text-gray-900"
                >
                  <div className="pb-1 text-xs font-medium leading-[18px] text-gray-500">
                    Çalışanlar
                  </div>
                </th>
                <th
                  scope="col"
                  className="px-5 text-left text-sm font-semibold text-gray-900"
                >
                  <div className="pb-1 text-xs font-medium leading-[18px] text-gray-500">
                    Departman
                  </div>
                </th>
                <th
                  scope="col"
                  className="px-5 text-center text-sm font-semibold text-gray-900"
                >
                  <div className="pb-1 text-xs font-medium leading-[18px] text-gray-500">
                    Başlangıç Tarihi
                  </div>
                </th>
                <th
                  scope="col"
                  className="px-5 text-center text-sm font-semibold text-gray-900"
                >
                  <div className="pb-1 text-xs font-medium leading-[18px] text-gray-500">
                    Uyum Derecesi
                  </div>
                </th>
                <th
                  scope="col"
                  className="px-5 text-center text-sm font-semibold text-gray-900"
                >
                  <div className="pb-1 text-xs font-medium leading-[18px] text-gray-500">
                    İncele
                  </div>
                </th>
              </tr>
            </thead>

            <tbody>
              {filteredPeople.map((p, index) => {
                const TdChildWrapper = ({
                  children,
                }: {
                  children: React.ReactNode;
                }) => (
                  <div
                    className={
                      "bg-gray-50 " + (index !== 0 ? "py-[5px]" : "pb-[5px]")
                    }
                  >
                    {children}
                  </div>
                );

                const personUyumScore = getUyumScore(p.id);

                return (
                  <tr key={p.id} className="">
                    <td className="whitespace-nowrap p-0 text-sm">
                      <TdChildWrapper>
                        <div className="flex items-center rounded-bl-lg rounded-tl-lg border border-r-0 border-gray-200 bg-white px-5 py-[14px]">
                          <div className="h-11 w-11 flex-shrink-0">
                            <img
                              className="h-11 w-11 rounded-full"
                              src={"/avatars/" + p.id + ".png"}
                              alt=""
                              onError={(e) => {
                                (e.target as any).src = "/avatars/default.png";
                              }}
                            />
                          </div>

                          <div className="ml-4">
                            <div className="text-base font-medium text-gray-900">
                              {p.ad + " " + p.soyad}
                            </div>
                            <div className="mt-1 text-gray-500">
                              {p.pozisyon}
                              {/* {p.puanBeceriler_okudugunuAnlama.yuzde +
                                " - " +
                                p.puanCalismatarzi_i̇nisiyatifAlma.yuzde +
                                " - " +
                                p.puanBilgi_yabanciDilBilgisi.yuzde} */}
                            </div>
                          </div>
                        </div>
                      </TdChildWrapper>
                    </td>

                    <td className="whitespace-nowrap p-0 text-sm text-gray-500">
                      <TdChildWrapper>
                        <div className="flex flex-col border border-gray-200 bg-white px-5 py-[14px]">
                          <div className="text-base font-medium text-gray-900">
                            {p.departman}
                          </div>
                          <div
                            className={classNames(
                              "mt-1 text-gray-500",
                              p.birim === undefined ? "opacity-0" : "",
                            )}
                          >
                            {p.birim ?? "-"}
                          </div>
                        </div>
                      </TdChildWrapper>
                    </td>

                    <td className="whitespace-nowrap p-0 text-center text-sm text-gray-500">
                      <TdChildWrapper>
                        <div className="flex flex-col border border-l-0 border-r-0 border-gray-200 bg-white px-5 py-[28px]">
                          <span className="text-base font-medium leading-tight text-gray-900">
                            {getStartedDate(p.id)}
                          </span>
                        </div>
                      </TdChildWrapper>
                    </td>

                    <td className="whitespace-nowrap p-0 text-center text-sm text-gray-500">
                      <TdChildWrapper>
                        <div className="flex flex-col border border-gray-200 bg-white px-5 py-[13px]">
                          <ProgressCircle
                            value={p.score}
                            color={"green"}
                            radius={25}
                            strokeWidth={5}
                          >
                            <div>
                              <span className="h-fit text-[9px] font-bold leading-[9px] text-gray-800">
                                %
                              </span>
                              <span className="h-fit text-lg font-bold leading-[18px] text-gray-800">
                                {p.score}
                              </span>
                            </div>
                          </ProgressCircle>
                        </div>
                      </TdChildWrapper>
                    </td>

                    <td className="p-0">
                      <TdChildWrapper>
                        <div className="flex flex-col items-center rounded-br-lg rounded-tr-lg border border-l-0 border-gray-200 bg-white px-5 py-[28px]">
                          <button
                            onClick={() => setSelectedUser(p)}
                            className="w-fit"
                          >
                            <svg
                              width="26"
                              height="20"
                              viewBox="0 0 26 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.82348 10.832C1.66459 10.5805 1.58515 10.4547 1.54068 10.2607C1.50727 10.1149 1.50727 9.88508 1.54068 9.73935C1.58515 9.54533 1.66459 9.41954 1.82348 9.16796C3.13645 7.08898 7.04463 1.83333 13.0005 1.83333C18.9563 1.83333 22.8645 7.08898 24.1775 9.16796C24.3364 9.41954 24.4158 9.54533 24.4603 9.73935C24.4937 9.88508 24.4937 10.1149 24.4603 10.2607C24.4158 10.4547 24.3364 10.5805 24.1775 10.832C22.8645 12.911 18.9563 18.1667 13.0005 18.1667C7.04463 18.1667 3.13645 12.911 1.82348 10.832Z"
                                stroke="#2970FF"
                                strokeWidth="2.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M13.0005 13.5C14.9335 13.5 16.5005 11.933 16.5005 10C16.5005 8.067 14.9335 6.5 13.0005 6.5C11.0675 6.5 9.50047 8.067 9.50047 10C9.50047 11.933 11.0675 13.5 13.0005 13.5Z"
                                stroke="#2970FF"
                                strokeWidth="2.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                        </div>
                      </TdChildWrapper>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <hr />
      <div className="mt-6 flex items-center justify-center">
        <Pagination peopleNumber={3} />
      </div>
    </div>
  );
};

export default TalentManager;

function getUyumScore(id: any) {
  return 12;
}

import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAtom, useAtomValue } from "jotai";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../atoms/surveyAtoms";

const FormSettingsBottomTicket = () => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const [bottomTicketValue, setBottomTicketValue] = useState<string>(
    stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]?.bottomTicket
      ?.tr || "",
  );

  const { t } = useTranslation();

  useEffect(() => {
    // Update local state when external value changes
    setBottomTicketValue(
      stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]?.bottomTicket
        ?.tr || "",
    );
  }, [stateSurveyJSON]);

  const handleBottomTicketChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setBottomTicketValue(newValue);

    setStateSurveyJSON((prevState) => {
      const newState = { ...prevState };
      newState.pages![pageIndex].elements![elementIndex].bottomTicket = {
        tr: newValue,
      };
      return newState;
    });
  };

  return (
    <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
      <label
        className="mb-2 block text-sm font-bold text-gray-500"
        htmlFor="enterTitle"
      >
        {t("bottomTicket")}
      </label>
      <input
        id="enterTitle"
        className="focus:shadow-outline w-full appearance-none rounded-lg border border-gray-300 px-3 py-2 leading-tight text-gray-500 focus:outline-none"
        type="text"
        placeholder={t("Alt Etiket Ekle")}
        value={bottomTicketValue}
        onChange={(e) => handleBottomTicketChange(e)}
        autoComplete="off"
      />

      <p className="mb-0 mt-3 text-sm text-stone-500">
        {t("bottomTicketDescription")}
      </p>
    </div>
  );
};

export default FormSettingsBottomTicket;

import { useState } from "react";
import { useAtomValue, useSetAtom } from "jotai";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../../atoms/surveyAtoms";

const DateType = () => {
  const setStateSurveyJSON = useSetAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const [specialValue, setSpecialValue] = useState<number>(1);

  const handleButtonClick = (buttonName: any) => {
    setStateSurveyJSON((prevState) => {
      let newState = { ...prevState };
      newState.pages![pageIndex].elements![elementIndex].dateFormat =
        buttonName;
      return newState;
    });
  };
  return (
    <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
      <label className="mb-2 block text-sm font-bold text-gray-500">
        Tarih Biçimi
      </label>
      <span className="isolate mt-[12px] inline-flex rounded-md shadow-sm">
        <button
          type="button"
          onClick={() => {
            setSpecialValue(1);
            handleButtonClick("MM/dd/yyyy");
          }}
          className={`relative inline-flex items-center rounded-l-md ${
            specialValue === 1
              ? "bg-[#D1E0FF] font-semibold text-gray-900"
              : "button-group-ticket-position bg-white font-medium text-gray-500 hover:bg-gray-300"
          } px-1 py-2 text-[11px] ring-1 ring-inset ring-gray-300 focus:z-10`}
        >
          AA-GG-YYYY
        </button>
        <button
          type="button"
          onClick={() => {
            setSpecialValue(2);
            handleButtonClick("dd/MM/yyyy");
          }}
          className={`relative inline-flex items-center px-1 py-2 text-[11px] ${
            specialValue === 2
              ? "bg-[#D1E0FF] font-semibold text-gray-900"
              : "button-group-ticket-position bg-white font-medium text-gray-500 hover:bg-gray-300"
          } ring-1 ring-inset ring-gray-300 focus:z-10`}
        >
          GG-AA-YYYY
        </button>
        <button
          type="button"
          onClick={() => {
            handleButtonClick("yyyy/MM/dd");
            setSpecialValue(3);
          }}
          className={`-ml-px relative inline-flex items-center rounded-r-md ${
            specialValue === 3
              ? "bg-[#D1E0FF] font-semibold text-gray-900"
              : "button-group-ticket-position bg-white font-medium text-gray-500"
          } px-1 py-2 text-[11px] ring-1 ring-inset ring-gray-300 focus:z-10`}
        >
          YYYY-AA-GG
        </button>
      </span>
      <p className="mb-0 mt-3 text-sm text-stone-500">
        Kullanılacak tarih biçimini seçin. A ayı, G günü, Y yılı ifade eder.
      </p>
    </div>
  );
};

export default DateType;

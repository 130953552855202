import FormSettingsSelectLibrary from "../../components/FormSettingsSelectLibrary";
import FormSettingsHighest from "../../components/FormSettingsHighest";
import FormSettingsLowestRankingText from "../../components/FormSettingsLowestRankingText";
import CESScoring from "../../components/SpecialRankings/CESScoring";
import CESCalculation from "../../components/SpecialRankings/CESCalculation";

export default function CesFormOptionSettings() {
  return (
    <>
      <FormSettingsLowestRankingText />
      <FormSettingsHighest />
      <CESScoring />
      <CESCalculation />
      <FormSettingsSelectLibrary />
    </>
  );
}

import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import FilterListCategoryItem from "pages/dashboard2/Templates/FilterListCategoryItem";
import { Category } from "pages/dashboard2/Templates/data";

export default function FilterList({
  filters,
}: {
  filters: {
    title: string;
    items: Category[];
  }[];
}) {
  return (
    <>
      {filters.map((filter) => (
        <Disclosure
          as="div"
          key={filter.title}
          className="border-b border-gray-200 bg-white py-6"
          defaultOpen={true}
        >
          {({ open }) => (
            <fieldset>
              <legend className="w-full">
                <h3 className="-my-3 flow-root">
                  <Disclosure.Button className="flex w-full items-center justify-between py-3 text-sm text-gray-400 hover:text-gray-500">
                    <span className="font-medium text-gray-900">
                      {filter.title}
                    </span>
                    <span className="ml-6 flex items-center">
                      {open ? (
                        <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                      ) : (
                        <ChevronDownIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      )}
                    </span>
                  </Disclosure.Button>
                </h3>
              </legend>
              <Disclosure.Panel className="pt-6">
                <div className="space-y-4">
                  <FilterListCategoryItem filterItems={filter.items} />
                </div>
              </Disclosure.Panel>
            </fieldset>
          )}
        </Disclosure>
      ))}
    </>
  );
}

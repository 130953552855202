import { useAtom, useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../../atoms/surveyAtoms";

const CESCalculation = () => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const [localState, setLocalState] = useState(
    stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
      ?.CesScoreCalculation,
  );

  const { t } = useTranslation();

  useEffect(() => {
    setStateSurveyJSON((prevState) => {
      let newState = { ...prevState };
      newState.pages![pageIndex]!.elements![elementIndex]!.CesScoreCalculation =
        localState;
      return newState;
    });
  }, [localState]);

  useEffect(() => {
    setLocalState(() => {
      return stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
        ?.CesScoreCalculation;
    });
  }, [
    stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
      ?.CesScoreCalculation,
  ]);

  const handleCESChange = (e: number) => {
    setLocalState((prevState) => {
      let newState = prevState;
      newState = e;
      return newState;
    });
  };

  return (
    <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
      <label className="block text-sm font-bold text-gray-500">
        CES Hesaplama Yöntemi
      </label>
      <div className="mt-3 flex items-center">
        <input
          id="CESFirstOption"
          type="radio"
          style={{ transform: "scale(1.2)" }}
          checked={localState === 1 ? true : false}
          onChange={(e) => handleCESChange(1)}
          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
        />
        <label
          htmlFor="CESFirstOption"
          className="ml-3 block text-sm font-medium leading-6 text-gray-900"
        >
          CES = %Olumlu Sonuçlar - %Olumsuz Sonuçlar
        </label>
      </div>
      <div className="mt-3 flex items-center">
        <input
          id="CESSecondOption"
          type="radio"
          style={{ transform: "scale(1.2)" }}
          checked={localState === 2 ? true : false}
          onChange={(e) => handleCESChange(2)}
          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
        />
        <label
          htmlFor="CESSecondOption"
          className="ml-3 block text-sm font-medium leading-6 text-gray-900"
        >
          CES = Tüm Katılımcıların Çaba Skoru / Toplam Katılımcılar
        </label>
      </div>
      <p className="mb-0 mt-3 text-sm text-stone-500">
        {t("shuffleOptionsDescription")}
      </p>
    </div>
  );
};

export default CESCalculation;

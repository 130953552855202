import EmployeeProfileCard from "pages/dashboard2/EmployeeProfileCard";
import GaugeWithPointer from "pages/dashboard2/Panel/GaugeWithPointer";
import CommonTable from "pages/dashboard2/Performance/CommonTable";

export default function ESAT() {
  return (
    <>
      <EmployeeProfileCard
        titleEmoji="💯"
        title="E-SAT"
        bgClassName="bg-red-100"
      >
        <div className="-mt-8 flex items-center justify-center gap-x-25">
          <div>
            <GaugeWithPointer
              value={30}
              height={248}
              width={248}
              isFromZero
              isNoPointer
            />
          </div>
        </div>
      </EmployeeProfileCard>

      <CommonTable forTable="esat" />
    </>
  );
}

import { user } from "../../Interfaces/SurveyInterfaces";
import axiosInstance from "../api";

export const register = async (data: user) => {
  return await axiosInstance.post("/User/Add", data);
};

export const getUser = async (id: string) => {
  return (
    await axiosInstance.get<any>(`User/GetById/`, {
      params: { userId: id },
    })
  ).data;
};

export const signIn = async (
  email: string,
  password: string,
): Promise<user> => {
  try {
    return await axiosInstance.post(
      "https://app.talentcooker.com/api/login",
      {
        email,
        password,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  } catch (error: any) {
    throw new Error(`Failed on sign in request: ${error.message}`);
  }
};

import { Option, SelectedOption } from "Interfaces/general";
import PrimaryButton from "components/tailwindUi/PrimaryButton";
import SelectFilters from "components/tailwindUi/SelectFilters";
import { generalSkills } from "pages/dashboard2/EmployeeProfileSkillsGeneral";
import { knowledgeSkills } from "pages/dashboard2/EmployeeProfileSkillsKnowledge";
import { talentSkills } from "pages/dashboard2/EmployeeProfileSkillsSkills";
import { workingStyleSkills } from "pages/dashboard2/EmployeeProfileSkillsWorkingStyle";
import {
  ChangeEvent,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { MultiValue } from "react-select";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./dialog";

interface Props {
  selectedOptions: SelectedOption[];
  setSelectedOptions: Dispatch<SetStateAction<SelectedOption[]>>;
  trigger?: ReactNode;
}

const SelectFeature = ({
  selectedOptions,
  setSelectedOptions,
  trigger,
}: Props) => {
  const [open, setOpen] = useState(false);

  const [localOptins, setLocalOptins] =
    useState<SelectedOption[]>(selectedOptions);

  const [generalItems, setGeneralItems] = useState(generalSkills);
  const [workingStyleItems, setWorkingStyleItems] =
    useState(workingStyleSkills);
  const [knowledgeItems, setKnowledgeItems] = useState(knowledgeSkills);
  const [talentItems, setTalentItems] = useState(talentSkills);

  useEffect(() => {
    setGeneralItems(
      generalSkills.map((skill) => ({
        ...skill,
        selected: localOptins.some(
          (option) =>
            option.value === skill.value && option.category === "general",
        ),
      })),
    );

    setWorkingStyleItems(
      workingStyleSkills.map((skill) => ({
        ...skill,
        selected: localOptins.some(
          (option) =>
            option.value === skill.value && option.category === "style",
        ),
      })),
    );

    setKnowledgeItems(
      knowledgeSkills.map((skill) => ({
        ...skill,
        selected: localOptins.some(
          (option) =>
            option.value === skill.value && option.category === "infos",
        ),
      })),
    );

    setTalentItems(
      talentSkills.map((skill) => ({
        ...skill,
        selected: localOptins.some(
          (option) =>
            option.value === skill.value && option.category === "features",
        ),
      })),
    );

    // generalSkills.forEach((skill: any) => {
    //   skill.selected = localOptins.some(
    //     (option) =>
    //       option.value === skill.value && option.category === "general",
    //   );
    // });

    // workingStyleSkills.forEach((skill: any) => {
    //   skill.selected = localOptins.some(
    //     (option) => option.value === skill.value && option.category === "style",
    //   );
    // });

    // knowledgeSkills.forEach((skill: any) => {
    //   skill.selected = localOptins.some(
    //     (option) => option.value === skill.value && option.category === "infos",
    //   );
    // });

    // talentSkills.forEach((skill: any) => {
    //   skill.selected = localOptins.some(
    //     (option) =>
    //       option.value === skill.value && option.category === "features",
    //   );
    // });
  }, [localOptins]);

  useEffect(() => {
    setLocalOptins(selectedOptions);
  }, [selectedOptions]);

  const onSelectChange = (
    selectedOptions: MultiValue<Option>,
    category: string,
  ) => {
    setLocalOptins((prevSelectedOptions) => {
      if (!selectedOptions) {
        return prevSelectedOptions.filter(
          (option) => option.category !== category,
        );
      }
      const optionsMap = new Map<string, SelectedOption>();
      prevSelectedOptions.forEach((option) => {
        if (option.category !== category) {
          optionsMap.set(option.value, option);
        }
      });
      selectedOptions.forEach((option) => {
        optionsMap.set(option.value, {
          ...option,
          min: undefined,
          max: undefined,
          multiple: undefined,
          category,
        });
      });
      return Array.from(optionsMap.values());
    });
  };

  const updateOption = (
    event: ChangeEvent<HTMLInputElement>,
    index: number,
    field: string,
  ) => {
    const value = event.target.value;
    const valueNumber = value !== "" ? Number(value) : undefined;

    setLocalOptins((prevSelectedOptions) =>
      prevSelectedOptions.map((option, i) =>
        i === index ? { ...option, [field]: valueNumber } : option,
      ),
    );
  };

  const removeSelectedOption = (indexToRemove: number) => {
    setLocalOptins((prevSelectedOptions) => {
      const newSelectedOptions = prevSelectedOptions.filter(
        (_, index) => index !== indexToRemove,
      );

      return newSelectedOptions;
    });
  };

  const renderSelectedOptions = (options: SelectedOption[]) => {
    return (
      <>
        {[...options].map((option, index) => (
          <div key={index} className="flex h-fit flex-col items-end">
            {(index === 0 || index === 1) && (
              <div className="mr-9 flex gap-x-2 text-center text-xs font-normal leading-normal text-slate-600">
                <div className="w-[64px]">
                  <span>En Düşük</span>
                </div>
                <div className="w-[59px]">
                  <span>En Yüksek</span>
                </div>
                <div className="w-[54px]">
                  <span>Katsayı</span>
                </div>
              </div>
            )}

            <div className="flex w-full items-center justify-end gap-1">
              <span className="w-[27px]">{index + 1}.</span>

              <div className="flex w-[480px] items-center justify-between rounded-lg border border-gray-200 bg-gray-50 px-3 py-2">
                <span>{option.label}</span>
                <div className="flex items-center">
                  <input
                    type="number"
                    value={option.min}
                    onChange={(e) => updateOption(e, index, "min")}
                    placeholder="0"
                    className="w-12 rounded border-gray-300 p-0 py-1 text-center placeholder:text-gray-300"
                  />
                  <input
                    type="number"
                    value={option.max}
                    onChange={(e) => updateOption(e, index, "max")}
                    placeholder="0"
                    className="mx-5 w-12 rounded border-gray-300 p-0 py-1 text-center placeholder:text-gray-300"
                  />
                  <input
                    type="number"
                    value={option.multiple}
                    onChange={(e) => updateOption(e, index, "multiple")}
                    placeholder="1.2"
                    className="w-12 rounded border-gray-300 p-0 py-1 text-center placeholder:text-gray-300"
                  />
                </div>
              </div>

              <button onClick={() => removeSelectedOption(index)}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.3333 5.00001V4.33334C13.3333 3.39992 13.3333 2.93321 13.1517 2.57669C12.9919 2.26308 12.7369 2.00812 12.4233 1.84833C12.0668 1.66667 11.6001 1.66667 10.6667 1.66667H9.33333C8.39991 1.66667 7.9332 1.66667 7.57668 1.84833C7.26308 2.00812 7.00811 2.26308 6.84832 2.57669C6.66667 2.93321 6.66667 3.39992 6.66667 4.33334V5.00001M8.33333 9.58334V13.75M11.6667 9.58334V13.75M2.5 5.00001H17.5M15.8333 5.00001V14.3333C15.8333 15.7335 15.8333 16.4335 15.5608 16.9683C15.3212 17.4387 14.9387 17.8212 14.4683 18.0609C13.9335 18.3333 13.2335 18.3333 11.8333 18.3333H8.16667C6.76654 18.3333 6.06647 18.3333 5.53169 18.0609C5.06129 17.8212 4.67883 17.4387 4.43915 16.9683C4.16667 16.4335 4.16667 15.7335 4.16667 14.3333V5.00001"
                    stroke="#667085"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
        ))}
      </>
    );
  };

  const openChangeHandle = (e: boolean, shouldSave?: boolean) => {
    if (e === true) {
      setSelectedOptions(localOptins);
    } else {
      setLocalOptins(selectedOptions);
    }
    shouldSave ? setOpen(false) : setOpen(e);
  };

  return (
    <Dialog open={open} onOpenChange={openChangeHandle}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent className="min-w-[1200px] bg-white">
        <DialogHeader>
          <DialogTitle className="border-b border-gray-200 pb-4 text-center">
            <div className="h-fit text-center text-[21px] font-semibold text-gray-400">
              Özellik Seç
            </div>
          </DialogTitle>
        </DialogHeader>

        <div>
          <h6 className="mb-4 text-lg font-semibold leading-normal text-slate-600">
            Özellikler
          </h6>

          <div className="flex items-center justify-start gap-6">
            <div className="flex w-[19%] flex-col gap-y-2">
              <label
                htmlFor="select-general"
                className="text-sm font-medium leading-[18px] text-slate-600"
              >
                Genel Beceriler
              </label>

              <SelectFilters
                items={generalItems}
                onChange={(newItems) => {
                  const newSelectedItems = newItems
                    .filter((x) => x.selected)
                    .map((x) => ({ value: x.value, label: x.text }));

                  onSelectChange(newSelectedItems, "general");
                }}
              />
            </div>

            <div className="flex w-[19%] flex-col gap-y-2">
              <label
                htmlFor="select-style"
                className="text-sm font-medium leading-[18px] text-slate-600"
              >
                Çalışma Tarzı
              </label>

              <SelectFilters
                items={workingStyleItems}
                onChange={(newItems) => {
                  const newSelectedItems = newItems
                    .filter((x) => x.selected)
                    .map((x) => ({ value: x.value, label: x.text }));

                  onSelectChange(newSelectedItems, "style");
                }}
              />
            </div>

            <div className="flex w-[19%] flex-col gap-y-2">
              <label
                htmlFor="select-infos"
                className="text-sm font-medium leading-[18px] text-slate-600"
              >
                Bilgi
              </label>

              <SelectFilters
                items={knowledgeItems}
                onChange={(newItems) => {
                  const newSelectedItems = newItems
                    .filter((x) => x.selected)
                    .map((x) => ({ value: x.value, label: x.text }));

                  onSelectChange(newSelectedItems, "infos");
                }}
              />
            </div>

            <div className="flex w-[19%] flex-col gap-y-2">
              <label
                htmlFor="select-features"
                className="text-sm font-medium leading-[18px] text-slate-600"
              >
                Yetkinlikler
              </label>

              <SelectFilters
                items={talentItems}
                onChange={(newItems) => {
                  const newSelectedItems = newItems
                    .filter((x) => x.selected)
                    .map((x) => ({ value: x.value, label: x.text }));

                  onSelectChange(newSelectedItems, "features");
                }}
              />
            </div>
          </div>
        </div>

        <hr />

        <div>
          <h6 className="mb-1 text-lg font-semibold leading-normal text-slate-600">
            Seçilenler
          </h6>

          <div className="h-[300px] overflow-y-auto">
            <div className="flex flex-wrap items-start justify-start gap-x-6 gap-y-5">
              {localOptins.length === 0 ? (
                <p>Seçilen herhangi bir özellik bulunmamaktadır.</p>
              ) : (
                <>{renderSelectedOptions(localOptins)}</>
              )}
            </div>
          </div>
        </div>

        <DialogFooter>
          <DialogClose asChild>
            <PrimaryButton isMinWidth isSecondary isGray>
              Vazgeç
            </PrimaryButton>
          </DialogClose>

          <PrimaryButton
            isMinWidth
            onClick={() => openChangeHandle(true, true)}
            disabled={
              !(
                localOptins.length > 0 &&
                localOptins.every((opt) => opt.min && opt.max && opt.multiple)
              )
            }
          >
            Özellikleri ekle
          </PrimaryButton>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default SelectFeature;

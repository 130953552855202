import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import { useAtom, useAtomValue } from "jotai";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../../atoms/surveyAtoms";

const DateStartEnd = () => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const [localState] = useState({
    inputWidth:
      stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]?.inputWidth,
  });

  const { t } = useTranslation();

  useEffect(() => {
    setStateSurveyJSON((prevState) => {
      let newState = { ...prevState };
      newState.pages![pageIndex]!.elements![elementIndex]!.inputWidth =
        localState.inputWidth;
      return newState;
    });
  }, [localState]);

  return (
    <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
      <label className="mb-2 block text-sm font-bold text-gray-500">
        {t("Başlangıç ve Bitiş Tarihi")}
      </label>
      <div className="mb-2 !flex">
        <DatePicker
          showIcon
          selected={
            stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex].minDate
          }
          onChange={(e: any) => {
            setStateSurveyJSON((prevState) => {
              let newState = { ...prevState };
              newState.pages![pageIndex].elements![elementIndex].minDate = e;
              return newState;
            });
          }}
          dateFormat={
            stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
              .dateFormat
          }
          placeholderText="Seçiniz"
          className="!w-[114px]"
        />
        <DatePicker
          showIcon
          className="!w-[114px]"
          selected={
            stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex].maxDate
          }
          onChange={(e: any) => {
            setStateSurveyJSON((prevState) => {
              let newState = { ...prevState };
              newState.pages![pageIndex].elements![elementIndex].maxDate = e;
              return newState;
            });
          }}
          dateFormat={
            stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
              .dateFormat
          }
          placeholderText="Seçiniz"
        />
      </div>
      <p className="mb-0 mt-3 text-sm text-stone-500">
        Sadece bu tarihler arasında bir tarih seçilebilir
      </p>
    </div>
  );
};

export default DateStartEnd;

import { classNames } from "../../lib/externalJS";

export default function PrimaryButton({
  children,
  isMinWidth,
  isSecondary,
  isGray,
  isBorderless,
  isRed,
  onClick,
  disabled,
  isLessYpadding,
  isEqualPadding,
  type,
}: {
  children?: React.ReactNode;
  isMinWidth?: boolean;
  isSecondary?: boolean;
  type?: any;
  isGray?: boolean;
  isBorderless?: boolean;
  isRed?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  isLessYpadding?: boolean;
  isEqualPadding?: boolean;
}) {
  const yPadding = isLessYpadding ? 5 : 10;
  const xPadding = isEqualPadding ? yPadding : 14;

  const getButtonColorClass = () => {
    if (disabled) {
      return isSecondary
        ? "border border-gray-300 text-gray-400"
        : "bg-gray-200 text-gray-400 font-semibold";
    }

    if (isSecondary) {
      if (isGray) {
        return `${!isBorderless ? "border border-gray-300" : ""} bg-white font-medium text-slate-700 hover:bg-gray-50`;
      }
      return `${!isBorderless ? "border border-blue-600" : ""} bg-white font-medium text-blue-600 hover:bg-blue-50`;
    }

    if (isRed) {
      return "border border-red-600 bg-red-600 font-medium text-white hover:bg-red-700";
    }

    if (isGray) {
      return "bg-gray-200 font-semibold text-black hover:bg-gray-300";
    }

    return "bg-blue-600 font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 disabled:bg-gray-200";
  };

  return (
    <button
      type={type ? type : "button"}
      onClick={onClick}
      disabled={disabled}
      className={classNames(
        "rounded-md text-sm leading-tight",
        isMinWidth ? "w-fit" : "w-full",
        getButtonColorClass(),
      )}
      style={{
        padding: `${yPadding}px ${xPadding}px`,
      }}
    >
      {children}
    </button>
  );
}

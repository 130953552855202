import { useAtom, useAtomValue } from "jotai";
import { ChangeEvent, useEffect, useState } from "react";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../atoms/surveyAtoms";

const FormSettingsSelectOptions = () => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const [inputText, setInputText] = useState<string>("");

  useEffect(() => {
    setStateSurveyJSON((prevState) => {
      let newState = { ...prevState };
      newState.pages![pageIndex]!.elements![elementIndex]!.dropDownButton =
        inputText;
      return newState;
    });
  }, [inputText]);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    setInputText(newValue);
  };

  return (
    <span
      style={{
        display:
          stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
            ?.cellType === "SelectList"
            ? "block"
            : "none",
      }}
    >
      <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
        <label className="mb-2 block text-sm font-bold text-gray-500">
          Açılır Liste Seçenekleri
        </label>
        <textarea
          value={inputText}
          onChange={handleChange}
          placeholder="test"
          className="w-full resize-none rounded border p-2"
        />
        <p className="mb-0 mt-3 text-sm text-stone-500">
          Her seçeneği virgül ile ayırınız
        </p>
      </div>
    </span>
  );
};

export default FormSettingsSelectOptions;

import DocumentFileTypes from "../../components/documentsSettings/DocumentFileTypes";
import DocumentOptionFileSize from "../../components/documentsSettings/DocumentOptionFileSize";

export default function DocumentOptionSettings() {
  return (
    <>
      <DocumentOptionFileSize />
      <DocumentFileTypes />
    </>
  );
}

import FormSettingsSelectLibrary from "../../components/FormSettingsSelectLibrary";
import FormSettingsHighest from "../../components/FormSettingsHighest";
import FormSettingsLowestRankingText from "../../components/FormSettingsLowestRankingText";
import NPSScoring from "../../components/SpecialRankings/NPSScoring";

export default function NPSFormOptionSettings() {
  return (
    <>
      <FormSettingsLowestRankingText />
      <FormSettingsHighest />
      <NPSScoring />
      <FormSettingsSelectLibrary />
    </>
  );
}

import { useAtom, useAtomValue } from "jotai";
import { useTranslation } from "react-i18next";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../../atoms/surveyAtoms";

const DatePastFuture = () => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const { t } = useTranslation();

  return (
    <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
      <label className="mb-2 block text-sm font-bold text-gray-500">
        {t("Geçmiş ve Gelecek")}
      </label>
      <div className="mb-2 !flex">
        <input
          className="form-check-input"
          style={{ transform: "scale(1.2)" }}
          type="checkbox"
          role="switch"
          id="flexSwitchCheckDefault1"
          checked={
            !stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
              .showExtra2
          }
          onChange={(e) => {
            setStateSurveyJSON((prevState) => {
              let newState = { ...prevState };
              newState.pages![pageIndex]!.elements![elementIndex]!.showExtra2 =
                !e.target.checked;
              return newState;
            });
          }}
        />
        <label
          className="ml-2 mr-10 text-gray-500"
          htmlFor="flexSwitchCheckDefault1"
        >
          Geçmiş
        </label>
        <input
          className="form-check-input"
          style={{ transform: "scale(1.2)" }}
          type="checkbox"
          role="switch"
          id="flexSwitchCheckDefault2"
          checked={
            !stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
              .showExtra3
          }
          onChange={(e) => {
            setStateSurveyJSON((prevState) => {
              let newState = { ...prevState };
              newState.pages![pageIndex]!.elements![elementIndex]!.showExtra3 =
                !e.target.checked;
              return newState;
            });
          }}
        />
        <label className="mx-2 text-gray-500" htmlFor="flexSwitchCheckDefault2">
          Gelecek
        </label>
      </div>
      <p className="mb-0 mt-3 text-sm text-stone-500">
        {t("Kullanıcılar geçmiş ya da gelecekten bir tarih seçebilsin")}
      </p>
    </div>
  );
};

export default DatePastFuture;

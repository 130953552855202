import FormSettingsSelectLibrary from "../../components/FormSettingsSelectLibrary";
import FormSettingsRatingIcons from "../../components/FormSettingsRatingIcons";
import FormSettingsRatingValue from "../../components/FormSettingsRatingValue";

export default function RatingFormOptionSettings() {
  return (
    <>
      <FormSettingsRatingIcons />
      <FormSettingsRatingValue />
      <FormSettingsSelectLibrary />
    </>
  );
}

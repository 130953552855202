import { useAtom, useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../atoms/surveyAtoms";

const FormSettingsAreaShape = () => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const [localState, setLocalState] = useState({
    areaGridNumber:
      stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
        ?.areaGridNumber,
  });

  const { t } = useTranslation();

  const [switchState, setSwitchState] = useState(
    Boolean(
      stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
        ?.areaGridNumber,
    ),
  );

  useEffect(() => {
    setStateSurveyJSON((prevState) => {
      let newState = { ...prevState };
      newState.pages![pageIndex]!.elements![elementIndex]!.areaGridNumber =
        localState.areaGridNumber;
      return newState;
    });
  }, [localState]);

  const handleGridChange = (e: any) => {
    const grid: number = parseInt(e, 10);
    setLocalState((prevState) => {
      const newState = { ...prevState };
      newState.areaGridNumber = grid;
      return newState;
    });
  };

  return (
    <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
      <label
        className="block text-sm font-bold text-gray-500"
        htmlFor="enterQuestion"
      >
        {t("fieldLayout")}
      </label>
      <span className="isolate mt-[12px] inline-flex rounded-md shadow-sm">
        <button
          type="button"
          onClick={() => {
            setSwitchState(false);
            setLocalState({
              areaGridNumber: 0,
            });
          }}
          className={`relative inline-flex items-center rounded-l-md ${
            !switchState
              ? "bg-[#D1E0FF] font-semibold text-gray-900"
              : "button-group-ticket-position bg-white font-medium text-gray-500 hover:bg-gray-300"
          } px-3 py-2 text-sm ring-1 ring-inset ring-gray-300 focus:z-10`}
        >
          {t("horizontal")}
        </button>
        <button
          type="button"
          onClick={() => {
            setSwitchState(true);
          }}
          className={`-ml-px relative inline-flex items-center rounded-r-md px-3 py-2 text-sm ${
            switchState
              ? "bg-[#D1E0FF] font-semibold text-gray-900"
              : "button-group-ticket-position bg-white font-medium text-gray-500 hover:bg-gray-300"
          } text-gray-900 ring-1 ring-inset ring-gray-300 focus:z-10`}
        >
          {t("vertical")}
        </button>
      </span>
      <div className="mt-3" style={{ display: switchState ? "flex" : "none" }}>
        <div className="custom-number-input h-10 w-32">
          <div className="relative mt-2 flex h-9 w-full flex-row rounded-3xl bg-transparent">
            <button
              data-action="decrement"
              onClick={() =>
                handleGridChange(
                  localState.areaGridNumber && localState.areaGridNumber <= 1
                    ? 1
                    : localState.areaGridNumber &&
                        localState.areaGridNumber - 1,
                )
              }
              className="h-full w-20 cursor-pointer rounded-l-lg border border-gray-300 bg-white text-gray-700 outline-none hover:bg-blue-400 hover:text-blue-50"
            >
              <span className="m-auto flex items-center justify-center text-2xl text-gray-800">
                −
              </span>
            </button>
            <input
              type="number"
              inputMode="numeric"
              value={localState.areaGridNumber}
              min={1}
              onChange={(e) =>
                handleGridChange(
                  parseInt(e.target.value) < 1 ? 1 : parseInt(e.target.value),
                )
              }
              className="text-md md:text-basecursor-default flex w-full items-center border border-gray-300 bg-white text-center font-semibold text-gray-800 outline-none hover:text-black focus:text-black focus:outline-none"
            />
            <button
              data-action="increment"
              className="flex h-full w-20 cursor-pointer items-center justify-center rounded-r-lg border border-gray-300 bg-white text-gray-700 hover:bg-blue-400 hover:text-blue-50"
              onClick={() =>
                handleGridChange(
                  localState.areaGridNumber ? localState.areaGridNumber + 1 : 1,
                )
              }
            >
              <span className="m-auto text-2xl text-gray-800">+</span>
            </button>
          </div>
        </div>
        <div className="mt-3 flex w-full items-center justify-center text-sm">
          {" "}
          Sütun sayısı
        </div>
      </div>
    </div>
  );
};

export default FormSettingsAreaShape;

import { selectedUserAtom } from "components/atoms/dashboardAtoms";
import { useAtomValue } from "jotai";

// {
//   title: "Bilgisayar tabanlı eğitim yazılımı",
//   desc: "Blackboard Learn; Karatahta Öğrenme Sistemi; Öğrenme yönetim sistemi LMS",
// }

export default function EmployeeProfileBusinessDistrict() {
  const selectedUser = useAtomValue(selectedUserAtom);

  const ROWS = selectedUser.isBolgesi.map((bolge: string) => {
    const splitted = bolge.split(":");
    return {
      title: splitted[0],
      desc: splitted[1],
    };
  });

  return (
    <div>
      {/* table header */}
      <div className="flex rounded-t-xl border border-gray-200 bg-gray-50 text-xs font-medium leading-[18px] text-gray-500">
        <div className="flex items-center p-3">İş Bölgesi</div>
      </div>

      {/* table items */}
      {ROWS.map((row: any, index: number) => (
        <div
          className={
            "flex border-x border-b border-gray-200 bg-white text-sm font-medium leading-tight text-gray-800 " +
            (index === ROWS.length - 1 ? "rounded-b-xl" : "")
          }
          key={index}
        >
          <div className="p-4">
            <span className="text-sm font-medium text-blue-600">
              {"• " + row.title + ":"}
            </span>

            {row.desc && (
              <span className="pl-1 text-sm font-normal text-black">
                {row.desc}
              </span>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

import { useState } from "react";
import ReminderMails from "./surveyMailShare/ReminderMails";
import Mails from "./surveyMailShare/Mails";
import NewReciever from "./surveyMailShare/NewReciever";
import ReminderMailsEdit from "./surveyMailShare/ReminderMailsEdit";
import { sharedUserMailsInterface } from "../../Interfaces/SurveyInterfaces";

const SurveyMailShare: React.FC = () => {
  const [mailState, setMailState] = useState<number>(1);
  const [formName, setFormName] = useState<string>("");
  const [pageState, setPageState] = useState<number>(1);
  const [selectedSharedMail, setSelectedSharedMail] =
    useState<sharedUserMailsInterface>();

  return (
    <div className="text-center">
      <div
        className="my-4 cursor-pointer items-center rounded-xl bg-gray-100 p-[2px] dark:text-gray-800"
        style={{
          display:
            mailState === 1 || (mailState === 3 && pageState === 1)
              ? "inline-flex"
              : "none",
        }}
      >
        <span
          className={`w-44 rounded-xl p-2 text-center ${
            mailState === 1 ? "bg-white text-blue-600" : "bg-gray-100"
          }`}
          onClick={() => setMailState(1)}
        >
          E-posta
        </span>
        <span
          className={`w-44 rounded-xl p-2 text-center ${
            mailState === 3 ? "bg-white text-blue-600" : "bg-gray-100"
          }`}
          onClick={() => setMailState(3)}
        >
          Hatırlatma E-postası
        </span>
      </div>

      {mailState === 1 ? (
        <Mails
          setMailState={setMailState}
          setSelectedSharedMail={setSelectedSharedMail}
        />
      ) : mailState === 2 ? (
        <NewReciever
          setMailState={setMailState}
          selectedSharedMail={selectedSharedMail}
        />
      ) : mailState === 3 ? (
        <ReminderMails
          setMailState={setMailState}
          setFormName={setFormName}
          setPageState={setPageState}
          pageState={pageState}
        />
      ) : mailState === 4 ? (
        <ReminderMailsEdit setMailState={setMailState} formName={formName} />
      ) : null}
      <br />
    </div>
  );
};

export default SurveyMailShare;

import axiosInstance from "../api";

export const getParticipantIds = async () => {
  return (await axiosInstance.get<any[]>("Participant/GetAll")).data;
};

export const getParticipant = async (id: string) => {
  return (
    await axiosInstance.get<any>(`Participant/GetById`, {
      params: { participantId: id },
    })
  ).data;
};

export const createParticipant = async (data: any) => {
  await axiosInstance.post("/Participant/Add", data);
};

export const updateParticipant = async (data: any) => {
  await axiosInstance.put(`/Participant/Update`, data, {
    params: { participantId: data._id },
  });
};

export const deleteParticipant = async (id: string) => {
  await axiosInstance.delete(`Participant/Delete`, {
    params: { participantId: id },
  });
};

import React, { CSSProperties, FC } from "react";

type Props = {
  checked?: boolean;
  onChange?: (e: any) => void;
  name: string;
  id: string;
  style?: CSSProperties;
};

const CheckboxBootstrap: FC<Props> = ({
  checked,
  onChange,
  name,
  id,
  style,
}) => {
  return (
    <input
      id={id}
      style={{ ...style }}
      type="checkbox"
      name={name}
      className="rounded float-left ml-{-1.5em} w-[1em] h-[1em] mt-[0.25em] align-top bg-white bg-center bg-no-repeat bg-contain border m-0"
      checked={checked}
      onChange={onChange}
    />
  );
};

export default CheckboxBootstrap;

import FilterList from "pages/dashboard2/Templates/FilterList";
import Search from "pages/dashboard2/Templates/Search";

function List() {
  const temp = [
    { title: "Tümü", count: 0 },
    { title: "Kişilik", count: 4 },
    { title: "Değerler", count: 1 },
    { title: "Liderlik", count: 3 },
    { title: "Takım Kültürü", count: 3 },
    { title: "Sayısal Testler", count: 4 },
    { title: "Sözel Testler", count: 3 },
    { title: "Tümevarımsal Akıl Yürütme Testleri", count: 3 },
    { title: "Tümdengelim Akıl Yürütme Testleri", count: 1 },
    { title: "Durumsal Yargı Testleri", count: 1 },
    { title: "Mekanik Akıl Yürütme Testleri", count: 1 },
    { title: "İç ve Dış Evrak Sepeti Testleri", count: 2 },
    { title: "Alıştırmalar", count: 3 },
  ];

  const filters = [
    {
      title: "Kategori",
      items: temp.map((item, index) => ({
        _id: "category" + index,
        parentCategoryId: "parentCategory1",
        path: "path" + index,
        title: item.title,
        description: "asd" + index,
        templateCount: index,
        parentCategory: {
          _id: "parentCategory1",
          title: "Kategori",
        },
        newCount: item.count,
      })),
    },
  ];

  filters[0].items[0].newCount = filters[0].items.reduce(
    (acc, item) => acc + item.newCount,
    0,
  );
  return <FilterList filters={filters} />;
}

export default function Categories({
  forPath,
}: {
  forPath: "templates" | "blog" | "terms";
}) {
  return (
    <form>
      {forPath !== "terms" && <Search placeholder="Ara" />}
      <List />
    </form>
  );
}

import { useAtom, useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../atoms/surveyAtoms";

const FormSettingsRatingIcons = () => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const [localState, setLocalState] = useState({
    ratingIcon:
      stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]?.ratingIcon,
  });

  useEffect(() => {
    setStateSurveyJSON((prevState) => {
      let newState = { ...prevState };
      newState.pages![pageIndex]!.elements![elementIndex]!.ratingIcon =
        localState.ratingIcon;
      return newState;
    });
  }, [localState]);

  useEffect(() => {
    setLocalState(() => {
      let localStateObject = {
        ratingIcon:
          stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]
            ?.ratingIcon,
      };
      return localStateObject;
    });
  }, [stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]?.ratingIcon]);

  const handleButtonClick = (buttonName: any) => {
    setLocalState({ ratingIcon: buttonName });
  };

  return (
    <div className="border-b-2 px-3 py-3">
      <label className="mb-2 block text-sm font-bold text-gray-500">
        Derecelendirme Simgesi
      </label>
      <div className="mb-1">
        <button
          onClick={() => handleButtonClick("star")}
          style={{
            backgroundColor:
              localState.ratingIcon === "star" ||
              localState.ratingIcon === undefined
                ? "rgb(219 234 254)"
                : "white",
          }}
          className={`focus:shadow-outline h-9 w-9 !rounded-s-xl border border-stone-400 bg-slate-100 px-2 text-base text-slate-900 transition-colors duration-150 hover:bg-blue-400 hover:text-slate-100`}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_392_34648)">
              <g filter="url(#filter0_d_392_34648)">
                <path
                  d="M9.40217 2.8778C9.59424 2.48869 9.69028 2.29413 9.82065 2.23197C9.93408 2.17789 10.0659 2.17789 10.1793 2.23197C10.3097 2.29413 10.4057 2.48869 10.5978 2.8778L12.42 6.56944C12.4767 6.68432 12.5051 6.74176 12.5465 6.78635C12.5832 6.82584 12.6272 6.85783 12.6761 6.88056C12.7313 6.90623 12.7946 6.91549 12.9214 6.93402L16.9975 7.5298C17.4267 7.59253 17.6413 7.6239 17.7406 7.72874C17.827 7.81995 17.8677 7.94529 17.8512 8.06985C17.8323 8.21302 17.6769 8.36436 17.3662 8.66702L14.4178 11.5387C14.3259 11.6282 14.28 11.673 14.2503 11.7263C14.2241 11.7734 14.2072 11.8252 14.2007 11.8788C14.1934 11.9393 14.2042 12.0025 14.2259 12.129L14.9216 16.1851C14.995 16.6129 15.0317 16.8269 14.9627 16.9538C14.9027 17.0642 14.7961 17.1417 14.6725 17.1646C14.5305 17.1909 14.3384 17.0899 13.9542 16.8879L10.3103 14.9716C10.1967 14.9119 10.14 14.882 10.0802 14.8703C10.0272 14.8599 9.97274 14.8599 9.91979 14.8703C9.85998 14.882 9.80321 14.9119 9.68967 14.9716L6.04573 16.8879C5.66156 17.0899 5.46947 17.1909 5.32744 17.1646C5.20386 17.1417 5.09723 17.0642 5.03724 16.9538C4.96829 16.8269 5.00498 16.6129 5.07835 16.1851L5.77403 12.129C5.79572 12.0025 5.80656 11.9393 5.79923 11.8788C5.79273 11.8252 5.77589 11.7734 5.74963 11.7263C5.71998 11.673 5.67402 11.6282 5.58211 11.5387L2.63376 8.66702C2.32301 8.36436 2.16764 8.21302 2.14873 8.06985C2.13228 7.94529 2.17292 7.81995 2.25933 7.72874C2.35866 7.6239 2.57327 7.59253 3.0025 7.5298L7.07855 6.93402C7.20531 6.91549 7.26869 6.90623 7.32389 6.88056C7.37276 6.85783 7.41676 6.82584 7.45345 6.78635C7.49488 6.74176 7.52323 6.68432 7.57994 6.56944L9.40217 2.8778Z"
                  fill="#D9D9D9"
                />
                <path
                  d="M9.40217 2.8778C9.59424 2.48869 9.69028 2.29413 9.82065 2.23197C9.93408 2.17789 10.0659 2.17789 10.1793 2.23197C10.3097 2.29413 10.4057 2.48869 10.5978 2.8778L12.42 6.56944C12.4767 6.68432 12.5051 6.74176 12.5465 6.78635C12.5832 6.82584 12.6272 6.85783 12.6761 6.88056C12.7313 6.90623 12.7946 6.91549 12.9214 6.93402L16.9975 7.5298C17.4267 7.59253 17.6413 7.6239 17.7406 7.72874C17.827 7.81995 17.8677 7.94529 17.8512 8.06985C17.8323 8.21302 17.6769 8.36436 17.3662 8.66702L14.4178 11.5387C14.3259 11.6282 14.28 11.673 14.2503 11.7263C14.2241 11.7734 14.2072 11.8252 14.2007 11.8788C14.1934 11.9393 14.2042 12.0025 14.2259 12.129L14.9216 16.1851C14.995 16.6129 15.0317 16.8269 14.9627 16.9538C14.9027 17.0642 14.7961 17.1417 14.6725 17.1646C14.5305 17.1909 14.3384 17.0899 13.9542 16.8879L10.3103 14.9716C10.1967 14.9119 10.14 14.882 10.0802 14.8703C10.0272 14.8599 9.97274 14.8599 9.91979 14.8703C9.85998 14.882 9.80321 14.9119 9.68967 14.9716L6.04573 16.8879C5.66156 17.0899 5.46947 17.1909 5.32744 17.1646C5.20386 17.1417 5.09723 17.0642 5.03724 16.9538C4.96829 16.8269 5.00498 16.6129 5.07835 16.1851L5.77403 12.129C5.79572 12.0025 5.80656 11.9393 5.79923 11.8788C5.79273 11.8252 5.77589 11.7734 5.74963 11.7263C5.71998 11.673 5.67402 11.6282 5.58211 11.5387L2.63376 8.66702C2.32301 8.36436 2.16764 8.21302 2.14873 8.06985C2.13228 7.94529 2.17292 7.81995 2.25933 7.72874C2.35866 7.6239 2.57327 7.59253 3.0025 7.5298L7.07855 6.93402C7.20531 6.91549 7.26869 6.90623 7.32389 6.88056C7.37276 6.85783 7.41676 6.82584 7.45345 6.78635C7.49488 6.74176 7.52323 6.68432 7.57994 6.56944L9.40217 2.8778Z"
                  stroke="#475467"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </g>
            <defs>
              <filter
                id="filter0_d_392_34648"
                x="-2.68823"
                y="1.3584"
                width="25.3763"
                height="24.6436"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_392_34648"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_392_34648"
                  result="shape"
                />
              </filter>
              <clipPath id="clip0_392_34648">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </button>
        <button
          onClick={() => handleButtonClick("hearth")}
          style={{
            backgroundColor:
              localState.ratingIcon === "hearth" ? "rgb(219 234 254)" : "white",
          }}
          className={`focus:shadow-outline h-9 w-9 !rounded-none border border-stone-400 px-2 text-base text-slate-900 transition-colors duration-150 hover:bg-blue-200 hover:text-slate-100`}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.4259 2.5C16.3611 2.5 18.3333 5.29375 18.3333 7.9C18.3333 13.1781 10.1481 17.5 9.99996 17.5C9.85181 17.5 1.66663 13.1781 1.66663 7.9C1.66663 5.29375 3.63885 2.5 6.57403 2.5C8.25922 2.5 9.36107 3.35312 9.99996 4.10312C10.6388 3.35312 11.7407 2.5 13.4259 2.5Z"
              stroke="#667085"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <button
          onClick={() => handleButtonClick("lightning")}
          style={{
            backgroundColor:
              localState.ratingIcon === "lightning"
                ? "rgb(219 234 254)"
                : "white",
          }}
          className={`ocus:shadow-outline h-9 w-9 !rounded-none border border-stone-400 px-2 text-base text-slate-900 transition-colors duration-150 hover:bg-blue-200 hover:text-slate-100`}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.8333 1.66699L3.41118 10.5735C3.12051 10.9223 2.97517 11.0967 2.97295 11.244C2.97102 11.3721 3.02808 11.4939 3.12768 11.5744C3.24226 11.667 3.46928 11.667 3.92333 11.667H9.99997L9.16663 18.3337L16.5888 9.42712C16.8794 9.07831 17.0248 8.9039 17.027 8.75661C17.0289 8.62856 16.9719 8.50674 16.8723 8.42625C16.7577 8.33366 16.5307 8.33366 16.0766 8.33366H9.99997L10.8333 1.66699Z"
              stroke="#667085"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <button
          onClick={() => handleButtonClick("flag")}
          style={{
            backgroundColor:
              localState.ratingIcon === "flag" ? "rgb(219 234 254)" : "white",
          }}
          className={`focus:shadow-outline h-9 w-9 !rounded-none border border-stone-400 px-2 text-base text-slate-900 transition-colors duration-150 hover:bg-blue-200 hover:text-slate-100`}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.33325 12.5003C3.33325 12.5003 4.16659 11.667 6.66659 11.667C9.16659 11.667 10.8333 13.3337 13.3333 13.3337C15.8333 13.3337 16.6666 12.5003 16.6666 12.5003V3.33366C16.6666 3.33366 15.8333 4.16699 13.3333 4.16699C10.8333 4.16699 9.16659 2.50033 6.66659 2.50033C4.16659 2.50033 3.33325 3.33366 3.33325 3.33366M3.33325 18.3337L3.33325 1.66699"
              stroke="#667085"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <button
          onClick={() => handleButtonClick("shield")}
          style={{
            backgroundColor:
              localState.ratingIcon === "shield" ? "rgb(219 234 254)" : "white",
          }}
          className={`focus:shadow-outline h-9 w-9 !rounded-none border border-stone-400 px-2 text-base text-slate-900 transition-colors duration-150 hover:bg-blue-200 hover:text-slate-100`}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.41826 18.0127C9.60275 18.1203 9.695 18.1741 9.82519 18.2021C9.92622 18.2237 10.0736 18.2237 10.1747 18.2021C10.3048 18.1741 10.3971 18.1203 10.5816 18.0127C12.205 17.0656 16.6666 14.0907 16.6666 10.0003V6.01497C16.6666 5.34871 16.6666 5.01558 16.5576 4.72922C16.4614 4.47625 16.3049 4.25053 16.1019 4.07157C15.872 3.869 15.5601 3.75203 14.9362 3.51809L10.4681 1.84253C10.2948 1.77756 10.2082 1.74508 10.1191 1.7322C10.0401 1.72078 9.95978 1.72078 9.88074 1.7322C9.79162 1.74508 9.705 1.77756 9.53175 1.84253L5.06359 3.51809C4.43975 3.75203 4.12783 3.869 3.89797 4.07157C3.6949 4.25053 3.53848 4.47625 3.44222 4.72922C3.33325 5.01558 3.33325 5.34871 3.33325 6.01497V10.0003C3.33325 14.0907 7.79489 17.0656 9.41826 18.0127Z"
              stroke="#667085"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <button
          onClick={() => handleButtonClick("plus")}
          style={{
            backgroundColor:
              localState.ratingIcon === "plus" ? "rgb(219 234 254)" : "white",
          }}
          className={`focus:shadow-outline h-9 w-9 !rounded-e-xl border border-stone-400 px-2 text-base text-slate-900 transition-colors duration-150 hover:bg-blue-200 hover:text-slate-100`}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_392_34658)">
              <path
                d="M10.0001 6.66699V13.3337M6.66675 10.0003H13.3334M18.3334 10.0003C18.3334 14.6027 14.6025 18.3337 10.0001 18.3337C5.39771 18.3337 1.66675 14.6027 1.66675 10.0003C1.66675 5.39795 5.39771 1.66699 10.0001 1.66699C14.6025 1.66699 18.3334 5.39795 18.3334 10.0003Z"
                stroke="#667085"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_392_34658">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </button>
      </div>
      <p className="mb-0 mt-3 text-sm text-stone-500">
        Derecelendirme ölçeğiniz için bir simge seçin
      </p>
    </div>
  );
};

export default FormSettingsRatingIcons;

import { useAtom, useAtomValue } from "jotai";
import { ChangeEvent, useEffect, useState } from "react";
import {
  elementIndexAtom,
  pageIndexAtom,
  surveyAtom,
} from "../../atoms/surveyAtoms";

const FormSettingsRatingValue = () => {
  const [stateSurveyJSON, setStateSurveyJSON] = useAtom(surveyAtom);
  const elementIndex = useAtomValue(elementIndexAtom);
  const pageIndex = useAtomValue(pageIndexAtom);

  const [localState, setLocalState] = useState({
    rateValues:
      stateSurveyJSON.pages![pageIndex!]!.elements![elementIndex!]?.rateValues,
  });

  function updateRateValues(e: ChangeEvent<HTMLInputElement>) {
    const numberOfValues = parseInt(e.target.value);
    if (numberOfValues < 1) {
      localState.rateValues = [];
    } else {
      localState.rateValues = Array.from(
        { length: numberOfValues },
        (val, index) => {
          return {
            _id: `seçenek ${index + 1}`,
            content: (index + 1).toString(),
            info: { tr: localState.rateValues?.[index]?.info?.tr || "" },
          };
        },
      );
    }

    setStateSurveyJSON((prevState) => {
      let newState = { ...prevState };
      newState.pages![pageIndex]!.elements![elementIndex]!.rateValues =
        localState.rateValues;
      return newState;
    });
  }

  useEffect(() => {
    setLocalState({
      rateValues:
        stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]?.rateValues,
    });
  }, [stateSurveyJSON.pages?.[pageIndex].elements?.[elementIndex]?.rateValues]);

  return (
    <div className="border-b-2 pb-5 pl-5 pr-3 pt-3">
      <label
        className="mb-2 block text-sm font-bold text-gray-500"
        htmlFor="enterTitle"
      >
        Değerlendirme Değeri
      </label>
      <input
        id="enterTitle"
        className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow-sm focus:outline-none"
        type="number"
        placeholder="5"
        value={localState.rateValues && localState.rateValues?.length}
        onChange={(e) => updateRateValues(e)}
      />
      <p className="mb-0 mt-3 text-sm text-stone-500">
        Derecelendirme ölçeğiniz için maksimum bir değer girin
      </p>
    </div>
  );
};

export default FormSettingsRatingValue;
